import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PaidRoute = ({ component: Component, paymentStatus, ...rest }) => {
    console.log("paymentStatus from routes", paymentStatus)
    return (
        <Route
            {...rest}
            render={(props) =>
                paymentStatus === 'paid' || paymentStatus == "trial" ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/subscription" />
                )
            }
        />
    );
}

const mapStateToProps = (state) => ({
    paymentStatus: state.loginReducer.paymentStatus,
});

export default connect(mapStateToProps)(PaidRoute);
