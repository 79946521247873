import React, { useEffect, useState } from 'react'
import myprofile from "../../assets/images/my-profile.png"
import camera from "../../assets/images/camera.png"
import { POSTIMAGE, GET, putAPI } from "../../utils/helper";
import { useHistory } from "react-router-dom"
import profileImage from '../../assets/images/profile1.png';

export default function EmployerProfile() {

    const [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {

        const getData = async () => {

            try {

                setLoading(true);
                const res = await GET(`getProfile`, 1);
               
                setLoading(false);
                setProfileData(res.body.data);



            } catch (error) {
                setLoading(false);
                console.log(error);
            }

        }

        getData();


    }, [])

    return (
        <div >
            <section style={{ minHeight: '60vh' }} class="My-Profile-wrapper bg-color">
                <div class="My-Profile">
                    <h2>My Profile</h2>
                    <hr style={{ width: '10rem' }} class="border-pink" />
                    {!loading ?
                        <>
                            <div class="profile-img pb-2 mt-5">
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} class="profile ">
                                    <img alt="" style={{ borderRadius: '50%', border: '2px solid #2786e2' }} class="img-pro" src={profileData?.profile_pic_url || profileImage} />
                                    <p onClick={() => history.push('/editemployerprofile')} style={{ fontSize: '1.2rem', color: '#FF6932', width: '3rem', cursor: 'pointer', marginTop: '1rem' }}>Edit</p>
                                </div>
                            </div>
                            <div class="form-information">
                                <div style={{ wordWrap: 'break-word' }} class="information">
                                    <span>Your name</span>
                                    <p>{profileData?.name}</p>
                                </div>
                                <div class="information">
                                    <span>Email address</span>
                                    <p>{profileData?.email}</p>
                                </div>
                                <div class="information">
                                    <span>Phone number</span>
                                    <p>{`${profileData?.country_code} ${profileData?.phone_number}`}</p>

                                
                                </div>
                                <div style={{ wordWrap: 'break-word' }} class="information">
                                    <span>Address</span>
                                    <p>{profileData?.address}</p>
                                </div>
                            </div>
                        </>

                        : <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    }
                </div>

            </section>
        </div>
    )
}
