import React from "react";
import "./style.scss";

function SelectFilterInput({ currentValue, options, handleSelectFilter }) {
  return (
    <div className="select-filter-input">
      <select value={currentValue} onChange={handleSelectFilter}>
        {options?.map((item) => (
          <option key={item.id} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SelectFilterInput;
