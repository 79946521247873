import React, { useState, useEffect } from "react";
import dotimg from "../../assets/images/dot-img.png";
import { GET, POST, clearLocalStorage } from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CModal,
  CModalBody,
  CModalFooter,
  CButton,
  CModalTitle,
  CModalHeader,
} from "@coreui/react";
import moment from "moment";

export default function MySubscription() {
  const history = useHistory();
  const [cancelUser, setCancelUser] = useState({
    id: null,
    modal: false,
  });
  const [modify, setModify] = useState(false);
  const [data, setData] = useState([]);
  const [expire, setExpire] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isCancel, setIsCancel] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoader(true);
        const result = await GET("mySubscription");
        setData(result.body.data);
        setExpire(result.body.data.expiry_date.slice(0, 10));

        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    };

    getData();
  }, [isCancel]);

  const handleCancelPlan = async () => {
    setCancelUser((state) => {
      return { ...state, modal: false };
    });
    try {
      const data = {
        subscription_id: cancelUser.id,
      };
      const result = await POST("/cancelPlan", data);
      if (result.status === 200) {
        toast.success(`Plan Cancelled Successfully`, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        clearLocalStorage();
        history.push("/home");
        setIsCancel(!isCancel);
      }
    } catch (error) {
      console.log(error);
      toast.error(`Something went wrong`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const cancelPlanOpen = (id) => {
    setCancelUser((state) => {
      return { ...state, id: id, modal: true };
    });
  };

  const ModifyPlanOpen = () => {
    setModify(true);
  };

  const handleModifyPlan = () => {
    history.push("/planlist");
  };

  return (
    <div>
      <section
        style={{ minHeight: "80vh" }}
        class="my-payment-wrapper bg-color"
      >
        <div class="container">
          <CModal
            show={cancelUser.modal}
            centered={true}
            backdrop={true}
            color="warning"
            onClose={() => {
              setCancelUser((state) => ({ ...state, modal: false }));
            }}
          >
            <CModalHeader>
              <CModalTitle>Cancel Plan?</CModalTitle>
            </CModalHeader>
            <CModalBody>
              Are you sure you want to cancel the plan? Once plan is cancelled
              you will lose access to the services.
            </CModalBody>
            <CModalFooter style={{ display: "flex", flexDirection: "row" }}>
              <CButton
                style={{
                  width: "6rem",
                  height: "3rem",
                  backgroundColor: "#F56A14",
                  color: "white",
                }}
                onClick={() => handleCancelPlan()}
              >
                Yes
              </CButton>

              <CButton
                style={{
                  width: "6rem",
                  height: "3rem",
                  backgroundColor: "#4B9CEB",
                  color: "white",
                }}
                onClick={() => {
                  setCancelUser((state) => ({ ...state, modal: false }));
                }}
              >
                Cancel
              </CButton>
            </CModalFooter>
          </CModal>

          <CModal
            show={modify}
            centered={true}
            backdrop={true}
            color="warning"
            onClose={() => {
              setModify(false);
            }}
          >
            <CModalHeader>
              <CModalTitle>Modify Plan?</CModalTitle>
            </CModalHeader>
            <CModalBody>
              Are you sure you want to modify subscription? Your previous
              services will be exhausted and you can access the app as per new
              plan services.{" "}
            </CModalBody>
            <CModalFooter style={{ display: "flex", flexDirection: "row" }}>
              <CButton
                style={{
                  width: "6rem",
                  height: "3rem",
                  backgroundColor: "#F56A14",
                  color: "white",
                }}
                onClick={() => handleModifyPlan()}
              >
                Yes
              </CButton>

              <CButton
                style={{
                  width: "6rem",
                  height: "3rem",
                  backgroundColor: "#4B9CEB",
                  color: "white",
                }}
                onClick={() => {
                  setModify(false);
                }}
              >
                Cancel
              </CButton>
            </CModalFooter>
          </CModal>

          <div class="my-payment comman-width-container">
            <div class="my-payment-head">
              <h3>My Subscription</h3>
            </div>
            {loader && (
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            )}
            {data && data.subscription_type === 0 ? (
              <div class="my-payment-Plan">
                <div style={{ minHeight: "16rem" }} class="my-payment-details">
                  <div class="free-trial">
                    <button>Free Trial</button>
                  </div>
                  <div class="time-info">
                    <small>$</small>
                    <h1>
                      0<span> 14 days</span>
                    </h1>
                  </div>
                </div>
                <div class="my-payment-expire">
                  <h6 class="plan-expire">
                    Your free trial expires on{" "}
                    {moment(expire).format("Do MMM YYYY")}
                  </h6>

                  <button
                    onClick={() => {
                      history.push("/planlist");
                    }}
                  >
                    Modify Subscription
                  </button>
                </div>
              </div>
            ) : null}

            {data && data.subscription_type === 1 ? (
              <div class="my-payment-Plan">
                <div class="my-payment-details">
                  <div class="time-info">
                    <small>$</small>
                    <h1>
                      {data.subscription?.subscription_management?.charge}
                      <span>{` ${data.subscription?.subscription_management?.duration} days`}</span>
                    </h1>
                  </div>
                  <div class="Business-plan">
                    <p>
                      {data.subscription?.subscription_management?.plan_name}
                    </p>
                    <span></span>
                  </div>
                  <ul>
                    {data.subscription?.subscription_management?.description
                      .length > 0
                      ? data.subscription?.subscription_management?.description.map(
                          (item) => (
                            <li>
                              <img src={dotimg} alt="" />
                              {item}
                            </li>
                          )
                        )
                      : null}
                  </ul>
                </div>
                <div class="my-payment-expire">
                  <h6 class="plan-expire">
                    Your plan expires on{" "}
                    {moment(
                      data?.subscription?.expiry_date.slice(0, 10)
                    ).format("Do MMM YYYY")}
                  </h6>
                  <button
                    onClick={() => {
                      cancelPlanOpen(data.subscription?.id);
                    }}
                    class="cancel-plan"
                  >
                    Cancel Plan
                  </button>
                  <button
                    onClick={() => {
                      ModifyPlanOpen();
                    }}
                  >
                    Modify Subscription
                  </button>
                </div>
              </div>
            ) : null}
            {data && data.subscription_type === 2 ? (
              <div class="my-payment-Plan">
                <div
                  style={{
                    color: "white",
                    height: "20rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "350px",
                  }}
                  class="my-payment-details"
                >
                  {data.message}
                </div>
                <div class="my-payment-expire">
                  <div class="plan-expire"></div>
                  <button
                    onClick={() => {
                      history.push("/planlist");
                    }}
                  >
                    Buy Subscription
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </div>
  );
}
