import React from 'react';
import { Redirect } from 'react-router-dom'
import TeamMemberDashboard from './TeamMemberDashboard'
import ManagerDashboard from './ManagerDashboard'
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux'


const Dashboard = (props) => {

  if (localStorage.getItem('first_time_reset_password') === '1') {
    return <Redirect to={{
      pathname: '/resetpasswordone',
    }}
    />
  }

  const look = props.showLook
 
  return (
    <React.Fragment>
      {look ? <ManagerDashboard />
        :
        <TeamMemberDashboard history={props.history}  id={props.loginData.id}  />
      }
    </React.Fragment>
  )

}
const mapStateToProps = (state) => {
  return {
    showLook: state.globalReducer.showLook,
    loginData: state.loginReducer.loginData
  }
}

const mapDispatch = dispatchEvent => ({
  setshowLook: () => dispatchEvent({ type: 'SET_LOOK' })
})

export default connect(mapStateToProps, mapDispatch)(Dashboard)