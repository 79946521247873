import globalReducer from './global.reducer';
import loginReducer from './login.reducer'
import employerReducer from './employer.reducer';
import userReducer from './userReducer';
import profileReducer from './profile.reducer'
import teamReducer from './team.reducer'
import goalReducer from './goal.reducer'
import ratingReducer from './rating.reducer';
import chatReducer from "./chat.reducer";
import unmountReducer from "./unmount.reducer";
import videoReducer from "./video.reducer";
import notificationCountReducer from "./notificationCount.reducer";
import particularAchievementReducer from './particularAchievement.reducer';
import achievementCommentReducer from "./AchievementComment.reducer";
import achievementListReducer from './AchievementListReducer'
import { combineReducers } from 'redux'

const rootReducer = combineReducers({
  unmountReducer: unmountReducer,
  globalReducer: globalReducer,
  loginReducer: loginReducer,
  employerReducer: employerReducer,
  user: userReducer,
  profileReducer: profileReducer,
  teamReducer: teamReducer,
  goalReducer: goalReducer,
  ratingReducer: ratingReducer,
  chatReducer: chatReducer,
  videoReducer: videoReducer,
  notificationCountReducer, 
  particularAchievementReducer: particularAchievementReducer,
  achievementCommentReducer: achievementCommentReducer,
  achievementListReducer
})

export default rootReducer