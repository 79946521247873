import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { validateEmail, errorMessage } from '../utils/validation'
import { POST } from '../utils/helper'
import { useHistory } from "react-router-dom"
import logo1 from '../assets/images/logo-1.png'
import { toast } from "react-toastify"
const ForgetPassword = (props) => {
  const history = useHistory();
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [err, setErr] = useState('')
  const [loginData, setLoginData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);


  const handleChange = ({ target }) => {
    props.onFieldChange({ name: 'OnShowEror', value: '' })
    const value = target.value
    setEmail(value)
    handleValidation(value)
  }

  const handleValidation = (value) => {
    let emailError = ''
    if (value === '') {
      emailError = errorMessage.required
    } else {
      if (validateEmail(value)) {
        emailError = ''
      } else {
        emailError = errorMessage.invalidEmail
      }
    }
    setEmailError(emailError)
  }

  const handleSubmit = async event => {
    event.preventDefault();
    handleValidation(email)
    if (emailError === '') {
      const postObj = { email: email, user_role: "3" }
     
      try {
        const result = await POST('forgotPassword', postObj, 0);
   
        if (result.data.success) {
          const { is_both } = result.data.body.data
          if (is_both) {
            setModalOpen(true);
            setLoginData(result.data.body.data);
            return
          }
          toast.success("We have sent you a password reset link on your email address", {
            position: toast.POSITION.BOTTOM_CENTER,
          })
          history.push('/login');
        }
        else {
          toast.error('Something went wrong', {
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: true
          })
        }
      } catch (error) {
      
        setEmailError(error.response.data.message)

      }








    }
  }
  return (
    <section class="sign-in-wrapper">
      <div class="signin-main">
        <div class="signin-box">
          <div class="logo-img">
            <img src={logo1} />
          </div>
          <h3>Forgot Password</h3>
          <form class="setup-new-pass" onSubmit={handleSubmit}>
            <div class="form-group">
              <input type="email" id="email3" name="email" placeholder="Email address" value={email}
                className={`form-control ${emailError ? "is-invalid" : ""}`}
                onChange={handleChange} required />
              <div className="invalid-feedback">
                {emailError}
              </div>
            </div>
            <div className="errmsg">  {props.forgotPswrdErrMsg}</div>

            <div class="Sign-In-button">
              {props.loading ?
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                :
                <button>Submit</button>
              }
            </div>

          
          </form>
        </div>
      </div>
    </section>
  )
}
const mapStateToProps = (state) => {
  const forgotPswrdMsg = state.loginReducer.forgotPswrdMsg
  const forgotPswrdErrMsg = state.loginReducer.forgotPswrdErrMsg
  const loading = state.globalReducer.loading
  return {
    forgotPswrdMsg,
    loading,
    forgotPswrdErrMsg
  }
}

const mapDispatch = dispatchEvent => ({
  ResetPswrd: (payload) => dispatchEvent({ type: 'RESET_EMAILSEND', value: payload }),
  onFieldChange: (payload) => dispatchEvent({ type: 'ONFIELD_CHANGE', value: payload })
})

export default connect(mapStateToProps, mapDispatch)(ForgetPassword)



