import React, { useEffect, useState } from "react";
import video from "../../assets/images/video.png";
import call from "../../assets/images/call.png";
import firebase from "firebase";
import { connect } from "react-redux";
import ChatsMsg from "../ChatsMsg";
import Avatar from "react-avatar";
import { getTime } from "../../utils/validation";
import { getFCMToken } from "../../Components/Common/Queries";
import profile1 from "../../assets/images/profile1.png";
import { sendFcmNotification } from "../../Components/Common/firebaseApi";
import Democall from "../Democall";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getMedia from "../../utils/permission";

const ChatThread = (props) => {
  const [path, setPath] = useState(null);

  const showComp = useSelector((state) => state.unmountReducer.showFromChat);
  const profile_pic_url = useSelector(
    (state) => state.loginReducer.coachloginData.image
  );
  const dispatch = useDispatch();

  const {
    chatLastDeletedOn,
    profilePic,
    name,
    item,
    roomId,
    updateLastMessageForRoom,
    lastMessageTime,
    lastMessageId,
    lastMessage,
    isMessageRead,
  } = props;
  var messgaesListner = null;

  const messaging = firebase.messaging.isSupported()
    ? firebase.messaging()
    : null;

  useEffect(() => {
    observerLastMessageForRoom(roomId);
    return () => {
      if (messgaesListner) {
        messgaesListner();
      }
    };
  }, []);

  const observerLastMessageForRoom = (id) => {
    const executor = async (resolve, reject) => {
      try {
        const roomCollection = firebase.firestore().collection("chats_dev");
        const messagesCollection = roomCollection
          .doc(id)
          .collection("messages");
        const query = messagesCollection.orderBy("timestamp", "desc").limit(1);
        const roomRef = roomCollection.doc(id.toString());
        const snap1 = await roomRef.get();
        if (snap1.empty) {
          resolve(null);
        } else {
          messgaesListner = query.onSnapshot((snapshot) => {
            let message = {};
            try {
              if (snapshot.empty) {
              } else {
                snapshot.docChanges().forEach((change) => {
                  if (change.type === "added") {
                    message = change.doc.data();
                  } else if (change.type === "modified") {
                    message = change.doc.data();
                  }
                });
                if (message._id != lastMessageId) {
                  onNewMessageReceived(message);
                }
              }
            } catch (err) {
              reject(err);
            }
          });
          resolve("Done");
        }
      } catch (error) {
        reject(error);
      }
    };
    return new Promise(executor);
  };

  const onNewMessageReceived = (message) => {
    updateLastMessageForRoom(roomId, message);
  };

  const openChat = async (e, data) => {
    e.stopPropagation();
    const name = localStorage.getItem("name");
    const userId = localStorage.getItem("id");

    let user = {
      id: data.id,
      is_manager: null,
      name: data.name,
      profile_pic_url: data.profilePic,
      status: 1,
    };

    let newdata = {
      id: data.id,
      user: {
        name: name,
        id: userId,
        profile_pic_url: profile_pic_url,
      },
      room_id: data.room_id,
      status: 1,
      other_user: user,
      chatLastDeletedOn: chatLastDeletedOn,
    };
    dispatch({ type: "SET_CHAT", payload: newdata });
    await new ChatsMsg().createChatNode(data.room_id, [
      { id: userId, name: name },
      user,
    ]);

    setTimeout(() => {
      props.history.push({
        pathname: "/coachchatdetail",
        state: {
          title: "",
          id: data?.id,
          room_id: data.room_id,
          is_disabled: item.is_disabled,
        },
      });
    }, 1000);
  };

  const changeView = (type, name) => {
    if (type === "timeout") {
      toast.error(`${name} is not available`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }

    if (type === "Call Ended") {
      toast.error("Call Ended", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
    }

    dispatch({ type: "MOUNTFROMCHAT" });
    setPath(null);
  };

  useEffect(() => {
    if (path) {
      if (path.joinCall === true) {
        dispatch({ type: "UNMOUNTFROMCHAT" });
      }
    }
  }, [path]);

  const userName = localStorage.getItem("name");
  const userId = localStorage.getItem("id");
  const startVideoCallFun = async (e, item, isVideo) => {
    e.stopPropagation();
    const res = await getMedia();
    if (!res) {
      toast.error(`Please give Video and Audio permission`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    localStorage.setItem("subs", null);
    e.stopPropagation();
    setTimeout(() => sendSignalToOtherUser(item, isVideo), 3000);

    setPath((state) => {
      return {
        ...state,
        userId: userId,
        joinCall: true,
        otherUserName: item.user.name,
        userName: userName,
        image: item.user.profile_pic_url,
        item: item,
        isVideo: isVideo,
      };
    });
  };
  const sendSignalToOtherUser = async (item, isVideo) => {
    try {
      var secondUserId = userId;
      // var token = await getFCMToken(secondUserId + "");
      const fcmToken = await messaging?.getToken();
      var firstUser = item.user.name;
      var secondUser = {
        firstName: userName ? userName : "",
        userId: secondUserId + "",
      };
      let msg = isVideo
        ? "Incoming video call from"
        : "Incoming Audio call from";
      await sendFcmNotification(fcmToken, msg, userName, {
        type: "2",
        firstUser,
        secondUser,
        firstUserId: userId,
        secondUserId,
        room_id: item?.room_id,
      })
        .then((res) => {
          console.log(res, "response from coachChatThread");
        })
        .catch((error) => {
          if (error && error.message && error.message !== "") {
          }
          console.log("ErrorSEND FCM", error);
        });
    } catch (e) {
      console.log("Error in VIDEO CALL MESSAGE", e);
    }
  };

  return showComp && path ? (
    <Democall
      removeComp={changeView}
      isVideo={path.isVideo}
      joinCall={path.joinCall}
      otherUserName={path.otherUserName}
      userName={path.userName}
      item={path.item}
      image={path.image}
      userId={path.userId}
    />
  ) : (
    <React.Fragment>
      <div
        class="chat-details comman-width-container"
        onClick={(e) => openChat(e, item)}
      >
        <div class="row">
          <div class="col-md-6 col-sm-6 col-6">
            <div class="media ">
              {profilePic ? (
                <img
                  src={profilePic ? profilePic : profile1}
                  alt="worker"
                  class="mr-3 rounded-circle"
                />
              ) : (
                <Avatar
                  color={Avatar.getRandomColor("sitebase", [
                    "red",
                    "green",
                    "blue",
                  ])}
                  name={name}
                  size="50"
                  round={true}
                  class="mr-3 rounded-circle"
                  style={{ margin: "0 16 0 0 " }}
                />
              )}
              <div class="media-body">
                <h5>{name}</h5>
                {lastMessage ? (
                  <p className={`${!isMessageRead ? "color-pink" : ""}`}>
                    {lastMessage}
                  </p>
                ) : (
                  <p></p>
                )}
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-6">
            <div class="socil-img float-right">
              {lastMessageTime ? (
                <p className={`${!isMessageRead ? "color-pink-time" : ""}`}>
                  {" "}
                  {getTime(lastMessageTime)}
                </p>
              ) : (
                <p></p>
              )}
              {!item.is_disabled && item.type !== 2 ? (
                <>
                  <img
                    src={video}
                    alt="video"
                    title="Start a Video Call"
                    onClick={(e) => startVideoCallFun(e, item, true)}
                  />
                  <img
                    src={call}
                    alt="call"
                    title="Start an Audio Call"
                    onClick={(e) => startVideoCallFun(e, item, false)}
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  const loading = state.globalReducer.loading;
  return {
    chatUser: state.chatReducer.chatUser,
    loading,
  };
};

const mapDispatch = (dispatchEvent) => ({
  getChatUser: (payload) =>
    dispatchEvent({ type: "Get_ChatUser", value: payload }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
  creteChatRoom: (payload) =>
    dispatchEvent({ type: "CREATECHAT_ROOM", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(ChatThread);
