import React from "react";
import { useHistory } from "react-router-dom";

import "./style.scss";

function GoalMemberItem({ employeeData }) {
  const history = useHistory();

  return (
    <div
      className="goal-member-item"
      onClick={() => {
        history.push(`/goalaproval/${employeeData.goal_id}/${employeeData.id}`);
      }}
    >
      {employeeData?.employee?.name}
    </div>
  );
}

export default GoalMemberItem;
