import axios from "axios";
import config from "./index";

const instanceAxios = axios.create({
  baseURL: config.BASE_API_URL,
});

export const setAuthToken = (token) => {
  if (token) {
    instanceAxios.defaults.headers.common["Authorization"] = `${token}`;
  } else {
    delete instanceAxios.defaults.headers.common["Authorization"];
  }
};

setAuthToken(localStorage.getItem("s_token"));

instanceAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instanceAxios;
