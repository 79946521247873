import React, { useEffect, useState } from "react";
import Advisorsline from "../assets/images/Advisors-line.png";
import Articles from "../assets/images/Articles.png";
import axios from "axios";
import Navbar from "./Common/Navbar";
import config from "../config";

const { BASE_API_URL } = config;

export default function NewsArticles() {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    const getData = async () => {
      try {
        setLoader(true);
        const result = await axios(
          `${BASE_API_URL}web/getArticles`
        );
        setLoader(false);
        setData(result.data.body.data.rows);
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    };

    getData();
  }, []);

  return (
    <div>
      <Navbar />
      <section class="Coaches-wrapper bg-color min-height-50vh">
        <div class="container">
          <div class="Coaches comman-width-container">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12">
                <div class="Coaches-info">
                  <div class="Coaches-head">
                    <h2>Resources</h2>
                    <img class="img-fluid" src={Advisorsline} alt="" />
                  </div>
                </div>
              </div>
            </div>
            {!loader ? (
              data && data.length > 0 ? (
                data.map((item) => (
                  <div key={item.id} class="media d-block">
                    <div className="row">
                      <div className="col-md-3">
                        <div class="client-img">
                          <img
                            className="img-fluid"
                            src={item.image ? item.image : Articles}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-md-9">
                        <div class="media-body">
                          <h4>{item.title}</h4>
                          <p>{item.description ? item.description : null}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                "No Articles Found"
              )
            ) : (
              <div
                class="spinner-border text-primary   newsarticles-spinner"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
