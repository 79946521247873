import React, { useEffect, useState } from "react";
import pro from "../../assets/images/profilePlaceholder/profilePlaceholder.png";
import { GET, POST } from "../../utils/helper";
import Modal from "../Common/Modal";
import ReactStars from "react-rating-stars-component";
import moment from "moment";
import { toast } from "react-toastify";
import ReactStarsComp from "../Common/ReactStarsComp";
import "../.././scss/customforall.scss";
export default function SessionHistory() {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [detailModal, setDetailModal] = useState({
    isOpen: false,
    data: null,
  });
  const [rateModal, setRateModal] = useState({
    isOpen: false,
    session_id: null,
    rating: 0,
    comment: "",
    data: null,
  });
  let dateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

  const handleViewDetailClicked = (id) => {
    const getData = async () => {
      try {
        const result = await GET(`getSessionHistoryDetails/${id}`);
        if (result.status === 200) {
          setDetailModal((state) => ({
            ...state,
            data: result?.body?.data,
            isOpen: true,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const result = await GET(`listSessionHistory?datetime=${dateTime}`);
        setLoading(false);
        if (result.status === 200) {
          setSessions(result?.body?.data?.rows);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    getData();
  }, [refresh]);

  const handleFeedback = async () => {
    if (!rateModal.rating) {
      toast.error("Please enter Rating", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    let body = {
      session_id: rateModal.session_id,
      rating: rateModal.rating,
      comment: rateModal.comment,
    };

    if (!rateModal.comment) {
      delete body.comment;
    }

    try {
      const result = await POST(`rateCoachSession`, body);
      setRateModal((state) => ({
        ...state,
        isOpen: false,
        rating: null,
        session_id: null,
        comment: "",
        data: null,
      }));

      if (result.status === 200) {
        setRefresh(!refresh);
        toast.success("Coach rated successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

 

  return (
    <section style={{ minHeight: "80vh" }} class="profiles-wrapper-main">
      <div class="container">
        <h3 style={{ textAlign: "center", justifyContent: "center" }}>
          Sessions History
        </h3>
        {!loading ? (
          sessions.length > 0 ? (
            sessions.map((session) => {
              return (
                <div class="profiles-wrapper">
                  <div class="profile-comman-box">
                    <div
                      class="row"
                      style={{ textAlign: "center", justifyContent: "center" }}
                    >
                      <div class="col-md-12 comman-width one">
                        <div class="main-profiles  mainprofile2">
                          <div className="profile-div">
                            <div className="profile-div1">
                              <p className="profile-p">
                                <p>
                                  {moment(session?.date).format("Do MMM YYYY")}
                                </p>{" "}
                              </p>
                            </div>
                          </div>
                          <div
                            style={{
                              color: "grey",
                              minWidth: "10%",
                              height: "1.5rem",
                              borderTopRightRadius: "1rem",
                              borderBottomRightRadius: "1rem",
                              position: "absolute",
                              top: "0",
                              right: "0",
                              paddingLeft: "5%",
                              paddingTop: "1px",
                            }}
                          >
                            <p
                              style={{
                                backgroundColor:
                                  session.status === 1
                                    ? "yellow"
                                    : session.status === 2
                                    ? "rgba(0, 127, 0, 0.54)"
                                    : session.status === 3
                                    ? "red"
                                    : session.status === 4
                                    ? "#FF7500"
                                    : "rgba(0, 127, 0, 0.54)",
                                borderTopRightRadius: "1rem",
                                borderBottomLeftRadius: "1rem",
                                paddingLeft: "5px",
                                paddingRight: "5px",
                                display: "inline-block",
                                color: "white",
                                wordBreak: "break-all",
                                textAlign: "right",
                              }}
                            >
                              <p>
                                {session.status === 1
                                  ? "Pending"
                                  : session.status === 2
                                  ? "Accepted"
                                  : session.status === 3
                                  ? "Rejected"
                                  : session.status === 4
                                  ? "Cancelled"
                                  : "Completed"}
                              </p>
                            </p>
                          </div>
                          <div class="profile-img">
                            <img
                              className="imgprofile"
                              src={
                                session?.coach_management?.profile_pic_url ||
                                pro
                              }
                              alt=""
                            />
                          </div>
                          <div class="row no-gutters  ">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "50rem",
                              }}
                            >
                              <div className="profile-div1">
                                <p>Coach Name :</p>
                                <p className="profile-p">
                                  <p>{session?.coach_management?.name}</p>
                                </p>
                              </div>
                              {session?.status !== 3 && session?.status !== 4 && (
                                <div className="profile-div1">
                                  <p>Ratings :</p>
                                  <p className="profile-p">
                                    <p>
                                      <ReactStarsComp
                                        color="#C9C9C9"
                                        size={20}
                                        value={session?.coach_rating}
                                        edit={false}
                                        activeColor="#FF7500"
                                        emptyIcon={
                                          <i className="fa fa-star-o" />
                                        }
                                        halfIcon={
                                          <i className="fa fa-star-half-alt" />
                                        }
                                        filledIcon={
                                          <i className="fa fa-star" />
                                        }
                                      />
                                    </p>
                                  </p>
                                </div>
                              )}
                              <div className="profile-div1">
                                <p>Performance Challenge :</p>
                                <p className="profile-p">
                                  <p>
                                    {
                                      session?.coach_specialization_category
                                        ?.name
                                    }
                                  </p>{" "}
                                </p>
                              </div>

                              <div className="profile-div1">
                                <p>Challenge Details :</p>
                                <p className="profile-p">
                                  <p>{session?.query}</p>{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="session-history-div">
                            {session?.coach_rating === 0 &&
                              session?.status !== 3 &&
                              session?.status !== 4 && (
                                <div className="session-history-div-2">
                                  <button
                                    onClick={() =>
                                      setRateModal((state) => ({
                                        ...state,
                                        isOpen: true,
                                        session_id: session.id,
                                        data: session,
                                      }))
                                    }
                                    style={{ width: "50%" }}
                                  >
                                    Rate Coach
                                  </button>
                                </div>
                              )}

                            <div className="session-history-div-2">
                              <button
                                onClick={() =>
                                  handleViewDetailClicked(session?.id)
                                }
                                style={{ width: "50%" }}
                              >
                                View Detail
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <h3 className="prev-content">No sessions found</h3>
          )
        ) : (
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        )}
      </div>
      <Modal
        showmodal={detailModal.isOpen}
        onCLose={() => {
          setDetailModal((state) => ({ ...state, isOpen: false }));
        }}
        body={
          detailModal.data && (
            <div class="profiles-wrapper">
              <div class="profile-comman-box">
                <div
                  class="row"
                  style={{ textAlign: "center", justifyContent: "center" }}
                >
                  <div class="">
                    <div class="main-profiles  mainprofile2">
                      <div className="profile-div">
                        <div className="profile-div1">
                          <p className="profile-p">
                            <p>
                              {moment(detailModal.data?.date).format(
                                "Do MMM YYYY"
                              )}
                            </p>{" "}
                          </p>
                        </div>
                      </div>
                      <div className="profile-div-1">
                        <p
                          style={{
                            backgroundColor:
                              detailModal.data.status === 1
                                ? "yellow"
                                : detailModal.data.status === 2
                                ? "rgba(0, 127, 0, 0.54)"
                                : detailModal.data.status === 3
                                ? "red"
                                : detailModal.data.status === 4
                                ? "red"
                                : "rgba(0, 127, 0, 0.54)",
                            borderTopRightRadius: "0.6rem",
                            borderBottomLeftRadius: "1rem",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            display: "inline-block",
                            color: "white",
                            wordBreak: "break-all",
                            textAlign: "right",
                          }}
                        >
                          <p>
                            {detailModal.data.status === 1
                              ? "Pending"
                              : detailModal.data.status === 2
                              ? "Accepted"
                              : detailModal.data.status === 3
                              ? "Rejected"
                              : detailModal.data.status === 4
                              ? "Cancelled"
                              : "Completed"}
                          </p>
                        </p>
                      </div>
                      <div class="profile-img">
                        <img
                          className="imgprofile"
                          src={
                            detailModal?.data?.coach_management
                              ?.profile_pic_url || pro
                          }
                          alt=""
                        />
                      </div>
                      <div class="row no-gutters">
                        <div className="data-main-div">
                          <div className="ladelstyle">
                            <p>Session Time :</p>
                            <p className="labelstyless">
                              <p>
                                {detailModal
                                  ? moment(
                                      detailModal.data.start_time.slice(0, 5),
                                      ["HH.mm"]
                                    ).format("hh:mm a")
                                  : ""}{" "}
                                -
                                {detailModal
                                  ? moment(
                                      detailModal.data.end_time.slice(0, 5),
                                      ["HH.mm"]
                                    ).format("hh:mm a")
                                  : ""}
                              </p>
                            </p>
                          </div>
                          <div className="ladelstyle">
                            <p>Team Level :</p>
                            <p className="labelstyless">
                              <p>{detailModal?.data?.employee_rank?.name}</p>
                            </p>
                          </div>

                          <div className="ladelstyle">
                            <p>Performance Challenge:</p>
                            <p className="labelstyless">
                              <p>
                                {
                                  detailModal?.data
                                    ?.coach_specialization_category?.name
                                }
                              </p>{" "}
                            </p>
                          </div>

                          <div className="ladelstyle">
                            <p>Challenge Details :</p>
                            <p className="labelstyless">
                              <p>{detailModal?.data?.query}</p>{" "}
                            </p>
                          </div>

                          {detailModal?.data?.status === 5 ? (
                            <div className="ladelstyle">
                              <p>Call duration :</p>
                              <p className="labelstyless">
                                <p>{detailModal?.data?.call_duration} mins</p>
                              </p>
                            </div>
                          ) : null}
                          {detailModal?.data?.comment && (
                            <div className="ladelstyle">
                              <p>Review :</p>
                              <p className="labelstyless">
                                <p>{detailModal?.data?.comment}</p>
                              </p>
                            </div>
                          )}

                          {detailModal?.data?.cancelled_by !== 0 && (
                            <div className="ladelstyle">
                              <p>Cancelled by :</p>
                              <p className="labelstyless">
                                {detailModal?.data?.cancelled_by === 0
                                  ? "Not Cancelled"
                                  : detailModal?.data?.cancelled_by === 1
                                  ? "Coach"
                                  : detailModal?.data?.cancelled_by === 2
                                  ? "Employee"
                                  : ""}
                              </p>
                            </div>
                          )}
                          {detailModal?.data?.cancelled_by !== 0 && (
                            <div className="ladelstyle">
                              <p>Cancel Reason :</p>
                              <p className="labelstyless">
                                <p>{detailModal?.data?.cancel_reason}</p>
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      />
      <Modal
        showmodal={rateModal.isOpen}
        onCLose={() => {
          setRateModal((state) => ({
            ...state,
            isOpen: false,
            rating: null,
            session_id: null,
            comment: "",
            data: null,
          }));
        }}
        title="Ratings and Reviews"
        handleClick={handleFeedback}
        body={
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h5>{rateModal?.data?.coach_management.name}</h5>
              <h5>{rateModal?.data?.date}</h5>
              <h5>
                {rateModal?.data?.start_time.slice(0, 5)} -{" "}
                {rateModal?.data?.end_time.slice(0, 5)}
              </h5>
            </div>
            <div
              style={{
                margin: "1rem 0 1rem 0",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ReactStarsComp
                onChange={(e) =>
                  setRateModal((state) => ({ ...state, rating: e }))
                }
                color="#C9C9C9"
                size={20}
                value={rateModal.rating}
                edit={true}
                activeColor="#FF7500"
                emptyIcon={<i className="fa fa-star-o" />}
                halfIcon={<i className="fa fa-star-half-alt" />}
                filledIcon={<i className="fa fa-star" />}
              />
            </div>
            <div>
              <label>Type your reviews/feedback</label>
              <textarea
                style={{ width: "100%", backgroundColor: "white" }}
                maxLength="200"
                class="form-control"
                onChange={(e) =>
                  setRateModal((state) => ({
                    ...state,
                    comment: e.target.value,
                  }))
                }
                value={rateModal.comment}
                rows="4"
                placeholder="Write here"
              ></textarea>
            </div>
          </div>
        }
      />
    </section>
  );
}
