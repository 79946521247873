import React from 'react'
import logofree from "../../../src/assets/images/logo-free.png"
import freeFile from "../../../src/assets/images/free-file.png"

export default function FreeTrial() {
    return (
        <div>
            <div class="free-trial-sign">
                <div class="container">
                    <div class="free-trial-img-logo">
                        <img class="logo" src={logofree} alt="" />
                        <img class="edit-free-trail" src={freeFile} alt="" />
                    </div>
                </div>
                <div class="trail-info-wrapper">
                    <div class="container">
                        <div class="trail-info">
                            <div class="trial-btn">
                                <button >
                                    Your free trial will expire <br />
                                    <span> after 14 days</span>
                                </button>
                            </div>
                            <div class="Subscription-btn">
                                <button >
                                    Buy Subscription Plan
                                </button>
                            </div>
                         
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
