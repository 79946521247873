import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import sms from "../../assets/images/sms-green.png";
import setChatTitle from "../../utils/ChatTitle"
import cross from "../../assets/images/cross.png"
import { Modal, ModalBody, } from 'reactstrap';
import dotfill from "../../assets/images/dot-fill.png"
import dotempty from "../../assets/images/dot-empty.png"
import ReactStars from "react-rating-stars-component"
const AttributeDetail = (props) => {
    const [modalOpen, setModalOpen] = useState(false)

    const ratingdetail = props?.location?.state?.quantitativeDetailEmployee
    return (
        <React.Fragment>
            <div class="Qualitative-measurement-wrapper">
                <div class="container">
                    <div class="Qualitative-measurement comman-width-container">
                        <div class="heding">
                            <h2>Qualitative Measures</h2>
                            <hr style={{ width: '12rem' }} class="border-pink" />
                        </div>
                        <div class="date-wrapper">
                            <button class="start-date">Last Rated on<br /><span className="start-date-span">{ratingdetail && ratingdetail.startDate.slice(0, 10)}</span></button>
                            <button>Next Rating on<br /><span className="start-date-span">{ratingdetail && ratingdetail.endDate.slice(0, 10)}</span></button>

                        </div>
                        <table class="table Qualitative-table">
                            <thead>
                                <tr>
                                    <th>Rating</th>
                                    <th>Comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ratingdetail && ratingdetail.qualitativeMeasures.length > 0 ? ratingdetail.qualitativeMeasures.map(item => (
                                    <tr>
                                        <td>{item.label}
                                            <p>

                                                <ReactStars {...{
                                                    color: "#C9C9C9",
                                                    size: 25,
                                                    value: item.rating,
                                                    edit: false,
                                                    activeColor: "#FF7500"
                                                }} />
                                            </p>
                                        </td>
                                        <td>{item.desc ? item.desc : 'N/A'}</td>

                                    </tr>

                                )) : <div class="heding">
                                    <h4>No Goals Found</h4>

                                </div>}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>


        </React.Fragment >
    )

}

const mapStateToProps = (state) => {
    const loading = state.globalReducer.loading
    const chatPopupData = state.chatReducer.chatPopupData

    return {
        loading,
        chatRoomdata: state.chatReducer.chatRoomdata,
        chatPopupData,

    }
}

const mapDispatch = dispatchEvent => ({
    getSearchData: (payload) => dispatchEvent({ type: 'SEARCH_TEAM', value: payload }),
    onFieldChange: (payload) => dispatchEvent({ type: 'ONFIELD_CHANGE', value: payload }),
    getchatpopupData: () => dispatchEvent({ type: 'GET_CHATPOPUP' }),

})

export default connect(mapStateToProps, mapDispatch)(AttributeDetail)