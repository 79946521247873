import React, { useEffect, useState } from "react";
import pro from "../../assets/images/profilePlaceholder/profilePlaceholder.png";
import { connect } from "react-redux";
import QuantitativeChart from "./GoalStats";
import ReactStars from "react-rating-stars-component";
import { useHistory } from "react-router-dom";
const ViewEmployeeDetail = (props) => {
  const history = useHistory();
  const [myAttributeLoading, setMyAttributeLoading] = useState(false);

  useEffect(() => {
    props?.location?.state?.id &&
      props.getEmployerTeamData(props?.location?.state?.id);
  }, [props?.location?.state?.id]);

  return (
    <React.Fragment>
      <section class="profiles-wrapper-main">
        <div class="container mt-3">
          <h3 style={{ textAlign: "center", justifyContent: "center" }}>
            View Detail of Employee
          </h3>
          <div class="profiles-wrapper">
            <div class="profile-comman-box">
              <div
                class="row"
                style={{ textAlign: "center", justifyContent: "center" }}
              >
                <div class="col-md-12 comman-width one">
                  <div
                    class="main-profiles position-relative"
                    style={{ maxWidth: "458px", margin: "0 auto" }}
                  >
                    <p
                      onClick={() =>
                        history.push(`/addemployee/${props.location.state.id}`)
                      }
                      className="edit-text"
                    >
                      Edit
                    </p>
                    <div class="profile-img">
                      <img
                        style={{
                          width: "68px",
                          height: "68px",
                          borderRadius: "50%",
                          border: "2px solid #2362A2",
                        }}
                        src={
                          props?.emplyrTeamDetailData?.employeeDetails
                            ?.profile_pic_url || pro
                        }
                        alt=""
                      />
                    </div>
                    <div class="row no-gutters">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "50rem",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <span>Employee Name :</span>
                          <span
                            style={{
                              width: "12rem",
                              wordBreak: "break-all",
                              textAlign: "left",
                            }}
                          >
                            <p>
                              {
                                props?.emplyrTeamDetailData?.employeeDetails
                                  ?.name
                              }
                            </p>
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <span>Mobile Number :</span>
                          <span
                            style={{
                              width: "12rem",
                              wordBreak: "break-all",
                              textAlign: "left",
                            }}
                          >
                            <p>
                              {props?.emplyrTeamDetailData?.employeeDetails
                                ?.country_code +
                                " " +
                                props?.emplyrTeamDetailData?.employeeDetails
                                  ?.phone_number}
                            </p>{" "}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <span>Email Address :</span>
                          <span
                            style={{
                              width: "12rem",
                              wordBreak: "break-all",
                              textAlign: "left",
                            }}
                          >
                            <p>
                              {
                                props?.emplyrTeamDetailData?.employeeDetails
                                  ?.email
                              }
                            </p>{" "}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <span>Department :</span>
                          <span
                            style={{
                              width: "12rem",
                              wordBreak: "break-all",
                              textAlign: "left",
                            }}
                          >
                            <p className="textwrap">
                              {
                                props?.emplyrTeamDetailData?.employeeDetails
                                  ?.department?.name
                              }
                            </p>
                          </span>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <span>Team Level :</span>
                          <span
                            style={{
                              width: "12rem",
                              wordBreak: "break-all",
                              textAlign: "left",
                            }}
                          >
                            <p>
                              {
                                props?.emplyrTeamDetailData?.employeeDetails
                                  ?.employee_rank.name
                              }
                            </p>{" "}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          {props?.emplyrTeamDetailData?.employeeDetails
                            ?.manager_team_member?.employee?.name ? (
                            <span>Manager :</span>
                          ) : (
                            ""
                          )}

                          <span
                            style={{
                              width: "12rem",
                              wordBreak: "break-all",
                              textAlign: "left",
                            }}
                          >
                            <p>
                              {
                                props?.emplyrTeamDetailData?.employeeDetails
                                  ?.manager_team_member?.employee?.name
                              }
                            </p>
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          {props?.emplyrTeamDetailData?.employeeDetails
                            ?.manager_team_member?.employee?.email ? (
                            <span>Manager Email Id :</span>
                          ) : (
                            ""
                          )}

                          <span
                            style={{
                              width: "12rem",
                              wordBreak: "break-all",
                              textAlign: "left",
                            }}
                          >
                            <p>
                              {
                                props?.emplyrTeamDetailData?.employeeDetails
                                  ?.manager_team_member?.employee?.email
                              }
                            </p>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <QuantitativeChart
        from={"teammember"}
        quantitativeDetail={props.emplyrTeamDetailData}
        deleteManager={props.DeleteManagerPrevGoal}
        getEmployerTeamData={props.getEmployerTeamData}
        employee_id={props?.location?.state?.id}
      />

      <section class="Qualitative-wrapper">
        <div class="container">
          <div class="Qualitative">
            <div
              class="heding"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent:
                  props?.emplyrTeamDetailData?.attributeRatings
                    ?.qualitativeMeasures?.length > 0
                    ? "space-between"
                    : "center",
                alignItems: "center",
                height: "10rem",
              }}
            >
              <div>
                <h2>Qualitative Measures</h2>
                <hr style={{ width: "100%" }} class="border-pink" />
              </div>
              {props?.emplyrTeamDetailData?.attributeRatings
                ?.qualitativeMeasures?.length > 0 && (
                <div
                  style={{ textAlign: "center", marginLeft: "2rem" }}
                  class="Consultation-button"
                >
                  <button
                    style={{ width: "10rem" }}
                    onClick={() =>
                      history.push({
                        pathname: "/attributeEmployerDetail",
                        state: {
                          quantitativeDetailEmployee:
                            props.emplyrTeamDetailData.attributeRatings,
                        },
                      })
                    }
                  >
                    View Details
                  </button>
                </div>
              )}
            </div>
            {props.emplyrTeamDetailData &&
            props.emplyrTeamDetailData.attributeRatings?.ratings &&
            props.emplyrTeamDetailData.attributeRatings.ratings.length > 0 ? (
              <div>
                <div class="Qualitative-details">
                  {props.emplyrTeamDetailData.attributeRatings
                    ? props.emplyrTeamDetailData.attributeRatings.ratings.map(
                        (item) => (
                          <div class="Qualitative-row">
                            <div class="row">
                              <div class="col-md-5 col-sm-4 col-12 Qualitative-name">
                                <p>{item.name}</p>
                              </div>
                              <div class="col-md-7">
                                <div class="Qualitative-star float-right">
                                  <ReactStars
                                    {...{
                                      color: "#C9C9C9",
                                      size: 35,
                                      value: item.rating,
                                      edit: false,
                                      activeColor: "#FF7500",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )
                    : null}
                </div>
              </div>
            ) : (
              <div style={{ color: "#FF7500" }} class="heding">
                <h4>No rating has been given</h4>
              </div>
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const emplyrTeamDetailData = state.teamReducer.emplyrTeamDetailData;
  const errorMsg = state.teamReducer.errorMsg;
  const totalTeamCount = state.teamReducer.totalTeamCount;
  const loading = state.globalReducer.loading;
  return {
    emplyrTeamDetailData,
    loading,
    errorMsg,
    totalTeamCount,
    employerloginData: state.loginReducer.employerloginData,
    teamEnergy: state.teamReducer.teamEnergy,
  };
};

const mapDispatch = (dispatchEvent) => ({
  getEmployerTeamData: (payload) =>
    dispatchEvent({ type: "GET_EMPLOYRTEAMDATA", value: payload }),
  DeleteManagerPrevGoal: (payload) =>
    dispatchEvent({ type: "DELETEMANAGERGOAL", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(ViewEmployeeDetail);
