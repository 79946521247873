import React, { useEffect, useState } from "react";
import Advisorsline from "../assets/images/Advisors-line.png";
import "../scss/customforall.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "../config";

const { BASE_API_URL } = config;

export default function Advisors() {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const token = localStorage.getItem("s_token");

  useEffect(() => {
    const getData = async () => {
      try {
        setLoader(true);
        const result = await axios(
          `${BASE_API_URL}web/getAdvisors`
        );
        setLoader(false);
        setData(result.data.body.data.rows);
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    };

    getData();
  }, []);
  return (
    <div>
      {!token && (
        <div class="tabbable-panel navbar-header">
          <div class="container">
            <ul class="nav nav-tabs ">
              <li>
                <Link to="/home" data-toggle="tab">
                  About Us{" "}
                </Link>
              </li>
              <li>
                <Link to="/founder" data-toggle="tab">
                  Founder{" "}
                </Link>
              </li>
              {!token && (
                <li>
                  <Link to="/pricing" data-toggle="tab">
                    Pricing{" "}
                  </Link>
                </li>
              )}
              <li>
                <Link to="/coaches" data-toggle="tab">
                  Coaches{" "}
                </Link>
              </li>
              <li>
                <Link to="/resources" data-toggle="tab">
                  Resources{" "}
                </Link>
              </li>
              <li>
                <Link to="/contactushome" data-toggle="tab">
                  Contact Us{" "}
                </Link>
              </li>
              <li class="active">
                <Link to="/advisors" data-toggle="tab">
                  Advisors{" "}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
      <section class="Coaches-wrapper bg-color  advisors-inline">
        <div class="container">
          <div class="Coaches comman-width-container">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-12">
                <div class="Coaches-info">
                  <div class="Coaches-head">
                    <h2>Advisory Board</h2>
                    <img alt="advisors" class="img-fluid" src={Advisorsline} />
                  </div>
                </div>
              </div>
            </div>

            <h3 className="advisors-h3">Coming Soon</h3>
          </div>
        </div>
      </section>
    </div>
  );
}
