import React, { useState, useEffect } from "react";
import { Prompt } from "react-router";
import { connect } from "react-redux";
import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CButton,
} from "@coreui/react";
import { useHistory } from "react-router";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import axios from "axios";

import camera from "../assets/images/camera.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import profile1 from "../assets/images/profile1.png";
import { getDate, updateProfileValidation } from "../utils/validation";
import { GET, POST, POSTIMAGE } from "../utils/helper";
import "../scss/customforall.scss";
import { UPLOADFILEAPI } from "../redux/saga/ApiConstant";
import profile from "../assets/images/my-profile.png";
import download from "../assets/images/download.png";
import PreviosProfileDropDown from "./PreviosProfileDropDown";
import ProfilePicture from "./UserProfileAvatar/ProfilePicture";
import MultiSelect from "./Common/MultiSelect";

const MyProfile = (props) => {
  const history = useHistory();
  const [active, setActive] = useState("Personal");
  const [name, setName] = useState(props.profileData.name);
  const [disable, setDisable] = useState(false);
  const [msg, showMsg] = useState(false);
  const [showLastEmployer, setLastEmployer] = useState(false);
  const [edit, setEdit] = useState(false);
  const [dateError, setDateError] = useState("");
  const [isShare, setIsShare] = useState(false);
  const [shareData, setShareData] = useState({
    to_email: "",
    subject: "",
    message: "",
  });
  const [rank, setRank] = useState([]);

  const formik = useFormik({
    initialValues: {
      first_name: props.profileData?.first_name || "",
      last_name: props.profileData?.last_name || "",
      phone_number: props.profileData?.phone_number || "",
      country_code: props.profileData?.country_code || "",
      current_designation: props.profileData?.current_designation || "",
      current_department_id: props.profileData?.current_department_id || "",
      employee_rank_id: props.profileData?.employee_rank_id || "",
      profile_pic_url: props.profileData?.profile_pic_url || "",
    },
    onSubmit: (values) => {
      updateProfile(values);
    },
    validationSchema: updateProfileValidation,
  });
  // const [cvdata, setCvdata] = useState({
  //   Personal_Address: "",
  //   Technical_skills: "",
  //   Qualifications: "",
  //   Educations: "",
  //   References: "",
  //   Previous_Employment_History: "",
  // });

  const [modal, setModal] = useState(false);
  // const [isOpen, setIsOpen] = useState(null);

  const name_len = name.split(" ").length;
  if (name_len > 2) {
    var [first, third, second] = name.split(" ");
    first = first + " " + third;
  } else {
    var [first, second] = name.split(" ");
    if (second == undefined) {
      second = "";
    }
  }

  useEffect(() => {
    props.getProfileData();
    props.getEmployerDepartmentList();

    const getRanks = async () => {
      const result = await GET("getEmployeeRankList");
      setRank(result?.body?.data?.rows);
    };
    getRanks();
  }, []);

  const showSucessToast = () => {
    toast.success(props.sucessMsg, {
      position: toast.POSITION.BOTTOM_CENTER,
      hideProgressBar: true,
    });
  };

  useEffect(() => {
    if (props.sucessMsg !== "") showSucessToast();
  }, [props.sucessMsg]);

  const toggleLastEmployerVisiblity = () => {
    setLastEmployer(!showLastEmployer);
  };

  const handleChange = (event) => {
    showMsg(true);

    if (event.target.name === "date_of_birth") {
      if (new Date(event.target.value) >= new Date().setHours(0, 0, 0, 0)) {
        setDateError("select valid date");
      } else {
        setDateError("");
      }
    }
    props.onFieldChange({
      name: "handleProfileChange",
      value: { [event.target.name]: event.target.value },
    });
  };

  // const onChangeFile = (event) => {
  //   event.preventDefault();
  //   if (event.target.files && event.target.files.length > 0) {
  //     showMsg(true);
  //     var file = event.target.files[0];
  //     props.onFieldChange({ name: "PROFILE_IMAGEFAIL", value: "" });
  //     const fileType = file.name;
  //     var re = /(\.jpg|\.jpeg|\.image|\.png|\.svg)$/i;
  //     if (!re.exec(fileType)) {
  //       props.onFieldChange({
  //         name: "PROFILE_IMAGEFAIL",
  //         value: "wrong file type selected",
  //       });

  //       alert("wrong file type selected");
  //     } else {
  //       const formData = new FormData();
  //       formData.append("file", file);
  //       formData.append("folderName", "employee_profile_pic");
  //       props.uploadProfile(formData);
  //     }
  //   }
  // };

  const handleImageUpload = () => {
    document.getElementById("fileInput").click();
  };

  const updateProfile = (values) => {
    showMsg(false);
    let postObj = { ...values };
    postObj.name = values.first_name + " " + values.last_name;
    delete postObj.first_name;
    delete postObj.last_name;
    props.updateProfileData({
      postObj,
      history: props.history,
      redirect: false,
    });
    setEdit(false);
  };
  const checkDate = () => {
    if (
      new Date(props.profileData.date_of_birth) >
      new Date().setHours(0, 0, 0, 0)
    ) {
      setDateError("select valid date");
      return false;
    }
    return true;
  };

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const handleShare = async () => {
    if (!shareData.to_email) {
      toast.error("Please enter email", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    if (!shareData.subject) {
      toast.error("Please enter Subject", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    if (!shareData.message) {
      toast.error("Please enter Message", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    if (!validateEmail(shareData.to_email)) {
      toast.error("Please enter a valid email", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    setModal(false);

    try {
      const result = await POST("shareEmployeeCV", shareData);
      if (result.status === 200) {
        setIsShare(false);

        toast.success("Email sent successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const showFile = async (data, id = "profile_pic_url") => {
    const bodyFormData = new FormData();
  

    if (data.length < 1) {
      return;
    }

    if (
      data[0].type !== "image/png" &&
      data[0].type !== "image/jpeg" &&
      data[0].type !== "image/svg+xml"
    ) {
      formik.setFieldError(id, "Only jpeg, png images are allowed");
      return;
    }
    bodyFormData.append("file", data[0]);
    bodyFormData.append("folderName", "employee_profile_pic");

    try {
      setDisable(true);
      const result = await POSTIMAGE(UPLOADFILEAPI, bodyFormData, 1);
      setDisable(false);

      formik.setFieldValue(id, result.body.data);
    } catch (error) {
      formik.setFieldError(id, error);
    }
  };

  return (
    <React.Fragment>
      <Prompt
        when={msg}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <section className="My-Profile-wrapper bg-color">
        <CModal
          show={modal}
          centered={true}
          backdrop={true}
          color="warning"
          onClose={() => {
            setModal(false);
          }}
        >
          <CModalHeader>
            <CModalTitle>Share CV</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <div className="width-100">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  value={shareData.to_email}
                  onChange={(e) => {
                    setShareData((state) => ({
                      ...state,
                      to_email: e.target.value,
                    }));
                  }}
                  placeholder="Receiver Email"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  value={shareData.subject}
                  onChange={(e) => {
                    setShareData((state) => ({
                      ...state,
                      subject: e.target.value,
                    }));
                  }}
                  placeholder="Subject"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  value={shareData.message}
                  onChange={(e) => {
                    setShareData((state) => ({
                      ...state,
                      message: e.target.value,
                    }));
                  }}
                  placeholder="Message"
                />
              </div>
            </div>
          </CModalBody>
          <CModalFooter style={{ display: "flex", flexDirection: "row" }}>
            <CButton
              onClick={handleShare}
              style={{
                width: "8rem",
                height: "3rem",
                backgroundColor: "#F56A14",
                color: "white",
              }}
            >
              Send Email
            </CButton>
            <CButton
              style={{
                width: "6rem",
                height: "3rem",
                backgroundColor: "#4B9CEB",
                color: "white",
              }}
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </CButton>
          </CModalFooter>
        </CModal>
        <div className="My-Profile">
          <h3>My Profile</h3>
          <hr className="border-pink" />
          <div className="tabbable-panel  table-pane-css">
            <ul>
              <li
                className={`${active === "Personal" ? "active" : ""}`}
                onClick={() => (checkDate() ? setActive("Personal") : null)}
              >
                <a href="#"></a>
              </li>
            </ul>
          </div>
          {active === "Personal" ? (
            <form onSubmit={formik.handleSubmit}>

              <ProfilePicture
                edit={edit}
                picture={formik?.values?.profile_pic_url || profile1}
                onChange={showFile} 
              />
              
              <div class="form-group">
                <div>
                  <label className="fontsize-11px">First Name</label>
                </div>

                <input
                  type="text"
                  className="form-control"
                  disabled={!edit}
                  value={formik.values.first_name}
                  name="first_name"
                  onChange={formik.handleChange}
                  placeholder="Your name"
                />
                {formik.errors.first_name ? (
                  <div className="errmsg">{formik.errors.first_name}</div>
                ) : null}
              </div>

              <div className="form-group">
                <div>
                  <label className="fontsize-11px">Last Name</label>
                </div>
                <input
                  type="text"
                  className="form-control"
                  disabled={!edit}
                  value={formik.values.last_name}
                  name="last_name"
                  onChange={formik.handleChange}
                  placeholder="Your name"
                />
                {formik.errors.last_name ? (
                  <div className="errmsg">{formik.errors.last_name}</div>
                ) : null}
              </div>

              <div className="form-group">
                <div className="fontsize-11px text-left mb-2">Phone Number</div>

                <PhoneInput
                  dropdownStyle={{ textAlign: "start" }}
                  inputStyle={{ width: "100%" }}
                  country={"us"}
                  disabled={!edit}
                  onChange={(val, data) => {
                    formik.setFieldValue("country_code", data.dialCode);
                    formik.setFieldValue(
                      "phone_number",
                      val.substring(data.dialCode.length, val.length)
                    );
                  }}
                  value={`${formik.values.country_code}${formik.values.phone_number}`}
                />
                {formik.errors.phone_number ? (
                  <div className="errmsg">{formik.errors.phone_number}</div>
                ) : null}
                {formik.errors.country_code ? (
                  <div className="errmsg">{formik.errors.country_code}</div>
                ) : null}
              </div>

              <div className="form-group">
                <div>
                  <label className="fontsize-11px">Email address</label>
                </div>
                <input
                  type="text"
                  disabled
                  className="form-control"
                  value={props.profileData?.email}
                  placeholder="Email address"
                />
              </div>
              <div className="form-group">
                <div>
                  <label className="fontsize-11px">Department</label>
                </div>
                <select
                  className="form-control custom-selec-wrap height-43px"
                  name="current_department_id"
                  onBlur={formik.handleBlur}
                  value={formik.values.current_department_id}
                  onChange={formik.handleChange}
                  disabled={!edit}
                >
                  <option value="">Department</option>
                  {props?.emplyrDepartmentData?.map((e) => (
                    <option value={e.id}>{e.name}</option>
                  ))}
                </select>
                {formik.errors.current_department_id ? (
                  <div className="errmsg">
                    {formik.errors.current_department_id}
                  </div>
                ) : null}
              </div>
              <div className="form-group">
                <div>
                  <label className="fontsize-11px">Current Designation</label>
                </div>
                <input
                  type="text"
                  disabled={!edit}
                  className="form-control"
                  value={formik.values.current_designation}
                  name="current_designation"
                  onChange={formik.handleChange}
                  placeholder="Title/position"
                />
                {formik.errors.current_designation ? (
                  <div className="errmsg">
                    {formik.errors.current_designation}
                  </div>
                ) : null}
              </div>

              <div className="form-group">
                <div>
                  <label className="fontsize-11px">Team Level</label>
                </div>
                <select
                  className="form-control custom-selec-wrap height-43px"
                  name="employee_rank_id"
                  onBlur={formik.handleBlur}
                  value={formik.values.employee_rank_id}
                  onChange={formik.handleChange}
                  disabled={!edit}
                >
                  <option value="">Employee Team Level</option>
                  {rank &&
                    rank.map((e) => <option value={e.id}>{e.name}</option>)}
                </select>
                {formik.errors.employee_rank_id ? (
                  <div className="errmsg">{formik.errors.employee_rank_id}</div>
                ) : null}
              </div>
              <div className="form-group">
                <div>
                  <label className="fontsize-11px">Date of Joining </label>
                </div>

                <input
                  type="text"
                  disabled
                  className="form-control"
                  value={getDate(props.profileData.current_date_of_joining)}
                  placeholder="Start Date"
                />
              </div>
              <div className="d-flex flex-column">
                <div>
                  <label className="fontsize-11px">Name of Manager</label>
                </div>
                <div className="w-100">
                  <MultiSelect
                    disabled
                    value={Array.isArray(props.profileData?.manager_team_members) ?
                      props.profileData?.manager_team_members?.map(item => {
                        return {
                          value:item.manager_id,
                          label:item.employee?.name,
                        }
                      }) : []}
                  />
                </div>
              </div>

              {props.loading ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <>
                  <div className="upadte-edit-btn profile-actions">
                    <button
                      className="Blue-Tango-button"
                      onClick={(event) => setEdit(!edit)}
                      type="button"
                    >
                      {edit ? "Cancel" : "Edit"}
                    </button>
                    {edit ? (
                      <button
                        className="Blue-Tango-button"
                        disabled={disable}
                        type="submit"
                      >
                        Update
                      </button>
                    ) : null}
                  </div>
                </>
              )}

              <div
                onClick={() => history.push("/managecv")}
                className="download-button"
              >
                <button>Manage Cv</button>
              </div>
              {isShare && (
                <div className="share-myprofile">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={shareData.to_email}
                      onChange={(e) => {
                        setShareData((state) => ({
                          ...state,
                          to_email: e.target.value,
                        }));
                      }}
                      placeholder="Receiver Email"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={shareData.subject}
                      onChange={(e) => {
                        setShareData((state) => ({
                          ...state,
                          subject: e.target.value,
                        }));
                      }}
                      placeholder="Subject"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={shareData.message}
                      onChange={(e) => {
                        setShareData((state) => ({
                          ...state,
                          message: e.target.value,
                        }));
                      }}
                      placeholder="Message"
                    />
                  </div>
                  <div className="upadte-edit-btn">
                    <button onClick={handleShare} className="Blue-Tango-button">
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </form>
          ) : active === "Employment" ? (
            <>
              {isShare && (
                <div className="share-myprofile">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={shareData.to_email}
                      onChange={(e) => {
                        setShareData((state) => ({
                          ...state,
                          to_email: e.target.value,
                        }));
                      }}
                      placeholder="Receiver Email"
                    />
                  </div>
                  <div className="form-group"></div>
                  <div className="form-group">
                    <textarea
                      maxLength={100}
                      onChange={(e) => {
                        setShareData((state) => ({
                          ...state,
                          message: e.target.value,
                        }));
                      }}
                      value={shareData.message}
                      className="form-control width-100"
                      rows="3"
                      placeholder="Messages"
                    ></textarea>
                  </div>
                  <div className="upadte-edit-btn">
                    <button onClick={handleShare} className="Blue-Tango-button">
                      Submit
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <form action="">
                <div className="form-group">
                  <label>Add accomplishments</label>
                  <textarea
                    disabled={edit}
                    maxLength="300"
                    rows="4"
                    className="form-control  form-control-myprofile"
                    value={props.profileData.accomplishments}
                    name="accomplishments"
                    onChange={(e) => handleChange(e)}
                    placeholder="Write here"
                  ></textarea>
                  <span className="textmsg  textmsg-myprofile">
                    {props.profileData.accomplishments?.length === undefined
                      ? 0
                      : props.profileData.accomplishments?.length}
                    /300{" "}
                  </span>
                </div>

                <div className="Sign-In-button">
                  {props.loading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <>
                      <div className="upadte-edit-btn">
                        <button
                          className="Blue-Tango-button"
                          onClick={(event) => updateProfile(event)}
                        >
                          {edit ? "Edit" : "Update"}
                        </button>
                      </div>
                    </>
                  )}

                  <div className="download-button">
                    <button>
                      Download CV
                      <img src={download} />
                    </button>
                  </div>
                  <div
                    onClick={() => {
                      setModal(true);
                    }}
                    className="download-button"
                  >
                    <button>Share CV</button>
                  </div>
                  {isShare && (
                    <div className="share-myprofile">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={shareData.to_email}
                          onChange={(e) => {
                            setShareData((state) => ({
                              ...state,
                              to_email: e.target.value,
                            }));
                          }}
                          placeholder="Receiver Email"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={shareData.subject}
                          onChange={(e) => {
                            setShareData((state) => ({
                              ...state,
                              subject: e.target.value,
                            }));
                          }}
                          placeholder="Subject"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={shareData.message}
                          onChange={(e) => {
                            setShareData((state) => ({
                              ...state,
                              message: e.target.value,
                            }));
                          }}
                          placeholder="Message"
                        />
                      </div>
                      <div className="upadte-edit-btn">
                        <button
                          onClick={handleShare}
                          className="Blue-Tango-button"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </>
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const profileData = state.profileReducer.profileData;
  const errorMsg = state.profileReducer.errorMsg;
  const imageError = state.profileReducer.imageError;
  const readProfileData = state.profileReducer.readProfileData;
  const loading = state.globalReducer.loading;
  const sucessMsg = state.profileReducer.sucessMsg;
  const emplyrDepartmentData = state.teamReducer.emplyrDepartmentData;

  return {
    profileData,
    loading,
    errorMsg,
    imageError,
    readProfileData,
    sucessMsg,
    emplyrDepartmentData,
  };
};

const mapDispatch = (dispatchEvent) => ({
  getProfileData: () => dispatchEvent({ type: "GET_PROFILEDATA" }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
  uploadProfile: (payload) =>
    dispatchEvent({ type: "UPLOAD_PROFILEPIC", value: payload }),
  updateProfileData: (payload) =>
    dispatchEvent({ type: "UPDATEPROFILEDATA", value: payload }),
  getEmployerDepartmentList: () =>
    dispatchEvent({ type: "GET_EMPLOYERDEPARTMENTLIST" }),
});

export default connect(mapStateToProps, mapDispatch)(MyProfile);
