import { call, put, apply } from "redux-saga/effects";
import * as Action from "../type.action";
import { POST, GET, RESETPOST } from "../../utils/helper";
import {
  ADD_QUALITATIVEMEASURMENT,
  GET_QUANTITATIVEMEASURMENTCOMMENT,
} from "./ApiConstant";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function* addManagerRating(action) {
  yield put({ type: Action.LOADING_SHOW });
  const { postObj, history } = action.value;
  try {
    const Data = yield call(RESETPOST, ADD_QUALITATIVEMEASURMENT, postObj);
    if (Data.status === 200) {
      yield put({
        type: Action.ADDMANAGERRATE_DATASUCCESS,
        msg: "Rating Added Successfully",
        data: Data.body.data,
      });
      toast.success(`Rating Added Successfully`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });

      yield put({ type: Action.LOADING_HIDE });
      yield apply(history, history.push, ["/team"]);
    } else {
      yield put({ type: Action.ADDMANAGERRATE_DATAFAIL, msg: Data.message });
      yield put({ type: Action.LOADING_HIDE });
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      yield put({
        type: Action.ADDMANAGERRATE_DATAFAIL,
        msg: error.response.data.message,
      });

      console.log("error.response", error.response);
    } else {
      yield put({
        type: Action.ADDMANAGERRATE_DATAFAIL,
        msg: "something went wrong!!",
      });
    }
    yield put({ type: Action.LOADING_HIDE });
  }
}

export function* getTeamRatingDetail(action) {
  yield put({ type: Action.LOADING_SHOW });
  try {
    const id = action.value;
    const url = `getQualitativeMeasurement?employee_id=${id}`;
    const Data = yield call(GET, url);
    if (Data.status === 200) {
      yield put({
        type: Action.RATEDETAIL_DATASUCCESS,
        data: Data.body.data[0],
        msg: "Success!!",
      });
      yield put({ type: Action.LOADING_HIDE });
    } else {
      yield put({ type: Action.TEAMDETAIL_DATAFAIL, data: Data.message });
      yield put({ type: Action.LOADING_HIDE });
    }
  } catch (error) {
    yield put({
      type: Action.TEAMDETAIL_DATAFAIL,
      data: "something went wrong",
    });
    yield put({ type: Action.LOADING_HIDE });
  }
}

export function* getQualitativePopData(action) {
  yield put({ type: Action.LOADING_SHOW });
  try {
    const url = GET_QUANTITATIVEMEASURMENTCOMMENT;
    const Data = yield call(GET, url);

    if (Data.status === 200) {
      yield put({
        type: Action.RATINGPOPUP_DATASUCCESS,
        data: Data.body.data,
        msg: "Success!!",
      });
      yield put({ type: Action.LOADING_HIDE });
    } else {
      yield put({ type: Action.ADDMANAGERRATE_DATAFAIL, data: Data.message });
      yield put({ type: Action.LOADING_HIDE });
    }
  } catch (error) {
    yield put({
      type: Action.ADDMANAGERRATE_DATAFAIL,
      data: "something went wrong",
    });
    yield put({ type: Action.LOADING_HIDE });
  }
}
