import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import banner from "../../assets/images/banner.png";
import { POST } from "../../utils/helper";
import AttributeDropDown from "./AttributeDropDown";
import { attributesValidation } from "../../utils/validation";
// import delete1 from "../../assets/images/delete1.png";

import { Form, Formik } from "formik";

const AddAttributes = (props) => {
  const [isOpen, setIsOpen] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const editMode = location.state?.attribute != null;
  const attribute = location.state?.attribute;
  const employees = location.state?.employees;
  const isEmployer = props.loginRole == 1;
  const team = isEmployer ? props.employerTeamList : props.teamList;

  const initialValues = {
    name: attribute?.name || "",
    desc: attribute?.comment || "",
    guidance: attribute?.guidance || "",
    employees: employees?.map((item) => item?.value) || [],
    isNewQualitativeMeasurement: false,
  };

  const onSubmit = async (values) => {
    let attributeData = values;
    try {
      if (editMode) {
        delete attributeData.name;
        delete attributeData.guidance;
        attributeData.id = attribute.id;
      } else if (attributeData.name == "+ New target") {
        attributeData.name = attributeData.other;
        attributeData.isNewQualitativeMeasurement = true;
        delete attributeData.other;
      }
      const res = await POST("addAttributes", attributeData);

      if (res.status === 200) {
        history.push("/myattributes");
        toast.success("Attribute added successfully!", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    if (isEmployer) props.getEmployerTeamList();
    else props.getTeamList();
  }, []);

  return (
    <React.Fragment>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={attributesValidation}
        enableReinitialize
      >
        {(formik) => (
          <Form>
            <div
              style={{ minHeight: "80vh" }}
              className="Manager-Goal-Look-wrapper bg-color"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="container"
              >
                <div className="Manager-Goal-Look comman-width-container">
                  <div className="banner-img">
                    <img className="img-fluid" alt="banner" src={banner} />
                  </div>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      {/* {isOpen !== outerIndex && (
                        <div
                          className="form-group"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            onClick={() => {
                              setIsOpen(outerIndex);
                            }}
                            className="row  goalButton"
                            style={{
                              background: false ? "#ff7500" : "",
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: "35%",
                                left: "4rem",
                              }}
                            >
                              {!attribute.name
                                ? `Attribute ${outerIndex + 1}`
                                : attribute.name}
                            </div>
                            {attributes.length > 1 && (
                              <img
                                style={{
                                  position: "absolute",
                                  top: "35%",
                                  right: "4rem",
                                }}
                                src={delete1}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (attributes.length > 1) {
                                    remove(outerIndex);
                                  }
                                }}
                                alt="deleteicon"
                              />
                            )}

                            <i
                              style={{
                                position: "absolute",
                                top: "35%",
                                right: "2rem",
                              }}
                              className="fa fa-angle-down"
                            ></i>
                          </div>
                        </div>
                      )} */}
                      <AttributeDropDown
                        setIsOpen={setIsOpen}
                        isEdit={editMode}
                        selectedMembers={employees}
                        formik={formik}
                        outerIndex={0}
                        title={formik?.values?.name}
                        teamList={team}
                      />
                    </div>
                    {/* <button
                      style={{ width: "10rem" }}
                      onClick={() => {
                        push({
                          name: "",
                          desc: "",
                          guidance: "",
                          other: "",
                        });
                        setIsOpen(attributes.length);
                      }}
                    >
                      Add Attribute
                    </button> */}
                  </div>
                </div>
                <div className="btn-blue">
                  <button
                    className="assign-goal"
                    type="submit"
                    style={{ width: "12rem", margin: "1rem 0 2rem 0" }}
                  >
                    {editMode ? "Update" : "Submit"} Attribute
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const loading = state.globalReducer.loading;
  const teamList = state.teamReducer.teamList;
  const employerTeamList = state.teamReducer.EmployerteamList;
  const loginRole = state.loginReducer.loginRole;
  return {
    loading,
    teamList,
    employerTeamList,
    loginRole
  };
};

const mapDispatch = (dispatchEvent) => ({
  getEmployerTeamList: (payload) =>
    dispatchEvent({ type: "GET_EMPLOYERTEAMLIST", value: payload }),
  getTeamList: () => dispatchEvent({ type: "GET_TEAMLIST" }),
  getSearchData: (payload) =>
    dispatchEvent({ type: "SEARCH_TEAM", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(AddAttributes);
