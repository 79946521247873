import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import banner from "../assets/images/banner.png";
import { GET, putAPI, getGoalPercentage } from "../utils/helper";
import GoalDetailDropDown from "./GoalDetailDropDown";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ModalBody, Modal } from "reactstrap";
import cross from "../assets/images/cross.png";
import Bravobackground from "../assets/images/bravobackground.png";
import Bravonote from "../assets/images/bravonote.png";

const EmployeeGoals = () => {
  const [goal, setGoal] = useState([]);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(location?.state?.goal_id || null);
  const history = useHistory();
  const [loading, setLoading] = useState(null);
  const [primaryGoals, setPrimaryGoals] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [showprimary, setShowPrimary] = useState(false);
  const [modal, setModal] = useState({
    isOpen: false,
  });
  const getData = async () => {
    try {
      setLoading(true);
      const res = await GET("viewGoalAsEmployee");
      setLoading(false);
      setGoal(res.body.data.rows);
      let isPrimary = [];
      res.body.data.rows.forEach((element) => {
        if (element.is_primary === 1) {
          isPrimary.push(element.id);
        }
      });

      setPrimaryGoals(isPrimary);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, [refresh]);

  const handleHistoryGoals = (goal) => {
    history.push(`/goalssubmissionhistory/${goal.team_goal.id}`);
  };

  const handleSubmitPrimaryGoals = async () => {
    if (primaryGoals.length !== 4) {
      toast.error("you must select 4 primary goals", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
      return;
    }
    let data = [];
    primaryGoals.forEach((goal) => {
      data.push({
        team_goal_assign_id: goal,
        is_primary: 1,
      });
    });

    try {
      const results = await putAPI(`markGoalsAsPrimary`, {
        goals: data,
      });

      setShowPrimary(false);

      if (results.status === 200) {
        setRefresh(!refresh);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div
        style={{ minHeight: "80vh" }}
        class="Manager-Goal-Look-wrapper bg-color"
      >
        <Modal
          isOpen={modal.isOpen}
          onClosed={() => {
            setModal({ isOpen: false });
          }}
          className="goalsucessmodal "
        >
          <ModalBody>
            <div
              style={{
                borderRadius: "1rem",
                width: "373px",
                height: "200px",
                position: "relative",
                backgroundImage: `url(${Bravobackground})`,
              }}
            >
              <img
                src={cross}
                className="cross  employee-goal-cross-inline"
                alt="cross-new"
                onClick={() => {
                  setModal({ isOpen: false });
                }}
              />
              <img
                alt="success"
                className="employee-goal-inline"
                src={Bravonote}
              />
              <p className="employee-goal-p">Bravo</p>
              <div className="employee-goal-content">
                You have completed your goal dashboard will be updated when your
                comments are approved by your manager.
              </div>
              <p
                onClick={() => {
                  setModal({ isOpen: false });
                }}
                className="employee-goal-content-div"
              >
                Go & Work On Other Goals
              </p>
            </div>
          </ModalBody>
        </Modal>
        <div class="container  container-employee-goal-div">
          <div class="Manager-Goal-Look comman-width-container">
            <div class="banner-img">
              <img alt="" class="img-fluid" src={banner} />
            </div>
            <br />

            {!loading ? (
              goal && goal.length > 0 ? (
                goal
                  .sort((first, second) => {
                    const { team_goal_assign_completion_by_employees } = first;
                    const team_goal = first?.team_goal;

                    let isPending =
                      !team_goal_assign_completion_by_employees ||
                      team_goal_assign_completion_by_employees.length > 0
                        ? true
                        : false;
                    let isPendingValue =
                      team_goal_assign_completion_by_employees &&
                      team_goal_assign_completion_by_employees[0] &&
                      team_goal_assign_completion_by_employees[0]
                        .complete_measure &&
                      team_goal_assign_completion_by_employees[0]
                        .complete_measure != ""
                        ? team_goal_assign_completion_by_employees[0]
                            .complete_measure
                        : "";
                    const total_complete_measure =
                      team_goal_assign_completion_by_employees &&
                      team_goal_assign_completion_by_employees[0] &&
                      team_goal_assign_completion_by_employees[0]
                        .total_complete_measure &&
                      team_goal_assign_completion_by_employees[0]
                        .total_complete_measure != ""
                        ? team_goal_assign_completion_by_employees[0]
                            .total_complete_measure
                        : "";

                    let goalCompletionPercentage = isPending
                      ? getGoalPercentage(
                          Number(isPendingValue) +
                            Number(total_complete_measure),
                          Number(team_goal?.enter_measure)
                        )
                      : getGoalPercentage(
                          Number(first?.complete_measure),
                          Number(team_goal?.enter_measure)
                        );

                    const {
                      team_goal_assign_completion_by_employees: team_goal_assign_completion_by_employees_second,
                    } = second;
                    const team_goal_second = second?.team_goal;

                    let isPending_second =
                      !team_goal_assign_completion_by_employees_second ||
                      team_goal_assign_completion_by_employees_second.length > 0
                        ? true
                        : false;
                    let isPendingValue_second =
                      team_goal_assign_completion_by_employees_second &&
                      team_goal_assign_completion_by_employees_second[0] &&
                      team_goal_assign_completion_by_employees_second[0]
                        .complete_measure &&
                      team_goal_assign_completion_by_employees_second[0]
                        .complete_measure != ""
                        ? team_goal_assign_completion_by_employees_second[0]
                            .complete_measure
                        : "";
                    const total_complete_measure_second =
                      team_goal_assign_completion_by_employees_second &&
                      team_goal_assign_completion_by_employees_second[0] &&
                      team_goal_assign_completion_by_employees_second[0]
                        .total_complete_measure &&
                      team_goal_assign_completion_by_employees_second[0]
                        .total_complete_measure != ""
                        ? team_goal_assign_completion_by_employees_second[0]
                            .total_complete_measure
                        : "";

                    let goalCompletionPercentage_second = isPending_second
                      ? getGoalPercentage(
                          Number(isPendingValue_second) +
                            Number(total_complete_measure_second),
                          Number(team_goal_second?.enter_measure)
                        )
                      : getGoalPercentage(
                          Number(second?.complete_measure),
                          Number(team_goal_second?.enter_measure)
                        );

                    console.log(
                      typeof goalCompletionPercentage,
                      goalCompletionPercentage_second,
                      "first"
                    );

                    if (
                      Number(goalCompletionPercentage) >
                      Number(goalCompletionPercentage_second)
                    ) {
                      return -1;
                    } else {
                      return 1;
                    }
                  })
                  .map((goal, index) => {
                    const {
                      team_goal_assign_completion_by_employees,
                      measure,
                      complete_measure,
                    } = goal;
                    const team_goal = goal?.team_goal;

                    let isPending =
                      !team_goal_assign_completion_by_employees ||
                      team_goal_assign_completion_by_employees.length > 0
                        ? true
                        : false;
                    let isPendingValue =
                      team_goal_assign_completion_by_employees &&
                      team_goal_assign_completion_by_employees[0] &&
                      team_goal_assign_completion_by_employees[0]
                        .complete_measure &&
                      team_goal_assign_completion_by_employees[0]
                        .complete_measure != ""
                        ? team_goal_assign_completion_by_employees[0]
                            .complete_measure
                        : "";
                    const total_complete_measure =
                      team_goal_assign_completion_by_employees &&
                      team_goal_assign_completion_by_employees[0] &&
                      team_goal_assign_completion_by_employees[0]
                        .total_complete_measure &&
                      team_goal_assign_completion_by_employees[0]
                        .total_complete_measure != ""
                        ? team_goal_assign_completion_by_employees[0]
                            .total_complete_measure
                        : "";

                    let goalCompletionPercentage = isPending
                      ? getGoalPercentage(
                          Number(isPendingValue) +
                            Number(total_complete_measure),
                          Number(measure)
                        )
                      : getGoalPercentage(
                          Number(complete_measure),
                          Number(measure)
                        );

                    return (
                      <div>
                        {isOpen !== goal?.goal_id && (
                          <div key={goal.id} class="form-group  Cmodal-footer">
                            {showprimary && (
                              <input
                                className="employee-goal-input"
                                checked={primaryGoals.includes(goal.id)}
                                defaultChecked={goal.is_primary}
                                onChange={(e) => {
                                  if (primaryGoals.includes(goal.id)) {
                                    setPrimaryGoals((state) => {
                                      let newState = state.filter((item) => {
                                        return item !== goal.id;
                                      });
                                      return [...newState];
                                    });
                                  } else {
                                    if (primaryGoals.length === 4) {
                                      toast.error(
                                        "you can select max  4 primary goals",
                                        {
                                          position:
                                            toast.POSITION.BOTTOM_CENTER,
                                          autoClose: 5000,
                                        }
                                      );
                                      return;
                                    }
                                    setPrimaryGoals((state) => [
                                      ...state,
                                      goal.id,
                                    ]);
                                  }
                                }}
                                type="checkbox"
                              />
                            )}

                            <div
                              onClick={() => {
                                setIsOpen(goal.goal_id);
                              }}
                              className="row cursor-pointer goalButton"
                              style={{
                                background: false ? "#ff7500" : "",
                                position: "relative",
                              }}
                            >
                              <div className="employee-goal-div3">
                                {goalCompletionPercentage}%
                              </div>

                              <div className="employee-goal-div2">
                                <div
                                  style={{
                                    height: "100%",
                                    width:
                                      goalCompletionPercentage < 100
                                        ? `${goalCompletionPercentage}%`
                                        : "100%",
                                    background:
                                      "linear-gradient(90deg, #2786E2 0%, #FA933C 99.67%)",
                                    borderBottomRightRadius: "13px",
                                    borderTopLeftRadius: "13px",
                                  }}
                                ></div>
                              </div>

                              <div
                                style={{
                                  position: "absolute",
                                  top: "35%",
                                  left: "2rem",
                                }}
                              >
                                {goal.team_goal.title}
                              </div>
                              <i
                                style={{
                                  position: "absolute",
                                  top: "35%",
                                  right: "2rem",
                                }}
                                class="fa fa-angle-down"
                              ></i>
                            </div>
                          </div>
                        )}

                        {isOpen === goal?.goal_id && (
                          <GoalDetailDropDown
                            handleHistoryGoals={handleHistoryGoals}
                            getData={getData}
                            goal={goal}
                            setIsOpen={setIsOpen}
                            setModal={setModal}
                          />
                        )}
                      </div>
                    );
                  })
              ) : (
                <div className="employee-goal-no-goals-found">
                  <h4>No Goals Found</h4>
                </div>
              )
            ) : (
              <div
                style={{ marginTop: "10rem" }}
                class="spinner-border text-primary"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            )}
          </div>
          {goal && goal.length > 0 && !loading && showprimary ? (
            <div className="employee-goal-div">
              <button
                className="employee-goal-button"
                onClick={handleSubmitPrimaryGoals}
              >
                Submit
              </button>
              <div class="btn-blue">
                <button
                  className="employee-goal-button"
                  onClick={() => setShowPrimary(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const loading = state.globalReducer.loading;
  return {
    loading,
  };
};

const mapDispatch = (dispatchEvent) => ({
  getSearchData: (payload) =>
    dispatchEvent({ type: "SEARCH_TEAM", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(EmployeeGoals);
