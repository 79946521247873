const initialState = {
  isLoggedIn: false,
  username: "",
  email: "",
  profileImage: "",
  user_id: "",
  password: "",
  confirmPassword: "",
  action: "Signup",
  msg: "",
  userDetails: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default userReducer;
