import { call, put } from 'redux-saga/effects'
import * as Action from '../type.action'
import { GET } from '../../utils/helper'
import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify"
import { PARTICULAR_ACHIEVEMENT } from "./ApiConstant";
import history from "../../history";


export function* getParticularAchievement(action) {

    try {
        if (action.showLoading) {
            yield put({ type: Action.LOADING_SHOW })
        }

        const Data = yield call(GET, PARTICULAR_ACHIEVEMENT + `/${action.id}`);
 

        if (Data.status === 200) {

            yield put({ type: Action.PARTICULAR_ACHIEVEMENT_SUCCESS, data: Data.body.data.achievement, msg: 'Success!!' });
            yield put({ type: Action.LOADING_HIDE })


        } else {
            toast.error('This post has been removed', {
                position: toast.POSITION.BOTTOM_CENTER,
            })
            history.goBack();
            yield put({ type: Action.PARTICULAR_ACHIEVEMENT_FAIL, data: Data.message })
            yield put({ type: Action.LOADING_HIDE })

        }

    } catch (error) {

        if (error.response && error.response.data && error.response.data.message) {
            yield put({ type: Action.PARTICULAR_ACHIEVEMENT_FAIL, data: error.response.data.message })

        } else {
            yield put({ type: Action.PARTICULAR_ACHIEVEMENT_FAIL, data: 'something went wrong' })
        }
        yield put({ type: Action.LOADING_HIDE })

    }
}