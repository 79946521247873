
import firebaseIs from '../../Firebase';
import firebase from 'firebase';
import configureStore from "../../store"
let messgaesListner = null;



export function chatNodeExist(node) {

    const executor = async (resolve, reject) => {
        try {
            const collection = firebase.firestore().collection('chats_dev');
            const ref = collection.doc(node?.toString());
            const snap = await ref.get();
            if (!snap.exists) {
                resolve(false);
                return;
            }
            const chat = snap.data();
            resolve(true);
        } catch (error) {
            reject(error);
        }
    };
    return new Promise(executor);
}

export function createChatNode(node, members) {
    const executor = async (resolve, reject) => {
        try {
            const collection = firebase.firestore().collection('chats_dev');
            const ref = collection.doc(node?.toString());
            const chatRef = await ref.set({
                id: node,
                members: members
            });
            resolve("Done");
        } catch (error) {
            reject(error);
        }
    };
    return new Promise(executor);
}

export const getFCMToken = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const collection = firebase.firestore().collection('users');
            const ref = collection.doc(id?.toString());
            const snapshot = await ref.get();
            let token = "";
            if (snapshot.exists) {
                const user = snapshot.data();
                if (user && user.fcmToken) {
                    token = user.fcmToken;
                }
            }
            resolve(token);
        } catch (error) {
            reject(error);
        }
    });
};
export function addMessage(node, msgObj) {

    const executor = async (resolve, reject) => {
        try {
            const collection = firebase.firestore().collection('chats_dev');
            const chatRef = collection.doc(node?.toString());
            const messagesCollection = chatRef.collection('messages');
            const addMsg = await messagesCollection.add(msgObj);
            resolve(addMsg);
        } catch (error) {
            reject(error);
        }
    };
    return new Promise(executor);
}

export const getMessagesOfNode = (chatNode, limit, lastDeletedTime) => {
    const executor = async (resolve, reject) => {



        var convertedTimestamp = new Date(lastDeletedTime);
        try {
            const chatCollection = firebase.firestore().collection('chats_dev');
            const chatRef = chatCollection.doc(chatNode?.toString());

            const messageRef = chatRef.collection('messages').where("timestamp", ">=", String(convertedTimestamp.getTime()));
            const query = messageRef.orderBy('timestamp', "desc").limit(limit);
            const snapshot = await query.get();
            if (snapshot.empty) {
                resolve([]);
                return;
            }
            let allMessages = []
            snapshot.forEach(element => {
                const data = element.data();
                const id = element.id;
                if (element) {
                    allMessages.push({ ...data, id });
                }
            })
            resolve(allMessages);
        } catch (error) {
            reject(error);
        }
    };
    return new Promise(executor);
};

export const clearMessageListener = () => {
    if (messgaesListner != null) {
        messgaesListner();
        messgaesListner = null;
    }
};


export const getMessageUpdate = (chatNode, callback = () => { }, onDataLoad, lastDeletedTime) => {
   
    const executor = async (resolve, reject) => {
        try {
            const chatCollection = firebase.firestore().collection('chats_dev');
            const nodeRef = chatCollection.doc(chatNode?.toString());
            const messageRef = nodeRef.collection('messages');
            if (messgaesListner != null) {
                messgaesListner();
                messgaesListner = null;
            }

            const snap = await nodeRef.get();
            if (!snap.exists) {
                resolve();
            }

            setTimeout(() => {
                const query = messageRef.orderBy('timestamp', 'desc').limit(1);
                query.get().then(snap => {
                    if (onDataLoad) {
                        onDataLoad();
                    }
                    if (snap.empty) {
                        callback([]);
                    }
                }).catch(err => { });
                messgaesListner = query.onSnapshot(snapshot => {
                    let allMessages = []
                    try {
                        if (snapshot.empty) {
                            resolve([]);
                        } else {
                            snapshot.forEach(doc => {
                                allMessages.push({ ...doc.data(), id: doc.id });
                            });
                            callback(allMessages);
                            resolve("Done");
                        }
                    } catch (err) {
                        console.log("Inside getMessageUpdate2", err);
                        reject(err)
                    }
                });
            }, 200)
        }
        catch (error) {
            console.log("Inside getMessageUpdate1", error);
            reject(error);
        }
    };
    return new Promise(executor);
}

export const getMessageForRoom = (chatNode) => {
    const executor = async (resolve, reject) => {
        try {
            const chatCollection = firebase.firestore().collection('chats_dev');
            const nodeRef = chatCollection.doc(chatNode?.toString());
            const messageRef = nodeRef.collection('messages');
            const query = messageRef
            const snapshot = await query.get();
            if (snapshot.empty) {
                resolve([]);
            } else {
                let allMessages = []
                snapshot.forEach(doc => {
                    allMessages.push({ ...doc.data(), id: doc.id });
                });
                resolve(allMessages);
            }
        } catch (error) {
            reject(error);
        }
    };
    return new Promise(executor);
}
