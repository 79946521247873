import React from "react";
import { useHistory } from "react-router";
import { useState, useEffect } from "react";
import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CButton,
} from "@coreui/react";
import { toast } from "react-toastify";
import { GET, POST, checkIsValueExit, getErrorMessage } from "../utils/helper";
import Typography from "@mui/material/Typography";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useFormik } from "formik";

import "../scss/customforall.scss";
import { manageCvValidation } from "./../utils/validation";
import CvSectionDetails from "./CvSectionDetails";
import { isEmpty } from "lodash";

function Managecv() {
  const history = useHistory();
  const currentDay = moment().format("MM-DD-YYYY");
  const nextDayOfCurrent = moment().add(1, "day").format("MM-DD-YYYY");
  const initialEducation = {
    id: 0,
    institute_name: "",
    degree_type: "",
    specialisation: "",
    from: currentDay,
    to: nextDayOfCurrent,
    in_present: false,
    isOpen: false,
  };
  const initialEmploymentHistory = {
    id: 0,
    company_name: "",
    department_name: null,
    designation: "",
    job_designation: null,
    from: currentDay,
    to: nextDayOfCurrent,
    in_present: false,
    isOpen: false,
  };
  const initialReference = { id: 0, name: "", email: "", isOpen: false };

  const [loading, setLoading] = useState(false);
  const [docsFormat, setDocsFormat] = useState(1);
  let [shareData, setShareData] = useState({
    to_email: "",
    subject: "",
    message: "",
    type: docsFormat,
  });
  const [modal, setModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      address: "",
      technical_skills: "",
      educations: [initialEducation],
      previous_employment_history: [initialEmploymentHistory],
      references: [initialReference],
    },
    validationSchema: manageCvValidation,
    onSubmit: async (values) => {
      let updatedEdu = values.educations.map((item) => {
        if (item.in_present) delete item.to;
        delete item.isOpen;
        delete item.in_present;
        return item;
      });

      let updatedRef = values.references.map((item) => {
        delete item.isOpen;
        return item;
      });

      let updatedPrevEmpl = values.previous_employment_history.map((item) => {
        if (item.in_present) delete item.to;
        if (isEmpty(item.department_name)) delete item.department_name;
        if (isEmpty(item.job_designation)) delete item.job_designation;
        delete item.isOpen;
        delete item.in_present;
        return item;
      });

      try {
        let result = await POST("updateProfile", {
          ...values,
          previous_employment_history: updatedPrevEmpl,
          references: updatedRef,
          educations: updatedEdu,
        });
        if (result.data.status === 200) {
          toast.success("Saved  successfully.", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          history.push("/myprofile");
        }
      } catch (err) {
        if (err?.response?.data?.message) {
          toast.error(
            " Input field cannot be empty, type [N/A] in case no data.",
            {
              position: toast.POSITION.BOTTOM_CENTER,
            }
          );
        }
      }
    },
  });

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  useEffect(() => {
    const getMyProfile = async () => {
      try {
        setLoading(true);
        const res = await GET(`getMyProfile`);
        const data = res.body.data;

        formik.setFieldValue("address", data?.address);
        formik.setFieldValue("technical_skills", data?.technical_skills);

        if (data?.references.length > 0) {
          formik.setFieldValue(
            "references",
            data.references?.map((edu) => {
              edu.isOpen = false;
              return edu;
            })
          );
        }

        if (data?.educations.length > 0) {
          formik.setFieldValue(
            "educations",
            data.educations?.map((edu) => {
              edu.isOpen = false;
              edu.in_present = checkIsValueExit(edu?.to);
              return edu;
            })
          );
        }

        if (data?.previous_employment_history.length > 0) {
          formik.setFieldValue(
            "previous_employment_history",
            data.previous_employment_history?.map((edu) => {
              edu.isOpen = false;
              edu.in_present = checkIsValueExit(edu?.to);
              return edu;
            })
          );
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    getMyProfile();
  }, []);

  useEffect(() => {
    shareData = { ...shareData, type: parseInt(docsFormat) };
  }, [shareData]);

  const handleShare = async () => {
    if (!shareData.to_email) {
      toast.error("Please enter email.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    if (!shareData.subject) {
      toast.error("Please enter subject.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    if (!shareData.message) {
      toast.error("Please enter message.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    if (!validateEmail(shareData.to_email)) {
      toast.error("Please enter a valid email.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    setModal(false);

    try {
      const result = await POST("shareEmployeeCV", shareData);
      if (result.status === 200) {
        toast.success("Email sent successfully.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const handleDocs = (e) => {
    setDocsFormat(e.target.value);
    shareData = { ...shareData, type: parseInt(e.target.value) };
  };

  const checkIsCvCompleted = () => {
    if (!formik.isValid) {
      toast.error(
        "Please complete your CV to be able to download or share your CV",
        { toastId: "cv-share-download" }
      );
      return false;
    }
    return true;
  };

  const handleDownload = async () => {
    if (!checkIsCvCompleted()) return;

    try {
      const result = await GET(`getEmployeeCV?type=${docsFormat}`);
      if (result.status === 200) {
        const element = document.createElement("a");
        element.href = result.body?.data;
        element.click();
        toast.success("Download successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else if (result.status) {
        const err = getErrorMessage(result);
        toast.error(err, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } catch (error) {
      const err = getErrorMessage(error);
      toast.error(err, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  return (
    <section className="My-Profile-wrapper bg-color">
      <CModal
        show={modal}
        centered={true}
        backdrop={true}
        color="warning"
        onClose={() => {
          setModal(false);
        }}
      >
        <CModalHeader>
          <CModalTitle>Share CV</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className="width-100">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                value={shareData.to_email}
                onChange={(e) => {
                  setShareData((state) => ({
                    ...state,
                    to_email: e.target.value,
                  }));
                }}
                placeholder="Receiver Email"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                value={shareData.subject}
                onChange={(e) => {
                  setShareData((state) => ({
                    ...state,
                    subject: e.target.value,
                  }));
                }}
                placeholder="Subject"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                value={shareData.message}
                onChange={(e) => {
                  setShareData((state) => ({
                    ...state,
                    message: e.target.value,
                  }));
                }}
                placeholder="Message"
              />
            </div>
          </div>
        </CModalBody>
        <CModalFooter className="Cmodal-footer">
          <CButton className="cbutton-myprofile-email" onClick={handleShare}>
            Send Email
          </CButton>
          <CButton
            className="cmodal-myprofile-cancel"
            onClick={() => {
              setModal(false);
            }}
          >
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>

      <div className="My-Profile">
        <div className="tabbable-panel table-pane-css">
          <div className="form-group  form-grp-myprofile">
            <div>
              <h3>Manage cv</h3>
            </div>
          </div>
          {loading ? (
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : null}
          <form onSubmit={formik.handleSubmit}>
            <div>
              <label className="margin-left-0px">Personal Address</label>
              <input
                className="form-control textarea-myprofilelist"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                placeholder="Personal Address"
              />
              {formik.touched.address && formik.errors.address ? (
                <div className="errmsg">{formik.errors.address}</div>
              ) : null}
            </div>
            <div>
              <label className="margin-left-0px">Technical Skills</label>
              <input
                className="form-control textarea-myprofilelist"
                name="technical_skills"
                value={formik.values.technical_skills}
                onChange={formik.handleChange}
                placeholder="Technical Skills"
              />
              {formik.touched.technical_skills &&
              formik.errors.technical_skills ? (
                <div className="errmsg">{formik.errors.technical_skills}</div>
              ) : null}
            </div>

            <div className="myprofile-sub-details">
              <div>
                <label>Education Details</label>
              </div>
              <div>
                <CvSectionDetails
                  name="educations"
                  initialDetailsObject={initialEducation}
                  detailsList={formik.values.educations}
                  setDetailsListState={(newValue) => {
                    formik.setFieldValue("educations", newValue);
                  }}
                  errors={formik.errors.educations}
                  touched={formik.touched.educations}
                >
                  {({ detailsItem, index }) => (
                    <Typography className="typography-inline-main">
                      <label className="details-1-label">Institute Name</label>
                      <input
                        className="form-control detail-1-inputs"
                        placeholder="Institute name"
                        id={`educations[${index}].institute_name`}
                        name={`educations[${index}].institute_name`}
                        value={detailsItem?.institute_name}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.educations?.[index]?.institute_name &&
                      formik.errors.educations?.[index]?.institute_name ? (
                        <div className="errmsg">
                          {formik.errors.educations[index].institute_name}
                        </div>
                      ) : null}
                      <label className="details-1-label">Degree Type</label>
                      <input
                        className="form-control detail-1-inputs"
                        placeholder="Degree type"
                        id={`educations[${index}].degree_type`}
                        name={`educations[${index}].degree_type`}
                        value={detailsItem?.degree_type}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.educations?.[index]?.degree_type &&
                      formik.errors.educations?.[index]?.degree_type ? (
                        <div className="errmsg">
                          {formik.errors.educations[index].degree_type}
                        </div>
                      ) : null}
                      <label className="details-1-label">Specialisation</label>
                      <input
                        className="form-control  detail-1-inputs"
                        placeholder="Specialisation"
                        id={`educations[${index}].specialisation`}
                        name={`educations[${index}].specialisation`}
                        value={detailsItem?.specialisation}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.educations?.[index]?.specialisation &&
                      formik.errors.educations?.[index]?.specialisation ? (
                        <div className="errmsg">
                          {formik.errors.educations[index].specialisation}
                        </div>
                      ) : null}
                      <div className="myprofilelist-calender-inline">
                        <div>
                          <label className="details-1-label">From</label>
                          <div className="reactdatepicker-inline">
                            <ReactDatePicker
                              id={`educations[${index}].from`}
                              name={`educations[${index}].from`}
                              selected={new Date(detailsItem.from)}
                              onChange={(date) => {
                                formik.setFieldValue(
                                  `educations[${index}].from`,
                                  date.toISOString()
                                );
                              }}
                              className="form-control"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                            />
                          </div>
                          {formik.touched.educations?.[index]?.from &&
                          formik.errors.educations?.[index]?.from ? (
                            <div className="errmsg">
                              {formik.errors.educations[index].from}
                            </div>
                          ) : null}
                        </div>

                        <div hidden={detailsItem.in_present}>
                          <label className="details-1-label">To</label>
                          <div className="reactdatepicker-inline">
                            <ReactDatePicker
                              id={`educations[${index}].to`}
                              name={`educations[${index}].to`}
                              selected={
                                new Date(detailsItem?.to || nextDayOfCurrent)
                              }
                              onChange={(date) => {
                                formik.setFieldValue(
                                  `educations[${index}].to`,
                                  date.toISOString()
                                );
                              }}
                              className="form-control"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                            />
                          </div>
                          {formik.touched.educations?.[index]?.to &&
                          formik.errors.educations?.[index]?.to ? (
                            <div className="errmsg">
                              {formik.errors.educations[index].to}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="details-input-checkbox">
                        <input
                          id={`educations[${index}].in_present`}
                          name={`educations[${index}].in_present`}
                          checked={detailsItem.in_present}
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setFieldValue(
                              `educations[${index}].to`,
                              nextDayOfCurrent
                            );
                          }}
                          type="checkbox"
                        />
                        <label htmlFor={`educations[${index}].in_present`}>
                          I am currently student
                        </label>
                      </div>
                    </Typography>
                  )}
                </CvSectionDetails>
              </div>
            </div>

            <div className="myprofile-sub-details">
              <div>
                <label> Employment Details</label>
              </div>

              <div>
                <CvSectionDetails
                  name="previous_employment_history"
                  initialDetailsObject={initialEmploymentHistory}
                  detailsList={formik.values.previous_employment_history}
                  setDetailsListState={(newValue) => {
                    formik.setFieldValue(
                      "previous_employment_history",
                      newValue
                    );
                  }}
                  errors={formik.errors.previous_employment_history}
                  touched={formik.touched.previous_employment_history}
                >
                  {({ detailsItem, index }) => (
                    <Typography className="typography-inline-main">
                      <label className="details-1-label">Company Name</label>
                      <input
                        className="form-control detail-1-inputs"
                        placeholder="Company name"
                        id={`previous_employment_history[${index}].company_name`}
                        name={`previous_employment_history[${index}].company_name`}
                        value={detailsItem?.company_name}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.previous_employment_history?.[index]
                        ?.company_name &&
                      formik.errors.previous_employment_history?.[index]
                        ?.company_name ? (
                        <div className="errmsg">
                          {
                            formik.errors.previous_employment_history[index]
                              .company_name
                          }
                        </div>
                      ) : null}
                      <label className="details-1-label">Department Name</label>
                      <input
                        className="form-control detail-1-inputs"
                        placeholder="Department name"
                        id={`previous_employment_history[${index}].department_name`}
                        name={`previous_employment_history[${index}].department_name`}
                        value={detailsItem?.department_name}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.previous_employment_history?.[index]
                        ?.department_name &&
                      formik.errors.previous_employment_history?.[index]
                        ?.department_name ? (
                        <div className="errmsg">
                          {
                            formik.errors.previous_employment_history[index]
                              .department_name
                          }
                        </div>
                      ) : null}
                      <label className="details-1-label">Job Position</label>
                      <input
                        className="form-control detail-1-inputs"
                        placeholder="Designation"
                        id={`previous_employment_history[${index}].designation`}
                        name={`previous_employment_history[${index}].designation`}
                        value={detailsItem?.designation}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.previous_employment_history?.[index]
                        ?.designation &&
                      formik.errors.previous_employment_history?.[index]
                        ?.designation ? (
                        <div className="errmsg">
                          {
                            formik.errors.previous_employment_history[index]
                              .designation
                          }
                        </div>
                      ) : null}
                      <label className="details-1-label">
                        Job Position Description
                      </label>
                      <input
                        className="form-control  detail-1-inputs"
                        placeholder="Job Position Description"
                        id={`previous_employment_history[${index}].job_designation`}
                        name={`previous_employment_history[${index}].job_designation`}
                        value={detailsItem?.job_designation}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.previous_employment_history?.[index]
                        ?.job_designation &&
                      formik.errors.previous_employment_history?.[index]
                        ?.job_designation ? (
                        <div className="errmsg">
                          {
                            formik.errors.previous_employment_history[index]
                              .job_designation
                          }
                        </div>
                      ) : null}
                      <div className="myprofilelist-calender-inline">
                        <div>
                          <label className="details-1-label">From</label>
                          <div className="reactdatepicker-inline">
                            <ReactDatePicker
                              id={`previous_employment_history[${index}].from`}
                              name={`previous_employment_history[${index}].from`}
                              selected={new Date(detailsItem.from)}
                              onChange={(date) => {
                                formik.setFieldValue(
                                  `previous_employment_history[${index}].from`,
                                  date.toISOString()
                                );
                              }}
                              className="form-control"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                            />
                          </div>
                          {formik.touched.previous_employment_history?.[index]
                            ?.from &&
                          formik.errors.previous_employment_history?.[index]
                            ?.from ? (
                            <div className="errmsg">
                              {
                                formik.errors.previous_employment_history[index]
                                  .from
                              }
                            </div>
                          ) : null}
                        </div>

                        <div hidden={detailsItem.in_present}>
                          <label className="details-1-label">To</label>
                          <div className="reactdatepicker-inline">
                            <ReactDatePicker
                              id={`previous_employment_history[${index}].to`}
                              name={`previous_employment_history[${index}].to`}
                              selected={
                                new Date(detailsItem?.to || nextDayOfCurrent)
                              }
                              onChange={(date) => {
                                formik.setFieldValue(
                                  `previous_employment_history[${index}].to`,
                                  date.toISOString()
                                );
                              }}
                              className="form-control"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                            />
                          </div>
                          {formik.touched.previous_employment_history?.[index]
                            ?.to &&
                          formik.errors.previous_employment_history?.[index]
                            ?.to ? (
                            <div className="errmsg">
                              {
                                formik.errors.previous_employment_history[index]
                                  .to
                              }
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="details-input-checkbox">
                        <input
                          id={`previous_employment_history[${index}].in_present`}
                          name={`previous_employment_history[${index}].in_present`}
                          checked={detailsItem.in_present}
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setFieldValue(
                              `previous_employment_history[${index}].to`,
                              nextDayOfCurrent
                            );
                          }}
                          type="checkbox"
                        />
                        <label
                          htmlFor={`previous_employment_history[${index}].in_present`}
                        >
                          I am currently working in this role
                        </label>
                      </div>
                    </Typography>
                  )}
                </CvSectionDetails>
              </div>
            </div>

            <div className="myprofile-sub-details">
              <div>
                <label>References</label>
              </div>
              <div>
                <CvSectionDetails
                  name="references"
                  initialDetailsObject={initialReference}
                  detailsList={formik.values.references}
                  setDetailsListState={(newValue) => {
                    formik.setFieldValue("references", newValue);
                  }}
                  errors={formik.errors.references}
                  touched={formik.touched.references}
                >
                  {({ detailsItem, index }) => (
                    <Typography className="typography-inline-main">
                      <label className="details-1-label">Person name</label>
                      <input
                        className="form-control detail-1-inputs"
                        placeholder="name"
                        id={`references[${index}].name`}
                        name={`references[${index}].name`}
                        value={detailsItem?.name}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.references?.[index]?.name &&
                      formik.errors.references?.[index]?.name ? (
                        <div className="errmsg">
                          {formik.errors.references[index].name}
                        </div>
                      ) : null}
                      <label className="details-1-label">Email</label>
                      <input
                        className="form-control detail-1-inputs"
                        placeholder="Email"
                        id={`references[${index}].email`}
                        name={`references[${index}].email`}
                        value={detailsItem?.email}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.references?.[index]?.email &&
                      formik.errors.references?.[index]?.email ? (
                        <div className="errmsg">
                          {formik.errors.references[index].email}
                        </div>
                      ) : null}
                      <br />
                    </Typography>
                  )}
                </CvSectionDetails>
              </div>
            </div>

            <div style={{ marginTop: "50px" }}>
              <div>
                <label
                  style={{
                    marginTop: "-12px",
                    marginLeft: "40px",
                  }}
                >
                  Choose an Option to download
                </label>
                <br />
              </div>
              <div style={{ display: "flex", marginLeft: "-25px" }}>
                <div style={{ marginLeft: "69px" }}>
                  <input
                    type="radio"
                    className="form-check-input   form-input-inline"
                    name="flexRadioDefault"
                    defaultChecked={true}
                    onChange={(e) => handleDocs(e)}
                    id="PDF File"
                    value={1}
                  />
                  <label htmlFor="html">PDF File</label>
                  <br />
                </div>
                <div className="radio-button-inline">
                  <input
                    type="radio"
                    id="MS Word Doc"
                    onChange={(e) => handleDocs(e)}
                    className="form-check-input   form-check-input-myprofilelist"
                    name="flexRadioDefault"
                    value={2}
                  />
                  <label htmlFor="css" className="option-title">
                    MS Word
                  </label>
                </div>
              </div>
            </div>
            <div className="download-button">
              <button type="submit">Save</button>
            </div>
          </form>
          <div className="download-button">
            <button onClick={handleDownload}>Download CV</button>
          </div>
          <div
            onClick={() => {
              if (!checkIsCvCompleted()) return;
              setModal(true);
            }}
            className="download-button"
          >
            <button>Share CV</button>
          </div>
          <div
            onClick={() => history.push("/myprofile")}
            className="download-button"
          >
            <button>Back</button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Managecv;
