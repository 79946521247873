import React, { useState } from "react";
import Refer from "../assets/images/refar-friend.png";
import { POST } from "../utils/helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ReferAFriend() {
  const [data, setData] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!data.name) {
      toast.error("PLease enter Name", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    if (!data.email) {
      toast.error("PLease enter emai", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    if (!lettersAndSpaceCheck(data.name)) {
      toast.error("Only Alphabets are allowed in name", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    if (data.mobile) {
      if (!numberOnlyCheck(data.mobile)) {
        toast.error("Only digits are allowed in Mobile Number", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return;
      }

      if (!mobileNumberCheck(data.mobile)) {
        toast.error("Invalid Mobile Number", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return;
      }
    }

    let postData = {
      name: data.name,
      email: data.email,
    };

    try {
      const res = await POST("referFriend", postData);

      if (res.data.success) {
        setData({
          name: "",
          mobile: "",
          email: "",
        });
        toast.success("Referral send successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } catch (error) {
      if (error.response.data?.message) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
  };

  function lettersAndSpaceCheck(name) {
    var regex = /^[a-zA-Z ]*$/;
    if (name.match(regex)) {
      return true;
    } else {
      return false;
    }
  }

  function numberOnlyCheck(name) {
    var reg = /^\d+$/;
    if (name.match(reg)) {
      return true;
    } else {
      return false;
    }
  }

  function mobileNumberCheck(name) {
    var reg = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
    if (name.match(reg)) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div>
      <div class="Refer-friend-wrapper bg-color">
        <div class="container">
          <div class="Refer-friend comman-width-container">
            <div class="banner-img">
              <img class="img-fluid" src={Refer} alt="" />
            </div>
            <div class="Refer-friend-details">
              <form>
                <div class="form-group">
                  <label className="font-Size-10px">Name</label>
                  <input
                    value={data.name}
                    onChange={(e) =>
                      setData((state) => {
                        let value = e.target.value;

                        return { ...state, name: value };
                      })
                    }
                    type="text"
                    class="form-control"
                    placeholder="Enter name"
                  />
                </div>
                <div class="form-group">
                  <label className="font-Size-10px">Email</label>
                  <input
                    value={data.email}
                    onChange={(e) =>
                      setData((state) => ({ ...state, email: e.target.value }))
                    }
                    type="email"
                    class="form-control"
                    placeholder="Email address"
                  />
                </div>
                <div class="form-group">
                  <label className="font-Size-10px">Mobile No.</label>
                  <input
                    value={data.mobile}
                    onChange={(e) =>
                      setData((state) => ({ ...state, mobile: e.target.value }))
                    }
                    type="text"
                    class="form-control"
                    placeholder="Mobile number"
                  />
                </div>
                <div class="Refer-friend-btn btn-blue">
                  <button onClick={handleSubmit}>Send Invite</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
