import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = moment().year()
  
  return (
    <section className="footer-wrapper">
      <nav className="navbar navbar-expand-md navbar-new-bottom">
        <div className="navbar-collapse" id="navbar2">
          <ul className="navbar-nav w-100 justify-content-center">
            <li className="nav-item active">
              <Link className="nav-link" to="/home">
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/founder">
                Founder
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/pricing">
                Pricing
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/coaches">
                Coaches
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/resources">
                Resources
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contactushome">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <hr className="footer-hr" />
      <p className="All-Rights">© {currentYear} Bluxinga. All Rights Reserved</p>
    </section>
  );
};

export default Footer;
