import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import NewVideoCall from "./Democall";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import "../scss/customforall.scss";
import {
  CModal,
  CModalBody,
  CModalHeader,
  CButton,
  CModalFooter,
  CModalTitle,
} from "@coreui/react";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import { GET, POST, clearLocalStorage } from "../utils/helper";
import Modal from "./Common/Modal";
import ReactStarsComp from "./Common/ReactStarsComp";
import config from "../config";
import { setAuthToken } from "../config/instance-axios";
import Header from "./Common/Header";
import Footer from "./Common/Footer";

const Layout = ({ children }) => {
  const isLoggedIn = useSelector((state) => state.loginReducer.isLoggedIn);
  const showComp = useSelector((state) => state.unmountReducer.show);
  const isEmployee = useSelector((state) => state.loginReducer.loginRole);

  const dispatch = useDispatch();
  const [rated, setRated] = useState([]);

  const user_name = localStorage.getItem("name");
  const user_id = localStorage.getItem("id");

  const [zoomModal, setZoomModal] = useState({
    isOpen: false,
    data: null,
  });

  const [path, setPath] = useState(null);
  const [notifdata, showNotifData] = useState({
    show: false,
    data: "",
    chatData: {},
  });
  const [notifdataGrp, showNotifDataGrp] = useState({
    show: false,
    data: "",
    chatData: {},
  });
  const [reminderModal, setReminderModal] = useState({
    isOpen: false,
    message: "",
  });
  const history = useHistory();
  const location = useLocation();

 // Update session and check if the session still active or not.
 const checkAuthSession = async () => {
  const currantUUID = localStorage.getItem("currant_uuid");
  const uuid = !currantUUID || currantUUID == "null" ? uuidv4() : currantUUID;
  const url = `${config.BASE_API_URL}session?uuid=${uuid}`;
  try {
    const sessionRes = await axios.get(url);

    const lastLogged = moment(sessionRes?.data?.body?.data?.last_logged);
    const currentTime = moment();

    if (currentTime.diff(lastLogged, "minutes") > 15) {
      clearLocalStorage();
      alert("Your session has been expired please login again!");
      window.location = "/home";
      return;
    }
    const res = await axios.post(url);
    localStorage.setItem("currant_uuid", res?.data?.body?.data?.uuid);
  } catch (error) {
    console.log("err:", error);
  }
};

// useEffect(() => {
//   if (isLoggedIn) {
//     checkAuthSession();
//     const intervalUpdateSession = setInterval(
//       checkAuthSession,
//       60 * 2 * 1000
//     ); //2m
//     return () => {
//       clearInterval(intervalUpdateSession);
//     };
//   }
// }, [isLoggedIn]);

  const getData = async () => {
    const res = await GET("getGoalSubmitReminders");

    if (res?.body?.data?.rows?.length > 0) {
      toast.success(
        "You have not filled anything in respect of any goal in past 10 days.",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          style: { width: "25rem", margin: "0" },
        }
      );
    }
  };

  const changeView = (type) => {
    if (type === "Call Ended") {
      toast.error("Call Ended", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
    }
    dispatch({ type: "MOUNT" });
  };
  const closeNotifGrp = () => {
    showNotifDataGrp((prev) => ({ show: !prev.show }));

    const {
      chat_room_id,
      sessionId,
      senderEmployeeData,
      token,
      group_name,
    } = notifdataGrp.chatData;
    const name = localStorage.getItem("name");
    const userId = localStorage.getItem("id");
    const profile_pic_url = localStorage.getItem("profile_pic_url");

    let secondUserData = JSON.parse(senderEmployeeData);
    let user = {
      id: secondUserData?.id,
      is_manager: null,
      name: group_name,
      profile_pic_url: null,
      status: 1,
    };

    let newdata = {
      id: secondUserData?.id,
      user: {
        name: name,
        id: userId,
        profile_pic_url: profile_pic_url,
      },
      room_id: chat_room_id,
      status: 1,
      other_user: user,
    };
    dispatch({ type: "SET_GRP_CHAT", payload: newdata });

    setTimeout(() => {
      history.push({
        pathname: "/groupChatDetail",
        state: {
          title: "",
          id: secondUserData?.id,
          room_id: chat_room_id,
          is_disabled: false,
        },
      });
    }, 2000);
  };

  const closeNotif = () => {
    showNotifData((prev) => ({ show: !prev.show }));
    const {
      chat_room_id,
      sessionId,
      senderEmployeeData,
      token,
    } = notifdata.chatData;

    const name = localStorage.getItem("name");
    const userId = localStorage.getItem("id");
    const profile_pic_url = localStorage.getItem("profile_pic_url") || "";

    let secondUserData = JSON.parse(senderEmployeeData);
    let user = {
      id: secondUserData?.id,
      is_manager: secondUserData?.is_manager,
      name: secondUserData?.name,
      profile_pic_url: secondUserData?.profile_pic_url,
      status: 1,
    };

    let newdata = {
      id: secondUserData?.id,
      user: {
        name: name,
        id: userId,
        profile_pic_url: profile_pic_url,
      },
      room_id: chat_room_id,
      status: 1,
      other_user: user,
    };
    dispatch({ type: "SET_CHAT", payload: newdata });

    setTimeout(() => {
      history.push({
        pathname: "/coachchatdetail",
        state: {
          title: "",
          id: secondUserData?.id,
          room_id: chat_room_id,
          is_disabled: false,
        },
      });
    }, 1000);
  };

  const getNotRatedSession = async () => {
    let dateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

    try {
      let data = await GET(`getNotRatedSessions?datetime=${dateTime}`);
      if (data?.body?.data?.rows) {
        data?.body?.data?.rows.map((item) => {
          setRated((state) => [
            ...state,
            {
              isOpen: true,
              data: item,
              rating: 0,
              comment: "",
            },
          ]);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isEmployee === 0) {
      getData();
      getNotRatedSession();
    }
    // set token to axios instance
    setAuthToken(localStorage.getItem("s_token"));
  }, []);

  useEffect(() => {
    const subs = localStorage.getItem("subs");

    if (path) {
      if (path.type === "6" || path.type === "5") {
        dispatch({ type: "UNMOUNT" });
      }

      if (path.type === "9") {
        if (subs !== "null") {
          toast.error("Call Ended", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 5000,
          });
        } else {
          toast.error("Call Declined", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
        dispatch({ type: "MOUNT" });
        dispatch({ type: "MOUNTFROMCHAT" });
      }
    }
  }, [path]);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register(config.FIREBASE_MESSAGING_SERVICE_WORKER)
        .then(function (registration) {
          navigator.serviceWorker.addEventListener("message", (message) => {
            if (message.data && message.data.isFromBackground) {
              const type = message.data.payload.FCM_MSG.data.type;
              const data = message.data.payload.FCM_MSG.data;

              if (type === "20" || type === "19") {
                if (isEmployee === 2) {
                  setZoomModal({ isOpen: true, data: data });
                }
              }

              if (type === "13" || type === "14" || type === "15") {
                history.push(`/achievement/${Number(data.id)}`);
              }

              if (type === "7" || type === "8") {
                history.push(`/goalssubmissionhistory/${Number(data.goal_id)}`);
              }

              if (type === "1") {
                history.push(`/employeegoal`);
              }

              if (type === "6" || type === "5") {
                setPath({
                  message: message.data.payload.FCM_MSG.data,
                  type,
                  isVideo: type === "6" ? true : false,
                  joinCall: true,
                });
              }

              if (type === "4") {
                const {
                  chat_room_id,
                  sessionId,
                  senderEmployeeData,
                  token,
                } = message.data.payload.FCM_MSG.data;

                const name = localStorage.getItem("name");
                const userId = localStorage.getItem("id");
                const profile_pic_url = localStorage.getItem("profile_pic_url");

                let secondUserData = JSON.parse(senderEmployeeData);
                let user = {
                  id: secondUserData?.id,
                  is_manager: secondUserData?.is_manager,
                  name: secondUserData?.name,
                  profile_pic_url: secondUserData?.profile_pic_url,
                  status: 1,
                };

                let newdata = {
                  id: secondUserData?.id,
                  user: {
                    name: name,
                    id: userId,
                    profile_pic_url: profile_pic_url,
                  },
                  room_id: chat_room_id,
                  status: 1,
                  other_user: user,
                };
                dispatch({ type: "SET_CHAT", payload: newdata });

                setTimeout(() => {
                  history.push({
                    pathname: "/coachchatdetail",
                    state: {
                      title: "",
                      id: secondUserData?.id,
                      room_id: chat_room_id,
                      is_disabled: false,
                    },
                  });
                }, 1000);
              }

              if (type === "16") {
                const name = localStorage.getItem("name");
                const userId = localStorage.getItem("id");
                const profile_pic_url = localStorage.getItem("profile_pic_url");
                const {
                  type,
                  chat_room_id,
                  sessionId,
                  senderEmployeeData,
                  token,
                  group_name,
                } = message.data.payload.FCM_MSG.data;

                let secondUserData = JSON.parse(senderEmployeeData);
                let user = {
                  id: secondUserData?.id,
                  is_manager: null,
                  name: group_name,
                  profile_pic_url: null,
                  status: 1,
                };

                let newdata = {
                  id: secondUserData?.id,
                  user: {
                    name: name,
                    id: userId,
                    profile_pic_url: profile_pic_url,
                  },
                  room_id: chat_room_id,
                  status: 1,
                  other_user: user,
                };
                dispatch({ type: "SET_GRP_CHAT", payload: newdata });

                setTimeout(() => {
                  history.push({
                    pathname: "/groupChatDetail",
                    state: {
                      title: "",
                      id: secondUserData?.id,
                      room_id: chat_room_id,
                      is_disabled: false,
                    },
                  });
                }, 2000);
              }

              if (type === "1") {
                history.push("/employeegoal");
              }
              if (type === "3") {
              }
              return;
            }

            const fbMessaging = message?.data?.firebaseMessaging;
            
            if(fbMessaging?.type == 'push-received' && !(fbMessaging?.payload?.data?.type)){
              let payload = fbMessaging?.payload;
              showNotifDataGrp((prev) => ({
                show: !prev.show,
                data: <div>
                    <h4>{payload?.notification?.title}</h4>
                    <p className="word-break">{payload?.notification?.body}</p>
                </div>,
                chatData: false
              }))
            }
            
            const type = fbMessaging?.payload?.data?.type;

            if (type) {
              dispatch({ type: "GET_NOTIFICATION_COUNT" });
              if (location.pathname === "/achievement") {
                dispatch({ type: "GET_ACHIEVEMENT_LIST", showLoading: false });
              }

              if (type === "18") {
                getData();
              }

              if (type === "6" || type === "5") {
                const msgData = message.data.firebaseMessaging.payload.data;
                let parseData = JSON.parse(msgData.senderEmployeeData);

                let otherUser = {
                  room_id: msgData.chat_room_id,
                  user: {
                    id: parseData.id,
                    is_manager: parseData.is_manager,
                    name: parseData.name,
                    email: parseData.email,
                    profile_pic_url: parseData.profile_pic_url,
                    status: parseData.status,
                  },
                };
                setPath({
                  message: msgData,
                  type,
                  isVideo: type === "6" ? true : false,
                  joinCall: true,
                  otherUserName: otherUser.user.name,
                  userName: user_name,
                  userID: user_id,
                  image: "",
                  item: otherUser,
                });
              }

              if (type === "4") {
                const {
                  type,
                  chat_room_id,
                  sessionId,
                  senderEmployeeData,
                  token,
                } = message.data.firebaseMessaging.payload.data;

                var secondUserData = {};
                if (senderEmployeeData) {
                  secondUserData = JSON.parse(senderEmployeeData);
                }
                if (location.pathname !== "/chatdetail") {
                  showNotifData((prev) => ({
                    show: !prev.show,
                    data:
                      message.data.firebaseMessaging.payload.notification.body,
                    chatData: message.data.firebaseMessaging.payload.data,
                  }));
                }
              }

              if (type === "16") {
                const {
                  type,
                  chat_room_id,
                  sessionId,
                  senderEmployeeData,
                  token,
                } = message.data.firebaseMessaging.payload.data;
                var secondUserData = {};
                if (senderEmployeeData) {
                  secondUserData = JSON.parse(senderEmployeeData);
                }

                showNotifDataGrp((prev) => ({
                  show: !prev.show,
                  data:
                    message.data.firebaseMessaging.payload.notification.body,
                  chatData: message.data.firebaseMessaging.payload.data,
                }));
              }

              if (type === "1") {
                toast.success(
                  message.data.firebaseMessaging.payload.notification.body,
                  {
                    position: toast.POSITION.TOP_CENTER,
                    onClick: () => history.push("/employeegoal"),
                  }
                );
              }
              if (type === "3") {
                toast.success(
                  message.data.firebaseMessaging.payload.notification.body,
                  {
                    position: toast.POSITION.TOP_CENTER,
                  }
                );
              }
              if (type === "9") {
                setPath({
                  message: message.data.firebaseMessaging.payload.data,
                  type,
                });
              }
            } else {
              const type_from_background = message?.data?.payload?.data?.type;

              if (
                type_from_background === "13" ||
                type_from_background === "14" ||
                type_from_background === "4" ||
                type_from_background === "15" ||
                type_from_background === "16" ||
                type_from_background === "4"
              ) {
                dispatch({ type: "GET_NOTIFICATION_COUNT" });
              }

              if (type_from_background === "12") {
                dispatch({ type: "GET_NOTIFICATION_COUNT" });
                if (location.pathname === "/achievement") {
                  dispatch({
                    type: "GET_ACHIEVEMENT_LIST",
                    showLoading: false,
                  });
                }
              }

              if (type_from_background === "9") {
                setPath({
                  message: message.data.payload.data,
                  type: type_from_background,
                });
              }

              if (type_from_background === "18") {
                getData();
              }
            }
          });
        })
        .catch(function (err) {
          console.log("Service worker registration failed, error:", err);
        });
    }
  }, []);

  const handleFeedback = async (rated, index) => {
    if (!rated.rating) {
      toast.error("Please enter Rating", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    let body = {
      session_id: rated.data.id,
      rating: rated.rating,
      comment: rated.comment,
    };

    if (!rated.comment) {
      delete body.comment;
    }

    try {
      const result = await POST(`rateCoachSession`, body);

      setRated((state) => {
        let newState = [...state];

        newState[index] = {
          isOpen: false,
          data: null,
          rating: 0,
          comment: "",
        };

        return newState;
      });

      if (result.status === 200) {
        toast.success("Coach Rated Successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header />
      <div>
        {showComp ? (
          <NewVideoCall
            removeComp={changeView}
            isVideo={path?.isVideo}
            message={path?.message}
            type={path?.type}
          />
        ) : (
          <div>{children}</div>
        )}
        <CModal
          show={reminderModal.isOpen}
          centered={true}
          backdrop={true}
          color="warning"
          onClose={() => {
            setReminderModal((state) => ({ message: "", isOpen: false }));
          }}
        >
          <CModalHeader>
            <CModalTitle>Reminder</CModalTitle>
          </CModalHeader>
          <CModalBody>{reminderModal.message}</CModalBody>
          <CModalFooter style={{ display: "flex", flexDirection: "row" }}>
            <CButton
              className="layout-cbutton2"
              onClick={() => history.push("/employeegoal")}
            >
              Fill Goals
            </CButton>

            <CButton
              className="layout-cbutton"
              onClick={() => {
                setReminderModal((state) => ({ message: "", isOpen: false }));
              }}
            >
              Cancel
            </CButton>
          </CModalFooter>
        </CModal>
        <CModal
          className="notif"
          backdrop={true}
          size="sm"
          show={notifdata.show}
          onClose={() => showNotifData((prev) => ({ show: !prev.show }))}
        >
          <CModalHeader closeButton>
            <p onClick={() => closeNotif()} className="word-break">{notifdata.data}</p>
          </CModalHeader>
        </CModal>
        <CModal
          className="notif"
          backdrop={true}
          size="sm"
          show={notifdataGrp.show}
          onClose={() => showNotifDataGrp((prev) => ({ show: !prev.show }))}
        >
          <CModalHeader closeButton>
            <p onClick={() => closeNotifGrp()} className="word-break">{notifdataGrp.data}</p>
          </CModalHeader>
        </CModal>
        <CModal
          show={zoomModal.isOpen}
          centered={true}
          backdrop={true}
          color="warning"
          onClose={() => {
            setZoomModal({ isOpen: false, data: null });
          }}
        >
          <CModalBody>{zoomModal?.data?.message}</CModalBody>
        </CModal>
        {rated.length > 0 &&
          isEmployee === 0 &&
          rated.map((rate, index) => {
            return (
              <Modal
                showmodal={rate?.isOpen}
                onCLose={() => {
                  setRated((state) => {
                    let newState = [...state];

                    newState[index] = {
                      isOpen: false,
                      data: null,
                      rating: 0,
                      comment: "",
                    };

                    return newState;
                  });
                }}
                title="Ratings and Reviews"
                handleClick={() => handleFeedback(rate, index)}
                body={
                  <div>
                    <div className="layout-div-div">
                      <h5>{rate?.data?.coach_management.name}</h5>
                      <h5>{rate?.data?.date}</h5>
                      <h5>
                        {rate?.data?.start_time.slice(0, 5)} -{" "}
                        {rate?.data?.end_time.slice(0, 5)}
                      </h5>
                    </div>
                    <div className="layout-div-style">
                      <ReactStarsComp
                        onChange={(e) => {
                          setRated((state) => {
                            let newState = [...state];
                            newState[index] = {
                              ...newState[index],
                              rating: e,
                            };
                            return newState;
                          });
                        }}
                        color="#C9C9C9"
                        size={20}
                        value={rate.rating}
                        edit={true}
                        activeColor="#FF7500"
                        emptyIcon={<i className="fa fa-star-o" />}
                        halfIcon={<i className="fa fa-star-half-alt" />}
                        filledIcon={<i className="fa fa-star" />}
                      />
                    </div>
                    <div>
                      <label>Type your reviews/feedback</label>
                      <textarea
                        maxLength="200"
                        class="form-control layout-textarea"
                        value={rate.comment}
                        onChange={(e) => {
                          setRated((state) => {
                            let newState = [...state];
                            newState[index] = {
                              ...newState[index],
                              comment: e.target.value,
                            };
                            return newState;
                          });
                        }}
                        rows="4"
                        placeholder="Write here"
                      ></textarea>
                    </div>
                  </div>
                }
              />
            );
          })}
      </div>
      <Footer />
    </>
  );
};

export default Layout;
