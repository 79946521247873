import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { PrivateRoute } from "../utils/authRoute";
import Dashboard from "./Dashboard";
import Login from "./Login";
import ResetPassword from "./ResetPassword";
import Profile from "./Profile";
import Team from "./ManagerTeam";
import TeamDetail from "./TeamDetail";
import ForgotPassword from "./ForgotPassword";
import PageError from "./PageError";
import ManagerRating from "./ManagerRating";
import ManagerGoal from "./ManagerGoal";
import SetupGoal from "../pages/SetupGoal";
import ViewManagerGoalDetail from "./ViewManagerGoalDetail";
import ManagerApproval from "./ManagerApproval";
import EmployeeGoal from "./EmployeeGoal";
import QualitativeDetail from "./QualitativeDetail";
import MyProfile from "./MyProfile";
import Chat from "./Chat";
import GroupChatDetail from "./NewChatDetail";
import Layout from "./layout";
import ChangePassword from "./changepassword";
import ListOfCoaches from "./ListOfCoaches/index";
import Checkcoachavailability from "./ListOfCoaches/CoachAvailability";
import Home from "./Home";
import TangoCoachAvailablity from "./ListOfCoaches/TangoCoachAvailability";
import EmployerLogin from "./EmployerModule/Login";
import EmployerResetPassword from "./EmployerModule/ResetPassword";
import EmployerForgetPassword from "./EmployerModule/ForgotPassword";
import EmployerDashboard from "./EmployerModule/EmployerDashboard";
import { connect } from "react-redux";
import EmployerTeam from "./EmployerModule/EmployerTeam";
import AddEmployee from "./EmployerModule/AddEmployee";
import ViewEmployeeDetail from "./EmployerModule/ViewEmployeDetail";
import ContactUS from "../pages/Contact";
import GoalsSubmissionHistory from "./GoalsSubmissionHistory";
import Achievement from "./Achievement";
import ParticularAchievement from "./ShowparticularAchievement";
import Library from "./Library";
import { ToastContainer } from "react-toastify";
import Refer from "../Components/ReferAFriend";
import Pricing from "../Components/Pricing";
import Founder from "../Components/Founder";
import NewsArticles from "../Components/NewsArticles";
import Coaches from "../Components/Coaches";
import Advisors from "./Advisors";
import FreeTrial from "./EmployerModule/FreeTrial";
import editEmployerProfile from "./EmployerModule/editEmployerProfile";
import EmployerProfile from "./EmployerModule/EmployerProfile";
import ChartDetail from "./EmployerModule/ChartDetail";
import AttributeEmployerDetail from "./EmployerModule/AttributeDetail";
import ScrollToTop from "./Common/ScrollToTop";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import ChangePasswordEmployer from "./EmployerModule/ChangePasswordEmployer";
import ContactUsEmployer from "./EmployerModule/ContactUsEmployer";
import CoachLogin from "./CoachModule/CoachLogin";
import CoachResetPassword from "./CoachModule/ResetPassword";
import CoachForgetPassword from "./CoachModule/CoachForgotPassword";
import CoachDashboard from "./CoachModule/CoachDashboard";
import EditCoachProfile from "./CoachModule/EditCoachProfile";
import CoachChat from "./CoachModule/CoachChat";
import CoachChatDetail from "./CoachChatDetail";
import PlanList from "./EmployerModule/ListPlans";
import MySubscription from "./EmployerModule/MySubscription";
import MyPayments from "./EmployerModule/MyPayments";
import StartFreeTrial from "./EmployerModule/Freetrial/StartFreeTrial";
import OnGoingFreeTrial from "./EmployerModule/Freetrial/OngoingFreeTrial";
import NoPlan from "./EmployerModule/Freetrial/NoPlan";
import ContacUsHome from "./ContactUsHome";
import EmployeeChartDetail from "./EmployeeChartDetail";
import MyAtrribute from "./EmployerModule/MyAtrribute";
import AddAtributes from "./EmployerModule/AddAtributes";
import AvailablityCheck from "./CoachModule/AvailablityCheck";
import DefineSlots from "./CoachModule/DefineSlots";
import MySessions from "./CoachModule/MySessions";
import MySessionRequest from "./CoachModule/MySessionRequest";
import SessionHistory from "./CoachModule/SessionHistory";
import SessionHistoryDetail from "./CoachModule/SessionHistoryDetail";
import EmployeeSessionList from "./EmployeeSessions/SessionList";
import EmployeeSessionHistory from "./EmployeeSessions/SessionHistory";
import ResetPswrd from "./Common/ResetPswrd";
import DefineAvailability from "./Common/DefineAvailability";
import PreviewSlots from "./CoachModule/PreviewSlots";
import Managecv from "./MyProfilelist";
import QualitativeDetailManager from "./QualitativeDetailManager";
import QualitativeRating from "../pages/QualitativeRating";
import EmployeeGoals from "./EmployeeGoals";
import RedirectToWebView from "./RedirectToWebView";
import PageLayout from "./PageLayout";
import Ticket from "../pages/Ticket";
import Analytics from './../pages/Analytics/index';
import EmployerChatThread from "./EmployerChatThread";
import FAQ from "../pages/FAQ";
import CheckoutPage from '../pages/StripeCheckout/index'
import PaidRoute from "../utils/paidRoute";

const MainContainer = (props) => {
  let FinalDashboardComponent =
    props?.loginRole === 0 ? Dashboard : EmployerDashboard;

  return (
    <React.Fragment>
      <BrowserRouter>
        <ScrollToTop />
        <ToastContainer limit={1} hideProgressBar={true} />
        <Switch>
          <Route exact path="/home" component={RedirectToWebView} />
          <Route exact path="/pricing" component={RedirectToWebView} />
          <Route exact path="/founder" component={RedirectToWebView} />
          <Route exact path="/coaches" component={RedirectToWebView} />
          <Route exact path="/resources" component={RedirectToWebView} />
          <Route exact path="/contactushome" component={RedirectToWebView} />

          <Route exact path="/advisors" component={RedirectToWebView} />

          <Route exact path="/login">
            <PageLayout component={Login} />
          </Route>
          <Route exact path="/forgetpassword">
            <PageLayout component={ForgotPassword} />
          </Route>
          <Route exact path="/employerforgetpassword">
            <PageLayout component={EmployerForgetPassword} />
          </Route>
          <Route exact path="/coachforgetpassword">
            <PageLayout component={CoachForgetPassword} />
          </Route>

          <Route exact path="/employerlogin">
            <PageLayout component={EmployerLogin} />
          </Route>
          <Route exact path="/coachlogin">
            <PageLayout component={CoachLogin} />
          </Route>
          <Route
            exact
            path="/resetPassword"
            render={(props) => <PageLayout component={ResetPswrd} {...props} />}
          />
          <Layout>
            <PrivateRoute exact path="/" component={FinalDashboardComponent} />
            <PrivateRoute
              exact
              path="/dashboard"
              component={FinalDashboardComponent}
            />
            <PrivateRoute
              exact
              path="/analytics"
              component={Analytics}
            />
            <PrivateRoute
              exact
              path="/resetpasswordone"
              component={ResetPassword}
            />
            <PrivateRoute
              exact
              path="/employerresetpassword"
              component={EmployerResetPassword}
            />
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/team" component={Team} />
            <PrivateRoute
              exact
              path="/teamemployeedetail/:id"
              component={TeamDetail}
            />
            <PrivateRoute exact path="/rating" component={ManagerRating} />

            <PrivateRoute exact path="/empgoals" component={EmployeeGoals} />

            <PrivateRoute exact path="/goal" component={ManagerGoal} />

            <PrivateRoute
              exact
              path="/viewmanagergoal/:id"
              component={ViewManagerGoalDetail}
            />
            <PrivateRoute
              exact
              path="/goalaproval/:goal_id/:team_goal_assign_id"
              component={ManagerApproval}
            />
            <PrivateRoute exact path="/addnewgoal" component={SetupGoal} />
            <PrivateRoute exact path="/edit-goal/:id" component={SetupGoal} />
            <PrivateRoute exact path="/employeegoal" component={EmployeeGoal} />
            <PrivateRoute
              exact
              path="/qualitativedetail"
              component={QualitativeDetail}
            />
            <PrivateRoute exact path="/myprofile" component={MyProfile} />
            <PaidRoute exact path="/chat" component={Chat} />
            <PrivateRoute
              exact
              path="/tangocoachavailabilty"
              component={TangoCoachAvailablity}
            />
            <PrivateRoute
              exact
              path="/groupChatDetail"
              component={GroupChatDetail}
            />
            <PrivateRoute
              exact
              path="/changepassword"
              component={ChangePassword}
            />
            <PrivateRoute
              exact
              path="/listofcoaches"
              component={ListOfCoaches}
            />
            <PrivateRoute
              exact
              path="/checkcoachavailability"
              component={Checkcoachavailability}
            />
            <PrivateRoute
              exact
              path="/employerdashboard"
              component={EmployerDashboard}
            />
            <PrivateRoute exact path="/freetrial" component={FreeTrial} />
            <PaidRoute exact path="/employerteam" component={EmployerTeam} />
            <PrivateRoute exact path="/addemployee" component={AddEmployee} />
            <PrivateRoute
              exact
              path="/addemployee/:id"
              component={AddEmployee}
            />
            <PrivateRoute
              exact
              path="/viewemployeedetail"
              component={ViewEmployeeDetail}
            />
            <PrivateRoute
              exact
              path="/goalssubmissionhistory/:goalid"
              component={GoalsSubmissionHistory}
            />
            <PrivateRoute exact path="/contactus" component={ContactUS} />
            <PrivateRoute exact path="/achievement" component={Achievement} />
            <PrivateRoute
              exact
              path="/achievement/:id"
              component={ParticularAchievement}
            />
            <PrivateRoute exact path="/library" component={Library} />
            <PrivateRoute exact path="/refer" component={Refer} />
            <PrivateRoute
              exact
              path="/editemployerprofile"
              component={editEmployerProfile}
            />
            <PrivateRoute
              exact
              path="/employerProfile"
              component={EmployerProfile}
            />
            <PrivateRoute exact path="/chartDetail" component={ChartDetail} />
            <PrivateRoute
              exact
              path="/attributeEmployerDetail"
              component={AttributeEmployerDetail}
            />
            <PrivateRoute
              exact
              path="/changepasswordemployer"
              component={ChangePasswordEmployer}
            />
            <PrivateRoute
              exact
              path="/contactusemployer"
              component={ContactUsEmployer}
            />
            <PrivateRoute
              exact
              path="/ticket/:id"
              component={Ticket}
            />
            <PrivateRoute
                exact
                path="/coachresetpassword"
                component={CoachResetPassword}
              />
              <PrivateRoute
                exact
                path="/coachdashboard"
                component={CoachDashboard}
              />
            <PrivateRoute
              exact
              path="/editcoachprofile"
              component={EditCoachProfile}
            />
            <PrivateRoute exact path="/coachchat" component={CoachChat} />
            <PrivateRoute
              exact
              path="/coachchatdetail"
              component={CoachChatDetail}
            />
            <PrivateRoute
              exact
              path="/employerchat"
              component={EmployerChatThread}
            />
            <PrivateRoute exact path="/planlist" component={PlanList} />
            <PrivateRoute
              exact
              path="/mysubscription"
              component={MySubscription}
            />
            <PrivateRoute exact path="/mypayments" component={PlanList} />
            <PrivateRoute exact path="/subscription" component={CheckoutPage} />
            <PrivateRoute
              exact
              path="/startfreetrial"
              component={StartFreeTrial}
            />
            <PrivateRoute
              exact
              path="/ongoingfreetrial"
              component={OnGoingFreeTrial}
            />
            <PrivateRoute exact path="/noplan" component={NoPlan} />
            <PrivateRoute
              exact
              path="/employeechartdetail"
              component={EmployeeChartDetail}
            />
            <PaidRoute exact path="/myattributes" component={MyAtrribute} />
            <PrivateRoute
              exact
              path="/addattributes"
              component={AddAtributes}
            />
            <PrivateRoute exact path="/previewslots" component={PreviewSlots} />
            <Route
              exact
              path="/defineavailabilty"
              component={DefineAvailability}
            />
            <PrivateRoute exact path="/defineslots" component={DefineSlots} />
            <PrivateRoute exact path="/mysessions" component={MySessions} />
              <PrivateRoute
                exact
                path="/mysessionrequest"
                component={MySessionRequest}
              />
              <PrivateRoute
                exact
                path="/sessionhistory"
                component={SessionHistory}
              />
            <PrivateRoute
                exact
                path="/sessionhistory/:id"
                component={SessionHistoryDetail}
              />
            <PrivateRoute
                exact
                path="/employeeSessions"
                component={EmployeeSessionList}
              />
              <PrivateRoute
                exact
                path="/employeeSessionsHistory"
                component={EmployeeSessionHistory}
              />
            <PrivateRoute exact path="/managecv" component={Managecv} />
            <PrivateRoute
              exact
              path="/qualitativedetailmanager"
              component={QualitativeDetailManager}
            />
            <PrivateRoute
              exact
              path="/qualitative-rating"
              component={QualitativeRating}
            />
            <PrivateRoute
              exact
              path="/faqs"
              component={FAQ}
            />
          </Layout>
          <Route path="*">
            <PageLayout component={PageError} />
          </Route>
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loginRole: state.loginReducer.loginRole,
  };
};

const mapDispatch = (dispatchEvent) => ({
  employerlogIn: (payload) =>
    dispatchEvent({ type: "GET_EMPLOYERLOGINDATA", value: payload }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(MainContainer);
