import React from "react";

import { ReactComponent as CancelIcon } from "../../assets/images/cancel.svg";
import AddGoal from "../AddGoal";
import "./style.scss";

const GoalItem = (props) => {
  const {
    goal,
    title,
    index,
    percentageTitle,
    percentage,
    actions = null,
    onCancel = null,
    onClick = null,
  } = props;

  return (
    <div
      className={`${onClick ? "pointer-goal-item" : ""} goal-item`}
      onClick={goal?.isOpen ? null : onClick}
    >
      <div
        className="goal-item-content goalButton"
        style={{ background: goal.isOpen ? "#ff7500" : "" }}
      >
        <p className="goal-item-title">{title}</p>
        <div className="col-8 text-right goal-completion position-relative">
          {!goal?.isOpen ? (
            <>
              <div className="goal-item-percentage">
                <div className="percentage-title">{percentageTitle}</div>

                <div className="percentage-progress">
                  <div
                    style={{
                      height: "100%",
                      width: percentage < 100 ? `${percentage}%` : "100%",
                      background:
                        "linear-gradient(90deg, #2786E2 0%, #FA933C 99.67%)",
                      borderBottomRightRadius: "13px",
                      borderTopLeftRadius: "13px",
                    }}
                  ></div>
                </div>
              </div>
              {actions}
            </>
          ) : (
            <CancelIcon onClick={onCancel} />
          )}
        </div>
      </div>
      {goal?.isOpen ? (
        <AddGoal item={goal} update={true} id={index} disable={false} />
      ) : null}
    </div>
  );
};

export default GoalItem;
