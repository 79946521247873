import React,{Component, useState,useEffect} from 'react';
import { connect } from 'react-redux'
import search from "../../assets/images/search.png"

const SearchTeam = (props) =>{
    const [keyword,setKeyword] = useState("")
    useEffect(() => {
     props.getSearchData({keyword})
     }, [keyword])

      const handleChange=(e)=>{
          e.preventDefault()
         setKeyword(e.target.value)
         props.handleKeyword(e.target.value)
     }
        return(
            <React.Fragment>
            <div class="search-wrapper" style={{ height:"50px", flex: props.fullWidth && 1 }}>
                <div class="container">
                    <form class="search" onSubmit={(e)=>e.preventDefault()}>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search by name, email address or mobile number" 
                             value={keyword}  onChange={(e) => handleChange(e)}/>
                            <img src={search} alt ='search'/>
                        </div>
                    </form> 
                </div>
            </div>
            </React.Fragment>
        )
    
}

const mapStateToProps = (state) => {
    const loading = state.globalReducer.loading
    return {
      loading,
    
    }
  }
  
  const mapDispatch = dispatchEvent => ({
    getSearchData: (payload) => dispatchEvent({ type: 'SEARCH_TEAM', value: payload  })
 })
  
export default connect(mapStateToProps, mapDispatch)(SearchTeam)