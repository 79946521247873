import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import banner from "../assets/images/banner.png";
import { GET } from "../utils/helper";
import { useHistory } from "react-router-dom";

const EmployeeGoal = (props) => {

  const [goal, setGoal] = useState([]);
  const [isOpen, setIsOpen] = useState(null);
  const history = useHistory();
  const [loading, setLoading] = useState(null);
  const [primaryGoals, setPrimaryGoals] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [showprimary, setShowPrimary] = useState(false);
  const [modal, setModal] = useState({
    isOpen: false,
  });
  const getData = async () => {
    try {
      setLoading(true);
      const res = await GET(`viewDetailsEmployee?id=${props.location.state.id}`);
      setLoading(false);
      setGoal(res.body.data.team_goal_assigns);
      let isPrimary = [];
      res.body.data.rows.forEach((element) => {
        if (element.is_primary === 1) {
          isPrimary.push(element.id);
        }
      });

      setPrimaryGoals(isPrimary);
    } catch (error) {
      setLoading(false);
      // console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, [refresh]);

  

  return (
    <React.Fragment>
      <div
        style={{ minHeight: "80vh" }}
        class="Manager-Goal-Look-wrapper bg-color"
      >
        
        <div class="container  container-employee-goal-div">
          <div class="Manager-Goal-Look comman-width-container">
            <div class="banner-img">
              <img alt="" class="img-fluid" src={banner} />
            </div>
            <br />



            {!loading ? (
              goal && goal.length > 0 ? (
                goal
               
                  .map((goal, index) => {
                    {console.log(goal,"goal")}
           

                    return (
                      <div>
                        {(
                          <div key={goal.id} class="form-group  Cmodal-footer">
                           
                            <div
                              
                              className="row cursor-pointer goalButton"
                              style={{
                                background: false ? "#ff7500" : "",
                                position: "relative",
                              }}
                            >
                              <div className="employee-goal-div3">
                                {(goal.complete_measure/goal.team_goal.enter_measure )*100}%
                              </div>

                              <div className="employee-goal-div2">
                                <div
                                  style={{
                                    height: "100%",
                                    width:
                                    (goal.complete_measure/goal.team_goal.enter_measure )*100 < 100
                                        ? `${(goal.complete_measure/goal.team_goal.enter_measure )*100}%`
                                        : "100%",
                                    background:
                                      "linear-gradient(90deg, #2786E2 0%, #FA933C 99.67%)",
                                    borderBottomRightRadius: "13px",
                                    borderTopLeftRadius: "13px",
                                  }}
                                ></div>
                              </div>

                              <div
                                style={{
                                  position: "absolute",
                                  top: "35%",
                                  left: "2rem",
                                }}
                              >
                                {goal.team_goal.title}
                              </div>
                            
                            </div>
                          </div>
                        )}

                        
                      </div>
                    );
                  })
              ) : (
                <div className="employee-goal-no-goals-found">
                  <h4>No Goals Found</h4>
                </div>
              )
            ) : (
              <div
                style={{ marginTop: "10rem" }}
                class="spinner-border text-primary"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            )
            }
          </div>
          {/* {goal && goal.length > 0 && !loading && showprimary ? (
            <div className="employee-goal-div">
              <button
                className="employee-goal-button"
                onClick={handleSubmitPrimaryGoals}
              >
                Submit
              </button>
              <div class="btn-blue">
                <button
                  className="employee-goal-button"
                  onClick={() => setShowPrimary(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : null} */}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const loading = state.globalReducer.loading;
  return {
    loading,
  };
};

const mapDispatch = (dispatchEvent) => ({
  getSearchData: (payload) =>
    dispatchEvent({ type: "SEARCH_TEAM", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(EmployeeGoal);
