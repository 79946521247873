import { GET } from "../../../src/utils/helper";
import { useEffect } from "react";

function useManagerTree(setTreeData, props) {
    useEffect(() => {
        GET("getCurrentEmployer").then(resp => {
            let employer_data = resp?.body?.data?.employer;
            let user_data = resp?.body?.data?.user_data;
            let all_employees = props?.teamList?.map(ele => ele.employee)


            if (employer_data && Object.keys(employer_data).length > 0) {
                setTreeData(
                    {
                        name: employer_data.name,
                        attributes: {
                            Email: employer_data.email,
                            Role: "Employer"
                        },
                        children: [{
                            name: user_data.name,
                            attributes: {
                                Email: user_data.email,
                                Role: "Manager"
                            },
                            children: all_employees.map(emp => ({ name: emp.name,attributes:{Email:emp.email,Role:'Employee'} })),
                        }]
                    }
                );
            }
        })
    }, [])
}

export default useManagerTree