import React, { useState } from "react";
import close from "../../assets/images/close.png";
import "../.././scss/commanallcustom.scss";
import {
  ModalBody,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalFooter,
  Button,
} from "reactstrap";

const TCModal = (props) => {
 
  return (
    <div>
      <div>
        <Modal isOpen={props.modalIsOpen} className="modal-main-div">
          <div className="border-bottom py-2 px-3 d-flex align-items-center justify-content-between">
            <h4>Employee Records Confidentiality Policy...</h4>
            <div
              className=""
              style={{}}
              onClick={() => props.setmodalIsOpen(false)}
            >
              <img
                style={{ height: "32px", width: "32px",objectFit:"contain" ,cursor:"pointer"}}
                src={close}
              />
            </div>
          </div>
          <ModalBody className="body-modal">
            The philosophy of BluXinga is to safeguard personal employee
            information in its possession to ensure the confidentiality of the
            information. Additionally, BluXinga will only collect personal
            information that is required to pursue its business operations and
            to comply with government reporting and disclosure requirements.
            Personal information collected by the company includes employee
            names, job title, organization charts, telephone numbers, and e-mail
            addresses. Personal employee information will be considered
            confidential and as such will be shared only as required and with
            those who have a need to have access to such information. Personal
            employee information used in BluXinga’s business will be safeguarded
            under company proprietary electronic transmission and intranet
            policies and security systems. If an employee becomes aware of a
            material breach in maintaining the confidentiality of his or her
            personal information, the employee should report the incident to a
            representative of BluXinga’s customer services. The customer
            services department has the responsibility to investigate the
            incident and take corrective action. Please be aware that a standard
            of reasonableness will apply in these circumstances. I acknowledge
            the above Employee Records Confidentiality Policy [ ]
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default TCModal;
