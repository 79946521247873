import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import pro from "../../assets/images/profilePlaceholder/profilePlaceholder.png";
import { GET, putAPI } from "../../utils/helper";
import Modal from "../Common/Modal";
import "../.././scss/customforall.scss";
export default function SessionList(props) {
  const [sessions, setSessions] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const [reason, setReason] = useState({
    isOpen: false,
    data: "",
    id: null,
  });

  const getData = async () => {
    let dateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    try {
      setLoader(true);
      const result = await GET(`getSessions?datetime=${dateTime}`);
      setLoader(false);

      if (result.status === 200) {
        setSessions(result?.body?.data?.rows);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, [refresh]);

  const handleRejection = async () => {
    console.log(reason.data);

    if (!reason.data.trim()) {
      toast.error("Enter reason", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    try {
      const res = await putAPI("cancelSession", {
        session_id: reason.id,
        cancel_reason: reason.data,
      });
      if (res.status === 200) {
        setReason((state) => ({ ...state, isOpen: false, data: "", id: null }));
        setRefresh(!refresh);
        toast.success("Session Cancelled", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } catch (error) {
      setReason((state) => ({ ...state, isOpen: false, data: "", id: null }));
      console.log(error.response);
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const handleAccept = async (url) => {
    window.open(url, "_blank");
  };

  const openChat = async (e, data) => {
    e.stopPropagation();
    let newdata = {
      id: data.id,
      user: data.user,
      room_id: data.room_id,
      status: 1,
      other_user: data.other_user,
      chatLastDeletedOn: data?.info[1].chatLastDeletedOn,
    };
    dispatch({ type: "SET_GRP_CHAT", payload: newdata });

    setTimeout(() => {
      props.history.push({
        pathname: "/groupChatDetail",
        state: {
          title: "",
          id: data?.id,
          room_id: data.room_id,
          is_disabled: false,
        },
      });
    }, 1000);
  };

console.log(sessions, 'sessionsList page');
  return (
    <section style={{ minHeight: "80vh" }} class="profiles-wrapper-main">
      <div class="container">
        <h3 style={{ textAlign: "center", justifyContent: "center" }}>
          My Sessions
        </h3>
        {!loader ? (
          sessions.length > 0 ? (
            sessions.map((session) => {           
              return (
                <div key={session.id} class="profiles-wrapper">
                  <div class="profile-comman-box">
                    <div
                      class="row"
                      style={{ textAlign: "center", justifyContent: "center" }}
                    >
                      <div class="col-md-12 comman-width one">
                        <div
                          class="main-profiles"
                          style={{ maxWidth: "558px", margin: "0 auto" }}
                        >
                          <div class="profile-img">
                            <img
                              className="imgprofile"
                              src={
                                session?.coach_management?.profile_pic_url ||
                                pro
                              }
                              alt=""
                            />
                          </div>
                          <div class="row no-gutters">
                            <div className="data-main-div">
                              <div className="profile-div1">
                                <p>Coach Name :</p>
                                <p className="profile-p">
                                  <p>{session?.coach_management?.name}</p>
                                </p>
                              </div>
                              <div className="profile-div1">
                                <p>Performance Challenge :</p>
                                <p className="profile-p">
                                  <p>
                                    {
                                      session?.coach_specialization_category
                                        ?.name
                                    }
                                  </p>
                                </p>
                              </div>
                              <div className="profile-div1">
                                <p>Challenge Details :</p>
                                <p className="profile-p">
                                  <p className="textwrap">{session?.query}</p>
                                </p>
                              </div>

                              <div className="profile-div1">
                                <p>Session Date :</p>
                                <p className="profile-p">
                                  <p>
                                    {moment(session?.date).format(
                                      "Do MMM YYYY"
                                    )}
                                  </p>{" "}
                                </p>
                              </div>
                              <div className="profile-div1">
                                <p>Session Time :</p>
                                <p className="profile-p">
                                  <p>
                                    {session
                                      ? moment(session.start_time.slice(0, 5), [
                                          "HH.mm",
                                        ]).format("hh:mm a")
                                      : ""}{" "}
                                    -{" "}
                                    {session
                                      ? moment(session.end_time.slice(0, 5), [
                                          "HH.mm",
                                        ]).format("hh:mm a")
                                      : ""}
                                  </p>
                                </p>
                              </div>
                            </div>
                          </div>
                          {session?.status === 2 && (
                            <div className="editbutton">
                              <button
                                onClick={() =>
                                  handleAccept(session.details.join_url)
                                }
                                className="button-div"
                              >
                                Join
                              </button>
                              <button
                                onClick={(e) => openChat(e, session?.chatRoom)}
                                style={{
                                  width: "30%",
                                  backgroundColor: !session.chatRoom
                                    ? "grey"
                                    : "",
                                }}
                              >
                                Chat
                              </button>
                              {moment(new Date()).format("YYYY-MM-DD") ===
                                session.date &&
                                moment(new Date(), "hh:mm").isBefore(
                                  moment(session.start_time, "hh:mm")
                                ) && (
                                  <button
                                    onClick={() => {
                                      setReason({
                                        id: session.id,
                                        isOpen: true,
                                        data: "",
                                      });
                                    }}
                                    style={{
                                      width: "30%",
                                      backgroundColor: "#0D86FF",
                                    }}
                                  >
                                    Cancel
                                  </button>
                                )}

                              {moment(new Date()).format("YYYY-MM-DD") !==
                                session.date && (
                                <button
                                  onClick={() => {
                                    setReason({
                                      id: session.id,
                                      isOpen: true,
                                      data: "",
                                    });
                                  }}
                                  style={{
                                    width: "30%",
                                    backgroundColor: "#0D86FF",
                                  }}
                                >
                                  Cancel
                                </button>
                              )}
                            </div>
                          )}

                          {session?.status === 1 && (
                            <div className="editbutton">
                              <button
                                style={{
                                  width: "30%",
                                  backgroundColor: "grey",
                                }}
                              >
                                Pending
                              </button>

                              <button
                                onClick={() => {
                                  setReason({
                                    id: session.id,
                                    isOpen: true,
                                    data: "",
                                  });
                                }}
                                style={{
                                  width: "30%",
                                  backgroundColor: "#0D86FF",
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <h3
              style={{
                textAlign: "center",
                marginTop: "4rem",
                color: "#FF6932",
              }}
            >
              No sessions found
            </h3>
          )
        ) : (
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        )}
        <Modal
          showmodal={reason.isOpen}
          onCLose={() => {
            setReason((state) => ({
              ...state,
              isOpen: false,
              data: "",
              id: null,
            }));
          }}
          handleClick={handleRejection}
          title={"Cancel the Session"}
          body={
            <div>
              <label>Enter the reason for cancelling the session</label>
              <textarea
                style={{ width: "100%", backgroundColor: "white" }}
                maxLength="200"
                class="form-control"
                onChange={(e) =>
                  setReason((state) => ({ ...state, data: e.target.value }))
                }
                value={reason.data}
                rows="4"
                placeholder="Write here"
              ></textarea>
            </div>
          }
        />
      </div>
    </section>
  );
}
