import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import instanceAxios from "../config/instance-axios";

export const useGetOverallMood = (interval = 0) => {
  const queryKey = ["analytics-overall-mood", interval];
  return useQuery(
    queryKey,
    async () => {
      const response = await instanceAxios.get(
        `analytics/overallMood?interval=${interval}`
      );
      return response.data;
    },
    {
      retry: 2,
    }
  );
};

export const useGetScore = (interval) => {
  const queryKey = ["analytics-score", interval];
  return useQuery(
    queryKey,
    async () => {
      const response = await instanceAxios.get(
        `analytics/score?interval=${interval}`
      );
      return response.data;
    },
    {
      retry: 2,
    }
  );
};

export const useGetStreaks = () => {
  const queryKey = ["analytics-streaks"];
  return useQuery(
    queryKey,
    async () => {
      const response = await instanceAxios.get(`analytics/streaks`);
      return response.data;
    },
    {
      retry: 2,
    }
  );
};

export const useGetEmployeeEnergyHistory = (employeeId) => {
  const queryKey = ["analytics-energy-history", employeeId];
  return useInfiniteQuery(
    queryKey,
    async ({ pageParam = 1 }) => {
      const response = await instanceAxios.get(
        `analytics/energyHistory?employee_id=${employeeId}&limit=15&page=${pageParam}`
      );
      return response.data;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage && lastPage?.body?.data?.length > 0) {
          return allPages.length + 1;
        }
        return undefined;
      },
      retry: 2,
      enabled: false,
      cacheTime: 0,
    }
  );
};

export const useGetAccomplishment = () => {
  const queryKey = ["analytics-accomplishment"];
  return useInfiniteQuery(
    queryKey,
    async ({ pageParam = 1 }) => {
      const response = await instanceAxios.get(
        `analytics/accomplishment?limit=15&page=${pageParam}`
      );
      return response.data;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage && lastPage?.body?.data?.length > 0) {
          return allPages.length + 1;
        }
        return undefined;
      },
      retry: 2,
    }
  );
};

export const useGetGoals = () => {
  const queryKey = ["analytics-view-goals"];
  return useQuery(
    queryKey,
    async () => {
      const response = await instanceAxios.get(`analytics/viewGoals`);
      return response.data;
    },
    {
      retry: 2,
    }
  );
};

export const useGetThoughtOfTheDay = () => {
  const queryKey = ["analytics-thought-of-the-day"];
  return useQuery(
    queryKey,
    async () => {
      const response = await instanceAxios.get(`analytics/thoughtOfTheDay`);
      return response.data;
    },
    {
      retry: 2,
    }
  );
};
function objectToText(obj, parentKey = '') {
  let text = '';
  for (const [key, value] of Object.entries(obj)) {
    const currentKey = parentKey ? `${key}` : key;
    if (typeof value === 'object' && value !== null) {
      text += `${objectToText(value, currentKey)} \r\n`;  
    } else {
      text += `${currentKey}: ${value} \r\n`;
    }
  }
  return text;
}

export const getExportData = async (interval, setData, setLoading) => {
  setLoading(true);
  const response = await instanceAxios.get("analytics/exportAnalytics" + "?interval=" + interval);
  let resp = response?.data;
  if (resp?.success) {
    let final = resp?.body?.data.map(d => ({
      ...d,
      accomplishments:d.accomplishments?.length && objectToText(d.accomplishments),
      goals:d.goals?.length && objectToText(d.goals),
      overallMood: d.overallMood?.length && objectToText(d.overallMood),
      score: d.score?.length && objectToText(d.score),
      streaks: d.streaks?.length && objectToText(d.streaks),
    }));

    setData(final);
    setLoading(false);
  } else {
    console.log(resp, "ERROR");
  }
};

export const getExportStats = async (setData, setLoading) =>{
  setLoading(true);
  const response = await instanceAxios.get("analytics/export/stats");
  let resp = response?.data;
  if (resp?.success) {
    setData(response.data.body.data);
    setLoading(false);
  } else {
    console.log(resp, "ERROR");
  }
}