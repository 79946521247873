import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";

import "./style.scss";
import { useGetOverallMood } from "../../hooks/analytics.hook";
import SelectFilterInput from "../SelectFilterInput";
import LoadingWidget from "../LoadingWidget";
import { useSelector } from "react-redux";
import { OverallMood, StoredEmails } from "../AnalyticsConstant";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      color: "black",
      anchor: "center",
      align: "center",
      formatter: (value, context) => {
        return context.dataIndex === 0 ? "Good Mood" : "Bad Mood";
      },
    },
  },
};

const data = {
  datasets: [
    {
      data: [1, 1],
      backgroundColor: ["rgba(75, 192, 192, 0.2)", "rgba(255, 99, 132, 0.2)"],
      borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
      borderWidth: 1,
    },
  ],
};

const intervalOptions = [
  {
    id: 0,
    value: 0,
    label: "Current",
  },
  {
    id: 7,
    value: 7,
    label: "Last 7 Days",
  },
  {
    id: 30,
    value: 30,
    label: "Last 30 Days",
  },
  {
    id: 90,
    value: 90,
    label: "Last 90 Days",
  },
];

const OverallMoodWidget = () => {
  const [selectedInterval, setSelectedInterval] = useState(0);
  const overallMoodQuery = useGetOverallMood(selectedInterval);
  const moods = overallMoodQuery.data?.body?.data;
  const [pieData, setPieData] = useState(data);
  const [isMoodNotFound, setIsMoodNotFound] = useState(true);

  const email = useSelector(state => state?.profileReducer?.profileData?.email);

  useEffect(() => {

    const isEmailStored = email && StoredEmails.includes(email);
    const isMoodZero = isEmailStored ? false : moods && moods?.goodMood === 0 && moods?.badMood === 0;
    setIsMoodNotFound(isMoodZero);
    const moodData = isEmailStored ? OverallMood[selectedInterval] : moods && [moods?.goodMood, moods?.badMood];
    setPieData({
      ...data,
      datasets: [{ ...data.datasets[0], data: moodData }],
    });

  }, [overallMoodQuery.isLoading, email]);

  const handleChangeSelectInterval = (e) => {
    setSelectedInterval(e.target.value);
  };

  return (
    <div className="over-all-mood-widget">
      <div className="header">
        <p>Overall Mood</p>
        <SelectFilterInput
          options={intervalOptions}
          currentValue={selectedInterval}
          handleSelectFilter={handleChangeSelectInterval}
        />
      </div>
      <div className="pie-wrapper">
        {!overallMoodQuery.isLoading && !isMoodNotFound ? (
          <Pie data={pieData} options={options} />
        ) : isMoodNotFound && !overallMoodQuery.isLoading ? (
          <>
            <p className="no-mood-found">No data found</p>
          </>
        ) : (
          <LoadingWidget />
        )}
      </div>
    </div>
  );
};

export default OverallMoodWidget;
