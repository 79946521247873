import { call, put, apply } from 'redux-saga/effects'
import * as Action from '../type.action'
import { GET, RESETPOST, POSTIMAGE } from '../../utils/helper'
import { GETMYPROFILE_API, UPLOADFILEAPI, UPDATEPROFILE } from "./ApiConstant";

export function* getProfile() {
  yield put({ type: Action.LOADING_SHOW })
  try {
    const Data = yield call(GET, GETMYPROFILE_API)

    if (Data.status === 200) {
      yield put({ type: Action.PROFILE_DATASUCCESS, data: Data.body.data, msg: '' })
      yield put({ type: Action.LOADING_HIDE })
    } else {
      yield put({ type: Action.PROFILE_DATAFAIL, data: Data.message })
      yield put({ type: Action.LOADING_HIDE })
    }
  } catch (error) {
    yield put({ type: Action.PROFILE_DATAFAIL, data: 'something went wrong' })
    yield put({ type: Action.LOADING_HIDE })
  }
}

export function* uploadProfile(action) {
  yield put({ type: Action.LOADING_SHOW })
  try {
    const postObj = action.value
    const Data = yield call(POSTIMAGE, UPLOADFILEAPI, postObj)
  
    if (Data.status === 200) {
      yield put({ type: 'handleProfileChange', data: { 'profile_pic_url': Data.body.data }, msg: 'Profile Pic Updated Successfully' })
      yield put({ type: Action.LOADING_HIDE })
    } else {
      yield put({ type: Action.PROFILE_IMAGEFAIL, data: Data.message })
      yield put({ type: Action.LOADING_HIDE })
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      yield put({ type: Action.PROFILE_IMAGEFAIL, msg: error.response.data.message })

      console.log("error.response", error.response)
    } else {
      yield put({ type: Action.PROFILE_IMAGEFAIL, data: 'something went wrong' })
    }

    yield put({ type: Action.LOADING_HIDE })

  }
}
export function* updateProfile(action) {
  yield put({ type: Action.LOADING_SHOW })
  const { postObj, history, redirect } = action.value
  try {
    const Data = yield call(RESETPOST, UPDATEPROFILE, postObj)

    if (Data.status === 200) {
      yield put({ type: Action.PROFILE_DATASUCCESS, data: Data.body.data, msg: 'Profile Updated Successfully!!' })
      yield put({ type: Action.LOADING_HIDE })
      if (redirect === true) {
        yield apply(history, history.push, ['/dashboard'])

      }
    } else {

      yield put({ type: Action.PROFILE_DATAFAIL, msg: Data.message })
      yield put({ type: Action.LOADING_HIDE })
     
    }
  } catch (error) {
    yield put({ type: Action.PROFILE_DATAFAIL, msg: 'something went wrong!!' })
    yield put({ type: Action.LOADING_HIDE })
   
  }
}