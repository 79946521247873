import React, { useEffect, useState } from "react";
import happy from "../assets/images/happy1.png";
import { connect } from "react-redux";
import SearchTeam from "./Common/SearchTeam";
import ManagerDashChart from "./ManagerDashChart";
import { GET } from "../utils/helper";
const ManagerDashboard = (props) => {
  const is_manager = props?.loginData?.is_manager == 1;
  const showLook = props?.showLook;

  const [chartDetails, setChartDetails] = useState(null);

  useEffect(() => {
    props.getTeamList();

    const getData = async () => {
      const res = await GET("getGoalCompletionAverageAsManager");

      setChartDetails(res?.body?.data);
    };

    getData();
  }, []);
  const [keyword, setKeyword] = useState("");
  const handleKeyword = (data) => {
    setKeyword(data);
  };

  const renderEnergyUpdate = () => {
    let updatedList = [];
   
    props?.teamList &&
      props?.teamList?.forEach((item) => {
        if (item.isEmployeeEnergyUpdatedInLast24Hour) {
          updatedList = [...updatedList, item];
        }
      });

    if (
      !props.loading &&
      props?.teamList &&
      props.teamList.length === 0 &&
      keyword.length === 0
    ) {
      return <div className="teammsg">No Team Member Added!</div>;
    }

    if (
      !props.loading &&
      props?.teamList &&
      props.teamList.length === 0 &&
      keyword.length >= 1
    ) {
      return <div className="teammsg">No Team Member Found!</div>;
    }
    
    if (updatedList.length <= 0) {
      return (
        <div className="teammsg">
          No employees updated their energy checks yet
        </div>
      );
    }
    return (
      <React.Fragment>
       
        {updatedList.map((item, index) => {
          return (
            <div class="Feeling-wrapper">
              <div class="container">
                <div class="Feeling">
                  <p>
                    {item.employee?.name}

                    <span class="float-right">
                      {" "}
                      <img
                        alt=""
                        src={
                          item?.employee?.emoji?.image_url
                            ? item.employee?.emoji.image_url
                            : happy
                        }
                      />
                    </span>
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  return (
    <div className="min-height-40vh">
      <SearchTeam handleKeyword={handleKeyword} />
      {props.loading && (
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      )}
      {chartDetails && chartDetails.length > 0 ? (
        <ManagerDashChart quantitativeDetail={chartDetails} isManager={is_manager} look={showLook} />
      ) : null}
 
      {renderEnergyUpdate()}
    </div>
  );
};

const mapStateToProps = (state) => {
  const teamList = state.teamReducer.teamList;
  const errorMsg = state.teamReducer.errorMsg;
  const totalTeamCount = state.teamReducer.totalTeamCount;
  const loading = state.globalReducer.loading;
  return {
    teamList,
    loading,
    errorMsg,
    totalTeamCount,
    teamEnergy: state.teamReducer.teamEnergy,
    showLook: state.globalReducer.showLook,
    loginData: state.loginReducer.loginData
  };
};

const mapDispatch = (dispatchEvent) => ({
  getTeamList: () => dispatchEvent({ type: "GET_TEAMLIST" }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(ManagerDashboard);
