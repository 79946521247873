import { CButton, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react'
import React from 'react'
import { toast } from 'react-toastify';
import useFAQ from './helper';
import { default as MyModal } from "../../Components/Common/Modal";

const FAQDelete = ({ Delete, toggle, getAllFaq, setData, BASE_URL }) => {

    const { deleteFAQ } = useFAQ()

    const del = () => {
        deleteFAQ(Delete?.id).then(resp => {
            toggle({ status: false, id: "" });
            getAllFaq(setData);
            toast.error('FAQ deleted successfully!');
        });
    }
    return (<>
        <MyModal
          showmodal={Delete?.status}
          onCLose={() => {
            toggle({ status: false, id: "" })
        }}
          title={`Delete?`}
          body={<div>Are you sure you want to delete this FAQ?</div>}
          handleClick={del}
        />
    </>
    )
}

export default FAQDelete