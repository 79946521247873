import * as actions from '../type.action'

const initialState = {
    commentCount: null,
    createdAt: null,
    description: null,
    employee_id: null,
    highFiveCount: null,
    id: null,
    isHighFived: null,
    isLiked: null,
    isSelf: null,
    last_action_on: null,
    likeCount: null,
    status: null,
    message: null,
    employee: null,
    comments: []
}

const particularAchievementReducer = (state, action) => {
    if (state === undefined) {
        return (state = initialState)
    }

    switch (action.type) {
        case actions.PARTICULAR_ACHIEVEMENT_SUCCESS:
            return {
                ...state,
                commentCount: action.data.commentCount,
                createdAt: action.data.createdAt,
                description: action.data.description,
                employee_id: action.data.employee_id,
                highFiveCount: action.data.highFiveCount,
                id: action.data.id,
                isHighFived: action.data.isHighFived,
                isLiked: action.data.isLiked,
                isSelf: action.data.isSelf,
                last_action_on: action.data.last_action_on,
                likeCount: action.data.likeCount,
                status: action.data.status,
                employee: action.data && action.data.employee ? action.data.employee : null,
                comments: action.data.achievement_comments,
                message: null
            }
        case actions.PARTICULAR_ACHIEVEMENT_FAIL:
            return {
                ...state,
                message: action.data,
                commentCount: null,
                createdAt: null,
                description: null,
                employee_id: null,
                highFiveCount: null,
                id: null,
                isHighFived: null,
                isLiked: null,
                isSelf: null,
                last_action_on: null,
                likeCount: null,
                status: null,
                employee: null,
                comments: []
            }
        default:
            return state
    }

}

export default particularAchievementReducer;