import React, { useState } from "react";
import camera from "../../assets/images/edit.png";
import PictureModal from "./PictureModal";

const ProfilePicture = ({ onChange, edit, picture }) => {
  const [Open, setOpen] = useState(false);
  return (
    <>
      <div className="profile-img pb-2">
        <div onClick={() => edit && setOpen(!Open)}>
          <div className={`profile ${edit ? "pointer-cursor" : ""}`}>
            <img className="img-pro img-pro-myprofile" src={picture} alt="user profile" />
            {edit && (
              <img
                className="edit-profile edit border border-warning rounded-pill"
                src={camera}
                alt=""
              />
            )}
          </div>
        </div>
      </div>

      {Open && <PictureModal onChange={onChange} Open={Open} Close={setOpen} />}
    </>
  );
};

export default ProfilePicture;
