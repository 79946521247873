import React, { useEffect, useState} from 'react';
import { useHistory } from "react-router-dom"
import title1 from "../../assets/images/title1.png"
import title2 from "../../assets/images/title2.png"
import title3 from "../../assets/images/title3.png"
import title4 from "../../assets/images/title4.png"
import Ellipse54 from "../../assets/images/Ellipse54.png"
import Lottie from 'react-lottie';
import sms from "../../assets/images/sms-green.png";
import setChatTitle from '../../utils/ChatTitle';


import * as animationData0 from '../../assets/json/Sundae 1/1.1 0.json';
import * as animationData from '../../assets/json/Sundae 1/1.1 0 to 20.json';
import * as animationData2 from '../../assets/json/Sundae 1/1.1 20 to 40.json';
import * as animationData3 from '../../assets/json/Sundae 1/1.1 40 to 60.json';
import * as animationData4 from '../../assets/json/Sundae 1/1.1 60 to 80.json';
import * as animationData5 from '../../assets/json/Sundae 1/1.1 60 to 80.json';
import * as animationData6 from '../../assets/json/Sundae 1/1.1 100 to.json';
import * as animationData7 from '../../assets/json/Sundae 1/Celebration JSON file.json'

import * as two_animationData0 from '../../assets/json/Sundae 2/2.1 0.json'
import * as two_animationData from '../../assets/json/Sundae 2/2.1 0 to 20.json'
import * as two_animationData2 from '../../assets/json/Sundae 2/2.1 20 to 40.json'
import * as two_animationData3 from '../../assets/json/Sundae 2/2.1 40 to 60.json'
import * as two_animationData4 from '../../assets/json/Sundae 2/2.1 60 to 80.json'
import * as two_animationData5 from '../../assets/json/Sundae 2/2.1 80 to 100.json'
import * as two_animationData6 from "../../assets/json/Sundae 2/2.1 100 to.json"

import * as three_animationData0 from '../../assets/json/Sundae 3/3.1 0.json'
import * as three_animationData from '../../assets/json/Sundae 3/3.1 0 to 20.json'
import * as three_animationData2 from '../../assets/json/Sundae 3/3.1 20 to 40.json'
import * as three_animationData3 from '../../assets/json/Sundae 3/3.1 40 to 60.json'
import * as three_animationData4 from '../../assets/json/Sundae 3/3.1 60 to 80.json'
import * as three_animationData5 from '../../assets/json/Sundae 3/3.1 80 to 100.json'
import * as three_animationData6 from "../../assets/json/Sundae 3/3.1 100 to.json"

import * as four_animationData0 from '../../assets/json/Sundae 4/4.1 0.json'
import * as four_animationData from '../../assets/json/Sundae 4/4.1 0 to 20.json'
import * as four_animationData2 from '../../assets/json/Sundae 4/4.1 20 to 40.json'
import * as four_animationData3 from '../../assets/json/Sundae 4/4.1 40 to 60.json'
import * as four_animationData4 from '../../assets/json/Sundae 4/4.1 60 to 80.json'
import * as four_animationData5 from '../../assets/json/Sundae 4/4.1 80 to 100.json'
import * as four_animationData6 from "../../assets/json/Sundae 4/4.1 100 to.json"

import * as five_animationData0 from '../../assets/json/Sundae 5/5.1 0.json'
import * as five_animationData from '../../assets/json/Sundae 5/5.1 0 to 20.json'
import * as five_animationData2 from '../../assets/json/Sundae 5/5.1 20 to 40.json'
import * as five_animationData3 from '../../assets/json/Sundae 5/5.1 40 to 60.json'
import * as five_animationData4 from '../../assets/json/Sundae 5/5.1 60 to 80.json'
import * as five_animationData5 from '../../assets/json/Sundae 5/5.1 80 to 100.json'
import * as five_animationData6 from "../../assets/json/Sundae 5/5.1 100 to.json"

import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { toast } from 'react-toastify';
import { default as MyModal } from "../../Components/Common/Modal";
import { useDispatch } from 'react-redux';

export default function QuantitativeChart(props) {
    const [primary, setPrimary] = useState(props.primary);
    const [celebration1, setCelebration1] = useState(false);
    const [celebration2, setCelebration2] = useState(false);
    const [celebration3, setCelebration3] = useState(false);
    const [celebration4, setCelebration4] = useState(false);
    const [celebration5, setCelebration5] = useState(false);
    const [animationCup1, setanimationCup1] = useState(animationData);
    const [animationCup2, setanimationCup2] = useState(two_animationData);
    const [animationCup3, setanimationCup3] = useState(three_animationData);
    const [animationCup4, setanimationCup4] = useState(four_animationData);
    const [animationCup5, setanimationCup5] = useState(five_animationData);
    const [celebrationSparkels, setCelebrationSparkels] = useState(animationData7);
    const [DeleteConfirm, setDeleteConfirm] = useState(false);
    const [DeleteData, setDeleteData] = useState({});

    const history = useHistory();
    const quantitativeDetail = props.quantitativeDetail;
    const deleteManager = props.deleteManager;
    const employee_id = props.employee_id;
    const getEmployerTeamData = props.getEmployerTeamData;
    const dispatch = useDispatch();

    useEffect(() => {
        let primary = [];
    
        props?.quantitativeDetail?.goalStats?.forEach((innerDetail, index) => {
            if (index <= 4) {
                primary.push(innerDetail)
            }
        })


        setPrimary(primary)
        if (primary.length > 0) {
            for (let i = 0; i < primary.length; i++) {
                if (i === 0) {
                    if (primary[0].quantitative_stats_percent == 0 ) {
                        setanimationCup1(animationData0)
                    }
                    else if (primary[0].quantitative_stats_percent > 0 && primary[0].quantitative_stats_percent <= 20) {
                        setanimationCup1(animationData)
                    }
                    else if (primary[0].quantitative_stats_percent > 20 && primary[0].quantitative_stats_percent <= 40) {
                        setanimationCup1(animationData2)
                    }
                    else if (primary[0].quantitative_stats_percent > 40 && primary[0].quantitative_stats_percent <= 60) {
                        setanimationCup1(animationData3)
                    }
                    else if (primary[0].quantitative_stats_percent > 60 && primary[0].quantitative_stats_percent <= 80) {
                        setanimationCup1(animationData4)
                    }
                    else if (primary[0].quantitative_stats_percent > 80 && primary[0].quantitative_stats_percent < 100) {
                        setanimationCup1(animationData5)
                     

                    }
                    else if (primary[0].quantitative_stats_percent >= 100) {
                        setanimationCup1(animationData6)
                    }
                }
                else if (i === 1) {
                    if (primary[1].quantitative_stats_percent == 0 ) {
                        setanimationCup2(two_animationData0)
                    }
                    if (primary[1].quantitative_stats_percent > 0 && primary[1].quantitative_stats_percent <= 20) {
                        setanimationCup2(two_animationData)
                    }
                    else if (primary[1].quantitative_stats_percent > 20 && primary[1].quantitative_stats_percent <= 40) {
                        setanimationCup2(two_animationData2)
                    }
                    else if (primary[1].quantitative_stats_percent > 40 && primary[1].quantitative_stats_percent <= 60) {
                        setanimationCup2(two_animationData3)
                    }
                    else if (primary[1].quantitative_stats_percent > 60 && primary[1].quantitative_stats_percent <= 80) {
                        setanimationCup2(two_animationData4)
                    }
                    else if (primary[1].quantitative_stats_percent > 80 && primary[1].quantitative_stats_percent < 100) {
                        setanimationCup2(two_animationData5)
                     

                    }
                    else if (primary[1].quantitative_stats_percent >= 100) {
                        setanimationCup2(two_animationData6)
                    }
                }
                else if (i === 2) {
                    if (primary[2].quantitative_stats_percent == 0 ) {
                        setanimationCup3(three_animationData0)
                    }
                    if (primary[2].quantitative_stats_percent > 0 && primary[2].quantitative_stats_percent <= 20) {
                        setanimationCup3(three_animationData)
                    }
                    else if (primary[2].quantitative_stats_percent > 20 && primary[2].quantitative_stats_percent <= 40) {
                        setanimationCup3(three_animationData2)
                    }
                    else if (primary[2].quantitative_stats_percent > 40 && primary[2].quantitative_stats_percent <= 60) {
                        setanimationCup3(three_animationData3)
                    }
                    else if (primary[2].quantitative_stats_percent > 60 && primary[2].quantitative_stats_percent <= 80) {
                        setanimationCup3(three_animationData4)
                    }
                    else if (primary[2].quantitative_stats_percent > 80 && primary[2].quantitative_stats_percent < 100) {
                        setanimationCup3(three_animationData5)
                      
                    } else if (primary[2].quantitative_stats_percent >= 100) {
                        setanimationCup3(three_animationData6)
                    }

                }
                else if (i === 3) {
                    if (primary[3].quantitative_stats_percent == 0 ) {
                        setanimationCup4(four_animationData0)
                    }
                    else if (primary[3].quantitative_stats_percent > 0 && primary[3].quantitative_stats_percent <= 20) {
                        setanimationCup4(four_animationData)
                    }
                    else if (primary[3].quantitative_stats_percent > 20 && primary[3].quantitative_stats_percent <= 40) {
                        setanimationCup4(four_animationData2)
                    }
                    else if (primary[3].quantitative_stats_percent > 40 && primary[3].quantitative_stats_percent <= 60) {
                        setanimationCup4(four_animationData3)
                    }
                    else if (primary[3].quantitative_stats_percent > 60 && primary[3].quantitative_stats_percent <= 80) {
                        setanimationCup4(four_animationData4)
                    }
                    else if (primary[3].quantitative_stats_percent > 80 && primary[3].quantitative_stats_percent < 100) {
                        setanimationCup4(four_animationData5)
                      
                    } else if (primary[3].quantitative_stats_percent >= 100) {
                        setanimationCup4(four_animationData6)
                    }
                }
                else if (i === 4) {
                    if (primary[4].quantitative_stats_percent == 0) {
                        setanimationCup5(five_animationData0)
                    }
                    else if (primary[4].quantitative_stats_percent > 0 && primary[4].quantitative_stats_percent <= 20) {
                        setanimationCup5(five_animationData)
                    }
                    else if (primary[4].quantitative_stats_percent > 20 && primary[4].quantitative_stats_percent <= 40) {
                        setanimationCup5(five_animationData2)
                    }
                    else if (primary[4].quantitative_stats_percent > 40 && primary[4].quantitative_stats_percent <= 60) {
                        setanimationCup5(five_animationData3)
                    }
                    else if (primary[4].quantitative_stats_percent > 60 && primary[4].quantitative_stats_percent <= 80) {
                        setanimationCup5(five_animationData4)
                    }
                    else if (primary[4].quantitative_stats_percent > 80 && primary[4].quantitative_stats_percent < 100) {
                        setanimationCup5(five_animationData5)
                      
                    }
                    else if (primary[4].quantitative_stats_percent >= 100) {
                        setanimationCup5(five_animationData6)
                    }
                }
            }

        }

    }, [props.quantitativeDetail, props.primary])

    const celebrationCup = {
        loop: false,
        autoplay: true,
        animationData: celebrationSparkels.default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }


    const cup1 = {
        loop: false,
        autoplay: true,
        animationData: animationCup1.default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }
    const cup2 = {
        loop: false,
        autoplay: true,
        animationData: animationCup2.default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }
    const cup3 = {
        loop: false,
        autoplay: true,
        animationData: animationCup3.default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }
    const cup4 = {
        loop: false,
        autoplay: true,
        animationData: animationCup4.default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }
    const cup5 = {

        loop: false,
        autoplay: true,
        animationData: animationCup5.default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    const eventListeners1 = [
        {
            eventName: 'complete', callback: () => {
                primary.forEach((stats, index) => {
                    if (index === 0 && stats.quantitative_stats_percent >= 100) {
                        setCelebration1(true)
                    }
                })

            }
        }
    ];
    const eventListeners2 = [
        {
            eventName: 'complete', callback: () => {
                primary.forEach((stats, index) => {
                    if (index === 1 && stats.quantitative_stats_percent >= 100) {
                        setCelebration2(true)
                    }
                })

            }
        }
    ];
    const eventListeners3 = [
        {
            eventName: 'complete', callback: () => {
                primary.forEach((stats, index) => {
                    if (index === 2 && stats.quantitative_stats_percent >= 100) {
                        setCelebration3(true)
                    }
                })

            }
        }
    ];
    const eventListeners4 = [
        {
            eventName: 'complete', callback: () => {
                primary.forEach((stats, index) => {
                    if (index === 3 && stats.quantitative_stats_percent >= 100) {
                        setCelebration4(true)
                    }
                })

            }
        }
    ];
    const eventListeners5 = [
        {
            eventName: 'complete', callback: () => {
                primary.forEach((stats, index) => {
                    if (index === 4 && stats.quantitative_stats_percent >= 100) {
                        setCelebration5(true)
                    }
                })

            }
        }
    ];




    const handleClick = () =>{
        setDeleteConfirm(_=>!_);
    }

    const handleDeleteGoal = async ()=>{
        if(DeleteData?.id != "" && DeleteData?.employee_id != ""){
            await dispatch({ type: "DELETEMANAGERGOAL", value: DeleteData?.id,employee_id:DeleteData?.employee_id })
            toast.error('Goal deleted successfully!');
            handleClick();
        }
    }

    const DeleteManagerGoal = ({id}) =>{
        return <div className='text-center text-theme w-fit mx-auto mt-1 cursor-pointer hover-underline text-xs' onClick={()=>{
            handleClick();
            setDeleteData({ id,employee_id });
        }}>
            Delete
        </div>
    }


    return (
        <div>
            <section class="Goals-Stats-wrapper pb-3">
                <div class="container">
                    <div class="Goals-Stats">
                    <div class="heding" style={{
                            display: 'flex', flexDirection: 'row',
                            justifyContent: quantitativeDetail && quantitativeDetail?.goalStats?.length > 4 ? 'space-between' : 'center',

                        }}>
                                                        <div >
                                <h2>Progress</h2>
                                <hr style={{ width: '100%' }} class="border-pink" />
                            </div>
                            {quantitativeDetail && quantitativeDetail?.goalStats?.length > 4 && <div onClick={() => {
                                history.push({ pathname: '/chartDetail', state: { quantitativeDetail: props.quantitativeDetail,employee_id } })

                            }} style={{ textAlign: 'center', marginLeft: '2rem' }} class="Consultation-button">
                                <button

                                    style={{ width: '10rem' }}>View Details</button>

                            </div>}


                        </div>
                        {primary && primary?.length > 0 ?
                            <div >
                                <div class="">
                                    <div style={{}}>
                                        <div>


                                            <div style={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
                                                {primary.length > 0 &&

                                                    <div style={{ position: 'relative', textAlign: 'center' }}>

                                                        {celebration1 === true ?
                                                            <div style={{ position: "absolute", top: "-200px" }}>
                                                                <Lottie options={celebrationCup}
                                                                    height={300}
                                                                    width={200}
                                                                    isStopped={false}
                                                                    isPaused={false}
                                                                />
                                                            </div> : <div></div>
                                                        }


                                                        <div>
                                                            <Lottie options={cup1}
                                                                height={400}
                                                                width={200}
                                                                isStopped={false}
                                                                isPaused={false}
                                                                eventListeners={eventListeners1}
                                                            />
                                                        </div>
                                                        <div style={{ marginTop: "1rem", display: 'flex', justifyContent: "space-around" }}>
                                                          
                                                        </div>
                                                        <div style={{ marginTop: '1rem', lineHeight: "1.5" }}>
                                                            {primary[0]?.team_goal.title} - {Math.trunc(primary[0].quantitative_stats_percent)}%
                                                        </div>
                                                        <DeleteManagerGoal id={primary[0]?.goal_id} />
                                                    </div>
                                                }

                                                {primary.length > 1 &&

                                                    <div style={{ position: 'relative', textAlign: 'center' }}>

                                                        {celebration2 === true ?
                                                            <div style={{ position: "absolute", top: "-200px" }}>
                                                                <Lottie options={celebrationCup}
                                                                    height={300}
                                                                    width={200}
                                                                    isStopped={false}
                                                                    isPaused={false}
                                                                />
                                                            </div> : <div></div>}

                                                        <div>
                                                            <Lottie options={cup2}
                                                                height={400}
                                                                width={200}
                                                                isStopped={false}
                                                                isPaused={false}
                                                                eventListeners={eventListeners2}
                                                            />
                                                        </div>
                                                        <div style={{ marginTop: "1rem", display: 'flex', justifyContent: "space-around" }}>
                                                           
                                                        </div>
                                                        <div style={{ marginTop: '1rem', lineHeight: "1.5" }}>
                                                            {primary[1]?.team_goal.title} - {Math.trunc(primary[1].quantitative_stats_percent)}%
                                                        </div>
                                                        <DeleteManagerGoal id={primary[1]?.goal_id} />

                                                    </div>
                                                }

                                                {primary.length > 2 &&

                                                    <div style={{ position: 'relative', textAlign: 'center' }}>

                                                        {celebration3 === true ?
                                                            <div style={{ position: "absolute", top: "-200px" }}>
                                                                <Lottie options={celebrationCup}
                                                                    height={300}
                                                                    width={200}
                                                                    isStopped={false}
                                                                    isPaused={false}
                                                                />
                                                            </div> : <div></div>}

                                                        <div>
                                                            <Lottie options={cup3}
                                                                height={400}
                                                                width={200}
                                                                isStopped={false}
                                                                isPaused={false}
                                                                eventListeners={eventListeners3}
                                                            />
                                                        </div>
                                                        <div style={{ marginTop: "1rem", display: 'flex', justifyContent: "space-around" }}>
                                                        
                                                        </div>
                                                        <div style={{ marginTop: '1rem', lineHeight: "1.5" }}>
                                                            {primary[2]?.team_goal.title} - {Math.trunc(primary[2].quantitative_stats_percent)}%
                                                        </div>
                                                        <DeleteManagerGoal id={primary[2]?.goal_id} />
                                                    </div>
                                                }
                                                {primary.length > 3 &&


                                                    <div style={{ position: 'relative', textAlign: 'center' }}>

                                                        {celebration4 === true ?
                                                            <div style={{ position: "absolute", top: "-200px" }}>
                                                                <Lottie options={celebrationCup}
                                                                    height={300}
                                                                    width={200}
                                                                    isStopped={false}
                                                                    isPaused={false}
                                                                />
                                                            </div> : <div></div>}

                                                        <div>
                                                            <Lottie options={cup4}
                                                                height={400}
                                                                width={200}
                                                                isStopped={false}
                                                                isPaused={false}
                                                                eventListeners={eventListeners4}

                                                            />
                                                        </div>
                                                        <div style={{ marginTop: "1rem", display: 'flex', justifyContent: "space-around" }}>
                                                    
                                                        </div>
                                                        <div style={{ marginTop: '1rem', lineHeight: "1.5" }}>
                                                            {primary[3]?.team_goal.title} - {Math.trunc(primary[3].quantitative_stats_percent)}%
                                                        </div>
                                                        <DeleteManagerGoal id={primary[3]?.goal_id} />
                                                    </div>
                                                }
                                                {primary.length > 4 &&

                                                    <div style={{ position: 'relative', textAlign: 'center' }}>

                                                        {celebration5 === true ?
                                                            <div style={{ position: "absolute", top: "-200px" }}>
                                                                <Lottie options={celebrationCup}
                                                                    height={300}
                                                                    width={200}
                                                                    isStopped={false}
                                                                    isPaused={false}
                                                                />
                                                            </div> : <div></div>}

                                                        <div>
                                                            <Lottie
                                                                eventListeners={eventListeners5}
                                                                options={cup5}
                                                                height={400}
                                                                width={200}
                                                                isStopped={false}
                                                                isPaused={false}
                                                            />
                                                        </div>
                                                        <div style={{ marginTop: "1rem", display: 'flex', justifyContent: "space-around" }}>
                                                          
                                                        </div>
                                                        <div style={{ marginTop: '1rem', lineHeight: "1.5" }}>
                                                            {primary[4]?.team_goal.title} - {Math.trunc(primary[4].quantitative_stats_percent)}%
                                                        </div>
                                                        <DeleteManagerGoal id={primary[4]?.goal_id} />
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            : <div class="heding">
                                {props.from === "teammember" ? <h4>No goals have been assigned</h4>
                                    : <h4>No goals have been assigned yet</h4>
                                }
                            </div>}

                    </div>

                </div>

            </section>
            <MyModal
                showmodal={DeleteConfirm}
                onCLose={handleClick}
                title={`Delete Goal?`}
                body={<div>Are you sure you want to delete this goal?</div>}
                handleClick={handleDeleteGoal}
            />
        </div>
    )
}
