import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { GET } from "../utils/helper";
import QuantitativeChart from "./QuantitativeChart";
import ReactStars from "react-rating-stars-component";
import { useHistory } from "react-router-dom";
import { isArray } from "lodash";

const TeamDetail = (props) => {
  const index = props.match.params.id;
  const [updatedAt, setUpdatedAT] = useState(null);

  const [quantitativeDetail, setQuantitaveDetail] = useState(null);
  const [quantitativeDetailEmployee, setQuantitaveDetailEmployee] = useState(
    null
  );
  const [primary, setPrimary] = useState([]);
  const [isPrimary, setIsPrimary] = useState(false);

  const history = useHistory();

  useEffect(() => {
    props.getTeamDetailData(index);

    const getQuantitativeStats = async () => {
      const res = await GET(`getQuantitativeStatsOfGoalsAsManager/${index}`);

      setQuantitaveDetail(res.body.data.quantitativeStats);

      let newprimary = [];
      res.body.data.quantitativeStats.forEach((item) => {
        if (item.is_primary) {
          newprimary.push(item);
        }
      });

      if (newprimary.length > 0) {
        setIsPrimary(true);
        setPrimary(newprimary);
      } else {
        setIsPrimary(false);
        setPrimary(res.body.data.quantitativeStats);
      }
    };

    const getQualitativeMeasurement = async () => {
      try {
        const res = await GET(`getAttributeRatings?employee_id=${index}`);

        if (res?.body?.data) {
          setQuantitaveDetailEmployee(res?.body?.data);

          setUpdatedAT(res.body.data?.updatedAt?.slice(0, 10));
        } else {
          setQuantitaveDetailEmployee(null);
        }
      } catch (error) {
        console.log(error);
        setQuantitaveDetailEmployee(null);
      }
    };

    getQuantitativeStats();
    getQualitativeMeasurement();
  }, [updatedAt]);

  return (
    <div>
      <section class="profiles-wrapper-main">
        <div class="container">
          <div class="profiles-wrapper">
            <div class="profile-comman-box">
              <div
                class="row"
                style={{ textAlign: "center", justifyContent: "center" }}
              >
                <div class="col-md-12 comman-width one">
                  <div class="main-profiles">
                    <div class="profile-img">
                      {props.teamDetailData?.profile_pic_url ? (
                        <img
                          alt="profile"
                          style={{
                            width: "68px",
                            borderRadius: "50%",
                            height: "68px",
                          }}
                          src={props.teamDetailData?.profile_pic_url}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="row no-gutters">
                      <div class="col-md-6  col-sm-6 col-6">
                        <div class="qution">
                          <p>Employee Name :</p>
                          <p>Department :</p>
                          <p>Mobile Number :</p>
                          <p>Email Address :</p>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-6 col-6">
                        <div class="details">
                          <p className="textwrap">
                            {props?.teamDetailData?.name}
                          </p>
                          <p className="textwrap">
                            {props?.teamDetailData?.department?.name}
                          </p>
                          <p className="textwrap">
                            {props?.teamDetailData?.phone_number}
                          </p>
                          <p className="textwrap">
                            {props?.teamDetailData?.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <QuantitativeChart
        from={"teamdetail"}
        name={props?.teamDetailData?.name}
        quantitativeDetail={quantitativeDetail}
        primary={primary}
        isPrimary={isPrimary}
        id={props.location.state.item.team_member_id}
      />

      <section class="Qualitative-wrapper" style={{ paddingBottom: "32px" }}>
        <div class="container">
          <div class="Qualitative">
            <div
              class="heding"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                height: "10rem",
              }}
            >
              <div>
                <h2>Qualitative Measures</h2>
                <hr style={{ width: "100%" }} class="border-pink" />
              </div>
            </div>

            {isArray(quantitativeDetailEmployee) ? (
              <div>
                <div class="Qualitative-details">
                  {quantitativeDetailEmployee?.map((item, index) => (
                    <div class="Qualitative-row" key={index}>
                      <div
                        class="row"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div class="col-md-3 col-sm-4 col-12 Qualitative-name">
                          <p>{item?.attribute?.name}</p>
                        </div>
                        <div class="col-md-3">
                          <div
                            class="Qualitative-star float-right"
                            style={{ fontSize: "35px" }}
                          >
                            <ReactStars
                              {...{
                                color: "#C9C9C9",
                                size: 30,
                                value: item.rating,
                                edit: false,
                                activeColor: "#FF7500",
                              }}
                            />
                          </div>
                        </div>
                        <div>
                          <i
                            style={{ color: "#FF7500", fontSize: "31px" }}
                            class="fa fa-eye"
                            aria-hidden="true"
                            onClick={() =>
                              history.push({
                                pathname: "/qualitativedetailmanager",
                                state: {
                                  item,
                                  from: "manager",
                                  id: props.teamDetailData?.id,
                                  updatedAt: updatedAt,
                                },
                              })
                            }
                          >
                            {" "}
                          </i>
                        </div>

                        <div>
                          {" "}
                          <i
                            style={{ color: "#FF7500", fontSize: "30px" }}
                            class="fa fa-pencil"
                            aria-hidden="true"
                            onClick={() =>
                              history.push({
                                pathname: "/rating",
                                state: { id: props.teamDetailData?.id },
                              })
                            }
                          ></i>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div class="heding">
                <h4>No rating has been given</h4>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => {
  const teamDetailData = state.teamReducer.teamDetailData;
  const errorMsg = state.teamReducer.errorMsg;
  const loading = state.globalReducer.loading;
  return {
    teamDetailData,
    loading,
    errorMsg,
    rateDetailData: state.ratingReducer.rateDetailData,
  };
};

const mapDispatch = (dispatchEvent) => ({
  getTeamDetailData: (payload) =>
    dispatchEvent({ type: "GET_TEAMDETAILDATA", value: payload }),
  getTeamRatingData: (payload) =>
    dispatchEvent({ type: "GET_TEAMRATINGDATA", value: payload }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(TeamDetail);
