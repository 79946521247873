import React, { useEffect, useState } from 'react';

import CoachChatThread from './CoachChatThread'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';

import { GET } from '../../utils/helper';
const Chat = (props) => {
    const userId = localStorage.getItem("id")
    const [chatUser, setChatList] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        GET('getChatList', 2).then(async (resp) => {


            if (resp.body.data) {
                var tempArray = []
                for (var i = 0; i < resp.body.data.length; i++) {

                    if (resp.body.data[i].type == 2) {

                        var userData = resp.body.data[i].user
                        var temp = {
                            id: resp.body.data[i].id,
                            profilePic: null,
                            name: resp.body.data[i].group_name,
                            lastMessage: "",
                            lastMessageTime: "",
                            room_id: resp.body.data[i].room_id,
                            user: null,
                            is_disabled: resp.body.data[i].is_disabled,
                            type: resp.body.data[i].type,
                            chatLastDeletedOn: resp.body.data[i].chatLastDeletedOn,

                            group_members: resp.body.data[i].group_members

                        }
                        tempArray.push(temp)


                        continue;
                    }

                    var userData = resp.body.data[i].user
                    var temp = {
                        id: userData.id,
                        profilePic: userData.profile_pic_url,
                        name: userData.name,
                        lastMessage: "",
                        lastMessageTime: "",
                        room_id: resp.body.data[i].room_id,
                        user: resp.body.data[i].user,
                        is_disabled: resp.body.data[i].is_disabled,
                        type: resp.body.data[i].type,
                        chatLastDeletedOn: resp.body.data[i].chatLastDeletedOn,



                    }
                    tempArray.push(temp)



                }
                setChatList(tempArray)
            }
            setLoading(false)



        })
            .catch((err) => {
                setLoading(false)


                if (err && err.msg && err.msg.message) {
                    toast.error(err.msg.message, {
                        position: toast.POSITION.BOTTOM_CENTER,
                    })
                }
            })


    }, []);



    const resortChatList = () => {

        try {
            let chatList = [...chatUser];
            let sortedChatList = chatList.sort((el1, el2) => {
                const l1 = el1.lastMessageTime ? (el1.lastMessageTime) : -1;
                const l2 = el2.lastMessageTime ? (el2.lastMessageTime) : -1;
                return l2 - l1;
            });
            setChatList(sortedChatList)
            setLoading(false)
        } catch (error) {
            console.log('Error ', error);
            setLoading(false)
        }
    }
    const updateLastMessageForRoom = async (roomId, message) => {

        let deepCopyUsers = [...chatUser];
        for (let i = 0; i < deepCopyUsers.length; i++) {
            if (deepCopyUsers[i].room_id == roomId) {
                deepCopyUsers[i].lastMessage = message?.text;
                deepCopyUsers[i].lastMessageId = message?._id;
                deepCopyUsers[i].lastMessageTime = message?.timestamp;
                deepCopyUsers[i].lastMessageUserId = message?.user?._id;
                deepCopyUsers[i].isRead = message?.isRead;
            }
        }
        setChatList(deepCopyUsers)
        setLoading(false)
        resortChatList()

    }



    const onProfilePress = () => {
        alert("Work under Progress")
    };
    const onCallPress = () => {
        alert("Work under Progress")
    };

    const onVideoPress = () => {
        alert("Work under Progress")
    };

    const onChatPress = () => {
        alert("Work under Progress")
    };

    const renderItem = (item, index) => {

        const { profilePic, name, room_id, lastMessage, lastMessageId, lastMessageTime, lastMessageUserId, isRead, type,
            group_members, chatLastDeletedOn } = item;
        return (
            <CoachChatThread
                key={room_id}
                profilePic={profilePic}
                name={name}
                item={item}
                history={props.history}
                type={type}
                onProfilePress={() => onProfilePress}
                onCallPress={() => onCallPress}
                onVideoPress={() => onVideoPress}
                onChatPress={() => onChatPress}
                updateLastMessageForRoom={updateLastMessageForRoom}
                chatLastDeletedOn={chatLastDeletedOn}
                roomId={room_id}
                lastMessage={lastMessage ? lastMessage : ""}
                lastMessageId={lastMessageId ? lastMessageId : ""}
                lastMessageTime={lastMessageTime ? lastMessageTime : ""}

                group_members={group_members ? group_members : null}
                isMessageRead={lastMessageUserId && lastMessageUserId == userId ? (true) : isRead}
            />
        );
    };


    return (
        <React.Fragment>
            <section style={{ minHeight: '60vh' }} className="chat-details-wrapper bg-color">
                {!loading && chatUser.length === 0 && <div className="teammsg">No Team Member to Chat!</div>}

                {loading &&
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>}
                <div className="container chatthread" style={{ padding: '45px !important' }}>
                    {chatUser.length > 0 && chatUser?.map((data, id) => (
                        renderItem(data)
                    ))}
                </div>
            </section>

        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    const loading = state.globalReducer.loading
    return {
        chatUser: state.chatReducer.chatUser,
        loading
    }
}

const mapDispatch = dispatchEvent => ({
    getChatUser: (payload) => dispatchEvent({ type: 'Get_ChatUser', value: payload }),
    onFieldChange: (payload) => dispatchEvent({ type: 'ONFIELD_CHANGE', value: payload }),
    creteChatRoom: (payload) => dispatchEvent({ type: 'CREATECHAT_ROOM', value: payload }),
})

export default connect(mapStateToProps, mapDispatch)(Chat)