import React, { useState } from 'react'
import logofree from "../../../../src/assets/images/logo-free.png"
import freeFile from "../../../../src/assets/images/free-file.png";
import { useHistory } from "react-router-dom";
import { GET } from "../../../utils/helper";
import { toast } from "react-toastify"

export default function FreeTrial() {

    const history = useHistory();
    const [loader, setLoader] = useState(false);


    const handleStartFreeTrial = async () => {
        try {
            setLoader(true);
            const result = await GET(`startFreeTrial`);
            setLoader(false);

            if (result.status === 200) {
                
                localStorage.setItem('free_trial_status', 100)
                localStorage.setItem('subscription_type', 100)

                history.push('/employerdashboard');
            }
        } catch (error) {
            setLoader(false);
            toast.error(error.response.data.message, {
                position: toast.POSITION.BOTTOM_CENTER,
            })
        }
    }





    return (
        <div>
            <div class="free-trial-sign">
                <div class="container">
                    <div class="free-trial-img-logo">
                        <img class="logo" src={logofree} alt="" />
                        <img class="edit-free-trail" src={freeFile} alt="" />
                    </div>
                </div>
                <div class="trail-info-wrapper">

                    <div style={{ minHeight: '30vh' }} class="container">

                        <div class="trail-info">
                            {!loader ?
                                <div>
                                    <div class="Subscription-btn">
                                        <button onClick={handleStartFreeTrial} >
                                            Start Free Trial
                                        </button>
                                    </div>


                                </div>
                                :
                                <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            }
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}
