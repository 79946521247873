import React, { useState, useEffect } from "react";
import "./style.scss";
import Svgs from "../../assets/Svgs";
import { CSVLink } from "react-csv";
import { getExportStats } from "./../../hooks/analytics.hook";
import LoadingWidget from "../LoadingWidget";

const StatsWidget = () => {
  const [data, setData] = useState({
    drillDownload: {
      data: [
        {
          employeeId: 1,
          employeeName: "Demo Name",
          months: [
            {
              monthName: "July",
              moodCount: 0,
              noActivity: 0,
              goalActivity: 0,
            },
          ],
        },
      ],
    },
    download: {
      data: [{ month: "July", moodCount: 0, noActivity: 0, goalActivity: 0 }],
    },
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getExportStats(setData, setLoading);
  }, []);

  const metricLabels = {
    moodCount: "Total vibe check",
    noActivity: "Employee no activity",
    goalActivity: "Total goal update",
  };

  const transformDataForCSV = (monthData) => {
    return [
      { metric: metricLabels.moodCount, value: monthData.moodCount },
      { metric: metricLabels.noActivity, value: monthData.noActivity },
      { metric: metricLabels.goalActivity, value: monthData.goalActivity },
    ];
  };

  const generateHeaders = (month) => {
    return [
      { label: "", key: "metric" },
      { label: month, key: "value" },
    ];
  };

  const transformAnnualDataForCSV = (data) => {
    const metrics = Object.keys(metricLabels);
    const transformedData = metrics.map((metric) => {
      const row = { metric: metricLabels[metric] };
      data.forEach((monthData) => {
        row[monthData.month.toLowerCase()] = monthData[metric];
      });
      return row;
    });
    return transformedData;
  };


  const generateAnnualHeaders = () => {
    const headers = [{ label: "Metric", key: "metric" }];
    data &&
      data.download.data.forEach((monthData) => {
        headers.push({
          label: monthData.month,
          key: monthData.month.toLowerCase(),
        });
      });
    return headers;
  };

  const transformDrillDataForCSV = (monthName) => {
    const metrics = Object.keys(metricLabels);
    const transformedData = metrics.map((metric) => {
      const row = { metric: metricLabels[metric] };
      data.drillDownload.data.forEach((employee) => {
        const monthData = employee.months.find(
          (month) => month.monthName === monthName
        );
        row[employee.employeeName.toLowerCase().replace(/\s/g, "")] =
          monthData && monthData[metric] !== undefined ? monthData[metric] : 0;
      });
      return row;
    });
    return transformedData;
  };

  const generateDrillHeaders = () => {
    const headers = [{ label: "Metric", key: "metric" }];
    data &&
      data.drillDownload.data.forEach((employee) => {
        headers.push({
          label: employee.employeeName,
          key: employee.employeeName.toLowerCase().replace(/\s/g, ""),
        });
      });
    return headers;
  };

  return (
    <div className="stats-container">
      {!loading ? (
        <>
          <div className="header">
            <p>Stats</p>
            <CSVLink
              data={transformAnnualDataForCSV(data && data.download.data)}
              headers={generateAnnualHeaders()}
              filename="Annual-Report.csv"
            >
              <button className="btn-sm btn-year-export h-100 py-2">
                <span className="pr-1">Annual Report</span>
                <Svgs.Download />
              </button>
            </CSVLink>
          </div>
          {data &&
            data.download.data.map((monthData, index) => (
              <div className="stats-body" key={index}>
                <span className="stats-title">{monthData.month}</span>
                <div className="stats-button-container">
                  <CSVLink
                    data={transformDataForCSV(monthData)}
                    headers={generateHeaders(monthData.month)}
                    filename={`${monthData.month}-Report.csv`}
                  >
                    <button className="btn-sm btn-export h-100 py-2">
                      Download
                    </button>
                  </CSVLink>
                  <CSVLink
                    data={transformDrillDataForCSV(monthData.month)}
                    headers={generateDrillHeaders()}
                    filename={`${monthData.month}-Drill-Report.csv`}
                  >
                    <button className="btn-sm btn-year-export h-100 py-2">
                      Drill Download
                    </button>
                  </CSVLink>
                </div>
              </div>
            ))}
        </>
      ) : (
        <div className="loading">
          <LoadingWidget />
        </div>
      )}
    </div>
  );
};

export default StatsWidget;
