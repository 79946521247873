import { MANAGER, EMPLOYER, EMPLOYEE } from "./utils/roles";

const routes = [
  { routeName: "login", allowedRoles: [MANAGER, EMPLOYER, EMPLOYEE] },
  { routeName: "forgetpassword" },
  { routeName: "employerforgetpassword" },
  { routeName: "home", hideManagerLook: true },
  { routeName: "pricing", hideManagerLook: true },
  { routeName: "founder", hideManagerLook: true },
  { routeName: "coaches" },
  { routeName: "resources", hideManagerLook: true },
  { routeName: "contactushome", hideManagerLook: true },
  { routeName: "advisors" },
  { routeName: "/coachforgetpassword" },
  { routeName: "employerlogin", allowedRoles: [MANAGER, EMPLOYER, EMPLOYEE] },
  { routeName: "/coachlogin" },
  { routeName: "resetPassword" },
  { routeName: "" },
  { routeName: "dashboard", allowedRoles: [MANAGER, EMPLOYEE] },
  { routeName: "analytics", allowedRoles: [MANAGER, EMPLOYER] },
  { routeName: "resetpasswordone" },
  { routeName: "employerresetpassword" },
  { routeName: "profile" },
  { routeName: "team", allowedRoles: [MANAGER] },
  { routeName: "teamemployeedetail" },
  { routeName: "rating" },
  { routeName: "empgoals" },
  { routeName: "goal", allowedRoles: [MANAGER] },
  { routeName: "viewmanagergoal", allowedRoles: [MANAGER] },
  { routeName: "goalaproval" },
  { routeName: "addnewgoal", allowedRoles: [MANAGER] },
  { routeName: "edit-goal", allowedRoles: [MANAGER] },
  { routeName: "employeegoal" },
  { routeName: "qualitativedetail" },
  { routeName: "myprofile", allowedRoles: [EMPLOYEE] },
  { routeName: "chat", allowedRoles: [MANAGER, EMPLOYEE] },
  { routeName: "tangocoachavailabilty" },
  { routeName: "groupChatDetail" },
  { routeName: "changepassword", allowedRoles: [MANAGER, EMPLOYEE] },
  { routeName: "listofcoaches" },
  { routeName: "/checkcoachavailability" },
  { routeName: "employerdashboard" },
  { routeName: "freetrial" },
  { routeName: "employerteam" },
  { routeName: "addemployee" },
  { routeName: "viewemployeedetail" },
  { routeName: "goalssubmissionhistory" },
  { routeName: "contactus", allowedRoles: [EMPLOYEE] },
  { routeName: "achievement" },
  { routeName: "achievement" },
  { routeName: "library" },
  { routeName: "refer", allowedRoles: [MANAGER, EMPLOYEE] },
  { routeName: "editemployerprofile" },
  { routeName: "employerProfile" },
  { routeName: "chartDetail" },
  { routeName: "attributeEmployerDetail" },
  { routeName: "changepasswordemployer" },
  { routeName: "contactusemployer" },
  { routeName: "/coachresetpassword" },
  { routeName: "/coachdashboard" },
  { routeName: "editcoachprofile" },
  { routeName: "/coachchat" },
  { routeName: "coachchatdetail" },
  { routeName: "planlist" },
  { routeName: "mysubscription" },
  { routeName: "mypayments" },
  { routeName: "startfreetrial" },
  { routeName: "ongoingfreetrial" },
  { routeName: "noplan" },
  { routeName: "employeechartdetail" },
  { routeName: "myattributes", allowedRoles: [MANAGER] },
  { routeName: "addattributes" },
  { routeName: "previewslots" },
  { routeName: "defineavailabilty" },
  { routeName: "defineslots" },
  { routeName: "/mysessions" },
  { routeName: "/mysessionrequest" },
  { routeName: "/sessionhistory" },
  { routeName: "/sessionhistory/:id" },
  { routeName: "/employeeSessions" },
  { routeName: "/employeeSessionsHistory" },
  { routeName: "/managecv" },
  { routeName: "qualitativedetailmanager" },
  { routeName: "stripe" },
];

export default routes;
