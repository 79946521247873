import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import Select, { components } from "react-select";

import uncheckedteam from "../../assets/images/uncheckedteam.png";
import checkedteam from "../../assets/images/checkedteam.png";
import { GET } from "../../utils/helper";

export default function AttributeDropDown(props) {
  const newTarget = "+ New target"
  const [target, setTarget] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    props?.selectedMembers || []
  );
  const [team, setTeam] = useState(null);

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    const options = [];
    props?.teamList?.map((e) =>
      options.push({
        value: e?.employee?.id || e?.id,
        label: e?.employee?.name || e?.name,
      })
    );
    options.unshift({ value: "all", label: "Select All" });
    setTeam(options);
  }, [props?.teamList]);

  useEffect(() => {
    const getTarget = async () => {
      try {
        const res = await GET(`getQualitativeMeasurementDetails`);
        res.body.data.push({
          comment: "other",
          id: 13,
          label: "+ New target",
          name: "+ New target",
          status: 1,
        });

        setTarget(res?.body?.data);
      } catch (error) {
        console.log(error);
      }
    };
    getTarget();
  }, [props.setIsOpen]);

  useEffect(() => {
    let selectedTargetText = props.formik.values.name || "Select Target";

    const getDetails = async () => {
      try {
        const result = await GET(
          `getQualitativeMeasurementDetails?name=${selectedTargetText}`
        );
        if (result.status === 200) {
          setFieldValue(`guidance`, result?.body?.data[0]?.comment || "");
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (props?.formik.values?.name !== newTarget) {
      getDetails();
    } else {
      setFieldValue(`guidance`, "");
    }
  }, [props?.formik?.values?.name]);

  const handleSelectChange = (selectedoption) => {
    let employeesIds = [];
    const isSelectAll = selectedoption.find((item) => item.value === "all");
    let selectedTeam = null;
    if (isSelectAll) {
      selectedTeam = team?.filter((item) => item.value !== "all");
    } else {
      selectedTeam = selectedoption;
    }
    setSelectedOption(selectedTeam);
    selectedTeam?.map((e) => employeesIds.push(e.value));
    props?.formik.setFieldValue("employees", employeesIds);
  };

  return (
    <div className="Goal-Detail wrapper">
      <div
        onClick={() => {
          props.setIsOpen(null);
        }}
        className="goal-head"
        style={{ position: "relative", height: "3rem" }}
      >
        <p>
          {!props.title ? `Attribute ${props?.outerIndex + 1}` : props.title}
        </p>
        <i
          style={{ position: "absolute", top: "35%", right: "2rem" }}
          className="fa fa-angle-up"
        ></i>
      </div>
      <div className="Goal-sales-wrapper">
        <div className="row">
          <div className="col-md-4">
            <div className="Goal-Title">
              <p>Target</p>
            </div>
          </div>
          <div className="col-md-7">
            <div className="Goal-Title">
              <select
                options={target}
                id={`name`}
                name={`name`}
                onChange={props?.formik.handleChange}
                onBlur={props?.formik.handleBlur}
                placeholder={"Select Target"}
                value={props?.formik?.values?.name}
              >
                <option value="">Select a target</option>

                {target
                  ? target?.map((item) => (
                      <>
                        <option key={item.name} value={item.name}>
                          {item.name}
                        </option>
                      </>
                    ))
                  : null}
              </select>

              {props.formik.touched?.name && props.formik.errors?.name ? (
                <div className="errmsg">{props.formik.errors?.name}</div>
              ) : null}
            </div>
          </div>
        </div>

        {props?.formik.values?.name === newTarget ? (
          <div className="row">
            <div className="col-md-4">
              <div className="Goal-Title">
                <p>Target name</p>
              </div>
            </div>
            <div className="col-md-7">
              <div className="Goal-Title">
                <input
                  style={{ width: "100%", backgroundColor: "white" }}
                  id={`other`}
                  name={`other`}
                  className="form-control"
                  onBlur={props?.formik.handleBlur}
                  onChange={props?.formik.handleChange}
                  value={props?.formik.values?.other}
                  rows="4"
                  placeholder="Enter target name"
                />
                {props.formik.touched?.other && props.formik.errors?.other ? (
                  <div className="errmsg">{props.formik.errors?.other}</div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}

        <div className="row">
          <div className="col-md-4">
            <div className="Goal-Title">
              <p>Guidance</p>
            </div>
          </div>
          <div className="col-md-7">
            <div className="Goal-Title">
              <input
                disabled={props?.formik.values?.name != newTarget}
                className="form-control"
                placeholder={
                  props?.formik.values?.name != newTarget
                    ? "Select Target"
                    : "Enter guidance"
                }
                id={`guidance`}
                name={`guidance`}
                onBlur={props?.formik.handleBlur}
                onChange={props?.formik.handleChange}
                value={props?.formik.values?.guidance}
                maxLength="90"
                rows="4"
              />
              {props.formik.touched.guidance && props.formik.errors.guidance ? (
                <div className="errmsg">{props.formik.errors?.guidance}</div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="Goal-Title">
              <p>Description</p>
            </div>
          </div>
          <div className="col-md-7">
            <div className="Goal-Title">
              <textarea
                style={{ width: "100%", backgroundColor: "white" }}
                id={`desc`}
                name={`desc`}
                maxLength="200"
                className="form-control"
                onBlur={props?.formik.handleBlur}
                onChange={props?.formik.handleChange}
                value={props?.formik.values?.desc}
                rows="4"
                placeholder="Enter attribute description"
              ></textarea>
              {props.formik.touched.desc && props.formik.errors.desc ? (
                <div className="errmsg">{props.formik.errors?.desc}</div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="Goal-Title">
              <p>Employees</p>
            </div>
          </div>
          <div className="col-md-7">
            <div className="Goal-Title">
              <Select
                isMulti
                name="employees"
                isSearchable={true}
                closeMenuOnSelect={false}
                onChange={handleSelectChange}
                options={team}
                value={selectedOption}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "rgb(255, 117, 0)",
                    primary: "rgb(255, 117, 0)",
                  },
                })}
                hideSelectedOptions={false}
                components={{ Option }}
                styles={colourStyles}
                placeholder="Select employees for this attribute"
              />
              {props.formik.touched.employees &&
              props.formik.errors.employees ? (
                <div className="errmsg">{props.formik.errors?.employees}</div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="row addgoal">
          <div className="col col-sm-6 col-md-6 col-xl-6 col-lg-6">
            <label className="addgoal-label">{props.label}</label>
          </div>
          <div className="text-right col col-sm-6 col-md-6 col-xl-6 col-lg-6">
            {props.isSelected ? (
              <img alt="checkteam" src={checkedteam} />
            ) : (
              <img alt="uncheckteam" src={uncheckedteam} />
            )}
          </div>
        </div>
      </components.Option>
    </div>
  );
};

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    borderRadius: 25,
  }),
  option: (styles) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: "black",

      ":active": {
        ...styles[":active"],
        backgroundColor: "white",
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "rgb(255, 117, 0)",
      borderRadius: 50,
      color: "white",
    };
  },

  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "white",
  }),
  menu: (provided, state) => ({
    ...provided,

    padding: 20,
    borderRadius: 14,
  }),
};
