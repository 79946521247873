import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Modal, ModalBody } from "reactstrap";
import { CModal, CModalBody } from "@coreui/react";
import { toast } from "react-toastify";

import worker from "../../assets/images/profilePlaceholder/profilePlaceholder.png";
import highFiveFill from "../../assets/images/achievement/high-five-fill.png";
import dot from "../../assets/images/achievement/dot-image.png";
import heartfill from "../../assets/images/achievement/heirt-fill.png";
import heartEmpty from "../../assets/images/achievement/heirt-like.png";
import highfive from "../../assets/images/achievement/high-five.png";
import cross from "../../assets/images/cross.png";
import { GET, POST } from "../../utils/helper";

import {
  handleDeleteAchievementHelper,
  handleHighFiveHelper,
  handleLikeHelper,
} from "../../utils/AchievementHelper";
import MentionInput from "../MentionInput";

function getCountText(count, singularLabel, pluralLabel) {
  if (count === 0 || count === 1) {
    return count + " " + singularLabel;
  } else {
    return count + " " + pluralLabel;
  }
}

function AchievementItem({ item, employees, targets, disableLink }) {
  let date = moment.utc(item?.createdAt).toDate();
  const achievementsdate = moment(date)
    .utc()
    .local()
    .startOf("minutes")
    .fromNow();

  const profile_pic_url = useSelector(
    (state) => state.loginReducer.loginData.profile_pic_url
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [reaction, setReaction] = useState({
    id: null,
    isLike: null,
    isComment: null,
    reactions: [],
    modalOpen: false,
    popupLoading: false,
  });

  const [post, setPost] = useState({
    id: null,
    createAchievementValue: "",
    isUpdate: false,
    createAchievementModal: false,
    refresh: false,
    editPostValue: "",
    isChanged: false,
  });

  const getAchievementList = useCallback(
    async (showLoading) => {
      try {
        dispatch({ type: "GET_ACHIEVEMENT_LIST", showLoading });
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch]
  );

  const handleCreateAchievement = (isUpdateVal, id, description) => {
    setPost((state) => ({
      ...state,
      isUpdate: isUpdateVal,
      createAchievementModal: true,
      id,
      editPostValue: description,
    }));
  };

  const handlePostUpdate = async () => {
    if (!post.isUpdate) {
      if (post.createAchievementValue.trim()?.length === 0) {
        toast.error("Post is not allowed to be empty", {
          position: toast.POSITION.BOTTOM_CENTER,
        });

        return;
      }
    } else {
      if (post.editPostValue.trim()?.length === 0) {
        toast.error("Post is not allowed to be empty", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        setPost((state) => ({ ...state, editPostValue: "", isUpdate: false, }));

        return;
      }
    }

    let data = {
      description: post.createAchievementValue,
      employees_mentions: post.employees_mentions,
      qualitative_tags: post.qualitative_tags,
    };

    let updateData = {
      achievement_id: post.id,
      description: post.editPostValue,
      employees_mentions: post.employees_mentions,
      qualitative_tags: post.qualitative_tags,
    };

    try {
      const result = await POST(
        "createUpdateAchievement",
        post.isUpdate ? updateData : data
      );

      if (result.status !== 200) {
        toast.error("Something went wrong", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
      if (!post.isUpdate) {
        setPost((state) => ({ ...state, createAchievementValue: "" }));
      } else {
        setPost((state) => ({ ...state, editPostValue: "", isUpdate: false, isChanged: false, }));
      }
      getAchievementList(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLike = async (id) => {
    try {
      await handleLikeHelper(id);
      getAchievementList(false);
    } catch (error) {}
  };

  const handleHighFive = async (id) => {
    try {
      await handleHighFiveHelper(id);
      getAchievementList(false);
    } catch (error) {}
  };

  const handleDeleteAchievement = async (id) => {
    try {
      await handleDeleteAchievementHelper(id);
      toast.success("Post Deleted Successfully", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      getAchievementList(false);
    } catch (error) {}
  };

  const handleReaction = async (type, id) => {
    try {
      setReaction((state) => ({
        ...state,
        modalOpen: true,
        popupLoading: true,
      }));
      if (type === "like") {
        const likesPeople = await GET(`getAchievementLikesList/${id}`);
        if (likesPeople.status === 200) {
          setReaction((state) => ({
            ...state,
            reactions: likesPeople?.body?.data?.achievementLikes,
            id,
            isLike: true,
            isComment: false,
            likeCount: getCountText(
              likesPeople?.body?.data?.likeCount,
              "Like",
              "Likes"
            ),
            highFiveCount: getCountText(
              likesPeople?.body?.data?.likeCount,
              "High Five",
              "High Fives"
            ),
            popupLoading: false,
          }));
        }
      }
      if (type === "highfive") {
        const likesPeople = await GET(`getAchievementHighFivesList/${id}`);
        if (likesPeople.status === 200) {
          setReaction((state) => ({
            ...state,
            reactions: likesPeople.body.data.achievementHighFives,
            id,
            isLike: false,
            isComment: true,
            likeCount: getCountText(
              likesPeople?.body?.data?.likeCount,
              "Like",
              "Likes"
            ),
            highFiveCount: getCountText(
              likesPeople?.body?.data?.likeCount,
              "High Five",
              "High Fives"
            ),
            popupLoading: false,
          }));
        }
      }
    } catch (error) {
      setReaction((state) => ({ ...state, popupLoading: false }));
    }
  };

  const handleClick = () => {
    setReaction((state) => ({
      ...state,
      modalOpen: false,
      isLike: false,
      isComment: false,
      reactions: [],
    }));
  };

  const handleClick1 = () => {
    handlePostUpdate();
    setPost((state) => ({
      ...state,
      createAchievementModal: false,
    }));
  };

  return (
    <>
      <Modal
        isOpen={post.createAchievementModal}
        backdrop={true}
        className="thoughtmodal"
      >
        <ModalBody>
          <div className="Acheivement-popup-wrapper post">
            <img
              alt="cross"
              src={cross}
              className="cross-achievement"
              onClick={() =>
                setPost((state) => ({
                  ...state,
                  createAchievementModal: false,
                  editPostValue: "",
                  isUpdate: false,
                }))
              }
            />

            <div className="Acheivement-popup">
              <div className="worker-img">
                <img
                  alt="profile pic"
                  className="achivement-pic"
                  src={profile_pic_url ? profile_pic_url : worker}
                />
              </div>
              <MentionInput
                value={post.editPostValue}
                mentionKey="user"
                tagKey="target"
                tagsData={targets}
                mentionsData={employees}
                onChange={(newValue, mentionList, tagsList) => {
                  setPost((state) => {
                    return {
                      ...state,
                      editPostValue: newValue,
                      employees_mentions: mentionList,
                      qualitative_tags: tagsList,
                      isChanged: true,
                    };
                  });
                }}
                placeholder="what do you want to talk about ?"
              />
            </div>
            <button className="update-achievement-btn" disabled={!post.isChanged} onClick={() => handleClick1()}>
              Update
            </button>
          </div>
        </ModalBody>
      </Modal>

      <CModal
        centered={true}
        className="main-achivement"
        backdrop={true}
        size="sm"
        show={reaction.modalOpen}
        onClose={() => handleClick()}
      >
        <CModalBody>
          <div style={{ position: "relative" }}>
            <img
              className="index-modal-img"
              alt="cross"
              src={cross}
              onClick={() => handleClick()}
            />
            <div
              style={{
                position: "relative",
                maxHeight: "30rem",
                overflowY: "auto",
                overflowX: "hidden",
              }}
              className="acheivment-details wrapper Reactions"
            >
              <div>
                <div
                  style={{ textAlign: "center" }}
                  className="socil-details Reactions"
                >
                  <h5>Reactions</h5>
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-12">
                      <div className="like-high-five">
                        <div
                          onClick={() => handleReaction("like", reaction.id)}
                          className="img-wrap"
                        >
                          <img alt="heart" src={heartfill} />
                          <p style={{ width: "4.5rem" }}>
                            {reaction.likeCount}{" "}
                          </p>
                          {reaction.isLike && <hr className="hr" />}
                        </div>
                        <div
                          onClick={() =>
                            handleReaction("highfive", reaction.id)
                          }
                          className="img-wrap"
                        >
                          <img alt="highfive" src={highFiveFill} />
                          <p style={{ width: "5.5rem" }}>
                            {reaction.highFiveCount}
                          </p>
                          {reaction.isComment && <hr className="hr" />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-12">
                    {reaction.reactions && reaction.reactions?.length > 0 ? (
                      reaction.reactions.map((item) => {
                        let employee_name = "";
                        if (item?.employee?.status === 1) {
                          employee_name = item.employee?.name;
                        } else if (item.employee.status === 2) {
                          employee_name =
                            item.employee?.name + " (Previous Employee)";
                        } else {
                          employee_name = item.employee?.name + "(Inactive)";
                        }
                        return (
                          <div className="media">
                            <div className="media-img">
                              <img
                                src={
                                  item.employee?.profile_pic_url
                                    ? item.employee.profile_pic_url
                                    : worker
                                }
                                alt="profilepic"
                                class="mr-3 rounded-circle"
                              />
                            </div>
                            <div className="media-body">
                              <h5>
                                {employee_name?.length > 20
                                  ? `${employee_name.slice(0, 20)}...`
                                  : employee_name}
                              </h5>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="media">No Reactions Found</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CModalBody>
      </CModal>

      <div className="acheivment-details wrapper">
        <div className="row">
          <div className="col-sm-6">
            <div className="media">
              <img
                src={
                  item?.employee?.profile_pic_url
                    ? item.employee.profile_pic_url
                    : worker
                }
                alt=""
                className="mr-3 rounded-circle"
              />
              <div className="media-body">
                <h5>
                  {item?.employee?.status === 1
                    ? item.employee?.name
                    : item?.employee?.status === 2
                    ? item.employee?.name + " (Previous Employee)"
                    : item.employee?.name + "(Inactive)"}
                </h5>
                <p>{achievementsdate}</p>
              </div>
            </div>
          </div>
          {Number(localStorage.getItem("id")) === item.employee_id && (
            <div className="col-sm-6">
              <div className="conver-icon">
                <div className="dropdown">
                  <button
                    type="button"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <img alt="dot" src={dot} />
                  </button>

                  <div className="dropdown-menu">
                    <div
                      className="dropdown-item"
                      onClick={() =>
                        handleCreateAchievement(true, item.id, item.description)
                      }
                    >
                      Edit
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => handleDeleteAchievement(item.id)}
                    >
                      Delete
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <MentionInput
          value={item.description}
          mentionKey="user"
          tagKey="target"
          readOnly={true}
        />
        <div className="socil-details">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <div className="like-high-five">
                <div className="img-wrap">
                  <img
                    alt="liked"
                    onClick={() => handleLike(item.id)}
                    src={item.isLiked ? heartfill : heartEmpty}
                  />
                  <p
                    className="reaction-count-people"
                    onClick={() => handleReaction("like", item.id)}
                  >
                    {getCountText(item.likeCount, "Like", "Likes")}
                  </p>
                </div>
                <div className="img-wrap">
                  <img
                    alt="highfive"
                    onClick={() => handleHighFive(item.id)}
                    src={item.isHighFived ? highFiveFill : highfive}
                  />
                  <p
                    className="reaction-count-people"
                    onClick={() => handleReaction("highfive", item.id)}
                  >
                    {getCountText(
                      item.highFiveCount,
                      "High Five",
                      "High Fives"
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <div
                onClick={() => {
                  if (!disableLink) history.push(`achievement/${item.id}`);
                }}
                className="comment"
              >
                <p>{getCountText(item.commentCount, "comment", "comments")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AchievementItem;
