import * as Action from "../type.action";
const initialState = {
  sidebarShow: "responsive",
  loading: false,
  showLook: false,
};
const globalReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case Action.REST_LOADING:
      return {
        ...state,
        sidebarShow: "responsive",
        loading: false,
        showLook: false,
      };
    case "set":
      return { ...state, ...rest };
    case "SET_LOOK":
      localStorage.setItem("look", JSON.stringify({ showLook: rest.value }));

      return Object.assign({}, state, {
        showLook: rest.value,
      });
    case "SET_LOOKPROP":
      return Object.assign({}, state, {
        showLook: rest.value,
      });
    case Action.LOADING_SHOW:
      return Object.assign({}, state, {
        loading: true,
      });
    case Action.LOADING_HIDE:
      return Object.assign({}, state, {
        loading: false,
        Edit: false,
      });
    default:
      return state;
  }
};

export default globalReducer;
