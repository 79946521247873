import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
import { isEmpty } from "lodash";

import "./style.scss";
import OverallMoodWidget from "../../Components/OverallMoodWidget";
import ScoreWidget from "../../Components/ScoreWidget";
import StreaksWidget from "../../Components/StreaksWidget";
import AccomplishmentsWidget from "./../../Components/AccomplishmentsWidget/index";
import GoalsWidget from "./../../Components/GoalsWidget/index";
import { getExportData, useGetThoughtOfTheDay } from "./../../hooks/analytics.hook";
import { GET } from "../../utils/helper"
import { useDispatch, useSelector } from "react-redux";
import Svgs from "../../assets/Svgs";
import { CSVLink } from "react-csv";
import StatsWidget from "../../Components/StatsWidget"

ChartJS.register(
  ArcElement,
  CategoryScale,
  zoomPlugin,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const Analytics = (props) => {
  const thoughtOfTheDayQuery = useGetThoughtOfTheDay();
  const thought = thoughtOfTheDayQuery.data?.body?.data?.thought;
  const role = useSelector(state => state?.loginReducer?.loginRole);
  const dispatch = useDispatch();
  const [Interval, setInterval] = useState(7);
  const [Loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  let email_dispatched = false;
  const getEmail = async () => {
    const res = await GET(`${role == 0 ? "getMyProfile" : "getProfile"}`);
    const data = res.body.data;
    dispatch({ type: "GET_EMPLOYER_DATA", data: data });
    email_dispatched = true;
  }

  useEffect(() => {
    getExportData(Interval,setData,setLoading);
  }, [Interval])


  if (!email_dispatched) {
    getEmail();
  }

  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Accomplishments", key: "accomplishments" },
    { label: "Goals", key: "goals" },
    { label: "Overall Mood", key: "overallMood" },
    { label: "Score", key: "score" },
    { label: "Streaks", key: "streaks" },
  ];

  const csvReport = {
    data: data,
    headers: headers,
    filename: 'Analytics-Data.csv'
  };

  return (
    <>
      {!isEmpty(thought) && <div className="analytics-banner">{thought}</div>}
      <div className="container text-right">
        <div className="d-flex gap-3 justify-content-end align-items-center py-2">
          <div className="fw-500">Export Data:</div>
          <div class="select-filter-input">
            {/* time period: can select from 1 week, 30 days, 90 days, 6 months, a year */}

            <select onChange={(e) => {
              setInterval(e.target.value);
            }}>
              <option value="7">Last 7 Days</option>
              <option value="30">Last 30 Days</option>
              <option value="90">Last 90 Days</option>
              <option value="182">Last 6 Months</option>
              <option value="365">Last 1 Year</option>
            </select>
          </div>
            <CSVLink {...csvReport}>
              <button className={`btn-sm btn-export h-100 py-2 ${Loading && 'opacity-70 not-allowed'}`}>
                <span className="pr-1">Export CSV</span>
                <Svgs.Download />
              </button>
            </CSVLink>
        </div>
      </div>
      <div className="bluxinga-analytics">
        <OverallMoodWidget />
        <ScoreWidget />
        <StatsWidget />
        <StreaksWidget />
        <GoalsWidget />
        <AccomplishmentsWidget />
      </div>
    </>
  );
};

export default Analytics