import { call, put, apply } from "redux-saga/effects";
import * as Action from "../type.action";
import { POST, GET, RESETPOST, deleteAPI } from "../../utils/helper";
import {
  GETLISTOFTEAMMEMBERBY_MANAGERID,
  GETCURRENTMANAGER,
  GETLIST_OFEMPLOYER_TEAMMEMBER,
} from "./ApiConstant";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export function* getTeamEmployeeDetail(action) {
  yield put({ type: Action.LOADING_SHOW });
  try {
    const id = action.value;
    const url = `viewDetailsEmployee?id=${id}`;
    const Data = yield call(GET, url);

    if (Data.status === 200) {
      yield put({
        type: Action.TEAMDETAIL_DATASUCCESS,
        data: Data.body.data,
        msg: "Success!!",
      });
      yield put({ type: Action.LOADING_HIDE });
    } else {
      yield put({ type: Action.TEAMDETAIL_DATAFAIL, data: Data.message });
      yield put({ type: Action.LOADING_HIDE });
    }
  } catch (error) {
    yield put({
      type: Action.TEAMDETAIL_DATAFAIL,
      data: "something went wrong",
    });
    yield put({ type: Action.LOADING_HIDE });
  }
}

export function* getTeamList(action) {
  yield put({ type: Action.LOADING_SHOW });
  try {
    const Data = yield call(GET, GETLISTOFTEAMMEMBERBY_MANAGERID);

    if (Data.status === 200) {
      yield put({
        type: Action.TEAM_DATASUCCESS,
        data: Data.body.data,
        msg: "Success!!",
      });
      yield put({ type: Action.LOADING_HIDE });
    } else {
      yield put({ type: Action.TEAM_DATAFAIL, data: Data.message });
      yield put({ type: Action.LOADING_HIDE });
    }
  } catch (error) {
    yield put({ type: Action.TEAM_DATAFAIL, data: "Something went wrong" });
    yield put({ type: Action.LOADING_HIDE });
  }
}
export function* getEmyprTeamList(action) {
  yield put({ type: Action.LOADING_SHOW });
  try {
    const searchkey = action?.value?.keyword || "";
    const dept = action?.value?.dept || "";
    let url = "";
    if (dept !== 0 && searchkey !== "" && dept !== undefined) {
      url = `getEmployeeList?departmentId=${dept}&searchKey=${searchkey}`;
    } else if (dept != 0 && dept != undefined) {
      url = `getEmployeeList?departmentId=${dept}`;
    } else if (searchkey != "") {
      url = `getEmployeeList?searchKey=${searchkey}`;
    } else {
      url = GETLIST_OFEMPLOYER_TEAMMEMBER;
    }
    const Data = yield call(GET, url, 1);

    if (Data.status === 200) {
      yield put({
        type: Action.EMPLOYERTEAM_DATASUCCESS,
        data: Data.body.data,
        msg: "Success!!",
      });
      yield put({ type: Action.LOADING_HIDE });
    } else {
      yield put({ type: Action.TEAM_DATAFAIL, data: Data.message });
      yield put({ type: Action.LOADING_HIDE });
    }
  } catch (error) {
    yield put({ type: Action.TEAM_DATAFAIL, data: "Something went wrong" });
    yield put({ type: Action.LOADING_HIDE });
  }
}

export function* getEmyprTeamData(action) {
  yield put({ type: Action.LOADING_SHOW });
  try {
    const id = action.value;
    const url = `viewEmployeeDetails/${id}`;
    const Data = yield call(GET, url, 1);

    if (Data.status === 200) {
      yield put({
        type: Action.EMPLYRTEAMDETAIL_DATASUCCESS,
        data: Data.body.data,
        msg: "Success!!",
      });
      yield put({ type: Action.LOADING_HIDE });
    } else {
      yield put({ type: Action.TEAMDETAIL_DATAFAIL, data: Data.message });
      yield put({ type: Action.LOADING_HIDE });
    }
  } catch (error) {
    yield put({
      type: Action.TEAMDETAIL_DATAFAIL,
      data: "something went wrong",
    });
    yield put({ type: Action.LOADING_HIDE });
  }
}

export function* addEmployerEmployee(action) {
  yield put({ type: Action.LOADING_SHOW });
  try {
    const { postObj, history } = action.value;
    const Data = yield call(RESETPOST, "addEditEmployee", postObj, 1);

    if (Data.status === 200) {
      yield put({ type: Action.LOADING_HIDE });
      yield apply(history, history.push, ["/employerteam"]);
      toast.success("Employee updated successfully", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      yield put({ type: Action.LOADING_HIDE });

      if (Data.message.slice(0, 3) === "pre") {
        toast.error("Please enter all the fields of previous employer", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        toast.error(Data.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
  } catch (error) {
    yield put({ type: Action.LOADING_HIDE });
    toast.error("something went wrong", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
}

export function* addManagerEmployee(action) {
  yield put({ type: Action.LOADING_SHOW });
  try {
    const { postObj, history } = action.value;
    const Data = yield call(RESETPOST, "addEditEmployee", postObj, 1);

    if (Data.status === 200) {
      yield put({ type: Action.LOADING_HIDE });
      yield apply(history, history.push, ["/team"]);
      toast.success("Employee added successfully", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      yield put({ type: Action.LOADING_HIDE });

      if (Data.message.slice(0, 3) === "pre") {
        toast.error("Please enter all the fields of previous employer", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        toast.error(Data.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
  } catch (error) {
    yield put({ type: Action.LOADING_HIDE });
    toast.error("something went wrong", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
}

export function* deleteEmployerEmployee(action) {
  yield put({ type: Action.LOADING_SHOW });
  try {
    const id = action.value;
    const Data = yield call(deleteAPI, `deleteEmployee/${id}`, 1);

    if (Data.status === 200) {
      yield put({ type: Action.LOADING_HIDE });

      toast.success("Employee Deleted successfully", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      yield put({ type: Action.LOADING_HIDE });
      toast.error(Data.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  } catch (error) {
    yield put({ type: Action.LOADING_HIDE });
    toast.error("something went wrong", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
}
export function* getEmyprDepartmentData(action) {
  yield put({ type: Action.LOADING_SHOW });
  try {
    const url = `getDepartmentList`;
    const Data = yield call(GET, url, 1);

    if (Data.status === 200) {
      yield put({
        type: Action.EMPLYRDEPARTMENT_DATASUCCESS,
        data: Data.body.data.rows,
        msg: "Success!!",
      });
      yield put({ type: Action.LOADING_HIDE });
    } else {
      yield put({ type: Action.TEAMDETAIL_DATAFAIL, data: Data.message });
      yield put({ type: Action.LOADING_HIDE });
    }
  } catch (error) {
    yield put({
      type: Action.TEAMDETAIL_DATAFAIL,
      data: "something went wrong",
    });
    yield put({ type: Action.LOADING_HIDE });
  }
}
export function* getEmyprMangerData(action) {
  yield put({ type: Action.LOADING_SHOW });
  try {
    const url = `getManagerList`;
    const Data = yield call(GET, url, 1);

    if (Data.status === 200) {
      yield put({
        type: Action.EMPLYRMANAGER_DATASUCCESS,
        data: Data.body.data,
        msg: "Success!!",
      });
      yield put({ type: Action.LOADING_HIDE });
    } else {
      yield put({ type: Action.TEAMDETAIL_DATAFAIL, data: Data.message });
      yield put({ type: Action.LOADING_HIDE });
    }
  } catch (error) {
    yield put({
      type: Action.TEAMDETAIL_DATAFAIL,
      data: "something went wrong",
    });
    yield put({ type: Action.LOADING_HIDE });
  }
}
export function* getMangerId(action) {
  yield put({ type: Action.LOADING_SHOW });
  try {
    const Data = yield call(GET, GETCURRENTMANAGER);
    if (Data.status === 200) {
      localStorage.setItem("manager_team_member_id", Data.body.data.manager_id);
    }
  } catch (error) {
    yield put({ type: Action.TEAM_DATAFAIL, data: "Something went wrong" });
    yield put({ type: Action.LOADING_HIDE });
  }
}
export function* searchTeamList(action) {
  yield put({ type: Action.LOADING_SHOW });
  try {
    const { keyword } = action.value;
    const url =
      keyword === ""
        ? "getListOfTeamMemberByManagerId"
        : `searchTeamMember?search_string=${keyword}`;
    const Data = yield call(GET, url);

    if (Data.status === 200) {
      yield put({
        type: Action.SEARCHTEAM_DATA,
        data: Data.body.data.rows,
        msg: "Success!!",
      });
      yield put({ type: Action.LOADING_HIDE });
    } else {
      yield put({ type: Action.TEAM_DATAFAIL, data: Data.message });
      yield put({ type: Action.LOADING_HIDE });
    }
  } catch (error) {
    yield put({
      type: Action.TEAMDETAIL_DATAFAIL,
      data: "something went wrong",
    });
    yield put({ type: Action.LOADING_HIDE });
  }
}
