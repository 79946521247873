import { get } from "lodash";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import banner from "../assets/images/banner.png";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { GET, POST, getSelectMeasure } from "../utils/helper";
import { toast } from "react-toastify";
import { getGoalPercentage } from "../utils/helper";
import { getDate } from "../utils/validation";
import delete1 from "../../src/assets/images/delete1.png";
import { useApprovedGoalDelete } from "./../hooks/goal.hook";
import { useSelector } from "react-redux";


const ManagerApproval = (props) => {
  const params = useParams();

  const [goalDetail, setGoalDetail] = useState(null);
  const [comment, setComment] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(null);
  const loginRole = useSelector((state) => state.loginReducer.loginRole);

  const { goal_id, team_goal_assign_id } = params;

  useEffect(() => {
    const viewGoalAssignCompletionAsManager = async () => {
      try {
        setLoading(true);
        const res = await GET(
          `viewGoalAssignCompletionAsManager?goal_id=${goal_id}&team_goal_assign_id=${team_goal_assign_id}`
        );
        setLoading(false);

        if (res?.body?.data) {
          setGoalDetail(res.body.data);
        }
      } catch (error) {
        setLoading(false);

        console.log(error);
      }
    };

    viewGoalAssignCompletionAsManager();
  }, [team_goal_assign_id, goal_id]);

  const handleAcceptReject = async (isApproved, id) => {
    if (!comment) {
      const toastId = "id-comment-empty";

      toast.error("Please enter comment", {
        position: toast.POSITION.BOTTOM_CENTER,
        toastId,
      });
      return;
    }

    let data = {
      goal_id: goalDetail.id,
      team_goal_assign_id: goalDetail.team_goal_assigns[0].id,
      team_goal_assign_completion_by_employee_id: id,
      status: isApproved ? 1 : 2,
      manager_comment: comment.trim(),
    };

    try {
      const res = await POST("goalAcceptRejectAsManager", data);

      if (res) {
        history.goBack();
        toast.success("Goal accepted successfully!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteClick = async (value) => {
    if (window.confirm("Are you sure you want to delete approved goal?")) {

      let data = {
        goal_id: "",
        id: "",
        team_goal_assign_id: ""
      };


      data.goal_id = value.goal_id;
      data.id = value.id;
      data.team_goal_assign_id = value.team_goal_assign_id;


      const res = await POST("deleteApprovedGoalAsManager", data);
      if (res.status == 200) {
        history.goBack();
        toast.success("Approved goal deleted!");
      } else {
        toast.error("Error deleting selected goal.");
      }
    }
  }

  return (
    <div style={{ minHeight: "55vh" }}>
      <div class="Approve-goal-wrapper bg-color">
        <div class="container">
          <div class="Approve-goal comman-width-container">
            <div class="banner-img">
              <img class="img-fluid" src={banner} />
            </div>
            <br />
            <p className="form-control Goal">
              {goalDetail?.team_goal_assigns?.[0]?.employee?.name}
            </p>

            {goalDetail &&
              goalDetail?.team_goal_assigns?.[0]
                ?.team_goal_assign_completion_by_employees?.length > 0 ? (
              goalDetail?.team_goal_assigns[0]?.team_goal_assign_completion_by_employees?.map(
                (item) => {
                  const measure = goalDetail?.team_goal_assigns?.[0]?.measure;
                  let goalCompletionPercentage =
                    item.status === 3
                      ? getGoalPercentage(
                        Number(item.complete_measure) +
                        Number(item.total_complete_measure),
                        Number(measure)
                      )
                      : getGoalPercentage(
                        Number(item.total_complete_measure),
                        Number(measure)
                      );

                  return (
                    <div key={item.id}>
                      <div class="goal-details">
                        <div class="Employee-details">
                          <div class="row">
                            <div class="col-md-12 col-sm-12">
                              <div style={{ float: "right", pointer: 'cursor' }}>
                                <img
                                  className="cursor-pointer"
                                  style={{
                                    position: "absolute",
                                    top: "35%",
                                    float: "right"
                                  }}
                                  src={delete1}
                                  onClick={(e) => {
                                    handleDeleteClick(item)
                                  }}
                                  alt="deleteicon"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="Employee-details">
                          <div class="row">
                            <div class="col-md-6 col-sm-6">
                              <div class="Manager">
                                <h6>Goal Title</h6>
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                              <div class="Employee-Inputs">
                                <h6>{goalDetail.title}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="Employee-details">
                          <div class="row">
                            <div class="col-md-6 col-sm-6">
                              <div class="Manager">
                                <h6>Goal Description</h6>
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                              <div class="Employee-Inputs">
                                <h6>{goalDetail.description}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="Employee-details">
                          <div class="row">
                            <div class="col-md-6 col-sm-6">
                              <div class="Manager">
                                <h6>
                                  Goal Measure{" "}
                                  {`${getSelectMeasure(goalDetail.select_measure)}: ${measure
                                    }`}
                                </h6>
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                              <div class="Employee-Inputs">
                                <h6>{item.complete_measure}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="Employee-details">
                          <div class="row">
                            <div class="col-md-6 col-sm-6">
                              <div class="Manager">
                                <h6>Goal Completion Percentage</h6>
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                              <div class="Employee-Inputs">
                                <h6>
                                  {item.goalCompletionPercentage < 0
                                    ? "0"
                                    : goalCompletionPercentage}
                                  %
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="Employee-details">
                          <div class="row">
                            <div class="col-md-6 col-sm-6">
                              <div class="Manager">
                                <h6>Employee Comment</h6>
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                              <div class="Employee-Inputs">
                                <h6>{item?.description}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="Employee-details">
                          <div class="row">
                            <div class="col-md-6 col-sm-6">
                              <div class="Manager">
                                <h6>Start Date</h6>
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                              <div class="Employee-Inputs">
                                <h6>{getDate(goalDetail?.start_date)}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="Employee-details">
                          <div class="row">
                            <div class="col-md-6 col-sm-6">
                              <div class="Manager">
                                <h6>Expected End Date</h6>
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                              <div class="Employee-Inputs">
                                <h6>{getDate(goalDetail?.end_date)}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="Employee-details">
                          <div class="row">
                            <div class="col-md-6 col-sm-6">
                              <div class="Manager">
                                <h6>Completed Date</h6>
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                              <div class="Employee-Inputs">
                                <h6>{getDate(item?.createdAt)}</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        {item.status !== 3 && (
                          <div class="Employee-details">
                            <div class="row">
                              <div class="col-md-6 col-sm-6">
                                <div class="Manager">
                                  <h6>Manager Comment</h6>
                                </div>
                              </div>
                              <div class="col-md-6 col-sm-6">
                                <div class="Employee-Inputs">
                                  <h6>{item.manager_comment}</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {item.status === 1 && (
                          <div class="Approved-btn">
                            <button>Approved</button>
                          </div>
                        )}
                        {item.status === 2 && (
                          <div class="reject-btn">
                            <button>Rejected</button>
                          </div>
                        )}
                      </div>
                      {item.status === 3 && (
                        <form class="textarea">
                          <div class="form-group">
                            <textarea
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                              class="form-control"
                              rows="4"
                              placeholder="Add your Comment"
                            ></textarea>
                          </div>
                        </form>
                      )}
                      {item.status === 3 && (
                        <div class="Approve-goal-btn">
                          <button
                            onClick={() => handleAcceptReject(true, item.id)}
                          >
                            Accept
                          </button>
                          <button
                            onClick={() => handleAcceptReject(false, item.id)}
                          >
                            Reject
                          </button>
                        </div>
                      )}
                    </div>
                  );
                }
              )
            ) : (
              <div
                style={{
                  height: "35vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#FF6B34",
                }}
              >
                {loading ? (
                  <div
                    style={{ marginTop: "5rem" }}
                    class="spinner-border text-primary"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <h4>No Approvals Needed</h4>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    showLook: state.globalReducer.showLook,
  };
};

const mapDispatch = (dispatchEvent) => ({
  setshowLook: (payload) => dispatchEvent({ type: "SET_LOOK", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(ManagerApproval);
