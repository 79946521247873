import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { validatePassword, errorMessage } from "../../utils/validation";
import { POST } from "../../utils/helper";
import { toast } from "react-toastify";
const ChangePassword = (props) => {
  const [confirmPassword, setConfirmPasword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [cpasswordShown, setCPasswordShown] = useState(false);

  const [oldPassword, setOldPasword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [oldpasswordShown, setOldPasswordShown] = useState(false);

  const ctogglePasswordVisiblity = () => {
    setCPasswordShown(!cpasswordShown);
  };
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleoldPasswordVisiblity = () => {
    setOldPasswordShown(!oldpasswordShown);
  };

  const handleChange = ({ target }) => {
    props.onFieldChange({ name: "OnShowEror", value: "" });
    const { name, value } = target;
    let newPasswordError,
      confirmPasswordError,
      oldPasswordError = "";
    if (name === "newPassword") {
      setNewPassword(value);
      if (value === "") {
        newPasswordError = errorMessage.required;
      } else {
        if (validatePassword(value)) {
          newPasswordError = "";
        } else {
          newPasswordError = errorMessage.paswrdError;
        }
      }
      setNewPasswordError(newPasswordError);
    }
    if (name === "confirmPassword") {
      setConfirmPasword(value);
      if (value === "") {
        confirmPasswordError = errorMessage.cofirmpaswrdError;
      } else {
        confirmPasswordError = "";
      }
      setConfirmPasswordError(confirmPasswordError);
    }
    if (name === "oldPassword") {
      setOldPasword(value);
      if (value === "") {
        oldPasswordError = errorMessage.required;
      } else {
        oldPasswordError = "";
      }
      setOldPasswordError(oldPasswordError);
    }
  };

  const checkValidate = () => {
    if (confirmPassword !== "" || newPassword !== "") {
      if (!validatePassword(newPassword)) {
        return false;
      }
      if (newPassword !== confirmPassword) {
        setConfirmPasswordError(errorMessage.paswrdnotmatchError);
        return false;
      } else {
        return true;
      }
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (checkValidate()) {
      const postObj = { old_password: oldPassword, new_password: newPassword };
      try {
        const result = await POST("changePassword", postObj, 1);

        if (result.data.success) {
          setOldPasword("");
          setNewPassword("");
          setConfirmPasword("");
          toast.success("Password Updated Successfully", {
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: true,
          });
        } else {
          toast.error("Something went wrong", {
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: true,
          });
        }
      } catch (error) {
        console.log(error.response);
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          hideProgressBar: true,
        });
      }
    }
  };

  return (
    <React.Fragment>
      <section class="confirm-password-wrapper bg-color">
        <div class="container">
          <div class="confirm-password">
            <form onSubmit={handleSubmit}>
              <div class="form-group">
                <div  
                className="changepassword-form"
              
                >
                  <input
                    type={oldpasswordShown ? "text" : "password"}
                    name="oldPassword"
                    className={`form-control ${
                      oldPasswordError ? "is-invalid" : ""
                    }`}
                    onChange={handleChange}
                    value={oldPassword}
                    required
                    placeholder="Old password"
                  />
                  <i
                    class={oldpasswordShown ? "fa fa-eye" : "fa fa-eye-slash"}
                    aria-hidden="true"
                    style={{ position: "absolute", top: "35%", left: "90%" }}
                    onClick={toggleoldPasswordVisiblity}
                  ></i>
                </div>
                <div className="errmsg">{oldPasswordError}</div>
              </div>
              <div class="form-group">
                <div className="changepassword-form"  
                
           
                  >
                  <input
                    placeholder="New password"
                    type={passwordShown ? "text" : "password"}
                    name="newPassword"
                    className={`form-control ${
                      newPasswordError ? "is-invalid" : ""
                    }`}
                    onChange={handleChange}
                    value={newPassword}
                    required
                  />
                  <i
                    class={passwordShown ? "fa fa-eye" : "fa fa-eye-slash"}
                    aria-hidden="true"
                    style={{ position: "absolute", top: "35%", left: "90%" }}
                    onClick={togglePasswordVisiblity}
                  ></i>
                </div>
                <div className="errmsg">{newPasswordError}</div>
              </div>
              <div class="form-group">
                <div  
                 className="changepassword-form"  
            
                >
                  <input
                    placeholder="Confirm new password"
                    type={cpasswordShown ? "text" : "password"}
                    name="confirmPassword"
                    className={`form-control ${
                      confirmPasswordError ? "is-invalid" : ""
                    }`}
                    onChange={handleChange}
                    value={confirmPassword}
                    required
                  />
                  <i
                    class={cpasswordShown ? "fa fa-eye" : "fa fa-eye-slash"}
                    aria-hidden="true"
                    style={{ position: "absolute", top: "35%", left: "90%" }}
                    onClick={ctogglePasswordVisiblity}
                  ></i>
                </div>
                <div className="errmsg">{confirmPasswordError}</div>
              </div>
              <span 
              className="green"
            
               > {props.setforgotPswrdMsg}</span>
              <span   
              className="red"
             
               >
                {" "}
                {props.setforgotPswrdErrMsg}
              </span>
              {props.loading ? (
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                <div class="confirm-password btn-blue text-center">
                  <button>Save</button>
                </div>
              )}
            </form>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const forgotPswrdData = state.loginReducer.forgotPswrdData;
  const forgotError = state.loginReducer.forgotError;
  const forgotErrMsg = state.loginReducer.forgotErrMsg;
  const loading = state.globalReducer.loading;
  const setforgotPswrdMsg = state.loginReducer.setforgotPswrdMsg;
  const setforgotPswrdErrMsg = state.loginReducer.setforgotPswrdErrMsg;
  return {
    forgotPswrdData,
    forgotErrMsg,
    loading,
    setforgotPswrdMsg,
    forgotError,
    setforgotPswrdErrMsg,
    isLoggedIn: state.loginReducer.isLoggedIn,
  };
};

const mapDispatch = (dispatchEvent) => ({
  setPswrd: (payload) =>
    dispatchEvent({ type: "SET_CHNAGEPASWORD", value: payload }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(ChangePassword);
