import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { isEmpty, isString } from "lodash";
import cn from "classnames";

import Add from "../../assets/images/Add.svg";
import Delete from "../../assets/images/Delete.svg";
import "./style.scss";

const CvSectionDetails = ({
  children,
  initialDetailsObject,
  detailsList,
  setDetailsListState,
  name,
  errors,
  touched,
}) => {
  const handleExpandItem = (itemIndex) => () => {
    setDetailsListState(
      detailsList.map((item, index) =>
        index === itemIndex ? { ...item, isOpen: !item.isOpen } : item
      )
    );
  };

  const handleAddDetailsItem = (id) => {
    setDetailsListState([
      ...detailsList,
      {
        ...initialDetailsObject,
        id,
      },
    ]);
  };

  const handleRemoveDetailsItem = (index) => {
    setDetailsListState(detailsList.filter((_, i) => i !== index));
  };

  const getItemSectionIsHasErrorsAndTouched = (index) => {
    return !isEmpty(errors?.[index]) && !isEmpty(touched?.[index]);
  };

  return (
    <>
      {detailsList?.map((item, index) => (
        <div key={index} className="details-section-box">
          <div>
            <Accordion
              className={`${cn({
                "invalid-details-item": getItemSectionIsHasErrorsAndTouched(
                  index
                ),
              })} accordion-custom-width`}
              expanded={item?.isOpen} // || validationErrors?.length > 0
              onChange={handleExpandItem(index)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div className="Cmodal-footer">
                  <div>Detail - {index + 1}</div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {children({ detailsItem: item, index })}
              </AccordionDetails>
            </Accordion>
            {
              getItemSectionIsHasErrorsAndTouched(index) && <div className="errmsg mt-2 mb-1">This field is required!</div>
            }
          </div>

          <div>
            {index === 0 && (
              <button
                // disabled={true}
                onClick={() => handleAddDetailsItem(index + 1)}
                className="add-button-myprofile"
                type="button"
              >
                <img className="add-button-img" src={Add} alt="" />
              </button>
            )}
          </div>
          <div>
            <button
              type="button"
              hidden={item.id < 1}
              onClick={() => handleRemoveDetailsItem(index)}
              className="add-button-myprofile"
            >
              <img
                style={{ height: "30px", width: "30px" }}
                src={Delete}
                alt=""
              />
            </button>
          </div>
          {touched && errors ? (
            <div className="errmsg">{isString(errors) ? errors : null}</div>
          ) : null}
        </div>
      ))}
    </>
  );
};

export default CvSectionDetails;
