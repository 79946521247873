import React, { useState } from 'react'

import { connect } from 'react-redux'
import logo1 from '../../assets/images/logo-1.png'
import eyeicon from '../../assets/images/eye.png'
import { validatePassword,errorMessage } from '../../utils/validation'


const EmployerResetPassword = (props) => {
  const [confirmPassword,setConfirmPasword] = useState('')
  const [newPassword,setNewPassword]= useState('')
  const [newPasswordError,setNewPasswordError] = useState('')
  const [confirmPasswordError,setConfirmPasswordError] = useState('')
  const [passwordShown, setPasswordShown] = useState(false);
  const [cpasswordShown, setCPasswordShown] = useState(false);

  const ctogglePasswordVisiblity = () => {
    setCPasswordShown(!cpasswordShown);
  };
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };
  const handleChange = ({target}) =>{
    props.onFieldChange({ name: 'OnShowEror', value: '' })
    const{ name, value} = target;
    let newPasswordError,confirmPasswordError=''
    if (name === 'newPassword') {
      setNewPassword(value)
      if (value === '') {
        newPasswordError = errorMessage.required
      } else {
        if(validatePassword(value)){
          newPasswordError = ''
        }
        else{
          newPasswordError = errorMessage.paswrdError
        }
     
      }
      setNewPasswordError(newPasswordError)
    }
    if (name === 'confirmPassword') {
      setConfirmPasword(value)
      if (value === '') {
        confirmPasswordError = errorMessage.cofirmpaswrdError
      } else {
        confirmPasswordError = ''
      }
      setConfirmPasswordError(confirmPasswordError)
    }
  }

  const checkValidate = () => {
    if (confirmPassword !== '' || newPassword !== '') {
      if(!validatePassword(newPassword)){
        return false
      }
      if (newPassword !== confirmPassword) {
        setConfirmPasswordError(errorMessage.paswrdnotmatchError)
        return false
      } else {
        return true
      }
    }
    return true
  }

  const handleSubmit = event =>{
 
    event.preventDefault();
    if(checkValidate()){
      const postObj = { password: confirmPassword} 
      props.setEmployerPswrd({postObj,history:props.history })
     
    }
  }

  return (
    
    <section class="sign-in-wrapper">
    <div class="signin-main"> 
        <div class="signin-box">
         <div class="logo-img"> 
             <img  src={logo1} alt=""/>
          </div>
        <h3>Set New Password</h3> 
        <form class="setup-new-pass" onSubmit={handleSubmit}>
            <div class="form-group">
                <input type={passwordShown ? "text" : "password"} name="newPassword" placeholder="Password"   className={`form-control ${
                    newPasswordError ? "is-invalid" : ""
                  }`}  onChange={handleChange}
                  value={newPassword} required/>
             {passwordShown ?     
             <i class="fa fa-eye" aria-hidden="true" onClick={togglePasswordVisiblity} ></i>
             :
             <i class="fa fa-eye-slash" aria-hidden="true"   onClick={togglePasswordVisiblity} ></i>
             }
                <div  className="errmsg">
                    {newPasswordError}
                  </div> 
            </div>
            <div class="form-group">
                <input type={cpasswordShown ? "text" : "password"}  name="confirmPassword" placeholder="Confirm New Password"   className={`form-control ${
                          confirmPasswordError ? "is-invalid" : ""
                        }`}   onChange={handleChange}
                        value={confirmPassword} required />
             {cpasswordShown ?           
             <i class="fa fa-eye" aria-hidden="true" onClick={ctogglePasswordVisiblity} ></i>
              :
             <i class="fa fa-eye-slash" aria-hidden="true"   onClick={ctogglePasswordVisiblity} ></i>
             }
                <div className="invalid-feedback">
                    {confirmPasswordError}
                  </div> 
            </div>
              <span style={{color:"red"}}>  {props.setforgotPswrdErrMsg}</span>
            <div class="Sign-In-button"> 
            {props.loading?
              <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
                      :
                <button>Save</button>
            }
            </div>
        </form>
        </div>
    </div>
</section>

  )
}

const mapStateToProps = (state) => {
  const forgotPswrdData = state.loginReducer.forgotPswrdData
  const forgotError = state.loginReducer.forgotError
  const forgotErrMsg = state.loginReducer.forgotErrMsg
  const loading = state.globalReducer.loading
  const setforgotPswrdMsg = state.loginReducer.setforgotPswrdMsg
  const setforgotPswrdErrMsg = state.loginReducer.setforgotPswrdErrMsg
  return {
    forgotPswrdData,
    forgotErrMsg,
    loading,
    setforgotPswrdMsg,
    forgotError,
    setforgotPswrdErrMsg,
    isLoggedIn: state.loginReducer.isLoggedIn,
    otp:state.loginReducer.otp,
  }
}

const mapDispatch = dispatchEvent => ({
  getForgtPswrdId: (payload) => dispatchEvent({ type: 'GET_FORGOTEMAIL', value: payload }),
  setEmployerPswrd: (payload) => dispatchEvent({ type: 'SET_EMPLOYERFORGOTPASWORD', value: payload }),
  onFieldChange: (payload) => dispatchEvent({ type: 'ONFIELD_CHANGE', value: payload })
})

export default connect(mapStateToProps, mapDispatch)(EmployerResetPassword)


