import React, { useState, useCallback, useEffect } from "react";
import { Calendar } from "@natscale/react-calendar";
import "@natscale/react-calendar/dist/main.css";
import delete1 from "../../assets/images/delete1.png";
import { POST } from "../../utils/helper";
import moment from "moment";
import Modal from "../Common/Modal";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";
import _ from "lodash";
import config from "../../config";

const { BASE_API_URL } = config;

export default function PreviewSlots(props) {
  const {
    start_date,
    end_date,
    time_capture_type,
    availableSlots,
    unavailableSlots,
    day,
    session_duration,
    type,
    timings,
  } = props.location.state;

  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [slot, setSlot] = useState([]);
  const [deleteSlotModal, setDeleteSlotModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmDeleteVal, setConfirmDeleteVal] = useState(false);
  const [deleteData, setDeleteData] = useState("");
  const [NewSlot, setNewSlot] = useState([]);
  const [Available, setAvailable] = useState(availableSlots);
  const [Unavailable, setUnavailable] = useState(unavailableSlots);
  const history = useHistory();

  const { IsUpdate } = props.location;

  useEffect(() => {
    if (!deleteData) {
      setSlot(Available);
    }
  }, [slot]);

  const [updateSlot, setUpdateSlot] = useState([]);
  const handleDelete = () => {
    setDeleteSlotModal(false);

    if (IsUpdate.is_update === true) {
      let Slot = slot.filter(
        (item) => item.start_time !== deleteData.start_time
      );
      setSlot(Slot);

      updateSlot.push({
        start_time: deleteData.start_time,
        end_time: deleteData.end_time,
        is_available: 4,
      });

      setUpdateSlot(updateSlot);
    } else {
      unavailableSlots.push({
        start_time: deleteData.start_time,
        end_time: deleteData.end_time,
        is_available: 4,
      });
      setUnavailable(unavailableSlots);

      let NewSlot = slot.filter(
        (item) => item.start_time !== deleteData.start_time
      );
      setSlot(NewSlot);
    }
  };

  let initialValues;

  if (IsUpdate.is_update === true) {
    initialValues = {
      date: start_date,
      custom_date: end_date,
      timings: timings,
      type: 3,
      session_duration: session_duration,
      time_capture_type: 3,
      is_update: true,
      slot_type: time_capture_type,
      validslots: [...slot, ...updateSlot],
      day: day,
    };
  } else {
    initialValues = {
      date: start_date,
      custom_date: end_date,
      timings: timings,
      type: type,
      session_duration: session_duration,
      time_capture_type: 3,
      validslots:
        time_capture_type === 2
          ? _.union([...slot, ...Unavailable])
          : [...slot, ...Unavailable],
      day: day,
    };
  }

  const handleSubmit = async () => {
    const token = localStorage.getItem("s_token");
    const headers = {
      Authorization: token,
    };
    axios
      .post(
        `${BASE_API_URL}coach/addEditSlot`,
        initialValues,
        { headers }
      )
      .then((response) => {
        toast.success("Successfully created.");

        history.push("/defineavailabilty");
      })
      .catch((error) => {
        toast.success("Slots are already created for the defined duration.");
        console.error("Something went wrong!", error);
      });
  };
  let is_update;
  IsUpdate.is_update === true ? (is_update = true) : (is_update = false);
  return (
    <section
      style={{ minHeight: "100vh", overflowY: "hidden" }}
      className="availbility-check-wrapper bg-color"
    >
      <div className="container chatthread">
        <div className="row flex-column">
          {/* <div
            className="col-sm-5"
            style={{ marginTop: "4rem", marginBottom: "4rem" }}
          ></div> */}
          

          <div className="col-sm-7 mx-auto text-center my-4">
            <div>
              <h3>
                Availablity
              </h3>

              <h5>
                Your sessions will be as follows
              </h5>
            </div>
            <div className="previ-date4 mt-5 pt-4">
              <div>From Date</div>
              <div>To Date</div>
            </div>
            <div className="prev-date5">
              <div>
                <div>{start_date}</div>
                <hr className="hr" />
              </div>
              <div>
                <div>{end_date}</div>
                <hr className="hr" />
              </div>
            </div>
            <div className="content-box prev-date7">
              <div className="prev-date6">
                <div className="prev-date3">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        height: "1rem",
                        width: "1rem",
                        borderRadius: "1rem",
                      }}
                    ></div>
                  </div>

                  <div className="prev-date2">
                    <div className="prev-date1"></div>
                  </div>
                </div>
                <div>
                  {!loading ? (
                    slot && slot.length > 0 ? (
                      slot.map((item) => (
                        <div key={item.start_time} style={{ width: "100%" }}>
                          <div
                            className="form-group"
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div
                              onClick={() => {}}
                              className="row  goalButton"
                              style={{
                                position: "relative",
                                borderColor: item.status === 1 ? "green" : "blue",
                              }}
                            >
                              <div className="prev-slot-img">
                                <strong>
                                  {moment(item.start_time.slice(0, 5), [
                                    "HH.mm",
                                  ]).format("hh:mm a")}
                                  -{"  "}
                                  {moment(item.end_time.slice(0, 5), [
                                    "HH.mm",
                                  ]).format("hh:mm a")}
                                </strong>
                              </div>
                              {
                                <img
                                  className="cursor-pointer"
                                  style={{
                                    position: "absolute",
                                    top: "35%",
                                    right: "4rem",
                                  }}
                                  src={delete1}
                                  onClick={(e) => {
                                    setDeleteSlotModal(true);
                                    setDeleteData(item);
                                  }}
                                  alt="deleteicon"
                                />
                              }
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="prev-content">No Sessions Found</div>
                    )
                  ) : (
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                </div>
              </div>

              <div container5 className="prev-slot">
                <div>
                  <button
                    onClick={() =>
                      history.push({
                        pathname: "/defineslots",
                        state: { is_update: is_update },
                      })
                    }
                    className="prev-submitr"
                  >
                    Back
                  </button>
                </div>
                <div>
                  <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    className="prev-submit"
                  >
                    Create
                  </button>
                </div>
              </div>

              <Modal
                showmodal={confirmDeleteVal}
                onCLose={() => {
                  setConfirmDeleteVal(false);
                }}
                title={`Delete Slot ${
                  deleteData?.start_time
                    ? moment(deleteData.start_time.slice(0, 5), ["HH.mm"]).format(
                        "hh:mm a"
                      )
                    : ""
                } - ${
                  deleteData?.end_time
                    ? moment(deleteData.end_time.slice(0, 5), ["HH.mm"]).format(
                        "hh:mm a"
                      )
                    : ""
                } ?`}
                body={
                  <div>
                    Are you sure you want to delete <strong>this Session</strong>
                  </div>
                }
                handleClick={handleDelete}
              />
              <Modal
                showmodal={deleteSlotModal}
                onCLose={() => {
                  setDeleteSlotModal(false);
                }}
                title={`Delete Slot ${
                  deleteData?.start_time
                    ? moment(deleteData.start_time.slice(0, 5), ["HH.mm"]).format(
                        "hh:mm a"
                      )
                    : ""
                } - ${
                  deleteData?.end_time
                    ? moment(deleteData.end_time.slice(0, 5), ["HH.mm"]).format(
                        "hh:mm a"
                      )
                    : ""
                } ?`}
                body={
                  <div>
                    <div className="prev-slot">
                      <h4>Delete this Session</h4>
                    </div>
                    <div className="prev-slot"></div>
                  </div>
                }
                handleClick={(e) => handleDelete()}
              />
            </div>
          </div>


        </div>
      </div>
    </section>
  );
}
