import { putAPI, deleteAPI } from "./helper"


export const handleLikeHelper = (id) => {
 
    return new Promise(async (resolve, reject) => {
        let data = {
            achievement_id: id,
        }
        try {
            const result = await putAPI('likeDislikeAchievement', data);
            resolve(result);
      

        } catch (error) {
            reject(error);
        }
    })
}

export const handleHighFiveHelper = (id) => {

    return new Promise(async (resolve, reject) => {
        let data = {
            achievement_id: id,
        }
        try {
            const result = await putAPI('highFiveAchievement', data);
            resolve(result);
      

        } catch (error) {
            reject(error);
        }
    })
 

}

export const handleDeleteAchievementHelper = (id) => {
 
    return new Promise(async (resolve, reject) => {

        try {
            const result = await deleteAPI(`deleteAchievement/${id}`);
            resolve(result);

        } catch (error) {
            reject(error);
        }
    })
}


