import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { StripeCheckoutNew } from "../../Components/StripeCheckout/StripeCheckoutNew";
import { useState, useEffect } from "react";
import InvoiceTable from "../../Components/InvoiceTable/InvoiceTable";
import axios from "axios";
import config from "../../config";

const { BASE_API_URL } = config;
const token = localStorage.getItem("s_token");

const StripeCheckoutPage = () => {
    const [isPaid, setIsPaid] = useState(false)
    const [paymentDetails, setPaymentDetails] = useState();
    const [loader, setLoader] = useState()
    const stripeSecret = process.env.REACT_APP_STRIPE_API_KEY;
    const stripeApiKey = loadStripe(stripeSecret);

    useEffect(() => {
        (async () => {
            try {
                setLoader(true)
                const response = await axios.get(`${BASE_API_URL}employer/paymentStripeDetails`, {
                    headers: {
                        Authorization: token
                    }
                });

                if (response?.data?.body?.data.length > 0) {
                    setPaymentDetails(response.data.body.data)
                    setIsPaid(true)
                }
                setLoader(false); 
            } catch (error) {
                console.error("Error fetching payment details:", error);
            } 

            
        }

        )()
       
    }, []);
    return (
        <div>

            {
                loader ?
                    <div style={{height:'100vh'}}>
                        <div
                            style={{ marginTop: "5rem", marginBottom: "15rem" }}
                            class="spinner-border text-primary"
                            role="status"
                        >
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    <>
                        {!isPaid ?
                            <Elements stripe={stripeApiKey}>
                                <StripeCheckoutNew />
                            </Elements>
                            :
                            <InvoiceTable data={paymentDetails} />}
                    </>
            }
        </div>

    );
}

export default StripeCheckoutPage;