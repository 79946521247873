import React from 'react'
import Svgs from '../../../src/assets/Svgs/index'

const TreeSwitch = ({ active, onChange }) => {

    return (
        <div className="d-flex align-items-center justify-content-md-end justify-content-center gap-3 w-100 my-3 pr-md-5">
            <p>View: </p>
            <div className="border rounded px-1 py-1 d-flex align-items-center">
                <div onClick={() => {
                    onChange('list')
                }} className={`custom-tab ${active == 'list' && 'active'}`}>
                    <Svgs.List />
                    List
                </div>
                <div onClick={() => {
                    onChange('tree')
                }} className={`custom-tab ${active == 'tree' && 'active'}`}>
                    <Svgs.Tree />
                    Tree
                </div>
            </div>
        </div>
    )
}

TreeSwitch.defaultProps = {
    active: 'list'
}

export default TreeSwitch