import React, { useState } from "react";
import { connect } from "react-redux";
import { validateEmail, errorMessage } from "../../utils/validation";
import { POST } from "../../utils/helper";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import logo1 from "../../assets/images/logo-1.png";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import cross from "../../assets/images/cross.png";
import { result } from "lodash";

const CoachForgetPassword = (props) => {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleChange = ({ target }) => {
    props.onFieldChange({ name: "OnShowEror", value: "" });
    const value = target.value;
    setEmail(value);
    handleValidation(value);
  };

  const [loginData, setLoginData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const handleValidation = (value) => {
    let emailError = "";
    if (value === "") {
      emailError = errorMessage.required;
    } else {
      if (validateEmail(value)) {
        emailError = "";
      } else {
        emailError = errorMessage.invalidEmail;
      }
    }
    setEmailError(emailError);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    handleValidation(email);
    if (emailError === "") {
      const postObj = { email: email, user_role: 5 };
      try {
        const result = await POST("forgotPassword", postObj, 2);
        if (result.data.success) {
          const { is_both } = result.data.body.data;
          if (is_both) {
            setModalOpen(true);
            setLoginData(result.data.body.data);
            return;
          }
          toast.success(
            "We have sent you a password reset link on your email address",
            {
              position: toast.POSITION.BOTTOM_CENTER,
            }
          );
          history.push("/coachlogin");
        } else {
          toast.error("Something went wrong", {
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: true,
          });
        }
      } catch (error) {
        setEmailError(error.response.data.message);
      }
    }
  };

  const bluxinga_coach = async () => {
    const postObj = { email: email, user_role: 5, app_id: 1 };
    try {
      const result = await POST("forgotPassword", postObj, 2);
      if (result.data.success) {
        toast.success(
          "We have sent you a password reset link on your email address",
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        history.push("/coachlogin");
      }
    } catch (error) {
      setEmailError(error.response.data.message);
    }
  };

  const blutango_coach = async () => {
    const postObj = { email: email, user_role: 5, app_id: 2 };
    try {
      const result = await POST("forgotPassword", postObj, 2);
      if (result.data.success) {
        toast.success(
          "We have sent you a password reset link on your email address",
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        history.push("/coachlogin");
      }
    } catch (error) {
      setEmailError(error.response.data.message);
    }
  };

  const mystyle1 = {
    fontSize: "17px",
    marginRight: "63px",
  };
  const mystyle2 = {
    marginTop: "25px",
    marginLeft: "-158px",
    width: "114px",
  };
  const mystyle3 = {
    marginTop: "25px",
    width: "114px",
    marginRight: "-219px",
  };

  return (
    <section class="sign-in-wrapper">
      <div class="signin-main">
        <div class="signin-box">
          <div class="logo-img">
            <img src={logo1} />
          </div>
          <h3>Forgot Password</h3>
          <form class="setup-new-pass" onSubmit={handleSubmit}>
            <div class="form-group">
              <input
                type="email"
                id="email3"
                name="email"
                placeholder="Email address"
                value={email}
                className={`form-control ${emailError ? "is-invalid" : ""}`}
                onChange={handleChange}
                required
              />
              <div className="invalid-feedback">{emailError}</div>
            </div>

            <div class="Sign-In-button">
              {props.loading ? (
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                <button>Submit</button>
              )}
            </div>
            <div className="errmsg"> {props.forgotPswrdErrMsg}</div>
          
            <Modal isOpen={modalOpen}>
              <ModalHeader style={{ textAlign: "right" }}>
                <div>
                  <img
                    style={{ width: "30px", cursor: "pointer" }}
                    src={cross}
                    className="cross"
                    onClick={() => setModalOpen(false)}
                    alt=""
                  />
                  <h3 style={mystyle1}>
                    {" "}
                    In which portal you want to reset password
                  </h3>
                </div>
              </ModalHeader>
              <ModalBody>
                <div>
                  <button style={mystyle3} onClick={bluxinga_coach}>
                    BluXinga
                  </button>
                </div>
                <div>
                  <button style={mystyle2} onClick={blutango_coach}>
                    BlueTango
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </form>
        </div>
      </div>
    </section>
  );
};
const mapStateToProps = (state) => {
  const forgotPswrdMsg = state.loginReducer.forgotPswrdMsg;
  const forgotPswrdErrMsg = state.loginReducer.forgotPswrdErrMsg;
  const loading = state.globalReducer.loading;
  return {
    forgotPswrdMsg,
    loading,
    forgotPswrdErrMsg,
  };
};

const mapDispatch = (dispatchEvent) => ({
  EmployerResetPswrd: (payload) =>
    dispatchEvent({ type: "EMPLOYERRESET_EMAILSEND", value: payload }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(CoachForgetPassword);
