import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import {
  getGoalPercentage,
  getSelectMeasure,
  POST,
} from "../utils/helper";
import pending from "../assets/images/pending.png";
import history from "../assets/images/history.png";
import { BsFillInfoCircleFill } from "react-icons/bs";
import "../scss/customforall.scss";

export default function GoalDetailDropDown(props) {
  const [inputVal, setInputVal] = useState(null);
  const [currGoalPercentage, setCurrentGoalPercentage] = useState(false);

  const team_goal = props?.goal?.team_goal;
  const {
    team_goal_assign_completion_by_employees,
    complete_measure,
  } = props?.goal;
  const total_complete_measure =
    team_goal_assign_completion_by_employees &&
    team_goal_assign_completion_by_employees[0] &&
    team_goal_assign_completion_by_employees[0].total_complete_measure &&
    team_goal_assign_completion_by_employees[0].total_complete_measure != ""
      ? team_goal_assign_completion_by_employees[0].total_complete_measure
      : "";
  let isPending =
    !team_goal_assign_completion_by_employees ||
    team_goal_assign_completion_by_employees.length > 0
      ? true
      : false;
  let isPendingValue =
    team_goal_assign_completion_by_employees &&
    team_goal_assign_completion_by_employees[0] &&
    team_goal_assign_completion_by_employees[0].complete_measure &&
    team_goal_assign_completion_by_employees[0].complete_measure != ""
      ? team_goal_assign_completion_by_employees[0].complete_measure
      : "";
  let isPendingComValue =
    team_goal_assign_completion_by_employees &&
    team_goal_assign_completion_by_employees[0] &&
    team_goal_assign_completion_by_employees[0].employee_comment &&
    team_goal_assign_completion_by_employees[0].employee_comment != ""
      ? team_goal_assign_completion_by_employees[0].employee_comment
      : "Write here";

  const [comments, setComments] = useState(null);

  const handleCommentChange = (e) => {
    setComments(e.target.value);
  };

  const handleSubmit = async () => {
    if (!isPending) {
      if (!comments) {
        toast.error("Please enter comment", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return;
      }

      if (!inputVal) {
        toast.error("Please enter goal measure", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return;
      }
    }

    let data = {
      goal_id: team_goal.id,
      team_goal_assign_id: props.goal.id,
      complete_measure: inputVal || isPendingValue,
      description: comments || isPendingComValue,
    };

    try {
      const res = await POST("submitGoalAsEmployee", data);
      isPending
        ? toast.success("Updated successfully", {
            position: toast.POSITION.BOTTOM_CENTER,
          })
        : toast.success("Submitted successfully", {
            position: toast.POSITION.BOTTOM_CENTER,
          });

      if (
        res &&
        res.data &&
        res.data.body &&
        res.data.body.data &&
        res.data.body.data.id
      ) {
        if (Number(currGoalPercentage) >= 100) {
          props.setModal({ isOpen: true });
          setCurrentGoalPercentage(null);
        }

        setInputVal("");
        props.getData();
      }
    } catch (err) {
      let msg =
        err && err.response && err.response.data
          ? err.response.data.message
          : "Something went wrong";
      toast.error(msg, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      console.log("err", err);
    }
  };

  let goalCompletion = inputVal
    ? Number(complete_measure) + Number(inputVal)
    : isPending
    ? Number(isPendingValue) + Number(total_complete_measure)
    : Number(complete_measure);
  let goalCompletionPercentage = getGoalPercentage(
    goalCompletion,
    +team_goal?.enter_measure
  );
  let ApprovedGoalCompletionPercentage = getGoalPercentage(
    Number(complete_measure),
    props?.goal?.measure
  );
  let PendingGoalCompletionPercentage = getGoalPercentage(
    Number(isPendingValue) + Number(total_complete_measure),
    props?.goal?.measure
  );

  const handleInputChange = (e) => {
    setInputVal(e.target.value);
  };

  useEffect(() => {
    setCurrentGoalPercentage(goalCompletionPercentage);
  }, [inputVal, goalCompletionPercentage]);

  return (
    <div>
      <div class="Goal-Detail wrapper">
        <div
          onClick={() => {
            props.setIsOpen(null);
          }}
          class="goal-head   position-relative"
        >
          <p>{props?.goal?.team_goal?.title}</p>
          <i class="fa fa-angle-up   fa-angle-up-i"></i>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-6 col-6">
            <div class="start-date btn-blue">
              <button>
                Start Date <br />
                {team_goal?.start_date.slice(0, 10)}
              </button>
              <p>Manager</p>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-6">
            <div class="Due-date btn-blue">
              <button>
                Finish date
                <br />
                {team_goal?.end_date.slice(0, 10)}
              </button>
              <p>Employee Inputs</p>
            </div>
          </div>
        </div>
        <hr />
        <div class="Goal-sales-wrapper">
          <div class="row">
            <div class="col-md-6 col-sm-6 col-6">
              <div class="Goal-Title">
                <p className="color-black"> Target Title</p>
                <p>{team_goal?.title}</p>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-6">
              <div class="Goal-Title">
                <p className="color-black">Current Measure</p>
                <p>
                  Approved: {complete_measure || 0}
                  <br />
                  Pending: {isPendingValue || 0}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-6 col-6">
              <div class="Goal-Title">
                <p style={{ color: "black" }}> Target Measure</p>
                <p>
                  {getSelectMeasure(team_goal.select_measure)}
                  {": "}
                  {props?.goal?.measure}
                </p>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-6">
              <div class="Goal-Title">
                <p className="color-black">
                  Achivement Measure{" "}
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="achivement-measure">
                        Goals are aggregated, please add additional
                        {team_goal?.select_measure == 1
                          ? " amount "
                          : " quantity "}
                        here
                      </Tooltip>
                    }
                  >
                    <a href="#">
                      <BsFillInfoCircleFill color="#000" />
                    </a>
                  </OverlayTrigger>
                </p>
                {/* {isPending ? (
                  <textarea
                    className="textarea-goalsdetail-3"
                    onChange={handleInputChange}
                    value={inputVal}
                    class="form-control"
                    rows="1"
                    placeholder={isPendingValue}
                  ></textarea>
                ) : ( */}
                <input
                  className="textarea-goalsdetail-3"
                  onChange={handleInputChange}
                  value={inputVal}
                  type="number"
                  placeholder="Write here"
                />
                {/* )} */}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-sm-6 col-6">
              <div class="Goal-Title">
                <p style={{ color: "black" }}> Target Particular Specifics</p>

                <p>{team_goal?.description}</p>
              </div>
            </div>

            <div class="col-md-6 col-sm-6 col-6">
              <div class="Goal-Title">
                <p style={{ color: "black" }}>Goal Completion Percentage</p>

                <p>
                  Approved: {ApprovedGoalCompletionPercentage || 0}%
                  <br />
                  Pending: {PendingGoalCompletionPercentage || 0}%
                </p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center mb-4">
            <div class="col-md-5">
              <div class="col align-items-center">
                <p className="text-center mb-2">Comments</p>
                <input
                  className="goalsdetails-comments"
                  onChange={handleCommentChange}
                  value={comments}
                  rows="1"
                  placeholder="Please enter comment..."
                  // placeholder={isPendingComValue}
                />
              </div>
            </div>
          </div>
        </div>
        {isPending ? (
          comments?.length > 0 || inputVal?.length > 0 ? (
            <div class="submit-btn  btn-blue">
              <button
                className="goaldetails-button"
                onClick={() => props.handleHistoryGoals(props?.goal)}
              >
                Target History&nbsp;{" "}
                <img className="goaldetails-img" src={history} />
              </button>
              <button onClick={handleSubmit}> Update</button>
            </div>
          ) : (
            <div class="submit-btn  btn-blue">
              <button
                className="goaldetails-button"
                onClick={() => props.handleHistoryGoals(props?.goal)}
              >
                Target History&nbsp;{" "}
                <img className="goaldetails-img" src={history} />
              </button>
              <button disabled={true} className="btn-disabled">
                {" "}
                <img className="pending-goalsdetail" src={pending} />
                &nbsp; Pending
              </button>
            </div>
          )
        ) : (
          <div class="submit-btn  btn-blue d-flex">
            <button
              className="submit-goalsdetails"
              onClick={() => props.handleHistoryGoals(props?.goal)}
            >
              Target History&nbsp;{" "}
              <img className="goaldetails-img" src={history} />
            </button>
            <button className="width-10rem" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
