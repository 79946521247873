import { put } from 'redux-saga/effects'


export function * onFieldChange (action) {
  const payload = action.value
  try {
   
    yield put({ type: payload.name, data: payload.value })
  } catch (error) {
    console.log(error)
  }
}