import React, { useState } from "react";
import { connect } from "react-redux";
import {
  validateEmail,
  validatePassword,
  errorMessage,
} from "../../utils/validation";
import { POST } from "../../utils/helper";
import TCModal from "../TermConditionModal/TCModal";
import logo1 from "../../assets/images/logo-1.png";
import { toast } from "react-toastify";
const CoachLogin = (props) => {
  const [formState, setFormState] = useState({

    formValues: {
      email: "",
      password: "",
    },
    formErrors: {
      email: "",
      password: "",
      api: "",
    },
    formValidity: {
      email: false,
      password: false,
    },
  });

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };
  const handleChange = ({ target }) => {
  
    props.onFieldChange({ name: "OnShowEror", value: "" });
    const { formValues } = formState;
    formValues[target.name] = target.value;
    setFormState({ formValues });
    handleValidation(target);
  };

  const handleValidation = (target) => {
    const { name, value } = target;
    const fieldValidationErrors = formState.formErrors;
    const validity = formState.formValidity;
    const isEmail = name === "email";
    const isPassword = name === "password";
    fieldValidationErrors.api = "";
    validity[name] = value.length > 0;
    fieldValidationErrors[name] = validity[name] ? "" : errorMessage.required;
    if (validity[name]) {
      if (isEmail) {
        validity[name] = validateEmail(value);
        fieldValidationErrors[name] = validity[name]
          ? ""
          : errorMessage.invalidEmail;
      }
      if (isPassword) {
        validity[name] = validatePassword(value);
        fieldValidationErrors[name] = validity[name]
          ? ""
          : errorMessage.paswrdError;
      }
    }
    setFormState({
      ...formState,
      formErrors: fieldValidationErrors,
      formValidity: validity,
    });
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    const { formValues, formValidity } = formState;
    if (Object.values(formValidity).every(Boolean)) {
      const postObj = {
        username: formState.formValues.email,
        password: formState.formValues.password,
        checkbox: checkbox,
        app_id: 1,
      };

      if (!checkbox) {
        toast.error("Please agree to our Terms", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return;
      }

      localStorage.setItem("agree_terms", checkbox);

      try {
         await POST(`login`, postObj, 2);
        props.coachlogIn({ postObj, history: props.history });
      } catch (error) {
        console.log(error.response);
        setFormState((state) => ({
          ...state,
          formErrors: {
            ...state.formErrors,
            api: error.response?.data?.message,
          },
        }));
      }
    } else {
      for (let key in formValues) {
        let target = {
          name: key,
          value: formValues[key],
        };
        handleValidation(target);
      }
    }
  };

  

  const [checkbox, setCheckbox] = useState(
    parseInt(localStorage.getItem("agree_terms") || 0)
  );
  const [modalIsOpen, setmodalIsOpen] = useState(false);

  const handleCheckBox = () => {
    if (checkbox === 0) {
      setCheckbox(1);
    } else {
      setCheckbox(0);
    }
  };
  return (
    <React.Fragment>
      <section className="sign-in-wrapper">
        {modalIsOpen && (
          <TCModal modalIsOpen={modalIsOpen} setmodalIsOpen={setmodalIsOpen} />
        )}
        <div className="signin-main">
          <div className="signin-box">
            <div class="logo-img">
              <img alt="" className="logo-img" src={logo1} />
            </div>
            <h3>Sign In</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  placeholder="Email address"
                  type="email"
                  name="email"
                  className={`form-control ${
                    formState.formErrors.email ? "is-invalid" : ""
                  }`}
                  onChange={handleChange}
                  value={formState.formValues.email}
                  required
                />
                <div className="invalid-feedback">
                  {formState.formErrors.email}
                </div>
              </div>
              <div className="form-group">
                <div style={{ display: "flex", position: "relative" }}>
                  <input
                    type={passwordShown ? "text" : "password"}
                    id="password3"
                    name="password"
                    className={`form-control ${
                      formState.formErrors.password ? "is-invalid" : ""
                    }`}
                    autoComplete="current-password"
                    onChange={handleChange}
                    value={formState.formValues.password}
                    required
                    placeholder="Password"
                  />
                  {passwordShown ? (
                    <i
                      class="fa fa-eye"
                      aria-hidden="true"
                      style={{
                        position: "absolute",
                        top: "33%",
                        right: "10%",
                      }}
                      onClick={togglePasswordVisiblity}
                    ></i>
                  ) : (
                    <i
                      class="fa fa-eye-slash"
                      aria-hidden="true"
                      style={{
                        position: "absolute",
                        top: "33%",
                        right: "10%",
                      }}
                      onClick={togglePasswordVisiblity}
                    ></i>
                  )}
                </div>
                <div className="errmsg" style={{textAlign: '-webkit-right'}}>{formState.formErrors.password}</div>
              </div>

              <span style={{ color: "red" }}> {formState.formErrors.api}</span>

              <div
                class="tacbox tacbox1"
           
              >
                <input
                  className="checkbox"
                  name="checkbox"
                  type="checkbox"
                  checked={checkbox}
                  id="myCheck"
                  onClick={(e) => handleCheckBox()}
                
                />
                <label
                  for="checkbox"
                  className="label1  label0"
               
                >
                  I agree to these{" "}
                  <a
                    href="#"
                    style={{
                      fontSize: "10px",
                      color: "blue",
                      lineHeight: "1px",
                    }}
                    onClick={() => setmodalIsOpen(true)}
                  >
                    Terms and Conditions
                  </a>
                </label>
              </div>
              <div className="Sign-In-button">
                {props.loading ? (
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <button type="submit" onClick={handleSubmit}>
                    Sign In
                  </button>
                )}

                <button
                  class="forget-Password forgotbtn"
               
                  onClick={() => props.history.push("/coachforgetpassword")}
                >
                  Forgot Password
                </button>
              </div>
            </form>

          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const errorMsg = state.loginReducer.errorMsg;
  const loading = state.globalReducer.loading;
  return {
    loading,
    errorMsg,
    isLoggedIn: state.loginReducer.isLoggedIn,
    coachloginData: state.loginReducer.coachloginData,
  };
};

const mapDispatch = (dispatchEvent) => ({
  coachlogIn: (payload) =>
    dispatchEvent({ type: "GET_COACHDATA", value: payload }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(CoachLogin);
