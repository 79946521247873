const BASE_URL = "https://app.bluxinga.com/";
const BASE_URL_WEB_VIEW = "https://www.bluxinga.com";
const BASE_API_URL = "https://api.bluxinga.com/api/v1/";
const FIREBASE_MESSAGING_SERVICE_WORKER = "./firebase-messaging-sw.js";
const FIREBASE_API_KEY = "AIzaSyDe7-LsFvVU1ZUOWsJdkfLhkRBpMtW6wvk";
const FIREBASE_AUTH_DOMAIN = "bluetango-c7f35.firebaseapp.com";
const FIREBASE_PROJECT_ID = "bluetango-c7f35";
const FIREBASE_STORAGE_BUCKET = "bluetango-c7f35.appspot.com";
const FIREBASE_MESSAGING_SENDER_ID = "285169942807";
const FIREBASE_APP_ID = "1:285169942807:web:8575a8e53b6e07a1d97752";
const FIREBASE_MEASUREMENT_ID = "MEASUREMENT_ID";
const SMALL_PLAN_ID = "price_1PhXnFIriDhPQnrm89f5O7Ic";
const MEDIUM_PLAN_ID = "price_1PhXpHIriDhPQnrmGGIIcBRn";
const LARGE_PLAN_ID = "price_1PhXpPIriDhPQnrmv5BIL33B";

export default {
  BASE_URL,
  BASE_URL_WEB_VIEW,
  BASE_API_URL,
  FIREBASE_MESSAGING_SERVICE_WORKER,
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
  SMALL_PLAN_ID,
  MEDIUM_PLAN_ID,
  LARGE_PLAN_ID
};
