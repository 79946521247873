
export const LOADING_SHOW = 'LOADING_SHOW';
export const LOADING_HIDE = 'LOADING_HIDE';
export const GETLOADING_SHOW = 'GETLOADING_SHOW';
export const GETLOADING_HIDE = 'GETLOADING_HIDE';

export const GET_LOGINDATA = "GET_LOGINDATA";
export const GET_LOGINDATA_TOKEN = "GET_LOGINDATA_TOKEN";
export const LOGIN_DATASUCCESS = "LOGIN_DATASUCCESS"
export const LOGIN_DATAFAIL = "LOGIN_DATAFAIL"
export const CHANGEPASWRD_DATASUCCESS = "CHANGEPASWRD_DATASUCCESS"
export const CHANGEPASWRD_DATAFAIL = "CHANGEPASWRD_DATAFAIL"


export const EMPLOYERLOGIN_DATASUCCESS = 'EMPLOYERLOGIN_DATASUCCESS'
export const UPDATE_PAYMENT_STATUS = 'UPDATE_PAYMENT_STATUS'
export const SETEMPOLYERFORGOTPASRD_DATASUCCESS = 'SETEMPOLYERFORGOTPASRD_DATASUCCESS'


export const COACHLOGIN_DATASUCCESS = 'COACHLOGIN_DATASUCCESS'
export const SETCOACHFORGOTPASRD_DATASUCCESS = 'SETCOACHFORGOTPASRD_DATASUCCESS'


export const SET_LOGOUT = "SET_LOGOUT"


export const FORGOTPASRD_DATASUCCESS = "FORGOTPASRD_DATASUCCESS"
export const FORGOTPASRD_DATAFAIL = "FORGOTPASRD_DATAFAIL"
export const SETFORGOTPASRD_DATASUCCESS = "SETFORGOTPASRD_DATASUCCESS"
export const SETFORGOTPASRD_DATAFAIL = "SETFORGOTPASRD_DATAFAIL"


export const EMPLOYER_DATASUCCESS = "EMPLOYER_DATASUCCESS"
export const EMPLOYER_DATAFAIL = "EMPLOYER_DATAFAIL"
export const ADDEMPLOYER_DATASUCCESS = "ADDEMPLOYER_DATASUCCESS"
export const ADDEMPLOYER_DATAFAIL = "ADDEMPLOYER_DATAFAIL"
export const INDUSTRY_DATASUCCESS = "INDUSTRY_DATASUCCESS"
export const INDUSTRY_DATAFAIL = "INDUSTRY_DATAFAIL"
export const EMPLOYERTEAM_DATASUCCESS = "EMPLOYERTEAM_DATASUCCESS"
export const EMPLYRTEAMDETAIL_DATASUCCESS = 'EMPLYRTEAMDETAIL_DATASUCCESS'
export const EMPLYRDEPARTMENT_DATASUCCESS = 'EMPLYRDEPARTMENT_DATASUCCESS'
export const EMPLYRMANAGER_DATASUCCESS = 'EMPLYRMANAGER_DATASUCCESS'

export const PROFILE_DATASUCCESS = 'PROFILE_DATASUCCESS'
export const PROFILE_DATAFAIL = 'PROFILE_DATAFAIL'
export const PROFILE_IMAGESUCCESS = 'PROFILE_IMAGESUCCESS'
export const PROFILE_IMAGEFAIL = 'PROFILE_IMAGEFAIL'

export const TEAM_DATASUCCESS = 'TEAM_DATASUCCESS'
export const ALL_TEAM_DATA = 'ALL_TEAM_DATA'
export const TEAM_DATAFAIL = 'TEAM_DATAFAIL'
export const TEAMDETAIL_DATASUCCESS = 'TEAMDETAIL_DATASUCCESS'
export const TEAMDETAIL_DATAFAIL = 'TEAMDETAIL_DATAFAIL'
export const SEARCHTEAM_DATA = 'SEARCHTEAM_DATA'
export const TEAMENERGY_DATASUCCESS = 'TEAMENERGY_DATASUCCESS'


export const MANAGERGOAL_DATASUCCESS = 'MANAGERGOAL_DATASUCCESS'
export const MANAGERGOAL_DATAFAIL = 'MANAGERGOAL_DATAFAIL'
export const UPDATEMANAGERGOALSUCCESS = 'UPDATEMANAGERGOALSUCCESS'
export const UPDATEMANAGERGOALFAIL = 'UPDATEMANAGERGOALFAIL'
export const ADDMANAGERGOALSUCCESS = 'ADDMANAGERGOALSUCCESS'
export const DELETEMANAGERGOALSUCCESS = 'DELETEMANAGERGOALSUCCESS'

export const ADDMANAGERRATE_DATASUCCESS = 'ADDMANAGERRATE_DATASUCCESS'
export const ADDMANAGERRATE_DATAFAIL = 'ADDMANAGERRATE_DATAFAIL'
export const RATEDETAIL_DATASUCCESS = 'RATEDETAIL_DATASUCCESS'
export const RATEDETAIL_DATAFAIL = 'RATEDETAIL_DATAFAIL'
export const RATINGPOPUP_DATASUCCESS = 'RATINGPOPUP_DATASUCCESS'
export const RATINGPOPUP_DATAFAIL = 'RATINGPOPUP_DATAFAIL'

export const CHATPOPUP_DATASUCCESS = 'CHATPOPUP_DATASUCCESS'
export const CHATROOM_DATASUCCESS = 'CHATROOM_DATASUCCESS'
export const CHATROOM_DATAFAIL = 'CHATROOM_DATAFAIL'
export const CHATUSER_DATASUCCESS = 'CHATUSER_DATASUCCESS'
export const CHATUSER_DATAFAIL = 'CHATUSER_DATAFAIL'



export const NOTIFICATION_COUNT_SUCCESS = 'NOTIFICATION_SUCCESS'
export const NOTIFICATION_COUNT_FAIL = 'NOTIFICATION_FAIL'



export const PARTICULAR_ACHIEVEMENT_SUCCESS = 'PARTICULAR_ACHIEVEMENT_SUCCESS'
export const PARTICULAR_ACHIEVEMENT_FAIL = 'PARTICULAR_ACHIEVEMENT_FAIL'


export const ACHIEVEMENT_COMMENTS_SUCCESS = 'ACHIEVEMENT_COMMENTS_SUCCESS'
export const ACHIEVEMENT_COMMENTS_FAIL = 'ACHIEVEMENT_COMMENTS_FAIL'



export const ACHIEVEMENT_LIST_SUCCESS = 'ACHIEVEMENT_LIST_SUCCESS'
export const ACHIEVEMENT_LIST_FAIL = 'ACHIEVEMENT_LIST_FAIL'


export const REST_LOADING = 'REST_LOADING';



export const CREATECHAT_ROOM = 'CREATECHAT_ROOM';
export const ONFIELD_CHANGE = 'ONFIELD_CHANGE';
export const CHAT_NOTIF = 'CHAT_NOTIF';
export const GET_NOTIFICATION_COUNT = 'GET_NOTIFICATION_COUNT';