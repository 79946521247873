import { useSelector } from "react-redux";
import config from "../../config";
import * as yup from "yup";
import { toast } from "react-toastify";

function useFAQ() {

    const { BASE_API_URL } = config;
    const emailsToCheck = ["olivia.bluxinga@bluxinga.com"];
    const loginReducer = useSelector(state => state?.loginReducer)
    const loginRole = loginReducer?.loginRole;
    const email = loginRole == 1 ? loginReducer?.employerloginData?.email : loginReducer?.loginData?.email;
    const BASE_URL = `${BASE_API_URL}${loginRole == 1 ? "employer" : "employee"}`

    const getAllFaq = (setData) => {
        fetch(BASE_API_URL + 'faq/').then(resp => resp.json()).then(result => {
            console.log(result, "result");
            if (result?.success) {
                setData(result?.body?.data)
            }
        });
    }

    const updateFAQ = async (data, id) => {
        const token = localStorage.getItem("s_token");
        const result = await fetch(`${BASE_URL}/updateFAQ/${id}`, {
            body: JSON.stringify(data),
            headers: {
                Accept: "application/json",
                Authorization: token,
                "Content-Type": "application/json",
            },
            method: "PUT",
        });
        return result.json()
    };

    const createFAQ = async (data) => {
        const token = localStorage.getItem("s_token");
        const result = await fetch(`${BASE_URL}/createFAQ/`, {
            body: JSON.stringify(data),
            headers: {
                Accept: "application/json",
                Authorization: token,
                "Content-Type": "application/json",
            },
            method: "POST",
        });
        return result.json()
    };

    const FAQschema = yup.object({
        question: yup
            .string()
            .trim()
            .required("Question is required!"),

        answer: yup
            .string()
            .trim()
            .required("Answer is required!"),
    })

    const updateFAQSorting = (data) => {
        const token = localStorage.getItem("s_token");

        return new Promise(async (resolve, reject) => {
            try {
                const result = await fetch(`${BASE_URL}/updateFAQSorting`, {
                    body: JSON.stringify(data),
                    headers: {
                        Accept: "application/json",
                        Authorization: token,
                        "Content-Type": "application/json",
                    },
                    method: "PATCH",
                }).then(resp => resp.json());

                if (result.success) {
                    resolve(result);
                } else {
                    reject(result);
                }
            } catch (error) {
                console.log(error);
            }
        });
    };

    const onSubmit = (values, Data, formik, setFAQForm, setDataa, setEdit,Edit) => {

        let faqUpdate = Edit?.status ? updateFAQ({
            question: values.question,
            answer: values.answer
        }, Data?.id) : createFAQ({
            question: values.question,
            answer: values.answer
        });

        faqUpdate.then(resp => {
            if (resp?.success) {
                formik.resetForm();
                setFAQForm(false);
                getAllFaq(setDataa);
                setEdit({ status: false, data: [] });
                toast.success(`FAQ ${Edit?.status ? "edited" : "added"} successfully`)
            } else if (resp?.message){
                toast.error(resp?.message)
            } else {
                toast.error("Something went wrong!")
            }
        })
    }

    const deleteFAQ = (id) => {
        const token = localStorage.getItem("s_token");

        return new Promise(async (resolve, reject) => {
            try {
                const result = await fetch(`${BASE_URL}/deleteFAQ/${id}`, {
                    headers: {
                        Accept: "application/json",
                        Authorization: token,
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    method: "DELETE",
                }).then(resp => resp.json());
                if (result.success) {
                    resolve(result);
                } else {
                    reject(result);
                }
            } catch (error) {
                console.log(error);
            }
        });
    };

    return {
        getAllFaq, deleteFAQ, emailsToCheck, email, loginReducer, BASE_URL, createFAQ, updateFAQ, FAQschema, updateFAQSorting, onSubmit
    }
}

export default useFAQ;