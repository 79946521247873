import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'


import { connect, useDispatch } from 'react-redux'
import { validateEmail, validatePassword, errorMessage } from '../../utils/validation'
import { useSelector } from "react-redux"
import logo1 from '../../assets/images/logo-1.png'
import Modal from 'react-modal'
import { toast } from "react-toastify";
import './Login.css'
import TCModal from '../TermConditionModal/TCModal';


const EmployerLogin = (props) => {
  const dispatch = useDispatch();

  const type = useSelector(state => state.loginReducer.loginRole);
  
  const [loader, setLoader] = useState(false);
  const [formState, setFormState] = useState({

    formValues: {
      email: "",
      password: ""
    },
    formErrors: {
      email: "",
      password: ""
    },
    formValidity: {
      email: false,
      password: false
    }
  });
  const [passwordShown, setPasswordShown] = useState(false);

  useEffect(()=>{
    dispatch({ type: "REST_LOADING" });
  },[])

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };
  const handleChange = ({ target }) => {
    props.onFieldChange({ name: 'OnShowEror', value: '' })
    const { formValues } = formState;
    formValues[target.name] = target.value;
    setFormState({ formValues });
    handleValidation(target);
  };

  const handleValidation = target => {
    const { name, value } = target;
    const fieldValidationErrors = formState.formErrors;
    const validity = formState.formValidity;
    const isEmail = name === "email";
    const isPassword = name === "password";
    validity[name] = value.length > 0;
    fieldValidationErrors[name] = validity[name]
      ? ""
      : errorMessage.required;
    if (validity[name]) {
      if (isEmail) {
        validity[name] = validateEmail(value);
        fieldValidationErrors[name] = validity[name]
          ? ""
          : errorMessage.invalidEmail;
      }
      if (isPassword) {
        validity[name] = validatePassword(value);
        fieldValidationErrors[name] = validity[name]
          ? ""
          : errorMessage.paswrdError;
      }
    }
    setFormState({
      ...formState,
      formErrors: fieldValidationErrors,
      formValidity: validity
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

      const { formValues, formValidity } = formState;
      if (Object.values(formValidity).every(Boolean)) {

        const postObj = { username: formState.formValues.email, password: formState.formValues.password,checkbox:checkbox }
       
        if(!checkbox) {
          toast.error("Please agree to our Terms",{
            position: toast.POSITION.BOTTOM_CENTER,
          })
          return;
        }
        props.employerlogIn({ postObj, history: props.history })
        localStorage.setItem("agree_terms",checkbox)

      } else {
        for (let key in formValues) {
          let target = {
            name: key,
            value: formValues[key]
          };
          handleValidation(target);
        }
      }

   
  };

  const [modalIsOpen, setmodalIsOpen] = useState(false)
  const [checkbox, setCheckbox] = useState(
    parseInt(localStorage.getItem("agree_terms") || 0)
  );
  const handleCheckBox = () => {
    if (checkbox === 0) {
      setCheckbox(1);
    } else {
      setCheckbox(0);
    }
  };
  return (
    <React.Fragment>

      <section className="sign-in-wrapper">
        {modalIsOpen && <TCModal modalIsOpen={modalIsOpen} setmodalIsOpen={setmodalIsOpen} />}
        <div className="signin-main">
          <div className="signin-box">
            <div class="logo-img">
              <img className="logo-img" src={logo1} />
            </div>
            <h3>Sign In</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input placeholder="Email address"
                  type="email" name="email"
                  className={`form-control ${formState.formErrors.email ? "is-invalid" : ""
                    }`} onChange={handleChange}
                  value={formState.formValues.email} required
                />
                <div className="invalid-feedback">
                  {formState.formErrors.email}
                </div>
              </div>
              <div className="form-group">
                <div style={{ display: 'flex', position: 'relative' }}>

                  <input type={passwordShown ? "text" : "password"} id="password3" name="password"
                    className={`form-control ${formState.formErrors.password ? "is-invalid" : ""
                      }`} autoComplete="current-password" onChange={handleChange}
                    value={formState.formValues.password} required placeholder="Password" />
                  {passwordShown ?
                    <i class="fa fa-eye" aria-hidden="true" style={{ position: 'absolute', top: '33%', right: '10%' }} onClick={togglePasswordVisiblity}  ></i>
                    :
                    <i class="fa fa-eye-slash" aria-hidden="true" style={{ position: 'absolute', top: '33%', right: '10%' }} onClick={togglePasswordVisiblity} ></i>
                  }
                </div>
                <div className="errmsg text-right">
                  {formState.formErrors.password}
                </div>

              </div>

              
              <span style={{ color: "red" }}>  {props.errorMsg}</span>
              <div class="tacbox tacbox1"
              >
                <input
                  name="checkbox"
                  type="checkbox"
                  id="myCheck"
                  checked={!!checkbox}
                  onClick={() => handleCheckBox()}
                  style={{
                    boxShadow: 'none'
                  }}
                />
                <label for="checkbox" className='label1 label0'>
                  I agree to these <a href="#" style={{ fontSize: '10px', color: 'blue', lineHeight: '1px' }} onClick={() => setmodalIsOpen(true)}>Terms and Conditions</a>
                  </label>
                
                <div></div>

              </div>
              <div style={{ display: 'flex' }}>

                <div className="Sign-In-button">
                  {props.loading ?
                     <button type="submit" name="submit" value="Submit"  disabled={true} >Sign In</button>
                    :
                    <button type="submit" name="submit" value="Submit">Sign In</button>
                  }
                  <button class="forget-Password" style={{
                    color: "#2517ec"
                  }} onClick={() => props.history.push('/employerforgetpassword')}>Forgot Password</button>

                </div>

              </div>
            
            </form>
          </div>




        </div>
      </section>

      <div style={{ margintop: '2px' }}>
   
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const errorMsg = state.loginReducer.errorMsg
  const loading = state.globalReducer.loading
  return {
    loading,
    errorMsg,
    isLoggedIn: state.loginReducer.isLoggedIn,
    employerloginData: state.loginReducer.employerloginData
  }
}

const mapDispatch = dispatchEvent => ({
  employerlogIn: (payload) => dispatchEvent({ type: 'GET_EMPLOYERLOGINDATA', value: payload }),
  onFieldChange: (payload) => dispatchEvent({ type: 'ONFIELD_CHANGE', value: payload })
})

export default connect(mapStateToProps, mapDispatch)(EmployerLogin)


