import React, { useState, useEffect } from "react";
import sub from "../assets/images/Subscription.png";
import axios from "axios";
import Navbar from "./Common/Navbar";
import config from "../config";

const { BASE_API_URL } = config;

export default function Pricing() {
  const [data, setData] = useState();

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoader(true);
        const result = await axios(
          `${BASE_API_URL}web/getSubscriptions`
        );
        setData(result.data.body.data);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    };

    getData();
  }, []);

  return (
    <div>
      <Navbar />
      <section className="Pricing-wrapper bg-color">
        <div>
          <div className="Pricing comman-width-container">
            <div className="Subscription-head">
              <h2>Subscription Plan</h2>
              <img className="img-fluid" alt="" src={sub} />
            </div>
            <div className="Subscription-Plan">
              {!loader ? (
                data ? (
                  <>
                    <div className="Subscription-details two">
                      <div className="time-info">
                        <div className="Business-plan">
                          <br></br> <br></br>
                          <p>{"Trial Plan"}</p>
                          <span></span>
                        </div>
                        <small>$</small>
                        <h1>
                          {"0"}

                          <span>
                            for {"7 "}
                            days
                          </span>
                        </h1>
                      </div>
                    </div>
                    <div className="Subscription-details two">
                      <div className="time-info">
                        <div className="Business-plan">
                          <br></br> <br></br>
                          <p>{"Premium Plan"}</p>
                          <span></span>
                        </div>
                        <small>$</small>
                        <h1>
                          {data.default_rate_of_subscription}
                          <span>
                            for {"30 "}
                            days per employee
                          </span>
                        </h1>
                      </div>
                    </div>
                  </>
                ) : (
                  "No Subscriptions Found"
                )
              ) : (
                <div
                  style={{ marginTop: "5rem", marginBottom: "15rem" }}
                  className="spinner-border text-primary"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
