import { useMutation, useQuery } from "@tanstack/react-query";
import instanceAxios from "../config/instance-axios";
import { generateApiEndpointByRole } from "../utils/roles";

export const useAddGoals = (loginRole) => {
  const queryKey = ["addGoal", loginRole];
  return useMutation(
    queryKey,
    async (goalsPayload) => {
      const response = await instanceAxios.post(
        generateApiEndpointByRole("addGoal", loginRole),
        goalsPayload
      );
      return response.data;
    },
    {
      retry: 2,
    }
  );
};

export const useEditGoal = (loginRole) => {
  const queryKey = ["editGoal", loginRole];
  return useMutation(
    queryKey,
    async (goalsPayload) => {
      const response = await instanceAxios.post(
        generateApiEndpointByRole("editGoal", loginRole),
        goalsPayload
      );
      return response.data;
    },
    {
      retry: 2,
    }
  );
};

export const useDeleteGoal = (loginRole) => {
  const queryKey = ["deleteGoal", loginRole];
  return useMutation(
    queryKey,
    async (goalsPayload) => {
      const response = await instanceAxios.delete(
        generateApiEndpointByRole("deleteGoalMember", loginRole),
        {
          data: goalsPayload,
        }
      );
      return response.data;
    },
    {
      retry: 2,
    }
  );
};

export const useGoalById = (loginRole, goal_id, enable = true) => {
  const queryKey = ["goalById", loginRole, goal_id, enable];
  return useQuery(
    queryKey,
    async () => {
      const response = await instanceAxios.get(
        generateApiEndpointByRole(
          `viewGoalAsManager?id=${goal_id}`,
          loginRole
        )
      );
      return response.data;
    },
    {
      enabled: enable,
      retry: 2,
    }
  );
};
