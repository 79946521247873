import React, { useEffect, useState } from "react";
import QualitativeItem from "./QualitativeItem";

import * as animationData0 from "../assets/json/Sundae 1/1.1 0.json";
import * as animationData from "../assets/json/Sundae 1/1.1 0 to 20.json";
import * as animationData2 from "../assets/json/Sundae 1/1.1 20 to 40.json";
import * as animationData3 from "../assets/json/Sundae 1/1.1 40 to 60.json";
import * as animationData4 from "../assets/json/Sundae 1/1.1 60 to 80.json";
import * as animationData5 from "../assets/json/Sundae 1/1.1 60 to 80.json";
import * as animationData6 from "../assets/json/Sundae 1/1.1 100 to.json";
import * as animationData7 from "../assets/json/Sundae 1/Celebration JSON file.json";

import * as two_animationData0 from "../assets/json/Sundae 2/2.1 0.json";
import * as two_animationData from "../assets/json/Sundae 2/2.1 0 to 20.json";
import * as two_animationData2 from "../assets/json/Sundae 2/2.1 20 to 40.json";
import * as two_animationData3 from "../assets/json/Sundae 2/2.1 40 to 60.json";
import * as two_animationData4 from "../assets/json/Sundae 2/2.1 60 to 80.json";
import * as two_animationData5 from "../assets/json/Sundae 2/2.1 80 to 100.json";
import * as two_animationData6 from "../assets/json/Sundae 2/2.1 100 to.json";

import * as three_animationData0 from "../assets/json/Sundae 3/3.1 0.json";
import * as three_animationData from "../assets/json/Sundae 3/3.1 0 to 20.json";
import * as three_animationData2 from "../assets/json/Sundae 3/3.1 20 to 40.json";
import * as three_animationData3 from "../assets/json/Sundae 3/3.1 40 to 60.json";
import * as three_animationData4 from "../assets/json/Sundae 3/3.1 60 to 80.json";
import * as three_animationData5 from "../assets/json/Sundae 3/3.1 80 to 100.json";
import * as three_animationData6 from "../assets/json/Sundae 3/3.1 100 to.json";

import * as four_animationData0 from "../assets/json/Sundae 4/4.1 0.json";
import * as four_animationData from "../assets/json/Sundae 4/4.1 0 to 20.json";
import * as four_animationData2 from "../assets/json/Sundae 4/4.1 20 to 40.json";
import * as four_animationData3 from "../assets/json/Sundae 4/4.1 40 to 60.json";
import * as four_animationData4 from "../assets/json/Sundae 4/4.1 60 to 80.json";
import * as four_animationData5 from "../assets/json/Sundae 4/4.1 80 to 100.json";
import * as four_animationData6 from "../assets/json/Sundae 4/4.1 100 to.json";

import * as five_animationData0 from "../assets/json/Sundae 5/5.1 0.json";
import * as five_animationData from "../assets/json/Sundae 5/5.1 0 to 20.json";
import * as five_animationData2 from "../assets/json/Sundae 5/5.1 20 to 40.json";
import * as five_animationData3 from "../assets/json/Sundae 5/5.1 40 to 60.json";
import * as five_animationData4 from "../assets/json/Sundae 5/5.1 60 to 80.json";
import * as five_animationData5 from "../assets/json/Sundae 5/5.1 80 to 100.json";
import * as five_animationData6 from "../assets/json/Sundae 5/5.1 100 to.json";

export default function QuantitativeChart(props) {
  const [primary, setPrimary] = useState(props.primary);
  const [celebration1, setCelebration1] = useState(false);
  const [celebration2, setCelebration2] = useState(false);
  const [celebration3, setCelebration3] = useState(false);
  const [celebration4, setCelebration4] = useState(false);
  const [celebration5, setCelebration5] = useState(false);
  const [animationCup1, setanimationCup1] = useState(animationData);
  const [animationCup2, setanimationCup2] = useState(two_animationData);
  const [animationCup3, setanimationCup3] = useState(three_animationData);
  const [animationCup4, setanimationCup4] = useState(four_animationData);
  const [animationCup5, setanimationCup5] = useState(five_animationData);
  const [celebrationSparkels, setCelebrationSparkels] = useState(
    animationData7
  );

  let look = JSON.parse(localStorage.getItem("look"))?.showLook;

  useEffect(() => {
    let primary = [];
    props?.quantitativeDetail?.forEach((innerDetail, index) => {
      if (index <= 4) {
        primary.push(innerDetail);
      }
    });

    setPrimary(primary);

    if (primary.length > 0) {
      for (let i = 0; i < primary.length; i++) {
        if (i === 0) {
          if (((Number(primary[0]?.complete_measure) / primary[0]?.measure) * 100).toFixed(2) === 0) {
            setanimationCup1(animationData0);
          } else if (
            ((Number(primary[0]?.complete_measure) / primary[0]?.measure) * 100).toFixed(2) > 0 &&
            ((Number(primary[0]?.complete_measure) / primary[0]?.measure) * 100).toFixed(2) <= 20
          ) {
            setanimationCup1(animationData);
          } else if (
            ((Number(primary[0]?.complete_measure) / primary[0]?.measure) * 100).toFixed(2) > 20 &&
            ((Number(primary[0]?.complete_measure) / primary[0]?.measure) * 100).toFixed(2) <= 40
          ) {
            setanimationCup1(animationData2);
          } else if (
            ((Number(primary[0]?.complete_measure) / primary[0]?.measure) * 100).toFixed(2) > 40 &&
            ((Number(primary[0]?.complete_measure) / primary[0]?.measure) * 100).toFixed(2) <= 60
          ) {
            setanimationCup1(animationData3);
          } else if (
            ((Number(primary[0]?.complete_measure) / primary[0]?.measure) * 100).toFixed(2) > 60 &&
            ((Number(primary[0]?.complete_measure) / primary[0]?.measure) * 100).toFixed(2) <= 80
          ) {
            setanimationCup1(animationData4);
          } else if (
            ((Number(primary[0]?.complete_measure) / primary[0]?.measure) * 100).toFixed(2) > 80 &&
            ((Number(primary[0]?.complete_measure) / primary[0]?.measure) * 100).toFixed(2) < 100
          ) {
            setanimationCup1(animationData5);
          } else if (((Number(primary[0]?.complete_measure) / primary[0]?.measure) * 100).toFixed(2) >= 100) {
            setanimationCup1(animationData6);
          }
        } else if (i === 1) {
          if (((Number(primary[1]?.complete_measure) / primary[1]?.measure) * 100).toFixed(2) === 0) {
            setanimationCup2(two_animationData0);
          }
          if (
            ((Number(primary[1]?.complete_measure) / primary[1]?.measure) * 100).toFixed(2) > 0 &&
            ((Number(primary[1]?.complete_measure) / primary[1]?.measure) * 100).toFixed(2) <= 20
          ) {
            setanimationCup2(two_animationData);
          } else if (
            ((Number(primary[1]?.complete_measure) / primary[1]?.measure) * 100).toFixed(2) > 20 &&
            ((Number(primary[1]?.complete_measure) / primary[1]?.measure) * 100).toFixed(2) <= 40
          ) {
            setanimationCup2(two_animationData2);
          } else if (
            ((Number(primary[1]?.complete_measure) / primary[1]?.measure) * 100).toFixed(2) > 40 &&
            ((Number(primary[1]?.complete_measure) / primary[1]?.measure) * 100).toFixed(2) <= 60
          ) {
            setanimationCup2(two_animationData3);
          } else if (
            ((Number(primary[1]?.complete_measure) / primary[1]?.measure) * 100).toFixed(2) > 60 &&
            ((Number(primary[1]?.complete_measure) / primary[1]?.measure) * 100).toFixed(2) <= 80
          ) {
            setanimationCup2(two_animationData4);
          } else if (
            ((Number(primary[1]?.complete_measure) / primary[1]?.measure) * 100).toFixed(2) > 80 &&
            ((Number(primary[1]?.complete_measure) / primary[1]?.measure) * 100).toFixed(2) < 100
          ) {
            setanimationCup2(two_animationData5);
          } else if (((Number(primary[1]?.complete_measure) / primary[1]?.measure) * 100).toFixed(2) >= 100) {
            setanimationCup2(two_animationData6);
          }
        } else if (i === 2) {
          if (((Number(primary[2]?.complete_measure) / primary[2]?.measure) * 100).toFixed(2) === 0) {
            setanimationCup3(three_animationData0);
          }
          if (
            ((Number(primary[2]?.complete_measure) / primary[2]?.measure) * 100).toFixed(2) > 0 &&
            ((Number(primary[2]?.complete_measure) / primary[2]?.measure) * 100).toFixed(2) <= 20
          ) {
            setanimationCup3(three_animationData);
          } else if (
            ((Number(primary[2]?.complete_measure) / primary[2]?.measure) * 100).toFixed(2) > 20 &&
            ((Number(primary[2]?.complete_measure) / primary[2]?.measure) * 100).toFixed(2) <= 40
          ) {
            setanimationCup3(three_animationData2);
          } else if (
            ((Number(primary[2]?.complete_measure) / primary[2]?.measure) * 100).toFixed(2) > 40 &&
            ((Number(primary[2]?.complete_measure) / primary[2]?.measure) * 100).toFixed(2) <= 60
          ) {
            setanimationCup3(three_animationData3);
          } else if (
            ((Number(primary[2]?.complete_measure) / primary[2]?.measure) * 100).toFixed(2) > 60 &&
            ((Number(primary[2]?.complete_measure) / primary[2]?.measure) * 100).toFixed(2) <= 80
          ) {
            setanimationCup3(three_animationData4);
          } else if (
            ((Number(primary[2]?.complete_measure) / primary[2]?.measure) * 100).toFixed(2) > 80 &&
            ((Number(primary[2]?.complete_measure) / primary[2]?.measure) * 100).toFixed(2) < 100
          ) {
            setanimationCup3(three_animationData5);
          } else if (((Number(primary[2]?.complete_measure) / primary[2]?.measure) * 100).toFixed(2) >= 100) {
            setanimationCup3(three_animationData6);
          }
        } else if (i === 3) {
          if (((Number(primary[3]?.complete_measure) / primary[3]?.measure) * 100).toFixed(2) === 0) {
            setanimationCup4(four_animationData0);
          } else if (
            ((Number(primary[3]?.complete_measure) / primary[3]?.measure) * 100).toFixed(2) > 0 &&
            ((Number(primary[3]?.complete_measure) / primary[3]?.measure) * 100).toFixed(2) <= 20
          ) {
            setanimationCup4(four_animationData);
          } else if (
            ((Number(primary[3]?.complete_measure) / primary[3]?.measure) * 100).toFixed(2) > 20 &&
            ((Number(primary[3]?.complete_measure) / primary[3]?.measure) * 100).toFixed(2) <= 40
          ) {
            setanimationCup4(four_animationData2);
          } else if (
            ((Number(primary[3]?.complete_measure) / primary[3]?.measure) * 100).toFixed(2) > 40 &&
            ((Number(primary[3]?.complete_measure) / primary[3]?.measure) * 100).toFixed(2) <= 60
          ) {
            setanimationCup4(four_animationData3);
          } else if (
            ((Number(primary[3]?.complete_measure) / primary[3]?.measure) * 100).toFixed(2) > 60 &&
            ((Number(primary[3]?.complete_measure) / primary[3]?.measure) * 100).toFixed(2) <= 80
          ) {
            setanimationCup4(four_animationData4);
          } else if (
            ((Number(primary[3]?.complete_measure) / primary[3]?.measure) * 100).toFixed(2) > 80 &&
            ((Number(primary[3]?.complete_measure) / primary[3]?.measure) * 100).toFixed(2) < 100
          ) {
            setanimationCup4(four_animationData5);
          } else if (((Number(primary[3]?.complete_measure) / primary[3]?.measure) * 100).toFixed(2) >= 100) {
            setanimationCup4(four_animationData6);
          }
        } else if (i === 4) {
          if (((Number(primary[4]?.complete_measure) / primary[4]?.measure) * 100).toFixed(2) === 0) {
            setanimationCup5(five_animationData0);
          } else if (
            ((Number(primary[4]?.complete_measure) / primary[4]?.measure) * 100).toFixed(2) > 0 &&
            ((Number(primary[4]?.complete_measure) / primary[4]?.measure) * 100).toFixed(2) <= 20
          ) {
            setanimationCup5(five_animationData);
          } else if (
            ((Number(primary[4]?.complete_measure) / primary[4]?.measure) * 100).toFixed(2) > 20 &&
            ((Number(primary[4]?.complete_measure) / primary[4]?.measure) * 100).toFixed(2) <= 40
          ) {
            setanimationCup5(five_animationData2);
          } else if (
            ((Number(primary[4]?.complete_measure) / primary[4]?.measure) * 100).toFixed(2) > 40 &&
            ((Number(primary[4]?.complete_measure) / primary[4]?.measure) * 100).toFixed(2) <= 60
          ) {
            setanimationCup5(five_animationData3);
          } else if (
            ((Number(primary[4]?.complete_measure) / primary[4]?.measure) * 100).toFixed(2) > 60 &&
            ((Number(primary[4]?.complete_measure) / primary[4]?.measure) * 100).toFixed(2) <= 80
          ) {
            setanimationCup5(five_animationData4);
          } else if (
            ((Number(primary[4]?.complete_measure) / primary[4]?.measure) * 100).toFixed(2) > 80 &&
            ((Number(primary[4]?.complete_measure) / primary[4]?.measure) * 100).toFixed(2) < 100
          ) {
            setanimationCup5(five_animationData5);
          } else if (((Number(primary[4]?.complete_measure) / primary[4]?.measure) * 100).toFixed(2) >= 100) {
            setanimationCup5(five_animationData6);
          }
        }
      }
    }
  }, [props.quantitativeDetail, props.primary]);

  const celebrationCup = {
    loop: false,
    autoplay: true,
    animationData: celebrationSparkels.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const cup1 = {
    loop: false,
    autoplay: true,
    animationData: animationCup1.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const cup2 = {
    loop: false,
    autoplay: true,
    animationData: animationCup2.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const cup3 = {
    loop: false,
    autoplay: true,
    animationData: animationCup3.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const cup4 = {
    loop: false,
    autoplay: true,
    animationData: animationCup4.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const cup5 = {
    loop: false,
    autoplay: true,
    animationData: animationCup5.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const eventListeners1 = [
    {
      eventName: "complete",
      callback: () => {
        primary.forEach((stats, index) => {
          if (index === 0 && stats.quantitative_stats_percent >= 100) {
            setCelebration1(true);
          }
        });
      },
    },
  ];
  const eventListeners2 = [
    {
      eventName: "complete",
      callback: () => {
        primary.forEach((stats, index) => {
          if (index === 1 && stats.quantitative_stats_percent >= 100) {
            setCelebration2(true);
          }
        });
      },
    },
  ];
  const eventListeners3 = [
    {
      eventName: "complete",
      callback: () => {
        primary.forEach((stats, index) => {
          if (index === 2 && stats.quantitative_stats_percent >= 100) {
            setCelebration3(true);
          }
        });
      },
    },
  ];
  const eventListeners4 = [
    {
      eventName: "complete",
      callback: () => {
        primary.forEach((stats, index) => {
          if (index === 3 && stats.quantitative_stats_percent >= 100) {
            setCelebration4(true);
          }
        });
      },
    },
  ];
  const eventListeners5 = [
    {
      eventName: "complete",
      callback: () => {
        primary.forEach((stats, index) => {
          if (index === 4 && stats.quantitative_stats_percent >= 100) {
            setCelebration5(true);
          }
        });
      },
    },
  ];

  const cupsMap = [
    {
      cup: cup1,
      eventListeners: eventListeners1,
      celebration: celebration1,
    },
    {
      cup: cup2,
      eventListeners: eventListeners2,
      celebration: celebration2,
    },
    {
      cup: cup3,
      eventListeners: eventListeners3,
      celebration: celebration3,
    },
    {
      cup: cup4,
      eventListeners: eventListeners4,
      celebration: celebration4,
    },
    {
      cup: cup5,
      eventListeners: eventListeners5,
      celebration: celebration5,
    },
    {
      cup: cup1,
      eventListeners: eventListeners1,
      celebration: celebration1,
    },
  ];

  return (
    <section className="Goals-Stats-wrapper">
      <div className="container">
        <div className="Goals-Stats">
          <div
            className="heding d-flex justify-content-md-between justify-content-center"
          >
            <div>
              <h2>
                {props.from === "teamdetail"
                  ? props.name + `'s Progress`
                  : "My Progress"}
              </h2>
              <hr className="border-pink  width-100" />
            </div>
          </div>
          {primary && primary?.length > 0 ? (
            <div className="Qualitative-chart-inline flex-wrap">
              {primary?.slice(0,5)?.map((item, index) => {
                return (
                  <QualitativeItem
                    key={index}
                    celebrationCup={celebrationCup}
                    primary={item}
                    id={props.id}
                    look={look}
                    {...cupsMap[index]}
                  />
                );
              })}
            </div>
          ) : (
            <div className="heding">
              {props.from === "teammember" ? (
                <h4>No goals have been assigned by your manager</h4>
              ) : (
                <h4>No goals have been assigned yet</h4>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
