import React, { Component } from 'react';
import { Link } from "react-router-dom";

export default class PageError extends Component {
   
    render() {
       
        return (
            <React.Fragment>
                <div className="d-flex align-items-center bg-auth err_height">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">
                               
                            </div>
                            <div className="col-12 col-md-5 col-xl-4 order-md-1 my-5">
                                <div className="text-center">
                                    <h6 className="text-uppercase text-muted mb-4">404 error</h6>
                                    <h1 className="display-4 mb-3">The requested URL {window?.location?.pathname} was not found on this server. That’s all we know. 😭</h1>
                                    <p className="text-muted mb-4">Looks like you ended up here by accident?</p>
                                    <Link to="/dashboard" className="btn btn-lg btn-primary btn-focus-out">Return to your dashboard</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}