import React from "react";
import Svgs from "../../assets/Svgs";
import Loader from "../../Components/Common/Loader";

const ViewMode = ({ setOpen, Loading, Open, RowData }) => {
  return (
    <div className="w-100">
      {Loading ? (
        <div>
          <Loader />
        </div>
      ) : (
        RowData.map((item, i) => {
          return (
            <>
              <div
                className={`goal-item-content goalButton faq mt-4 p-3 cursor-pointer transition-all h-auto`}
                onClick={() => {
                  setOpen(i == Open ? -1 : i);
                }}
              >
                <div className="d-flex align-items-center justify-content-between w-100">
                  <p className="goal-item-title">
                    <strong className="word-break">{item?.question}</strong>
                  </p>
                  <div
                    className={`${Open == i && "rotate-180"} transition-all`}
                  >
                    <Svgs.ChevronDown />
                  </div>
                </div>
                {Open == i && (
                  <div className="mt-3">
                    <p className="word-break">{item?.answer}</p>
                  </div>
                )}
              </div>
            </>
          );
        })
      )}
    </div>
  );
};

export default ViewMode;
