import React from "react";
import { connect } from "react-redux";
import ReactStars from "react-rating-stars-component";
import { useHistory } from "react-router-dom";

const QualitativeDetailManager = (props) => {
  const history = useHistory();

  const ratingdetail = [props?.location?.state?.item];
  const updatedAt = [props?.location?.state?.updatedAt];

  return (
    <div class="Qualitative-measurement-wrapper margin-bottom-8rem">
      <div class="container">
        <div class="Qualitative-measurement comman-width-container">
          <div class="heding">
            <h1 className="qualitative-details-manager-h1">Qualitative</h1>
            <br />
            <h2> Measures</h2>
            <hr class="border-pink Qualitative" />
            <button class="start-date  start-date-qualitative-details">
              Last Rated on
              <br />
              <span className="start-date-span">{updatedAt}</span>
            </button>
          </div>

          <table class="table Qualitative-table">
            <thead>
              <tr>
                <th className="employee-goal-button"> Target</th>
                <th className="employee-goal-button">Rating</th>
                <th className="employee-goal-button">Feedback</th>
                <th className="employee-goal-button">Edit</th>
              </tr>
            </thead>
            <tbody>
              {ratingdetail && ratingdetail.length > 0 ? (
                ratingdetail?.map((item) => (
                  <tr>
                    <td className="td-qualitative-inline">
                      {item?.attribute?.name}
                    </td>

                    <td className="td-qualitative-inline2">
                      <ReactStars
                        {...{
                          color: "#C9C9C9",
                          size: 30,
                          value: item.rating,
                          edit: false,
                          activeColor: "#FF7500",
                        }}
                      />
                    </td>
                    <td className="td-qualitative-inline">
                      {item.comment ? item.comment : "N/A"}
                    </td>
                    <td>
                      <i
                        class="fa fa-pencil   fa-fa-pencil"
                        aria-hidden="true"
                        onClick={() =>
                          history.push({
                            pathname: "/rating",
                            state: { id: props.location.state?.id },
                          })
                        }
                      ></i>
                    </td>
                  </tr>
                ))
              ) : (
                <div class="heding">
                  <h4>No Goals Found</h4>
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const loading = state.globalReducer.loading;
  const chatPopupData = state.chatReducer.chatPopupData;
  const profileData = state.profileReducer.profileData;

  return {
    loading,
    chatRoomdata: state.chatReducer.chatRoomdata,
    chatPopupData,
  };
};

const mapDispatch = (dispatchEvent) => ({
  getSearchData: (payload) =>
    dispatchEvent({ type: "SEARCH_TEAM", value: payload }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
  getchatpopupData: () => dispatchEvent({ type: "GET_CHATPOPUP" }),
  getProfileData: () => dispatchEvent({ type: "GET_PROFILEDATA" }),
});

export default connect(mapStateToProps, mapDispatch)(QualitativeDetailManager);
