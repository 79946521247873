import React, { useState } from 'react';
import axios from 'axios';
import { useStripe } from "@stripe/react-stripe-js";
import { Container, Grid } from '@mui/material';
import SubscriptionCard from '../../Components/SubscriptionCard/SubscriptionCard';
import { toast } from "react-toastify";
import config from "../../config";
const { BASE_API_URL, LARGE_PLAN_ID, MEDIUM_PLAN_ID, SMALL_PLAN_ID } = config;

export const StripeCheckoutNew = ({ planId }) => {
    const token = localStorage.getItem("s_token");
    const stripe = useStripe();


    const plans = [
        {
            title: 'Small',
            price: '900',
            features: [
                '1-10 Users',
                'Vibe check',
                "Quantitive and Qualitative goal tracking",
                "Live Feedback",
                "Peer to Peer recognition board",
                "2 free career coaching sessions"],
            priceId: SMALL_PLAN_ID
        },
        {
            title: 'Medium',
            price: '2250',
            features: [
                '11-25 Users',
                'Vibe check',
                "Quantitive and Qualitative goal tracking",
                "Live Feedback",
                "Peer to Peer recognition board",
                "2 free career coaching sessions"],
            priceId: MEDIUM_PLAN_ID
        },
        {
            title: 'Large',
            price: '4000',
            features: [
                '26-50 Users',
                'Vibe check',
                "Quantitive and Qualitative goal tracking",
                "Live Feedback",
                "Peer to Peer recognition board",
                "2 free career coaching sessions"],
            priceId: LARGE_PLAN_ID
        },
    ];

    const handleSubscribeClick = async (priceId) => {
        let responseProfile;
        try {
            responseProfile = await axios.get(`${BASE_API_URL}employer/getProfile`, {
                headers: { 'Content-Type': 'application/json', Authorization: token },
            });

        } catch (error) {
            toast.error("Unable to fetch user profile", {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        }

        let email = responseProfile && responseProfile.data.body.data.email;
        let userId = responseProfile && responseProfile.data.body.data.id;

        if (email) {
            try {
                const response = await axios.post(`${BASE_API_URL}employer/paymentStripeNew`,
                    { email, priceId, userId },
                    {
                        headers: { Authorization: token },
                    });
                const sessionId = response.data.sessionId;
                if (sessionId) {
                    const { error } = await stripe.redirectToCheckout({
                        sessionId,
                    });

                    if (error) {
                        console.error(error.message);
                    }
                }

            }
            catch (error) {
                toast.error("Error in stripe checkout service", {
                    position: toast.POSITION.BOTTOM_CENTER,
                });
            }
        }

    }
    return (
        <Container sx={{ py: 5 }}>
            <Grid container spacing={3} justifyContent="center">
                {plans.map((plan, index) => (
                    <Grid item key={index}>
                        <SubscriptionCard {...plan} handleSubscribeClick={handleSubscribeClick} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
