import React from "react";
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from "opentok-react";
import { RESETPOST, GET, deleteChatSession } from "../utils/helper";
import cancle from "../assets/images/cancle.png";
import acceptcall from "../assets/images/acceptcall/acceptcall.png";
import reject from "../assets/images/reject/reject.png";
import Coaches from "../assets/images/userIcon/userIcon3.png";
import mutedaudio from "../assets/images/muted/mutedaudio.png";
import mutedvideo from "../assets/images/muted/mutedvideo.png";
import unmutedmic from "../assets/images/unmuted/unmutedmic.png";
import unmutedvideo from "../assets/images/unmuted/unmutedvideo.png";
import ChatsMsg from "./ChatsMsg";
import firebase from "firebase";
import Timer from "./Timer";
import demo from "../assets/sound/demo.mp3";
import ringing from "../assets/sound/ringing.mp3";
import ReactFullScreenElement from "react-fullscreen-element";
import { withRouter } from "react-router-dom";
import "../scss/customforall.scss";
var Sound = require("react-sound").default;

class videoPublisher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriberIds: [],
      streamProperties: {},
      mainSubscriberStreamId: null,
      videoCallComing: false,
      connectingImage: "",
      callConnectStatus: 0,
      callDuration: 1,
      endCallStatus: false,
      userCallMessage: "User not responding",
      timeout: null,
      userNotRespondingStatus: false,
      chatRoomId: "",
      fromNoti: false,
      joinedByBoth: false,
      connectionId: "",
      showMuted: false,
      showVideo: this.props?.isVideo,
      sessionId: "",
      token: "",
      error: null,
      connection: "Connecting",
      publishVideo: true,
      localPublishAudio: true,
      type: "DEFAULT",
      joinCall: this.props?.joinCall,
      signal: {
        type: "",
        data: "",
      },
      text: "",
      apiKey: process.env.REACT_APP_OPENTOK_API_KEY,
      isLoading: false,
      subscribeToAudio: true,
      subscribeToVideo: true,
      startCallTime: {},
      timerStop: false,
      ringtoneStatus: true,
    };
    this.sessionOptions = {
      connectionEventsSuppressed: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSignalSend = this.onSignalSend.bind(this);

    this.otSession = React.createRef();
    this.otPublisher = React.createRef();
    this.sessionEventHandlers = {
      streamCreated: (event) => {
        const streamProperties = {
          ...this.state.streamProperties,
          [event.streamId]: {
            subscribeToAudio: true,
            subscribeToVideo: true,
          },
        };
        this.setState(
          {
            streamProperties,
            subscriberIds: [...this.state.subscriberIds, event.stream.streamId],
          },
          () => {
            this.setState({ joinedByBoth: true, startCallTime: new Date() });

            clearTimeout(this.state.timeout);

            localStorage.setItem("subs", this.state.subscriberIds);
          }
        );
      },
      streamDestroyed: (event) => {
        const indexToRemove = this.state.subscriberIds.indexOf(
          event.stream.streamId
        );
        const newSubscriberIds = this.state.subscriberIds;
        const streamProperties = { ...this.state.streamProperties };
        if (indexToRemove !== -1) {
          delete streamProperties[event.stream.streamId];
          newSubscriberIds.splice(indexToRemove, 1);
          this.setState({ subscriberIds: newSubscriberIds });
        }
      },
      error: (error) => {
        console.log("session error:", error);
      },
      otrnError: (error) => {
        console.log("Session otrnError error:", error);
      },
      sessionConnected: (event) => {
        this.setState({ connection: "Connected" });
      },
      sessionDisconnected: () => {
        this.setState({
          connection: "Disconnected",
          streamProperties: {},
          subscriberIds: [],
        });
      },
      sessionReconnected: () => {
        this.setState({ connection: "Reconnected" });
      },
      sessionReconnecting: () => {
        this.setState({ connection: "Reconnecting" });
      },
      streamPropertyChanged: (event) => {
        if (
          event.changedProperty === "hasAudio" &&
          event.stream.connection.connectionId !== this.state.connectionId
        ) {
          this.setState({ showMuted: !event.newValue });
        }
        if (
          event.changedProperty === "hasVideo" &&
          event.stream.connection.connectionId !== this.state.connectionId
        ) {
          this.setState({ showVideo: event.newValue }, () =>
            console.log(this.state.showVideo)
          );
        }
      },
    };
    this.publisherEventHandlers = {
      accessDenied: () => {
        console.log("User denied access to media source");
      },
      streamCreated: (event) => {
        this.setState({ connectionId: event.stream.connection.connectionId });
      },
      streamDestroyed: ({ reason }) => {},
    };

    this.subscriberEventHandlers = {
      videoEnabled: () => {
        console.log("hi");
      },
      videoDisabled: () => {
        console.log("byee");
      },
    };
  }
  onSessionError = (error) => {
    this.setState({ error });
  };

  onPublish = () => {
    // console.log('Publish Success');
  };

  onPublishError = (error) => {
    // console.log('Publish error', error);
  };

  onSubscribe = () => {
    // console.log('Subscribe Success');
  };

  onSubscribeError = (error) => {
    // console.log('onSubscribeError error', error);
  };
  toggleAudio = () => {
    let publishAudio = this.state.localPublishAudio;
    this.publisherProperties = {
      ...this.publisherProperties,
      publishAudio: !publishAudio,
    };
    this.setState({
      localPublishAudio: !publishAudio,
    });
  };
  toggleVideo = () => {
    this.setState((state) => ({
      publishVideo: !state.publishVideo,
    }));
  };

  handleChange(event) {
    this.setState({ text: event.target.value });
  }

  onSignalSend = () => {
    var value = this.state.text;
    this.otSession.current.sessionHelper.session.signal(
      {
        type: "msg",
        data: value,
      },
      function signalCallback(error) {
        if (error) {
          console.error("Error sending signal:", error.name, error.message);
        } else {
          console.log(value);
        }
      }
    );
  };

  onSignalRecieve = (data) => {
    //recieve signal
  };

  userTimeout = () => {
    this.setState({
      timeout: setTimeout(() => {
        this.setState(
          {
            userNotRespondingStatus: true,
            endCallStatus: true,
          },
          () => this.endVideoCallFun("timeout")
        );
      }, 60000),
    });
  };

  sendChatInfo = async (message, startCallTime) => {
    const { chatRoomId } = this.state;
    await new ChatsMsg().createChatNode(chatRoomId, [
      { id: localStorage.getItem("id"), name: localStorage.getItem("name") },
      this.props && this.props?.otherUserName
        ? this.props?.otherUserName
        : JSON.parse(this.props?.message?.senderEmployeeData).name,
    ]);
    firebase
      .firestore()
      .collection("chats_dev")
      .doc(chatRoomId)
      .collection("messages")
      .add({
        _id: Math.random().toString(),
        text: message,
        createdAt: startCallTime
          ? startCallTime.getTime()
          : new Date().getTime(),
        system: true,
        timestamp: startCallTime
          ? startCallTime.getTime().toString()
          : new Date().getTime().toString(),
      })
      .then((success) => {
        console.log("success", success);
      })
      .catch((err) => console.log("err", err));
  };

  componentDidMount() {
    this.userTimeout();

    if (this.props?.joinCall) {
      this.setState(
        {
          publishVideo: this.props?.isVideo,
          chatRoomId: this.props?.item?.room_id,
          joinCall: this.props?.joinCall,
        },
        () => {
          // console.log("publishVideo", this.state.publishVideo)
        }
      );
      this.createSession();
    } else {
      GET(`/checkChatSession/${this.props.message?.chat_room_id}`).then(
        (res) => {
          if (!res.body.data.isSessionExist) {
            this.props.removeComp();
          }
        }
      );

      const message = this.props?.message;
      this.setState({ joinCall: false, chatRoomId: message?.chat_room_id });
      if (message) {
        this.joinSession(message?.chat_room_id);
      }
    }
  }

  componentWillUnmount() {
    const { timeout } = this.state;

    clearTimeout(timeout);
    this.props.removeComp();
  }

  createSession = () => {
    const item = this.props?.item;
    RESETPOST("createChatSession", { chat_room_id: item?.room_id })
      .then((res) => {
        if (res.status === 200) {
          GET(`getChatSessionIdandToken/${item?.room_id}`)
            .then((res) => {
              if (res.status === 200) {
                this.setState({
                  sessionId: res.body.data.sessionId,
                  token: res.body.data.token,
                });
                RESETPOST(`sendChatNotification`, {
                  chat_type: this.props?.isVideo ? "video" : "audio",
                  chat_room_id: item?.room_id,
                  session_id: res.body.data.sessionId,
                  token: res.body.data.token,
                })
                  .then((res) => {
                    if (res.status === 200) {
                    }
                  })
                  .catch((error) => {
                    console.log(
                      "sendChatNotification api error------->",
                      error
                    );
                  });
              }
            })
            .catch((error) => {
              console.log("getChatSessionIdandToken api error------->", error);
            });
        }
      })
      .catch((error) => {
        console.log("createChatSession api error------->", error);
      });
  };

  joinSession = (roomId) => {
    GET(`getChatSessionIdandToken/${roomId}`)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            sessionId: res.body.data.sessionId,
            token: res.body.data.token,
          });
        }
      })
      .catch((error) => {
        console.log("getChatSessionIdandToken api error------->", error);
      });
  };
  endVideoCallSubscriber = () => {
    this.setState({ ringtoneStatus: false });

    this.setState(
      {
        endCallStatus: true,
      },
      () => this.endVideoCallFun("Call Ended")
    );
  };
  endVideoCallFun = (type) => {
    if (type === "timeout" && !this.props.message) {
      this.sendChatInfo("Missed call");
    }

    this.endVideoCallApi(type);
  };
  endVideoCallApi = (type) => {
    this.setState({ isLoading: true });
    let dataPass = {
      chat_type: this.props.isVideo ? "video" : "audio",
      chat_room_id: this.state.chatRoomId,
      session_id: this.state.sessionId,
      token: this.state.token,
      disconnect_type: type === "timeout" ? 2 : 1,
    };
    var data = {
      chat_room_id: String(this.state.chatRoomId),
    };

    deleteChatSession(data)
      .then((res) => {
        data.chat_type = "audio";
        if (res.status === 200) {
          RESETPOST(`sendChatDisconnectNotification`, dataPass)
            .then((res) => {
              if (res.status === 200) {
                this.setState({ isLoading: false });
                if (type === "timeout") {
                  this.props.removeComp(type, this.props.otherUserName);
                  return;
                }
                if (type) {
                  this.props.removeComp(type);
                  return;
                }

                this.props.removeComp();
              }
            })
            .catch((error) => {
              console.log(
                "sendChatDisconnectNotification api error------->",
                error
              );
            });
        }
      })
      .catch((error) => {
        console.log("dropChatSession api error------->", error);
      });
  };

  endCallFromPublisher = () => {
    if (this.state.subscriberIds.length > 0) {
      this.setState({ timerStop: true });
    }

    this.setState(
      {
        endCallStatus: true,
      },
      () => this.endVideoCallFun("Call Ended")
    );
  };

  videoView = () => {
    const { publishVideo, apiKey, sessionId, subscriberIds } = this.state;

    return (
      <ReactFullScreenElement fullScreen={true}>
        <div style={{ position: "relative", width: "100%" }}>
          {this.state.isLoading ? (
            <div></div>
          ) : (
            sessionId && (
              <OTSession
                ref={this.otSession}
                apiKey={apiKey}
                sessionId={sessionId}
                token={this.state.token}
                onError={this.onSessionError}
                eventHandlers={this.sessionEventHandlers}
                onSignalRecieve={this.onSignalRecieve}
              >
                <OTPublisher
                  properties={{
                    publishVideo,
                    publishAudio: this.state.localPublishAudio,
                    width: 100,
                    height: 100,
                  }}
                  ref={this.otPublisher}
                  onPublish={this.onPublish}
                  onError={this.onPublishError}
                  eventHandlers={this.publisherEventHandlers}
                  style={{
                    backgroundColor: "#28282D",
                    width: 100,
                    height: 100,
                    position: "absolute",
                    top: 25,
                    right: 10,
                    zIndex: 25,
                    display:
                      this.props?.isVideo || this.props?.type == "6"
                        ? ""
                        : "none",
                  }}
                />

                <OTStreams>
                  <OTSubscriber
                    properties={{
                      subscribeToAudio: this.state.subscribeToAudio,
                      subscribeToVideo: this.state.subscribeToVideo,
                      width: window.innerWidth,
                      height: "86vh",
                    }}
                    onSubscribe={this.onSubscribe}
                    onError={this.onSubscribeError}
                    eventHandlers={this.subscriberEventHandlers}
                    style={{
                      position: "absolute",
                      zIndex: this.state.showVideo ? 20 : -100,
                      display:
                        this.props?.isVideo || this.props?.type == "6"
                          ? ""
                          : "none",
                    }}
                  />
                </OTStreams>
              </OTSession>
            )
          )}

          <section
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-end",
              height: "100vh",
              width: "100%",
              backgroundColor: "#28282D",
            }}
          >
            <div
              style={{
                color: "white",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "15%",
              }}
            >
              <img
                alt=""
                style={{
                  borderRadius: "50%",
                  width: "8rem",
                  height: "8rem",
                  zIndex: 15,
                }}
                src={
                  this.props.image ||
                  (this.props.message &&
                    JSON.parse(this.props?.message.senderEmployeeData)
                      .profile_pic_url) ||
                  Coaches
                }
              />
              <h3>
                {" "}
                {subscriberIds.length > 0 ? "Connected to " : "Connecting to "}
                {this.props && this.props.otherUserName
                  ? this.props.otherUserName
                  : JSON.parse(this.props?.message.senderEmployeeData).name}
                ...
              </h3>
              {subscriberIds.length > 0 && (
                <Timer
                  isVideo={this.props.isVideo}
                  startCallTime={this.state.startCallTime}
                  sendChatInfo={this.sendChatInfo}
                  stop={this.state.timerStop}
                />
              )}
            </div>
            {this.state.showMuted && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 25,
                  marginBottom: "4rem",
                }}
              >
                <div
                  style={{
                    color: "#E74C3C",
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                  }}
                >
                  {this.props && this.props.otherUserName
                    ? this.props.otherUserName
                    : JSON.parse(this.props?.message.senderEmployeeData)
                        .name}{" "}
                  Muted{" "}
                </div>
              </div>
            )}
            <div style={{ marginBottom: "2rem" }}>
              {this.state.subscriberIds.length > 0 && (
                <img
                  style={{ marginRight: "1rem", width: "3rem", height: "3rem" }}
                  src={this.state.localPublishAudio ? unmutedmic : mutedaudio}
                  onClick={this.toggleAudio}
                  alt="speaker"
                  className="cursor-pointer"
                />
              )}

              {this.state.subscriberIds.length > 0 && (
                <img
                  style={{
                    width: "3rem",
                    height: "3rem",
                    marginRight: "1rem",
                    display:
                      this.props?.isVideo || this.props?.type == "6"
                        ? ""
                        : "none",
                  }}
                  src={this.state.publishVideo ? unmutedvideo : mutedvideo}
                  onClick={this.toggleVideo}
                  alt="video"
                  className="cursor-pointer"
                />
              )}
              <img
                className="cursor-pointer"
                src={cancle}
                onClick={this.endCallFromPublisher}
                alt="cancle"
              />
            </div>
          </section>
        </div>

        {this.state.subscriberIds.length == 0 && (
          <Sound
            url={ringing}
            loop={true}
            playStatus={
              this.state.ringtoneStatus
                ? Sound.status.PLAYING
                : Sound.status.STOPPED
            }
          />
        )}
      </ReactFullScreenElement>
    );
  };
  joinCall = () => {
    const { joinCall, apiKey, sessionId, token } = this.state;
    this.setState({ ringtoneStatus: false });

    if (!joinCall) {
      this.setState({ joinCall: true });
    }
  };

  joinVideoView = () => {
    return (
      <ReactFullScreenElement fullScreen={true}>
        <section
          style={{
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            minHeight: "100vh",
            backgroundColor: "#28282D",
          }}
        >
          <img
            alt=""
            style={{
              borderRadius: "50%",
              width: "8rem",
              height: "8rem",
              zIndex: 1000,
            }}
            src={
              this.props.message &&
              JSON.parse(this.props?.message.senderEmployeeData).profile_pic_url
                ? JSON.parse(this.props?.message.senderEmployeeData)
                    .profile_pic_url
                : Coaches
            }
          />
          <p style={{ marginBottom: "10rem", color: "white" }}>
            Incoming call from{" "}
            {JSON.parse(this.props?.message?.senderEmployeeData)?.name}
          </p>
          <div
            style={{
              marginBottom: "5rem",
              width: "12rem",
              height: "2rem",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
            class="call-options"
          >
            <img
              alt=""
              className="cursor-pointer"
              style={{ marginRight: "2rem", width: "4rem", height: "4rem"}}
              onClick={this.joinCall}
              src={acceptcall}
            />
            <img
              alt=""
              style={{ width: "4rem", height: "4rem" }}
              className="cursor-pointer"
              src={reject}
              onClick={this.endVideoCallSubscriber}
            />
          </div>
        </section>
      </ReactFullScreenElement>
    );
  };
  render() {
    return (
      <div>
        {this.props.message && (
          <Sound
            url={demo}
            loop={true}
            playStatus={
              this.state.ringtoneStatus
                ? Sound.status.PLAYING
                : Sound.status.STOPPED
            }
          />
        )}
        {this.state.joinCall ? this.videoView() : this.joinVideoView()}
      </div>
    );
  }
}

export default withRouter(videoPublisher);
