import FullCalendar from "@fullcalendar/react";
import React, { useState, useEffect } from "react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useHistory } from "react-router";
import { Modal } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { GET, POST, putAPI } from "../../utils/helper";
import "./Calendar.css";
import {
  CDropdown,
  CDropdownToggle,
  CDropdownItem,
  CDropdownMenu,
  CContainer,
  CRow,
  CCol,
} from "@coreui/react";
import { toast } from "react-toastify";
import ChatsMsg from "../ChatsMsg";
import cross from "../../assets/images/cross.png";

function DefineAvailability() {
  const history = useHistory();
  // const [bookedmodelOpen, setbookedmodelOpen] = useState(false);
  const [bookedmodalOpen, setbookedmodalOpen] = useState(false);
  // const [date, setDate] = useState("2022-03-02");
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [slot, setSlot] = useState();
  const [unavailablemodalOpen, setUnavailableModalOpen] = useState(false);
  const [availablemodalOpen, setAvailableModalOpen] = useState(false);
  const [cancelBookingmodalOpen, setcancelBookingmodalOpen] = useState(false);
  const [uavailableData, setuavailableData] = useState({});
  const [availableData, setavailableData] = useState({});
  const [bookingData, setbookingData] = useState({});
  const [renderUnavailable, setrenderUnavailable] = useState(false);
  const [renderAvailable, setrenderAvailable] = useState(false);
  const [renderBooking, setrenderBooking] = useState(false);
  const [cancelReason, setcancelReason] = useState();
  const [chatroomdata, setchatroomData] = useState();
  const [updateButton, setupdateButton] = useState();
  const profile_pic_url = useSelector(
    (state) => state.loginReducer.coachloginData.image
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const all_slots = [];
    const getSlots = async () => {
      const res = await GET(`getSlots?date=${date}`);
      const slotData = res?.body?.data?.rows;
      setupdateButton(res.body.data.count);
      const color = ["#FFFFFF", "#AAD1F6", "#D9D9D9", "#D9D9D9"];
      slotData.map((e) => {
        let session_id;
        let session_status;
        let employee_id;
        try {
          session_status = e.employee_coach_session.status;
          session_id = e.employee_coach_session.id;
          employee_id = e.employee_coach_session.employee_id;
        } catch (error) {
          session_id = null;
          session_status = null;
          employee_id = null;
        }
        var End_Time;
        if (e.end_time === "00:00:00") {
          End_Time = "23:59:59";
        } else {
          End_Time = e.end_time;
        }

        all_slots.push({
          title: "event 1",
          start: e.date,
          startTime: e.start_time,
          endTime: End_Time,
          backgroundColor: color[e.status - 1],
          coach_id: e.coach_id,
          date: e.date,
          day: e.day,
          // end_time: e.end_time,
          id: e.id,
          is_available: e.is_available,
          slot_date_group_id: e.slot_date_group_id,
          slot_time_group_id: e.slot_time_group_id,
          status: e.status,
          type: 3,
          textColor: "black",
          session_id: session_id,
          session_status: session_status,
          employee_id: employee_id,
        });
      });
      setSlot(all_slots);
    };
    getSlots();
  }, [date, renderUnavailable, renderAvailable, renderBooking]);

  const mystyle4 = {
    marginLeft: "285px",
    width: "111px",
    textAlign: "center",
    // marginBottom: "500px"
    marginTop: "-50px",
    height: "32px",
  };

  const buttonStyle = {
    width: "150px",
  };

  const mystyle1 = {
    fontSize: "21px",
    marginRight: "63px",
  };
  const mystyle2 = {
    marginTop: "25px",
    marginLeft: "-158px",
    width: "114px",
  };
  const mystyle3 = {
    marginTop: "25px",
    width: "114px",
    marginRight: "-219px",
  };

  const clickCheck = (event) => {
    slot.forEach((each) => {
      if (each.id == event.target.id) {
        if (each.status == 1) {
          setuavailableData(each);
        } else if (each.status == 4) {
          setavailableData(each);
        } else if (each.status == 2) {
          setbookingData(each);
          setchatroomData(each);
        }
      }
    });
  };

  const UnavailableModalOpen = (e) => {
    setUnavailableModalOpen(true);
  };
  const BookedmodalOpen = (e) => {
    slot.forEach((each) => {
      if (each.id == e.target.id) {
        if (each.status == 2) {
          setbookingData(each);
          setchatroomData(each);
        }
        // console.log(each)
        // setbookingData(each);
        // setchatroomData(each);
      }
    });
    setbookedmodalOpen(true);
  };

  const AvailableModalState = (e) => {
    setAvailableModalOpen(true);
  };

  const BookingFunc = async (e) => {
    setbookedmodalOpen(false);
    if (e.target.id == 1) {
      setcancelBookingmodalOpen(true);
    } else {
      let other_user_id = chatroomdata.employee_id;
      const chat_room_details = await GET(
        `getChatRoomId?other_user_id=${other_user_id}&type=${0}`
      );
      const name = localStorage.getItem("name");
      const userId = localStorage.getItem("id");
      if (chat_room_details.status === 400) {
        toast.error("Cannot initiate chat");
        return true;
      }
      let data = chat_room_details.body.data;

      let user = chat_room_details.body.data.other_user;

      let newdata = {
        id: data.id,
        user: {
          name: name,
          id: userId,
          profile_pic_url: profile_pic_url,
        },
        room_id: data.room_id,
        status: 1,
        other_user: user,
        chatLastDeletedOn: data.chatLastDeletedOn,
      };

      dispatch({ type: "SET_CHAT", payload: newdata });
      await new ChatsMsg().createChatNode(newdata.room_id, [
        { id: userId, name: name },
        user,
      ]);

      setTimeout(() => {
        history.push({
          pathname: "/coachchatdetail",
          state: {
            title: "",
            id: data?.id,
            room_id: data.room_id,
            is_disabled: false,
          },
        });
      }, 1000);
    }
  };

  const markUnavailable = async (type) => {
    const postObj = {
      date: uavailableData.start,
      is_available: 4,
      event_type: type,
      timings: [
        {
          start_time: uavailableData.startTime,
          end_time: uavailableData.endTime,
        },
      ],
    };
    const res = await POST(`updateSlotAvailability`, postObj);
    if (res.status == 200) {
      setUnavailableModalOpen(false);
      if (renderUnavailable == true) {
        setrenderUnavailable(false);
      } else {
        setrenderUnavailable(true);
      }
      toast.success("Unavailable marked successfully");
    }
  };

  const markAvailable = async (type) => {
    const postObj = {
      date: availableData.start,
      is_available: 1,
      event_type: type,
      timings: [
        {
          start_time: availableData.startTime,
          end_time: availableData.endTime,
        },
      ],
    };
    const res = await POST(`updateSlotAvailability`, postObj);
    if (res.status == 200) {
      setAvailableModalOpen(false);
      if (renderAvailable == true) {
        setrenderAvailable(false);
      } else {
        setrenderAvailable(true);
      }
      toast.success("Available marked successfully");
    }
  };

  const cancelBooking = async (e) => {
    if (!cancelReason) {
      toast.success("Please enter the cancel reason");
      setcancelReason();
      return;
    }

    if (bookingData.session_status == 2) {
      const postObj = {
        session_id: bookingData.session_id,
        cancel_reason: cancelReason,
      };

      const res = await putAPI(`cancelSession`, postObj);

      // for marking the cancelled session as avaialable slot
      const postObj2 = {
        date: bookingData.start,
        is_available: 1,
        event_type: 0,
        timings: [
          {
            start_time: bookingData.startTime,
            end_time: bookingData.endTime,
          },
        ],
      };
      const res2 = await POST(`updateSlotAvailability`, postObj2);
      if (res2.status == 200) {
        if (res.status == 200) {
          setcancelBookingmodalOpen(false);
          if (renderBooking == true) {
            setrenderBooking(false);
          } else {
            setrenderBooking(true);
          }
          toast.success("Session cancelled successfully");
        }
      }
      setcancelReason();
    } else {
      toast.success("Please accept the session first");
      setcancelBookingmodalOpen(false);
      setcancelReason();
      return;
    }
  };

  const handleInputChange = (e) => {
    setcancelReason(e.target.value);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <CContainer>
          <CRow>
            <CCol md={12} lg={6}>
              <b>
                {eventInfo.timeText.split("- ")[1] === "11:59"
                  ? eventInfo.timeText.split("- ")[0] + "- 12:00"
                  : eventInfo.timeText}
              </b>
            </CCol>
            <CCol md={12} lg={6}>
              <p>
                {eventInfo.event.extendedProps.status == 1 ? (
                  <CDropdown variant="btn-group" direction="dropstart">
                    <CDropdownToggle
                      className="cdrop  cdrop-sub"
                      
                      id={eventInfo.event.id}
                      onClick={(e) => clickCheck(e)}
                      color="secondary"
                    >
                      Available
                    </CDropdownToggle>
                    <CDropdownMenu
                      style={{
                        height: "30px",
                      }}
                      onClick={(e) => UnavailableModalOpen(e)}
                    >
                      <CDropdownItem
                        className="cdropitem  cddropitem-sub"
                        
                      >
                        Mark Unavailable
                      </CDropdownItem>
                    </CDropdownMenu>
                  </CDropdown>
                ) : eventInfo.event.extendedProps.status == 2 ? (
                  <CDropdown variant="btn-group" direction="dropend">
                    <CDropdownToggle
                      className="cdrop2"
                      style={{
                        backgroundColor: "#AAD1F6",
                        color: "black",
                        borderColor: "orange",
                      }}
                      id={eventInfo.event.id}
                      onClick={(e) => BookedmodalOpen(e)}
                      color="secondary"
                    >
                      Booked
                    </CDropdownToggle>
                  </CDropdown>
                ) : (
                  <CDropdown variant="btn-group" direction="dropend">
                    <CDropdownToggle
                      className="cdrop1  cddrop1-sub"
                     
                      id={eventInfo.event.id}
                      onClick={(e) => clickCheck(e)}
                      color="secondary"
                    >
                      Unavailable
                    </CDropdownToggle>
                    <CDropdownMenu
                      style={{
                        height: "30px",
                      }}
                      onClick={AvailableModalState}
                    >
                      <CDropdownItem
                        className="CDropdownItem"
                        
                      >
                        Mark Available
                      </CDropdownItem>
                    </CDropdownMenu>
                  </CDropdown>
                )}
              </p>
            </CCol>
          </CRow>
        </CContainer>
      </>
    );
  };

  let week = moment(date).startOf("week").format("YYYY-MM-DD");
  let lastDay = moment(date).endOf("week").format("YYYY-MM-DD");
  let year = moment(date).year();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const day_mom = moment(date).day();

  let month = months[moment(date).month()];

  //Previos

  const dates = [];
  function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());

    while (date <= endDate) {
      const NewDates = new Date(date).toISOString().slice(0, 10).split("-");
      dates.push(new Date(date).toISOString().slice(0, 10).slice(0, 10));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  const d1 = new Date(week);
  const d2 = new Date(lastDay);


  const previousClicked = () => {
    var mydate = new Date(date + "T00:00:00");
    let previous_date = mydate.setDate(mydate.getDate() - 6);
    previous_date = new Date(previous_date);
    let days = previous_date.getDay();

    previous_date = previous_date.toISOString().toString().split("T")[0];
    setDate(previous_date);
  };

  const NextClicked = () => {
    var mydate = new Date(date + "T08:00:00");
    let next_date = mydate.setDate(mydate.getDate() + 7);
    next_date = new Date(next_date);

    next_date = next_date.toISOString().toString().split("T")[0];

    setDate(next_date);
  };

  const weeks = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  return (
    <>
      <div id="app" class="calendar">
        <h2 className="month">
          {month} {year}
        </h2>

        <div
          className="button-div-button"
          
        >
          <div>
            <button
              className="button-div-2"
              
              onClick={previousClicked}
            >
              &lt;
            </button>
          </div>

          <div style={{ display: "flex" }}>
            <ul className="da-week-calendar">
              {weeks.map((day, index) => {
                if (day_mom === index) {
                  return (
                    <lione>
                      {" "}
                      <span className="today cursor-pointer">{day}</span>
                    </lione>
                  );
                } else {
                  return <litwo style={{ color: "white" }}>{"   "}</litwo>;
                }
              })}

              {dates.map((DATE, index) => {
                return (
                  <lione
                    onClick={() => {
                      setDate(DATE);
                    }}
                  >
                    {date.split("-")[2] === DATE.split("-")[2] ? (
                      <span className="today cursor-pointer">{DATE.split("-")[2]}</span>
                    ) : (
                      DATE.split("-")[2]
                    )}
                  </lione>
                );
              })}
            </ul>
          </div>
          <div>
            <button
              className="button-div-2"
             
              onClick={NextClicked}
            >
              &gt;
            </button>
          </div>
        </div>
      </div>

      <div
        className="App container pb-3"
      >
        <Modal
          isOpen={bookedmodalOpen}
          style={{ width: "290px", height: "170px" }}
          className="abs"
        >
          <div className="close">
            <button
              style={{ marginLeft: "239px" }}
              className="plus"
              onClick={() => setbookedmodalOpen(false)}
            >
              <strong>X</strong>
            </button>
          </div>
          <h2
            style={{ width: "200px", marginTop: "-24px", marginLeft: "2.5rem" }}
            className="title"
          >
            Booked
          </h2>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
             
              width: "170px",
              height: "99px",
              alignItems: "center",
              marginTop: "12px",
              marginLeft: "55px",
            }}
          >
            <h6 id="1" className="heads" onClick={(e) => BookingFunc(e)}>
              Cancel Booking
            </h6>
            <h6
              id="2"
              className="heads"
              style={{
                paddingLeft: "80px",
              }}
              onClick={(e) => BookingFunc(e)}
            >
              Chat
            </h6>
          </div>
        </Modal>

        <Modal centered isOpen={unavailablemodalOpen} className="position-relative w-md-100 w-90 mx-auto">
          <div className="position-absolute top-right-icon">
              <img
                src={cross}
                onClick={() => setUnavailableModalOpen(false)}
                alt=""
              />
          </div>
          <div className="p-4">
            <h2 className="title">
              Mark Unavailable
            </h2>
            <div className="d-flex flex-column gap-3">
              <h6 className="">
                Would you like to mark as unavailable for today only or repeat it
                for all upcoming days
              </h6>
              <div className="d-flex flex-md-nowrap justify-content-center flex-wrap gap-3">
                <button onClick={() => markUnavailable(0)} className="">
                  Mark unavailable for this day only
                </button>
                <button onClick={() => markUnavailable(1)} className="">
                  Repeat unavailable for upcoming days
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal centered isOpen={availablemodalOpen} className="position-relative w-md-100 w-90 mx-auto">
          <div className="position-absolute top-right-icon">
              <img
                src={cross}
                onClick={() => setAvailableModalOpen(false)}
                alt=""
              />
          </div>
          <div className="p-4">
            <h2 className="title">
              Mark Available
            </h2>
            <div className="d-flex flex-column gap-3">
              <h6 className="">
                Would you like to mark as available for today only or repeat it
                for all upcoming days
              </h6>
              <div className="d-flex flex-md-nowrap justify-content-center flex-wrap gap-3">
                <button onClick={() => markAvailable(0)} className="">
                  Mark available for this day only
                </button>
                <button onClick={() => markAvailable(1)} className="">
                  Repeat available for upcoming days
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal isOpen={cancelBookingmodalOpen} className="abs">
          <div className="cancel-model">
            <div className="close">
              <button
                className="plus"
                onClick={() => setcancelBookingmodalOpen(false)}
              >
                <strong>X</strong>
              </button>
            </div>
            <h2 style={{ width: "241px" }} className="title">
              Cancel Booking
            </h2>
            <p style={{ width: "420.26px" }} className="body">
              Would you like to Cancel the Session
            </p>
            <input
              style={{
                marginLeft: "28px",
                marginRight: "22px",
                marginBottom: "17px",
                height: "2rem",
                width: "27rem",
              }}
              placeholder="  Enter the cancel reason"
              onChange={(e) => handleInputChange(e)}
              value={cancelReason}
              type="text"
            ></input>
            <button
              style={{ marginBottom: "30px", marginLeft: "30px" }}
              onClick={cancelBooking}
              className="b1"
            >
              Cancel Booking
            </button>
          </div>
        </Modal>

        <div className="calendar1">
          <FullCalendar
            style={{
              height: "200px !important",
            }}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="timeGridDay"
            events={slot}
            navLinks="true"
            eventContent={renderEventContent}
            headerToolbar={false}
            weekends={false}
            dayHeaders={false}
            allDaySlot={false}
          />
        </div>
        <div
          style={{ alignItem: "center", textAlign: "center", width: "100%" }}
        >
          <button
            onClick={() =>
              history.push({
                pathname: "/defineslots",
                state: { is_update: false },
              })
            }
            style={{ marginTop: "50px" }}
          >
            Define Availability
          </button>
        </div>

        {updateButton ? (
          <div
            style={{ alignItem: "center", textAlign: "center", width: "100%" }}
          >
            <button
              onClick={() =>
                history.push({
                  pathname: "/defineslots",
                  state: { is_update: true },
                })
              }
              style={{ marginTop: "50px" }}
            >
              Revise Defined Slots
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default DefineAvailability;
