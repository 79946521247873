import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import firebase from "firebase";
import moment from "moment";
import { addMessage, getMessageUpdate, getMessagesOfNode } from '../Common/Queries';
import _ from "lodash";
import { RESETPOST, putAPI } from "../../utils/helper";
import { toast } from "react-toastify";
import happymoji from "../../assets/images/happy-moji.png";
import smiling from "../../assets/images/smiling.png";
import heart from "../../assets/images/heart.png";
import haifai from "../../assets/images/hai-fai.png";
import hend from "../../assets/images/hend-moji.png";
function EmployerChatHelper(props) {
    const [chats, setChats] = useState([]);
    const [newchat, setNewchat] = useState("");
    const [samplechat, setSampleChat] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showemoji, setShowemoji] = useState(false);
    const name = localStorage.getItem("name");
    const userId = localStorage.getItem("id");
    const [successmsg, setSuccessmsg] = useState("");
    const chatdata = useSelector((state) => state.chatReducer.chatRoomdata);
    const primaryUser = {
        _id: userId,
        name: name,
        avatar: chatdata?.user?.profile_pic_url || "",
    };
    var globalMsg = [];
    const reactions = {
        2: hend,
        5: happymoji,
        4: smiling,
        3: heart,
        1: haifai,
        default: haifai, // Default value if none of the conditions are met
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        loadData();
    }, []);
    useEffect(() => {
        return () => {
            if (samplechat.length > 0) {
                if (samplechat[samplechat?.length - 1]?.user?._id !== userId) {
                    const msgId = samplechat[samplechat.length - 1].id;
                    const collection = firebase
                        .firestore()
                        .collection("chats_dev")
                        .doc(props?.location?.state?.room_id);
                    const ref = collection.collection("messages").doc(msgId);
                    ref.update({ isRead: true });
                }
            }
        };
    }, [samplechat, userId, props.location.state.room_id]);
    const toDay = (time) => {
        let date;
        date = new Date(new Date(time))
            .toLocaleDateString()
            .replace(/T.*/, "")
            .split("-")
            .reverse()
            .join("-");
        return date;
    };
    const toDayMoment = (time) => {
        return moment(time).format("MM/DD/YYYY");
    };
    const loadData = async () => {
        try {
            globalMsg = [];
            let messages = await getMessagesOfNode(
                props.location.state?.room_id,
                100,
                props.chatRoomdata.chatLastDeletedOn
            );

            messages = messages.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
            globalMsg = messages;
            setSampleChat(messages);
            let grouped = _.groupBy(messages, (message) => {
                return toDayMoment(message["createdAt"]);
            });
            let grp = [];
            grp.push(grouped);
            setChats(grp);
            setLoading(false);
            setTimeout(() => {
                getMessageUpdate(
                    chatdata?.room_id,
                    onMessageAdded,
                    props.chatRoomdata.chatLastDeletedOn
                );
            }, 2000);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }

        let data = {
            type: "chat_message_only",
            chat_room_id: `${props?.location?.state?.room_id}`,
        };
        await putAPI(`markNotificationsAsViewed`, data);
        props.notificationCount();
    };
    const onMessageAdded = async (newMessages) => {
        try {
            if (newMessages.length === 0) {
                return;
            }

            const updatedMessageId = newMessages[0].id;
            const nonUpdatingMessages = globalMsg.filter(
                (m) => m.id !== updatedMessageId
            );
            let combinedMessages = [
                ...nonUpdatingMessages,
                ...newMessages,
            ].sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));

            setSampleChat(combinedMessages);
            globalMsg = combinedMessages;
            let grouped = _.groupBy(combinedMessages, (msg) => {
                return toDayMoment(msg["createdAt"]);
            });

            let grp = [];
            grp.push(grouped);
            setChats(grp);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };
    const submitMessage = async (e) => {
        e.preventDefault();
        try {
            if (newchat && newchat.trim()) {
                let chatorder =
                    samplechat.length > 0 && !isNaN(samplechat[0].order)
                        ? samplechat[0].order
                        : 1;
                const order = samplechat.length > 0 ? chatorder + 1 : 1;

                const message = {
                    _id: Math.random().toString(),
                    type: "text",
                    text: newchat,
                    timestamp: new Date().getTime().toString(),
                    createdAt: new Date().getTime(),
                    user: primaryUser,
                    receiver_id: chatdata.other_user.id,
                    order: order,
                    isRead: false,

                    reactionId: 0,
                };

                addMessage(chatdata.room_id, message);
                var data = {
                    chat_type: "text",
                    chat_room_id: chatdata?.room_id,
                    message: newchat,
                };

                setNewchat("");

                RESETPOST(`sendChatNotification`, data)
                    .then((res) => {
                        if (res.status === 200) {
                            setSuccessmsg("success");
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        } catch (error) {
            toast.error(error.message, {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        }
    };
    const closeEmoji = (E) => {
        E.stopPropagation();
        let obj = [...chats];
        if (obj[0]) {
            Object.keys(obj[0])?.map((key, index) => {
                obj[0][key]?.map((data, Index) => {
                    data.showemoji = false;
                });
            });
            setChats(obj);
        }
    };
    const openEmoji = (e, data, key, j) => {
        e.stopPropagation();

        setEmojiOpen(e, data, key, j);
    };
    const setEmojiOpen = (e, data, key, j) => {
        let obj = [...chats];
        obj[0][key][j]["showemoji"] = obj[0][key][j]["showemoji"]
            ? !obj[0][key][j]["showemoji"]
            : true;

        Object.keys(obj[0])?.map((key, index) => {
            obj[0][key].map((data, index) => {
                if (index !== j) {
                    data.showemoji = false;
                }
            });
        });
        setChats(obj);
    };
    const setMsgReaction = (value, data, key, j) => {
        let obj = [...chats];
        obj[0][key][j]["showemoji"] = false;
        setChats(obj);
        const collection = firebase
            .firestore()
            .collection("chats_dev")
            .doc(props?.location?.state?.room_id);
        const ref = collection.collection("messages").doc(data.id);
        ref.update({ reactionId: value });
        setShowemoji(!showemoji);
    };
   
    return { chats, chatdata, loading, newchat, setMsgReaction, setEmojiOpen, openEmoji, closeEmoji, submitMessage, onMessageAdded, loadData, toDayMoment, toDay, setNewchat, name, reactions }
}

export default EmployerChatHelper