import React, { useEffect, useState } from "react";
import {
  addMessage,
  getMessagesOfNode,
  getMessageUpdate,
} from "../Components/Common/Queries";
import { getTextDate } from "../utils/validation";
import _ from "lodash";
import Avatar from "react-avatar";
import send from "../assets/images/send.png";
import { connect, useSelector } from "react-redux";
import ScrollToBottom from "react-scroll-to-bottom";
import firebase from "firebase";
import happymoji from "../assets/images/happy-moji.png";
import smiling from "../assets/images/smiling.png";
import heart from "../assets/images/heart.png";
import haifai from "../assets/images/hai-fai.png";
import hend from "../assets/images/hend-moji.png";
import profile1 from "../assets/images/profile1.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RESETPOST, putAPI } from "../utils/helper";
import SenderMessageComp from "../Components/Common/RenderEmoji";
import moment from "moment";
var globalMsg = [];
const ChatDetail = (props) => {
  const [chats, setChats] = useState([]);
  const [newchat, setNewchat] = useState("");
  const [samplechat, setSampleChat] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showemoji, setShowemoji] = useState(false);
  const name = localStorage.getItem("name");
  const userId = localStorage.getItem("id");
  const [successmsg, setSuccessmsg] = useState("");
  const chatdata = useSelector((state) => state.chatReducer.chatRoomdata);
  const primaryUser = {
    _id: userId,
    name: name,
    avatar: chatdata?.user?.profile_pic_url || "",
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    loadData();
  }, []);
  useEffect(() => {
    return () => {
      if (samplechat.length > 0) {
        if (samplechat[samplechat?.length - 1]?.user?._id !== userId) {
          const msgId = samplechat[samplechat.length - 1].id;
          const collection = firebase
            .firestore()
            .collection("chats_dev")
            .doc(props?.location?.state?.room_id);
          const ref = collection.collection("messages").doc(msgId);
          ref.update({ isRead: true });
        }
      }
    };
  }, [samplechat, userId, props.location.state.room_id]);

  const toDay = (time) => {
    let date;
    date = new Date(new Date(time))
    .toLocaleDateString()
    .replace(/T.*/, "")
    .split("-")
    .reverse()
    .join("-");
    return date;
  };

  const toDayMoment = (time) => {
    return moment(time).format("MM/DD/YYYY");
  };
  const loadData = async () => {
    try {
      globalMsg = [];
      let messages = await getMessagesOfNode(
        props.location.state?.room_id,
        100,
        props.chatRoomdata.chatLastDeletedOn
      );

      messages = messages.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
      globalMsg = messages;
      setSampleChat(messages);
      let grouped = _.groupBy(messages, (message) => {
        return toDayMoment(message["createdAt"]);
      });
      let grp = [];
      grp.push(grouped);
      setChats(grp);
      setLoading(false);
      setTimeout(() => {
        getMessageUpdate(
          chatdata?.room_id,
          onMessageAdded,
          props.chatRoomdata.chatLastDeletedOn
        );
      }, 2000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }

    let data = {
      type: "chat_message_only",
      chat_room_id: `${props?.location?.state?.room_id}`,
    };
    await putAPI(`markNotificationsAsViewed`, data);
    props.notificationCount();
  };

  const onMessageAdded = async (newMessages) => {
    try {
      if (newMessages.length === 0) {
        return;
      }

      const updatedMessageId = newMessages[0].id;
      const nonUpdatingMessages = globalMsg.filter(
        (m) => m.id !== updatedMessageId
      );
      let combinedMessages = [
        ...nonUpdatingMessages,
        ...newMessages,
      ].sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));

      setSampleChat(combinedMessages);
      globalMsg = combinedMessages;
      let grouped = _.groupBy(combinedMessages, (msg) => {
        return toDayMoment(msg["createdAt"]);
      });

      let grp = [];
      grp.push(grouped);
      setChats(grp);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const submitMessage = async (e) => {
    e.preventDefault();
    try {
      if (newchat && newchat.trim()) {
        let chatorder =
          samplechat.length > 0 && !isNaN(samplechat[0].order)
            ? samplechat[0].order
            : 1;
        const order = samplechat.length > 0 ? chatorder + 1 : 1;

        const message = {
          _id: Math.random().toString(),
          type: "text",
          text: newchat,
          timestamp: new Date().getTime().toString(),
          createdAt: new Date().getTime(),
          user: primaryUser,
          receiver_id: chatdata.other_user.id,
          order: order,
          isRead: false,

          reactionId: 0,
        };

        addMessage(chatdata.room_id, message);
        var data = {
          chat_type: "text",
          chat_room_id: chatdata?.room_id,
          message: newchat,
        };

        setNewchat("");

        RESETPOST(`sendChatNotification`, data)
          .then((res) => {
            if (res.status === 200) {
              setSuccessmsg("success");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const getTimeFromDate = (timestamp) => {
    let date = new Date(timestamp);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + ampm;
  };

  const closeEmoji = (E) => {
    E.stopPropagation();
    let obj = [...chats];
    if (obj[0]) {
      Object.keys(obj[0])?.map((key, index) => {
        obj[0][key]?.map((data, Index) => {
          data.showemoji = false;
        });
      });
      setChats(obj);
    }
  };
  const openEmoji = (e, data, key, j) => {
    e.stopPropagation();

    setEmojiOpen(e, data, key, j);
  };
  const setEmojiOpen = (e, data, key, j) => {
    let obj = [...chats];
    obj[0][key][j]["showemoji"] = obj[0][key][j]["showemoji"]
      ? !obj[0][key][j]["showemoji"]
      : true;

    Object.keys(obj[0])?.map((key, index) => {
      obj[0][key].map((data, index) => {
        if (index !== j) {
          data.showemoji = false;
        }
      });
    });
    setChats(obj);
  };
  const setMsgReaction = (value, data, key, j) => {
    let obj = [...chats];
    obj[0][key][j]["showemoji"] = false;
    setChats(obj);
    const collection = firebase
      .firestore()
      .collection("chats_dev")
      .doc(props?.location?.state?.room_id);
    const ref = collection.collection("messages").doc(data.id);
    ref.update({ reactionId: value });
    setShowemoji(!showemoji);
  };
  const renderChat = () => {
    return chats.map((e, i) =>
    Object.keys(e).map(function (key, index) {
        return (
          <div>
            <div style={{ position: "relative" }}>
              <div class="msg-day">
                <p>
                  {key === moment().format("MM/DD/YYYY")
                    ? "Today"
                    : key+""}
                </p>
              </div>
            </div>
            {e[key].map((data, j) => {
              var convertedTimestamp = new Date(
                props.chatRoomdata.chatLastDeletedOn
              );

              return data.timestamp >= Number(convertedTimestamp.getTime()) ? (
                data?.system ? (
                  <div>
                    <div style={{ position: "relative" }} class="msg-day">
                      <div
                        style={{
                          display: "block",
                          marginLeft: "85%",
                          fontSize: "7px",
                          marginBottom: "1rem",
                        }}
                      >
                        {getTimeFromDate(data.createdAt)}
                      </div>
                      <span>{data.text}</span>
                    </div>
                  </div>
                ) : (
                  <>
                    {Number(data?.user?._id) === Number(chatdata?.user?.id) ? (
                      <div class="outgoing_msg">
                        {chatdata?.user?.profile_pic_url ? (
                          <img
                            style={{ marginTop: "1rem" }}
                            src={
                              chatdata?.user?.profile_pic_url
                                ? chatdata?.user?.profile_pic_url
                                : profile1
                            }
                            alt=""
                          />
                        ) : (
                          <Avatar
                            style={{ marginTop: "1rem" }}
                            color={Avatar.getRandomColor("sitebase", [
                              "red",
                              "green",
                              "blue",
                            ])}
                            name={name}
                            size="36"
                            round={true}
                          />
                        )}
                        <div style={{ minHeight: "62px" }} class="sent_msg">
                          <span
                            style={{
                              marginBottom: "0.2rem",
                              fontSize: "0.6rem",
                            }}
                            class="time_date"
                          >
                            {" "}
                            {getTimeFromDate(data.createdAt)}
                          </span>

                          <p>
                            {data.text}
                            {data.reactionId !== 0 && (
                              <img
                                class="moji-wrap"
                                src={
                                  data.reactionId === 2
                                    ? hend
                                    : data.reactionId === 5
                                    ? happymoji
                                    : data.reactionId === 4
                                    ? smiling
                                    : data.reactionId === 3
                                    ? heart
                                    : data.reactionId === 1
                                    ? haifai
                                    : haifai
                                }
                                alt="emoji"
                              />
                            )}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <SenderMessageComp
                        chatdata={chatdata}
                        setMsgReaction={setMsgReaction}
                        data={data}
                        openEmoji={openEmoji}
                        newkey={key}
                        getTimeFromDate={getTimeFromDate}
                        Avatar={Avatar}
                        j={j}
                      />
                    )}
                  </>
                )
              ) : null;
            })}
          </div>
        );
      })
    );
  };
  return (
    <React.Fragment>
      {(props.loading || loading) && (
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      )}
      <section class="chat-details-wrapper" onClick={(e) => closeEmoji(e)}>
        <div class="message-chat-details bg-color">
          <div class="container">
            <div class="messaging comman-width-container">
              <div class="inbox_msg">
                <div class="mesgs">
                  <div class="msg_history">
                    <ScrollToBottom className="msgsc" mode="bottom">
                      {renderChat()}
                    </ScrollToBottom>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!props?.location?.state?.is_disabled && (
          <div class="container">
            <div class="type_msg comman-width-container">
              <div class="input_msg_write">
                <form onSubmit={submitMessage}>
                  <input
                    type="text"
                    class="write_msg"
                    placeholder="Type a message"
                    value={newchat}
                    onChange={(e) => setNewchat(e.target.value)}
                  />
                  <div class="send-icon" onClick={(e) => submitMessage(e)}>
                    <button class="msg_send_btn" type="button">
                      <img alt="" src={send} />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </section>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  const loading = state.globalReducer.loading;
  return {
    chatRoomdata: state.chatReducer.chatRoomdata,
    loading,
  };
};
const mapDispatch = (dispatchEvent) => ({
  creteChatRoom: (payload) =>
    dispatchEvent({ type: "CREATECHAT_ROOM", value: payload }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
  hitSendChatNotiApi: (payload) =>
    dispatchEvent({ type: "CHAT_NOTIF", value: payload }),
  notificationCount: () => dispatchEvent({ type: "GET_NOTIFICATION_COUNT" }),
});

export default connect(mapStateToProps, mapDispatch)(ChatDetail);
