import * as actions from "../type.action";

const initialState = {
  all: null,
  achievement_post_only: null,
  chat_message_only: null,
  message: null,
};

const notificationReducer = (state, action) => {
  if (state === undefined) {
    return (state = initialState);
  }

  switch (action.type) {
    case actions.NOTIFICATION_COUNT_SUCCESS:
      return {
        ...state,
        data:action.data,
        all: action.data.all,
        achievement_post_only: action.data.achievement_post_only,
        chat_message_only: action.data.chat_message_only,
      };
    case actions.NOTIFICATION_COUNT_FAIL:
      return {
        ...state,
        message: action.data,
      };
    default:
      return state;
  }
};

export default notificationReducer;
