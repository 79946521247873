import React from "react";
import { isEmpty } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { CSpinner } from "@coreui/react";

import "./style.scss";
import { useGetAccomplishment } from "../../hooks/analytics.hook";
import LoadingWidget from "../LoadingWidget";
import AccomplishmentItem from "./components/AccomplishmentItem";

const AccomplishmentsWidget = () => {
  const accomplishmentQuery = useGetAccomplishment();
  const accomplishments =
    accomplishmentQuery.data?.pages.flatMap((page) => page.body?.data) || [];
 
  return (
    <div className="accomplishments-widget">
      <div className="header">
        <p>Accomplishments</p>
      </div>
      <div className="accomplishments-wrapper">
        {!accomplishmentQuery.isLoading && !isEmpty(accomplishments) ? (
          <InfiniteScroll
            className="accomplishments"
            height={360}
            dataLength={accomplishments.length}
            next={accomplishmentQuery.fetchNextPage}
            hasMore={accomplishmentQuery.hasNextPage}
            loader={<CSpinner grow color="primary" />}
            endMessage={
              accomplishmentQuery.isLoading ? (
                <CSpinner grow color="primary" />
              ) : (
                <span style={{ textAlign: "center" }}>
                  <b>You reached the end</b>
                </span>
              )
            }
          >
            {accomplishments.map((item) => (
              <AccomplishmentItem key={item.id} accomplishment={item} />
            ))}
          </InfiniteScroll>
        ) : isEmpty(accomplishments) && !accomplishmentQuery.isLoading ? (
          <>
            <p className="no-data-found">No data found</p>
          </>
        ) : (
          <LoadingWidget />
        )}
      </div>
    </div>
  );
};

export default AccomplishmentsWidget;
