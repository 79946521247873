import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import search from "../assets/images/search.png";
import banner from "../assets/images/banner.png";
import ManagerGoalItem from "./ManagerGoalItem";
import { GET } from "../utils/helper";

const ManagerGoal = (props) => {
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await GET(`viewGoalAsManager`, 0);
      setData(res.body.data.rows);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    props.getGoalsData(keyword);
  }, [keyword]);

  const handleChange = (e) => {
    e.preventDefault();
    setKeyword(e.target.value);
  };

  useEffect(() => {
    props.getGoalsData("");
    props.getTeamList();
    props.closeSucessModal();
  }, []);

  const openGoalDetail = (id) => {
    props.onFieldChange({ name: "OpenGoal", value: { id } });
  };

  const showToast = () => {
    toast.error(props.errorMsg, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  useEffect(() => {
    if (props.errorMsg !== "") showToast();
  }, [props.errorMsg]);
  
  const showSucessToast = () => {
    toast.success(props.updatesucessMsg, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  useEffect(() => {
    if (props.updatesucessMsg !== "") showSucessToast();
  }, [props.updatesucessMsg]);

  const deletePrevGoal = (id) => {
    if (window.confirm("Are you sure you want to delete this goal")) {
      props.DeleteManagerPrevGoal(id);
    }
  };

  return (
    <React.Fragment>
      <div className="assign-Goal-Look-wrapper min-height-60vh">
        <div className="container">
          <div className="Manager-Goal-Look comman-width-container">
            <div className="banner-img">
              <img className="img-fluid" src={banner} />
            </div>
            <div className="search-wrapper">
              <form className="search" onSubmit={(e) => e.preventDefault()}>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by Goal name"
                    value={keyword}
                    onChange={(e) => handleChange(e)}
                  />
                  <img src={search} alt=""/>
                </div>
              </form>
              <div className="assign-Goal-Look comman-width-container">
                {props?.goalData?.length === 0 &&
                  keyword &&
                  keyword?.length === 0 &&
                  props.goallistStatus && (
                    <div className="teammsg margin-top-4rem">
                      No Goals Added!
                    </div>
                  )}
                {props?.goalData?.length === 0 &&
                  keyword &&
                  keyword?.length >= 1 && (
                    <div className="teammsg  margin-top-4rem">
                      No Goals Found!
                    </div>
                  )}

                {props?.goalData?.map((item, index) => {
                  return (
                    <ManagerGoalItem
                      key={index}
                      goal={item}
                      deletePrevGoal={deletePrevGoal}
                      openGoalDetail={openGoalDetail}
                      index={index}
                    />
                  );
                })}

                <div className="More-Goal-assign-buttons">
                  {props.loading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : null}
                  <div className="btn-blue">
                    <button
                      className="assign-goal"
                      onClick={() => props.history.push("/addnewgoal")}
                    >
                      Setup New Goal
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    goalData: state.goalReducer.goalData,
    addNewGoal: state.goalReducer.addNewGoal,
    errorMsg: state.goalReducer.errorMsg,
    updatesucessMsg: state.goalReducer.updatesucessMsg,
    loading: state.globalReducer.loading,
    goalReadData: state.goalReducer.goalReadData,
    goallistStatus: state.goalReducer.goallistStatus,
  };
};

const mapDispatch = (dispatchEvent) => ({
  getGoalsData: (payload) =>
    dispatchEvent({ type: "GET_GOALDATA", value: payload }),
  getTeamList: () => dispatchEvent({ type: "GET_TEAMLIST" }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
  submitnewGoals: (payload) =>
    dispatchEvent({ type: "SUBMIT_NEWGOALS", value: payload }),
  DeleteManagerPrevGoal: (payload) =>
    dispatchEvent({ type: "DELETEMANAGERGOAL", value: payload }),
  deleteNewGoal: (payload) =>
    dispatchEvent({ type: "DELETENEW_MANAGERGOAL", value: payload }),
  closeSucessModal: () => dispatchEvent({ type: "CLOSE_GOALMODAL" }),
});

export default connect(mapStateToProps, mapDispatch)(ManagerGoal);
