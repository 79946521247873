import { CHAT_NOTIF, CREATECHAT_ROOM, GET_NOTIFICATION_COUNT, ONFIELD_CHANGE } from "../type.action";

export const createChatRoom = (payload) => ({
    type: CREATECHAT_ROOM,
    value: payload,
  });
  
  export const onFieldChange = (payload) => ({
    type: ONFIELD_CHANGE,
    value: payload,
  });
  
  export const hitSendChatNotiApi = (payload) => ({
    type: CHAT_NOTIF,
    value: payload,
  });
  
  export const notificationCount = () => ({
    type: GET_NOTIFICATION_COUNT,
  });