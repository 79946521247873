const BASE_URL = "https://app.dev.bluxinga.com/";
const BASE_URL_WEB_VIEW = "https://www.bluxinga.com";
const BASE_API_URL = "https://api.dev.bluxinga.com/api/v1/";
const FIREBASE_MESSAGING_SERVICE_WORKER = "./firebase-messaging-sw.dev.js";
const FIREBASE_API_KEY =
  "AIzaSyDFnuo0oK4ncm_ncgfdixcFft-T3M-_iCk";
const FIREBASE_AUTH_DOMAIN = "bluxinga-dev.firebaseapp.com";
const FIREBASE_PROJECT_ID = "bluxinga-dev";
const FIREBASE_STORAGE_BUCKET = "bluxinga-dev.appspot.com";
const FIREBASE_MESSAGING_SENDER_ID = "1049163737107";
const FIREBASE_APP_ID = "1:1049163737107:web:75061c0d0970442177f7e6";
const FIREBASE_MEASUREMENT_ID = "G-CRPMJV0RR6";
const SMALL_PLAN_ID = "price_1PctaUIriDhPQnrmsyl15bNZ";
const MEDIUM_PLAN_ID = "price_1PctbhIriDhPQnrmtiHYgqpt";
const LARGE_PLAN_ID = "price_1Pctc1IriDhPQnrmzpG0mqlC"

export default {
  BASE_URL,
  BASE_URL_WEB_VIEW,
  BASE_API_URL,
  FIREBASE_MESSAGING_SERVICE_WORKER,
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
  SMALL_PLAN_ID,
  MEDIUM_PLAN_ID,
  LARGE_PLAN_ID
};
