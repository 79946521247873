import React, { useEffect, useState } from "react";
import pro from "../../assets/images/profilePlaceholder/profilePlaceholder.png";
import { GET } from "../../utils/helper";
import moment from "moment";
import ReactStarsComp from "../Common/ReactStarsComp";
import Modal from "../Common/Modal";

export default function SessionHistory() {
  const [sessions, setSessions] = useState([]);

  const [loading, setLoading] = useState(false);
  const [detailModal, setDetailModal] = useState({
    isOpen: false,
    data: null,
  });
  let dateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

  const handleViewDetailClicked = (id) => {
    const getData = async () => {
      try {
        const result = await GET(`getSessionHistoryDetails/${id}`);
        if (result.status === 200) {
          setDetailModal((state) => ({
            ...state,
            data: result?.body?.data,
            isOpen: true,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const result = await GET(`listSessionHistory?datetime=${dateTime}`);
        setLoading(false);
        if (result.status === 200) {
          setSessions(result?.body?.data?.rows);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    getData();
  }, []);

  return (
    <section style={{ minHeight: "80vh" }} class="profiles-wrapper-main">
      <div class="container">
        <h3 style={{ textAlign: "center", justifyContent: "center" }}>
          Sessions History
        </h3>
        {!loading ? (
          sessions.length > 0 &&
          sessions.map((session) => {
            return (
              <div style={{}} class="profiles-wrapper">
                <div class="profile-comman-box">
                  <div
                    class="row"
                    style={{ textAlign: "center", justifyContent: "center" }}
                  >
                    <div class="col-md-12 comman-width one">
                      <div
                        class="main-profiles  mainprofile"
                      
                      >
                        <div
                          className="profile-div"
                        
                        >
                          <div
                            className="profile-div1"
                           
                          >
                            <p
                             
                              style={{
                                width: "12rem",
                                wordBreak: "break-all",
                                textAlign: "left",
                              }}
                            >
                              <p>
                                {moment(session?.date).format("Do MMM YYYY")}
                              </p>{" "}
                            </p>
                          </div>
                        </div>
                        <div
                       
                          style={{
                            color: "grey",
                            minWidth: "10%",
                            height: "1.5rem",
                            borderTopRightRadius: "1rem",
                            borderBottomRightRadius: "1rem",
                            position: "absolute",
                            top: "0",
                            right: "0",
                            paddingLeft: "5%",
                            paddingTop: "1px",
                          }}
                        >
                          <p
                            style={{
                              backgroundColor:
                                session.status === 1
                                  ? "yellow"
                                  : session.status === 2
                                  ? "rgba(0, 127, 0, 0.54)"
                                  : session.status === 3
                                  ? "red"
                                  : session.status === 4
                                  ? "red"
                                  : "rgba(0, 127, 0, 0.54)",
                              borderTopRightRadius: "1rem",
                              borderBottomLeftRadius: "1rem",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                              display: "inline-block",
                              color: "white",
                              wordBreak: "break-all",
                              textAlign: "right",
                            }}
                          >
                            <p>
                              {session.status === 1
                                ? "Pending"
                                : session.status === 2
                                ? "Accepted"
                                : session.status === 3
                                ? "Rejected"
                                : session.status === 4
                                ? "Cancelled"
                                : "Completed"}
                            </p>
                          </p>
                        </div>
                        <div class="profile-img">
                          <img
                            className="imgprofile"
                           
                            src={session?.employee?.profile_pic_url || pro}
                            alt=""
                          />
                        </div>
                        <div class="row no-gutters">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "50rem",
                            }}
                          >
                            <div
                              className="ladelstyle"
                            
                            >
                              <p>Team Level :</p>
                              <p
                                className="labelstyless"
                              
                              >
                                <p>{session?.employee_rank?.name}</p>
                              </p>
                            </div>
                            <div
                              className="ladelstyle"
                             
                            >
                              <p>Performance Challenge :</p>
                              <p
                                className="labelstyless"

                            
                              >
                                <p>
                                  {session?.coach_specialization_category?.name}
                                </p>{" "}
                              </p>
                            </div>
                            <div
                              className="ladelstyle"
                            
                            >
                              <p>Challenge Details :</p>
                              <p
                                className="labelstyless"
                                
                              >
                                <p>{session?.query}</p>{" "}
                              </p>
                            </div>
                            {session.status !== 3 &&
                            session.status !== 4 &&
                            session.coach_rating ? (
                              <div
                                className="ladelstyle"
                             
                              >
                                <p>Ratings :</p>
                                <p
                                  className="labelstyless"
                                
                                >
                                  <p>
                                    <ReactStarsComp
                                      color="#C9C9C9"
                                      size={20}
                                      value={session.coach_rating}
                                      edit={false}
                                      activeColor="#FF7500"
                                      emptyIcon={<i className="fa fa-star-o" />}
                                      halfIcon={
                                        <i className="fa fa-star-half-alt" />
                                      }
                                      filledIcon={<i className="fa fa-star" />}
                                    />
                                  </p>
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div
                          className="viewbutton"
                        
                        >
                          <button
                            onClick={() => handleViewDetailClicked(session?.id)}
                            className="px-4 w-fit"
                          >
                            View Detail
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        )}
      </div>
      <Modal
        showmodal={detailModal.isOpen}
        onCLose={() => {
          setDetailModal((state) => ({ ...state, isOpen: false }));
        }}
       
        body={
          detailModal.data && (
            <div class="profiles-wrapper w-100">
              <div class="profile-comman-box">
                <div
                  class="row mx-auto"
                  style={{ textAlign: "center", justifyContent: "center" }}
                >
                  <div class="w-100">
                    <div
                      class="main-profiles  mainprofile"
                      // style={{
                      //   borderRadius: "12px",
                      // }}
                    >
                      <div
                        className="profile-div"
                      >
                        <div
                          className="ladelstyle"
                      
                        >
                          <p
                            className="ladelstyless"
                           
                          >
                            <p>
                              {moment(detailModal.data?.date).format(
                                "Do MMM YYYY"
                              )}
                            </p>{" "}
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          color: "grey",
                          minWidth: "10%",
                          height: "1.5rem",
                          borderTopRightRadius: "0.6rem",
                          borderBottomRightRadius: "1rem",
                          position: "absolute",
                          top: "0",
                          right: "0",
                          paddingLeft: "5%",
                        }}
                      >
                        <p
                          style={{
                            backgroundColor:
                              detailModal.data.status === 1
                                ? "yellow"
                                : detailModal.data.status === 2
                                ? "rgba(0, 127, 0, 0.54)"
                                : detailModal.data.status === 3
                                ? "red"
                                : detailModal.data.status === 4
                                ? "red"
                                : "rgba(0, 127, 0, 0.54)",
                            borderTopRightRadius: "0.6rem",
                            borderBottomLeftRadius: "1rem",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            display: "inline-block",
                            color: "white",
                            wordBreak: "break-all",
                            textAlign: "right",
                          }}
                        >
                          <p>
                            {detailModal.data.status === 1
                              ? "Pending"
                              : detailModal.data.status === 2
                              ? "Accepted"
                              : detailModal.data.status === 3
                              ? "Rejected"
                              : detailModal.data.status === 4
                              ? "Cancelled"
                              : "Completed"}
                          </p>
                        </p>
                      </div>
                      <div class="profile-img">
                        <img
                          className="modal-img"
                        
                          src={
                            detailModal?.data?.employee?.profile_pic_url || pro
                          }
                          alt=""
                        />
                      </div>
                      <div class="row no-gutters">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "50rem",
                          }}
                        >
                          <div
                            className="ladelstyle"
                          
                          >
                            <p>Session Time :</p>
                            <p
                              className="ladelstyless"
                             
                            >
                              <p>
                                {detailModal
                                  ? moment(
                                      detailModal.data.start_time.slice(0, 5),
                                      ["HH.mm"]
                                    ).format("hh:mm a")
                                  : ""}
                                -{" "}
                                {detailModal
                                  ? moment(
                                      detailModal.data.end_time.slice(0, 5),
                                      ["HH.mm"]
                                    ).format("hh:mm a")
                                  : ""}
                              </p>
                            </p>
                          </div>
                          <div
                            className="ladelstyle"
                           
                          >
                            <p>Team Level :</p>
                            <p
                              className="ladelstyless"
                            
                            >
                              <p>{detailModal?.data?.employee_rank?.name}</p>
                            </p>
                          </div>

                          <div
                            className="ladelstyle"
                          
                          >
                            <p>Performance Challenge :</p>
                            <p
                              className="ladelstyless"
                            
                            >
                              <p>
                                {
                                  detailModal?.data
                                    ?.coach_specialization_category?.name
                                }
                              </p>{" "}
                            </p>
                          </div>

                          <div
                            className="ladelstyle"
                          
                          >
                            <p>Challenge Details :</p>
                            <p
                              className="ladelstyless"
                            
                            >
                              <p>{detailModal?.data?.query}</p>{" "}
                            </p>
                          </div>

                          {detailModal?.data?.status === 5 ? (
                            <div
                              className="ladelstyle"
                            
                            >
                              <p>Call duration :</p>
                              <p
                                className="ladelstyless"
                              
                              >
                                <p>{detailModal?.data?.call_duration} mins</p>
                              </p>
                            </div>
                          ) : null}

                          {detailModal?.data?.status === 5 ? (
                            <div
                              className="ladelstyle"
                             
                            >
                              {detailModal?.data?.comment ? (
                                <p>Review :</p>
                              ) : (
                                ""
                              )}

                              <p
                                className="ladelstyless"
                               
                              >
                                <p>{detailModal?.data?.comment} </p>
                              </p>
                            </div>
                          ) : null}

                          {detailModal?.data?.cancelled_by !== 0 && (
                            <div
                              className="ladelstyle"
                             
                            >
                              <p>Cancelled by :</p>
                              <p
                                className="ladelstyless"
                              
                              >
                                {detailModal?.data?.cancelled_by === 0
                                  ? "Not Cancelled"
                                  : detailModal?.data?.cancelled_by === 1
                                  ? "Coach"
                                  : detailModal?.data?.cancelled_by === 2
                                  ? "Employee"
                                  : ""}
                              </p>
                            </div>
                          )}
                          {detailModal?.data?.cancelled_by !== 0 && (
                            <div
                              className="ladelstyle"
                             
                            >
                              <p>Cancel reason :</p>
                              <p
                                className="ladelstyless"
                              
                              >
                                <p>{detailModal?.data?.cancel_reason}</p>
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      
      />
    </section>
  );
}
