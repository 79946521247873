

import React, { useEffect } from 'react'
import { toast } from 'react-toastify';

export default function wrapper(ComposedComponent) {

  return function NetworkDetector() {
    useEffect(() => {
      const showToast = () => {
        toast.error('Seems like ,you lost your internet connection', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        window.removeEventListener('offline', showToast);
      }
      window.addEventListener('offline', showToast);
    }, []);

    return (
      <ComposedComponent />
    )
  }




}
