const initialState = {
    subs: []
}

const videoReducer = (state, action) => {
    if (state === undefined) {
        return (state = initialState)
    }

    switch (action.type) {
        case 'SUBS':
            return {
                ...state,
                subs: action.payload
            }
        default:
            return state
    }

}

export default videoReducer;