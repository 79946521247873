import * as actions from "../type.action";
const initialState = {
  goalData: [],
  goalReadData: [],
  errorMsg: "",
  imageError: "",
  addNewGoal: [
    {
      start_date: new Date(),
      end_date: "",
      title: "",
      description: "",
      select_measure: "Select",
      enter_measure: "",
      team_goal_assigns: [],
      isOpen: true,
      employee_ids: [],
      disable: false,
    },
  ],
  updatesucessMsg: "",
  goalAddStatus: false,
  showForm: true,
  goallistStatus: false,
};

const goalReducer = (state, action) => {
  let newState = {};
  let obj = [];
  let id = 0;
  if (state === undefined) {
    return (state = initialState);
  }
  switch (action.type) {
    case "CLEAR_GOAL":
      return {
        goalData: [],
        goalReadData: [],
        errorMsg: "",
        imageError: "",
        addNewGoal: [
          {
            start_date: new Date(),
            end_date: "",
            title: "",
            description: "",
            select_measure: "Select",
            enter_measure: "",
            team_goal_assigns: [],
            isOpen: true,
            employee_ids: [],
            disable: false,
          },
        ],
        updatesucessMsg: "",
        goalAddStatus: false,
        showForm: true,
        goallistStatus: false,
      };
    case actions.MANAGERGOAL_DATASUCCESS:
      return {
        ...state,
        goalData: action.data,
        goalReadData: action.data,
        goallistStatus: true,
        updatesucessMsg: "",
      };
    case "Change_MsgStatus":
      return {
        goallistStatus: false,
      };
    case actions.UPDATEMANAGERGOALSUCCESS:
      obj = Object.assign([], state.goalData);
      id = action?.index;
      obj[id].isOpen = false;
      newState = Object.assign({}, state, {
        goalData: obj,
      });

      return {
        ...state,
        goalData: obj,
        updatesucessMsg: action.msg,
        goalReadData: obj,
      };
    case actions.DELETEMANAGERGOALSUCCESS:
      return {
        ...state,
        updatesucessMsg: action.msg,
      };
    case "HIDE_MSG":
      return {
        ...state,
        updatesucessMsg: "",
        errorMsg: "",
      };
    case actions.ADDMANAGERGOALSUCCESS:
      return {
        ...state,
        addNewGoal: [
          {
            start_date: new Date(),
            end_date: "",
            title: "",
            description: "",
            select_measure: "Select",
            enter_measure: "",
            team_goal_assigns: [],
            isOpen: true,
            employee_ids: [],
          },
        ],
        goalAddStatus: true,
        showForm: true,
      };
    case "CLOSE_GOALMODAL":
      return {
        ...state,
        goalAddStatus: false,
        errorMsg: "",
        updatesucessMsg: "",
      };
    case actions.MANAGERGOAL_DATAFAIL:
      return {
        ...state,
        errorMsg: action.data,
        goallistStatus: false,
      };
    case "DELETENEW_MANAGERGOAL":
      obj = Object.assign([], state.addNewGoal);
      id = action.value;
      obj.splice(id, 1);
      newState = Object.assign({}, state, {
        addNewGoal: obj,
      });
      return newState;
    case "OpenGoal":
      obj = Object.assign([], state.goalData);
      id = action.data.id;
      obj[id].isOpen = obj[id].isOpen ? !obj[id].isOpen : true;
      newState = Object.assign({}, state, {
        goalData: obj,
      });
      return newState;
    case "OpenNewGoal":
      obj = Object.assign([], state.addNewGoal);
      id = action.data.id;
      obj[id].isOpen = !obj[id].isOpen;
      newState = Object.assign({}, state, {
        addNewGoal: obj,
      });
      return newState;
    case "EditNewGoal":
      obj = Object.assign([], state.addNewGoal);
      id = action.data.id;

      obj.forEach((data, index) => {
        if (index === id) {
          obj[index].isOpen = true;
          obj[index].disable = false;
        } else {
          obj[index].isOpen = false;
          obj[index].disable = true;
        }
      });
      newState = Object.assign({}, state, {
        addNewGoal: obj,
        showForm: true,
      });
      return newState;
    case "addGoal":
      obj = Object.assign([], state.addNewGoal);
      let finalobj = Object.assign([], state.addNewGoal);
      let last = [];
      let e_id = [];
      if (obj.length >= 1) {
        last = obj.slice(-1);
      }
      last[0]?.team_goal_assigns !== undefined &&
        last[0].team_goal_assigns.map((e) => e_id.push(e.value));

      let newgoal = {
        start_date: new Date(),
        end_date: "",
        title: "",
        description: "",
        select_measure: "Select",
        enter_measure: "",
        team_goal_assigns:
          last[0]?.team_goal_assigns !== undefined
            ? last[0].team_goal_assigns
            : [],
        isOpen: true,
        employee_ids: e_id,
      };
      finalobj.push(newgoal);
      newState = Object.assign({}, state, {
        addNewGoal: finalobj,
      });
      return newState;
    case "ADD_CHECKNewGoal":
      obj = Object.assign([], state.addNewGoal);
      let newchekgoal = {
        start_date: new Date(),
        end_date: "",
        title: "",
        description: "",
        select_measure: "Select",
        enter_measure: "",
        team_goal_assigns: [],
        isOpen: true,
        employee_ids: [],
      };
      if (obj.length === 0) {
        obj.push(newchekgoal);
        newState = Object.assign({}, state, {
          addNewGoal: obj,
          showForm: true,
        });
        return newState;
      } else return state;
    case "handleGoalUpdateChange":
    case "handleGoalUpdateValidation":
      if (action.data.update === true) {
        obj = Object.assign([], state.goalData);
        obj[action.data.id] = { ...obj[action.data.id], ...action.data.data };
        return {
          ...state,
          goalData: obj,
          errorMsg: "",
          updatesucessMsg: "",
        };
      } else {
        obj = Object.assign([], state.addNewGoal);
        obj[action.data.id] = { ...obj[action.data.id], ...action.data.data };
        return {
          ...state,
          addNewGoal: obj,
          goalAddStatus: false,
        };
      }

    case "handlePreview":
      obj = Object.assign([], state.addNewGoal);
      obj.forEach((data, index) => {
        obj[index].disable = action.data.disable;
        obj[index].isOpen = true;
      });
      newState = Object.assign({}, state, {
        addNewGoal: obj,
        showForm: false,
      });
      return newState;

    default:
      return state;
  }
};
export default goalReducer;
