import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { getDate } from "../utils/validation";
import banner from "../assets/images/banner.png";
import { GET, getErrorMessage, getSelectMeasure } from "../utils/helper";
import GoalMemberItem from "./GoalMemberItem";

const ManagerGoalDetail = (props) => {
  const params = useParams();
  const history = useHistory();
  const [goal, setGoal] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.onFieldChange({ name: "HIDE_MSG", value: "" });
  }, []);

  const getGoalDetail = async () => {
    try {
      setLoading(true);
      const res = await GET(`viewGoalAsManager?id=${params?.id}`);
      setGoal(res?.body?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const err = getErrorMessage(error);
      toast.error(err, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  useEffect(() => {
    getGoalDetail();
  }, []);

  return (
    <div class="Manager-Goal-Look-wrapper bg-color min-height-90vh">
      <div class="container">
        {!loading ? (
          <div class="Manager-Goal-Look comman-width-container">
            <div class="banner-img">
              <img class="img-fluid" src={banner} />
            </div>
            <br />
            
            {goal?.id ? (
              <>
                <div class="Goal-Detail wrapper">
                  <div class="row">
                    <div class="col-md-6 col-sm-6 col-6">
                      <div class="start-date btn-blue">
                        <button>
                          Start Date <br />
                          {getDate(goal?.start_date)}
                        </button>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-6">
                      <div class="Due-date btn-blue">
                        <button>
                          Due date
                          <br />
                          {getDate(goal?.end_date)}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="Goal-sales-wrapper">
                      <hr />
                      <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                          <div class="Goal-Title">
                            <p>Goal Title</p>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                          <div class="Goal-Title">
                            <p>{goal?.title}</p>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                          <div class="Goal-Title">
                            <p>Goal Description</p>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                          <div class="Goal-Title">
                            <p>{goal?.description}</p>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                          <div class="Goal-Title">
                            <p>Goal Measure </p>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                          <div class="Goal-Title">
                            <p>
                              {getSelectMeasure(goal?.select_measure)}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <div class="row">
                        <div class="col-md-6 col-sm-6 col-6">
                          <div class="Goal-Title">
                            <p>Goal Completion</p>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6">
                          <div class="Goal-Title">
                            <p> {goal?.enter_measure}</p>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div>
                  {goal?.team_goal_assigns?.map((employee) => (
                    <GoalMemberItem key={employee.id} employeeData={employee}/>
                  ))}
                </div>
              </>
            ) : (
              <div className="teammsg   margin-top-4rem">Goal not found!</div>
            )}
          </div>
        ) : (
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    showLook: state.globalReducer.showLook,
  };
};

const mapDispatch = (dispatchEvent) => ({
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(ManagerGoalDetail);
