import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getDate, getTextDate } from "../utils/validation";
import Select, { components } from "react-select";
import uncheckedteam from "../assets/images/uncheckedteam.png";
import checkedteam from "../assets/images/checkedteam.png";
import calendar from "../assets/images/calander.png";
import "../scss/customforall.scss";
const AddGoal = ({
  item,
  teamList,
  id,
  update,
  onFieldChange,
  mangerGoalUpdate,
  loading,
  disable,
}) => {
  const [team, setTeam] = useState(teamList);
  const [employee_ids, setEmployeId] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [startDateType, setStartDateType] = useState("text");
  const [endDateType, setEndDateType] = useState("text");

  useEffect(() => {
    const options = [];

    if (update === true) {
      item?.team_goal_assigns?.map(
        (e) =>
          options.push({
            value: e?.employee?.id ? e?.employee?.id : e.value,
            label: e?.employee?.name ? e?.employee?.name : e.label,
          }) && employee_ids.push(e?.employee?.id)
      );
    } else {
      item?.team_goal_assigns?.map(
        (e) =>
          options.push({ value: e.value, label: e.label }) &&
          employee_ids.push(e.value)
      );
    }
    setSelectedOption(options);
    setEmployeId(employee_ids);
  }, [employee_ids, item?.team_goal_assigns, update]);
  useEffect(() => {
    const options = [];
    teamList.map((e) =>
      options.push({ value: e.employee.id, label: e.employee.name })
    );
    setTeam(options);
  }, [teamList]);
  const handleSelectChange = (selectedoption) => {
    let selectedemp = [];
    setSelectedOption(selectedoption);
    selectedoption?.map((e) => selectedemp.push(e.value));
    setEmployeId(selectedemp);
    handleSelect(selectedemp, selectedoption);
  };
  const handleSelect = (selectedemp, SelectedOption) => {
    onFieldChange({
      name: "handleGoalUpdateChange",
      value: {
        data: {
          [`selectedempError`]: "",
          employee_ids: selectedemp,
          team_goal_assigns: SelectedOption,
        },
        id,
        update,
      },
    });
  };

  const handleChange = (e) => {
    if (e.target.name === "enter_measure") {
      if (
        Number(e.target.value) < e.target.min ||
        Number(e.target.value) > e.target.max
      ) {
        return;
      }
    }
    onFieldChange({
      name: "handleGoalUpdateChange",
      value: {
        data: {
          [e.target.name]: e.target.value,
          [`${e.target.name}Error`]: "",
        },
        id,
        update,
      },
    });
  };
  const onBlur = (event) => {
    if (
      Number(event.target.value) < event.target.min ||
      Number(event.target.value) > event.target.max
    ) {
      return;
    }
  };

  const updateGoal = () => {
    let count = 0;
    let error = "Please enter this field";

    if (+new Date(item.start_date) >= +new Date(item.end_date)) {
      onFieldChange({
        name: "handleGoalUpdateValidation",
        value: {
          data: {
            [`end_dateError`]: "End date should be greater than start date",
          },
          id,
          update,
        },
      });
      count++;
    }

    if (item.start_date === "") {
      onFieldChange({
        name: "handleGoalUpdateValidation",
        value: { data: { [`start_dateError`]: error }, id, update },
      });
      count++;
    }
    if (item.end_date === "") {
      onFieldChange({
        name: "handleGoalUpdateValidation",
        value: { data: { [`end_dateError`]: error }, id, update },
      });
      count++;
    }
    if (item.title === "") {
      onFieldChange({
        name: "handleGoalUpdateValidation",
        value: { data: { [`titleError`]: error }, id, update },
      });
      count++;
    }
    if (item.description === "") {
      onFieldChange({
        name: "handleGoalUpdateValidation",
        value: { data: { [`descriptionError`]: error }, id, update },
      });
      count++;
    }
    if (item.select_measure === "Select") {
      onFieldChange({
        name: "handleGoalUpdateValidation",
        value: { data: { [`select_measureError`]: error }, id, update },
      });
      count++;
    }
    if (item.enter_measure === "") {
      onFieldChange({
        name: "handleGoalUpdateValidation",
        value: { data: { [`enter_measureError`]: error }, id, update },
      });
      count++;
    }
    if (employee_ids.length === 0) {
      onFieldChange({
        name: "handleGoalUpdateValidation",
        value: { data: { [`selectedempError`]: error }, id, update },
      });

      count++;
    }
    let fixedEmployeeIds = employee_ids.filter((emp) => {
      return emp !== null && emp !== undefined;
    });

    if (count === 0) {
      let postObj = {
        id: item.id,
        start_date: item.start_date,
        end_date: item.end_date,
        title: item.title,
        description: item.description,
        select_measure: parseInt(item.select_measure),
        enter_measure: item.enter_measure,
        employee_ids: JSON.stringify(fixedEmployeeIds),
      };
      mangerGoalUpdate({ postObj, index: id });
    }
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <div className="row addgoal">
            <div className="col col-sm-6 col-md-6 col-xl-6 col-lg-6">
              <label className="addgoal-label">{props.label}</label>
            </div>
            <div className="text-right col col-sm-6 col-md-6 col-xl-6 col-lg-6">
              {props.isSelected ? (
                <img alt="checkteam" src={checkedteam} />
              ) : (
                <img alt="uncheckteam" src={uncheckedteam} />
              )}
            </div>
          </div>
        </components.Option>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div class="row">
        <div class="col-md-6 col-sm-6 col-6">
          <div class="start-date form-group">
            <p>
              <input
                placeholder="Start"
                type={startDateType}
                onFocus={() => setStartDateType("date")}
                className="goalcalendar"
                onBlur={() => setStartDateType("text")}
                disabled={disable}
                value={
                  startDateType === "text"
                    ? getTextDate(item.start_date)
                    : getDate(item.start_date)
                }
                name="start_date"
                onChange={(e) => handleChange(e)}
              />
              <img alt="calendar" src={calendar} />
            </p>

            {item.start_dateError ? (
              <p className="text-danger  width-100">{item.start_dateError}</p>
            ) : null}
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-6">
          <div class="end-date form-group">
            <p>
              <input
                type={endDateType}
                onClick={() => setEndDateType("date")}
                onFocus={() => setEndDateType("date")}
                className="goalcalendar"
                onBlur={() => setEndDateType("text")}
                disabled={disable}
                placeholder="EndDate"
                value={
                  endDateType === "text"
                    ? getTextDate(item.end_date)
                    : getDate(item.end_date)
                }
                name="end_date"
                onChange={(e) => handleChange(e)}
              />

              <img alt="calendar" src={calendar} />
            </p>
            {item.end_dateError ? (
              <p className="text-danger  width-100">{item.end_dateError}</p>
            ) : null}
          </div>
        </div>
      </div>
      <div class="form-group ">
        <Select
          isMulti
          isSearchable={true}
          closeMenuOnSelect={false}
          onChange={handleSelectChange}
          options={team}
          value={selectedOption}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "rgb(255, 117, 0)",
              primary: "rgb(255, 117, 0)",
            },
          })}
          hideSelectedOptions={false}
          components={{ Option }}
          isDisabled={disable}
          styles={colourStyles}
          placeholder="Select team member"
        />
        {item.selectedempError ? (
          <p className="text-danger">{item.selectedempError}</p>
        ) : null}
      </div>
      <div class="form-group">
        <input
          type="text"
          value={item.title}
          disabled={disable}
          maxLength="60"
          name="title"
          class="form-control"
          onChange={(e) => handleChange(e)}
          placeholder="Goal Name"
        />
        <span class="textmsg" style={{ textAlign: "right", float: "right" }}>
          {item.title?.length === undefined ? 0 : item.title?.length}/60{" "}
        </span>

        {item.titleError ? (
          <p className="text-danger">{item.titleError}</p>
        ) : null}
      </div>
      <div class="form-group">
        <textarea
          name="description"
          disabled={disable}
          maxLength="250"
          class="form-control"
          onChange={(e) => handleChange(e)}
          value={item.description}
          rows="4"
          placeholder="Write here"
        ></textarea>
        <span class="textmsg  textmsg-addgoal">
          {item.description?.length === undefined
            ? 0
            : item.description?.length}
          /250{" "}
        </span>

        {item.descriptionError ? (
          <p className="text-danger">{item.descriptionError}</p>
        ) : null}
      </div>
      <div class="row">
        <div class="col-md-7">
          <div class="form-group">
            <select
              name="select_measure"
              disabled={disable}
              value={item.select_measure}
              onChange={(e) => handleChange(e)}
            >
              <option value="Select">Select measure</option>
              <option value="1">Amount</option>
              <option value="2">Quantity</option>
            </select>
            {item.select_measureError ? (
              <p className="text-danger">{item.select_measureError}</p>
            ) : null}
          </div>
        </div>
        <div class="col-md-5">
          <div class="form-group">
            <input
              type="number"
              disabled={disable}
              min="0"
              max="9999999999"
              maxLength="10"
              onKeyUp={(e) => onBlur(e)}
              onKeyDown={(e) => onBlur(e)}
              name="enter_measure"
              value={item.enter_measure}
              onChange={(e) => handleChange(e)}
              class="form-control"
              placeholder="Enter here"
            />
            <span class="textmsg   textmsg-addgoal">
              {item.enter_measure?.length === undefined
                ? 0
                : item.enter_measure?.length}
              /10{" "}
            </span>

            {item.enter_measureError ? (
              <p className="text-danger">{item.enter_measureError}</p>
            ) : null}
          </div>
        </div>
        {update && loading && (
          <div className="form-group">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {update && !loading && (
          <div class="form-group" style={{ margin: "0 auto" }}>
            <button class="assign-goal" onClick={() => updateGoal()}>
              Update Goal
            </button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    teamList: state.teamReducer.teamList,
    loading: state.globalReducer.loading,
  };
};

const mapDispatch = (dispatchEvent) => ({
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
  mangerGoalUpdate: (payload) =>
    dispatchEvent({ type: "ManagerGoalUpdate", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(AddGoal);
const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    borderRadius: 25,
  }),
  option: (styles) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: "black",

      ":active": {
        ...styles[":active"],
        backgroundColor: "white",
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "rgb(255, 117, 0)",
      borderRadius: 50,
      color: "white",
    };
  },

  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "white",
  }),
  menu: (provided, state) => ({
    ...provided,

    padding: 20,
    borderRadius: 14,
  }),
};
