import React, { useEffect, useState } from 'react'
import ReactStars from "react-rating-stars-component";

export default function ReactStarsComp(props) {
    const [starKeyForce, setStarKeyForce] = useState(0)

    useEffect(() => {
        setStarKeyForce(prev => prev + 1)
    }, [props.value])
   
    return <ReactStars
        {...props}
        key={starKeyForce} />
}
