
import React, { useState, useEffect } from 'react'
import moment from 'moment';
import { PayPalScriptProvider, PayPalButtons, } from "@paypal/react-paypal-js";
import { POST } from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify'
export default function PayPal({ amount, }) {

    const history = useHistory();
    const initialOptions = {
        "client-id": process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID,
        currency: "USD",
        
    };
    function createOrder(
        data,
        actions
    ) {
        return actions.order
            .create({
                purchase_units: [
                    {
                        amount: {
                            value: amount,
                        },
                    },
                ],
            })
            .then((orderID) => {
                return orderID;
            });
    }

    function onApprove(data, actions) {

        return actions.order.capture().then(async function (details) {
            toast.success(`Plan Updated Successfully`, {
                position: toast.POSITION.BOTTOM_CENTER,
            })

            const data = {
                transaction_id: details.id
            }
            const result = await POST('buyPlan', data);

            if (result.status === 200) {
                localStorage.setItem('free_trial_status', 100)
                localStorage.setItem('subscription_type', 100)

                history.push('/employerdashboard');
            }


        });
    }

    function onError(err) {
        toast.error(`Something went wrong`, {
            position: toast.POSITION.BOTTOM_CENTER,
        })
    }

    return (

        <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
                style={{ layout: "horizontal", tagline: false }}
                createOrder={createOrder}
                onApprove={onApprove}
                onError={onError}
                forceReRender={[amount]}
            />
        </PayPalScriptProvider>
    )
}