import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import banner from "../../assets/images/banner.png";
import { GET, deleteAPI } from "../../utils/helper";
import AttributeItem from "../AttributeItem";

const ManagerGoal = (props) => {
  const [refresh, setRefresh] = useState(false);
  const [loader, setLoader] = useState(false);

  const [attributes, setAttributes] = useState([
    {
      name: "",
      desc: "",
      guidance: "",
    },
  ]);
  useEffect(() => {
    const getData = async () => {
      try {
        setLoader(true);

        let result = await GET(
          props?.loginRole == 1 ? "getAttributes" : "getAttributeList"
        );
        setLoader(false);

        setAttributes(result.body.data.rows);
      } catch (error) {
        setLoader(false);

        console.log(error);
      }
    };

    getData();
  }, [refresh]);

  return (
    <div style={{ minHeight: "80vh" }} class="assign-Goal-Look-wrapper">
      <div class="container">
        <div class="Manager-Goal-Look comman-width-container">
          <div class="banner-img">
            <img class="img-fluid" alt="banner" src={banner} />
          </div>
          <div style={{ marginTop: "2rem" }} class="search-wrapper">
            <div class="assign-Goal-Look comman-width-container">
              {!loader ? (
                attributes.length > 0 ? (
                  attributes?.map((item) => (
                    <AttributeItem
                      key={item?.id}
                      attribute={item}
                      handleRefresh={setRefresh}
                      refresh={refresh}
                    />
                  ))
                ) : (
                  <div className="teammsg" style={{ marginTop: "4rem" }}>
                    No Target Added!
                  </div>
                )
              ) : (
                <div
                  style={{ marginBottom: "4rem" }}
                  class="spinner-border text-primary"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              )}

              <div
                style={{ marginTop: "4rem" }}
                class="More-Goal-assign-buttons"
              >
                <div class="btn-blue">
                  <button
                    class="assign-goal"
                    onClick={() => props.history.push("/addattributes")}
                  >
                    Add Qualitative Target
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    goalData: state.goalReducer.goalData,
    addNewGoal: state.goalReducer.addNewGoal,
    errorMsg: state.goalReducer.errorMsg,
    updatesucessMsg: state.goalReducer.updatesucessMsg,
    loading: state.globalReducer.loading,
    goalReadData: state.goalReducer.goalReadData,
    goallistStatus: state.goalReducer.goallistStatus,
    loginRole: state.loginReducer.loginRole,
  };
};

const mapDispatch = (dispatchEvent) => ({
  getGoalsData: (payload) =>
    dispatchEvent({ type: "GET_GOALDATA", value: payload }),
  getTeamList: () => dispatchEvent({ type: "GET_TEAMLIST" }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
  submitnewGoals: (payload) =>
    dispatchEvent({ type: "SUBMIT_NEWGOALS", value: payload }),
  DeleteManagerPrevGoal: (payload) =>
    dispatchEvent({ type: "DELETEMANAGERGOAL", value: payload }),
  deleteNewGoal: (payload) =>
    dispatchEvent({ type: "DELETENEW_MANAGERGOAL", value: payload }),
  closeSucessModal: () => dispatchEvent({ type: "CLOSE_GOALMODAL" }),
});

export default connect(mapStateToProps, mapDispatch)(ManagerGoal);
