import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { addEmployerValidation, getDate } from "../../utils/validation";
import { useHistory, useParams } from "react-router-dom";
import profileImage from "../../assets/images/profile1.png";
import { POSTIMAGE, GET } from "../../utils/helper";
import { UPLOADFILEAPI } from "../../redux/saga/ApiConstant";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import MultiSelect from "../Common/MultiSelect";
import config from "../../config";

const { LARGE_PLAN_ID, MEDIUM_PLAN_ID, SMALL_PLAN_ID } = config;


const AddEmployee = (props) => {
  const params = useParams();
  const history = useHistory();
  const isCurrentUserManager = props.isManager == 1;
  const currentManagerId = props.employeeId;
  const [emplyrTeamDetailData, setEmplyrTeamDetailData] = useState(null);
  const [emplyrManagerData, setEmplyrManagerData] = useState(null);
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("text");
  const isFresher = true;
  const [rank, setRank] = useState([]);
  const [isManager, setIsManager] = useState(false);
  const [allowedEmployee, setAllowedEmployee] = useState()

  const initialValues = {
    id: params?.id && params.id !== undefined,
    manager_team_icon_url:
      params?.id &&
      emplyrTeamDetailData?.employeeDetails?.groupChatRoom?.icon_image_url,
    manager_id: isCurrentUserManager
      ? []
      : params?.id
        ? emplyrTeamDetailData?.employeeDetails?.manager_team_members?.map(member => member?.manager_id)
        : [],
    manager_team_name:
      params?.id && emplyrTeamDetailData?.employeeDetails?.groupChatRoom?.name,
    first_name: params?.id && emplyrTeamDetailData?.employeeDetails?.first_name,
    last_name: params?.id && emplyrTeamDetailData?.employeeDetails?.last_name,
    email: params?.id && emplyrTeamDetailData?.employeeDetails?.email,
    country_code: params?.id
      ? emplyrTeamDetailData?.employeeDetails?.country_code
      : "1",
    phone_number:
      params?.id && emplyrTeamDetailData?.employeeDetails?.phone_number,
    current_department_id:
      params?.id && emplyrTeamDetailData?.employeeDetails?.department.id,
    employee_rank_id:
      params?.id && emplyrTeamDetailData?.employeeDetails?.employee_rank.id,
    current_date_of_joining:
      params?.id &&
        emplyrTeamDetailData?.employeeDetails?.current_date_of_joining
        ? getDate(
          emplyrTeamDetailData?.employeeDetails?.current_date_of_joining
        )
        : "",
    designation:
      params?.id && emplyrTeamDetailData?.employeeDetails?.current_designation,
  };

  const onSubmit = async (values) => {
    const postObj = {
      id: params?.id,
      is_manager: isManager === true ? 1 : 0,
      manager_team_icon_url: values.manager_team_icon_url,
      manager_team_name: values.manager_team_name,
      manager_id: values.manager_id,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      country_code: values.country_code,
      phone_number: values.phone_number,
      current_department_id: String(values.current_department_id),
      employee_rank_id: String(values.employee_rank_id),
      current_date_of_joining: values.current_date_of_joining,
      current_designation: values.designation,
    };

    if (allowedEmployee) {
      if (!isManager) {
        delete postObj.manager_team_icon_url;
        delete postObj.manager_team_name;
        if (!values.manager_id && !isCurrentUserManager) {
          toast.error("Please select manager", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          return;
        }
      } else {
        delete postObj.manager_id;
      }
      if (isCurrentUserManager) {
        props.addManagerEmployee({ postObj, history: props.history });
      } else {
        props.addEmployrEmploye({ postObj, history: props.history });
      }
    }else{
      toast.error("You have reached your employee limit, please upgrade your plan", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }

  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema: () =>
      addEmployerValidation(
        emplyrManagerData?.length,
        params?.id,
        isManager,
        isFresher,
        isCurrentUserManager
      ),
  });

  useEffect(() => {
    const getData = async () => {
      try {
        if (params?.id) {
          setLoading(true);
          const res = await GET(`viewEmployeeDetails/${params.id}`);
          setLoading(false);
          setEmplyrTeamDetailData(res.body.data);
          const is__manager = res.body.data?.employeeDetails?.is_manager === 0 ? false : true;
          setIsManager(is__manager);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    getData();
  }, [params?.id]);

  useEffect(() => {
    const getData = async () => {
      try {

        let allowedEmployee;
        let noOfCreatedEmployee;
        setLoading(true);
        const employerDetails = await GET(`getProfile`);
        noOfCreatedEmployee = employerDetails && employerDetails?.body?.data?.number_of_employee
        const payment = await GET('paymentStripeDetails')
        let plan = payment.body.data[0].plan
        allowedEmployee = plan == SMALL_PLAN_ID ? 10 : plan == MEDIUM_PLAN_ID ? 25 : plan == LARGE_PLAN_ID ? 50 : null
        if (noOfCreatedEmployee < allowedEmployee) {
          setAllowedEmployee(true)
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    if (isManager) {
      formik.setFieldError("manager_id", null);
      formik.setFieldValue("manager_id", []);
    }
  }, [isManager]);

  useEffect(() => {
    props.getEmployerDepartmentList();

    const getRanks = async () => {
      const result = await GET("getEmployeeRankList");
      setRank(result?.body?.data?.rows);
    };
    const getData = async () => {
      try {
        let res;
        if (params?.id) {
          res = await GET(`getManagerList?id=${params.id}`, 1);
        } else {
          res = await GET(`getManagerList`, 1);
        }

        if (res.status === 402) {
          toast.error(res.message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 5000,
          });
        }

        if (params.id) {
          res = res.body.data.filter((item) => {
            return item.id !== params.id;
          });
          setEmplyrManagerData(res);
          return;
        }

        setEmplyrManagerData(res.body.data);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    getData();
    getRanks();
  }, [formik.values.current_department_id]);

  const showFile = async (event, id, formik) => {
    const bodyFormData = new FormData();

    event.preventDefault();

    if (event.target.files.length < 1) {
      return;
    }

    if (
      event.target.files[0].type !== "image/png" &&
      event.target.files[0].type !== "image/jpeg"
    ) {
      formik.setFieldError(id, "Only jpeg, png images are allowed");
      return;
    }
    bodyFormData.append("file", event.target.files[0]);
    bodyFormData.append("folderName", "employee_profile_pic");

    try {
      setDisable(true);
      const result = await POSTIMAGE(UPLOADFILEAPI, bodyFormData, 1);
      setDisable(false);

      formik.setFieldValue(id, result.body.data);
    } catch (error) {
      formik.setFieldError(id, error);
    }
  };

  return (
    <React.Fragment>
      <section class="Add-employee-wrapper bg-color   min-height-60vh">
        {!loading ? (
          <div class="Add-employee">
            <h3 class="pb-5">
              {params?.id ? "Edit Employee" : "Add Employee"}
            </h3>

            <form onSubmit={formik.handleSubmit}>
              <div>
                <div class="form-group">
                  <label>
                    <h6 className="letter-spacing-0.04px">First Name </h6>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="First name"
                    name="first_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.first_name}
                  />
                  {formik.touched.first_name && formik.errors.first_name ? (
                    <div className="errmsg">{formik.errors.first_name}</div>
                  ) : null}
                </div>
                <div class="form-group">
                  <label>
                    {" "}
                    <h6 className="letter-spacing-0.04px">Last Name </h6>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Last name"
                    name="last_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.last_name}
                  />
                  {formik.touched.last_name && formik.errors.last_name ? (
                    <div className="errmsg">{formik.errors.last_name}</div>
                  ) : null}
                </div>

                <div
                  className="form-group"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>
                    <h6 className="letter-spacing-0.04px">Mobile Number</h6>
                  </label>
                  <div className="row">
                    <div className="col">
                      <PhoneInput
                        dropdownStyle={{ textAlign: "start" }}
                        inputStyle={{ width: "100%" }}
                        name="country_code"
                        country={"us"}
                        placeholder="Enter phone number"
                        onChange={(val, data) => {
                          formik.setFieldValue("country_code", data.dialCode);
                          formik.setFieldValue(
                            "phone_number",
                            val.substring(data.dialCode.length, val.length)
                          );
                        }}
                        value={`${formik.values.country_code}${formik.values.phone_number}`}
                      />
                    </div>
                  </div>
                  {formik.touched.phone_number && formik.errors.phone_number ? (
                    <div className="errmsg">{formik.errors.phone_number}</div>
                  ) : null}
                  {formik.touched.country_code && formik.errors.country_code ? (
                    <div className="errmsg">{formik.errors.country_code}</div>
                  ) : null}
                </div>

                <div class="form-group">
                  <label>
                    {" "}
                    <h6 className="letter-spacing-0.04px">Email Address </h6>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Email address"
                    name="email"
                    autocomplete="new-email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="errmsg">{formik.errors.email}</div>
                  ) : null}
                </div>
                <div class="form-group ">
                  <label>
                    {" "}
                    <h6 className="letter-spacing-0.04px"> Department </h6>
                  </label>
                  <select
                    class="custom-selec-wrap  height-43px"
                    name="current_department_id"
                    onBlur={formik.handleBlur}
                    value={formik.values.current_department_id}
                    onChange={formik.handleChange}
                  >
                    <option value="">Department</option>
                    {props?.emplyrDepartmentData?.map((e) => (
                      <option value={e.id}>{e.name}</option>
                    ))}
                  </select>
                  {formik.touched.current_department_id &&
                    formik.errors.current_department_id ? (
                    <div className="errmsg">
                      {formik.errors.current_department_id}
                    </div>
                  ) : null}
                </div>

                <div class="form-group">
                  <label>
                    {" "}
                    <h6 className="letter-spacing-0.04px">
                      {" "}
                      Current Designation
                    </h6>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Title"
                    name="designation"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.designation}
                  />
                  {formik.touched.designation && formik.errors.designation ? (
                    <div className="errmsg">{formik.errors.designation}</div>
                  ) : null}
                </div>
                <div class="form-group ">
                  <label>
                    {" "}
                    <h6 className="letter-spacing-0.04px">Team Level </h6>
                  </label>
                  <select
                    class="custom-selec-wrap  height-43px"
                    name="employee_rank_id"
                    onBlur={formik.handleBlur}
                    value={formik.values.employee_rank_id}
                    onChange={formik.handleChange}
                  >
                    <option value="">Employee Team Level</option>
                    {rank &&
                      rank.map((e) => <option value={e.id}>{e.name}</option>)}
                  </select>
                  {formik.touched.employee_rank_id &&
                    formik.errors.employee_rank_id ? (
                    <div className="errmsg">
                      {formik.errors.employee_rank_id}
                    </div>
                  ) : null}
                </div>
                <div class="form-group">
                  <label>
                    {" "}
                    <h6 className="letter-spacing-0.04px">Date of Joining </h6>
                  </label>
                  <input
                    type="date"
                    class="form-control"
                    placeholder="Start date"
                    name="current_date_of_joining"
                    onChange={formik.handleChange}
                    max={new Date().toISOString().split("T")[0]}
                    onFocus={() => {
                      setType("date");
                    }}
                    onBlur={(e) => {
                      setType("text");
                      formik.handleBlur(e);
                    }}
                    value={formik?.values?.current_date_of_joining}
                  />
                  {formik.touched.current_date_of_joining &&
                    formik.errors.current_date_of_joining ? (
                    <div className="errmsg">
                      {formik.errors.current_date_of_joining}
                    </div>
                  ) : null}
                </div>

                {!isCurrentUserManager && (
                  <div class="mt-2 mb-4">
                    <div className="text-left">
                      {" "}
                      <h6 className="letter-spacing-0.04px">Manager </h6>
                    </div>

                    <MultiSelect
                      onChange={(selectedOptions) => {
                        formik.setFieldValue('manager_id',
                          selectedOptions ? selectedOptions.map((option) => option.value) : []
                        );
                      }}
                      value={Array.isArray(formik.values.manager_id) ?
                        formik.values.manager_id.map((selectedId) => {
                          const selectedManager = emplyrManagerData?.find((manager) => {
                            return manager.id === selectedId
                          });
                          if (selectedManager) {
                            return {
                              value: selectedManager?.id,
                              label: selectedManager?.name,
                            };
                          }
                        }) : []}
                      options={
                        emplyrManagerData?.map((e) => ({
                          value: e?.id,
                          label: e?.name,
                        }))
                      }
                    />

                    {formik.touched.manager_id && formik.errors.manager_id ? (
                      <div className="errmsg">{formik.errors.manager_id}</div>
                    ) : null}
                  </div>
                )}

                <section
                  style={{
                    marginBottom: "2rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "",
                  }}
                >
                  <div class="custom-control custom-switch">
                    <input
                      className=""
                      style={{ maxHeight: "1.3rem", minWidth: "2rem" }}
                      type="checkbox"
                      autocomplete="off"
                      class=""
                      id=""
                      checked={isManager}
                      onClick={() => {
                        setIsManager(!isManager);
                        formik.setFieldValue(
                          "manager_team_name",
                          `${formik.values?.first_name &&
                            formik.values?.last_name
                            ? formik.values?.first_name +
                            " " +
                            formik.values?.last_name
                            : "GroupName"
                          } - Team`
                        );
                      }}
                    />
                  </div>

                  <p className="font-size">
                    Check this if the employee is also a manager?
                  </p>
                </section>
                {isManager ? (
                  <>
                    <div
                      style={{ display: isManager ? "" : "none" }}
                      class="form-group"
                    >
                      <div class="form-group">
                        <label>
                          {" "}
                          <h6 className="letter-spacing-0.04px">
                            Group chat name{" "}
                          </h6>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Group chat name"
                          name="manager_team_name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.manager_team_name}
                        />
                      </div>
                      {formik.touched.manager_team_name &&
                        formik.errors.manager_team_name ? (
                        <div className="errmsg">
                          {formik.errors.manager_team_name}
                        </div>
                      ) : null}
                    </div>
                    <div class="form-group ">
                      <div
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        class="profile-img"
                      >
                        <input
                          type="file"
                          accept="image/*"
                          multiple={false}
                          onChange={(e) => {
                            const id = "manager_team_icon_url";
                            showFile(e, id, formik);
                          }}
                          id="manager_team_icon_url"
                          name="manager_team_icon_url"
                          hidden
                        />
                        <div className="addemployee-div-input">
                          <label htmlFor="manager_team_icon_url">
                            Upload Group Chat logo
                          </label>

                          <label htmlFor="manager_team_icon_url">
                            <img
                              alt=""
                              src={
                                formik.values.manager_team_icon_url
                                  ? formik.values.manager_team_icon_url
                                  : profileImage
                              }
                              className="managerTeam-img"
                            />
                          </label>
                        </div>
                      </div>
                      {formik.touched.manager_team_icon_url &&
                        formik.errors.manager_team_icon_url ? (
                        <div className="errmsg">
                          {formik.errors.manager_team_icon_url}
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : null}

                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <button
                    onClick={() => {
                      history.goBack();
                    }}
                    style={{ width: "8rem" }}
                    type="button"
                    class="Blue-Tango-button"
                  >
                    Back
                  </button>
                  <button
                    disabled={disable}
                    type="submit"
                    class="Blue-Tango-button  width-8rem"
                  >
                    {params?.id ? "Update" : "Save"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        )}
      </section>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const emplyrTeamDetailData = state.teamReducer.emplyrTeamDetailData;
  const errorMsg = state.teamReducer.errorMsg;
  const totalTeamCount = state.teamReducer.totalTeamCount;
  const loading = state.globalReducer.loading;
  return {
    emplyrTeamDetailData,
    loading,
    errorMsg,
    totalTeamCount,
    isManager: state.loginReducer.loginData?.is_manager,
    employeeId: state.loginReducer.loginData?.id,
    employeeName: state.loginReducer.loginData?.name,
    employerloginData: state.loginReducer.employerloginData,
    emplyrDepartmentData: state.teamReducer.emplyrDepartmentData,
    emplyrManagerData: state.teamReducer.emplyrManagerData,
  };
};

const mapDispatch = (dispatchEvent) => ({
  getEmployerTeamData: (payload) =>
    dispatchEvent({ type: "GET_EMPLOYRTEAMDATA", value: payload }),
  getEmployerDepartmentList: () =>
    dispatchEvent({ type: "GET_EMPLOYERDEPARTMENTLIST" }),
  getEmployerManagrList: () =>
    dispatchEvent({ type: "GET_EMPLOYERMANAGRLIST" }),
  addEmployrEmploye: (payload) =>
    dispatchEvent({ type: "ADDEMPLOYR_EMPLOYE", value: payload }),
  addManagerEmployee: (payload) =>
    dispatchEvent({ type: "ADD_MANAGER_EMPLOYEE", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(AddEmployee);
