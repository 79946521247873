import { useMutation, useQuery } from "@tanstack/react-query";
import instanceAxios from "../config/instance-axios";
import { generateApiEndpointByRole } from "../utils/roles";

export const useTickets = () => {
  const queryKey = ["tickets"];
  return useQuery(
    queryKey,
    async () => {
      const response = await instanceAxios.get(
        generateApiEndpointByRole("ticket")
      );
      return response;
    },
    {
      retry: 2,
    }
  );
};

export const useOpenTicket = () => {
  const queryKey = ["openTicket"];
  return useMutation(
    queryKey,
    async (ticketPayload) => {
      const response = await instanceAxios.post(
        generateApiEndpointByRole("ticket"),
        ticketPayload
      );
      return response;
    },
    {
      retry: 2,
    }
  );
};

export const useTicket = (ticketId) => {
  const queryKey = ["ticket", ticketId];
  return useQuery(
    queryKey,
    async () => {
      const response = await instanceAxios.get(
        generateApiEndpointByRole(`ticket/${ticketId}`)
      );
      return response;
    },
    {
      retry: 2,
    }
  );
};

export const useTicketReplay = (ticketId) => {
  const queryKey = ["ticketReplay", ticketId];
  return useMutation(
    queryKey,
    async (ticketPayload) => {
      const response = await instanceAxios.post(
        generateApiEndpointByRole(`ticket/${ticketId}`),
        ticketPayload
      );
      return response;
    },
    {
      retry: 2,
    }
  );
};
