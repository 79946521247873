import { useEffect, useRef } from 'react';
import Tree from 'react-d3-tree';

const TreeStructure = ({ TreeData }) => {
    const nodeSize = { x: 380, y: 200 };
    const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: -(nodeSize.x /2),y:-(nodeSize.y/3) };

    const Color = {
        "Employer":'#8a784a',
        "Manager":'#b0c836',
        "Employee":'#fcc43b'
    }

    const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
        <g>
            <foreignObject {...foreignObjectProps} onClick={toggleNode}>
                <div style={{ border: "1px solid #ddd",borderRadius:"12px", backgroundColor: Color[nodeDatum.attributes.Role] ,padding:'10px 16px'}}>
                    <h3 style={{color:"#fff",fontSize:"18px"}}>{nodeDatum.name}</h3>
                    <h3 style={{color:"#fff",fontSize:"18px"}}>{nodeDatum.attributes.Email}</h3>
                    <h3 style={{color:"#fff",fontSize:"18px"}}>{nodeDatum.attributes.Role}</h3>
                </div>
            </foreignObject>
        </g>
    );
    return (
        <>
            <div id="treeWrapper" className='rounded border mb-4 bg-grid' style={{ height: '40em' }}>
                {
                    Object.keys(TreeData)?.length > 0 ? <>
                        <Tree zoom={0.5} renderCustomNodeElement={renderRectSvgNode} pathFunc={'step'} orientation='horizontal' data={TreeData} translate={{ x: 300, y: 300 }} nodeSize={{ x: 500, y: 200 }} // Adjust the y-coordinate for spacing
                        />
                    </> : <div className='text-center d-flex align-items-center justify-content-center'>Loading ...</div>
                }
            </div>
        </>
    )
}

export default TreeStructure