export default async function getMedia() {
    let stream = null;

    try {
        stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
       
        if (stream.active) {
            return true;
        } else {
            return false;
        }
    } catch (err) {
        console.log(err)
        return (false);

    }
}