import React, { useRef, useState } from "react";
import Svgs from "../../../assets/Svgs";
import Avatar from "avataaars";
import { AvatarPresets, SkinColors } from "./AvatarData";

const UploadAvatar = ({ setStep, onChange, Close }) => {
  const [SkinColor, setSkinColor] = useState("Light");
  const avatarRef = useRef(null);

  const AvatarSelection = (e) => {
    let parent = e.target.closest(".Avatar_Svg");
    const svgString = new XMLSerializer().serializeToString(parent);
    const blob = new Blob([svgString], { type: "image/svg+xml" });
    onChange([blob]);
    Close(false);
  };

  return (
    <>
      <div
        onClick={() => setStep("SelectStep")}
        className="d-flex align-items-center gap-2 rounded pointer-cursor w-fit"
      >
        <div>
          <Svgs.Back />
        </div>
        <p>Back</p>
      </div>
      <div className="my-5 avatar_parent d-flex align-items-center gap-3 flex-wrap justify-content-center">
        {AvatarPresets.map((item) => {
          return (
            <div onClick={AvatarSelection}>
              <Avatar
                className="Avatar_Svg"
                ref={avatarRef}
                avatarStyle={item.avatarStyle}
                topType={item.topType}
                accessoriesType={item.accessoriesType}
                hairColor={item.hairColor}
                facialHairType={item.facialHairType}
                clotheType={item.clotheType}
                clotheColor={item.clotheColor}
                eyeType={item.eyeType}
                eyebrowType={item.eyebrowType}
                mouthType={item.mouthType}
                skinColor={SkinColor}
              />
            </div>
          );
        })}
      </div>
      <div className="d-flex align-items-center justify-content-center gap-2 flex-wrap">
        {SkinColors.map((item) => {
          return (
            <div
              className={`outer-colorbox ${
                SkinColor == item.name && "selected-box"
              }`}
              style={{
                borderColor:
                  SkinColor == item.name ? item.color_code : "transparent",
              }}
            >
              <div
                className={`colorbox`}
                onClick={() => setSkinColor(item.name)}
                style={{ backgroundColor: item.color_code }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default UploadAvatar;
