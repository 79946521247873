
import { Container, Grid, Card, CardContent, CardActions, Typography, Button, Box } from '@mui/material';
import './styles.scss'
const SubscriptionCard = ({ title, price, features, priceId, handleSubscribeClick }) => {
    const handleSubsClick = () =>{
        handleSubscribeClick(priceId)
    }
    return (
        <Card sx={{ maxWidth: 345, mx: 'auto', my: 2, boxShadow: 3, borderRadius: 2, height: 350, display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h5" component="div" align="center" sx={{ fontWeight: 'bold' }}>
                    {title}
                </Typography>
                <Typography variant="h6" align="center" sx={{ my: 2 }}>
                    ${price}/year
                </Typography>
                <Typography component="div">

                    {features.map((feature, index) => (
                        <div className="feature">
                            <span className="feature-span" >
                            </span>
                            {feature}
                        </div>
                    ))}

                </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
                <Button className="sbt-btn" variant="contained" size="small" color="primary" onClick={handleSubsClick}>
                    Subscribe
                </Button>
            </CardActions>
        </Card>
    );

}

export default SubscriptionCard;