import React from "react";
import { MentionsInput, Mention } from "react-mentions";

import "./style.scss";

export function filterArrayByChildIndex(list, childIndex) {
  let filteredArray = [];
  list.forEach((item) => {
    if (item.childIndex == childIndex) {
      filteredArray.push(item.id);
    }
  });
  return filteredArray;
}

function MentionInput({
  value,
  placeholder,
  onChange,
  mentionKey,
  tagKey,
  mentionsData = null,
  tagsData = null,
  readOnly = false,
  ...rest
}) {
  return (
    <div className="mention-input-container">
      <MentionsInput
        className={`mention-input`}
        value={value}
        onChange={(event, newValue, newPlainTextValue, mentions) => {
          const mentionList = filterArrayByChildIndex(mentions, 0);
          const tagsList = filterArrayByChildIndex(mentions, 1);
          onChange(event.target.value, mentionList, tagsList);
        }}
        placeholder={placeholder}
        allowSpaceInQuery
        a11ySuggestionsListLabel={"Suggested mentions"}
        disabled={readOnly}
        {...rest}
      >
        <Mention
          displayTransform={(_, displayMention) => `@${displayMention}`}
          markup={`@[__display__](${mentionKey}:__id__)`}
          trigger="@"
          data={mentionsData}
          renderSuggestion={(
            suggestion,
            search,
            highlightedDisplay,
            index,
            focused
          ) => (
            <div className={`${mentionKey} ${focused ? "focused" : ""}`}>
              {highlightedDisplay}
            </div>
          )}
          style={{ backgroundColor: "#cee4e5" }}
        />

        <Mention
          displayTransform={(_, displayMention) => `#${displayMention}`}
          markup={`#[__display__](${tagKey}:__id__)`}
          trigger="#"
          data={tagsData}
          renderSuggestion={(
            suggestion,
            search,
            highlightedDisplay,
            index,
            focused
          ) => (
            <div className={`${tagKey} ${focused ? "focused" : ""}`}>
              {highlightedDisplay}
            </div>
          )}
          style={{ backgroundColor: "#cee4e5" }}
        />
      </MentionsInput>
    </div>
  );
}

export default MentionInput;
