import React from "react";

import deleteimg from "../../../../assets/images/delete.png";
import draw from "../../../../assets/images/draw.png";
import workerimg from "../../../../assets/images/profilePlaceholder/profilePlaceholder.png";
import "./style.scss";
import Svgs from "../../../../assets/Svgs/index";

const EmployeeCard = ({
  history,
  employee,
  primary,
  lastUpdate,
  toggleDelete,
  handleStartChat
}) => {
  return (
    <div
      className={`employee-card ${primary ? "primary" : ""}`}
      onClick={() =>
        history.push({
          state: { id: employee.id },
          pathname: "/viewemployeedetail",
        })
      }
    >
      <div className="item-flex-grow">
        <div className="avatar-row">
          <div className="employee-avatar">
            <img
              src={
                employee?.profile_pic_url
                  ? employee?.profile_pic_url
                  : workerimg
              }
              alt=""
              style={{ width: "53px", height: "53px", borderRadius: "31px" }}
            />
            <div className="moji-img">
              {employee?.energy_emoji_data?.image_url && lastUpdate <= 24 ? (
                <img
                  alt=""
                  style={{ width: "18px", height: "18px" }}
                  src={employee?.energy_emoji_data?.image_url}
                />
              ) : null}
            </div>
          </div>
          <p className="employee-name">{employee.name}</p>
        </div>
      </div>

      <div className="item-flex-grow text-white">
        {employee.email.length < 20 ? (
          <p>{employee.email}</p>
        ) : (
          <p>{employee.email.substring(0, 20)}...</p>
        )}

        {employee.employee_rank.name.length < 30 ? (
          <p style={{ fontSize: "0.8rem" }}>{employee.employee_rank.name}</p>
        ) : (
          <p style={{ fontSize: "0.8rem" }}>
            {employee.employee_rank.name.substring(0, 20)}...
          </p>
        )}
      </div>

      <div className="item-flex-grow text-white">
        <p>{employee.country_code + " " + employee.phone_number}</p>
      </div>

      <div className="item-flex-grow">
        <button className="department-name">{employee.department.name}</button>
      </div>

      <div className="item-flex-grow actions">
        <div className="circle-icon" onClick={(e) => {
          e.stopPropagation();
          handleStartChat(employee.id)
        }}>
          <Svgs.Chat />
        </div>
        <img
          alt=""
          src={draw}
          onClick={(e) => {
            e.stopPropagation();
            history.push({
              pathname: `/addemployee/${employee.id}`,
            });
          }}
        />
        <img
          alt=""
          src={deleteimg}
          onClick={(e) => {
            e.stopPropagation();
            toggleDelete(employee.id, employee.is_manager, employee.name);
          }}
        />
      </div>
    </div>
  );
};

export default EmployeeCard;
