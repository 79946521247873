const {
    location: { hostname },
  } = window;
  
  const fileName = hostname.includes("app") && !hostname.includes("dev") ? "prod" : "dev";
  
  let currentConfig;
  
  try {
    currentConfig = require(`./${fileName}`);
  } catch (error) {
    currentConfig = require("./dev");
  }
  
  export default currentConfig.default;
  export const env = fileName;
  