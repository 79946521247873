import * as actions from "../type.action";
const initialState = {
  errorMsg: "",
  sucessMsg: "",
  ratingData: {},
  rateDetailData: {},
  RatingPopupData: [],
};

const ratingReducer = (state, action) => {
  if (state === undefined) {
    return (state = initialState);
  }
  switch (action.type) {
    case actions.ADDMANAGERRATE_DATASUCCESS:
      return {
        ...state,
        ratingData: action.data,
        sucessMsg: action.msg,
      };
    case actions.ADDMANAGERRATE_DATAFAIL:
      return {
        ...state,
        errorMsg: action.msg,
      };
    case actions.RATEDETAIL_DATASUCCESS:
      return {
        ...state,
        rateDetailData: action.data,
      };
    case actions.RATINGPOPUP_DATASUCCESS:
      return {
        ...state,
        RatingPopupData: action.data,
      };

    default:
      return state;
  }
};
export default ratingReducer;
