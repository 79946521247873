import React from "react";
import { isEmptyArray } from "formik";
import { useDispatch, useSelector } from "react-redux";

import "./style.scss";
import { useGetGoals } from "../../hooks/analytics.hook";
import LoadingWidget from "../LoadingWidget";
import ManagerGoalItem from "../ManagerGoalItem";

const GoalsWidget = () => {
  const goalsQuery = useGetGoals();
  const goals = goalsQuery.data?.body?.data?.rows || [];
  const isManager = useSelector(
    (state) => state.loginReducer.loginData.is_manager === 1
  );
  const dispatch = useDispatch();

  const deletePrevGoal = async (id) => {
    if (window.confirm("Are you sure you want to delete this goal")) {
      await dispatch({ type: "DELETEMANAGERGOAL", value: id });
      goalsQuery.refetch()
    }
  };

  return (
    <div className="goals-widget">
      <div className="header">
        <p>Goals</p>
      </div>
      <div className="goals-wrapper">
        {!goalsQuery.isLoading && !isEmptyArray(goals) ? (
          <div className="goals">
            {goals.map((item) => (
              <ManagerGoalItem
                key={item.id}
                goal={item}
                readOnly={!isManager}
                deletePrevGoal={deletePrevGoal}
              />
            ))}
          </div>
        ) : isEmptyArray(goals) && !goalsQuery.isLoading ? (
          <>
            <p className="no-data-found">No data found</p>
          </>
        ) : (
          <LoadingWidget />
        )}
      </div>
    </div>
  );
};

export default GoalsWidget;
