import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { toast } from "react-toastify";

import banner2 from "../assets/images/achievement/banner2.png";
import worker from "../assets/images/profilePlaceholder/profilePlaceholder.png";
import dot from "../assets/images/achievement/dot-image.png";
import send from "../assets/images/achievement/send.png";
import { POST, deleteAPI } from "../utils/helper";
import cross from "../assets/images/cross.png";
import MentionInput from "./MentionInput";
import AchievementItem from "./AchievementItem";
import { isArray } from "lodash";
import {
  useEmployeesForMention,
  useQualitativeTargetForMention,
} from "../hooks/mention.hook";

export default function ShowparticularAchievement() {
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "a few seconds",
      ss: "%d seconds",
      m: "a minute",
      mm: "%d minutes",
      h: "an hour",
      hh: "%d hours",
      d: "a day",
      dd: "%d days",
      w: "a week",
      ww: "%d weeks",
      M: "a month",
      MM: "%d months",
      y: "a year",
      yy: "%d years",
    },
  });
  const loginRole = useSelector((state) => state.loginReducer.loginRole);
  const employees = useEmployeesForMention(loginRole);
  const targets = useQualitativeTargetForMention(loginRole);
  const params = useParams();
  const [inputComment, setInputComment] = useState("");
  const [updatePost, setUpdatePost] = useState({
    createAchievementModal: false,
    createAchievementValue: "",
  });
  const [editComment, setEditComment] = useState({
    isEdit: false,
    value: "",
    commentId: null,
  });

  const dispatch = useDispatch();
  const achievement = useSelector(
    (state) => state.particularAchievementReducer
  );
  const loading = useSelector((state) => state.globalReducer.loading);
  const achievementId = params.id;

  const getParticularAchievement = async (showLoading) => {
    dispatch({
      type: "GET_PARTICULAR_ACHIEVEMENT",
      id: achievementId,
      showLoading,
    });
  };

  useEffect(() => {
    getParticularAchievement(true);
  }, [params.id]);

  const handleComment = async () => {
    let data;
    if (editComment.isEdit) {
      if (editComment.value.trim().length === 0) {
        toast.error("Post is not allowed to be empty", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return;
      }
      data = {
        achievement_id: achievement.id,
        comment: editComment.value,
        achievement_comment_id: editComment.commentId,
        employees_mentions: editComment.employees_mentions,
        qualitative_tags: editComment.qualitative_tags,
      };
    } else {
      if (inputComment?.value?.trim().length === 0) {
        toast.error("Please enter a comment", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return;
      }
      data = {
        achievement_id: achievement.id,
        comment: inputComment.value,
        employees_mentions: inputComment.employees_mentions,
        qualitative_tags: inputComment.qualitative_tags,
      };
    }

    try {
      await POST("addEditCommentAchievement", data);
      if (editComment.isEdit) {
        setEditComment((state) => ({ ...state, value: "", isEdit: false }));
      }
      setInputComment("");
      getParticularAchievement(false);
    } catch (error) {
      console.log(error);
      if (editComment.isEdit) {
        setEditComment((state) => ({ ...state, value: "", isEdit: false }));
      }
      setInputComment("");
    }
  };

  const handlePostUpdate = async () => {
    if (updatePost.createAchievementValue.trim().length === 0) {
      toast.error("Please enter a comment", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    let updateData = {
      achievement_id: achievement.id,
      description: updatePost.createAchievementValue,
    };

    try {
      const result = await POST("createUpdateAchievement", updateData);
      getParticularAchievement(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCommentDelete = async (id) => {
    try {
      const result = await deleteAPI(`deleteAchievementComment/${id}`);
      toast.success("Comment Deleted Successfully", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      getParticularAchievement(false);
    } catch (error) {}
  };

  const handleCommentEdit = async (id, comment) => {
    setEditComment((state) => ({
      ...state,
      isEdit: true,
      commentId: id,
      value: comment,
    }));
  };

  return (
    <div className="Employee-acheivment-wrapper bg-color">
      <Modal
        isOpen={updatePost.createAchievementModal}
        backdrop={false}
        className="thoughtmodal"
      >
        <ModalBody>
          <div className="Acheivement-popup-wrapper post">
            <img
              src={cross}
              className="cross-achievement"
              onClick={() =>
                setUpdatePost((state) => ({
                  ...state,
                  createAchievementModal: false,
                }))
              }
              alt=""
            />

            <div className="Acheivement-popup">
              <div className="worker-img">
                <img alt="" src={worker} />
              </div>
              <textarea
                value={updatePost.createAchievementValue}
                onChange={(e) =>
                  setUpdatePost((state) => {
                    return {
                      ...state,
                      createAchievementValue: e.target.value,
                    };
                  })
                }
                name=""
                id=""
                placeholder="what do you want to talk about ?"
                className="form-control"
              ></textarea>
            </div>
            <button
              onClick={() => {
                handlePostUpdate();
                setUpdatePost((state) => ({
                  ...state,
                  createAchievementModal: false,
                }));
              }}
            >
              Update
            </button>
          </div>
        </ModalBody>
      </Modal>

      <div className="container">
        <div className="Employee-acheivment comman-width-container">
          <div className="banner-img">
            <img className="img-fluid" alt="" src={banner2} />
          </div>

          {!loading && achievement?.id ? (
            <div
              style={{ marginTop: "5rem" }}
              class="acheivment-details wrapper"
            >
              <AchievementItem item={achievement} disableLink={true} />
              {/* comments */}
              <div className="achiment-more-details">
                <div className="edit-comment-container">
                  <div className="comment-input gap-2">
                    <MentionInput
                      value={
                        editComment.isEdit ? editComment.value : inputComment?.value
                      }
                      mentionKey="user"
                      tagKey="target"
                      tagsData={targets?.data?.data || []}
                      mentionsData={employees?.data?.data || []}
                      onChange={(value, mentionList, tagsList) => {
                        if (editComment.isEdit) {
                          setEditComment((state) => ({
                            ...state,
                            value: value,
                            employees_mentions: mentionList,
                            qualitative_tags: tagsList,
                          }));
                        } else {
                          setInputComment({
                            value: value,
                            employees_mentions: mentionList,
                            qualitative_tags: tagsList,
                          });
                        }
                      }}
                      placeholder={
                        editComment.isEdit
                          ? "Edit Comment"
                          : "Leave your thoughts here"
                      }
                    />
                    <img alt="" onClick={() => handleComment()} src={send} />
                  </div>
                  {editComment.isEdit ? (
                    <img
                      className="cross-edit"
                      onClick={() =>
                        setEditComment((state) => ({
                          ...state,
                          isEdit: false,
                          commentId: null,
                          value: "",
                        }))
                      }
                      src={cross}
                      alt=""
                    />
                  ) : null}
                </div>
                <br />
                {isArray(achievement?.comments)
                  ? achievement.comments.map((item) => {
                      moment.updateLocale("en", {
                        relativeTime: {
                          future: "%s",
                          past: "%s",
                          s: "just now",
                          ss: "just now",
                          m: "%dm",
                          mm: "%dm",
                          h: "%dh",
                          hh: "%dh",
                          d: "%dd",
                          dd: "%dd",
                          M: "%d mon",
                          MM: "%d mon",
                          y: "%dy",
                          yy: "%dy",
                        },
                      });

                      let date = moment.utc(item.createdAt).toDate();
                      const commnetdate = moment(date)
                        .utc()
                        .local()
                        .startOf("hh")
                        .fromNow();

                      return (
                        <div
                          key={item.id}
                          className="row no-gutters more-details"
                        >
                          <div className="col-md-12 col-sm-12 col-12">
                            <div className="media">
                              <img
                                src={
                                  item?.employee?.profile_pic_url
                                    ? item.employee.profile_pic_url
                                    : worker
                                }
                                alt=""
                                className="mr-1"
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  borderRadius: "1rem",
                                }}
                              />
                              <div className="media-body">
                                <h5>
                                  {item?.employee?.status === 1
                                    ? item.employee.name
                                    : item?.employee?.status === 2
                                    ? item.employee.name +
                                      " (Previous Employee)"
                                    : item.employee.name + "(Inactive)"}
                                  <div className="float-right">
                                    <div>
                                      <div className="comment-time">
                                        {commnetdate}
                                      </div>
                                      {item.isSelf ? (
                                        <div className="conver-comment">
                                          <div className="dropdown">
                                            <button
                                              type="button"
                                              className="dropdown-toggle"
                                              data-toggle="dropdown"
                                            >
                                              <img src={dot} alt="" />
                                            </button>
                                            <div className="dropdown-menu">
                                              <div
                                                className="dropdown-item"
                                                onClick={() =>
                                                  handleCommentEdit(
                                                    item.id,
                                                    item.comment
                                                  )
                                                }
                                              >
                                                Edit
                                              </div>
                                              <div
                                                className="dropdown-item"
                                                onClick={() =>
                                                  handleCommentDelete(item.id)
                                                }
                                              >
                                                Delete
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </h5>

                                <MentionInput
                                  value={item.comment}
                                  mentionKey="user"
                                  tagKey="target"
                                  readOnly={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          ) : (
            <div
              style={{ marginTop: "5rem", marginBottom: "5rem" }}
              className="spinner-border text-primary"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
