import React, { useState } from "react";
import { toast } from "react-toastify";
import { POST } from "../../utils/helper";
import { useHistory } from "react-router-dom";
export default function Contact() {
  const history = useHistory();
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message) {
      toast.error("Please enter message", {
        position: toast.POSITION.BOTTOM_CENTER,
      });

      return;
    }

    try {
      const result = await POST(`contactUs`, { message }, 1);

      if (result?.data?.body?.data?.status === 1) {
        setMessage("");
        toast.success(
          "Your message has been sent. We will back to you shortly",
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
      }
      history.push("/employerdashboard");
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  return (
    <section
  
      class="Add-employee-wrapper bg-color  contact-textareas"
    >
      <div class="Add-employee">
        <form action="">
          <div class="form-group">
            <label 
            className="contact-ladel"
           
            >Drop us a line</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Write here"
              class="form-control"
              rows="4"
              cols="50"
            ></textarea>
          </div>
          <div onClick={handleSubmit} class="btn-blue">
            <button class="Blue-Tango-button mt-4">Submit</button>
          </div>
        </form>
      </div>
    </section>
  );
}
