import * as yup from 'yup';
import moment from 'moment';
export const slotvalidation = () => {
    return yup.lazy(values => {

        return yup.object({

            session_duration: yup.number().required("Select a session duration").test(
                "Select a session duration",
                "Select a session duration",
              
                (value) => {
                    
                    if (value != 0) {
             
                        return true;
                    }
                    else{
                        return true;
                    }
                    
                }
            ),
            day: yup.array().required("Select at least one session frequency").test(
                "Select at least one session frequency",
                "Select at least one session frequency",
                (value) => {
                    
                    if (value.length>=1) {
                       
                        return true
                    }
                    else {
                        return false
                    }
                }
            ),
            time_capture_type: yup.number().required("Select a definition type").test(
                "Select a definition type",
                "Select a definition type",
                (value) => {
                    if (value===1 || value===2) {
                        return true
                    }
                    else {
                        return false
                    }

                }
            ),
            custom_date: yup.date().test(
                "End date should be equal or greater than start date",
                "End date should be equal or greater than start date",
                (value) => {
                    if (values.date <= values.custom_date) {
                        return true
                    }
                    else {
                        return false
                    }

                }
            ),
            timings: yup.array().of(
                yup.object().shape({
                    start_time: yup.string().required('Start time is required')
                        .test(
                            "Start time is required",
                            "Times overlap with another sets of time",
                            (value, ctx) => {
                                let index = ctx.options.index;
                                if (index === 0) {
                                    return true;
                                }
                                else {
                                    let prevIndex = index - 1;
                                    return (moment(value, 'h:mm a').isSameOrAfter(moment(values.timings[prevIndex].end_time, 'h:mm a')))

                                }
                            }
                        ),
                    end_time: yup.string().required('End time is required')
                        .test(
                            "End time is required",
                            "Select valid time according to choosen session duration",
                            (value, ctx) => {
                                var ms = moment(ctx.parent.end_time, 'h:mm a').diff(moment(ctx.parent.start_time, 'h:mm a'), "minutes");


                                if (ms % values.session_duration == 0) {

                                    return moment(ctx.parent.start_time, 'h:mm a').isBefore(moment(value, 'h:mm a'));
                                }
                                else {

                                    return false;
                                }
                            
                            }
                        ),
                })
            )


        })
    })
}