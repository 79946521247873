import React, { useState, useEffect } from 'react'
import happymoji from "../../assets/images/happy-moji.png"
import smiling from "../../assets/images/smiling.png"
import heart from "../../assets/images/heart.png"
import like from "../../assets/images/like.png"
import haifai from "../../assets/images/hai-fai.png"
import signdown from "../../assets/images/sign-down.png"
import mojiwillopen from "../../assets/images/moji-will-open.png"
import hend from "../../assets/images/hend-moji.png"
import profile1 from "../../assets/images/profile1.png";

export default function RenderEmoji({ chatdata, setMsgReaction, data, openEmoji, newkey, getTimeFromDate, Avatar, j }) {

    const [reactionId, setReactionId] = useState(data.reactionId);


  
    return (
        <div class="incoming_msg-wrap">
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }} class="incoming_msg">
                <div class="incoming_msg_img">

                    {chatdata?.other_user?.profile_pic_url ? <img src={chatdata?.other_user?.profile_pic_url ? chatdata?.other_user?.profile_pic_url : profile1} alt="" /> : <Avatar color={Avatar.getRandomColor('sitebase', ['red', 'green', 'blue'])} name={chatdata.other_user.name} size="36" round={true} />}

                </div>
                <div style={{ minHeight: '62px' }} class="received_msg">
                    <span style={{ marginBottom: '0.2rem', fontSize: '0.6rem' }} class="time_date"> {`${data?.user?.name}, ` + " " + `  ${getTimeFromDate(data.createdAt)}`}</span>

                    <div class="received_withd_msg">


                        <p>{data.text}
                            {(reactionId !== 0) && <img class="moji-wrap"
                                src={reactionId === 2 ? hend : reactionId === 5 ? happymoji : reactionId === 4 ? smiling : reactionId === 3 ? heart : reactionId === 1 ? haifai : haifai} alt='emoji' />}
                            <span class="mojis-will-open" onClick={(e) => { openEmoji(e, data, newkey, j) }}>
                                <img src={mojiwillopen} alt="" />
                            </span>
                        </p>
               

                    </div>
                </div>
            </div>
            {data?.showemoji && <div class="mojis-img-wrapper">


                <img src={haifai} alt="" onClick={() => {

                    setReactionId(1)
                    setMsgReaction(1, data, newkey, j)

                }} />
                <img src={like} alt="" onClick={() => {
                    setReactionId(2)

                    setMsgReaction(2, data, newkey, j)
                }} />
                <img src={heart} alt="" onClick={() => {
                    setReactionId(3)
                    setMsgReaction(3, data, newkey, j)
                }} />
                <img src={smiling} alt="" onClick={() => {
                    setReactionId(4)
                    setMsgReaction(4, data, newkey, j)
                }} />
                <img src={happymoji} alt="" onClick={() => {
                    setReactionId(5)
                    setMsgReaction(5, data, newkey, j)
                }} />
                <div class="sign-down">
                    <img src={signdown} alt="" />
                </div>
            </div>}

        </div>
    )
}
