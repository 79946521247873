import React from "react";

import "./style.scss";
import MentionInput from "../../../MentionInput";

const AccomplishmentItem = ({ accomplishment }) => {
  return (
    <div className="accomplishment-item">
      <p className="name">-{accomplishment.employee.name}</p>
      <MentionInput
        value={accomplishment.description}
        mentionKey="user"
        tagKey="target"
        readOnly={true}
      />
    </div>
  );
};

export default AccomplishmentItem;
