import React from "react";
import _ from "lodash";
import Avatar from "react-avatar";
import send from "../assets/images/send.png";
import { connect, useSelector } from "react-redux";
import ScrollToBottom from "react-scroll-to-bottom";
import profile1 from "../assets/images/profile1.png";
import "react-toastify/dist/ReactToastify.css";
import SenderMessageComp from "../Components/Common/RenderEmoji";
import moment from "moment";
import EmployerChatHelper from "./EmployerChat/helper";
import Loader from "./Common/Loader";
import getTimeFromDate from "./Common/getTimeFromDate";
import {
  createChatRoom,
  onFieldChange,
  hitSendChatNotiApi,
  notificationCount,
} from "../redux/action/chatActions"


const ChatDetail = (props) => {
  
  const { closeEmoji, openEmoji, setMsgReaction, submitMessage, chatdata, chats, loading, newchat, setNewchat, name, reactions } = EmployerChatHelper(props);

  const renderChat = () => {
    return chats.map((e, i) =>
    Object.keys(e).map(function (key, index) {
        return (
          <div>
            <div className="position-relative">
              <div class="msg-day">
                <p>
                  {key === moment().format("MM/DD/YYYY")
                    ? "Today"
                    : key+""}
                </p>
              </div>
            </div>
            {e[key].map((data, j) => {
              var convertedTimestamp = new Date(
                props.chatRoomdata.chatLastDeletedOn
              );

              return data.timestamp >= Number(convertedTimestamp.getTime()) ? (
                data?.system ? (
                  <div>
                    <div className="position-relative" class="msg-day">
                      <div
                        className="time-stamp_"
                      >
                        {getTimeFromDate(data.createdAt)}
                      </div>
                      <span>{data.text}</span>
                    </div>
                  </div>
                ) : (
                  <>
                    {Number(data?.user?._id) === Number(chatdata?.user?.id) ? (
                      <div class="outgoing_msg">
                        {chatdata?.user?.profile_pic_url ? (
                          <img
                            className="mt-3"
                            src={
                              chatdata?.user?.profile_pic_url
                                ? chatdata?.user?.profile_pic_url
                                : profile1
                            }
                            alt=""
                          />
                        ) : (
                          <Avatar
                            className="mt-3"
                            color={Avatar.getRandomColor("sitebase", [
                              "red",
                              "green",
                              "blue",
                            ])}
                            name={name}
                            size="36"
                            round={true}
                          />
                        )}
                        <div class="sent_msg h-62px">
                          <span
                            class="time_date time_date_"
                          >
                            {" "}
                            {getTimeFromDate(data.createdAt)}
                          </span>

                          <p className="word-break">
                            {data.text}
                            {data.reactionId !== 0 && (
                              <img
                                class="moji-wrap"
                                src={reactions[data.reactionId] || reactions.default}
                                alt="emoji"
                              />
                            )}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <SenderMessageComp
                        chatdata={chatdata}
                        setMsgReaction={setMsgReaction}
                        data={data}
                        openEmoji={openEmoji}
                        newkey={key}
                        getTimeFromDate={getTimeFromDate}
                        Avatar={Avatar}
                        j={j}
                      />
                    )}
                  </>
                )
              ) : null;
            })}
          </div>
        );
      })
    );
  };
  return (
    <React.Fragment>
      {(props.loading || loading) && (
        Loader
      )}
      <section class="chat-details-wrapper" onClick={(e) => closeEmoji(e)}>
        <div class="message-chat-details bg-color">
          <div class="container">
            <div class="messaging comman-width-container">
              <div class="inbox_msg">
                <div class="mesgs">
                  <div class="msg_history">
                    <ScrollToBottom className="msgsc" mode="bottom">
                      {renderChat()}
                    </ScrollToBottom>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!props?.location?.state?.is_disabled && (
          <div class="container">
            <div class="type_msg comman-width-container">
              <div class="input_msg_write">
                <form onSubmit={submitMessage}>
                  <input
                    type="text"
                    class="write_msg"
                    placeholder="Type a message"
                    value={newchat}
                    onChange={(e) => setNewchat(e.target.value)}
                  />
                  <div class="send-icon" onClick={(e) => submitMessage(e)}>
                    <button class="msg_send_btn" type="button">
                      <img alt="" src={send} />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </section>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    chatRoomdata: state.chatReducer.chatRoomdata,
    loading: state.globalReducer.loading,
  };
};
const mapDispatch = (dispatchEvent) => ({
  createChatRoom,
  onFieldChange,
  hitSendChatNotiApi,
  notificationCount,
});

export default connect(mapStateToProps, mapDispatch)(ChatDetail);
