import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import {
  CFormGroup,
  CFormText,
  CInput,
  CLabel,
  CSelect,
  CTextarea,
} from "@coreui/react";
import { useSelector } from "react-redux";

import "./style.scss";
import "../../../../scss/customforall.scss";
import uncheckedteam from "../../../../assets/images/uncheckedteam.png";
import checkedteam from "../../../../assets/images/checkedteam.png";
import { useManagerTeam } from "../../../../hooks/team.hook";
import AssignMeasureItem from "../AssignMeasureItem";

const AddEditGoalForm = (props) => {
  const { goalItem, index, formik, onClickExpand, onClickDelete } = props;
  const loginRole = useSelector((state) => state.loginReducer.loginRole);
  const managerTeamQuery = useManagerTeam(loginRole);
  const [team, setTeam] = useState();

  useEffect(() => {
    if (managerTeamQuery.isSuccess) {
      const options = [];
      managerTeamQuery.data?.body?.data?.rows?.map((e) =>
        options.push({
          value: e.employee.id,
          label: e.employee.name,
          employee_id: e.employee.id,
          measure: 10,
        })
      );
      setTeam(options);
    }
  }, [managerTeamQuery.isSuccess]);

  const handleSelectChange = (selectedOption) => {
    const isSelectAll = selectedOption.find((item) => item.value === "all");
    let selectedTeam = null;
    if (isSelectAll) {
      selectedTeam = team?.filter((item) => item.value !== "all");
    } else {
      selectedTeam = selectedOption;
    }
    formik.setFieldValue(`goals[${index}].employees_measures`, selectedTeam);
  };

  return (
    <div className="add-edit-goal">
      <div className="goal-header" onClick={() => onClickExpand(index)}>
        <p className="goal-name">Goal {index + 1}</p>
        <div className="actions">
          <i
            className="fa fa-trash"
            aria-hidden="true"
            onClick={(e) => {
              e.stopPropagation();
              onClickDelete(index);
            }}
          ></i>
          <i className="fa fa-angle-down" aria-hidden="true"></i>
        </div>
      </div>
      {goalItem?.isOpen ? (
        <>
          <div className="goal-date">
            <CFormGroup>
              <CLabel htmlFor={`goals[${index}].start_date`}>Start Date</CLabel>
              <CInput
                type="date"
                id={`goals[${index}].start_date`}
                name={`goals[${index}].start_date`}
                placeholder="Start Date"
                value={formik.values.goals[index].start_date}
                onChange={formik.handleChange}
              />
              {formik.touched?.goals?.[index]?.start_date &&
              formik.errors?.goals?.[index]?.start_date ? (
                <div className="errmsg">
                  {formik.errors.goals[index].start_date}
                </div>
              ) : null}
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor={`goals[${index}].end_date`}>End Date</CLabel>
              <CInput
                type="date"
                id={`goals[${index}].end_date`}
                name={`goals[${index}].end_date`}
                placeholder="End Date"
                value={formik.values.goals[index].end_date}
                onChange={formik.handleChange}
              />
              {formik.touched?.goals?.[index]?.end_date &&
              formik.errors?.goals?.[index]?.end_date ? (
                <div className="errmsg">
                  {formik.errors.goals[index].end_date}
                </div>
              ) : null}
            </CFormGroup>
          </div>

          <div className="select-members">
            <CLabel htmlFor={`goals[${index}].employees_measures`}>
              Goal Members
            </CLabel>
            <CFormGroup>
              <Select
                isMulti
                isSearchable={true}
                closeMenuOnSelect={false}
                name={`goals[${index}].employees_measures`}
                onChange={handleSelectChange}
                options={team}
                value={formik.values.goals[index].employees_measures}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "rgb(255, 117, 0)",
                    primary: "rgb(255, 117, 0)",
                  },
                })}
                hideSelectedOptions={false}
                components={{ Option }}
                isDisabled={goalItem?.disable}
                styles={colorStyles}
                placeholder="Select team member"
              />
              {formik.touched?.goals?.[index]?.employees_measures &&
              formik.errors?.goals?.[index]?.employees_measures ? (
                <div className="errmsg">
                  {formik.errors.goals[index].employees_measures}
                </div>
              ) : null}
            </CFormGroup>
          </div>

          <CFormGroup>
            <CLabel htmlFor={`goals[${index}].title`}>Goal Name</CLabel>
            <CInput
              type="text"
              id={`goals[${index}].title`}
              name={`goals[${index}].title`}
              placeholder="Goal Name"
              value={formik.values.goals[index].title}
              onChange={formik.handleChange}
            />
            <CFormText className="help-block">
              {formik.values.goals[index].title?.length}/60
            </CFormText>
            {formik.touched?.goals?.[index]?.title &&
            formik.errors?.goals?.[index]?.title ? (
              <div className="errmsg">{formik.errors.goals[index].title}</div>
            ) : null}
          </CFormGroup>

          <CFormGroup>
            <CLabel htmlFor={`goals[${index}].description`}>
              Goal Description
            </CLabel>
            <CTextarea
              type="text"
              id={`goals[${index}].description`}
              name={`goals[${index}].description`}
              placeholder="Write here"
              rows="4"
              value={formik.values.goals[index].description}
              onChange={formik.handleChange}
            />
            <CFormText className="help-block">
              {formik.values.goals[index].description?.length === undefined
                ? 0
                : formik.values.goals[index].description?.length}
              /250
            </CFormText>
            {formik.touched?.goals?.[index]?.description &&
            formik.errors?.goals?.[index]?.description ? (
              <div className="errmsg">
                {formik.errors.goals[index].description}
              </div>
            ) : null}
          </CFormGroup>

          <CFormGroup>
            <CLabel htmlFor={`goals[${index}].select_measure`}>
              Measure Type
            </CLabel>
            <CSelect
              style={{ height: "50px" }}
              id={`goals[${index}].select_measure`}
              name={`goals[${index}].select_measure`}
              onChange={formik.handleChange}
              value={formik.values.goals[index].select_measure}
            >
              <option value="">Select measure</option>
              <option value={1}>Quantity</option>
              <option value={2}>$ USD</option>
              <option value={3}>Percentage</option>
            </CSelect>

            {formik.touched?.goals?.[index]?.select_measure &&
            formik.errors?.goals?.[index]?.select_measure ? (
              <div className="errmsg">
                {formik.errors.goals[index].select_measure}
              </div>
            ) : null}
          </CFormGroup>

          <div className="assigned-employees-measure">
            {formik.values.goals?.[index]?.employees_measures?.length > 0 ? (
              <CLabel htmlFor={`goals[${index}].employees_measures`}>
                Members Measure
              </CLabel>
            ) : null}
            {formik.values.goals?.[index]?.employees_measures?.map(
              (item, measureIndex) => (
                <AssignMeasureItem
                  key={measureIndex}
                  assignMeasure={item}
                  formik={formik}
                  goalIndex={index}
                  measureIndex={measureIndex}
                />
              )
            )}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default AddEditGoalForm;

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="row addgoal">
          <div className="col col-sm-6 col-md-6 col-xl-6 col-lg-6">
            <label className="addgoal-label">{props.label}</label>
          </div>
          <div className="text-right col col-sm-6 col-md-6 col-xl-6 col-lg-6">
            {props.isSelected ? (
              <img alt="checkteam" src={checkedteam} />
            ) : (
              <img alt="uncheckteam" src={uncheckedteam} />
            )}
          </div>
        </div>
      </components.Option>
    </div>
  );
};

const colorStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    borderRadius: 15,
  }),
  option: (styles) => {
    return {
      ...styles,
      backgroundColor: "white",
      color: "black",

      ":active": {
        ...styles[":active"],
        backgroundColor: "white",
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "rgb(255, 117, 0)",
      borderRadius: 50,
      color: "white",
    };
  },

  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "white",
  }),
  menu: (provided, state) => ({
    ...provided,

    padding: 20,
    borderRadius: 14,
  }),
};
