import React, { Component, useState, useEffect } from "react";
import { Prompt } from "react-router";
import { connect } from "react-redux";
import profile from "../../assets/images/my-profile.png";
import camera from "../../assets/images/camera.png";
import download from "../../assets/images/download.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import profile1 from "../../assets/images/profile1.png";
import { getDate } from "../../utils/validation";
import { useFormik } from "formik";
import { POSTIMAGE, GET, putAPI } from "../../utils/helper";
import { UPLOADFILEAPI } from "../../redux/saga/ApiConstant";
import { employerProfileValidation } from "../../utils/validation";
import { useHistory } from "react-router-dom";
import ProfilePicture from "../UserProfileAvatar/ProfilePicture";

const MyProfile = (props) => {
  const [msg, showMsg] = useState(false);
  const [disable, setDisable] = useState(false);
  const history = useHistory();

  const [showLastEmployer, setLastEmployer] = useState(false);
  const [edit, setEdit] = useState(true);
  const [dateError, setDateError] = useState("");
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    profile_pic_url: profileData?.profile_pic_url || null,
    name: profileData?.name || null,
    email: profileData?.email || null,
    country_code: profileData?.country_code || null,
    phone_number: profileData?.phone_number || null,
    address: profileData?.address || null,
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const res = await GET(`getProfile`, 1);

        setLoading(false);
        setProfileData(res.body.data);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    getData();
  }, []);

  const onSubmit = async (values) => {
    const data = {
      profile_pic_url: values.profile_pic_url,
      name: values.name,
      email: values.email,
      country_code: values.country_code,
      phone_number: values.phone_number,
      address: values.address,
    };

    if (!data.profile_pic_url) {
      delete data.profile_pic_url;
    }

    try {
      setLoading(true);
      const result = await putAPI(`editProfile`, data, 1);
      setLoading(false);
      history.push("/employerProfile");

      toast.success("Profile Updated Successfully", {
        position: toast.POSITION.BOTTOM_CENTER,
        hideProgressBar: true,
      });
    } catch (error) {
      setLoading(false);

      console.log(error);
      toast.error("Something Went wrong", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema: employerProfileValidation,
  });

  const showFile = async (data, id="profile_pic_url") => {
    const bodyFormData = new FormData();

    if (data.length < 1) {
      return;
    }

    if (
      data[0].type !== "image/png" &&
      data[0].type !== "image/jpeg" &&
      data[0].type !== "image/svg+xml"
    ) {
      formik.setFieldError(id, "Only jpeg, png images are allowed");
      return;
    }
    bodyFormData.append("file", data[0]);
    bodyFormData.append("folderName", "employee_profile_pic");

    try {
      setDisable(true);
      const result = await POSTIMAGE(UPLOADFILEAPI, bodyFormData, 1);
      setDisable(false);

      formik.setFieldValue(id, result.body.data);
    } catch (error) {
      console.log(error);
      formik.setFieldError(id, error);
    }
  };

  return (
    <React.Fragment>
      <section class="My-Profile-wrapper bg-color">
        <div class="My-Profile">
          <h2>My Profile</h2>
            <ProfilePicture
              edit={true}
              picture={formik?.values?.profile_pic_url || profile1}
              onChange={showFile} 
            />
          <form onSubmit={formik.handleSubmit}>
            <div class="form-group">
              <label 
              className="font-Size-10px"
            
               >Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Your name"
                name="name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.errors.name ? (
                <div className="errmsg">{formik.errors.name}</div>
              ) : null}
            </div>

            <div class="form-group">
              <label 
                 className="font-Size-10px"
            
              > Address</label>

              <textarea
                placeholder="Write here"
                class="form-control"
                rows="4"
                cols="50"
                name="address"
                value={formik.values.address}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              ></textarea>
              {formik.errors.address && formik.touched.address ? (
                <div className="errmsg">{formik.errors.address}</div>
              ) : null}
            </div>

            <div class="row">
              <div class="col-3">
                <div class="form-group">
                  <label 
                  className="font-Size-10px"
                
                  >Code</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="+91"
                    maxLength="4"
                    name="country_code"
                    value={formik.values.country_code}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div class="col-9">
                <div class="form-group">
                  <label 
                  className="font-Size-10px"
                
                  >Mobile No.</label>
                  <input
                    type="text"
                    maxLength="10"
                    class="form-control"
                    placeholder="Phone number"
                    name="phone_number"
                    value={formik.values.phone_number}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.phone_number && formik.errors.phone_number ? (
                    <div className="errmsg">{formik.errors.phone_number}</div>
                  ) : null}
                  {formik.touched.phone_number && formik.errors.country_code ? (
                    <div className="errmsg">{formik.errors.country_code}</div>
                  ) : null}
                </div>
              </div>
            </div>

            <div class="form-group">
              <label 
              className="font-Size-10px"
            
               >Email</label>
              <input
                type="text"
                class="form-control"
                placeholder="Email address"
                name="email"
                disabled
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.errors.email ? (
                <div className="errmsg">{formik.errors.email}</div>
              ) : null}
            </div>

            <div
            
              class="upadte-edit-btn  update-profile-pic"
            >
              {loading ? (
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                <button type="submit" class="Blue-Tango-button">
                  Save
                </button>
              )}
            </div>
          </form>
        </div>
      </section>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const profileData = state.profileReducer.profileData;
  const errorMsg = state.profileReducer.errorMsg;
  const imageError = state.profileReducer.imageError;
  const readProfileData = state.profileReducer.readProfileData;
  const loading = state.globalReducer.loading;
  const sucessMsg = state.profileReducer.sucessMsg;
  return {
    profileData,
    loading,
    errorMsg,
    imageError,
    readProfileData,
    sucessMsg,
  };
};

const mapDispatch = (dispatchEvent) => ({
  getProfileData: () => dispatchEvent({ type: "GET_PROFILEDATA" }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
  uploadProfile: (payload) =>
    dispatchEvent({ type: "UPLOAD_PROFILEPIC", value: payload }),
  updateProfileData: (payload) =>
    dispatchEvent({ type: "UPDATEPROFILEDATA", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(MyProfile);
