import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import pro from "../../assets/images/profilePlaceholder/profilePlaceholder.png";
import { GET, putAPI } from "../../utils/helper";
import ChatsMsg from "../ChatsMsg";
import { useHistory } from "react-router-dom";
import Modal from "../Common/Modal";
import moment from "moment";
import "../.././scss/customforall.scss";

export default function MySessions() {
  let dateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const history = useHistory();
  const profile_pic_url = useSelector(
    (state) => state.loginReducer.coachloginData.image
  );
  const [reason, setReason] = useState({
    isOpen: false,
    data: "",
    id: null,
  });
  const dispatch = useDispatch();

  const getData = async () => {
    try {
      setLoading(true);
      const result = await GET(`getAcceptedSessions?datetime=${dateTime}`);
      setLoading(false);
      if (result.status === 200) {
        setSessions(result?.body?.data?.rows);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, [refresh]);

  const openChat = async (data) => {
    const name = localStorage.getItem("name");
    const userId = localStorage.getItem("id");

    let user = {
      ...data.user,
    };

    let newdata = {
      id: data.id,
      user: {
        name: name,
        id: userId,
        profile_pic_url: profile_pic_url,
      },
      room_id: data.room_id,
      status: 1,
      other_user: user,
      chatLastDeletedOn: data.info[1].chatLastDeletedOn,
    };
    dispatch({ type: "SET_CHAT", payload: newdata });
    await new ChatsMsg().createChatNode(data.room_id, [
      { id: userId, name: name },
      user,
    ]);

    setTimeout(() => {
      history.push({
        pathname: "/coachchatdetail",
        state: {
          title: "",
          id: data?.id,
          room_id: data.room_id,
          is_disabled: false,
        },
      });
    }, 1000);
  };

  const rejectionModal = (id) => {
    setReason({ id, isOpen: true, data: "" });
  };

  const handleRejection = async () => {
    console.log(reason.data);

    if (!reason.data.trim()) {
      toast.error("Enter reason", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    try {
      const res = await putAPI("cancelSession", {
        session_id: reason.id,
        cancel_reason: reason.data,
      });
      if (res.status === 200) {
        setReason((state) => ({ ...state, isOpen: false, data: "", id: null }));
        setRefresh(!refresh);
        toast.success("Session Rejected", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } catch (error) {
      setReason((state) => ({ ...state, isOpen: false, data: "", id: null }));
      console.log(error.response);
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const handleAccept = async (res) => {
    try {
      window.open(res.details.start_url, "_blank");
    } catch (error) {
      console.log(error);
    }
  };



  const renderSessions = (session, index) => {
    let isLarger = new Date(`${session.date} ${session.end_time}`) < new Date();
    return (
      <div key={index} class="profiles-wrapper">
        <div class="profile-comman-box">
          <div
            class="row"
            style={{ textAlign: "center", justifyContent: "center" }}
          >
            <div class="col-md-12 comman-width one">
              <div
                class="main-profiles"
                style={{ maxWidth: "558px", margin: "0 auto" }}
              >
                <div class="profile-img">
                  <img
                    className="imgprofile"
                    src={session?.employee?.profile_pic_url || pro}
                    alt=""
                  />
                </div>
                <div class="row no-gutters">
                  <div className="data-main-div ">
                    <div className="ladelstyle">
                      <p>Team Level :</p>
                      <p className="labelstyless">
                        <p>{session?.employee_rank?.name}</p>
                      </p>
                    </div>

                    <div className="ladelstyle">
                      <p>Performance Challenge :</p>
                      <p className="labelstyless">
                        <p>{session?.coach_specialization_category.name}</p>
                      </p>
                    </div>

                    <div className="ladelstyle">
                      <p>Challenge Details :</p>
                      <p className="labelstyless">
                        <p>{session?.query}</p>{" "}
                      </p>
                    </div>
                    <div className="ladelstyle">
                      <p>Session Date :</p>
                      <p className="labelstyless">
                        <p>{moment(session?.date).format("Do MMM YYYY")}</p>{" "}
                      </p>
                    </div>
                    <div className="ladelstyle">
                      <p>Session Time :</p>
                      <p className="labelstyless">
                        <p>
                          {session
                            ? moment(session.start_time.slice(0, 5), [
                                "HH.mm",
                              ]).format("hh:mm a")
                            : ""}{" "}
                          -{" "}
                          {session
                            ? moment(session.end_time.slice(0, 5), [
                                "HH.mm",
                              ]).format("hh:mm a")
                            : ""}
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="editbutton">
                  <button
                    disabled={isLarger}
                    onClick={() => handleAccept(session)}
                    style={{
                      width: "30%",
                      backgroundColor: !isLarger ? "green" : "grey",
                    }}
                  >
                    Start
                  </button>

                  <button
                    disabled={isLarger || !session.chatRoom}
                    onClick={() => openChat(session.chatRoom)}
                    style={{
                      width: "30%",
                      backgroundColor:
                        session.chatRoom && !isLarger ? "" : "grey",
                    }}
                  >
                    Chat
                  </button>
                  <button
                    onClick={() => rejectionModal(session.id)}
                    style={{
                      width: "30%",
                      backgroundColor: !isLarger ? "#0D86FF" : "grey",
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section style={{ minHeight: "80vh" }} class="profiles-wrapper-main">
      <div class="container">
        <h3 style={{ textAlign: "center", justifyContent: "center" }}>
          My Sessions
        </h3>
        {!loading ? (
          sessions.length > 0 ? (
            sessions.map((session, index) => {
              return renderSessions(session, index);
            })
          ) : (
            <h3
              style={{
                textAlign: "center",
                marginTop: "4rem",
                color: "#FF6932",
              }}
            >
              No Sessions Found
            </h3>
          )
        ) : (
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        )}
      </div>
      <Modal
        showmodal={reason.isOpen}
        onCLose={() => {
          setReason((state) => ({
            ...state,
            isOpen: false,
            data: "",
            id: null,
          }));
        }}
        handleClick={handleRejection}
        title={"Cancel the Session"}
        body={
          <div>
            <label>Enter the reason for cancelling the session</label>
            <textarea
              style={{ width: "100%", backgroundColor: "white" }}
              maxLength="200"
              class="form-control"
              onChange={(e) =>
                setReason((state) => ({ ...state, data: e.target.value }))
              }
              value={reason.data}
              rows="4"
              placeholder="Write here"
            ></textarea>
          </div>
        }
      />
    </section>
  );
}
