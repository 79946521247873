import firebase from "firebase";
import ChatsMsg from "../Components/ChatsMsg";
import { GET } from "./helper";
import store from "../store";

const setChatTitle = async (props, data, id) => {
  const managerId = localStorage.getItem("manager_team_member_id");

  const res = await GET(`getChatRoomId?other_user_id=${managerId}&type=0`);

  const name = localStorage.getItem("name");
  const userId = localStorage.getItem("id");
  let label;
  props.onFieldChange({ name: "SETCHATTITLE", value: { data, id } });
  if (data?.team_goal) {
    label = data?.team_goal?.title;
  } else if (data?.label) {
    label = data?.label;
  } else {
    label = data?.name;
  }

  store.dispatch({ type: "SET_CHAT", payload: res.body.data });

  new ChatsMsg().createChatNode(res.body.data.room_id, [
    { id: userId, name: name },
    res.body.data.other_user,
  ]);
  firebase
    .firestore()
    .collection("chats_dev")
    .doc(res.body.data.room_id)
    .collection("messages")
    .add({
      _id: Math.random().toString(),
      text: label,
      createdAt: new Date().getTime(),
      system: true,
      timestamp: new Date().getTime().toString(),
    })
    .then((sc) => {
      console.log("sc", sc);
    })
    .catch((err) => {
        console.log("err:", err)
    });
  setTimeout(() => {
    props.history.push({
      pathname: "/coachchatdetail",
      state: { title: label, id: managerId, room_id: res.body.data.room_id },
    });
  }, 1000);
};

export default setChatTitle;