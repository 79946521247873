import { useQuery } from "@tanstack/react-query";
import instanceAxios from "../config/instance-axios";
import { generateApiEndpointByRole } from "../utils/roles";

export const useManagerTeam = (userRole) => {
  const queryKey = ["managerTeam", userRole];
  return useQuery(
    queryKey,
    async () => {
      const response = await instanceAxios.get(
        generateApiEndpointByRole("getListOfTeamMemberByManagerId", userRole)
      );
      return response.data;
    },
    {
      retry: 2,
    }
  );
};
