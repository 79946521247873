import * as actions from '../type.action'

const initialState = {
    comments: [],
    message: null
}

const achievementCommentReducer = (state, action) => {
    if (state === undefined) {
        return (state = initialState)
    }

    switch (action.type) {
        case actions.ACHIEVEMENT_COMMENTS_SUCCESS:
            return {
                ...state,
                comments: action.data
            }
        case actions.ACHIEVEMENT_COMMENTS_FAIL:
            return {
                ...state,
                message: action.data
            }
        default:
            return state
    }

}

export default achievementCommentReducer;