import React, { Component, useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import toggle from "../../assets/images/toggle.png";
import notification from "../../assets/images/notification.png";
import { useLocation, Link, useHistory } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Notification from "./Notification";
import cross from "../../assets/images/cross.png";
import {
  putAPI,
  GET,
  clearLocalStorage,
  getRouteName,
  verifyAccessToken,
} from "../../utils/helper";
import firebase from "firebase";
import { default as MyModal } from "./Modal";
import DashboardSwitch from "./DashboardSwitch";
import routes from "../../routes";
import { toast } from "react-toastify";

const Header = (props) => {
  const isLoggedIn = useSelector((state) => state.loginReducer.isLoggedIn);
  const is_manager = props.loginData?.is_manager;
  const look = props.showLook;
  const unauthRoutes = [
    "/login",
    "/employerlogin",
    "/forgetpassword",
    "/resetpassword",
    "/employerresetpassword",
    "/employerforgetpassword",
    "/profile",
  ];
  let location = useLocation();
  let history = useHistory();
  const currentRoute = routes.find(
    (route) => route.routeName == getRouteName(location.pathname)
  );

  const free_trial_status = Number(localStorage.getItem("free_trial_status"));
  const subscription_type = Number(localStorage.getItem("subscription_type"));

  const dispatch = useDispatch();
  const notificationCountReducer = useSelector(
    (state) => state.notificationCountReducer
  );
  const notificationCount = notificationCountReducer.all;
  const chatCount = notificationCountReducer.chat_message_only || 0;
  const ticket_reply = notificationCountReducer?.data?.ticket_reply || 0;
  const [showOption, setShowOption] = useState(false);
  const showTitle = !unauthRoutes.includes(location.pathname);
  const [modalOpen, setModalOpen] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const total_count = (chatCount > 0 || ticket_reply > 0) ? chatCount + ticket_reply : 0;
  const handleLogout = async () => {
    try {
      if (props.loginRole == 0) {
        await putAPI("clearEmployeeDeviceToken", {});
      } else {
        await putAPI("clearEmployerDeviceToken", {});
      }

      setTimeout(() => dispatch({ type: "RESET_LOGIN_ROLE" }), 1000);

      clearLocalStorage();
      setLogoutModal(false);
      dispatch({ type: "CLEAR_LOGIN_DATA" });
      history.push("/home");
    } catch (error) {
      clearLocalStorage();
      setLogoutModal(false);

      history.push("/home");
    }
  };

  const notificationCountFunction = async () => {
    if (window.location.pathname !== "/home") {
      dispatch({ type: "GET_NOTIFICATION_COUNT" });
    }
  };

  useEffect(() => {
    if ((props.loginRole == 0 || props.loginRole == 2) && isLoggedIn) {
      notificationCountFunction();
    }
  }, [free_trial_status]);

  const renderDropDown = () => {
    if (props.loginRole === 0 || props.loginRole === 2) {
      return (
        <div
          className="dropdown Logout menu noticount-wrap"
          onClick={() => setShowOption(!showOption)}
        >
          <button type="button" data-toggle="dropdown" id="dropdownMenuButton">
            <img src={toggle} />
            {total_count > 0 ? (
              <div className="noti-count">
                <span>{total_count}</span>
              </div>
            ) : null}
          </button>

          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {props.loginRole == 0 ? (
              look ? (
                <>
                  <Link to={"/dashboard"} className="dropdown-item">
                    Dashboard
                  </Link>
                  <Link to={"/analytics"} className="dropdown-item">
                    Analytics
                  </Link>
                  {/* <Link to={"/employeeSessions"} className="dropdown-item">
                    My Sessions
                  </Link>
                  <Link
                    to={"/employeeSessionsHistory"}
                    className="dropdown-item"
                  >
                    Sessions History
                  </Link> */}
                  <Link to={"/goal"} className="dropdown-item">
                    Goal
                  </Link>
                  <Link to={"/team"} className="dropdown-item">
                    Team
                  </Link>

                  <div
                    style={{ cursor: "pointer" }}
                    to={"/chat"}
                    onClick={() => {
                      if (firebase.messaging.isSupported()) {
                        history.push("/chat");
                      }
                    }}
                    className="dropdown-item"
                  >
                    Chat
                    <p className="chat-notification">
                      {chatCount && chatCount > 0 ? (
                        <span>{chatCount}</span>
                      ) : null}
                    </p>
                  </div>
                  <Link to={"/myattributes"} className="dropdown-item">
                    Qualitative Target
                  </Link>
                  <Link to={"/refer"} className="dropdown-item">
                    Refer a Friend
                  </Link>
                  <Link to={"/changepassword"} className="dropdown-item">
                    Change Password
                  </Link>
                  <Link to={"/faqs"} className="dropdown-item">
                    FAQs
                  </Link>
                </>
              ) : (
                <>
                  <Link to={"/dashboard"} className="dropdown-item">
                    Dashboard
                  </Link>
                  {/* <Link to={"/employeeSessions"} className="dropdown-item">
                    My Sessions
                  </Link>
                  <Link
                    to={"/employeeSessionsHistory"}
                    className="dropdown-item"
                  >
                    Sessions History
                  </Link> */}
                  <Link to={"/empgoals"} className="dropdown-item">
                    Goal
                  </Link>
                  <Link to={"/myprofile"} className="dropdown-item">
                    My Profile
                  </Link>

                  <div
                    style={{ cursor: "pointer" }}
                    to={"/chat"}
                    onClick={() => {
                      if (firebase.messaging.isSupported()) {
                        history.push("/chat");
                      }
                    }}
                    className="dropdown-item"
                  >
                    Chat
                    <p className="chat-notification">
                      {chatCount && chatCount > 0 ? (
                        <span>{chatCount}</span>
                      ) : null}
                    </p>
                  </div>
                  <Link to={"/achievement"} className="dropdown-item">
                    Achievements
                  </Link>
                  {/* <Link to={"/library"} className="dropdown-item">
                    Library
                  </Link> */}
                  <Link to={"/changepassword"} className="dropdown-item">
                    Change Password
                  </Link>
                  <Link to={"/contactus"} className="dropdown-item">
                    <div className="d-flex align-items-center justify-content-center">
                      Contact Us 
                      {
                          ticket_reply > 0 && <div className="count_circle ml-2">
                            <span>{ticket_reply}</span>
                          </div>
                        }
                    </div>
                  </Link>
                  <Link to={"/refer"} className="dropdown-item">
                    Refer a Friend
                  </Link>
                  <Link to={"/faqs"} className="dropdown-item">
                    FAQs
                  </Link>
                </>
              )
            ) : props.loginRole == 1 ? (
              <>
                <>
                  <Link to={"/employerdashboard"} className="dropdown-item">
                    My Company Profile
                  </Link>
                  <Link to={"/employerteam"} className="dropdown-item">
                    My Employees
                  </Link>

                  <Link to={"/subscription"} className="dropdown-item">
                  Subscription
                  </Link>
                  <Link
                    to={"/changepasswordemployer"}
                    className="dropdown-item"
                  >
                    Change Password
                  </Link>
                  <Link to={"/contactusemployer"} className="dropdown-item">
                    Contact Us
                  </Link>
                </>
              </>
            ) : (
              <>
                <Link to={"/coachdashboard"} className="dropdown-item">
                  Dashboard
                </Link>
                <Link to={"/coachchat"} className="dropdown-item">
                  Chat
                  <p className="chat-notification">
                    {chatCount && chatCount > 0 ? (
                      <span>{chatCount}</span>
                    ) : null}
                  </p>
                </Link>
                <Link to={"/defineavailabilty"} className="dropdown-item">
                  Define Availablity
                </Link>
                <Link to={"/mysessions"} className="dropdown-item">
                  My Sessions
                </Link>
                <Link to={"/mysessionrequest"} className="dropdown-item">
                  My Session Requests
                </Link>
                <Link to={"/sessionhistory"} className="dropdown-item">
                  Session History
                </Link>
              </>
            )}
          </div>
          {/* :null    
} */}
        </div>
      );
    } else if (
      props.loginRole === 1 &&
      free_trial_status !== 0 &&
      subscription_type !== 2
    ) {
      return (
        <div
          className="dropdown Logout menu noticount-wrap"
          onClick={() => setShowOption(!showOption)}
        >
          <button type="button" data-toggle="dropdown" id="dropdownMenuButton">
            <img src={toggle} />
            {(props.loginRole == 0 || props.loginRole == 1 || props.loginRole == 2 ) && total_count > 0 ? (
              <div className="noti-count">
                <span>{total_count}</span>
              </div>
            ) : null}
          </button>
          {/* {/ {showOption? /} */}
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {props.loginRole == 0 ? (
              look ? (
                <>
                  <Link to={"/dashboard"} className="dropdown-item">
                    Dashboard
                  </Link>
                  <Link to={"/goal"} className="dropdown-item">
                    Goal
                  </Link>
                  <Link to={"/team"} className="dropdown-item">
                    Team
                  </Link>
                  {/* {/ <Link to={'/chat'} className="dropdown-item">Chat</Link> /} */}
                  <Link to={"/chat"} className="dropdown-item">
                    Chat
                    <p className="chat-notification">
                      {chatCount && chatCount > 0 ? (
                        <span>{chatCount}</span>
                      ) : null}
                    </p>
                  </Link>
                  <Link to={"/refer"} className="dropdown-item">
                    Refer a Friend
                  </Link>
                  <Link to={"/changepassword"} className="dropdown-item">
                    Change Password
                  </Link>
                </>
              ) : (
                <>
                  <Link to={"/dashboard"} className="dropdown-item">
                    Dashboard
                  </Link>
                  <Link to={"/employeegoal"} className="dropdown-item">
                    Goal
                  </Link>
                  <Link to={"/myprofile"} className="dropdown-item">
                    My Profile
                  </Link>
                  <Link to={"/chat"} className="dropdown-item">
                    Chat
                    <p className="chat-notification">
                      {chatCount && chatCount > 0 ? (
                        <span>{chatCount}</span>
                      ) : null}
                    </p>
                  </Link>
                  <Link to={"/achievement"} className="dropdown-item">
                    Achievements
                  </Link>
                  {/* <Link to={"/library"} className="dropdown-item">
                    Library
                  </Link> */}
                  <Link to={"/changepassword"} className="dropdown-item">
                    Change Password
                  </Link>
                  <Link to={"/contactus"} className="dropdown-item">
                    <div className="d-flex align-items-center justify-content-center">
                      Contact Us 
                      {
                          ticket_reply > 0 && <div className="count_circle ml-2">
                            <span>{ticket_reply}</span>
                          </div>
                        }
                    </div>
                  </Link>
                  <Link to={"/refer"} className="dropdown-item">
                    Refer a Friend
                  </Link>
                  <Link to={"/faqs"} className="dropdown-item">
                    FAQs
                  </Link>
                </>
              )
            ) : props.loginRole == 1 ? (
              <>
                <>
                  <Link to={"/employerdashboard"} className="dropdown-item">
                    My Profile
                  </Link>
                  <Link to={"/analytics"} className="dropdown-item">
                    Analytics
                  </Link>
                  <Link to={"/employerteam"} className="dropdown-item">
                    My Employees
                  </Link>
                  
                  <Link to={"/chat"} className="dropdown-item">
                    Chat
                    <p className="chat-notification">
                      {chatCount && chatCount > 0 ? (
                        <span>{chatCount}</span>
                      ) : null}
                    </p>
                  </Link>

                  <Link to={"/myattributes"} className="dropdown-item">
                    Qualitative Target
                  </Link>
                  {/* <Link to={"/mypayments"} className="dropdown-item">
                    My Payments
                  </Link> */}
                  <Link to={"/subscription"} className="dropdown-item">
                  Subscription
                  </Link>
                  <Link
                    to={"/changepasswordemployer"}
                    className="dropdown-item"
                  >
                    Change Password
                  </Link>
                  <Link to={"/contactus"} className="dropdown-item">
                    <div className="d-flex align-items-center justify-content-center">
                        Contact Us 
                        {
                          ticket_reply > 0 && <div className="count_circle ml-2">
                            <span>{ticket_reply}</span>
                          </div>
                        }
                        
                    </div>
                  </Link>
                  <Link to={"/faqs"} className="dropdown-item">
                    FAQs
                  </Link>
                </>
              </>
            ) : (
              <>
                <Link to={"/coachdashboard"} className="dropdown-item">
                  Dashboard
                </Link>

                <Link to={"/coachchat"} className="dropdown-item">
                  Chat
                  <p className="chat-notification">
                    {chatCount && chatCount > 0 ? (
                      <span>{chatCount}</span>
                    ) : null}
                  </p>
                </Link>
                <Link to={"/defineavailabilty"} className="dropdown-item">
                  Define Availablity
                </Link>
                <Link to={"/mysessions"} className="dropdown-item">
                  My Sessions
                </Link>
                <Link to={"/mysessionrequest"} className="dropdown-item">
                  My Session Requests
                </Link>
                <Link to={"/sessionhistory"} className="dropdown-item">
                  Session History
                </Link>
              </>
            )}
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    const access_token = new URLSearchParams(location.search).get('access');
    if(window.location.pathname.includes('login') &&access_token){
      verifyAccessToken(access_token)
      .then(data=>data.json())
      .then(result=>{
        if(result.success){
          dispatch({ type: 'GET_LOGINDATA_TOKEN', value: {
            loginData:result,
            history:props.history
          } })
        }else{
          toast.error("Access link expired!")
        }
      })
    }else if (isLoggedIn) {
      history.push("/dashboard");
    }
  }, []);
  
  return (
    <React.Fragment>
      <section className="header-wrapper">
        <div className="container">
          <div className="row no-gutters justify-content-between">
            <div className="">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
            </div>
            {(location.pathname == "/home" ||
              location.pathname === "/founder" ||
              location.pathname === "/pricing" ||
              location.pathname === "/coaches" ||
              location.pathname === "/resources" ||
              location.pathname === "/contactushome" ||
              location.pathname === "/advisors") &&
            !isLoggedIn ? (
              <>

                <div className="col-md-4 col-4 Employee-Team d-md-flex d-none"></div>
                <div className="col-md-4 col-4 Logout">
                  <h3>
                    <a style={{ color: '#000', fontWeight: 600 }} href="https://calendly.com/kevin-lichtenfels" target="_blank">
                      Schedule A Demo | 
                    </a>
                  </h3>
                  <div className="dropdown">
                    <h3 data-toggle="dropdown" id="dropdownMenuButton">
                      Login
                    </h3>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <Link to={"/login"} className="dropdown-item">
                        Employee
                      </Link>
                      <Link to={"/employerlogin"} className="dropdown-item">
                        Employer
                      </Link>
                      <Link to={"/coachlogin"} className="dropdown-item">
                        Coach
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            {showTitle && isLoggedIn ? (
              <div className="Logout">
                <h3 onClick={() => setLogoutModal(true)}>Logout</h3>

                {props.loginRole == 0 && (
                  <div
                    className="dropdown Logout menu noticount-wrap cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setModalOpen(true);
                    }}
                  >
                    <button>
                      <img alt="" src={notification} className="bell" />
                    </button>
                    {notificationCount && notificationCount > 0 ? (
                        <div className="noti-count">
                          <span>
                          {notificationCount < 99 ? notificationCount : "99+"}

                          {/* 1 */}
                        </span>
                      </div>
                    ) : null}
                  </div>
                )}

                {props.loginRole == 2 && (
                  <div
                    className="dropdown Logout menu noticount-wrap cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setModalOpen(true);
                    }}
                  >
                    <button>
                      <img alt="" src={notification} className="bell" />
                    </button>

                    {notificationCount && notificationCount > 0 ? (
                      <div className="noti-count">
                        <span>
                          {notificationCount < 99 ? notificationCount : "99+"}
                        </span>
                      </div>
                    ) : null}
                  </div>
                )}

                {renderDropDown()}
              </div>
            ) : null}
          </div>
        </div>

        <Modal isOpen={modalOpen} className="notifmodal">
          <ModalBody>
            <div className="notif-wrapper">
              <img
                src={cross}
                className="cross"
                onClick={() => setModalOpen(false)}
                alt=""
              />
              {props.loginRole == 0 && (
                <Notification
                  setModalOpen={setModalOpen}
                  notificationCountFunction={notificationCountFunction}
                />
              )}
              {props.loginRole == 2 && (
                <Notification
                  setModalOpen={setModalOpen}
                  notificationCountFunction={notificationCountFunction}
                />
              )}
            </div>
          </ModalBody>
        </Modal>

        <MyModal
          showmodal={logoutModal}
          onCLose={() => {
            setLogoutModal(false);
          }}
          title={`Logout?`}
          body={<div>Are you sure you want to Logout</div>}
          handleClick={handleLogout}
        />
      </section>
      {is_manager == 1 && !currentRoute?.hideManagerLook && isLoggedIn ? (
        <DashboardSwitch />
      ) : null}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    showLook: state.globalReducer.showLook,
    loginRole: state.loginReducer.loginRole,
    loginData: state.loginReducer.loginData,
    isLoggedIn: state.loginReducer.isLoggedIn,
  };
};

const mapDispatch = (dispatchEvent) => ({
  logIn: (payload) => dispatchEvent({ type: 'GET_LOGINDATA', value: payload }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(Header);
