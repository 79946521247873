import React from "react";
import { getDate } from "../utils/validation";

export default function PreviosProfileDropDown(props) {
  return (
    <div>
      <div
        onClick={() => {
          props.setIsOpen(null);
        }}
        class="goal-head  previos-profile-drop-down-goal-head"
      >
        <p className="previos-profile-drop-down-p">
          {!props.title
            ? `Previous Employer ${props.outerIndex + 1}`
            : props.title}
        </p>
        <i class="fa fa-angle-up  previos-profile-drop-down-p-fa"></i>
      </div>
      <div className="previos-profile-drop-down-div">
        <div class="form-group">
          <input
            type="text"
            disabled
            class="form-control"
            value={`Name: ${props.profileData.prev_employer}`}
            placeholder="Last employer Name"
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            disabled
            class="form-control lastemp"
            value={`Department: ${props.profileData.prev_department}`}
            placeholder="Department"
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            disabled
            class="form-control"
            value={`Title/position: ${props.profileData.prev_designation}`}
            placeholder="Title/position"
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            disabled
            class="form-control"
            value={`Start Date: ${getDate(
              props.profileData.prev_date_of_joining
            )}`}
            placeholder="Start Date"
          />
        </div>
        <div class="form-group">
          <input
            type="text"
            disabled
            class="form-control lastin"
            value={`Date of exit: ${getDate(props.profileData.prev_exit)}`}
            placeholder="Date of exit"
          />
        </div>
      </div>
    </div>
  );
}
