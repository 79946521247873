import { get } from "lodash";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import banner from "../assets/images/banner.png";
import { useParams, useHistory } from "react-router-dom";
import { GET, getGoalPercentage } from "../utils/helper";
import "../scss/customforall.scss";
const GoalSubmissionHistory = (props) => {
  const params = useParams();

  const [goalDetail, setGoalDetail] = useState(null);
  const [loading, setLoading] = useState(null);

  const { goalid } = params;

  useEffect(() => {
    const viewGoalAssignCompletionAsEmployee = async () => {
      try {
        setLoading(true);

        const res = await GET(`viewGoalDetailsAsEmployee?goal_id=${goalid}`);
        setLoading(false);
        if (res?.body?.data) {
          setGoalDetail(res.body.data);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    viewGoalAssignCompletionAsEmployee();
  }, []);

  return (
    <div className="goalsubmission-main">
      <div class="Approve-goal-wrapper history bg-color">
        <div class="container">
          <div class="Approve-goal comman-width-container">
            <div class="banner-img">
              <img class="img-fluid" src={banner} />
            </div>
            {goalDetail &&
            goalDetail?.team_goal_assigns?.[0]
              ?.team_goal_assign_completion_by_employees?.length > 0 ? (
              <div>
                <div class="goal-details-history">
                  <div class="goal-head history">
                    <div class="row">
                      <div class="col-md-6 col-sm-6">
                        <div class="Manager">
                          <h6>
                            {" "}
                            {goalDetail && goalDetail.title && (
                              <h6>{goalDetail.title}</h6>
                            )}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="history-wrapper one">
                    <div class="row goalsubmit-row">
                      {goalDetail?.team_goal_assigns[0]?.team_goal_assign_completion_by_employees?.map(
                        (item) => (
                          <div class="col-md-6">
                            <div class="history-details">
                              <div class="Employee-details">
                                <div class="row">
                                  <div class="col-md-6 col-sm-6">
                                    <div class="Manager">
                                      <p>Date</p>
                                    </div>
                                  </div>
                                  <div class="col-md-6 col-sm-6">
                                    <div class="Employee-Inputs">
                                      <p>{item.createdAt.slice(0, 10)}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="Employee-details">
                                <div class="row">
                                  <div class="col-md-8 col-sm-8">
                                    <div class="Manager">
                                      <p>Target Measure Amount</p>
                                    </div>
                                  </div>
                                  <div class="col-md-4 col-sm-4">
                                    <div class="Employee-Inputs">
                                      <p>{item.complete_measure}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="Employee-details">
                                <div class="row">
                                  <div class="col-md-8 col-sm-8">
                                    <div class="Manager">
                                      <p>Target Completion Percentage</p>
                                    </div>
                                  </div>
                                  <div class="col-md-4 col-sm-4">
                                    <div class="Employee-Inputs">
                                      <p>{((item.complete_measure/goalDetail?.team_goal_assigns?.[0]?.measure)*100).toFixed(2)}%</p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {item.status === 1 && (
                                <div class="Approved-btn">
                                  <button>Approved</button>
                                </div>
                              )}
                              {item.status === 2 && (
                                <div class="Approved-btn rejected">
                                  <button>Rejected</button>
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="goalsubmit-no-data-found">
                {loading ? (
                  <div
                    class="spinner-border text-primary    goalsubmit-spinner"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <h4>No history Found</h4>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    showLook: state.globalReducer.showLook,
  };
};

const mapDispatch = (dispatchEvent) => ({
  setshowLook: (payload) => dispatchEvent({ type: "SET_LOOK", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(GoalSubmissionHistory);
