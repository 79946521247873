import React, { useCallback, useEffect, useState } from "react";
import { GET } from "../../utils/helper";
import { Calendar } from "@natscale/react-calendar";
import Select from "react-select";
import Loader from "../Common/Loader";
import moment from "moment";
import { useHistory } from "react-router-dom";
import ReactStarsComp from "../Common/ReactStarsComp";
import "../.././scss/customforall.scss";
import {
  FaInstagram,
  FaFacebook,
  FaTwitter,
  FaGoogle,
  FaLinkedin,
} from "react-icons/fa";
import { toast } from "react-toastify";
import favicon from "./favicon.ico";
import ShowMoreText from "react-show-more-text";
import { CgWebsite } from "react-icons/cg";
export default function ListOfCoaches() {
  const [coaches, setCoaches] = useState([]);
  const [tangocoaches, setTangocoaches] = useState([]);
  const [filters, setFilters] = useState(2);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [sort, setSort] = useState(3);
  const [coachSpecialization, setCoachSpecialization] = useState([]);
  const [selectedSpecialization, setSelectedSpecialization] = useState("");
  const history = useHistory();
  const [readMore, setReadMore] = useState(false);
  const [readMoreId, setReadMoreId] = useState(null);
  const optionStyles = {
    input: (styles) => ({ ...styles, width: window.innerWidth < 540 ? "17rem" : "22rem" }),
    control: (styles) => ({ ...styles, marginTop: "2rem" }),
  };

  const onChange = useCallback(
    (value) => {
      setFilters(3);
      setDate(moment(value).format("YYYY-MM-DD"));
    },
    [setDate]
  );

  useEffect(() => {
    const getCoachSpecializations = async () => {
      try {
        const res = await GET(`getCoachSpecializationCategoryList`);
        setLoading(false);

        setCoachSpecialization(res.body.data.rows);
      } catch (error) {
        setLoading(false);
      }
    };
    getCoachSpecializations();
  }, []);

  useEffect(() => {
    let selectedSpecializationText = selectedSpecialization.name || "";

    const getCoaches = async () => {
      let dateText = "";
      if (filters === 2) {
        dateText = `${date}`;
      }

      setLoading(true);
      try {
        const res = await GET(
          `getCoachList?sortBy=${sort}&searchKey=${selectedSpecializationText}&filterBy=${filters}&date=${moment(
            new Date()
          ).format("YYYY-MM-DD")}`
        );

        setLoading(false);
        if (res.status === 200) {
          setCoaches(res.body.data.BX.rows);

          setTangocoaches(res.body.data.BT);
        } else {
          setCoaches([]);
          setTangocoaches([]);
        }
      } catch (error) {
        setLoading(false);
        setCoaches([]);
        setTangocoaches([]);
      }
    };
    getCoaches();
  }, [filters, date, sort, selectedSpecialization]);

  const executeOnClick = (isExpanded) => {
   
  };

  return (
    <div
      style={{ minHeight: "80vh" }}
      className="Manager-Goal-Look-wrapper bg-color"
    >
      <div className="">
        <div className="Manager-Goal-Look comman-width-container profile-edit">
          <div style={{ marginTop: "2rem" }} className="banner-img">
            <h3>Available Coaches</h3>
          </div>

          <Select
            isloading={true}
            autoFocus={true}
            onChange={(e) => {
              if (e) {
                setSelectedSpecialization((state) => ({
                  id: e.id,
                  name: e.name,
                }));
              } else {
                setSelectedSpecialization((state) => ({
                  id: coachSpecialization[0].id,
                  name: coachSpecialization[0].name,
                }));
              }
            }}
            required
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => `${option.id}`}
            options={coachSpecialization}
            styles={optionStyles}
            textarea
            placeholder="Specialization category"
          />
          <br />
          <div className="index-br">
            {!loading && (
              <div className="calendar-box   calendar-box2">
                <Calendar
                  disablePast
                  value={date ? new Date(date) : ""}
                  size={250}
                  onChange={onChange}
                />
              </div>
            )}

            <div className="cardss  cardss1">
              {!loading ? (
                tangocoaches.count > 0 ? (
                  <div
                    className="List-of-Coaches-wrapper"
                    style={{ width: "63%" }}
                  >
                    <div className="profile-img">
                      <img className="index-profile1" src={favicon} alt="" />
                    </div>
                    <div className="index-star">
                      <ReactStarsComp
                        color="white"
                        size={15}
                        value={tangocoaches.average_rating}
                        edit={false}
                        activeColor="yellow"
                        backgroundColor="white"
                        emptyIcon={<i className="fa fa-star-o" />}
                        halfIcon={<i className="fa fa-star-half-alt" />}
                        filledIcon={<i className="fa fa-star" />}
                      />
                    </div>

                    <div className="List-of-Coaches" style={{ width: "63%" }}>
                      <h4>BluTango </h4>
                      <p>Coaches:{tangocoaches.count}</p>
                    </div>

                    <button
                      onClick={() => {
                        if (selectedSpecialization == "") {
                          toast.error("Select specialization category", {
                            position: toast.POSITION.BOTTOM_CENTER,
                            hideProgressBar: true,
                          });
                          return;
                        } else {
                          history.push({
                            pathname: "tangocoachavailabilty",
                            state: { selectedSpecialization, sort, date },
                          });
                        }
                      }}
                      className="mt-4 w-50"
                    >
                      Check Availablity
                    </button>
                  </div>
                ) : null
              ) : (
                <Loader />
              )}

              {!loading ? (
                coaches.length > 0 ? (
                  coaches.map((coach) => {
                    return (
                      <div
                        key={coach.id}
                        id={coach.id}
                        className="List-of-Coaches-wrapper"
                        style={{ width: "63%" }}
                      >
                        <div className="profile-img">
                          <img
                            className="index-profile"
                            src={coach.profile_pic_url}
                            alt="profile pic"
                          />
                        </div>
                        <div className="index-star">
                          <ReactStarsComp
                            color="white"
                            size={15}
                            value={coach.average_rating}
                            edit={false}
                            activeColor="yellow"
                            backgroundColor="white"
                            emptyIcon={<i className="fa fa-star-o" />}
                            halfIcon={<i className="fa fa-star-half-alt" />}
                            filledIcon={<i className="fa fa-star" />}
                          />
                        </div>

                        <div className="List-of-Coaches">
                          <h4>{coach.name}</h4>
                          <p>{coach.description}</p>
                          <h5>
                            Website{" "}
                            <a
                              href={coach.website}
                              style={{ color: "#0e76a8" }}
                            >
                              <CgWebsite />
                            </a>
                          </h5>
                          <div className="social-media-handles">
                            {coach.social_media_handles ? (
                              coach.social_media_handles.facebook ||
                              coach.social_media_handles.twitter ||
                              coach.social_media_handles.linkedin ||
                              coach.social_media_handles.instagram ||
                              coach.social_media_handles.google ? (
                                <h4>Social Media Handles</h4>
                              ) : null
                            ) : null}
                            {coach.social_media_handles ? (
                              coach.social_media_handles.google ? (
                                <a
                                  href={coach.social_media_handles.google}
                                  className="icon-style4"
                                >
                                  <FaGoogle
                                    style={{ width: "25px", height: "35px" }}
                                  />
                                </a>
                              ) : null
                            ) : null}{" "}
                            {coach.social_media_handles ? (
                              coach.social_media_handles.twitter ? (
                                <a
                                  href={coach.social_media_handles.twitter}
                                  className="icon-style3"
                                >
                                  <FaTwitter
                                    style={{ width: "25px", height: "35px" }}
                                  />
                                </a>
                              ) : null
                            ) : null}{" "}
                            {coach.social_media_handles ? (
                              coach.social_media_handles.linkedin ? (
                                <a
                                  href={coach.social_media_handles.linkedin}
                                  className="icon-style2"
                                >
                                  <FaLinkedin
                                    style={{ width: "25px", height: "35px" }}
                                  />
                                </a>
                              ) : null
                            ) : null}{" "}
                            {coach.social_media_handles ? (
                              coach.social_media_handles.facebook ? (
                                <a
                                  href={coach.social_media_handles.facebook}
                                  className="icon-style1"
                                >
                                  <FaFacebook
                                    style={{ width: "25px", height: "35px" }}
                                  />
                                </a>
                              ) : null
                            ) : null}{" "}
                            {coach.social_media_handles ? (
                              coach.social_media_handles.instagram ? (
                                <a
                                  className="icon-style"
                                  href={coach.social_media_handles.instagram}
                                >
                                  <FaInstagram
                                    style={{ width: "25px", height: "35px" }}
                                  />
                                </a>
                              ) : null
                            ) : null}
                          </div>
                          <br></br>
                          <div>
                            <h4>Specialization</h4>
                            <div>
                              <ShowMoreText
                                lines={1}
                                more={<p>Show More</p>}
                                less={<p>Show Less</p>}
                                onClick={executeOnClick}
                                expanded={false}
                                width={850}
                              >
                                <div>
                                  {coach.coach_specialization_categories.map(
                                    (specialization) => {
                                      return (
                                        <div key={specialization.id}>
                                          <h5>{specialization.name} </h5>

                                          <p>{specialization.description}</p>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </ShowMoreText>
                            </div>
                          </div>
                        </div>

                        <button
                          onClick={() =>
                            history.push({
                              pathname: "Checkcoachavailability",
                              state: {
                                app_id: 1,
                                dateprop: date,
                                coachId: coach.id,
                                coachName: coach.name,
                                coachSpecializationCategories:
                                  coach.coach_specialization_categories,
                              },
                            })
                          }
                          className="mt-4 w-50"
                        >
                          Check Availablity
                        </button>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center p-md-0 py-3" style={{ minWidth: "35rem" }}>
                    No Coaches Found On BluXinga
                  </div>
                )
              ) : (
                <Loader />
              )}
            </div>

            {!loading && (
              <div className="filtercopy    filter-main-div">
                <div>
                  <h4>Filters</h4>
                  <div>
                    <div className="index-label">
                      <input
                        type="radio"
                        id="filterone"
                        name="filterone"
                        checked={filters === 1}
                        className="list-of-coaches-input"
                        onChange={(e) => {
                          setDate(null);
                          setFilters(1);
                        }}
                      />
                      Week
                    </div>

                    <div className="index-label">
                      <input
                        type="radio"
                        id="filtertwo"
                        name="filtertwo"
                        checked={filters === 2}
                        className="list-of-coaches-input"
                        onChange={(e) => {
                          setDate(moment(new Date()).format("YYYY-MM-DD"));
                          setFilters(2);
                        }}
                      />
                    
                      Today
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "2rem" }}>
                  <h4>Sort</h4>
                  <div>
                    <div className="index-label">
                      <input
                        type="radio"
                        id="sortone"
                        className="list-of-coaches-input"
                        name="sortone"
                        checked={sort === 1}
                        onChange={(e) => setSort(1)}
                      />
                      Newly Added on Top
                    </div>

                    <div className="index-label">
                      <input
                        type="radio"
                        id="sorttwo"
                        className="list-of-coaches-input"
                        name="sorttwo"
                        checked={sort === 2}
                        onChange={(e) => setSort(2)}
                      />
                      Old on Top
                    </div>
                    <div className="index-label">
                      <input
                        type="radio"
                        id="sortthree"
                        className="list-of-coaches-input"
                        name="sortthree"
                        checked={sort === 3}
                        onChange={(e) => setSort(3)}
                      />
                      Rating high to Low
                    </div>
                    <div className="index-label">
                      <input
                        type="radio"
                        id="sortfour"
                        name="sortfour"
                        className="list-of-coaches-input"
                        checked={sort === 6}
                        onChange={(e) => setSort(6)}
                      />
                      Sooner Availablity on Top
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
