import moment from "moment";
import * as yup from "yup";

const educationSchema = yup.object().shape({
  institute_name: yup.string().required("Institute name is required"),
  degree_type: yup.string().required("Degree type is required"),
  specialisation: yup.string().required("Specialisation is required"),
  from: yup
    .date()
    .required("From date is required")
    .test(
      "is-valid-range",
      "To date must be before the to date",
      function (value) {
        const { to, in_present } = this.parent;
        if (in_present) return true;
        return moment(to).isAfter(value);
      }
    ),
  in_present: yup.boolean().default(false),
  to: yup
    .date()
    .nullable()
    .test(
      "is-valid-range",
      "To date must be after the from date",
      function (value) {
        const { from, in_present } = this.parent;
        if (in_present) return true;
        return moment(from).isBefore(value);
      }
    ),
});

const employmentHistorySchema = yup.object().shape({
  company_name: yup.string().required("Company name is required"),
  department_name: yup.string().nullable(), //.required("Department name is required"),
  designation: yup.string().required("Designation is required"),
  job_designation: yup.string().nullable(), //.required("Job designation is required"),
  from: yup
    .date()
    .required("From date is required")
    .test(
      "is-valid-range",
      "To date must be before the to date",
      function (value) {
        const { to, in_present } = this.parent;
        if (in_present) return true;
        return moment(to).isAfter(value);
      }
    ),
  in_present: yup.boolean().default(false),
  to: yup
    .date()
    .nullable()
    .test(
      "is-valid-range",
      "To date must be after the from date",
      function (value) {
        const { from, in_present } = this.parent;
        if (in_present) return true;
        return moment(from).isBefore(value);
      }
    ),
});

const referencesSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email is required"),
});

export const manageCvValidation = () => {
  return yup.object({
    address: yup.string().nullable().required("Personal address is required"),
    technical_skills: yup
      .string()
      .nullable()
      .required("Technical skills is required"),
    educations: yup
      .array()
      .of(educationSchema)
      .required("Educations is required"),
    previous_employment_history: yup
      .array()
      .of(employmentHistorySchema)
      .required("Employment details is required"),
    references: yup
      .array()
      .of(referencesSchema)
      .required("References is required"),
  });
};

export const openTicketValidation = () => {
  return yup.object({
    subject: yup.string().required("Subject is required"),
    content: yup.string().required("Message is required"),
  });
};

export const replyTicketValidation = () => {
  return yup.object({
    content: yup.string().required("Message is required"),
  });
};

export const attributesValidationEmployee = () => {
  return yup.object({
    ratings: yup.array().of(
      yup.object().shape({
        desc: yup.string().required("Please enter description for this field"),
      })
    ),
  });
};

export const attributesValidation = () => {
  return yup.object({
    name: yup.string().max(50,"The max of characters is 50").required("Please select the target"),
    desc: yup.string().required("Please enter the Description"),
    employees: yup
      .array()
      .of(yup.number())
      .min(1, "Please select at least one employee")
      .required("Please select the employees for this attribute"),
  });
};

export const addGoalsValidation = () => {
  return yup.object({
    goals: yup.array(
      yup.object().shape({
        start_date: yup
          .date()
          .required("Start date is required")
          .test(
            "is-valid-range",
            "To date must be before the end date",
            function (value) {
              const { end_date } = this.parent;
              return moment(end_date).isAfter(value);
            }
          ),
        end_date: yup
          .date()
          .required("End date is required")
          .test(
            "is-valid-range",
            "To date must be after the start date",
            function (value) {
              const { start_date } = this.parent;
              return moment(start_date).isBefore(value);
            }
          ),
        title: yup.string().max(60,"The max of characters is 60").required("Title is required"),
        description: yup.string().max(250,"The max of characters is 250").required("Description is required"),
        select_measure: yup.string().required("Measure type is required"),
        employees_measures: yup
          .array()
          .of(
            yup.object().shape({
              employee_id: yup.number().required("Employee id is required"),
              measure: yup.number().required("Measure is required"),
            })
          )
          .min(1, "The goal should have at lest one employee"),
      })
    ),
  });
};

export const coachProfileValidation = (id) => {
  const phoneRegExp = /^\d+$/;

  return yup.object({
    name: yup
      .string()
      .max(50, "Name cannot exceed 50 characters")
      .required("Name is required"),
    email: yup
      .string()
      .email("Invalid email address format")
      .required("Email address is required")
      .trim(),
    country_code: yup.number().required("Country code is required"),
    phone_number: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .max(10, "Phone number cannot exceed 10 digits")
      .min(10, "Phone number must be exactly 10 digits")
      .required("Phone number is required"),
    description: yup
      .string()
      .max(150, "Description can not exceed 150 characters")
      .required("Description is required"),
  });
};

export const employerProfileValidation = (id) => {
  const phoneRegExp = /^\d+$/;

  return yup.object({
    name: yup
      .string()
      .max(50, "Name cannot exceed 50 characters")
      .required("Name is required"),
    email: yup
      .string()
      .email("Invalid email address format")
      .required("Email address is required")
      .trim(),
    country_code: yup.string().required("Country code is required"),
    phone_number: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .max(10, "Phone number cannot exceed 10 digits")
      .min(10, "Phone number must be exactly 10 digits")
      .required("Phone number is required"),
    address: yup
      .string()
      .max(150, "Address can not exceed 150 characters")
      .required("Address is required"),
  });
};

export const addEmployerValidation = (id, isAdd, isManager, isFresher, isCurrentUserManager) => {
  const phoneRegExp = /^\d+$/;
  let manager_team_icon_url;
  let manager_team_name;
  let manager_id;
  if (isManager) {
    manager_team_icon_url = yup.string().nullable();
    manager_team_name = yup
      .string()
      .nullable()
      .required("Manager team name is required");
  } else if (!isCurrentUserManager){
    manager_id = yup.array().min(1,"Manager is required").required("Manager is required");
  }

  return yup.object({
    first_name: yup
      .string()
      .max(100, "First Name cannot exceed 100 characters")
      .test(
        "alphabetsonly",
        "Last Name should not contain numbers and special characters",
        function (val) {
          return /^[a-zA-Z\s]*$/.test(val);
        }
      )
      .required("First name is required"),
    last_name: yup
      .string()
      .max(100, "Last Name cannot exceed 100 characters")
      .test(
        "alphabetsonly",
        "Last Name should not contain numbers and special characters",
        function (val) {
          return /^[a-zA-Z\s]*$/.test(val);
        }
      )
      .required("Last name is required"),

    email: yup
      .string()
      .email("Invalid email address format")
      .trim()
      .required("Email address is required"),
    manager_id,
    manager_team_name,
    manager_team_icon_url,
    country_code: yup.number("Country code is not valid"),
    phone_number: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid"),
    current_date_of_joining: yup
      .string()
      .required("Date of joining is required"),
    designation: yup
      .string()
      .max(50, "Name cannot exceed 50 characters")
      .required("Title is required"),
    current_department_id: yup
      .string()
      .test("len", "Department is required", (val) => val != 0)
      .required("Department is required"),
    employee_rank_id: yup
      .string()
      .test("len", "Employee team level is required", (val) => val != 0)
      .required("Employee team level is required"),
    prev_employers: isFresher
      ? ""
      : yup.array().of(
          yup.object().shape({
            prev_employer: yup
              .string()
              .max(100, "Name cannot exceed 100 characters")
              .test(
                "alphabetsonly",
                "Name should not contain numbers and special characters",
                function (val) {
                  return /^[a-zA-Z\s]*$/.test(val);
                }
              )
              .required("Name is Required"),
            prev_department: yup
              .string()
              .max(100, "Department name cannot exceed 50 characters")
              .test(
                "alphabetsonly",
                "Name should not contain numbers and special characters",
                function (val) {
                  return /^[a-zA-Z\s]*$/.test(val);
                }
              ),
            prev_designation: yup
              .string()
              .max(20, "Name cannot exceed 20 characters"),
            prev_date_of_joining: yup
              .string()
              .required("Previous start date is required"),
            prev_exit: yup
              .string()
              .required("Previous date of exit is required"),
          })
        ),

    prev_date_of_joining: isFresher
      ? ""
      : yup.string().required("Previous start date is required"),
    prev_exit: isFresher
      ? ""
      : yup.string().required("Previous date of exit is required"),
  });
};

export const updateProfileValidation = () => {
  const phoneRegExp = /^\d+$/;

  return yup.object({
    first_name: yup
      .string()
      .max(100, "First Name cannot exceed 100 characters")
      .test(
        "alphabetsonly",
        "First Name should not contain numbers and special characters",
        function (val) {
          return /^[a-zA-Z\s]*$/.test(val);
        }
      )
      .required("First name is required"),
    last_name: yup
      .string()
      .max(100, "Last Name cannot exceed 100 characters")
      .test(
        "alphabetsonly",
        "Last Name should not contain numbers and special characters",
        function (val) {
          return /^[a-zA-Z\s]*$/.test(val);
        }
      )
      .required("Last name is required"),

    country_code: yup
      .number("Country code is not valid")
      .required("Country code is required"),
    phone_number: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is required"),
    current_designation: yup
      .string()
      .max(50, "Current designation cannot exceed 50 characters")
      .required("Current designation is required"),
    profile_pic_url: yup
      .string()
      .nullable()
      .required("Profile picture is required"),
    current_department_id: yup
      .number()
      .nullable()
      .required("Department is required"),
    employee_rank_id: yup
      .number()
      .nullable()
      .required("Team level is required"),
  });
};

export const validateEmail = function (data = "") {
  const email = /^(?:^[0-9]{4,15}|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})$/i;
  let status = false;
  if (data && data !== "") {
    if (data.match(email)) {
      status = true;
    } else {
      status = false;
    }
  }
  return status;
};
export const validMobile = function (text = "") {
  let status = false;
  if (!isNaN(text) && text.length === 10) {
    status = true;
  } else {
    status = false;
  }

  return status;
};
export const validatePassword = function (data = "") {
  const hasUpperAndLowerCase = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
  let status = false;
  if (data && data !== "") {
    if (data.match(hasUpperAndLowerCase) && data.length > 7) {
      status = true;
    } else {
      status = false;
    }
  }
  return status;
};

export const errorMessage = {
  required: "Please enter this information",
  invalid: "Please enter valid input",
  invalidFile: "Wrong file type is selected",
  invalidEmail: " Email should be a valid email address",
  paswrdError:
    "Password must have 8 character,one uppercase,one lowercase, one numeric digit,one special character",
  cofirmpaswrdError: "ReEnter password to continue",
  paswrdnotmatchError: "Passwords do not match.",
  raterequired: "Please rate this field",
  checkbox: "Please check before SignIn",
};

export const validateCheckbox = {};

export function getDate(dateObj) {
  if (dateObj) {
    return moment(dateObj).format("YYYY-MM-DD");
  }
  return "";
}
export function getTextDate(dateObj) {
  if (dateObj) {
    return moment(dateObj).format("MM/DD/yyy");
  }
  return "";
}
export const getTime = (lastMessageTime) => {
  const now = new Date().getTime();
  const sec = parseInt((now - lastMessageTime) / 1000);
  if (sec < 1 * 60) {
    return "just now";
  }
  if (sec < 1 * 60 * 60) {
    return `${parseInt(sec / 60)}min`;
  }
  if (sec < 1 * 24 * 60 * 60) {
    return `${parseInt(sec / (60 * 60))}h`;
  }
  if (sec < 1 * 30 * 24 * 60 * 60) {
    return `${parseInt(sec / (24 * 60 * 60))}d`;
  }
  return `${parseInt(sec / (30 * 24 * 60 * 60))}mth`;
};
export function timeAgo(timestamp) {
  if (!timestamp) {
    return "";
  }
  var timeStampFromServer = timestamp;
  var date = new Date(Number(timestamp));
  let hour = date.getHours();
  let minutes = date.getMinutes();
  let finalDate = "";

  var currentTime = new Date().getTime();
  let sec = parseInt((currentTime - timeStampFromServer) / 1000);
  let min = parseInt(sec / 60);
  let hours = parseInt(min / 60);
  let days = parseInt(hours / 24);
  let weeks = parseInt(days / 7);
  let months = parseInt(weeks / 4);
  let years = parseInt(months / 12);

  if (days < 1) {
    if (hour <= 12) {
      finalDate = hour + ":";
    } else if (hour > 12) {
      finalDate = (hour % 12) + ":";
    }
    if (minutes < 10) {
      finalDate = finalDate + "0" + minutes;
    }
    if (minutes >= 10) {
      finalDate = finalDate + minutes;
    }
    if (hour < 12) {
      finalDate = finalDate + " AM";
    }
    if (hour >= 12) {
      finalDate = finalDate + " PM";
    }
    return finalDate;
  } else if (days <= 7) {
    if (days == 1) {
      return "Yesterday";
    } else {
      return days + " days ago";
    }
  } else if (weeks <= 4.3) {
    if (weeks == 1) {
      return "a week ago";
    } else {
      return weeks + " weeks ago";
    }
  } else if (months <= 12) {
    if (months == 1) {
      return "a month ago";
    } else {
      return months + " months ago";
    }
  } else {
    if (years == 1) {
      return "one year ago";
    } else {
      return years + " years ago";
    }
  }
}
