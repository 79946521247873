import React, { useState } from "react";
import { Formik, FieldArray } from "formik";
import { CInput, CLabel, CSelect } from "@coreui/react";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import moment from "moment";
import { useHistory } from "react-router";
import { slotvalidation } from "../../utils/AvailabilityValidation";
import { toast } from "react-toastify";
import { POST } from "../../utils/helper";
import "./DefineSlotsCss.css";
import _ from "lodash";
export default function DefineSlots(props) {
  let [Day, setDay] = useState([]);
  const history = useHistory();

  let { is_update } = props.location.state;
  console.log(is_update, "line18");

  const initialValues = {
    date: new Date().toISOString().slice(0, 10),
    timings: [
      {
        start_time: "",
        end_time: "",
      },
    ],
    type: 3,
    session_duration: 0,
    time_capture_type: is_update ? 1 : 0,
    day: [],
    custom_date: new Date().toISOString().slice(0, 10),
  };

  const onSubmit = async (values) => {
    let data = {};
    console.log(values);
    try {
      let result = await POST("addEditSlot", { ...values, ...data });
      let PreviewField = {
        start_date: values.date,
        end_date: values.custom_date,
        time_capture_type: values.time_capture_type,
        availableSlots: result.data.body.data["availableSlots"],
        unavailableSlots: result.data.body.data["unavailableSlots"],
        day: values.day,
        session_duration: values.session_duration,
        type: values.type,
        timings: values.timings,
      };

      if (result.data.status === 200) {
        try {
          is_update = props.location.state;
        } catch (error) {
          is_update = false;
        }
        history.push({
          pathname: "/previewslots",
          state: PreviewField,
          IsUpdate: is_update,
        });
        console.log(PreviewField, "PreviewFieldsssss");
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
  };

  const handleColor = (e) => {
    var i = parseInt(e.target.value);
    if (Day.includes(i)) {
      setDay(Day);
    } else {
      Day.push(parseInt(i));
      console.log("Day", Day);
      setDay(Day);
    }
    setDay(Day);
    console.log(Day);
  };
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={slotvalidation}
      enableReinitialize
    >
      {(formik) => {
        return (
          <section className="availbility-check-wrapper bg-color">
            <div className="container define-slot-maindiv">
              <h3>Define your Availablity</h3>
              <form onSubmit={formik.handleSubmit}>
                <div className="define-slot-maindiv">
                  <div className="define-slot-subdiv">
                    <div>
                      <CLabel htmlFor="drop-down">
                        <h5>
                          Your coaching session duration
                        </h5>
                      </CLabel>
                      <CSelect
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "session_duration",
                            parseInt(e.target.value)
                          );
                        }}
                      >
                        {" "}
                        <option value={parseInt(0)}>
                          Select Session Duration
                        </option>
                        <option value={parseInt(15)}>15 minutes</option>
                        <option value={parseInt(20)}>20 minutes</option>
                        <option value={parseInt(30)}>30 minutes</option>
                        <option value={parseInt(60)}>1 hour</option>
                      </CSelect>
                      {formik.errors &&
                      formik.errors.session_duration &&
                      formik.touched.session_duration ? (
                        <div style={{ color: "red", fontSize: "0.7rem" }}>
                          {formik.errors.session_duration}
                        </div>
                      ) : null}
                    </div>
                    {is_update === false ? (
                      <div>
                        <h5 className="mt-4">
                          {" "}
                          How do you want to add this
                        </h5>
                        <div className="d-flex flex-md-row flex-column">
                          <div class="fab">
                            <input
                              onBlur={formik.handleBlur}
                              className="define-slot-input1"
                              style={{
                                marginTop: "-9px",
                                marginLeft: "0",
                                boxShadow: "none",
                              }}
                              class="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              value={1}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "time_capture_type",
                                  parseInt(e.target.value)
                                );
                              }}
                            />
                            <label
                              style={{
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "13.657px",
                                lineHeight: "32px",
                                textAlign: "center",
                                marginLeft: "1rem",
                              }}
                              class="aa"
                              for="flexRadioDefault1"
                            >
                              Submit my available time
                            </label>
                          </div>
                          <div class="fb ml-md-3 ml-0">
                            <input
                              style={{
                                marginTop: "-9px",
                                marginLeft: "0",
                                boxShadow: "none",
                              }}
                              value={2}
                              class="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              onBlur={formik.handleBlur}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "time_capture_type",
                                  parseInt(e.target.value)
                                );
                              }}
                            />
                            <label
                              style={{
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "13.657px",
                                lineHeight: "32px",
                                textAlign: "center",
                                marginLeft: "1.2rem",
                              }}
                              class="bb"
                              for="flexRadioDefault2"
                            >
                              Submit my unavailable time
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {formik.errors &&
                    formik.errors.time_capture_type &&
                    formik.touched.time_capture_type ? (
                      <div style={{ color: "red", fontSize: "0.7rem" }}>
                        {formik.errors.time_capture_type}
                      </div>
                    ) : null}

                    <div>
                      <h5 className="mt-3">Session Frequency</h5>
                      <div className="days pl-0 pt-1 subday gap-2 flex-md-nowrap flex-wrap">
                        <div className="">
                          <input
                            className="input1"
                            type="checkbox"
                            id="0"
                            hidden
                            value="0"
                            onBlur={formik.handleBlur}
                            onClick={(e) => {
                              handleColor(e);
                            }}
                            onChange={() => {
                              formik.setFieldValue("day", Day);
                            }}
                          />
                          <span class="checkmark"></span>
                          <label className="Label days-label" for="0">
                            S
                          </label>
                        </div>
                        <div className="">
                          <input
                            className="input"
                            type="checkbox"
                            id="1"
                            hidden
                            value="1"
                            onBlur={formik.handleBlur}
                            onClick={(e) => {
                              handleColor(e);
                            }}
                            onChange={() => {
                              formik.setFieldValue("day", Day);
                            }}
                          />
                          <label
                            className="Label"
                            for="1"
                            style={{ padding: "17px" }}
                          >
                            M
                          </label>
                        </div>
                        <div>
                          <input
                            className="input"
                            type="checkbox"
                            id="2"
                            hidden
                            value="2"
                            onBlur={formik.handleBlur}
                            onClick={(e) => {
                              handleColor(e);
                            }}
                            onChange={() => {
                              formik.setFieldValue("day", Day);
                            }}
                          />
                          <label
                            className="Label"
                            for="2"
                            style={{ padding: "17px" }}
                          >
                            T
                          </label>
                        </div>
                        <div>
                          <input
                            className="input"
                            type="checkbox"
                            id="3"
                            hidden
                            value="3"
                            onBlur={formik.handleBlur}
                            onClick={(e) => {
                              handleColor(e);
                            }}
                            onChange={() => {
                              formik.setFieldValue("day", Day);
                            }}
                          />
                          <label
                            className="Label"
                            for="3"
                            style={{ padding: "17px" }}
                          >
                            W
                          </label>
                        </div>
                        <div>
                          <input
                            className="input"
                            type="checkbox"
                            id="4"
                            hidden
                            value="4"
                            onBlur={formik.handleBlur}
                            onClick={(e) => {
                              handleColor(e);
                            }}
                            onChange={() => {
                              formik.setFieldValue("day", Day);
                            }}
                          />
                          <label
                            className="Label"
                            for="4"
                            style={{ padding: "17px" }}
                          >
                            T
                          </label>
                        </div>
                        <div>
                          <input
                            className="input"
                            type="checkbox"
                            id="5"
                            hidden
                            value="5"
                            onBlur={formik.handleBlur}
                            onClick={(e) => {
                              handleColor(e);
                            }}
                            onChange={() => {
                              formik.setFieldValue("day", Day);
                            }}
                          />
                          <label
                            className="Label"
                            for="5"
                            style={{ padding: "17px" }}
                          >
                            F
                          </label>
                        </div>
                        <div>
                          <input
                            className="input"
                            type="checkbox"
                            id="6"
                            hidden
                            value="6"
                            onBlur={formik.handleBlur}
                            onClick={(e) => {
                              handleColor(e);
                            }}
                            onChange={() => {
                              formik.setFieldValue("day", Day);
                            }}
                          />
                          <label
                            className="Label"
                            for="6"
                            style={{ padding: "17px" }}
                          >
                            S
                          </label>
                        </div>
                      </div>

                      {formik.errors &&
                      formik.errors.day &&
                      formik.touched.day ? (
                        <div style={{ color: "red", fontSize: "0.7rem" }}>
                          {formik.errors.day}
                        </div>
                      ) : null}
                    </div>

                    <div class="container px-0">
                      <div class="row d-flex gap-0">
                        <div class="col-md-5">
                          <div>
                            <div className="d-flex flex-column">
                              <h5>Start Date</h5>

                              <CInput
                                value={formik.values.date}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                type="date"
                                id="date"
                                name="date"
                                placeholder="date"
                                min={new Date().toISOString().slice(0, 10)}
                              />
                              <hr className="hr-div2" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-5">
                          <div className="d-flex flex-column">
                            <h5>End Date</h5>
                            {console.log(formik.values.date, "date-----")}
                            <CInput
                              value={formik.values.custom_date}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              type="date"
                              id="custom_date"
                              name="custom_date"
                              placeholder="custom_date"
                            />

                            <hr className="hr-div2" />
                          </div>
                        </div>
                        <div className="col-md-1"></div>

                        {formik.errors &&
                        formik.errors.custom_date &&
                        formik.touched.custom_date ? (
                          <div className="errmsg pl-3 pt-2">
                            {formik.errors.custom_date}
                          </div>
                        ) : null}
                      </div>

                      <FieldArray name="timings">
                        {(slotsArray) => {
                          const { push, remove, form } = slotsArray;
                          const { values } = form;
                          const { timings } = values;

                          return (
                            <div>
                              {timings.map((timings, outerIndex) => {
                                return (
                                  <div key={outerIndex}>
                                    <div className="row d-flex mt-5 gap-0">
                                      <div className="col-md-5">
                                        <div>
                                          <h5>Start Time</h5>
                                          <TimePicker
                                            allowEmpty={false}
                                            className="w-100"
                                            use12Hours={true}
                                            placeholder="Select start time"
                                            onBlur={formik.handleBlur}
                                            showSecond={false}
                                            id={`timings[${outerIndex}].start_time`}
                                            name={`timings[${outerIndex}].start_time`}
                                            onChange={(e) => {
                                              formik.setFieldValue(
                                                `timings[${outerIndex}].start_time`,
                                                e.format("HH:mm")
                                              );
                                            }}
                                            value={
                                              formik.values.timings[outerIndex]
                                                .start_time
                                                ? moment(
                                                    formik.values.timings[
                                                      outerIndex
                                                    ].start_time,
                                                    "HH:mm"
                                                  )
                                                : null
                                            }
                                            inputReadOnly
                                          />
                                          <hr className="defineslot-hr" />
                                        </div>

                                        <div className="hr-div">
                                          {formik.errors &&
                                          formik.errors.timings &&
                                          formik.errors.timings.length > 0 &&
                                          formik.errors.timings[outerIndex] &&
                                          formik.errors.timings[outerIndex]
                                            .start_time &&
                                          formik.touched &&
                                          formik.touched.timings &&
                                          formik.touched.timings.length > 0 &&
                                          formik.touched.timings[outerIndex] &&
                                          formik.touched.timings[outerIndex]
                                            .start_time
                                            ? formik.errors.timings[outerIndex]
                                                .start_time
                                            : null}
                                        </div>
                                      </div>
                                      <div className="col-md-5">
                                        <div>
                                          <h5>End Time</h5>
                                          <TimePicker
                                            allowEmpty={false}
                                            showSecond={false}
                                            placeholder="Select end time"
                                            use12Hours={true}
                                            className="w-100"
                                            id={`timings[${outerIndex}].end_time`}
                                            name={`timings[${outerIndex}].end_time`}
                                            onChange={(e) => {
                                              if (
                                                !formik.values.timings[
                                                  outerIndex
                                                ].start_time
                                              ) {
                                                toast.error(
                                                  "Please fill start time",
                                                  {
                                                    position:
                                                      toast.POSITION
                                                        .BOTTOM_CENTER,
                                                  }
                                                );
                                                return;
                                              }
                                              formik.setFieldValue(
                                                `timings[${outerIndex}].end_time`,
                                                e.format("HH:mm")
                                              );
                                            }}
                                            value={
                                              formik.values.timings[outerIndex]
                                                .end_time
                                                ? moment(
                                                    formik.values.timings[
                                                      outerIndex
                                                    ].end_time,
                                                    "HH:mm"
                                                  )
                                                : null
                                            }
                                            onBlur={formik.handleBlur}
                                            inputReadOnly
                                          />
                                          <hr className="defineslot-hr" />
                                        </div>

                                          <div className="hr-div">
                                            {formik.errors &&
                                            formik.errors.timings &&
                                            formik.errors.timings.length > 0 &&
                                            formik.errors.timings[outerIndex] &&
                                            formik.errors.timings[outerIndex]
                                              .end_time &&
                                            formik.touched &&
                                            formik.touched.timings &&
                                            formik.touched.timings.length > 0 &&
                                            formik.touched.timings[
                                              outerIndex
                                            ] &&
                                            formik.touched.timings[outerIndex]
                                              .end_time
                                              ? formik.errors.timings[
                                                  outerIndex
                                                ].end_time
                                              : null}
                                          </div>
                                      </div>
                                      <div className="col-md-2"
                                        style={{ marginTop: "33px" }}
                                      >
                                        <div>
                                          <div>
                                            {outerIndex === 0 ? (
                                              <i
                                                onClick={() =>
                                                  push({
                                                    start_time: "",
                                                    end_time: "",
                                                  })
                                                }
                                                style={{ fontSize: "1.4rem" }}
                                                className="fa fa-plus-circle"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                onClick={() =>
                                                  remove(outerIndex)
                                                }
                                                className="fa fa-minus-circle"
                                                style={{ fontSize: "1.4rem" }}
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        }}
                      </FieldArray>
                    </div>
                  </div>
                  <div
                    style={{
                      marginBottom: "2rem",
                      marginTop: "2rem",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <button type="submit">Preview</button>
                  </div>
                </div>
              </form>
            </div>
          </section>
        );
      }}
    </Formik>
  );
}
