import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import sms from "../assets/images/sms-green.png";
import setChatTitle from "../utils/ChatTitle";
import ReactStars from "react-rating-stars-component";
import { useLocation } from "react-router-dom";
import firebase from "firebase";
import { GET } from "../utils/helper";
import { isArray } from "lodash";

const QualitativeDetail = (props) => {
  const location = useLocation();
  const currantEmployeeId = location?.params?.id || null;
  const [ratingdetail, setRatingdetail] = useState(
    props?.location?.state?.item
  );
  const [loading, setLoading] = useState(false);

  const getQualitativeMeasurement = async (employeeId) => {
    try {
      setLoading(true);
      const res = await GET(`getAttributeRatings?employee_id=${employeeId}`);
      setLoading(false);
      if (res?.body?.data?.ratings) {
        setRatingdetail(res?.body?.data?.ratings);
      } else {
        setRatingdetail(null);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currantEmployeeId) {
      getQualitativeMeasurement(currantEmployeeId);
    }
    props.getProfileData();
  }, []);

  return (
    <React.Fragment>
      <div class="Qualitative-measurement-wrapper   qualitative-measurement-wrapper-inline">
        <div class="container">
          <div class="Qualitative-measurement comman-width-container">
            <div class="heding">
              <h1 className="qualitative-heading">Qualitative Measures</h1>
              <hr class="border-pink Qualitative" />
            </div>

            {!loading ? (
              <div className="overflow-auto">
                <table class="table Qualitative-table">
                  <thead>
                    <tr>
                      <th className="width-22">Target</th>
                      <th>Rating</th>
                      <th>Feedback</th>
                      <th>Chat</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isArray(ratingdetail) &&
                      ratingdetail?.map((item) => (
                        <tr>
                          <td className="padding-top-21px">{item?.name}</td>
                          <td className="font-size-35px">
                            {item?.rating ? (
                              <ReactStars
                                {...{
                                  color: "#C9C9C9",
                                  size: 30,
                                  value: item.rating,
                                  edit: false,
                                  activeColor: "#FF7500",
                                }}
                              />
                            ) : (
                              "N/A"
                            )}
                          </td>{" "}
                          <td className="padding-top-21px">
                            {item?.desc ? item.desc : "N/A"}
                          </td>
                          <td>
                            {
                              <img
                                onClick={() => {
                                  if (firebase.messaging.isSupported()) {
                                    setChatTitle(props, item, item?.id);
                                  }
                                }}
                                className="qualiltative-img"
                                src={sms}
                                alt=""
                              />
                            }
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const loading = state.globalReducer.loading;
  const chatPopupData = state.chatReducer.chatPopupData;

  return {
    loading,
    chatRoomdata: state.chatReducer.chatRoomdata,
    chatPopupData,
  };
};

const mapDispatch = (dispatchEvent) => ({
  getSearchData: (payload) =>
    dispatchEvent({ type: "SEARCH_TEAM", value: payload }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
  getchatpopupData: () => dispatchEvent({ type: "GET_CHATPOPUP" }),
  getProfileData: () => dispatchEvent({ type: "GET_PROFILEDATA" }),
});

export default connect(mapStateToProps, mapDispatch)(QualitativeDetail);
