export const SkinColors = [
    {
        name: "Tanned",
        color_code: "rgb(253, 152, 65)",
    },
    {
        name: "Yellow",
        color_code: "rgb(248, 210, 92)",
    },
    {
        name: "Pale",
        color_code: "rgb(255, 219, 180)",
    },
    {
        name: "Light",
        color_code: "rgb(237, 185, 138)",
    },
    {
        name: "Brown",
        color_code: "rgb(208, 139, 91)",
    },
    {
        name: "DarkBrown",
        color_code: "rgb(174, 93, 41)",
    },
    {
        name: "Black",
        color_code: "rgb(97, 67, 53)",
    },
]

export const AvatarPresets = [
    {
        avatarStyle: 'Circle',
        topType: 'LongHairMiaWallace',
        accessoriesType: 'Prescription02',
        hairColor: 'BrownDark',
        facialHairType: 'Blank',
        clotheType: 'Hoodie',
        clotheColor: 'PastelBlue',
        eyeType: 'Happy',
        eyebrowType: 'Default',
        mouthType: 'Smile',
    },
    {
        avatarStyle: 'Circle',
        topType: 'ShortHairShortFlat',
        accessoriesType: 'Prescription01',
        hairColor: 'BlondeGolden',
        facialHairType: 'MoustacheMagnum',
        facialHairColor: 'Blonde',
        clotheType: 'GraphicShirt',
        clotheColor: 'PastelRed',
        graphicType: 'Skull',
        eyeType: 'Happy',
        eyebrowType: 'RaisedExcited',
        mouthType: 'Smile',
    },
    {
        avatarStyle: 'Circle',
        topType: 'LongHairStraight',
        accessoriesType: 'Round',
        hairColor: 'Blonde',
        facialHairType: 'Blank',
        clotheType: 'CollarSweater',
        clotheColor: 'PastelOrange',
        eyeType: 'Happy',
        eyebrowType: 'Angry',
        mouthType: 'Smile',
    },
    {
        avatarStyle: 'Circle',
        topType: 'ShortHairShortWaved',
        accessoriesType: 'Wayfarers',
        hairColor: 'Blonde',
        facialHairType: 'Blank',
        clotheType: 'GraphicShirt',
        clotheColor: 'Heather',
        graphicType: 'Deer',
        eyeType: 'Cry',
        eyebrowType: 'Default',
        mouthType: 'Smile',
    },
    {
        avatarStyle: 'Circle',
        topType: 'ShortHairTheCaesarSidePart',
        accessoriesType: 'Round',
        hairColor: 'Auburn',
        facialHairType: 'BeardMajestic',
        facialHairColor: 'Auburn',
        clotheType: 'BlazerShirt',
        clotheColor: 'PastelGreen',
        eyeType: 'Happy',
        eyebrowType: 'UpDownNatural',
        mouthType: 'Smile',
    },
    {
        avatarStyle: 'Circle',
        topType: 'LongHairBigHair',
        accessoriesType: 'Blank',
        hairColor: 'Auburn',
        facialHairType: 'Blank',
        clotheType: 'ShirtScoopNeck',
        clotheColor: 'Blue03',
        eyeType: 'Happy',
        eyebrowType: 'UpDown',
        mouthType: 'Smile',
    },
    {
        avatarStyle: 'Circle',
        topType: 'LongHairFro',
        accessoriesType: 'Round',
        hairColor: 'Blonde',
        facialHairType: 'BeardLight',
        facialHairColor: 'Blonde',
        clotheType: 'Hoodie',
        clotheColor: 'Black',
        eyeType: 'Happy',
        eyebrowType: 'UpDown',
        mouthType: 'Smile',
    },
    {
        avatarStyle: 'Circle',
        topType: 'Hijab',
        accessoriesType: 'Blank',
        hatColor: 'Blue02',
        clotheType: 'Overall',
        clotheColor: 'Gray02',
        eyeType: 'Happy',
        eyebrowType: 'Default',
        mouthType: 'Smile',
    },
    {
        avatarStyle: 'Circle',
        topType: 'LongHairStraightStrand',
        accessoriesType: 'Blank',
        hairColor: 'Blonde',
        facialHairType: 'Blank',
        clotheType: 'Overall',
        clotheColor: 'Gray02',
        eyeType: 'Happy',
        eyebrowType: 'Default',
        mouthType: 'Smile',
    },
    {
        avatarStyle: 'Circle',
        topType: 'Hat',
        accessoriesType: 'Blank',
        facialHairType: 'BeardMajestic',
        facialHairColor: 'BrownDark',
        clotheType: 'BlazerShirt',
        eyeType: 'Happy',
        eyebrowType: 'Default',
        mouthType: 'Smile',
    },
    {
        avatarStyle: 'Circle',
        topType: 'ShortHairShaggyMullet',
        accessoriesType: 'Blank',
        hairColor: 'Black',
        facialHairType: 'MoustacheFancy',
        facialHairColor: 'Black',
        clotheType: 'ShirtCrewNeck',
        clotheColor: 'Heather',
        eyeType: 'Happy',
        eyebrowType: 'RaisedExcitedNatural',
        mouthType: 'Smile',
    },
    {
        avatarStyle: 'Circle',
        topType: 'LongHairCurvy',
        accessoriesType: 'Blank',
        hairColor: 'Brown',
        facialHairType: 'Blank',
        clotheType: 'ShirtCrewNeck',
        clotheColor: 'Pink',
        eyeType: 'Happy',
        eyebrowType: 'Default',
        mouthType: 'Smile',
    }
]