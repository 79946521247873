import React from "react";
import "./style.scss";

function EnergyHistoryItem({ energy }) {
  return (
    <div className="energy-history-item">
      <div>
        <img src={energy?.emoji?.image_url} alt="" />
        <span>{energy?.emoji?.caption}</span>
      </div>
      <span>{energy?.date}</span>
    </div>
  );
}

export default EnergyHistoryItem;
