import { useQuery } from "@tanstack/react-query";
import instanceAxios from "../config/instance-axios";
import { generateApiEndpointByRole } from "../utils/roles";

export const useEmployeesForMention = (userRole) => {
  const queryKey = ["mentionedEmployees", userRole];
  return useQuery(
    queryKey,
    async () => {
      const response = await instanceAxios.get(
        generateApiEndpointByRole("employeesForMention", userRole)
      );
      return response.data.body;
    },
    {
      enabled: true,
      retry: 1,
    }
  );
};

export const useQualitativeTargetForMention = (userRole) => {
  const queryKey = ["mentionedQualitativeTargets", userRole];
  return useQuery(
    queryKey,
    async () => {
      const response = await instanceAxios.get(
        generateApiEndpointByRole("qualitativeForMention", userRole)
      );
      return response.data.body;
    },
    {
      enabled: true,
      retry: 1,
    }
  );
};

