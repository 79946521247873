import React, { useEffect, useState } from "react";
import search from "../../assets/images/search.png";
import happy from "../../assets/images/happy1.png";
import pro from "../../assets/images/profilePlaceholder/default_image.png";

import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { GET, putAPI } from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import firebase from "firebase";

const EmployerDashboard = (props) => {
  const [keyword, setKeyword] = useState("");
  const history = useHistory();

  const [errorText, setErrorText] = useState("");
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [EmployerteamList, setEmployerteamList] = useState([]);

  const [key, setKey] = useState(false);
  const dispatch = useDispatch();
  console.log('firebase.messaging.isSupported()', firebase.messaging.isSupported())
  const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;
  const getFcmToken = async () => {
    const fcmToken = await messaging?.getToken();
    console.log(fcmToken,"fcmTokenfcmTokenfcmToken");
    if (fcmToken) {
      localStorage.setItem("fcm_token", fcmToken);
      putAPI("updateEmployerDeviceToken", { device_token: fcmToken })
        .then((res) => {})
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("Failed", "No token received");
    }
  };

  useEffect(() => {
    dispatch({ type: "GET_NOTIFICATION_COUNT" });
    getFcmToken();
  }, [])
  
  useEffect(() => {
    props.getEmployerTeamList({ keyword });

    EmployerteamList &&
      EmployerteamList?.map((item) => {
        let lastUpdate = moment().diff(
          moment(item.energy_last_updated),
          "hours"
        );
        if (
          item.energy_emoji_data &&
          item.energy_emoji_data.image_url &&
          lastUpdate <= 24
        ) {
          setKey(true);
        }
      });

    const getData = async () => {
      try {
        const res = await GET(`getProfile`, 1);

        setProfileData(res.body.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getList = async () => {
      try {
        let query = "";
        if (keyword) {
          query = `searchKey=${keyword}`;
        }
        setLoading(true);
        const res = await GET(`getEmployeeList?${query}`, 1);

        if (res.status === 402) {
          toast.error(res.message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 5000,
          });
        }

        if (
          res.body.data &&
          res.body.data.rows &&
          res.body.data.rows.length > 0
        ) {
          setErrorText("");
          const data = res.body.data.rows.filter((item) => {
            let lastUpdate = moment().diff(
              moment(item.energy_last_updated),
              "hours"
            );
            return lastUpdate <= 24;
          });

          if (data.length === 0) {
            if (keyword !== "") {
              setErrorText("No employees updated their energy checks yet");
              setLoading(false);

              return;
            }

            setErrorText("No employees updated their energy checks yet");
          } else {
            setErrorText("");
          }

          setEmployerteamList(data);
          setLoading(false);
        } else {
          setLoading(false);

          if (keyword !== "") {
            setErrorText("No employees found");
          } else {
            setErrorText("No Employee Added");
          }
        }
      } catch (error) {
        setLoading(false);

        console.log(error);
      }
    };

    getData();
    getList();
  }, [keyword]);

  const handleChange = (e) => {
    e.preventDefault();
    setKeyword(e.target.value);
  };

  useEffect(() => {
    if(profileData && profileData.paymentstatus == "paid"){
      props.updatePayment({ paymentStatus: 'paid' })
    }

  }, [profileData]);
  return (
    <div style={{ minHeight: "80vh" }}>
      <div class="search-wrapper">
        <div class="container">
          <form class="search" onSubmit={(e) => e.preventDefault()}>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="Search by name, email address or mobile number"
                value={keyword}
                onChange={(e) => handleChange(e)}
              />
              <img src={search} alt="search" />
            </div>
          </form>
        </div>
      </div>
      <section class="My-Profile-wrapper ">
        <div class="My-Profile  employerDashboard-my-profile" 
     
        >
          <h2>My Profile</h2>
          <hr
        
          class="border-pink  employee-goal-button" />
          {!loading ? (
            <>
              <div class="profile-img pb-2 mt-5">
                <div

                
                  class="profile  profile-edit"
                >
                  <img
                    alt=""
                 
                    class="img-pro  img-pro-inline"
                    src={profileData?.profile_pic_url || pro}
                  />
                  <p  className="employer-dashboard-inline"
                    onClick={() => history.push("/editemployerprofile")}
                  
                  >
                    Edit
                  </p>
                </div>
              </div>
              <div class="form-information">
                <div
              
                 class="information  title-wrap">
                  <span>Your name</span>
                  <p>{profileData?.name}</p>
                </div>

                <div
              
                 class="information  title-wrap">
                  <span>Address</span>
                  <p>{profileData?.address}</p>
                </div>
                <div class="information">
                  <span>Phone number</span>
                  <p>{`${profileData?.country_code} ${profileData?.phone_number}`}</p>
                </div>
                <div class="information">
                  <span>Email address</span>
                  <p>{profileData?.email}</p>
                </div>
                <div 
            
                class="information  title-wrap">
                  <span>Industry Type</span>
                  <p className="textwrap" style={{ textAlign: "left" }}>
                    {profileData?.industry_info?.name}
                  </p>
                </div>
                <div
             
                 class="information title-wrap">
                  <span>No of Employees</span>
                  <p className="textwrap" style={{ textAlign: "left" }}>
                    {profileData?.no_of_employee}
                  </p>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </section>

      {!loading ? (
        !errorText ? (
          EmployerteamList &&
          EmployerteamList?.map((item, id) => {
            return item.energy_emoji_data &&
              item.energy_emoji_data.image_url ? (
              <div class="Feeling-wrapper">
                <div class="container">
                  <div class="Feeling">
                    <p>
                      {item?.name}
                      <span class="float-right">
                        {" "}
                        <img
                          src={
                            item?.energy_emoji_data?.image_url
                              ? item?.energy_emoji_data.image_url
                              : happy
                          }
                          alt=""
                        />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            ) : null;
          })
        ) : (
          <div className="teammsg">{errorText}</div>
        )
      ) : (
        <div
          style={{ marginTop: "12rem" }}
          class="spinner-border text-primary"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const EmployerteamList = state.teamReducer.EmployerteamList;
  const errorMsg = state.teamReducer.errorMsg;
  const totalTeamCount = state.teamReducer.totalTeamCount;
  const loading = state.globalReducer.loading;
  return {
    EmployerteamList,
    loading,
    errorMsg,
    totalTeamCount,
    employerloginData: state.loginReducer.employerloginData,
    teamEnergy: state.teamReducer.teamEnergy,
  };
};

const mapDispatch = (dispatchEvent) => ({
  getEmployerTeamList: (payload) =>
    dispatchEvent({ type: "GET_EMPLOYERTEAMLIST", value: payload }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
  updatePayment: (payload) => dispatchEvent({ type: "UPDATE_PAYMENT_STATUS", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(EmployerDashboard);
