import React from "react";
import { CSpinner } from "@coreui/react";

function LoadingWidget() {
  return (
    <CSpinner
      size="sm"
      grow
      color="primary"
      style={{ width: "3rem", height: "3rem" }}
    />
  );
}

export default LoadingWidget;
