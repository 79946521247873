import { call, put } from "redux-saga/effects";
import * as Action from "../type.action";
import { GET } from "../../utils/helper";
import "react-toastify/dist/ReactToastify.css";
import { GET_ACHIEVEMENT_COMMENTS } from "./ApiConstant";

export function* getAchievementComment(action) {
  try {
    const commentsData = yield call(
      GET,
      GET_ACHIEVEMENT_COMMENTS + `/${action.id}`
    );

    if (commentsData.status === 200) {
      yield put({
        type: Action.ACHIEVEMENT_COMMENTS_SUCCESS,
        data: commentsData.body.data,
        msg: "Success!!",
      });
    } else {
      yield put({
        type: Action.ACHIEVEMENT_COMMENTS_FAIL,
        data: commentsData.message,
      });
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      yield put({
        type: Action.ACHIEVEMENT_COMMENTS_FAIL,
        data: error.response.data.message,
      });
    } else {
      yield put({
        type: Action.ACHIEVEMENT_COMMENTS_FAIL,
        data: "something went wrong",
      });
    }
  }
}
