import React, { useState } from 'react'
import logofree from "../../../../src/assets/images/logo-free.png"
import freeFile from "../../../../src/assets/images/free-file.png";
import { useHistory } from "react-router-dom";
import { GET } from "../../../utils/helper";
import { toast } from "react-toastify"

export default function NoPlan() {
    const history = useHistory();
    return (
        <div>
            <div class="free-trial-sign">
                <div class="container">
                    <div class="free-trial-img-logo">
                        <img class="logo" src={logofree} alt="" />
                        <img class="edit-free-trail" src={freeFile} alt="" />
                    </div>
                </div>
                <div class="trail-info-wrapper">
                    <div class="container">
                        <div class="trail-info">
                            <div class="trial-btn">
                                <button >
                                    Your subscription  plan has expired.<br />
                                    Please Subscribe to continue your services
                                </button>
                            </div>
                            <div class="Subscription-btn">
                                <button onClick={() => history.push('/planlist')}>
                                    Pay Now
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
