import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router";

import { GET } from "../utils/helper";
import QualitativeRatingForm from "./QualitativeRatingForm";
import { isArray } from "lodash";

const ManagerRating = (props) => {
  const employee_id = props?.history?.location?.state?.id;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [employeeRates, setEmployeeRates] = useState(null);

  useEffect(() => {
    const getList = async () => {
      try {
        setLoading(true);
        const result = await GET(
          `getAttributeRatingsGrouped?employee_id=${employee_id}`
        );
        setLoading(false);
        if (result?.body?.data) {
          setEmployeeRates(result?.body?.data);
        }
      } catch (error) {
        setLoading(false);
      }
    };
    getList();
  }, []);

  return (
    <div className="min-height-60vh">
      <section class="Qualitative-rating-wrapper">
        <div class="container">
          {!loading ? (
            employeeRates && typeof employeeRates === "object" ? (
              Object.entries(employeeRates).map(([key, items]) => {
                return (
                  <QualitativeRatingForm
                    ratingsData={items}
                    qualitativeTargetName={
                      items.length && items[0]?.attribute?.name
                    }
                    attributeId={items.length && items[0]?.attribute_id}
                    id={items.length && items[0]?.id}
                    onSuccess={() => history.push("/team")}
                     employeeId={items.length && items[0]?.employee_id}
                     employerId={items.length && items[0]?.employer_id}
                  />
                );
              })
            ) : (
              <div style={{ paddingTop: "5rem" }} className="teammsg">
                No Attributes available for rating
              </div>
            )
          ) : (
            <div
              style={{ marginBottom: "4rem" }}
              class="spinner-border text-primary"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};
const mapStateToProps = (state) => {
  const teamDetailData = state.teamReducer.teamDetailData;
  const errorMsg = state.ratingReducer.errorMsg;
  const loading = state.globalReducer.loading;
  return {
    teamDetailData,
    loading,
    errorMsg,
    RatingPopupData: state.ratingReducer.RatingPopupData,
  };
};

const mapDispatch = (dispatchEvent) => ({
  addRating: (payload) =>
    dispatchEvent({ type: "ADD_MANAGERRATING", value: payload }),
  getQualitativepopupdata: () =>
    dispatchEvent({ type: "GET_QUALITATITVEPOPUPDATA" }),
});

export default connect(mapStateToProps, mapDispatch)(ManagerRating);
