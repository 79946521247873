import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/style.scss";
import "./App.css";
import MainContainer from "./Components/Main";
import NetworkDetector from "./Components/NetworkDetector";

import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import queryClient from "./utils/queryClient";

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <MainContainer />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  );
}

export default NetworkDetector(App);
