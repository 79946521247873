import { call, put, apply } from 'redux-saga/effects'
import * as Action from '../type.action'
import { POST, GET, RESETPOST } from '../../utils/helper'
import 'react-toastify/dist/ReactToastify.css';
import { GET_ACHIEVEMENT_LIST } from "./ApiConstant";


export function* getAchievementList(action) {
    

    try {
        if (action.showLoading) {
            yield put({ type: Action.LOADING_SHOW })
        }
        const queryParams = [];
        
        if (action?.mention) {
          queryParams.push(`employee_id=${action.mention}`);
        }

        if (action.tag) {
          queryParams.push(`qualitative_id=${action.tag}`);
        }
        const Data = yield call(GET, `${GET_ACHIEVEMENT_LIST}${queryParams.length > 0 ? '?' + queryParams.join('&') : ''}`);


        if (Data.status === 200) {
            yield put({ type: Action.LOADING_HIDE })

            yield put({ type: Action.ACHIEVEMENT_LIST_SUCCESS, data: Data.body.data.achievements, msg: 'Success!!' });

        } else {
            yield put({ type: Action.LOADING_HIDE })

            yield put({ type: Action.ACHIEVEMENT_LIST_FAIL, data: Data.message })
        }

    } catch (error) {
        yield put({ type: Action.LOADING_HIDE })

        if (error.response && error.response.data && error.response.data.message) {
            yield put({ type: Action.ACHIEVEMENT_LIST_FAIL, data: error.response.data.message })

        } else {
            yield put({ type: Action.ACHIEVEMENT_LIST_FAIL, data: 'something went wrong' })
        }
    }
}