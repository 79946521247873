import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import pro from "../../assets/images/profilePlaceholder/profilePlaceholder.png";
import { GET } from "../../utils/helper";
import moment from "moment";
import ReactStars from "react-rating-stars-component";
export default function SessionsHistoryDetail() {
  const [sessions, setsessions] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await GET(`getSessionHistoryDetails/${id}`);
        if (result.status === 200) {
          setsessions(result?.body?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [id]);

  return (
    <section style={{ minHeight: "80vh" }} class="profiles-wrapper-main">
      <div class="container">
        <h3 style={{ textAlign: "center", justifyContent: "center" }}>
          Session Detail
        </h3>
        {sessions && (
          <div class="profiles-wrapper">
            <div class="profile-comman-box">
              <div
                class="row"
                style={{ textAlign: "center", justifyContent: "center" }}
              >
                <div class="col-md-12 comman-width one">
                  <div
                    class="main-profiles"
                    style={{ width: "458px", margin: "0 auto" }}
                  >
                    <div class="profile-img">
                      <img
                        className="request-img"
                        src={sessions?.employee?.profile_pic_url || pro}
                        alt=""
                      />
                    </div>
                    <div class="row no-gutters">
                      <div className="labelstyless">
                        <div className="labelstyle">
                          <p>Session Date :</p>
                          <p className="labelstyless">
                            <p>{sessions?.date}</p>{" "}
                          </p>
                        </div>
                        <div className="labelstyle">
                          <p>Session Time :</p>
                          <p className="labelstyless">
                            <p>
                              {sessions?.start_time
                                ? moment(sessions.start_time.slice(0, 5), [
                                    "HH.mm",
                                  ]).format("hh:mm a")
                                : ""}
                              -{" "}
                              {sessions?.end_time
                                ? moment(sessions.end_time.slice(0, 5), [
                                    "HH.mm",
                                  ]).format("hh:mm a")
                                : ""}{" "}
                            </p>
                          </p>
                        </div>
                        <div className="labelstyle">
                          <p>Team Level :</p>
                          <p className="labelstyless">
                            <p>{sessions?.employee_rank?.name}</p>
                          </p>
                        </div>

                        <div className="labelstyless">
                          <p>Challenge Details :</p>
                          <p className="labelstyless">
                            <p>{sessions?.query}</p>{" "}
                          </p>
                        </div>
                        <div className="labelstyle">
                          <p>Performance Challenge :</p>
                          <p className="labelstyless">
                            <p>
                              {sessions?.coach_specialization_category?.name}
                            </p>{" "}
                          </p>
                        </div>

                        <div className="labelstyle">
                          <p>Status :</p>
                          <p className="labelstyless">
                            <p>
                              {sessions.status === 1
                                ? "Pending"
                                : sessions.status === 2
                                ? "Accepted"
                                : sessions.status === 3
                                ? "Rejected"
                                : sessions.status === 4
                                ? "Cancelled"
                                : "Completed"}
                            </p>{" "}
                          </p>
                        </div>

                        <div className="labelstyle">
                          <p>Ratings :</p>
                          <p className="labelstyless">
                            <p>
                              {" "}
                              <ReactStars
                                {...{
                                  color: "#C9C9C9",
                                  size: 20,
                                  value: sessions.coach_rating,
                                  edit: false,
                                  activeColor: "#FF7500",
                                }}
                              />
                            </p>
                          </p>
                        </div>
                        {sessions.cancelled_by === 0 && (
                          <div className="labelstyle">
                            <p>Call duration :</p>
                            <p className="labelstyless">
                              <p>{sessions?.call_duration} mins</p>
                            </p>
                          </div>
                        )}
                        <div className="labelstyle">
                          <p>Comment :</p>
                          <p className="labelstyless">
                            <p>{sessions?.comment}</p>
                          </p>
                        </div>

                        <div className="labelstyle">
                          <p>Cancelled by :</p>
                          <p className="labelstyless">
                            {sessions.cancelled_by === 0
                              ? "Not Cancelled"
                              : sessions.cancelled_by === 1
                              ? "Coach"
                              : sessions.cancelled_by === 2
                              ? "Employee"
                              : ""}
                          </p>
                        </div>

                        <div className="ladelstyle">
                          <p>Amount :</p>
                          <p className="labelstyless">
                            <p>{sessions.amount}</p>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
