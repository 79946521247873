import React, { useEffect, useState } from "react";
import video from "../assets/images/video.png";
import call from "../assets/images/call.png";
import firebase from "firebase";
import { connect } from "react-redux";
import Avatar from "react-avatar";
import { getTime } from "../utils/validation";
// import { getFCMToken } from "../Components/Common/Queries";
import profile1 from "../assets/images/profile1.png";
import { sendFcmNotification } from "../Components/Common/firebaseApi";
import Democall from "./Democall";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getMedia from "../utils/permission";
import { deleteChatThread } from "../utils/helper";
import {
  CModal,
  CModalHeader,
  CModalFooter,
  CModalBody,
  CButton,
  CModalTitle,
} from "@coreui/react";

const ChatThread = (props) => {
  const [path, setPath] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const showComp = useSelector((state) => state.unmountReducer.showFromChat);
  const profile_pic_url = useSelector(
    (state) => state.profileReducer?.profileData?.profile_pic_url
  );
  const dispatch = useDispatch();
  const messaging = firebase.messaging.isSupported()
    ? firebase.messaging()
    : null;

  const {
    getData,
    renderLastMessageOnDelete,
    profilePic,
    name,
    item,
    roomId,
    updateLastMessageForRoom,
    lastMessageTime,
    lastMessageId,
    lastMessage,
    isMessageRead,
    refresh,
    setRefresh,
    chatLastDeletedOn,
  } = props;

  var messgaesListner = null;
  useEffect(() => {
    observerLastMessageForRoom(roomId);
    return () => {
      if (messgaesListner) {
        messgaesListner();
      }
    };
  }, [roomId, renderLastMessageOnDelete]);

  const observerLastMessageForRoom = (id) => {
    var convertedTimestamp = new Date(chatLastDeletedOn);

    const executor = async (resolve, reject) => {
      try {
        const roomCollection = firebase.firestore().collection("chats_dev");
        const messagesCollection = roomCollection
          .doc(id)
          .collection("messages")
          .where("timestamp", ">=", String(convertedTimestamp.getTime()));
        const query = messagesCollection.orderBy("timestamp", "desc").limit(1);
        const roomRef = roomCollection.doc(id.toString());
        const snap1 = await roomRef.get();
        if (snap1.empty) {
          resolve(null);
        } else {
          messgaesListner = query.onSnapshot((snapshot) => {
            let message = {};
            try {
              if (snapshot.empty) {
                console.log("emptyyyyyyyyyyyyyyyyy");
              } else {
                snapshot.docChanges().forEach((change) => {
                  if (change.type === "added") {
                    message = change.doc.data();
                  } else if (change.type === "modified") {
                    message = change.doc.data();
                  }
                });
                if (message._id !== lastMessageId) {
                  onNewMessageReceived(message);
                }
              }
            } catch (err) {
              reject(err);
            }
          });
          resolve("Done");
        }
      } catch (error) {
        console.log(error);
        reject(error);
      }
    };
    return new Promise(executor);
  };

  const onNewMessageReceived = (message) => {
    updateLastMessageForRoom(roomId, message);
  };

  const openChat = async (e, data) => {
    e.stopPropagation();
    const Name = localStorage.getItem("name");
    const userId = localStorage.getItem("id");

    if (data.type === 2) {
      let user = {
        id: data.id,
        is_manager: null,
        name: data.name,
        profile_pic_url: data.profilePic,
        status: 1,
      };

      let newdata = {
        id: data.id,
        User: {
          name: Name,
          id: userId,
          profile_pic_url: profile_pic_url,
        },
        room_id: data.room_id,
        status: 1,
        other_user: user,
        chatLastDeletedOn: chatLastDeletedOn,
      };
      dispatch({ type: "SET_GRP_CHAT", payload: newdata });

      setTimeout(() => {
        props.history.push({
          pathname: "/groupChatDetail",
          state: {
            title: "",
            id: data?.id,
            room_id: data.room_id,
            is_disabled: item.is_disabled,
          },
        });
      }, 1000);
      return;
    }

    let user = {
      id: data.id,
      is_manager: null,
      name: data.name,
      profile_pic_url: data.profilePic,
      status: 1,
    };
    let newData = {
      id: data.id,
      user: {
        name: Name,
        id: userId,
        profile_pic_url: profile_pic_url,
      },
      room_id: data.room_id,
      status: 1,
      other_user: user,
      chatLastDeletedOn: chatLastDeletedOn,
    };
    dispatch({ type: "SET_CHAT", payload: newData });

    setTimeout(() => {
      props.history.push({
        pathname: data?.type == 4 ? "/employerchat" : "/coachchatdetail",
        state: {
          title: "",
          id: data?.id,
          room_id: data.room_id,
          is_disabled: item.is_disabled,
        },
      });
    }, 2000);
  };

  const changeView = (type, name) => {
    if (type === "timeout") {
      toast.error(`${name} is not available`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }

    if (type === "Call Ended") {
      toast.error("Call Ended", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }

    dispatch({ type: "MOUNTFROMCHAT" });
    setPath(null);
  };

  useEffect(() => {
    if (path) {
      if (path.joinCall === true) {
        dispatch({ type: "UNMOUNTFROMCHAT" });
      }
    }
  }, [path, dispatch]);

  const userName = localStorage.getItem("name");
  const userId = localStorage.getItem("id");
  const startVideoCallFun = async (e, i, isVideo) => {
    const res = await getMedia();
    if (!res) {
      toast.error(`Please give Video and Audio permission`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    localStorage.setItem("subs", null);
    e.stopPropagation();
    setTimeout(() => sendSignalToOtherUser(i, isVideo), 3000);

    setPath((state) => {
      return {
        ...state,

        userId: userId,
        joinCall: true,
        otherUserName: i.user.name,
        userName: userName,
        image: i.user.profile_pic_url,
        item: i,
        isVideo: isVideo,
      };
    });
  };
  const sendSignalToOtherUser = async (item, isVideo) => {
    try {
      var secondUserId = userId;
      // var token = await getFCMToken(secondUserId + "");
      const fcmToken = await messaging?.getToken();
      var firstUser = item.user.name;
      var secondUser = {
        firstName: userName ? userName : "",

        userId: secondUserId + "",
      };

      let msg = isVideo
        ? "Incoming video call from"
        : "Incoming Audio call from";
      await sendFcmNotification(fcmToken, msg, userName, {
        type: "2",
        firstUser,
        secondUser,
        firstUserId: userId,
        secondUserId,
        room_id: item?.room_id,
      })
        .then((response) => {
          console.log(response, "response from chatThread");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log("Error in VIDEO CALL MESSAGE", e);
    }
  };

  const handleDeleteModal = (e) => {
    e.stopPropagation();

    setDeleteModal(!deleteModal);
  };

  const handleDelete = async () => {
    setDeleteModal(!deleteModal);

    try {
      const res = await deleteChatThread(`clearChat`, { chat_room_id: roomId });
      if (res.status === 200) {
        setRefresh(!refresh);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return showComp && path ? (
    <Democall
      removeComp={changeView}
      isVideo={path.isVideo}
      joinCall={path.joinCall}
      otherUserName={path.otherUserName}
      userName={path.userName}
      item={path.item}
      image={path.image}
      userId={path.userId}
    />
  ) : (
    <React.Fragment>
      <CModal
        show={deleteModal}
        centered={true}
        backdrop={true}
        color="warning"
        onClose={setDeleteModal}
      >
        <CModalHeader>
          <CModalTitle>Delete Chat?</CModalTitle>
        </CModalHeader>
        <CModalBody>Are you sure you want to Delete this Chat?</CModalBody>
        <CModalFooter style={{ display: "flex", flexDirection: "row" }}>
          <CButton
            style={{
              width: "6rem",
              height: "3rem",
              backgroundColor: "#F56A14",
              color: "white",
            }}
            onClick={handleDelete}
          >
            Yes
          </CButton>{" "}
          <CButton
            style={{
              width: "6rem",
              height: "3rem",
              backgroundColor: "#4B9CEB",
              color: "white",
            }}
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
      <div
        class="chat-details comman-width-container"
        onClick={(e) => openChat(e, item)}
      >
        <div class="row">
          <div class="col-md-6 col-sm-6 col-6">
            <div class="media ">
              {profilePic ? (
                <img
                  src={profilePic}
                  alt="worker"
                  class="mr-3 rounded-circle"
                />
              ) : (
                <Avatar
                  color={Avatar.getRandomColor("sitebase", [
                    "red",
                    "green",
                    "blue",
                  ])}
                  name={name}
                  size="50"
                  round={true}
                  class="mr-3 rounded-circle"
                  style={{ margin: "0 16 0 0 " }}
                />
              )}
              <div class="media-body">
                <h5>{name}</h5>
                {lastMessage ? (
                  <p className={`${!isMessageRead ? "color-pink" : ""}`}>
                    {lastMessage}
                  </p>
                ) : (
                  <p></p>
                )}
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-6">
            <div class="socil-img float-right">
              {lastMessageTime ? (
                <p className={`${!isMessageRead ? "color-pink-time" : ""}`}>
                  {" "}
                  {getTime(lastMessageTime)}
                </p>
              ) : (
                <p></p>
              )}
              <i
                style={{
                  color: "#2786E2",
                  marginRight: "1rem",
                  cursor: "pointer",
                }}
                onClick={handleDeleteModal}
                class="fas fa-trash-alt"
              ></i>

              {!item.is_disabled && item.type !== 2 ? (
                <>
                  <img
                    src={video}
                    alt="video"
                    title="Start a Video Call"
                    onClick={(e) => {
                      e.stopPropagation();
                      startVideoCallFun(e, item, true);
                    }}
                  />
                  <img
                    src={call}
                    alt="call"
                    title="Start an Audio Call"
                    onClick={(e) => {
                      e.stopPropagation();
                      startVideoCallFun(e, item, false);
                    }}
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  const loading = state.globalReducer.loading;
  return {
    chatUser: state.chatReducer.chatUser,
    loading,
  };
};

const mapDispatch = (dispatchEvent) => ({
  getChatUser: (payload) =>
    dispatchEvent({ type: "Get_ChatUser", value: payload }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
  creteChatRoom: (payload) =>
    dispatchEvent({ type: "CREATECHAT_ROOM", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(ChatThread);
