import * as actions from "../type.action";
const initialState = {
  employerData: [],
  errorMsg: "",
  addemployermsg: "",
  addemployererrorMsg: "",
  employerDataLength: 1,
  industryData: [],
};

const employerReducer = (state, action) => {
  if (state === undefined) {
    return (state = initialState);
  }
  switch (action.type) {
    case actions.EMPLOYER_DATASUCCESS:
      return {
        ...state,
        employerData: action.data.rows,
        employerDataLength: action.data.count,
      };
    case actions.EMPLOYER_DATAFAIL:
      return {
        ...state,
        errorMsg: action.data,
      };
    case actions.ADDEMPLOYER_DATASUCCESS:
      return {
        ...state,
        addemployermsg: action.msg,
      };
    case actions.ADDEMPLOYER_DATAFAIL:
      return {
        ...state,
        addemployererrorMsg: action.msg,
      };
    case actions.INDUSTRY_DATASUCCESS:
      return {
        ...state,
        industryData: action.data,
      };
    case actions.INDUSTRY_DATAFAIL:
      return {
        ...state,
        addemployererrorMsg: action.data,
      };

    default:
      return state;
  }
};
export default employerReducer;
