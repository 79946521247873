import * as actions from '../type.action'
const initialState = {
  profileData: {},
  readProfileData: {},
  errorMsg: '',
  imageError: '',
  sucessMsg:''
}

const profileReducer = (state, action) => {
  if (state === undefined) {
    return (state = initialState)
  }
  switch (action.type) {
    case actions.PROFILE_DATASUCCESS:
      return {
        ...state,
        profileData: action.data,
        readProfileData: action.data,
        sucessMsg:action.msg
      }
    case actions.PROFILE_DATAFAIL:
      return {
        ...state,
        errorMsg: action.data,

      }
    case 'handleProfileChange':
      console.log('handleprofile', action)
      return {
        ...state,
        profileData: { ...state.profileData, ...action.data },
        sucessMsg:'',
        errorMsg:''
      }
    case 'GET_EMPLOYER_DATA':
      return {
        ...state,
        profileData: { ...state.profileData, ...action.data },
        sucessMsg:'',
        errorMsg:''
      }
    case actions.PROFILE_IMAGEFAIL:
      return {
        ...state,
        imageError: action.data,
        errorMsg: action.data,
      }

    default:
      return state
  }
}
export default profileReducer
