import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { GET } from "../../utils/helper";
import { timeAgo } from "../../utils/validation";
import { withRouter } from "react-router-dom";
import newgoalImage from "../../assets/images/notification/newgoal.png";
import audiocallImage from "../../assets/images/notification/audiocall.png";
import audiomissImage from "../../assets/images/notification/audiomiss.png";
import goalacceptedImage from "../../assets/images/notification/goalaccepted.png";
import goalcompleteimage from "../../assets/images/notification/goalcomplete.png";
import goalrejectedImage from "../../assets/images/notification/goalrejected.png";
import highfiveImage from "../../assets/images/notification/highfive.png";
import likeImage from "../../assets/images/notification/like.png";
import message from "../../assets/images/notification/message.png";
import rating from "../../assets/images/notification/rating.png";
import videomiss from "../../assets/images/notification/videomiss.png";
import videocall from "../../assets/images/notification/videocall.png";
import freetrial from "../../assets/images/notification/freetrial.png";

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      notifications: [],
    };
  }
  componentDidMount() {
    this.loadData(true);
  }
  loadData = (showLoader) => {
    showLoader && this.setState({ isLoading: true });
    GET(`getNotifications`)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ isLoading: false });
          this.props.notificationCountFunction();
          if (
            res &&
            res.body &&
            res.body.data &&
            res.body.data.rows &&
            res.body.data.rows.length > 0
          ) {
            this.setState({ notifications: res.body.data.rows });
          }
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log("getNotifications api error------->", error);
      });
  };

  renderItem = (item, index) => {
    const { data, createdAt, status } = item;
    const title = data.title ? data.title : "";
    const body = data.message ? data.message : "";
    const timestamp = new Date(createdAt).getTime();
    let icon;

    switch (item.type) {
      case 1:
        icon = newgoalImage;
        break;
      case 2:
        icon = goalcompleteimage;
        break;
      case 3:
        icon = rating;
        break;
      case 5:
        icon = audiocallImage;
        break;
      case 6:
        icon = videocall;
        break;
      case 7:
        icon = goalacceptedImage;
        break;
      case 8:
        icon = goalrejectedImage;
        break;
      case 10:
        icon = audiomissImage;
        break;
      case 11:
        icon = videomiss;
        break;
      case 14:
        icon = highfiveImage;
        break;
      case 13:
        icon = likeImage;
        break;
      case 15:
        icon = message;
        break;
      case 16:
        icon = freetrial;
        break;
      default:
        break;
    }
    return (
      <div
        onClick={() => {
          if (item.type === 2) {
            this.props.setModalOpen(false);
            this.props.history.push(
              `/goalaproval/${Number(item.type_id)}/${Number(
                item.team_goal_assign_id
              )}`
            );
          }

          if (item.type === 12) { // Mention notification
            this.props.setModalOpen(false);
            this.props.history.push(`/achievement/${Number(item.data.id)}`);
          }

          if (item.type === 13 || item.type === 14 || item.type === 15) {
            this.props.setModalOpen(false);
            this.props.history.push(`/achievement/${Number(item.data.id)}`);
          }

          if (item.type === 7 || item.type === 8) {
            this.props.setModalOpen(false);
            this.props.history.push(
              `/goalssubmissionhistory/${Number(item.data.goal_id)}`
            );
          }

          if (item.type === 1) {
            this.props.setModalOpen(false);
            this.props.history.push(`/empgoals`);
          }
          if (item.type === 3) {
            this.props.setModalOpen(false);
            this.props.history.push({
              pathname: "/qualitativedetail",
              params: { id: item?.data?.senderEmplyeeData?.id },
            });
          }
        }}
        class="Notification-chat"
        style={{
          border: status === 1 ? "1px solid #ED8423 " : "1px solid #000000",
        }}
      >
        <div style={{ display: "flex" }}>
          {icon && (
            <img
              src={icon}
              style={{ marginRight: "0.5rem", width: "24px", height: "24px" }}
              alt=""
            />
          )}
          <h5>{title}</h5>
        </div>
        <p style={{ paddingBottom: "5px" }}>
          {body}
          <span class="float-right">{timeAgo(timestamp)}</span>
        </p>
      </div>
    );
  };

  render() {
    const { notifications, isLoading } = this.state;
    return (
      <div class="Notification-main-wrap">
        <div class="Notification-head">
          <h2>Notification</h2>
        </div>

        <div class="Notification-body">
          {this.state.isLoading && (
            <div
              style={{ left: "61%" }}
              class="spinner-border text-primary"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          )}
          {notifications &&
            notifications.length > 0 &&
            notifications.map((item, id) => this.renderItem(item, id))}
          {!this.state.isLoading && notifications.length == 0 && (
            <div class="Notification-body text-center d-flex align-items-center justify-content-center" style={{ color: "orange" }}>
              No Notifications Found
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    // showLook: state.globalReducer.showLook
  };
};

const mapDispatch = (dispatchEvent) => ({
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatch)
)(Notification);
