import { useEffect } from "react";
import config from "../config";

function RedirectToWebView(props) {
  const { match } = props;
  useEffect(() => {
    window.location.replace(`${config.BASE_URL_WEB_VIEW}${match.path == "/home"? "/": match.path }`);
  }, [match.path]);
  return null;
}

export default RedirectToWebView;
