import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Svgs from "../../assets/Svgs";
import Loader from "../../Components/Common/Loader";
import useFAQ from "./helper";

const EditMode = ({
  Loading,
  RowData,
  setRowData,
  setFAQForm,
  setDelete,
  setEdit,
}) => {
  const { updateFAQSorting } = useFAQ();
  const moveRow = (result) => {
    if (!result.destination) {
      return;
    }
    const updatedRows = Array.from(RowData);
    const [movedRow] = updatedRows.splice(result.source.index, 1);
    updatedRows.splice(result.destination.index, 0, movedRow);
    let sortedRow = updatedRows.map((ele, i) => ({
      id: ele?.id,
      index: i + 1,
    }));
    updateFAQSorting({
      faqs: sortedRow,
    });
    setRowData(updatedRows);
  };
  return (
    <>
      <DragDropContext onDragEnd={moveRow}>
        <Droppable droppableId="rows" type="TABLE_ROW">
          {(provided) => (
            <div
              className="w-100"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {Loading ? (
                <div>
                  <Loader />
                </div>
              ) : (
                RowData?.map((item, i) => {
                  return (
                    <>
                      <Draggable
                        key={item.id}
                        draggableId={item.id.toString()}
                        index={i}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`goal-item-content goalButton faq mt-4 p-3 cursor-pointer transition-all h-auto`}
                          >
                            <div className="d-flex align-items-center justify-content-between w-100">
                              <p className="goal-item-title">
                                <strong className="word-break">
                                  {item?.question}
                                </strong>
                              </p>
                              <div
                                className={`transition-all d-flex align-items-center gap-2`}
                              >
                                <div
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setFAQForm(true);
                                    setEdit({ status: true, data: item });
                                  }}
                                >
                                  <Svgs.Edit />
                                </div>
                                <div
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setDelete({ status: true, id: item?.id });
                                  }}
                                >
                                  <Svgs.Delete />
                                </div>
                              </div>
                            </div>
                            <div className="mt-3">
                              <p className="word-break">{item?.answer}</p>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    </>
                  );
                })
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default EditMode;
