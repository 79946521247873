export const CHAT_POPUPLIST = "getChatPopUpListAsEmployee";
export const CHAT_LIST = "getChatList";
export const CHAT_NOTIFICATION = "sendChatNotification";
export const INDUSTRY_TYPE = "getIndustryTypeList";
export const ADD_EDITEMPLOYER = "addEditEmployers";

export const EDIT_GOAL = "editGoal";
export const ADD_GOAL = "addGoal";


export const LOGIN_API = "login";
export const FORGOT_PASWRDAPI = "forgotPassword";
export const EMPLOYE_RESETPASWRD = "employeeResetPassword"
export const CHANGE_PASWORD = "changePassword"
export const EMPLOYER_RESETPASWRD = "resetPassword"
export const COACH_RESETPASWRD = "resetPassword"


export const GETMYPROFILE_API = "getMyProfile";
export const UPLOADFILEAPI = "uploadFile";
export const UPDATEPROFILE = "updateProfile"
export const ADD_QUALITATIVEMEASURMENT = "addQualitativeMeasurement"
export const GET_QUANTITATIVEMEASURMENTCOMMENT = "getQuantitativeMeasurementCommentList"

export const GETLISTOFTEAMMEMBERBY_MANAGERID = "getListOfTeamMemberByManagerId"
export const GETCURRENTMANAGER = "getCurrentManager"
export const GETLIST_OFEMPLOYER_TEAMMEMBER = "getEmployeeList"
export const GETUNSEENNOTIFICATIONCOUNT = "getUnseenNotificationCount";
export const PARTICULAR_ACHIEVEMENT = 'getAchievementById';
export const GET_ACHIEVEMENT_COMMENTS = 'getAchievementComments'
export const GET_ACHIEVEMENT_LIST = 'getAchievements'


