import { put } from "redux-saga/effects";
import * as Action from "../type.action";

export function* setGroupChatData(action) {
  try {
    yield put({
      type: Action.CHATROOM_DATASUCCESS,
      data: action.payload,
      msg: "Success!!",
    });
  } catch (error) {
    yield put({ type: Action.CHATROOM_DATAFAIL, data: "Something went wrong" });
    yield put({ type: Action.LOADING_HIDE });
  }
}
