import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { validatePassword, errorMessage } from "../../utils/validation";
import { RESET_PASSWORD } from "../../utils/helper";
import { useHistory, Redirect } from "react-router-dom";
import logo1 from "../../assets/images/logo-1.png";
import { toast } from "react-toastify";
import "../.././scss/customforall.scss";
const ResetPswrd = (props) => {
  const [formState, setFormState] = useState({
    formValues: {
      newPassword: "",
      confirmPassword: "",
    },
    formErrors: {
      newPassword: "",
      confirmPassword: "",
    },
    formValidity: {
      newPassword: false,
      confirmPassword: false,
    },
  });

  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setconfirmPasswordShown] = useState(false);
  const [confirmPassword, setConfirmPasword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  useEffect(() => {
    if (props.setforgotPswrdMsg === "Password Updated Successfully ") {
      setConfirmPasword("");
      setNewPassword("");
    }
  }, [props.setforgotPswrdMsg]);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const togglePasswordVisiblity2 = () => {
    setconfirmPasswordShown(!confirmPasswordShown);
  };

  const history = useHistory();

  const handleChange = ({ target }) => {
    props.onFieldChange({ name: "OnShowEror", value: "" });
    const { formValues } = formState;
    formValues[target.name] = target.value;
    setFormState({ formValues });
    handleValidation(target);

    const { name, value } = target;
    let newPasswordError,
      confirmPasswordError = "";
    if (name === "password") {
      setNewPassword(value);
      if (value === "") {
        newPasswordError = errorMessage.required;
      } else {
        if (validatePassword(value)) {
          newPasswordError = "";
        } else {
          newPasswordError = errorMessage.paswrdError;
        }
      }
      setNewPasswordError(newPasswordError);
    }
    if (name === "confirm_password") {
      setConfirmPasword(value);
      if (value === "") {
        confirmPasswordError = errorMessage.cofirmpaswrdError;
      } else {
        confirmPasswordError = "";
      }
      setConfirmPasswordError(confirmPasswordError);
    }
  };

  const checkValidate = () => {
    if (confirmPassword !== "" || newPassword !== "") {
      if (!validatePassword(newPassword)) {
        return false;
      }
      if (newPassword !== confirmPassword) {
        setConfirmPasswordError(errorMessage.paswrdnotmatchError);
        return false;
      } else {
        return true;
      }
    }
    return true;
  };

  const handleValidation = (target) => {
    const { name, value } = target;
    const fieldValidationErrors = formState.formErrors;
    const validity = formState.formValidity;
    const isPassword = name === "password";
    validity[name] = value.length > 0;
    fieldValidationErrors[name] = validity[name] ? "" : errorMessage.required;
    if (validity[name]) {
      if (isPassword) {
        validity[name] = validatePassword(value);
        fieldValidationErrors[name] = validity[name]
          ? ""
          : errorMessage.paswrdError;
      }
    }
    setFormState({
      ...formState,
      formErrors: fieldValidationErrors,
      formValidity: validity,
    });
  };

  const handleSubmit = async (event) => {
    const reqtoken = props?.history?.location?.search?.split("=")?.pop();
    event.preventDefault();
    if (checkValidate()) {
      const postObj = { password: confirmPassword };
      const res = await RESET_PASSWORD(`resetPassword`, postObj, reqtoken);
      if (res.status === 200) {
        setNewPassword("");
        setConfirmPasword("");
        toast.success("Password reset successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        history.push("/home");
      } else {
        toast.success(
          "Password reset link is invalid or has expired. Please send another reset link.",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }
    }
  };

  return (
    <React.Fragment>
      <section className="sign-in-wrapper">
        <div className="signin-main">
          <div className="signin-box">
            <div class="logo-img">
              <img alt="" className="logo-img" src={logo1} />
            </div>
            <h3>Reset Password</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <div style={{ display: "flex", position: "relative" }}>
                  <input
                    type={passwordShown ? "text" : "password"}
                    id="password"
                    name="password"
                    className={`form-control ${
                      newPasswordError ? "is-invalid" : ""
                    }`}
                    autoComplete="current-password"
                    onChange={handleChange}
                    value={newPassword}
                    required
                    placeholder="New Password"
                  />
                  {passwordShown ? (
                    <i
                      class="fa fa-eye   i-fa-fa-eye"
                      aria-hidden="true"
                      onClick={togglePasswordVisiblity}
                    ></i>
                  ) : (
                    <i
                      class="fa fa-eye-slash  i-fa-fa-eye"
                      aria-hidden="true"
                      onClick={togglePasswordVisiblity}
                    ></i>
                  )}
                </div>
                <div className="errmsg">{newPasswordError}</div>
              </div>

              <div className="form-group">
                <div style={{ display: "flex", position: "relative" }}>
                  <input
                    type={confirmPasswordShown ? "text" : "password"}
                    id="password3"
                    name="confirm_password"
                    className={`form-control ${
                      confirmPasswordError ? "is-invalid" : ""
                    }`}
                    autoComplete="current-password"
                    onChange={handleChange}
                    value={confirmPassword}
                    required
                    placeholder="Confirm Password"
                  />
                  {confirmPasswordShown ? (
                    <i
                      class="fa fa-eye  i-fa-fa-eye"
                      aria-hidden="true"
                      onClick={togglePasswordVisiblity2}
                    ></i>
                  ) : (
                    <i
                      class="fa fa-eye-slash i-fa-fa-eye"
                      aria-hidden="true"
                      onClick={togglePasswordVisiblity2}
                    ></i>
                  )}
                </div>
                <div className="errmsg">{confirmPasswordError}</div>
              </div>

              <span className="red"> {props.errorMsg}</span>
              <div className="Sign-In-button">
                {props.loading ? (
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <button onClick={handleSubmit}>Submit</button>
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const errorMsg = state.loginReducer.errorMsg;
  const loading = state.globalReducer.loading;
  return {
    loading,
    errorMsg,
    isLoggedIn: state.loginReducer.isLoggedIn,
  };
};

const mapDispatch = (dispatchEvent) => ({
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(ResetPswrd);
