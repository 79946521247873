import { GET } from "../../../src/utils/helper";
import { useEffect } from "react";

function useEmployerTree(setTreeData) {
    useEffect(() => {
        GET("getEmployeeTree").then(resp => {
            let employer_data = resp?.body?.data;
            if (employer_data && Object.keys(employer_data).length > 0) {
                setTreeData(
                    {
                        name: employer_data.name,
                        attributes: {
                            Email: employer_data.email,
                            Role: "Employer"
                        },
                        children: employer_data?.managers?.map(manager => {
                            return {
                                name: manager.name,
                                attributes: {
                                    Email: manager.email,
                                    Role: "Manager"
                                },
                                children: manager?.employees?.map(emp => ({ name: emp.name, attributes: { Email: emp.email, Role: 'Employee' } })),
                            }
                        })
                    }
                );
            }
        })
    }, [])
}

export default useEmployerTree