import React, { useState, useCallback, useEffect } from "react";
import { Calendar } from "@natscale/react-calendar";
import "@natscale/react-calendar/dist/main.css";
import { GET, POST } from "../../utils/helper";
import moment from "moment";
import Modal from "../Common/Modal";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import "../.././scss/customforall.scss";

export default function TangoCoachAvailablity(props) {
  const SelectedSpecializationOption =
    props.location.state.selectedSpecialization;
  const Datee = props.location.state.date;
  const Sort = props.location.state.sort;

  const [filters, setFilters] = useState(2);
  const selectedSpecialization = SelectedSpecializationOption.name;

  const [date, setDate] = useState(
    Datee || moment(new Date()).format("YYYY-MM-DD")
  );
  const [slot, setSlot] = useState([]);
  const [modal, setmodal] = useState({ isOpen: false, data: null });
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onChange = useCallback(
    (value) => {
      setFilters(3);
      setDate(moment(value).format("YYYY-MM-DD"));
    },
    [setDate]
  );
  const [data, setdata] = useState({
    coach_specialization_category_id: SelectedSpecializationOption,
    query: "",
  });

  useEffect(() => {
    const getCoaches = async () => {
      setLoading(true);
      try {
        const res = await GET(
          `getCoachList?sortBy=${Sort}&searchKey=${SelectedSpecializationOption.name}&filterBy=${filters}&date=${date}`
        );

        setLoading(false);
        if (res.status === 200) {
          setSlot(res.body.data.BT["available_slots"]);
        } else {
          setSlot([]);
        }
      } catch (error) {
        setLoading(false);
        setSlot([]);
      }
    };
    getCoaches();
  }, [filters, date, Sort, selectedSpecialization]);

  const requestSessionHandle = async () => {
    if (!data.query.trim()) {
      toast.error("Please Enter Query", {
        position: toast.POSITION.BOTTOM_CENTER,
        hideProgressBar: true,
      });
      return;
    }
    if (!data.coach_specialization_category_id) {
      toast.error("Please Enter Coach Specialization", {
        position: toast.POSITION.BOTTOM_CENTER,
        hideProgressBar: true,
      });
      return;
    }

    try {
      let resultData = {
        app_id: 2,
        slot_id: modal.data.id,
        query: data.query,
        coach_specialization_category_id:
          data.coach_specialization_category_id.id,
        date: date,
        start_time: modal.data.start_time,
        end_time: modal.data.end_time,
      };

      const result = await POST("createSessionRequest", resultData);

      if (result.status === 200) {
        toast.success("Session request created successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
          hideProgressBar: true,
        });
        history.push("dashboard");
      }
    } catch (error) {}

    setmodal({ isOpen: false, data: null });
  };

  const handleSlotSelect = (data) => {
    setmodal({ isOpen: true, data });
  };

  return (
    <section className="availbility-check-wrapper bg-color   check-wraper">
      <div className="container">
        <div className="row">
          <div className="col-sm-5 col-sm5-inline">
            <div className="calendar-box">
              <Calendar
                disablePast
                value={new Date(date)}
                size={382}
                onChange={onChange}
              />
            </div>
          </div>
          <div
            className="col-sm-7 content-box"
            style={{
              marginTop: "4rem",
              marginBottom: "4rem",
              height: "60vh",
              overflowY: "scroll",
            }}
          >
            <div className="content-box-sub">
              <div>
                {!loading ? (
                  slot && slot.length > 0 ? (
                    slot.map((item) => {
                      if (
                        item.date === moment(new Date()).format("YYYY-MM-DD") &&
                        moment(new Date(), "hh:mm").isBefore(
                          moment(item.start_time, "hh:mm")
                        )
                      ) {
                        return (
                          <div key={item.id} style={{ width: "100%" }}>
                            <div
                              className="form-group"
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div
                                className="row  goalButton"
                                style={{
                                  position: "relative",
                                  borderColor:
                                    item.status === 1 ? "green" : "blue",
                                }}
                              >
                                {" "}
                                <div className="tango-div">
                                  <strong>
                                    {moment(item.start_time.slice(0, 5), [
                                      "HH.mm",
                                    ]).format("hh:mm a")}{" "}
                                    -{" "}
                                    {moment(item.end_time.slice(0, 5), [
                                      "HH.mm",
                                    ]).format("hh:mm a")}
                                  </strong>
                                </div>
                                <button
                                  onClick={() => handleSlotSelect(item)}
                                  className="button-tango"
                                >
                                  Select
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      } else if (
                        item.date !== moment(new Date()).format("YYYY-MM-DD")
                      ) {
                        return (
                          <div key={item.id} style={{ width: "100%" }}>
                            <div
                              className="form-group"
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div
                                className="row  goalButton"
                                style={{
                                  position: "relative",
                                  borderColor:
                                    item.status === 1 ? "green" : "blue",
                                }}
                              >
                                {" "}
                                <div className="tango-div">
                                  <strong>
                                    {moment(item.start_time.slice(0, 5), [
                                      "HH.mm",
                                    ]).format("hh:mm a")}{" "}
                                    -{" "}
                                    {moment(item.end_time.slice(0, 5), [
                                      "HH.mm",
                                    ]).format("hh:mm a")}
                                  </strong>
                                </div>
                                <button
                                  onClick={() => handleSlotSelect(item)}
                                  className="button-tango"
                                >
                                  Select
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      }

                      return null;
                    })
                  ) : (
                    <div className="no-session-found">No Sessions Found</div>
                  )
                ) : (
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
            </div>

            <Modal
              showmodal={modal.isOpen}
              onCLose={() => {
                setmodal({ data: null, isOpen: false });
              }}
              title={`Request for a Session`}
              body={
                <div
                  style={{ width: "100%", padding: 0 }}
                  className="My-Profile-wrapper"
                >
                  <div style={{ width: "100%" }} className="My-Profile">
                    <div className="form-information">
                      <div className="information">
                        <p className="modal-title">Session Date</p>
                        <p>{date}</p>
                      </div>
                      <div className="information">
                        <p className="modal-title">Session Timing</p>
                        <p>{`${
                          modal.data
                            ? moment(modal.data.start_time.slice(0, 5), [
                                "HH.mm",
                              ]).format("hh:mm a")
                            : null
                        } - 
                                                     ${
                                                       modal.data
                                                         ? moment(
                                                             modal.data.end_time.slice(
                                                               0,
                                                               5
                                                             ),
                                                             ["HH.mm"]
                                                           ).format("hh:mm a")
                                                         : null
                                                     }`}</p>
                      </div>
                      <div className="information">
                        <p className="modal-title">Performance Challenge</p>
                        <p>
                          {data.coach_specialization_category_id !== null
                            ? data.coach_specialization_category_id.name
                            : ""}
                        </p>
                      </div>
                      <div
                        style={{
                          wordWrap: "break-word",
                          display: "flex",
                          flexDirection: "column",
                        }}
                        className="information"
                      >
                        <p className="modal-title">Challenge Details</p>

                        <textarea
                          value={data.query}
                          onChange={(e) =>
                            setdata((state) => ({
                              ...state,
                              query: e.target.value,
                            }))
                          }
                          rows="4"
                          style={{
                            padding: "1rem",
                            marginTop: "0.5rem",
                            outline: "none",
                          }}
                          placeholder="Please enter description"
                          maxLength="150"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              }
              handleClick={requestSessionHandle}
              yesButtonText="Request Session"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
