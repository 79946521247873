import React from "react";
import { useLocation } from "react-router-dom";

export default function EmployeeChartDetail() {
  const location = useLocation();

  const renderPrimary = () => {
    return (
      <div class="assign-Goal-Look comman-width-container">
        {location.state.quantitativeDetail.map((item, index) => (
          <div key={index}>
            <div class="form-group">
              <div className="row  goalButton">
                <div className="col col-xl-8 col-sm-12 col-xs-12 mt-3 textwrap">
                  {item.team_goal?.title}
                </div>
                <div
                  className="col col-xl-4 col-sm-12 col-xs-12  text-right goallistcommonimg"
                  style={{ position: "relative" }}
                >
                  <div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: "2px",
                        right: "31rem",
                        color: "#FF7500",
                      }}
                    >
                      {item.quantitative_stats_percent}%
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: "-1px",
                        right: "0rem",
                        background:
                          "linear-gradient(90deg, rgba(39, 134, 226, 0.37) 0%, rgba(250, 147, 60, 0.37) 99.67%)",
                        width: "30rem",
                        height: "1rem",
                        borderBottomRightRadius: "13px",
                        borderTopLeftRadius: "13px",
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          width:
                            Number(item.quantitative_stats_percent) < 100
                              ? `${item.quantitative_stats_percent}%`
                              : "100%",
                          background:
                            "linear-gradient(90deg, #2786E2 0%, #FA933C 99.67%)",
                          borderBottomRightRadius: "13px",
                          borderTopLeftRadius: "13px",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      style={{ minHeight: "80vh", marginBottom: "2rem" }}
      class="assign-Goal-Look-wrapper"
    >
      <div class="container">
        <div class="Manager-Goal-Look comman-width-container">
          <div style={{ marginTop: "5rem" }} class="search-wrapper">
            {renderPrimary()}
          </div>
        </div>
      </div>
    </div>
  );
}
