import { all, takeLatest, fork } from 'redux-saga/effects'
import { onFieldChange } from '../saga/globalsaga'
import { fetchLogin, SendResetEmail, SendEmployerResetEmail, setForgtPswrd, onLogout, changePswrd, fetchEmployerLogin, setEmployerForgtPswrd, fetchCoachLogin, setCoachForgtPswrd, fetchLoginToken } from './loginSaga.js'
import { getProfile, uploadProfile, updateProfile } from './profileSaga'
import { getTeamList, getTeamEmployeeDetail, searchTeamList, getMangerId, getEmyprTeamList, getEmyprTeamData, getEmyprDepartmentData, getEmyprMangerData, addEmployerEmployee,addManagerEmployee, deleteEmployerEmployee } from "./teamSaga";
import { getManagerGoals, updateManagerGoal, submitManagerGoal, deleteManagerGoal } from "./goalSaga";
import { addManagerRating, getTeamRatingDetail, getQualitativePopData } from "./ratingSaga";
import { getChatPopup, getChatRoom, getChatUser, sendChatNotif } from "./chatSaga";
import { getNotificationCount } from "./notificationCountSaga";
import { getParticularAchievement } from "./getParticularAchievementSaga";
import { getAchievementComment } from "./achievementCommentSaga";
import { getAchievementList } from './AchievementListSaga';
import { setGroupChatData } from "./setGroupChatDataSaga";
import { setChatData } from "./setCoachChatDataSaga";
export default function* rootSaga() {

  yield all([
    takeLatest('ONFIELD_CHANGE', onFieldChange),
    takeLatest('GET_LOGINDATA', fetchLogin),
    takeLatest('GET_LOGINDATA_TOKEN', fetchLoginToken),
    takeLatest('RESET_EMAILSEND', SendResetEmail),
    takeLatest('EMPLOYERRESET_EMAILSEND', SendEmployerResetEmail),
    takeLatest('SET_FORGOTPASWORD', setForgtPswrd),
    takeLatest('ON_LOGOUT', onLogout),
    takeLatest('GET_PROFILEDATA', getProfile),
    takeLatest('GET_TEAMLIST', getTeamList),
    takeLatest('GET_TEAMDETAILDATA', getTeamEmployeeDetail),
    takeLatest('UPLOAD_PROFILEPIC', uploadProfile),
    takeLatest('UPDATEPROFILEDATA', updateProfile),
    takeLatest('SEARCH_TEAM', searchTeamList),
    takeLatest('GET_GOALDATA', getManagerGoals),
    takeLatest('ManagerGoalUpdate', updateManagerGoal),
    takeLatest('SUBMIT_NEWGOALS', submitManagerGoal),
    takeLatest('ADD_MANAGERRATING', addManagerRating),
    takeLatest('DELETEMANAGERGOAL', deleteManagerGoal),
    takeLatest('GET_TEAMRATINGDATA', getTeamRatingDetail),
    takeLatest('GET_QUALITATITVEPOPUPDATA', getQualitativePopData),
    takeLatest('CREATECHAT_ROOM', getChatRoom),
    takeLatest('Get_ChatUser', getChatUser),
    takeLatest('GET_CHATPOPUP', getChatPopup),
    takeLatest('GETMANGER_ID', getMangerId),
    takeLatest('CHAT_NOTIF', sendChatNotif),
    takeLatest('SET_CHNAGEPASWORD', changePswrd),
    takeLatest('GET_EMPLOYERLOGINDATA', fetchEmployerLogin),
    takeLatest('SET_EMPLOYERFORGOTPASWORD', setEmployerForgtPswrd),
    takeLatest('GET_EMPLOYERTEAMLIST', getEmyprTeamList),
    takeLatest('GET_EMPLOYRTEAMDATA', getEmyprTeamData),
    takeLatest('GET_EMPLOYERDEPARTMENTLIST', getEmyprDepartmentData),
    takeLatest('GET_EMPLOYERMANAGRLIST', getEmyprMangerData),
    takeLatest('ADDEMPLOYR_EMPLOYE', addEmployerEmployee),
    takeLatest('ADD_MANAGER_EMPLOYEE', addManagerEmployee),
    takeLatest('DELETEEMPLYEMPLYE', deleteEmployerEmployee),
    takeLatest('GET_NOTIFICATION_COUNT', getNotificationCount),
    takeLatest('GET_PARTICULAR_ACHIEVEMENT', getParticularAchievement),
    takeLatest('GET_ACHIEVEMENT_COMMENT', getAchievementComment),
    takeLatest('GET_ACHIEVEMENT_LIST', getAchievementList),
    takeLatest('SET_GRP_CHAT', setGroupChatData),
    takeLatest('SET_CHAT', setChatData),
    takeLatest('GET_COACHDATA', fetchCoachLogin),
    takeLatest('SET_COACHFORGOTPASWORD', setCoachForgtPswrd),



  ])
}

