import React, { useEffect, useState } from "react";
import StarRatingComponent from "react-star-rating-component";

import { useFormik } from "formik";
import { Modal, ModalBody } from "reactstrap";
import {
  CModal,
  CModalHeader,
  CModalFooter,
  CModalBody,
  CButton,
  CModalTitle,
} from "@coreui/react";
import { toast } from "react-toastify";
import { BsInfoCircleFill } from "react-icons/bs";
import { useSelector } from "react-redux";

import { GET, POST, getErrorMessage } from "../../utils/helper";
// import Vector from "../../assets/images/Vector.png";
import cross from "../../assets/images/cross.png";
import map from "../../assets/images/map.png";

const QualitativeRatingForm = ({
  qualitativeTarget,
  attributeId,
  employeeId,
  employerId,
  readOnly,
  onSuccess,
  isManager,
  ratingsData,
  id,
  qualitativeTargetName,
  isUpdate
}) => {
  const showLook = useSelector((state) => state.globalReducer?.showLook);
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [modal, setShowModal] = useState(false);
  const [assignedEmployee, setAssignedEmployees] = useState(null);
  const ratingId = `rating_${attributeId}`;

  const formik = useFormik({
    initialValues: {
      attribute_id: attributeId,
      [ratingId]: qualitativeTarget?.rating || 0,
      comment: qualitativeTarget?.decs || "",
      update: isUpdate || false,
      id: id,
      employee_id: employeeId,
      employer_id: employerId,
    },
    onSubmit: (values) => {
      confirmSubmit();
    },
  });

  const getAssignedEmployee = async () => {
    if (!assignedEmployee) {
      try {
        const result = await GET(`getAttributeEmployees/${attributeId}`);
        setAssignedEmployees(result?.body?.data);
        return result?.body?.data;
      } catch (error) {
        console.log(error);
      }
    } else {
      return assignedEmployee;
    }
  };

  useEffect(() => {
    isManager && getAssignedEmployee();
  }, []);

  const addAttributeRatings = async (attribute, employee_id) => {
    let attributeWithId = {
      ...attribute,
      employee_id: employee_id,
    };

    const result = await POST("addAttributeRatingsUpdated", attributeWithId);

    if (result.status === 200) {
      return result?.data;
    } else {
      throw new Error(`API call failed: ${result.statusText}`);
    }
  };

  const handleApiCalls = async (attribute) => {
    try {
      const results = await Promise.all(
        assignedEmployee.map((item) =>
          addAttributeRatings(attribute, item.employee_id)
        )
      );
      if (results) {
        onSuccess?.();
        setLoading(false);
        toast.success("Added Successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error("Failed to complete API calls:", error);
    }
  };

  const confirmSubmit = async () => {
    setConfirmModal(false);
    setLoading(true);
    try {
      let attribute = formik.values;
      attribute.rating = formik.values?.[ratingId];
      delete attribute?.[ratingId];
      // if (showLook) {
      //   attribute.employee_id = 119;
      // }
      if (isManager) {
        handleApiCalls(attribute);
      } else {
        const result = await POST("addAttributeRatingsUpdated", attribute);
        if (result?.status === 200) {
          onSuccess?.();
          setLoading(false);
          toast.success("Added Successfully", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 5000,
          });
        }
      }
    } catch (error) {
      setLoading(false);
      const message = getErrorMessage(error);
      toast.error(message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
    }
  };

  const toggleShowModal = () => {
    setShowModal(!modal);
  };

  const isSubmitDisabled =
    formik.values.comment == formik.initialValues.comment &&
    formik.values?.[ratingId] == formik.initialValues?.[ratingId];

  return (
    <>
      <div className="Qualitative-rating">
        <div className="managerRating-div-inline">
          <div className="header-rating">
            <h5>{qualitativeTargetName}</h5>
          </div>
          <span className="float-right" onClick={toggleShowModal}>
            <BsInfoCircleFill className="action-item" />
          </span>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className="rating-star">
            <StarRatingComponent
              id={ratingId}
              name={ratingId}
              size={27}
              starCount={5}
              value={formik.values?.[ratingId]}
              editing={!readOnly}
              onStarClick={(nextValue, prevValue, name) => {
                formik.setFieldValue(ratingId, nextValue);
                formik.setTouched(ratingId, true);
                formik.setTouched({
                  ...formik.touched,
                  [ratingId]: true,
                });
              }}
              starColor={`#FF7500`}
              className="star"
            />
          </div>
          {!readOnly ? (
            <div className="form-group manager-rating-form-group">
              <textarea
                name="comment"
                value={formik.values.comment}
                className="form-control"
                placeholder="Provide Feedback"
                maxLength="250"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              ></textarea>

              {!loading ? (
                <div
                  className="btn-blue  btn-blue-manager-rating-inline"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "9.5px",
                  }}
                >
                  <button
                    type="submit"
                    style={{
                      background: !isSubmitDisabled ? "" : "#6ca5cc",
                      display: "flex",
                      justifyContent: "center",
                      width: "6rem",
                      height: "3rem",
                      marginLeft: "1rem",
                      marginTop: "-1px",
                      paddingTop: "16px",
                      color: "#edf4fa",
                    }}
                    disabled={isSubmitDisabled}
                  >
                    Submit
                  </button>
                </div>
              ) : (
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
          ) : null}
        </form>
        <div style={{ paddingTop: 20 }}>
          {Array.isArray(ratingsData) && ratingsData.map((item) => (
            <>
              <div
                className="rating-star"
                style={{ justifyContent: "flex-start", borderBottom: "none" }}
              >
                <StarRatingComponent
                  id={ratingId}
                  name={ratingId}
                  size={27}
                  starCount={5}
                  value={item.rating}
                  editing={!readOnly}
                  onStarClick={(nextValue, prevValue, name) => {
                    formik.setFieldValue(ratingId, nextValue);
                    formik.setTouched(ratingId, true);
                    formik.setTouched({
                      ...formik.touched,
                      [ratingId]: true,
                    });
                  }}
                  starColor={`#FF7500`}
                  className="star"
                />
              </div>
              <div
                className="font-size"
                style={{ padding: "4px 23px 7px 23px" }}
              >
                <span>{item?.comment || ""}</span>
              </div>
            </>
          ))}
        </div>
      </div>

      <Modal isOpen={modal} className="goalsucessmodal ">
        <ModalBody>
          <div className="owenship-popup">
            <img
              src={cross}
              className="cross closepopup"
              alt=""
              onClick={toggleShowModal}
            />
            <div className="map-img">
              <img src={map} alt="" />
            </div>
            <p style={{ wordWrap: "break-word" }}>
              {qualitativeTarget?.comment}
            </p>
          </div>
        </ModalBody>
      </Modal>
      <CModal
        show={confirmModal}
        centered={true}
        backdrop={true}
        color="warning"
        onClose={setConfirmModal}
      >
        <CModalHeader>
          <CModalTitle>Submit Ratings?</CModalTitle>
        </CModalHeader>
        <CModalBody>Are you sure to proceed with the same?</CModalBody>
        <CModalFooter style={{ display: "flex", flexDirection: "row" }}>
          <CButton
            style={{
              width: "6rem",
              height: "3rem",
              backgroundColor: "#F56A14",
              color: "white",
            }}
            onClick={() => {
              setConfirmModal(false);
              confirmSubmit();
            }}
          >
            Yes
          </CButton>{" "}
          <CButton
            style={{
              width: "6rem",
              height: "3rem",
              backgroundColor: "#4B9CEB",
              color: "white",
            }}
            onClick={() => setConfirmModal(false)}
          >
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default QualitativeRatingForm;
