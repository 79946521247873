

import React, { useState, useEffect } from 'react'

import dot from "../../assets/images/dot-img.png"
import sub from "../../assets/images/Subscription.png";
import { GET } from "../../utils/helper"
import PayPal from "./PayPal";
import { CModal, CModalBody, CModalFooter, CButton, CModalTitle, CModalHeader } from "@coreui/react"
import moment from 'moment';


export default function ListPlans() {

    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [checkout, setCheckOut] = useState(false);
    const [modal, setModal] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0)
    useEffect(() => {

        const getData = async () => {
            try {
                setLoader(true);
                const result = await GET('myPayments');
                setLoader(false);
                let amount = 0;
                let updated = result.body.data.rows.map((item, index) => {
                    item.month = moment(item.month, 'M').format('MMMM')
                    if (item.status === 0) {
                        amount = amount + item.amount
                    }
                    return item;
                })
                setData(updated);
                setTotalAmount(amount)
            } catch (error) {
                setLoader(false);
                console.log(error);
            }
        }

        getData();
    }, [])
   

    const handleBuyPlan = () => {
        setModal(false)
        setCheckOut(!checkout)

    }


    return (
        <div>
            <section style={{ minHeight: '55vh' }} class="Pricing-wrapper bg-color">
                <div >
                    <CModal
                        show={modal}
                        centered={true}
                        backdrop={true}
                        color="warning"
                        onClose={() => {
                            setModal(true)
                        }}
                    >
                        <CModalHeader >
                            <CModalTitle>
                                Pay now?
                            </CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                            Are you sure you want to Pay now?
                        </CModalBody>
                        <CModalFooter style={{ display: 'flex', flexDirection: "row", }}>
                            <CButton
                                style={{ width: "6rem", height: "3rem", backgroundColor: '#F56A14', color: 'white' }}
                                onClick={() => handleBuyPlan()}
                            >Yes</CButton>

                            <CButton
                                style={{ width: "6rem", height: "3rem", backgroundColor: '#4B9CEB', color: 'white' }}
                                onClick={() => {
                                    setModal(false)

                                }}
                            >Cancel</CButton>

                        </CModalFooter>

                    </CModal>
                    <div class="Pricing comman-width-container">
                        <div class="Subscription-head">
                            <h2>My Payments</h2>
                            <img src={sub} />
                        </div>
                        <section style={{ minHeight: '80vh' }} class="My-Profile-wrapper bg-color">
                            {!loader ? data.length > 0 ?
                                <div className="My-Profile">
                                    <>
                                        <div style={{ paddingBottom: '10px', marginBottom: "1rem" }} className="form-information">
                                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                <div style={{ alignSelf: 'left' }}>
                                                    Month
                                                </div>
                                                <div style={{ display: 'flex', width: '50%', justifyContent: 'space-between' }}>
                                                    <div>
                                                        Status
                                                    </div>
                                                    <div>
                                                        Amount
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px', width: '100%' }}>
                                                {data.map((item) => {
                                                    return (
                                                        <div style={{ marginTop: '10px', display: 'flex', width: '100%', justifyContent: 'space-between' }} className=''>
                                                            <div>
                                                               
                                                                {item.month}
                                                            </div>
                                                            <div style={{ display: 'flex', width: '50%', justifyContent: 'space-between' }}>
                                                                <div>
                                                                    {item.status === 1 ?
                                                                        <div style={{ width: '68px', height: '21px', background: "#B9E3B9", borderRadius: "111px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                            <div style={{ color: "#007F00", fontSize: "10px" }}>Paid</div>
                                                                        </div>
                                                                        : <div>
                                                                            <div style={{ width: '68px', height: '21px', background: "#FFD3D3", borderRadius: "111px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                                <div style={{ color: "#FD3737", fontSize: "10px" }}>Unpaid</div>
                                                                            </div>
                                                                        </div>}
                                                                </div>
                                                                <div>
                                                                    ${item.amount}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <hr style={{ marginTop: '1rem' }} />
                                            <div style={{ marginTop: "1rem", display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                                <div>
                                                    Total Due
                                                </div>
                                                <div style={{ color: "#FD3737", fontSize: "22px", lineHeight: "33px", fontWeight: "bold" }}>
                                                   $ {totalAmount}
                                                </div>
                                            </div>

                                        </div>
                                    </>
                                    {totalAmount > 0 ? checkout ?
                                        <PayPal amount={totalAmount} />
                                        :
                                        <div style={{ marginTop: "2rem" }}
                                            className="Subscription-btn"
                                        >
                                            <button
                                                onClick={() => {
                                                    setModal(true)
                                                }}
                                            >Pay Now</button>

                                        </div>
                                        : null}

                                </div>
                                : <div style={{ marginTop: '5rem', marginBottom: '15rem', textAlign: "center" }} role="status">
                                    No payments Found
                                </div>
                                :
                                <div style={{ marginTop: '5rem', marginBottom: '15rem' }} class="spinner-border text-primary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            }

                        </section>

                      
                    </div>
                </div >
            </section >
        </div >
    )
}


