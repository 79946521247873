


//unused code


import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import profile from "../assets/images/profile1.png";
import { getDate } from "../utils/validation";
import PreviosProfileDropDown from "./PreviosProfileDropDown";

const Profile = (props) => {
  const [active, setActive] = useState("Personal");
  const [showLastEmployer, setLastEmployer] = useState(false);
  const [dateError, setDateError] = useState("");
  const [isOpen, setIsOpen] = useState(null);

  useEffect(() => {
    props.getProfileData();
  }, []);

  const showToast = () => {
    toast.error(props.errorMsg, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  useEffect(() => {
    if (props.errorMsg !== "") showToast();
  }, [props.errorMsg]);

  const toggleLastEmployerVisiblity = () => {
    setLastEmployer(!showLastEmployer);
  };

  const handleChange = (event) => {
    if (event.target.name === "date_of_birth") {
      if (new Date(event.target.value) >= new Date().setHours(0, 0, 0, 0)) {
        setDateError("select valid date");
      } else {
        setDateError("");
      }
    }
    props.onFieldChange({
      name: "handleProfileChange",
      value: { [event.target.name]: event.target.value },
    });
  };

  const checkDate = () => {
    if (
      new Date(props.profileData.date_of_birth) >
      new Date().setHours(0, 0, 0, 0)
    ) {
      setDateError("select valid date");
      return false;
    }
    return true;
  };
  const onChangeFile = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files.length > 0) {
      var file = event.target.files[0];
      props.onFieldChange({ name: "PROFILE_IMAGEFAIL", value: "" });
      const fileType = file.name;
      var re = /(\.jpg|\.jpeg|\.image|\.png|\.svg)$/i;
      if (!re.exec(fileType)) {
        props.onFieldChange({
          name: "PROFILE_IMAGEFAIL",
          value: "wrong file type selected",
        });

        alert("wrong file type selected");
      } else {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("folderName", "employee_profile_pic");
        props.uploadProfile(formData);
      }
    }
  };

  const handleImageUpload = () => {
    document.getElementById("fileInput").click();
  };

  const updateProfile = (event) => {
    event.preventDefault();
    let postObj = {};
    if (props.profileData.profile_pic_url)
      postObj.profile_pic_url = props.profileData.profile_pic_url;
    if (props.profileData.accomplishments)
      postObj.accomplishments = props.profileData.accomplishments;
    if (props.profileData.date_of_birth)
      postObj.date_of_birth = props.profileData.date_of_birth;
    props.updateProfileData({
      postObj,
      history: props.history,
      redirect: true,
    });
  };

  const skipPersonal = () => {
    props.onFieldChange({
      name: "handleProfileChange",
      value: {
        profile_pic_url: props.readProfileData.profile_pic_url,
        date_of_birth: props.readProfileData.date_of_birth,
      },
    });
    setActive("Employment");
    setDateError("");
  };

  return (
    <div>
      <section class="sign-in-wrapper setup-profile">
        <div class="signin-main">
          <div class="signin-box">
            <h3>Set Up Profile</h3>
            <div class="tabbable-panel">
              <ul class="nav nav-tabs ">
                <li
                  class={`${active === "Personal" ? "active" : ""}`}
                  onClick={() => (checkDate() ? setActive("Personal") : null)}
                >
                  <a href="#">Personal</a>
                </li>
                <li
                  class={`${active === "Employment" ? "active" : ""}`}
                  onClick={() => (checkDate() ? setActive("Employment") : null)}
                >
                  <a href="#">Employment</a>
                </li>
                <li
                  class={`${active === "Achievements" ? "active" : ""}`}
                  onClick={() =>
                    checkDate() ? setActive("Achievements") : null
                  }
                >
                  <a href="#">Achievements</a>
                </li>
              </ul>
            </div>
            {active === "Personal" ? (
              <>
                <div class="profile-img" onClick={() => handleImageUpload()}>
                  <img
                    alt=""
                    src={
                      props.profileData.profile_pic_url
                        ? props.profileData.profile_pic_url
                        : profile
                    }
                    style={{
                      width: "110px",
                      height: "110px",
                      borderRadius: props.profileData?.profile_pic_url
                        ? "50%"
                        : "",
                      border: "1px solid #2786e2",
                    }}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    multiple={false}
                    onChange={(e) => onChangeFile(e)}
                    id="fileInput"
                    name="fileInput"
                    hidden
                  />
                </div>
                <form action="">
                  <div class="form-group">
                    <input
                      type="text"
                      disabled
                      value={props.profileData.name}
                      class="form-control"
                      placeholder="Your name"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      value={props.profileData.email}
                      placeholder="Email address"
                    />
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <div class="form-group">
                        <input
                          type="text"
                          disabled
                          class="form-control"
                          value={props.profileData.country_code}
                          placeholder="countrycode"
                        />
                      </div>
                    </div>
                    <div class="col-9">
                      <div class="form-group">
                        <input
                          type="text"
                          disabled
                          class="form-control"
                          value={props.profileData.phone_number}
                          placeholder="Phone number"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input
                      type="date"
                      class="form-control"
                      name="date_of_birth"
                      onChange={(e) => handleChange(e)}
                      value={props.profileData.date_of_birth}
                      placeholder="Date of Birth"
                    />
                    <span style={{ color: "red" }}>{dateError}</span>
                  </div>

                  <div class="Sign-In-button">
                    {props.loading ? (
                      <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <>
                        <button
                          type="button"
                          onClick={() =>
                            checkDate() ? setActive("Employment") : null
                          }
                        >
                          Save
                        </button>
                        <button
                          class="Skip"
                          type="button"
                          onClick={() => skipPersonal()}
                        >
                          Skip
                        </button>
                      </>
                    )}
                  </div>
                </form>
              </>
            ) : active === "Employment" ? (
              <>
                <form action="">
                  <div class="form-group">
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      value={props.profileData.current_designation}
                      placeholder="Title/position"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      value={props.profileData.department.name}
                      placeholder="Department"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      value={getDate(props.profileData.current_date_of_joining)}
                      placeholder="Start Date"
                    />
                  </div>
                  <div
                    class="form-group"
                    onClick={toggleLastEmployerVisiblity}
                    style={{ marginBottom: "0" }}
                  >
                    <div style={{ display: "flex", position: "relative" }}>
                      <input
                        type="text"
                        disabled
                        class="form-control lasttitle"
                        style={{
                          borderRadius: showLastEmployer
                            ? "14px 14px 0px 0px"
                            : "14px 14px 14px 14px",
                        }}
                        placeholder={`${
                          props?.profileData?.prev_employers
                            ? "Last employer details"
                            : "Fresher"
                        }`}
                      />

                      {showLastEmployer ? (
                        <i
                          class="fas fa-angle-up"
                          aria-hidden="true"
                          style={{
                            position: "absolute",
                            top: "35%",
                            left: "90%",
                          }}
                        ></i>
                      ) : (
                        <i
                          class="fas fa-angle-down"
                          aria-hidden="true"
                          style={{
                            position: "absolute",
                            top: "35%",
                            left: "90%",
                          }}
                        ></i>
                      )}
                    </div>
                  </div>
                  {showLastEmployer ? (
                    <div className="lastempdetail">
                      {props?.profileData?.prev_employers?.map(
                        (item, outerIndex) => {
                          return (
                            <div style={{ width: "100%", marginTop: "1rem" }}>
                              {isOpen !== outerIndex && (
                                <div
                                  key={outerIndex}
                                  class="form-group"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div
                                    onClick={() => {
                                      setIsOpen(outerIndex);
                                    }}
                                    className="row  goalButton"
                                    style={{
                                      background: false ? "#ff7500" : "",
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "35%",
                                        left: "4rem",
                                      }}
                                    >
                                      {item?.prev_employer}
                                    </div>

                                    <i
                                      style={{
                                        position: "absolute",
                                        top: "35%",
                                        right: "2rem",
                                      }}
                                      class="fa fa-angle-down"
                                    ></i>
                                  </div>
                                </div>
                              )}

                              {isOpen === outerIndex && (
                                <PreviosProfileDropDown
                                  setIsOpen={setIsOpen}
                                  profileData={item}
                                  outerIndex={outerIndex}
                                />
                              )}
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : null}
                  <div class="Sign-In-button">
                    <button
                      type="button"
                      onClick={() => setActive("Achievements")}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      class="Skip"
                      onClick={() => setActive("Achievements")}
                    >
                      Skip
                    </button>
                  </div>
                </form>
              </>
            ) : (
              <>
                <form action="">
                  <div class="form-group">
                    <label>Add accomplishments</label>
                    <textarea
                      maxLength="300"
                      rows="4"
                      class="form-control"
                      value={props.profileData.accomplishments}
                      name="accomplishments"
                      onChange={(e) => handleChange(e)}
                      placeholder="Write here"
                      style={{ fontSize: "12px", minHeight: "118px" }}
                    ></textarea>
                    <span class="textmsg">
                      {props.profileData.accomplishments?.length === undefined
                        ? 0
                        : props.profileData.accomplishments?.length}
                      /300{" "}
                    </span>
                  </div>

                  <div class="Sign-In-button">
                    {props.loading ? (
                      <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <>
                        <button onClick={(event) => updateProfile(event)}>
                          Add
                        </button>
                        <button
                          class="Skip"
                          type="button"
                          onClick={() => props.history.push("/dashboard")}
                        >
                          Skip
                        </button>
                      </>
                    )}
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => {
  const profileData = state.profileReducer.profileData;
  const errorMsg = state.profileReducer.errorMsg;
  const imageError = state.profileReducer.imageError;
  const readProfileData = state.profileReducer.readProfileData;
  const loading = state.globalReducer.loading;
  const setforgotPswrdMsg = state.loginReducer.setforgotPswrdMsg;
  return {
    profileData,
    loading,
    errorMsg,
    imageError,
    readProfileData,
    setforgotPswrdMsg,
  };
};

const mapDispatch = (dispatchEvent) => ({
  getProfileData: () => dispatchEvent({ type: "GET_PROFILEDATA" }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
  uploadProfile: (payload) =>
    dispatchEvent({ type: "UPLOAD_PROFILEPIC", value: payload }),
  updateProfileData: (payload) =>
    dispatchEvent({ type: "UPDATEPROFILEDATA", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(Profile);
