import * as actions from "../type.action";
let token = localStorage.getItem("s_token");
const initialState = {
  loginData: {},
  loading: false,
  errorMsg: "",
  errorStatus: "",
  isLoggedIn: token ? true : false,
  loginMsg: "",
  forgotPswrdMsg: "",
  forgotPswrdErrMsg: "",
  forgotError: false,
  forgotPswrdData: "",
  forgotErrMsg: "",
  otp: 0,
  userDetails: {},
  employerloginData: {},
  loginRole: null,
  paymentStatus: null
};

const loginReducer = (state, action) => {
  token = localStorage.getItem("s_token");
  if (state === undefined) {
    return (state = initialState);
  }
  switch (action.type) {
    case actions.LOGIN_DATASUCCESS:
      return {
        ...state,
        loginData: action.data,
        isLoggedIn: true,
        errorMsg: "",
        loginMsg: action.msg,
        loginRole: 0,
      };
    case actions.EMPLOYERLOGIN_DATASUCCESS:
      return {
        ...state,
        employerloginData: action.data,
        isLoggedIn: true,
        errorMsg: "",
        loginMsg: action.msg,
        loginRole: 1,
        paymentStatus: action.data.paymentstatus
      };
    case actions.UPDATE_PAYMENT_STATUS:
      return {
        ...state,
        paymentStatus: action.value.paymentStatus
      }
    case actions.COACHLOGIN_DATASUCCESS:
      return {
        ...state,
        coachloginData: action.data,
        isLoggedIn: true,
        errorMsg: "",
        loginMsg: action.msg,
        loginRole: 2,
      };
    case "RESET_LOGIN_ROLE":
      return {
        ...state,
        loginRole: null,
      };
    case "CLEAR_LOGIN_DATA":
      return {
        ...state,
        loginData: {},
        isLoggedIn: false,
        loginRole: null,
      };
    case "SET_CURRENT_USER":
      return {
        ...state,
        userDetails: action.payload,
        isLoggedIn: true,
      };
    case actions.SET_LOGOUT:
      return Object.assign({}, state, {
        isLoggedIn: false,
      });
    case actions.LOGIN_DATAFAIL:
      return {
        ...state,
        errorMsg: action.data,
        loading: false,
        isRedirect: false,
        isLoggedIn: false,
      };
    case actions.FORGOTPASRD_DATASUCCESS:
      return {
        ...state,
        forgotPswrdMsg: action.msg,
        otp: action.data,
      };
    case actions.FORGOTPASRD_DATAFAIL:
      return {
        ...state,
        forgotPswrdErrMsg: action.msg,
      };
    case actions.SETFORGOTPASRD_DATASUCCESS:
      return {
        ...state,
        setforgotPswrdMsg: action.msg,
        loginData: action.data,
      };
    case actions.SETEMPOLYERFORGOTPASRD_DATASUCCESS:
      return {
        ...state,
        setforgotPswrdMsg: action.msg,
        employerloginData: action.data,
      };
    case actions.SETFORGOTPASRD_DATAFAIL:
      return {
        ...state,
        setforgotPswrdErrMsg: action.msg,
      };
    case actions.CHANGEPASWRD_DATASUCCESS:
      return {
        ...state,
        setforgotPswrdMsg: action.msg,
      };
    case actions.CHANGEPASWRD_DATAFAIL:
      return {
        ...state,
        setforgotPswrdErrMsg: action.msg,
      };
    case "OnShowEror":
      return Object.assign({}, state, {
        errorMsg: "",
        forgotPswrdErrMsg: "",
        setforgotPswrdErrMsg: "",
        setforgotPswrdMsg: "",
      });
    default:
      return { ...state, isLoggedIn: token ? true : false };
  }
};
export default loginReducer;
