import { call, put, apply } from 'redux-saga/effects'
import * as Action from '../type.action'
import { POST, GET, RESETPOST, POSTIMAGE, deleteAPI } from '../../utils/helper'
import { EDIT_GOAL ,ADD_GOAL,CHAT_NOTIFICATION} from "./ApiConstant";
import { getEmyprTeamData } from './teamSaga';


export function * deleteManagerGoal (action) { 
  yield put({ type: Action.LOADING_SHOW })
  yield put({ type:'HIDE_MSG' })
  try {
    const id = action.value
    const Data = yield call(deleteAPI, `deleteGoal?id=${id}`)

    if (Data.status === 200) {
        yield call(getManagerGoals,{value:''})
        yield put({ type: Action.DELETEMANAGERGOALSUCCESS, data: Data.body.data, msg: 'Goal deleted successfully' })
        yield call(getEmyprTeamData,{value:action?.employee_id })
        yield put({ type: Action.LOADING_HIDE })
    } else {
      yield put({ type: Action.MANAGERGOAL_DATAFAIL, data: Data.message })
      yield put({ type: Action.LOADING_HIDE })
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
        yield put({ type: Action.MANAGERGOAL_DATAFAIL, data: error.response.data.message })

    } else {
        yield put({ type: Action.MANAGERGOAL_DATAFAIL, data: 'Something went wrong' })
    }

    yield put({ type: Action.LOADING_HIDE })
  }
}

export function* getManagerGoals(action) {
    yield put({ type: Action.LOADING_SHOW })
    yield put({ type:'HIDE_MSG' })
    try {
        const str = action.value
         const url = str===''?'viewGoalAsManager?offset=1&limit=500':`viewGoalAsManager?search_string=${str}&offset=1&limit=500`
        const Data = yield call(GET, url)
        if (Data.status === 200) {
            yield put({ type: Action.MANAGERGOAL_DATASUCCESS, data: Data.body.data.rows, msg: 'Success' })
            yield put({ type: Action.LOADING_HIDE })
        } else {
            yield put({ type: Action.MANAGERGOAL_DATAFAIL, data: Data.message })
            yield put({ type: Action.LOADING_HIDE })
        }
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put({ type: Action.MANAGERGOAL_DATAFAIL, data: error.response.data.message })

        } else {
            yield put({ type: Action.MANAGERGOAL_DATAFAIL, data: 'Something went wrong' })
        }
        yield put({ type: Action.LOADING_HIDE })
    }
}


export function* updateManagerGoal(action) {
    yield put({ type: Action.LOADING_SHOW })
    const {postObj,index} = action.value
    try {
        const Data = yield call(RESETPOST, EDIT_GOAL, postObj)
   
        if (Data.status === 200) {
          
            yield put({ type: Action.UPDATEMANAGERGOALSUCCESS, data: Data.body.data, msg: 'Updated successfully',index })
            yield put({ type: Action.LOADING_HIDE })
            // This just quick fix for the bug
            window.location.reload();
        } else {

            yield put({ type: Action.MANAGERGOAL_DATAFAIL, data: Data.message })
            yield put({ type: Action.LOADING_HIDE })
           
        }
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put({ type: Action.MANAGERGOAL_DATAFAIL, data: error.response.data.message })

        } else {
            yield put({ type: Action.MANAGERGOAL_DATAFAIL, data: 'Something went wrong' })
        }

        yield put({ type: Action.LOADING_HIDE })

    }
}

export function* submitManagerGoal(action) {
    yield put({ type: Action.LOADING_SHOW })
    const postObj = action.value
    try {
        const Data = yield call(RESETPOST, ADD_GOAL, postObj)
   
        if (Data.status === 200) {

            yield put({ type: Action.ADDMANAGERGOALSUCCESS, data: Data.body.data, msg: 'Goal added Successfully' })
            yield call(getManagerGoals,{value:''})
            yield put({ type: Action.LOADING_HIDE })
        } else {

            yield put({ type: Action.MANAGERGOAL_DATAFAIL, data: Data.message })
            yield put({ type: Action.LOADING_HIDE })
 
        }
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
            yield put({ type: Action.MANAGERGOAL_DATAFAIL, data: error.response.data.message })

        } else {
            yield put({ type: Action.MANAGERGOAL_DATAFAIL, data: 'Something went wrong' })
        }

        yield put({ type: Action.LOADING_HIDE })

    }
}