import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import routes from "../../routes";
import { MANAGER, EMPLOYEE } from "../../utils/roles";
import { getRouteName } from "../../utils/helper";

const DashboardSwitch = (props) => {
  const look = JSON.parse(localStorage.getItem("look"))?.showLook;
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    props.setLookProp(look);
  }, []);

  useEffect(() => {
    const currentRouteName = getRouteName(location.pathname);
    const route = routes.find((route) => {
      return route.routeName == currentRouteName;
    });

    if (
      !route?.allowedRoles?.includes(MANAGER) ||
      (!props.showLook && !route?.allowedRoles?.includes(EMPLOYEE))
    ) {
      history.replace("/dashboard");
    }
  }, [props.showLook]);

  const handleToggleLookup = (e) => {
    props.setshowLook(e.target.checked);
  };

  return (
    <section className="Manager-look-wrapper">
      <div className="container">
        <div className="manage-lock">
          <div className="row">
            <div className="Switch-text col">
              {props.showLook ? (
                <p>Switch to Team Member View</p>
              ) : (
                <p>Switch to Manager View</p>
              )}
            </div>
            <div className="Switch-icon float-right">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  autoComplete="off"
                  className="custom-control-input"
                  id="customSwitch1"
                  defaultChecked={props.showLook}
                  onClick={handleToggleLookup}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customSwitch1"
                ></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    showLook: state.globalReducer.showLook,
    loginRole: state.loginReducer.loginRole,
  };
};

const mapDispatch = (dispatchEvent) => ({
  setshowLook: (payload) => dispatchEvent({ type: "SET_LOOK", value: payload }),
  setLookProp: (payload) =>
    dispatchEvent({ type: "SET_LOOKPROP", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(DashboardSwitch);
