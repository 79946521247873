import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import pro from "../../assets/images/profilePlaceholder/profilePlaceholder.png";
import { GET, putAPI } from "../../utils/helper";
import Modal from "../Common/Modal";

export default function MySessionRequest() {
  const [sessions, setSessions] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loader, setLoader] = useState(false);
  const [rejectModal, setRejectModal] = useState({
    isOpen: false,
    data: null,
  });

  useEffect(() => {
    const getData = async () => {
      let dateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

      try {
        setLoader(true);
        const result = await GET(`getSessionRequests?datetime=${dateTime}`);
        setLoader(false);

        if (result.status === 200) {
          setSessions(result?.body?.data?.rows);
        }
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    };
    getData();
  }, [refresh]);

  const handleReject = async () => {
    try {
      let result = await putAPI("rejectSessionRequest", {
        session_id: rejectModal.data.id,
      });
      setRejectModal({ isOpen: false, data: null });
      if (result.status === 200) {
        setRefresh(!refresh);
        toast.success("Session Rejected", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } catch (error) {
      setRejectModal({ isOpen: false, data: null });

      console.log(error);
    }
  };

  const handleAccept = async (session) => {
    try {
      let result = await putAPI("acceptSessionRequest", {
        session_id: session.id,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      if (result.status === 200) {

          let other_user_id = session.employee_id;
          const getChatRoomData = async () =>
            await GET(`getChatRoomId?other_user_id=${other_user_id}&type=${0}`);
          getChatRoomData()
            .then((_result) => {
              setRefresh(!refresh);
            })
            .catch((err) => console.log(err));
        

        toast.success("Session Accepted", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } catch (error) {
      toast.error(error.response?.data?.message, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  return (
    <section style={{ minHeight: "80vh" }} class="profiles-wrapper-main">
      <div class="container">
        <h3 style={{ textAlign: "center", justifyContent: "center" }}>
          My Session Requests
        </h3>
        {!loader ? (
          sessions.length > 0 ? (
            sessions.map((session) => {
              return (
                <div key={session.id} class="profiles-wrapper">
                  <div class="profile-comman-box">
                    <div
                      class="row"
                      style={{ textAlign: "center", justifyContent: "center" }}
                    >
                      <div class="col-md-12 comman-width one">
                        <div
                          class="main-profiles"
                          style={{ maxWidth: "558px", margin: "0 auto" }}
                        >
                          <div class="profile-img">
                            <img
                            className="request-img"
                            
                              src={session?.employee?.profile_pic_url || pro}
                              alt=""
                            />
                          </div>
                          <div class="row no-gutters">
                            <div
                            className="data-main-div"
                             
                            >
                              <div
                              className="ladelstyle"
                             
                              >
                                <p>Team Level :</p>
                                <p
                                 className="labelstyless"
                              
                                >
                                  <p>{session?.employee_rank?.name}</p>
                                </p>
                              </div>

                              <div
                               className="ladelstyle"
                              
                              >
                                <p>Performance Challenge :</p>
                                <p
                                 className="labelstyless"
                                
                                >
                                  <p className="textwrap">
                                    {
                                      session?.coach_specialization_category
                                        ?.name
                                    }
                                  </p>
                                </p>
                              </div>

                              <div
                               className="ladelstyle"
                           
                              >
                                <p>Challenge Details :</p>
                                <p
                                 className="labelstyless"
                                
                                >
                                  <p>{session?.query}</p>{" "}
                                </p>
                              </div>
                              <div
                               className="ladelstyle"
                             
                              >
                                <p>Session Date :</p>
                                <p
                                 className="labelstyless"
                                 
                                >
                                  <p>
                                    {moment(session?.date).format(
                                      "Do MMM YYYY"
                                    )}
                                  </p>{" "}
                                </p>
                              </div>
                              <div
                               className="ladelstyle"
                               
                              >
                                <p>Session Time :</p>
                                <p
                              className="labelstyless"
                                 
                                >
                                  <p>
                                    {session
                                      ? moment(session.start_time.slice(0, 5), [
                                          "HH.mm",
                                        ]).format("hh:mm a")
                                      : ""}
                                    -{" "}
                                    {session
                                      ? moment(session.end_time.slice(0, 5), [
                                          "HH.mm",
                                        ]).format("hh:mm a")
                                      : ""}
                                  </p>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div

                          className="editbutton"
                        
                          >
                            <button
                              onClick={() => handleAccept(session)}
                              style={{ width: "30%" }}
                            >
                              Accept
                            </button>
                            <button
                              onClick={() => {
                                setRejectModal({ data: session, isOpen: true });
                             
                              }}
                              style={{
                                width: "30%",
                                backgroundColor: "#0D86FF",
                              }}
                            >
                              Reject
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <h3
              style={{
                textAlign: "center",
                marginTop: "4rem",
                color: "#FF6932",
              }}
            >
              No sessions request found
            </h3>
          )
        ) : (
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        )}
        <Modal
          showmodal={rejectModal.isOpen}
          title={`Reject Session?`}
          body={`Do you want to reject this Session?`}
          handleClick={handleReject}
          onCLose={() => setRejectModal({ isOpen: false, data: null })}
        />
      </div>
    </section>
  );
}
