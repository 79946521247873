import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import moment from 'moment';
import './styles.scss'
import config from "../../config";

const { BASE_API_URL, LARGE_PLAN_ID, MEDIUM_PLAN_ID, SMALL_PLAN_ID } = config;


function InvoiceTable({ data }) {


  const handleButtonClick = (url) => {
    window.open(url, '_blank');
  };

  const formatDatetime = (datetime) => {
    return moment(datetime).format('MMMM Do YYYY, h:mm:ss a');
  };

  return (

    <TableContainer component={Paper} className='tableContainer'>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Stripe Customer ID</TableCell>
            <TableCell>Transaction Status</TableCell>
            <TableCell>Plan</TableCell>
            <TableCell>Payment Datetime</TableCell>
            <TableCell>Subscription End Datetime</TableCell>
            <TableCell>Receipt URL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.employer_email}</TableCell>
              <TableCell>{row.stripe_customer_id}</TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>
                {row.plan == SMALL_PLAN_ID ? 'Small' : row.plan == MEDIUM_PLAN_ID ? 'Medium' :
                  row.plan == LARGE_PLAN_ID ? "Large " :
                    null
                }</TableCell>
              <TableCell>{formatDatetime(row.payment_datetime)}</TableCell>
              <TableCell>{formatDatetime(row.subscription_end_datetime)}</TableCell>
              <TableCell>
                <Button variant="contained" color="primary" className="button" onClick={() => handleButtonClick(row.recipt_url)}>
                  View Receipt
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default InvoiceTable;
