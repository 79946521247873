import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import title1 from "../../assets/images/title1.png";
import title2 from "../../assets/images/title2.png";
import title3 from "../../assets/images/title3.png";
import title4 from "../../assets/images/title4.png";
import delete1 from "../../assets/images/delete1.png";
import Ellipse54 from "../../assets/images/Ellipse54.png";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { default as MyModal } from "../../Components/Common/Modal";

export default function ChartDetail() {
  const location = useLocation();
  console.log(location.state);
  const dispatch = useDispatch();
  const [DeleteConfirm, setDeleteConfirm] = useState(false);
  const [DeleteData, setDeleteData] = useState({});

  const handleClick = () =>{
    setDeleteConfirm(_=>!_);
  }

  const handleDeleteGoal = ()=>{
    if(DeleteData?.id != "")
      // deleteManager(DeleteData?.id);
      dispatch({ type: "DELETEMANAGERGOAL", value: DeleteData?.id })
      window.history.back();
      toast.error('Goal deleted successfully!');
      handleClick();
  }

  const DeleteManagerGoal = ({id}) =>{
      return <div onClick={()=>{
          handleClick();
          setDeleteData({ id });
      }}>
          <img className="action cursor-pointer" alt="del" src={delete1} />
      </div>
  }

  return (
    <div>
      <section
        style={{ minHeight: "55vh" }}
        class="My-Profile-wrapper bg-color"
      >
        <div
          class="My-Profile"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2>Goal Details</h2>
          <hr style={{ width: "10rem" }} class="border-pink" />

          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              flexDirection: "column",
              minHeight: "50vh",
              width: "50rem",
            }}
            class="form-information"
          >
            <div class="Goal-title">
              {location.state.quantitativeDetail ? (
                <div>
                  <div
                    className="d-flex flex-row justify-content-between mb-3">
                    <span>
                      {location.state.quantitativeDetail.goalStats[0] && (
                        <img
                          alt=""
                          style={{
                            width: "1rem",
                            height: "1rem",
                            marginRight: "0.5rem",
                          }}
                          src={title1}
                        />
                      )}
                      {location.state.quantitativeDetail.goalStats[0]
                        ? location.state.quantitativeDetail.goalStats[0]
                            ?.team_goal?.title
                        : null}
                    </span>
                    <div className="d-flex align-items-center gap-2">
                      <span>
                        {location.state.quantitativeDetail.goalStats[0] &&
                          Math.round(
                            location.state.quantitativeDetail.goalStats[0]
                              ?.quantitative_stats_percent * 100
                          ) /
                            100 +
                            "%"}
                      </span>
                      <DeleteManagerGoal id={location.state.quantitativeDetail.goalStats[0]?.goal_id} />
                    </div>
                  </div>
                  <div
                    className="d-flex flex-row justify-content-between mb-3"
                  >
                    <span>
                      {location.state.quantitativeDetail.goalStats[1] && (
                        <img
                          alt=""
                          style={{
                            width: "1rem",
                            height: "1rem",
                            marginRight: "0.5rem",
                          }}
                          src={title2}
                        />
                      )}
                      {location.state.quantitativeDetail.goalStats[1]
                        ? location.state.quantitativeDetail.goalStats[1]
                            ?.team_goal?.title
                        : null}
                    </span>
                    {location.state.quantitativeDetail.goalStats[1] && (
                      <div className="d-flex align-items-center gap-2">
                        <span>
                          {location.state.quantitativeDetail.goalStats[1] &&
                            Math.round(
                              location.state.quantitativeDetail.goalStats[1]
                                ?.quantitative_stats_percent * 100
                            ) /
                              100 +
                              "%"}
                        </span>
                        <DeleteManagerGoal id={location.state.quantitativeDetail.goalStats[1]?.goal_id} />
                      </div>
                    )}
                    
                  </div>
                  <div
                    className="d-flex flex-row justify-content-between mb-3"
                  >
                    <span>
                      {location.state.quantitativeDetail.goalStats[2] && (
                        <img
                          alt=""
                          style={{
                            width: "1rem",
                            height: "1rem",
                            marginRight: "0.5rem",
                          }}
                          src={title3}
                        />
                      )}
                      {location.state.quantitativeDetail.goalStats[2]
                        ? location.state.quantitativeDetail.goalStats[2]
                            ?.team_goal?.title
                        : null}
                    </span>
                    {
                      location.state.quantitativeDetail.goalStats[2] && (
                        <div className="d-flex align-items-center gap-2">
                          <span>
                            {location.state.quantitativeDetail.goalStats[2] &&
                              Math.round(
                                location.state.quantitativeDetail.goalStats[2]
                                  ?.quantitative_stats_percent * 100
                              ) /
                                100 +
                                "%"}
                          </span>
                          <DeleteManagerGoal id={location.state.quantitativeDetail.goalStats[2]?.goal_id} />
                        </div>
                      )
                    }
                    

                  </div>
                  <div
                   className="d-flex flex-row justify-content-between mb-3"
                  >
                    <span>
                      {location.state.quantitativeDetail.goalStats[3] && (
                        <img
                          alt=""
                          style={{
                            width: "1rem",
                            height: "1rem",
                            marginRight: "0.5rem",
                          }}
                          src={title4}
                        />
                      )}
                      {location.state.quantitativeDetail.goalStats[3]
                        ? location.state.quantitativeDetail.goalStats[3]
                            ?.team_goal?.title
                        : null}
                    </span>
                    {
                      location.state.quantitativeDetail.goalStats[3] && (
                        <div className="d-flex align-items-center gap-2">
                          <span>
                            {location.state.quantitativeDetail.goalStats[3] &&
                              Math.round(
                                location.state.quantitativeDetail.goalStats[3]
                                  ?.quantitative_stats_percent * 100
                              ) /
                                100 +
                                "%"}
                          </span>
                          <DeleteManagerGoal id={location.state.quantitativeDetail.goalStats[3]?.goal_id} />
                        </div>
                      )
                    }
                    
                  </div>
                  {location.state.quantitativeDetail.goalStats.map(
                    (item, index) => {
                      if (index > 3) {
                        return (
                          <div
                            className="d-flex flex-row justify-content-between mb-3">
                            <span>
                              {location.state.quantitativeDetail.goalStats[
                                index
                              ] && (
                                <img
                                  alt=""
                                  style={{
                                    width: "1rem",
                                    height: "1rem",
                                    marginRight: "0.5rem",
                                  }}
                                  src={Ellipse54}
                                />
                              )}
                              {location.state.quantitativeDetail.goalStats[
                                index
                              ]
                                ? location.state.quantitativeDetail.goalStats[
                                    index
                                  ]?.team_goal?.title
                                : null}
                            </span>
                            {
                              location.state.quantitativeDetail.goalStats[
                                index
                              ] && (
                                <div className="d-flex align-items-center gap-2">
                                <span>
                                  {Math.round(
                                    location.state.quantitativeDetail.goalStats[
                                      index
                                    ]?.quantitative_stats_percent * 100
                                  ) /
                                    100 +
                                    "%"}
                                </span>
                                <DeleteManagerGoal id={location.state.quantitativeDetail.goalStats[index]?.goal_id} />
                              </div>
                              )
                            }
                            
                          </div>
                        );
                      }
                    }
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
      <MyModal
        showmodal={DeleteConfirm}
        onCLose={handleClick}
        title={`Delete Goal?`}
        body={<div>Are you sure you want to delete this goal?</div>}
        handleClick={handleDeleteGoal}
      />
    </div>
  );
}
