import * as actions from "../type.action";
const initialState = {
  errorMsg: "",
  sucessMsg: "",
  ratingData: {},
  rateDetailData: {},
  chatPopupData: [],
  chatTitle: "",
  chatRoomdata: {},
  chatUser: [],
  chatList: [],
};

const chatReducer = (state, action) => {
  let newState = {};
  let obj = [];
  let id = 0;
  if (state === undefined) {
    return (state = initialState);
  }
  switch (action.type) {
    case actions.CHATPOPUP_DATASUCCESS:
      return {
        ...state,
        chatPopupData: action.data,
      };
    case "SETCHATTITLE":
      obj = Object.assign([], state.chatPopupData);
      id = action.data?.data?.id;
      obj.forEach((e, index) => {
        if (e?.id === id) {
          e["select"] = true;
        } else {
          e["select"] = false;
        }
      });
      newState = Object.assign({}, state, {
        chatPopupData: obj,
        chatTitle: action.data.data?.label || action.data?.name,
      });
      return newState;
    case actions.CHATROOM_DATASUCCESS:
      return {
        ...state,
        chatRoomdata: action.data,
      };

    case "setChatUser":
      return {
        ...state,
        chatUser: action.value,
      };
    
    case "CREATECHAT_ROOM":
      // Handle create chat room action
      return {
        ...state,
        chatRoomdata: action.value,
      };
    case "ONFIELD_CHANGE":
      // Handle on field change action
      // Update state accordingly
      return {
        ...state,
        // Update state properties based on the action
      };
    case "CHAT_NOTIF":
      // Handle chat notification action
      // Update state accordingly
      return {
        ...state,
        // Update state properties based on the action
      };
    case "GET_NOTIFICATION_COUNT":
      // Handle get notification count action
      // Update state accordingly
      return {
        ...state,
        // Update state properties based on the action
      };
    
    default:
      return state;
  }
};
export default chatReducer;
