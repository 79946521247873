import React from "react";
import { Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import ChatBot from "react-simple-chatbot";
import { useSelector } from "react-redux";

import Header from "./Common/Header";
import Footer from "./Common/Footer";

const theme = {
  width: "400px",
  background: "#f5f8fb",
  fontFamily: "Helvetica Neue",
  headerBgColor: "#5662DA",
  headerFontColor: "#fff",
  headerFontSize: "15px",
  botBubbleColor: "#ff7500;",
  botFontColor: "black",
  userBubbleColor: "#7883f0",
  userFontColor: "white",
};

const chatBotConfig = {
  hideSubmitButton: true,
  placeholder: "",
  submitButtonStyle: { width: "50px" },
  floating: true,
  floatingStyle: { width: "65px", height: "65px" },
};

let steps = [
  {
    id: "1",
    message: "Hi there 👋",
    trigger: "2",
  },
  {
    id: "2",
    message: "How can I help you?",
    trigger: "3",
  },
  {
    id: "3",
    options: [
      { value: 1, label: "I want to learn about BluXinga", trigger: "4" },
      {
        value: 2,
        label: "I would  like to speak to BluXinga support",
        trigger: "5",
      },
    ],
  },
  {
    id: "4",
    message: "Great! What would you like to know about?",
    trigger: "8",
  },
  {
    id: "8",
    options: [
      { value: 1, label: "What is BluXinga?", trigger: "9" },
      { value: 2, label: "Why use BluXinga?", trigger: "10" },
      { value: 3, label: "How does BluXinga work?", trigger: "11" },
      { value: 4, label: "What does BluXinga cost?", trigger: "12" },
      { value: 5, label: "Can I create an account?", trigger: "13" },
    ],
  },

  {
    id: "9",
    message:
      "We enable people to manage their work related data in a simple way.BluXinga is a powerful Employee management platform for organizations that puts people first.",
    trigger: 14,
  },
  {
    id: "10",
    message:
      "Organizations adopt BluXinga in order to create a strong employee management system.Using the platform, employers can manage their employees using a dedicated platform.",
    trigger: 14,
  },
  {
    id: "11",
    message:
      "In BluXinga, employers can create the targets and employees can provide the respective data and easily track the progress.",
    trigger: 14,
  },
  {
    id: "12",
    message:
      "It is completely free for initial 7 days as a trial. The latest cost you can check under the pricing section.",
    trigger: 14,
  },
  {
    id: "13",
    message:
      'BluXinga is a platform for businesses.In order to create an account, you can connect to BluXinga team on "support email"',
    trigger: 14,
  },
  {
    id: "14",
    message: "Any other questions?",
    trigger: 15,
  },

  {
    id: "15",
    options: [
      { value: 1, label: "Yes", trigger: "8" },
      { value: 2, label: "No", trigger: "16" },
    ],
  },
  {
    id: "16",
    message: "Alright!",
    end: true,
  },

  {
    id: "5",
    message: "Thank you for getting in touch with us!",
    trigger: 6,
  },
  {
    id: "6",
    message:
      "I would recommend you to email us at customercare@bluxinga.com to connect with us directly.",
    trigger: 7,
  },
  {
    id: "7",
    message:
      "If you ever need support we are always available at customercare@bluxinga.com. 👋",
    end: true,
  },
];

function PageLayout({ component: Component, ...rest }) {
  const loginRole = useSelector((state)=> state.loginReducer.loginRole)
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Header />
          <Component {...props} />
          {loginRole === 0 && (
            <div>
              <ThemeProvider theme={theme}>
                <ChatBot
                  steps={steps}
                  {...chatBotConfig}
                  width={"450px"}
                  userDelay={2000}
                />
              </ThemeProvider>
            </div>
          )}
          <Footer />
        </>
      )}
    />
  );
}

export default PageLayout;
