import React, { useEffect, useState } from "react";
import ChatThread from "../Components/ChatThread";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect, useSelector } from "react-redux";
import { GET } from "../utils/helper";
const Chat = (props) => {
  const userId = localStorage.getItem("id");
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [renderLastMessageOnDelete, setRenderLastMessageOnDelete] = useState(
    true
  );
  const chatUser = props.chatUser;
  const loginRole = useSelector((state) => state.loginReducer.loginRole);
  const getData = () => {
    GET("getChatList")
      .then(async (resp) => {
        setLoading(false);

        if (resp.body.data) {
          var tempArray = [];
          for (var i = 0; i < resp.body.data.length; i++) {
            const currentChatInfo = resp.body.data[i]
            if (resp.body.data[i].type === 2) {
              var newtemp = {
                id: resp.body.data[i].id,
                profilePic: getAvatarChat(currentChatInfo),
                name: resp.body.data[i].group_name,
                lastMessage: "",
                lastMessageTime: "",
                room_id: resp.body.data[i].room_id,
                user: null,
                is_disabled: resp.body.data[i].is_disabled,
                type: resp.body.data[i].type,
                chatLastDeletedOn: resp.body.data[i].chatLastDeletedOn,
                group_members: resp.body.data[i].group_members,
              };
              tempArray.push(newtemp);

              continue;
            }

            var userData = resp.body.data[i].user;
            var temp = {
              id: userData.id,
              profilePic: getAvatarChat(currentChatInfo),
              name: userData.name,
              lastMessage: "",
              lastMessageTime: "",
              room_id: resp.body.data[i].room_id,
              user: resp.body.data[i].user,
              is_disabled: resp.body.data[i].is_disabled,
              type: resp.body.data[i].type,
              chatLastDeletedOn: resp.body.data[i].chatLastDeletedOn,
            };
            tempArray.push(temp);
          }
          props.getChatUser([...tempArray]);
        }
      })
      .catch((err) => {
        setLoading(false);

        if (err && err.msg && err.msg.message) {
          toast.error(err.msg.message, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      });
  };
    
  useEffect(() => {
    getData();
  }, [refresh]);

  useEffect(() => {
    setRenderLastMessageOnDelete(!renderLastMessageOnDelete);
  }, [chatUser]);

  const resortChatList = () => {
    try {
      let chatList = [...chatUser];
      let sortedChatList = chatList.sort((el1, el2) => {
        const l1 = el1.lastMessageTime ? el1.lastMessageTime : -1;
        const l2 = el2.lastMessageTime ? el2.lastMessageTime : -1;
        return l2 - l1;
      });

      props.getChatUser(sortedChatList);
      setLoading(false);
    } catch (error) {
      console.log("Error ", error);
      setLoading(false);
    }
  };

  const updateLastMessageForRoom = (roomId, message) => {
    let deepCopyUsers = [...chatUser];
    for (let i = 0; i < deepCopyUsers.length; i++) {
      if (deepCopyUsers[i].room_id === roomId) {
        deepCopyUsers[i].lastMessage = message?.text;
        deepCopyUsers[i].lastMessageId = message?._id;
        deepCopyUsers[i].lastMessageTime = message?.timestamp;
        deepCopyUsers[i].lastMessageUserId = message?.user?._id;
        deepCopyUsers[i].isRead = message?.isRead;
      }
    }
    props.getChatUser(deepCopyUsers);

    resortChatList();
  };

  const getAvatarChat = (chatInfo) => {
    // check user type
    switch (chatInfo.type) {
      case 0:
        // employee
        return chatInfo?.user?.profile_pic_url;
      case 2:
        // group
        return chatInfo?.group_icon_url;
      case 3:
        // BT_admin
        return null;
      default:
        return null
    }
  };

  const renderItem = (item, index) => {
    const {
      profilePic,
      name,
      room_id,
      lastMessage,
      lastMessageId,
      lastMessageTime,
      lastMessageUserId,
      isRead,
      type,
      group_members,
      chatLastDeletedOn,
    } = item;
    return (
      <ChatThread
        key={room_id}
        profilePic={profilePic}
        name={name}
        item={item}
        history={props.history}
        type={type}
        updateLastMessageForRoom={updateLastMessageForRoom}
        roomId={room_id}
        lastMessage={lastMessage ? lastMessage : ""}
        lastMessageId={lastMessageId ? lastMessageId : ""}
        lastMessageTime={lastMessageTime ? lastMessageTime : ""}
        refresh={refresh}
        setRefresh={setRefresh}
        group_members={group_members ? group_members : null}
        isMessageRead={
          lastMessageUserId && lastMessageUserId === userId ? true : isRead
        }
        chatLastDeletedOn={chatLastDeletedOn}
        renderLastMessageOnDelete={renderLastMessageOnDelete}
        chatUser={chatUser}
        getData={getData}
      />
    );
  };

  return (
    <React.Fragment>
      <section
        style={{ minHeight: "80vh" }}
        class="chat-details-wrapper bg-color"
      >
        {!loading && chatUser?.length === 0 && (
          <div className="teammsg">No Team Member to Chat!</div>
        )}

        {loading && (
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        )}

        <div
          class="container chatthread"
          style={{ padding: "45px !important" }}
        >
          {chatUser?.length > 0 &&
            chatUser?.map((data, id) => renderItem(data))}
        </div>
      </section>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    chatUser: state.chatReducer.chatUser,
    profileData:state.profileReducer.profileData
  };
};

const mapDispatch = (dispatchEvent) => ({
  getChatUser: (payload) =>
    dispatchEvent({ type: "setChatUser", value: payload }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
  creteChatRoom: (payload) =>
    dispatchEvent({ type: "CREATECHAT_ROOM", value: payload }),
  updateProfileData: (payload) =>
    dispatchEvent({ type: "UPDATEPROFILEDATA", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(Chat);
