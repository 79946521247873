import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { MdEdit } from "react-icons/md";
import { BsFillClockFill } from "react-icons/bs";
import dotfill from "../assets/images/dot-fill.png";
import dotempty from "../assets/images/dot-empty.png";
import biglight from "../assets/images/big-light.png";
import bigghand5 from "../assets/images/big-hand.png";
import sms from "../assets/images/sms-green.png";
import bigmessage from "../assets/images/big-message.png";
import sky from "../assets/images/sky-icon.png";
import coachwhistle from "../assets/images/coachwhistle.png";
import { Modal, ModalBody } from "reactstrap";
import cross from "../assets/images/cross.png";
import firebase from "firebase";
import { putAPI, GET, POST, PENDING_RATE } from "../utils/helper";
import { useHistory } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import ReactStars from "react-rating-stars-component";
import QuantitativeChart from "./QuantitativeChart";
import setChatTitle from "../utils/ChatTitle";
import { toast } from "react-toastify";
import { isArray } from "lodash";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const TeamMemberDashboard = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [emojiData, setEmojiData] = useState([]);
  const [viewEmployeeEnergy, setViewEmployeeEnergy] = useState(null);
  const [isShowEmojis, setShowEmojis] = useState(false);
  const [quantitativeDetail, setQuantitaveDetail] = useState(null);
  const [primary, setPrimary] = useState([]);
  const [quantitativeDetailEmployee, setQuantitaveDetailEmployee] = useState(
    null
  );
  const [quantitativeDetailEmployeeUpdated, setQuantitaveDetailEmployeeUpdated] = useState(
    []
  );
  const [ThoughOftheDay, setThoughOftheDay] = useState("");
  const [myAttributeLoading, setMyAttributeLoading] = useState(false);
  const achievementCount = useSelector(
    (state) => state.notificationCountReducer.achievement_post_only
  );

  const messaging = firebase.messaging.isSupported()
    ? firebase.messaging()
    : null;
  const viewEmployeeEnergyFunc = async () => {
    const res = await GET("viewEmployeeEnergy");
    setViewEmployeeEnergy(res?.body?.data);
    setShowEmojis(res?.body?.data?.isUpdateAvailable || false);
  };

  useEffect(() => {
    dispatch({ type: "GET_NOTIFICATION_COUNT" });
    loadId();
    getFcmToken();

    const getEmojis = async () => {
      const res = await GET("getEmoji");

      setEmojiData(res?.body?.data?.reverse());
    };
    const getQuantitativeStats = async () => {
      try {
        const res = await GET("getQuantitativeStatsOfGoals");

        let newprimary = [];
        res.body.data.quantitativeStats.map((item) => {
          if (item.is_primary) {
            newprimary.push(item);
          }
        });

        if (newprimary.length > 0) {
          setPrimary(newprimary);
        } else {
          setPrimary(res.body.data.quantitativeStats);
        }

        setQuantitaveDetail(res.body.data.quantitativeStats);
      } catch (error) { }
    };

    const getQualitativeMeasurement = async () => {
      try {
        setMyAttributeLoading(true);
        const res = await GET(
          `getAttributeRatings?employee_id=${localStorage.getItem("id")}`
        );
        setMyAttributeLoading(false);
        if (res?.body?.data) {
          setQuantitaveDetailEmployee(res?.body?.data);
        } else {
          setQuantitaveDetailEmployee(null);
        }
      } catch (error) {
        setMyAttributeLoading(false);
        console.log(error);
        setQuantitaveDetailEmployee(null);
      }
    };


    const viewThoughtOfTheDay = async () => {
      const d = new Date();
      const res = await GET(`getThought?date=${d}`);

      setThoughOftheDay(res?.body?.data?.thought);
    };

    getEmojis();
    viewEmployeeEnergyFunc();
    getQuantitativeStats();
    getQualitativeMeasurement();
    viewThoughtOfTheDay();
  }, []);

  useEffect(() => {
    window?.navigator &&
      window.navigator.mediaDevices?.getUserMedia({ audio: true })
        .then((stream) => {
          stream.getTracks().forEach((track) => track.stop());
        })
        .catch((error) => {
          console.log(error);
        });
  }, []);
  const loadId = async () => {
    props.getProfileData();
    props.getchatpopupData();
    await props.getManagerId();
  };

  useEffect(() => {
    if (quantitativeDetailEmployee) {

      const groupedRatings = quantitativeDetailEmployee.reduce((acc, curr) => {
        const { attribute_id, rating } = curr;

        if (!acc[attribute_id]) {
          acc[attribute_id] = { ...curr, ratingSum: rating, count: 1 };
        } else {
          acc[attribute_id].ratingSum += rating;
          acc[attribute_id].count += 1;
        }

        return acc;
      }, {});

      const averagedRatings = Object.values(groupedRatings).map(obj => {
        return {
          ...obj,
          rating: obj.ratingSum / obj.count,
        };

      });
      const finalRatings = averagedRatings.map(({ ratingSum, count, ...rest }) => rest);
      setQuantitaveDetailEmployeeUpdated(finalRatings)
    }
  }, [quantitativeDetailEmployee]);


  const getFcmToken = async () => {
    const fcmToken = await messaging?.getToken();
    if (fcmToken) {
      localStorage.setItem("fcm_token", fcmToken);
      putAPI("updateEmployeeDeviceToken", { device_token: fcmToken })
        .then((res) => { })
        .catch((error) => {
          console.log("fcmtoken api error------->", error);
        });
    } else {
      console.log("Failed", "No token received");
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [ThoughtmodalOpen, setThoughtModalOpen] = useState(false);

  const handleEnergyCheck = async (type) => {
    await POST("updateEnergyCheck", { energy_id: type });

    viewEmployeeEnergyFunc();
  };

  return (
    <React.Fragment>
      <section className="profiles-wrapper-main">
        <div className="container">
          <div className="profiles-wrapper">
            <div className="notification-wrap  notification-wrap-teammember-dash">
              <div className="row">
                <div className="col-md-6 light-img-wrap">
                  <div className="light-img">
                    <img
                      alt="biglight"
                      onClick={() => setThoughtModalOpen(true)}
                      src={biglight}
                    />
                    {viewEmployeeEnergy &&
                      !viewEmployeeEnergy.isUpdateAvailable ? (
                      <div
                        className="mogi-react"
                        onClick={() => setShowEmojis(!isShowEmojis)}
                      >
                        <img
                          alt=""
                          id={viewEmployeeEnergy.emoji.id}
                          src={viewEmployeeEnergy?.emoji?.image_url}
                        />
                      </div>
                    ) : (
                      <img
                        width={84}
                        alt="disable-happy-face"
                        id="disable_happy_face"
                        src="https://bluexinga-prod.s3.us-east-2.amazonaws.com/disable_happy_face_2.png"
                      />
                    )}
                    <div className="big-hand  position-relative">
                      {achievementCount && achievementCount > 0 ? (
                        <div className="team-member-achievement-count">
                          <div>
                            {achievementCount}
                          </div>
                        </div>
                      ) : null}

                      <img
                        onClick={() => {
                          history.push("/achievement");
                        }}
                        className="action-img"
                        alt=""
                        src={bigghand5}
                      />
                    </div>
                    <img
                      className="whistle-icon"
                      onClick={() => {
                        if (firebase.messaging.isSupported()) {
                          history.push("/listofcoaches");
                        }
                      }}
                      alt=""
                      width={100}
                      src={coachwhistle}
                    />
                    <img
                      onClick={() => {
                        if (firebase.messaging.isSupported()) {
                          setModalOpen(true);
                        }
                      }}
                      src={bigmessage}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="profile-comman-box  margin-top-32">
              {viewEmployeeEnergy ? (
                isShowEmojis ? (
                  <div className="row">
                    <div className="col-md-6  comman-width one">
                      <div className="profiles smail">
                        <p className="how-is-energy">
                          How is your energy level?
                        </p>

                        <div className="feeling-react">
                          <div className="row gap-2">
                            {emojiData.map((item, index) => (
                              <div key={index} className="col">
                                <div className="react-img">
                                  <img
                                    alt=""
                                    onClick={(e) => handleEnergyCheck(item.id)}
                                    src={item.image_url}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null
              ) : (
                <div
                  className="spinner-border text-primary  margin-top-15rem"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <QuantitativeChart
        from={"teammember"}
        quantitativeDetail={quantitativeDetail}
        primary={primary}
        onFieldChange={props.onFieldChange}
        id={props.id}
      />
      <section className="Qualitative-wrapper  team-member-dashboard-section">
        <div className="container">
          <div className="Qualitative">
            {isArray(quantitativeDetailEmployeeUpdated) ? ( 
              <div>
                <div className="Qualitative-details">
                  {quantitativeDetailEmployeeUpdated?.map((item) => (
                    <div key={item?.id} className="Qualitative-row">
                      <div className="row  contact-textareas-team-member gap-3">
                        <div className="col-md-8 col-sm-4 col-12 Qualitative-name d-flex align-items-center flex-sm-row flex-column flex-wrap justify-content-sm-between justify-content-center">
                          <p>{item?.attribute?.name}</p>
                          <div
                            className="Qualitative-star float-right"
                            style={{ fontSize: "35px" }}
                          >
                            <ReactStars
                              {...{
                                color: "#C9C9C9",
                                size: 35,
                                value: item?.rating,
                                edit: false,
                                activeColor: "#FF7500",
                                fontSize: "556px",
                              }}
                            />
                          </div>
                        </div>
                        {item?.state == PENDING_RATE ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="achivement-measure">
                                Waiting for manager review
                              </Tooltip>
                            }
                          >
                            <>
                              <BsFillClockFill
                                style={{ cursor: "pointer" }}
                                size="25"
                                color="#FF7500"
                                onClick={() =>
                                  history.push({
                                    pathname: "/qualitative-rating",
                                    state: {
                                      qualitative_id: item?.attribute_id,
                                      employeeAttribute: {
                                        name: item?.attribute?.name,
                                        rating: item?.employee_rating,
                                        comment: item?.attribute?.comment,
                                        decs: item?.comment,
                                      },
                                    },
                                  })
                                }
                              />
                            </>
                          </OverlayTrigger>
                        ) : null}

                        <div>
                          <i
                            class="fa fa-eye   quatatitive-inline-i"
                            aria-hidden="true"
                            onClick={() =>
                              history.push({
                                pathname: "/qualitativedetail",
                                state: {
                                  item: [
                                    {
                                      ...item,
                                      name: item?.attribute?.name,
                                      desc: item?.comment,
                                    },
                                  ],
                                  from: "teammember",
                                },
                              })
                            }
                          ></i>
                        </div>

                        <MdEdit
                          style={{ cursor: "pointer" }}
                          size="25"
                          color="#FF7500"
                          onClick={() =>
                            history.push({
                              pathname: "/qualitative-rating",
                              state: {
                                qualitative_id: item?.attribute_id,
                                employeeAttribute: {
                                  name: item?.attribute?.name,
                                  rating: item?.rating,
                                  comment: item?.attribute?.comment,
                                  decs: item?.comment,
                                },
                                id: item?.id
                              },
                            })
                          }
                        />

                        <div class="date-wrapper  top-65px">
                          {"teammember" === "teammember" && (
                            <img
                              onClick={() => {
                                if (firebase.messaging.isSupported()) {
                                  setChatTitle(props, item?.attribute, item.id);
                                }
                              }}
                              className="team-member-dashboard-img"
                              src={sms}
                              alt=""
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : myAttributeLoading ? (
              <div></div>
            ) : (
              <div className="heding  heading-color">
                <h4>
                  No rating has been given. Reach out to your manager.
                </h4>
              </div>
            )}
          </div>
        </div>
      </section>
      <Modal isOpen={modalOpen} className="chatmodal">
        <ModalBody>
          <div
            className="select-team-member-wrapper"
            style={{ width: "max-content" }}
          >
            <img
              alt=""
              style={{ position: "absolute", top: "-20px", right: "-22px" }}
              src={cross}
              className="cross"
              onClick={() => setModalOpen(false)}
            />

            <div className="team-member-head">
              <p>Select</p>
            </div>

            <div className="select-team-member">
              <ul>
                {props.chatPopupData?.map((data, id) => {
                  if (data.team_goal) {
                    return (
                      <li
                        style={{ marginBottom: "20px", marginRight: 10 }}
                        key={data.id}
                        onClick={() => {
                          if (!props.profileData.manager_team_member) {
                            toast.info(
                              "This is the top-level manager. Only your employees can start a chat",
                              {
                                position: toast.POSITION.BOTTOM_CENTER,
                              }
                            );
                            return;
                          }

                          setChatTitle(props, data, id);
                        }}
                      >
                        <img
                          alt=""
                          src={data?.select === true ? dotfill : dotempty}
                        />{" "}
                        <span>
                          {data?.team_goal.title?.charAt(0).toUpperCase() +
                            data?.team_goal.title?.slice(1)}
                        </span>{" "}
                      </li>
                    );
                  }
                  if (data.label) {
                    return (
                      <li
                        style={{ marginBottom: "20px", marginRight: 10 }}
                        key={data.id}
                        onClick={() => {
                          if (!props.profileData.manager_team_member) {
                            toast.info(
                              "This is the top-level manager. Only your employees can start a chat",
                              {
                                position: toast.POSITION.BOTTOM_CENTER,
                              }
                            );
                            return;
                          }
                          setChatTitle(props, data, id);
                        }}
                      >
                        <img
                          alt=""
                          src={data?.select === true ? dotfill : dotempty}
                        />{" "}
                        <span>
                          {data?.label?.charAt(0).toUpperCase() +
                            data?.label?.slice(1)}
                        </span>{" "}
                      </li>
                    );
                  }

                  if (data.name) {
                    return (
                      <li
                        style={{ marginBottom: "20px", marginRight: 10 }}
                        key={data.id}
                        onClick={() => {
                          if (!props.profileData.manager_team_member) {
                            toast.error(
                              "This is the top-level manager. Only your employees can start a chat",
                              {
                                position: toast.POSITION.BOTTOM_CENTER,
                              }
                            );
                            return;
                          }
                          setChatTitle(props, data, id);
                        }}
                      >
                        <img
                          style={{ margineRight: "2px" }}
                          alt=""
                          src={data?.select === true ? dotfill : dotempty}
                        />{" "}
                        <span>
                          {data?.name?.charAt(0).toUpperCase() +
                            data?.name?.slice(1)}
                        </span>{" "}
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={ThoughtmodalOpen} className="thoughtmodal">
        <ModalBody>
          <img
            alt=""
            src={cross}
            className="cross"
            onClick={() => setThoughtModalOpen(false)}
          />

          <div className="profiles-wrapper-main w-100">
            <div className="profiles-wrapper m-0">
              <div className="profiles sun">
                <div className="row h-100">
                  <div className="col-md-5 h-100">
                    <img alt="sky" src={sky} />
                  </div>
                  <div className="col-md-7 overflow-auto position-relative h-100 d-flex justify-content-center flex-column">
                    <h5>Thought of the day</h5>
                    <p>
                      {ThoughOftheDay}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  const profileData = state.profileReducer.profileData;
  const errorMsg = state.profileReducer.errorMsg;
  const chatPopupData = state.chatReducer.chatPopupData;
  const loading = state.globalReducer.loading;

  return {
    profileData,
    loading,
    errorMsg,
    chatPopupData,
    chatRoomdata: state.chatReducer.chatRoomdata,
  };
};

const mapDispatch = (dispatchEvent) => ({
  getProfileData: () => dispatchEvent({ type: "GET_PROFILEDATA" }),
  getchatpopupData: () => dispatchEvent({ type: "GET_CHATPOPUP" }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
  creteChatRoom: (payload) =>
    dispatchEvent({ type: "CREATECHAT_ROOM", value: payload }),
  getManagerId: () => dispatchEvent({ type: "GETMANGER_ID" }),
});

export default connect(mapStateToProps, mapDispatch)(TeamMemberDashboard);
