import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import "./style.scss";
import Successfully from "../../assets/images/Successfully.png";
import plus from "../../assets/images/plus.png";
import cross from "../../assets/images/cross.png";
import AddEditGoalForm from "./components/AddEditGoalForm";
import { addGoalsValidation } from "../../utils/validation";
import { useAddGoals, useEditGoal, useGoalById, useDeleteGoal } from "./../../hooks/goal.hook";
import { toString } from "lodash";
import moment from "moment";

function convertJsonToGoalObject(inputJson) {
  const output = {
    start_date: moment(inputJson.start_date).format("YYYY-MM-DD") || "",
    end_date: moment(inputJson.end_date).format("YYYY-MM-DD") || "",
    title: inputJson.title || "",
    description: inputJson.description || "",
    select_measure: toString(inputJson.select_measure) || "",
    employees_measures:
      inputJson.team_goal_assigns?.map((assign) => ({
        value: assign.employee.id,
        label: assign.employee.name,
        employee_id: assign.employee.id,
        measure: assign.measure,
      })) || [],
    isOpen: true,
    disable: false,
  };

  return output;
}

const SetupGoal = (props) => {
  const params = useParams();
  const loginRole = useSelector((state) => state.loginReducer.loginRole);
  const addGoalsMutation = useAddGoals(loginRole);
  const editGoalMutation = useEditGoal(loginRole);
  const deleteGoalMutation = useDeleteGoal(loginRole)
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [deletes, setDeletes] = useState(false);
  const isEdit = params?.id ? true : false;
  const goalQuery = useGoalById(loginRole, params?.id, isEdit);
  const dispatch = useDispatch();
  const history = useHistory();
  const initialValues = {
    start_date: "",
    end_date: "",
    title: "",
    description: "",
    select_measure: "",
    employees_measures: [],
    isOpen: true,
    disable: false,
  };

  const formik = useFormik({
    initialValues: {
      goals: [initialValues],

    },
    validationSchema: addGoalsValidation,
    onSubmit: async (values) => {

      try {
        if (deletes) {
          await deleteGoalMutation.mutateAsync({ ...values.goals, id: params.id });
        } else {
          const goals = values.goals.map((goal) => {
            const { isOpen, disable, ...cleanedGoal } = goal;
            const cleanedTeamGoalAssigns = cleanedGoal?.employees_measures?.map(
              (assign) => {
                const { value, label, ...cleanedAssign } = assign;
                return cleanedAssign;
              }
            );
            return {
              ...cleanedGoal,
              employees_measures: cleanedTeamGoalAssigns,
            };
          });

          if (!isEdit) {
            await addGoalsMutation.mutateAsync({ goal_details: goals });
          } else {
            await editGoalMutation.mutateAsync({ ...goals[0], id: params.id });
          }
        }
        setShowSuccessModal(true);
        setDeletes(false);
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:54 ~ onSubmit: ~ error:", error);
      }
    },
  });

  const handleToggleExpandGoal = (goalIndex) => {
    const goals = [...formik.values.goals];
    goals[goalIndex].isOpen = !goals[goalIndex].isOpen;
    formik.setFieldValue("goals", goals);
  };

  const handleDeleteNewGoal = async (goalIndex) => {
    if (
      formik.values.goals.length >= 1 &&
      window.confirm("Are you sure you want to delete this goal")
    ) {
      try {

        await deleteGoalMutation.mutateAsync({ id: params.id });
        toast.success("Goal Deleted successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        history.push('/goal')

      } catch (error) {
        console.log(error)
        toast.error("Something went wrong", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
  };

  const handleAddMoreGoal = () => {
    const goals = [...formik.values.goals];
    goals.push(initialValues);
    formik.setFieldValue("goals", goals);
  };

  useEffect(() => {
    if (goalQuery.data && isEdit) {
      formik.setValues({
        ...formik.values,
        goals: [convertJsonToGoalObject(goalQuery.data.body.data)],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalQuery.isLoading]);

  return (
    <>
      <div className="setup-goal bg-color min-height-60vh">
        {!goalQuery.isLoading || !isEdit ? (
          <form className="goals container" onSubmit={formik.handleSubmit}>
            {formik.values.goals?.map((item, id) => (
              <AddEditGoalForm
                key={id}
                index={id}
                goalItem={item}
                formik={formik}
                onClickExpand={handleToggleExpandGoal}
                onClickDelete={handleDeleteNewGoal}
              />
            ))}

            <div className="goals-actions">
              {!isEdit ? (
                <>
                  <button
                    type="button"
                    className="add-goal"
                    onClick={handleAddMoreGoal}
                  >
                    <img src={plus} alt="" /> <span>Add Goal</span>
                  </button>
                  <div className="btn-blue">
                    <button
                      type="submit"
                      className="assign-goal"
                      disabled={addGoalsMutation.isLoading}
                    >
                      Assign Goal
                    </button>
                  </div>
                </>
              ) : (
                <button
                  type="submit"
                  className="assign-goal"
                  disabled={addGoalsMutation.isLoading}
                >
                  Update Goal
                </button>
              )}
            </div>
          </form>
        ) : (
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
      </div>
      <Modal isOpen={showSuccessModal} className="goalsucessmodal ">
        <ModalBody>
          <div className="Successfully-goal">
            <img
              src={cross}
              className="cross"
              onClick={() => props.history.push("/goal")}
              alt=""
            />
            <img src={Successfully} alt="" />
            {!isEdit ? (
              <>
                <p>Goals are</p>
                <p>Successfully Submitted!</p>
              </>
            ) : (
              <>
                <p>Updated Successfully!</p>
              </>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SetupGoal;
