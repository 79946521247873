import moment from "moment";

export const StoredEmails = ["kevin.lichtenfels@bluxinga.com", "customer.care@bluxinga.com"];

export const OverallMood = {
    "0": [14, 2],
    "7": [57, 9],
    "30": [249, 60],
}

const mood = [
    {
        "good_mood": "3",
        "bad_mood": "7"
    },
    {
        "good_mood": "48",
        "bad_mood": "43"
    },
    {
        "good_mood": "83",
        "bad_mood": "62",
    },
    {
        "good_mood": "24",
        "bad_mood": "89",
    },
    {
        "good_mood": "69",
        "bad_mood": "27",
    },
    {
        "good_mood": "87",
        "bad_mood": "91",
    },
    {
        "good_mood": "56",
        "bad_mood": "32",
    },
    {
        "good_mood": "7",
        "bad_mood": "16",
    },
    {
        "good_mood": "8",
        "bad_mood": "31",
    },
    {
        "good_mood": "25",
        "bad_mood": "86",
    },
    {
        "good_mood": "37",
        "bad_mood": "30",
    },
    {
        "good_mood": "89",
        "bad_mood": "92",
    },
    {
        "good_mood": "95",
        "bad_mood": "82",
    },
    {
        "good_mood": "62",
        "bad_mood": "92",
    },
    {
        "good_mood": "36",
        "bad_mood": "59",
    },
    {
        "good_mood": "24",
        "bad_mood": "87",
    },
    {
        "good_mood": "43",
        "bad_mood": "17",
    },
    {
        "good_mood": "32",
        "bad_mood": "77",
    },
    {
        "good_mood": "41",
        "bad_mood": "85",
    },
    {
        "good_mood": "27",
        "bad_mood": "36",
    },
    {
        "good_mood": "87",
        "bad_mood": "92",
    },
    {
        "good_mood": "21",
        "bad_mood": "80",
    },
    {
        "good_mood": "44",
        "bad_mood": "95",
    },
    {
        "good_mood": "19",
        "bad_mood": "7",
    },
    {
        "good_mood": "73",
        "bad_mood": "60",
    },
    {
        "good_mood": "2",
        "bad_mood": "38",
    },
    {
        "good_mood": "23",
        "bad_mood": "44",
    },
    {
        "good_mood": "50",
        "bad_mood": "29",
    },
    {
        "good_mood": "96",
        "bad_mood": "48",
    },
    {
        "good_mood": "49",
        "bad_mood": "94",
    },
    {
        "good_mood": "57",
        "bad_mood": "27",
    },
    {
        "good_mood": "39",
        "bad_mood": "5",
    },
    {
        "good_mood": "26",
        "bad_mood": "18",
    },
    {
        "good_mood": "33",
        "bad_mood": "46",
    },
    {
        "good_mood": "75",
        "bad_mood": "52",
    },
    {
        "good_mood": "97",
        "bad_mood": "32",
    },
    {
        "good_mood": "36",
        "bad_mood": "37",
    },
    {
        "good_mood": "65",
        "bad_mood": "21",
    },
    {
        "good_mood": "49",
        "bad_mood": "4",
    },
    {
        "good_mood": "38",
        "bad_mood": "3",
    },
    {
        "good_mood": "55",
        "bad_mood": "11",
    },
    {
        "good_mood": "8",
        "bad_mood": "85",
    },
    {
        "good_mood": "43",
        "bad_mood": "71",
    },
    {
        "good_mood": "20",
        "bad_mood": "87",
    },
    {
        "good_mood": "71",
        "bad_mood": "71",
    },
    {
        "good_mood": "77",
        "bad_mood": "55",
    },
    {
        "good_mood": "56",
        "bad_mood": "70",
    },
    {
        "good_mood": "78",
        "bad_mood": "95",
    },
    {
        "good_mood": "26",
        "bad_mood": "84",
    },
    {
        "good_mood": "70",
        "bad_mood": "70",
    },
    {
        "good_mood": "89",
        "bad_mood": "42",
    },
    {
        "good_mood": "23",
        "bad_mood": "63",
    },
    {
        "good_mood": "64",
        "bad_mood": "90",
    },
    {
        "good_mood": "6",
        "bad_mood": "65",
    },
    {
        "good_mood": "21",
        "bad_mood": "0",
    },
    {
        "good_mood": "27",
        "bad_mood": "26",
    },
    {
        "good_mood": "5",
        "bad_mood": "66",
    },
    {
        "good_mood": "62",
        "bad_mood": "61",
    },
    {
        "good_mood": "69",
        "bad_mood": "89",
    },
    {
        "good_mood": "82",
        "bad_mood": "98",
    },
    {
        "good_mood": "20",
        "bad_mood": "71",
    },
    {
        "good_mood": "23",
        "bad_mood": "45",
    },
    {
        "good_mood": "47",
        "bad_mood": "97",
    },
    {
        "good_mood": "67",
        "bad_mood": "58",
    },
    {
        "good_mood": "5",
        "bad_mood": "48",
    },
    {
        "good_mood": "78",
        "bad_mood": "65",
    },
    {
        "good_mood": "82",
        "bad_mood": "22",
    },
    {
        "good_mood": "15",
        "bad_mood": "3",
    },
    {
        "good_mood": "27",
        "bad_mood": "62",
    },
    {
        "good_mood": "84",
        "bad_mood": "45",
    },
    {
        "good_mood": "7",
        "bad_mood": "1",
    },
    {
        "good_mood": "38",
        "bad_mood": "65",
    },
    {
        "good_mood": "15",
        "bad_mood": "87",
    },
    {
        "good_mood": "98",
        "bad_mood": "67",
    },
    {
        "good_mood": "9",
        "bad_mood": "11",
    },
    {
        "good_mood": "10",
        "bad_mood": "90",
    },
    {
        "good_mood": "22",
        "bad_mood": "60",
    },
    {
        "good_mood": "9",
        "bad_mood": "60",
    },
    {
        "good_mood": "17",
        "bad_mood": "63",
    },
    {
        "good_mood": "51",
        "bad_mood": "45",
    },
    {
        "good_mood": "49",
        "bad_mood": "14",
    },
    {
        "good_mood": "10",
        "bad_mood": "14",
    },
    {
        "good_mood": "48",
        "bad_mood": "60",
    },
    {
        "good_mood": "72",
        "bad_mood": "37",
    },
    {
        "good_mood": "86",
        "bad_mood": "40",
    },
    {
        "good_mood": "92",
        "bad_mood": "23",
    },
    {
        "good_mood": "9",
        "bad_mood": "79",
    },
    {
        "good_mood": "83",
        "bad_mood": "50",
    },
    {
        "good_mood": "79",
        "bad_mood": "55",
    },
    {
        "good_mood": "65",
        "bad_mood": "51",
    }
]

export const Score = (number_of_items) => {
    let arr = [];
    const currentDate = moment();
    for (let i = 0; i < number_of_items; i++) {
        const previousDate = moment(currentDate).subtract(i, 'days');
        const formattedDate = previousDate.format('YYYY-MM-DD');
        arr.push({
            "good_mood": mood[i].good_mood,
            "bad_mood": mood[i].bad_mood,
            "date": formattedDate,
        });
    };
    return arr;
}

export const Streaks = (userInfo) =>{
    let userName = userInfo?.name ? userInfo?.name : "";
    return [
        {
            "id": "",
            "name": userName,
            "good_mood_streak": 2,
            "bad_mood_streak": 1,
            "emoji": {
                "image_url": "https://cdn2.iconfinder.com/data/icons/business-and-finance-related-hand-gestures/256/face_female_blank_user_avatar_mannequin-512.png",
                "caption": userName
            },
            "date": moment().format('YYYY-MM-DD'),
        }
    ]
}

