import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configurestore from './store'
import { Provider } from "react-redux";

if (process.env.NODE_ENV !== "development")
  console.log = console.warn = console.error = () => { };

ReactDOM.render(
  <Provider store={configurestore}>
    <App />
  </Provider>
  ,
  document.getElementById('root')
);

reportWebVitals();



