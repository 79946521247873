import React from "react";
import Lottie from "react-lottie";
import { useHistory } from "react-router-dom";
import "./style.scss";

const QualitativeItem = ({
  celebration,
  celebrationCup,
  cup,
  eventListeners,
  look,
  primary,
  id,
  is_Manger,
}) => {
  const history = useHistory();
  
  const handleOnClickEye = () => {
    if (is_Manger) {
      history.push(`/viewmanagergoal/${primary?.id}`);
    } else {
      look
        ? history.push({
            pathname: "/employeegoal",
            state: { id: id },
          })
        : history.push({
            pathname: "/empgoals",
            state: { goal_id: primary?.goal_id },
          });
    }
  };
  return (
    <div className="qualitative-primary-inline">
      {celebration === true ? (
        <div className="qantitative-celebration-inline">
          <Lottie
            options={celebrationCup}
            height={300}
            width={200}
            isStopped={false}
            isPaused={false}
          />
        </div>
      ) : (
        <div></div>
      )}

      <div           onClick={handleOnClickEye}
>
        <Lottie
          options={cup}
          height={400}
          width={200}
          isStopped={false}
          isPaused={false}
          eventListeners={eventListeners}
        />
      </div>
      <div className="quantative-div-inline">
        <i
          className="fa fa-eye   quatatitive-inline-i"
          aria-hidden="true"
          onClick={handleOnClickEye}
        ></i>
      </div>
      <div className="quantitative-math-inline">
        {primary?.team_goal.title} -{" "}
        { is_Manger ? (
          !isNaN(primary.quantitative_stats_percent) ?
          Math.trunc(primary.quantitative_stats_percent) : 0
        ) : (
          Math.trunc(((Number(primary?.complete_measure)/primary?.measure)*100).toFixed(2))
        )
        }%
      </div>
    </div>
  );
};

export default QualitativeItem;
