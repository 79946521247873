import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import camera from "../../assets/images/camera.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import profileImage from "../../assets/images/profile1.png";
import { useFormik } from "formik";
import { POSTIMAGE, GET, putAPI } from "../../utils/helper";
import { UPLOADFILEAPI } from "../../redux/saga/ApiConstant";
import { coachProfileValidation } from "../../utils/validation";
import { useHistory } from "react-router-dom";
import ProfilePicture from "../UserProfileAvatar/ProfilePicture";

const MyProfile = (props) => {
  const [disable, setDisable] = useState(false);
  const history = useHistory();

  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    image: profileData?.image || null,
    name: profileData?.name || "",
    email: profileData?.email || "",
    country_code: profileData?.country_code || "",
    phone_number: profileData?.phone_number || "",
    description: profileData?.description || "",
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const res = await GET(`getProfile`, 2);
        setLoading(false);
        setProfileData(res.body.data);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    getData();
  }, []);

  const onSubmit = async (values) => {
    const data = {
      image: values.image,
      name: values.name,
      email: values.email,
      country_code: values.country_code,
      phone_number: values.phone_number,
      description: values.description,
    };

    if (!data.image) {
      delete data.image;
    }

    try {
      setLoading(true);
      await putAPI(`editProfile`, data, 2);
      setLoading(false);
      history.push("/coachdashboard");

      toast.success("Profile Updated Successfully", {
        position: toast.POSITION.BOTTOM_CENTER,
        hideProgressBar: true,
      });
    } catch (error) {
      setLoading(false);
      toast.error("Something Went wrong", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const showFile = async (data, id="image") => {
    const bodyFormData = new FormData();

    if (data.length < 1) {
      return;
    }

    if (
      data[0].type !== "image/png" &&
      data[0].type !== "image/jpeg" &&
      data[0].type !== "image/svg+xml"
    ) {
      formik.setFieldError(id, "Only jpeg, png images are allowed");
      return;
    }
    bodyFormData.append("file", data[0]);
    bodyFormData.append("folderName", "employee_profile_pic");

    try {
      setDisable(true);
      const result = await POSTIMAGE(UPLOADFILEAPI, bodyFormData, 2);
      setDisable(false);

      formik.setFieldValue(id, result.body.data);
    } catch (error) {
      formik.setFieldError(id, error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema: coachProfileValidation,
  });
  return (
    <React.Fragment>
      <section className="My-Profile-wrapper bg-color">
        <div className="My-Profile">
          <h2>My Profile</h2>
         

          <ProfilePicture
            edit={true}
            picture={formik?.values?.image || profileImage}
            onChange={showFile} 
          />

          <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <label style={{ fontSize: "10px" }}>Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Your name"
                name="name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.errors.name ? (
                <div className="errmsg">{formik.errors.name}</div>
              ) : null}
            </div>
            <div className="form-group">
              <label style={{ fontSize: "10px" }}>email</label>
              <input
                type="text"
                className="form-control"
                placeholder="Email address"
                name="email"
                disabled
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.errors.email ? (
                <div className="errmsg">{formik.errors.email}</div>
              ) : null}
            </div>

            <div className="row">
              <div className="col-3">
                <div className="form-group">
                  <label style={{ fontSize: "10px" }}>Code.</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="+91"
                    maxLength="4"
                    name="country_code"
                    value={formik.values.country_code}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
               
                </div>
              </div>
              <div className="col-9">
                <div className="form-group">
                  <label style={{ fontSize: "10px" }}>Mobile No.</label>
                  <input
                    type="text"
                    maxLength="10"
                    className="form-control"
                    placeholder="Phone number"
                    name="phone_number"
                    value={formik.values.phone_number}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.phone_number && formik.errors.phone_number ? (
                    <div className="errmsg">
                      {formik.errors.phone_number}
                    </div>
                  ) : null}
                  {formik.touched.phone_number && formik.errors.country_code ? (
                    <div className="errmsg">
                      {formik.errors.country_code}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="form-group">
              <label style={{ fontSize: "10px" }}>Description</label>
        
              <textarea
                placeholder="Write here"
                className="form-control"
                rows="4"
                cols="50"
                name="description"
                value={formik.values.description}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              ></textarea>
              {formik.errors.description && formik.touched.description ? (
                <div className="errmsg">{formik.errors.description}</div>
              ) : null}
            </div>
            <div
              style={{ paddingBottom: "2rem", position: "relative" }}
              className="upadte-edit-btn"
            >
              {loading || disable ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <button
                  disabled={disable}
                  type="submit"
                  name="submit"
                  className="Blue-Tango-button"
                >
                  Save
                </button>
              )}
            </div>
          </form>
        </div>
      </section>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const profileData = state.profileReducer.profileData;
  const errorMsg = state.profileReducer.errorMsg;
  const imageError = state.profileReducer.imageError;
  const readProfileData = state.profileReducer.readProfileData;
  const loading = state.globalReducer.loading;
  const sucessMsg = state.profileReducer.sucessMsg;
  return {
    profileData,
    loading,
    errorMsg,
    imageError,
    readProfileData,
    sucessMsg,
  };
};

const mapDispatch = (dispatchEvent) => ({
  getProfileData: () => dispatchEvent({ type: "GET_PROFILEDATA" }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
  uploadProfile: (payload) =>
    dispatchEvent({ type: "UPLOAD_PROFILEPIC", value: payload }),
  updateProfileData: (payload) =>
    dispatchEvent({ type: "UPDATEPROFILEDATA", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(MyProfile);
