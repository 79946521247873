import React, { useEffect, useState } from "react";
import { GET, putAPI } from "../../utils/helper";
import { useHistory } from "react-router-dom";
import profileImage from "../../assets/images/profile1.png";
import firebase from "firebase";
import "../.././scss/customforall.scss";
export default function EmployerProfile() {
  const messaging = firebase.messaging.isSupported()
    ? firebase.messaging()
    : null;

  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const getFcmToken = async () => {
      const fcmToken = await messaging?.getToken();
      if (fcmToken) {
        localStorage.setItem("fcm_token", fcmToken);
        putAPI("updateEmployerDeviceToken", { device_token: fcmToken }, 2)
          .then((res) => {})
          .catch((error) => {
            console.log("fcmtoken api error------->", error);
          });
      } else {
        console.log("Failed", "No token received");
      }
    };

    const getData = async () => {
      try {
        setLoading(true);
        const res = await GET(`getProfile`, 2);
        setLoading(false);
        setProfileData(res.body.data);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    getData();
    getFcmToken();
  }, [messaging]);

  return (
    <div>
      <section
        style={{ minHeight: "80vh" }}
        className="My-Profile-wrapper bg-color"
      >
        <div className="My-Profile" style={{ width: "30%" }}>
          <h2>My Profile</h2>
          <hr style={{ width: "10rem" }} className="border-pink" />

          <div className="profile-img pb-2 mt-5">
            <div
              className="profile  profile-edit"
            >
              <img
                alt=""
                className="img-pro"
                src={profileData?.image || profileImage}
                style={{ borderRadius: "50%" }}
              />
              <p
                onClick={() => history.push("/editcoachprofile")}
                className="profile-edit-button"
             
              >
                Edit
              </p>
            </div>
          </div>
          {!loading ? (
            <div className="form-information" style={{ with: "273px" }}>
              <div className="information  title-wrap">
                <span> Name</span>
                <p className="textwrap">{profileData?.name}</p>
              </div>
              <div className="information">
                <span>Email address</span>
                <p className="textwrap">{profileData?.email}</p>
              </div>
              <div className="information">
                <span>Phone number</span>
                <p className="textwrap">{`${profileData?.country_code} ${profileData?.phone_number}`}</p>
              </div>
              <div className="information  title-wrap">
                <span> Description</span>
                <p className="textwrap">{profileData?.description}</p>
              </div>
              <div className="information  title-wrap">
                <span>Conversion Rate</span>
                {profileData?.conversionRate ? (
                  <p className="textwrap">{profileData.conversionRate.toFixed(2)}</p>
                ) : (
                  <p></p>
                )}
              </div>
              {profileData?.coach_specialization_categories?.length > 0 && (
                <div className="information title-wrap">
                  <span>Coach Specialization </span>
                  <p className="textwrap">
                    {profileData.coach_specialization_categories.map(
                      (item, index) => (
                        <>
                          {index > 0 && ","} {item.name}
                        </>
                      )
                    )}
                  </p>
                </div>
              )}
              {profileData?.employee_ranks?.length > 0 && (
                <div
                
                  className="information title-wrap"
                >
                  <span>Team Level</span>
                  <p className="textwrap">
                    {" "}
                    {profileData.employee_ranks.map((item, index) => (
                      <>
                        {index > 0 && ","} {item.name}
                      </>
                    ))}
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div
              style={{ marginTop: "15rem" }}
              className="spinner-border text-primary"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
