import React, { useEffect, useState } from "react";
import { GET } from "../utils/helper";
import ShowMoreText from "react-show-more-text";

export default function Library() {
  const [videos, setvideos] = useState([]);
  const [loader, setLoader] = useState(false);

  const getLibraryData = async () => {
    try {
      setLoader(true);
      const data = await GET(`listVideo`);
      setLoader(false);
      if (data?.body?.data?.rows) {
        setvideos(data.body.data.rows);
      }

      
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getLibraryData();
  }, []);

  return (
    <section class="Employee-library-wrapper bg-color">
      <div>
        <div class="Employee-library comman-width-container">
          <div class="Employee-video">
            <div class="row">
              {!loader ? (
                videos.map((video) => {
                  return (
                    <div
                      style={{ marginBottom: "2rem", marginRight: "4rem" }}
                      class="col-md-5 col-sm-5"
                    >
                      <div class="video-details">
                        <div class="video-head">
                          <h5>{video.title}</h5>
                        </div>
                        <div class="video-details">
                          <h5>Description</h5>
                          <ShowMoreText
                            lines={1}
                            more="Show more"
                            less="Show less"
                            className="content-css"
                            anchorClass="my-anchor-css-class"
                            expanded={false}
                          >
                            <p style={{ color: "#6A6A6A" }}>
                              {video.description}
                            </p>
                          </ShowMoreText>
                        </div>
                        <div class="video">
                          <video
                            class="img-fluid"
                            style={{
                              minWidth: "40vw",
                              maxHeight: "40vh",
                              minHeight: "40vh",
                              objectFit: "fill",
                            }}
                            controls
                          >
                            {video && (
                              <source src={video.video} type="video/mp4" />
                            )}
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
