import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from '../src/redux/reducer/index'
import rootSaga from '../src/redux/saga/index'
import createSagaMiddleware from 'redux-saga'

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);

        localStorage.setItem('state', serializedState);
    } catch (err) {
        console.log(err)
    }
};

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

const persistedState = loadState();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();
const configureStore = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(sagaMiddleware)))
configureStore.subscribe(() => saveState(configureStore.getState()))

sagaMiddleware.run(rootSaga);
export default configureStore

