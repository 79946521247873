import React from 'react'
import Select from "react-select";


const MultiSelect = ({name,onChange,value,options,disabled}) => {
    return (
        <Select

            isMulti
            isDisabled={disabled}
            name={name}
            onChange={onChange}
            value={value}
            placeholder={disabled?'':"Select ..."}
            options={options}
            styles={{
                menu: (provided, state) => ({
                    ...provided,
                    zIndex: 9999,
                }),
                control: (provided, state) => ({
                    ...provided,
                    borderRadius: '14px',
                    backgroundColor: disabled ? 'rgb(233 236 239)' : '#fff',
                    borderColor: disabled ?"rgba(0,0,0,0.1)" : "transparent",
                    boxShadow: disabled ? "0px 0px 15px rgba(74, 74, 74, 0.1)" : "none"
                }),
                multiValueRemove: (base) => ({ ...base, display: disabled && 'none' }),
                dropdownIndicator:(provided)=>({
                    ...provided,
                    color:disabled ? 'transparent' :"black"
                }),
                indicatorSeparator: (provided, state) => ({
                    ...provided,
                    backgroundColor: 'transparent',
                  }),
            }}
            theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: "#fff",
                    primary: "#fff",
                },
            })}
        />
    )
}

MultiSelect.defaultProps = {
    disabled:false
}

export default MultiSelect