import * as actions from "../type.action";
const initialState = {
  teamList: [],
  EmployerteamList: [],
  totalTeamCount: 0,
  errorMsg: "",
  teamDetailData: {},
  teamEnergy: [],
  emplyrTeamDetailData: {},
  emplyrDepartmentData: [],
  emplyrManagerData: [],
};

const teamReducer = (state, action) => {
  if (state === undefined) {
    return (state = initialState);
  }
  switch (action.type) {
    case actions.TEAM_DATASUCCESS:
      return {
        ...state,
        teamList: action.data.rows,
        totalTeamCount: action.data.count,
      };
    case actions.EMPLOYERTEAM_DATASUCCESS:
      return {
        ...state,
        EmployerteamList: action.data.rows,
      
      };
    case actions.TEAM_DATAFAIL:
      return {
        ...state,
        errorMsg: action.data,
      };
    case actions.TEAMDETAIL_DATASUCCESS:
      return {
        ...state,
        teamDetailData: action.data,
      };
    case actions.EMPLYRTEAMDETAIL_DATASUCCESS:
      return {
        ...state,
        emplyrTeamDetailData: action.data,
      };
    case actions.EMPLYRDEPARTMENT_DATASUCCESS:
      return {
        ...state,
        emplyrDepartmentData: action.data,
      };
    case actions.EMPLYRMANAGER_DATASUCCESS:
      return {
        ...state,
        emplyrManagerData: action.data,
      };
    case actions.TEAMDETAIL_DATAFAIL:
      return {
        ...state,
        errorMsg: action.data,
      };
    case actions.SEARCHTEAM_DATA:
      return {
        ...state,
        teamList: action.data,
      };

    case actions.TEAMENERGY_DATASUCCESS:
      return {
        ...state,
        teamEnergy: action.data,
      };
    default:
      return state;
  }
};
export default teamReducer;
