import React, { useEffect, useState } from "react";
import useFAQ from "./helper";
import FAQModal from "./FAQModal";
import FAQDelete from "./FAQDelete";
import EditMode from "./EditMode";
import ViewMode from "./ViewMode";

const FAQ = () => {
  const { emailsToCheck, email, getAllFaq, BASE_URL } = useFAQ();

  const [Data, setData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [Open, setOpen] = useState(0);
  const [FAQForm, setFAQForm] = useState(false);
  const [Delete, setDelete] = useState({ status: false, id: "" });
  const [EditToggle, setEditToggle] = useState(false);
  const [Edit, setEdit] = useState({ status: false, data: {} });
  const [RowData, setRowData] = useState([]);

  useEffect(() => {
    getAllFaq(setData);
    setLoading(false);
  }, []);

  useEffect(() => {
    setRowData(Data);
  }, [Data]);

  return (
    <>
      <div className="contact-wrapper container">
      <div class="contact-header flex-wrap">
          <h3>FAQs</h3>

          <div className="d-flex align-items-center gap-3">
            {emailsToCheck.includes(email) && (
              <>
                {!EditToggle ? (
                  <button
                    onClick={() => {
                      setEditToggle(!EditToggle);
                    }}
                  >
                    Edit Mode
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        setEditToggle(!EditToggle);
                      }}
                    >
                      View Mode
                    </button>
                    <button
                      onClick={() => {
                        setFAQForm(!FAQForm);
                      }}
                    >
                      + Add FAQ
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        {EditToggle ? (
          <EditMode
            BASE_URL={BASE_URL}
            setFAQForm={setFAQForm}
            setEdit={setEdit}
            setDelete={setDelete}
            Loading={Loading}
            RowData={RowData}
            setRowData={setRowData}
            Data={Data}
          />
        ) : (
          <ViewMode
            Loading={Loading}
            Open={Open}
            RowData={RowData}
            setOpen={setOpen}
          />
        )}
      </div>
      <FAQModal
        BASE_URL={BASE_URL}
        setFAQForm={setFAQForm}
        Edit={Edit}
        FAQForm={FAQForm}
        setEdit={setEdit}
        setDataa={setData}
        getAllFaq={getAllFaq}
      />
      <FAQDelete
        BASE_URL={BASE_URL}
        Delete={Delete}
        toggle={setDelete}
        getAllFaq={getAllFaq}
        setData={setData}
      />
    </>
  );
};

export default FAQ;
