import { call, put, apply } from 'redux-saga/effects'
import * as Action from '../type.action'
import { POST, RESETPOST, clearLocalStorage } from '../../utils/helper'
import setAuthenticationToken from "../action/setAuthenticationToken";
import jwt from "jsonwebtoken";
import { LOGIN_API, FORGOT_PASWRDAPI, EMPLOYE_RESETPASWRD, CHANGE_PASWORD, EMPLOYER_RESETPASWRD } from "./ApiConstant";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


function privacyPopup(name) {
  toast.success(`Welcome ${name.split(' ')[0]}!`,
    {
      position: toast.POSITION.TOP_CENTER,
    })
}

export function* fetchLoginToken(action) {
  const { loginData, history } = action.value
  try {
    if (loginData.status === 200) {
      window.location.search = '';
      localStorage.clear();
      let finalData = loginData.body.data;
      // Check for Coach
      if (finalData?.coach_specialization_categories) {
        const { token, first_time_reset_password, id, name } = finalData
        yield put({ type: Action.COACHLOGIN_DATASUCCESS, data: finalData, msg: 'Successfully Login' })

        localStorage.setItem('s_token', token)
        localStorage.setItem('first_time_reset_password', first_time_reset_password)
        
        localStorage.setItem('id', id)
        localStorage.setItem('name', name);

        yield put({
          type: 'SET_CURRENT_USER',
          payload: jwt.decode(token)
        })
        window.location.pathname = "/"
      } 
      // Check for Employer
      else if (finalData?.free_trial_end_datetime) {
        const { token, first_time_login, first_time_reset_password, id, name, free_trial_status, subscription_type } = finalData
        yield put({ type: Action.EMPLOYERLOGIN_DATASUCCESS, data: finalData, msg: 'Successfully Login' })
  
        localStorage.setItem('free_trial_status', free_trial_status)
        localStorage.setItem('subscription_type', subscription_type)
        localStorage.setItem('s_token', token)
        localStorage.setItem('first_time_reset_password', first_time_reset_password)
        localStorage.setItem('id', id)
        localStorage.setItem('name', name)

        yield put({
          type: 'SET_CURRENT_USER',
          payload: jwt.decode(token)
        })
        window.location.pathname = "/"
      } 
      // Check for Employee
      else {
        const { token, first_time_login , first_time_reset_password, is_manager, manager_team_member, id, name, profile_pic_url } = finalData
        yield put({ type: Action.LOGIN_DATASUCCESS, data: finalData, msg: 'Successfully Login' })
        localStorage.setItem('s_token', token)
        localStorage.setItem('first_time_reset_password', first_time_reset_password)
        localStorage.setItem('is_manager', is_manager)
        localStorage.setItem('state', JSON.stringify({ showLook: false }))
        localStorage.setItem('manager_team_member_id', manager_team_member?.employee?.id)
        localStorage.setItem('id', id)
        localStorage.setItem('name', name)
        localStorage.setItem('profile_pic_url', profile_pic_url);

        yield put({
          type: 'SET_CURRENT_USER',
          payload: jwt.decode(token)
        })
        window.location.pathname = "/dashboard"
      }
      
      
    } else {
      yield put({ type: Action.LOGIN_DATAFAIL, data: loginData.message })
      yield put({ type: Action.LOADING_HIDE })
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      yield put({ type: Action.LOGIN_DATAFAIL, data: error.response.data.message })

      console.log("error.response", error.response)
    }
    yield put({ type: Action.LOADING_HIDE })
  }
}

export function* fetchLogin(action) {
  yield put({ type: Action.LOADING_SHOW })

  try {
    const { postObj, history } = action.value
    const loginData = yield call(POST, LOGIN_API, postObj)

    if (loginData.status === 200) {
      const { token, first_time_login, first_time_reset_password, is_manager, manager_team_member, id, name, profile_pic_url } = loginData.data.body.data
      yield put({ type: Action.LOGIN_DATASUCCESS, data: loginData.data.body.data, msg: 'Successfully Login' })

      localStorage.setItem('s_token', token)
      localStorage.setItem('first_time_reset_password', first_time_reset_password)
      localStorage.setItem('is_manager', is_manager)
      localStorage.setItem('state', JSON.stringify({ showLook: false }))
      localStorage.setItem('manager_team_member_id', manager_team_member?.employee?.id)
      localStorage.setItem('id', id)
      localStorage.setItem('name', name)
      localStorage.setItem('profile_pic_url', profile_pic_url)


      setAuthenticationToken(token);
      yield put({
        type: 'SET_CURRENT_USER',
        payload: jwt.decode(token)
      })
      yield put({ type: Action.LOADING_HIDE })
      if (first_time_login === 0) {
        yield apply(history, history.push, [{ pathname: '/dashboard', state: { showWelcome: true } }])
        privacyPopup(name)
      }
      else {
        yield apply(history, history.push, ['/resetpasswordone'])

      }

    } else {
      yield put({ type: Action.LOGIN_DATAFAIL, data: loginData.message })
      yield put({ type: Action.LOADING_HIDE })
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      yield put({ type: Action.LOGIN_DATAFAIL, data: error.response.data.message })

      console.log("error.response", error.response)
    }
    yield put({ type: Action.LOADING_HIDE })
  }
}

export function* fetchCoachLogin(action) {
  yield put({ type: Action.LOADING_SHOW })

  try {
    const { postObj, history } = action.value
    const loginData = yield call(POST, LOGIN_API, postObj, 2)

    if (loginData.status === 200) {

      const { token, first_time_login, first_time_reset_password, id, name } = loginData.data.body.data
      yield put({ type: Action.COACHLOGIN_DATASUCCESS, data: loginData.data.body.data, msg: 'Successfully Login' })

      localStorage.setItem('s_token', token)
      localStorage.setItem('first_time_reset_password', first_time_reset_password)
    
      localStorage.setItem('id', id)
      localStorage.setItem('name', name)

      setAuthenticationToken(token);
      yield put({
        type: 'SET_CURRENT_USER',
        payload: jwt.decode(token)
      })
      yield put({ type: Action.LOADING_HIDE })
      if (first_time_login === 0) {

        yield apply(history, history.push, [{ pathname: '/coachdashboard', state: { showWelcome: true } }])
       

        privacyPopup(name)

      }
      else {
        yield apply(history, history.push, ['/coachresetpassword'])

      }

    } else {
      yield put({ type: Action.LOGIN_DATAFAIL, data: loginData.message })
      yield put({ type: Action.LOADING_HIDE })
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      yield put({ type: Action.LOGIN_DATAFAIL, data: error.response.data.message })

      console.log("error.response", error.response)
    }
    yield put({ type: Action.LOADING_HIDE })
  }
}

export function* fetchEmployerLogin(action) {
  yield put({ type: Action.LOADING_SHOW })

  try {
    const { postObj, history } = action.value
    const loginData = yield call(POST, LOGIN_API, postObj, 1)
    if (loginData.status === 200) {
      const { token, first_time_login, first_time_reset_password, id, name, free_trial_status, subscription_type } = loginData.data.body.data
      yield put({ type: Action.EMPLOYERLOGIN_DATASUCCESS, data: loginData.data.body.data, msg: 'Successfully Login' })

      localStorage.setItem('free_trial_status', free_trial_status)
      localStorage.setItem('subscription_type', subscription_type)
      localStorage.setItem('s_token', token)
      localStorage.setItem('first_time_reset_password', first_time_reset_password)
      localStorage.setItem('id', id)
      localStorage.setItem('name', name)

      setAuthenticationToken(token);
      yield put({
        type: 'SET_CURRENT_USER',
        payload: jwt.decode(token)
      })
      yield put({ type: Action.LOADING_HIDE })

      if (first_time_login !== 0) {

        yield apply(history, history.push, [{ pathname: '/employerresetpassword', state: { showWelcome: false } }])
        return;

      }

      if (free_trial_status === 0) {

        yield apply(history, history.push, [{ pathname: '/startfreetrial', state: { showWelcome: false } }])
        privacyPopup(name)
        return;

      }

      if (subscription_type === 2) {

        yield apply(history, history.push, [{ pathname: '/noplan', state: { showWelcome: true } }])
        privacyPopup(name)
        return;

      }



      yield apply(history, history.push, ['/employerdashboard'])



    } else {
      yield put({ type: Action.LOGIN_DATAFAIL, data: loginData.message })
      yield put({ type: Action.LOADING_HIDE })
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      yield put({ type: Action.LOGIN_DATAFAIL, data: error.response.data.message })

      console.log("error.response", error.response)
    }
    yield put({ type: Action.LOADING_HIDE })
  }
}
export function* SendResetEmail(action) {
  yield put({ type: Action.LOADING_SHOW })
  const data = action.value
  try {
    const Data = yield call(POST, FORGOT_PASWRDAPI, data)
 
    if (Data.status === 200) {
      yield put({ type: Action.FORGOTPASRD_DATASUCCESS, msg: 'We have sent you a password reset link on your email address' })
      yield put({ type: Action.LOADING_HIDE })
    } else {

      yield put({ type: Action.FORGOTPASRD_DATAFAIL, msg: Data.message })
      yield put({ type: Action.LOADING_HIDE })
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      yield put({ type: Action.FORGOTPASRD_DATAFAIL, msg: error.response.data.message })

      console.log("error.response", error.response)
    } else {
      yield put({ type: Action.FORGOTPASRD_DATAFAIL, msg: 'something went wrong' })

    }
    yield put({ type: Action.LOADING_HIDE })
  }
}
export function* SendEmployerResetEmail(action) {
  yield put({ type: Action.LOADING_SHOW })
  const data = action.value
  try {
    const Data = yield call(POST, FORGOT_PASWRDAPI, data, 1)
 
    if (Data.status === 200) {
      yield put({ type: Action.FORGOTPASRD_DATASUCCESS, msg: 'We have sent you a password reset link on your email address' })
      yield put({ type: Action.LOADING_HIDE })
    } else {

      yield put({ type: Action.FORGOTPASRD_DATAFAIL, msg: Data.message })
      yield put({ type: Action.LOADING_HIDE })
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      yield put({ type: Action.FORGOTPASRD_DATAFAIL, msg: error.response.data.message })

      console.log("error.response", error.response)
    } else {
      yield put({ type: Action.FORGOTPASRD_DATAFAIL, msg: 'something went wrong' })

    }
    yield put({ type: Action.LOADING_HIDE })
  }
}
export function* setForgtPswrd(action) {
  yield put({ type: Action.LOADING_SHOW })
  try {
    const { postObj, history } = action.value
  
    const Data = yield call(RESETPOST, EMPLOYE_RESETPASWRD, postObj)
   
    if (Data.status === 200) {
      const { first_time_reset_password } = Data.body.data
      localStorage.setItem('first_time_reset_password', first_time_reset_password)
      yield put({ type: Action.SETFORGOTPASRD_DATASUCCESS, data: Data.body.data, msg: '' })
      yield put({ type: Action.LOADING_HIDE })
      yield apply(history, history.push, ['/dashboard'])
    } else {

      yield put({ type: Action.SETFORGOTPASRD_DATAFAIL, msg: Data.message })
      yield put({ type: Action.LOADING_HIDE })
     
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      yield put({ type: Action.SETFORGOTPASRD_DATAFAIL, msg: error.response.data.message })

      console.log("error.response", error.response)
    } else {
      yield put({ type: Action.SETFORGOTPASRD_DATAFAIL, msg: 'something went wrong' })

    }
    yield put({ type: Action.LOADING_HIDE })
  }
}

export function* setCoachForgtPswrd(action) {
  yield put({ type: Action.LOADING_SHOW })
  try {
    const { postObj, history } = action.value
  
    const Data = yield call(RESETPOST, EMPLOYER_RESETPASWRD, postObj, 1)
  
    if (Data.status === 200) {
      const { first_time_reset_password } = Data.body.data
      localStorage.setItem('first_time_reset_password', first_time_reset_password)
      yield put({ type: Action.SETEMPOLYERFORGOTPASRD_DATASUCCESS, data: Data.body.data, msg: 'Password Updated Successfully ' })
      yield put({ type: Action.LOADING_HIDE })
      yield apply(history, history.push, ['/employerdashboard'])

    } else {
      yield put({ type: Action.SETFORGOTPASRD_DATAFAIL, msg: Data.message })
      yield put({ type: Action.LOADING_HIDE })
     
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      yield put({ type: Action.SETFORGOTPASRD_DATAFAIL, msg: error.response.data.message })

      console.log("error.response", error.response)
    } else {
      yield put({ type: Action.SETFORGOTPASRD_DATAFAIL, msg: 'something went wrong' })

    }
    yield put({ type: Action.LOADING_HIDE })
  }
}

export function* setEmployerForgtPswrd(action) {
  yield put({ type: Action.LOADING_SHOW })
  try {
    const { postObj, history } = action.value
    const Data = yield call(RESETPOST, EMPLOYER_RESETPASWRD, postObj, 1)
   
    if (Data.status === 200) {
      const { first_time_reset_password, free_trial_status, subscription_type } = Data.body.data
      localStorage.setItem('first_time_reset_password', first_time_reset_password)
      yield put({ type: Action.SETEMPOLYERFORGOTPASRD_DATASUCCESS, data: Data.body.data, msg: 'Password Updated Successfully ' })
      yield put({ type: Action.LOADING_HIDE })
    
      if (free_trial_status === 0) {

        yield apply(history, history.push, [{ pathname: '/startfreetrial', state: { showWelcome: false } }])
        return;

      }

      if (subscription_type === 2) {

        yield apply(history, history.push, [{ pathname: '/noplan', state: { showWelcome: true } }])
        return;

      }


      yield apply(history, history.push, ['/employerdashboard'])

    } else {
      yield put({ type: Action.SETFORGOTPASRD_DATAFAIL, msg: Data.message })
      yield put({ type: Action.LOADING_HIDE })
     
    }
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      yield put({ type: Action.SETFORGOTPASRD_DATAFAIL, msg: error.response.data.message })

      console.log("error.response", error.response)
    } else {
      yield put({ type: Action.SETFORGOTPASRD_DATAFAIL, msg: 'something went wrong' })

    }
    yield put({ type: Action.LOADING_HIDE })
  }
}

export function* onLogout(action) {
  yield put({ type: Action.SET_LOGOUT, msg: 'Succesfully Logout' })
  const history = action.history
  clearLocalStorage()
  setAuthenticationToken(false);
  yield put({
    type: 'SET_CURRENT_USER',
    payload: {}
  })
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.removeEventListener();
  }


  yield apply(history, history.push, ['/'])

}

export function* changePswrd(action) {
  yield put({ type: Action.LOADING_SHOW })
  try {
    const postObj = action.value
    const Data = yield call(RESETPOST, CHANGE_PASWORD, postObj)

    if (Data.status === 200) {
      yield put({ type: Action.CHANGEPASWRD_DATASUCCESS, data: Data.user, msg: 'Password Updated Successfully ' })
      yield put({ type: Action.LOADING_HIDE })
    } else {
      yield put({ type: Action.CHANGEPASWRD_DATAFAIL, msg: Data.message })
      yield put({ type: Action.LOADING_HIDE })
    }
  } catch (error) {
    yield put({ type: Action.CHANGEPASWRD_DATAFAIL, msg: 'something went wrong' })
    yield put({ type: Action.LOADING_HIDE })
  }
}