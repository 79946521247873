import React from "react";
import { CCard } from "@coreui/react";
import moment from "moment";

import "./style.scss";

function ReplyItem({ reply }) {
  const isAdmin = reply?.commentable_type === "admin";
  return (
    <CCard className={`reply ${!isAdmin ? "current" : "other"}`}>
      <div className="reply-header">
        <span className="name">
          {reply?.commentable?.name}
          <span className="user-type">{isAdmin ? "(Admin)" : ""}</span>
        </span>
        <span className="">
          {moment(reply?.createdAt).format("MMM Do YY, h:mm a")}
        </span>
      </div>
      <hr />
      <br />
      <span
        className="content"
        dangerouslySetInnerHTML={{ __html: reply?.content }}
      />
    </CCard>
  );
}

export default ReplyItem;
