import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import SelectType from "./SelectType";
import UploadAvatar from "./UploadAvatar";
import Svgs from "../../../assets/Svgs";

const PictureModal = ({ onChange, Open, Close }) => {
  const [Step, setStep] = useState("SelectStep");

  const Data = {
    SelectStep: {
      title: "Choose or Upload Avatar",
      UI: <SelectType setStep={setStep} onChange={onChange} Close={Close} />,
    },
    UploadAvatar: {
      title: "Customize Your Avatar",
      UI: <UploadAvatar setStep={setStep} onChange={onChange} Close={Close} />,
    },
  };

  return (
    <>
      <Modal className="custom-width faq" isOpen={Open} centered size={"lg"}>
        <ModalBody className="position-relative">
          <div className="w-100">
            <div
              className="modal-close-icon bg-danger"
              onClick={() => Close(!Open)}
            >
              <Svgs.Close />
            </div>

            <div className="border-bottom pb-2 mb-3">
              <h3>{Data[Step].title}</h3>
            </div>

            <div>{Data[Step].UI}</div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PictureModal;
