import { call, put} from 'redux-saga/effects'
import * as Action from '../type.action'
import { GET, RESETPOST } from '../../utils/helper'
import { CHAT_POPUPLIST, CHAT_LIST, CHAT_NOTIFICATION } from "./ApiConstant";
export function* getChatPopup(action) {
  yield put({ type: Action.LOADING_SHOW })
  try {
    const Data = yield call(GET, CHAT_POPUPLIST)
    if (Data.status === 200) {
      yield put({ type: Action.CHATPOPUP_DATASUCCESS, data: Data.body.data, msg: 'Success!!' })
      yield put({ type: Action.LOADING_HIDE })
    } else {
      yield put({ type: Action.TEAM_DATAFAIL, data: Data.message })
      yield put({ type: Action.LOADING_HIDE })
    }
  } catch (error) {
    yield put({ type: Action.TEAM_DATAFAIL, data: 'Something went wrong' })
    yield put({ type: Action.LOADING_HIDE })
  }
}

export function* getChatRoom(action) {
  yield put({ type: Action.LOADING_SHOW })

  let url;
  if (action.value.type === 2) {
    url = `getChatRoomId?other_user_id=${action.value.id}&type=2`
  } else {
    url = `getChatRoomId?other_user_id=${action.value.id}`

  }
  try {
    const Data = yield call(GET, url)
    if (Data.status === 200) {
      yield put({ type: Action.CHATROOM_DATASUCCESS, data: Data.body.data, msg: 'Success!!' })
      yield put({ type: Action.LOADING_HIDE })
    } else {
      yield put({ type: Action.CHATROOM_DATAFAIL, data: Data.message })
      yield put({ type: Action.LOADING_HIDE })
    }
  } catch (error) {
    yield put({ type: Action.CHATROOM_DATAFAIL, data: 'Something went wrong' })
    yield put({ type: Action.LOADING_HIDE })
  }
}

export function* getChatUser(action) {
  yield put({ type: Action.LOADING_SHOW })
  try {
    const Data = yield call(GET, CHAT_LIST)
    if (Data.status === 200) {
      yield put({ type: Action.CHATUSER_DATASUCCESS, data: Data.body.data, msg: 'Success!!' })
      yield put({ type: Action.LOADING_HIDE })
    } else {
      yield put({ type: Action.CHATUSER_DATAFAIL, data: Data.message })
      yield put({ type: Action.LOADING_HIDE })
    }
  } catch (error) {
    yield put({ type: Action.CHATUSER_DATAFAIL, data: 'Something went wrong' })
    yield put({ type: Action.LOADING_HIDE })
  }
}

export function* sendChatNotif(action) {
  const postObj = action.value
  try {
   yield call(RESETPOST, CHAT_NOTIFICATION, postObj)

  } catch (error) {
    yield put({ type: Action.PROFILE_DATAFAIL, msg: 'something went wrong!!' })
    yield put({ type: Action.LOADING_HIDE })
  
  }
}