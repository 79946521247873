import React, { useState } from "react";
import { BsFillClockFill } from "react-icons/bs";
import { FaRegEye } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { MdEdit } from "react-icons/md";
import ReactStars from "react-rating-stars-component";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { deleteAPI, POST, PENDING_RATE } from "../../../../utils/helper";
import "./style.scss";

const AssignedEmployee = ({
  employeeAttribute,
  attributeName,
  attributeId,
}) => {
  const history = useHistory();
  const [deleteModal, setDeleteModal] = useState(false);
  const [pendingModal, setPendingModal] = useState(false);

  const handlePendingRate = async (isApproved) => {
    try {
      const result = await POST(`approveRating`, {
        rating_id: employeeAttribute?.id,
        is_approved: isApproved,
      });
      if (result.status === 200) {
        toast.success(`${isApproved ? "Accepted" : "Rejected"} successfully`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
        });
      } else {
        toast.error(result.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
        });
      }
      setPendingModal(false);
      window.location.reload();
    } catch (error) {
      setPendingModal(false);
    }
  };

  const handleRestEmployeeRate = async () => {
    try {
      const result = await deleteAPI(`rating/${employeeAttribute?.id}`);
      if (result.status === 200) {
        window.location.reload();
        toast.success("Rested successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
        });
      } else {
        toast.error(result.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
        });
      }
    } catch (error) {
      // console.log(error.response);
    }
  };

  const handleViewProfile = () => {
    // history.push({
    //   pathname: `/teamemployeedetail/${employeeAttribute?.employee_id}`,
    // state: { item },
    // })
    // history.push({
    //   pathname: "/qualitative-rating",
    //   state: {
    //     qualitative_id: attributeId,
    //     employeeAttribute: {
    //       employeeName: employeeAttribute?.employee?.name,
    //       name: attributeName,
    //       rating: employeeAttribute?.rating,
    //       comment: employeeAttribute?.comment,
    //     },
    //   },
    // });
  };

  return (
    <>
      <div className="assigned-employee-item" onClick={handleViewProfile}>
        <span className="employee-name">
          {employeeAttribute?.employee?.name}
        </span>

        <div className="employee-rating">
          <ReactStars
            edit={false}
            count={5}
            size={24}
            value={employeeAttribute?.rating || 0}
            activeColor="#ff7500"
          />
        </div>

        <div className="attribute-item-actions">
          {employeeAttribute?.state == PENDING_RATE ? (
            <BsFillClockFill
              className="action-item"
              onClick={(e) => {
                e.stopPropagation();
                setPendingModal(true);
              }}
            />
          ) : (
            <div className="action-item hidden"></div>
          )}

          <MdEdit
            className="action-item"
            onClick={(e) => {
              e.stopPropagation();
              history.push({
                pathname: "/rating",
                state: { id: employeeAttribute?.employee_id },
              });
            }}
          />
          <RiDeleteBin6Fill
            className="action-item delete"
            onClick={(e) => {
              e.stopPropagation();
              setDeleteModal(true);
            }}
          />
        </div>
      </div>

      <CModal
        show={pendingModal}
        centered={true}
        backdrop={true}
        color="warning"
        onClose={() => {
          setPendingModal(false);
        }}
      >
        <CModalHeader>
          <CModalTitle>Do you want to accept this Rating?</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>
            <ReactStars
              edit={false}
              count={5}
              size={24}
              value={employeeAttribute?.employee_rating || 0}
              activeColor="#ff7500"
            />
            <br />
            <p className="center-comment">
              {employeeAttribute?.employee_comment || "---"}
            </p>
          </div>
        </CModalBody>
        <CModalFooter style={{ display: "flex", flexDirection: "row" }}>
          <CButton
            style={{
              width: "6rem",
              height: "3rem",
              backgroundColor: "#4B9CEB",
              color: "white",
            }}
            onClick={() => handlePendingRate(true)}
          >
            Accept
          </CButton>
          <CButton
            style={{
              width: "6rem",
              height: "3rem",
              backgroundColor: "#F56A14",
              color: "white",
            }}
            onClick={() => handlePendingRate(false)}
          >
            Reject
          </CButton>
        </CModalFooter>
      </CModal>

      <CModal
        show={deleteModal}
        centered={true}
        backdrop={true}
        color="warning"
        onClose={setDeleteModal}
      >
        <CModalHeader>
          <CModalTitle>Delete Employee Rating?</CModalTitle>
        </CModalHeader>
        <CModalBody>Are you sure you want to rest employee Rate?</CModalBody>
        <CModalFooter style={{ display: "flex", flexDirection: "row" }}>
          <CButton
            style={{
              width: "6rem",
              height: "3rem",
              backgroundColor: "#F56A14",
              color: "white",
            }}
            onClick={handleRestEmployeeRate}
          >
            Yes
          </CButton>
          <CButton
            style={{
              width: "6rem",
              height: "3rem",
              backgroundColor: "#4B9CEB",
              color: "white",
            }}
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default AssignedEmployee;
