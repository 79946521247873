import { CInput } from "@coreui/react";
import React from "react";

import "./style.scss";

function AssignMeasureItem({ assignMeasure, formik, goalIndex, measureIndex }) {
  return (
    <div className="assign-measure-item">
      <p>
        {formik.values.goals[goalIndex].employees_measures[measureIndex].label}
      </p>
      <div className="measure-form-group">
        <CInput
          className="measure-input"
          type="number"
          id={`goals[${goalIndex}].employees_measures[${measureIndex}].measure`}
          name={`goals[${goalIndex}].employees_measures[${measureIndex}].measure`}
          placeholder="Enter measure"
          value={
            formik.values.goals[goalIndex].employees_measures[measureIndex]
              .measure
          }
          onChange={formik.handleChange}
        />
        {formik.touched?.goals?.[goalIndex]?.employees_measures?.[measureIndex]
          ?.measure &&
        formik.touched?.goals?.[goalIndex]?.employees_measures?.[measureIndex]
          ?.measure ? (
          <div className="errmsg">
            {
              formik.touched?.goals?.[goalIndex]?.employees_measures?.[
                measureIndex
              ].measure
            }
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default AssignMeasureItem;
