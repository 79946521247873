import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  CButton,
  CForm,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import arraySort from "array-sort";
import { isArray } from "lodash";

import ReplyItem from "./components/ReplyItem";
import { useTicket, useTicketReplay } from "../../hooks/ticket.hook";
import {
  ACTIVE_TICKET_STATUS,
  RESOLVED_TICKET_STATUS,
  putAPI,
} from "../../utils/helper";
import { replyTicketValidation } from "../../utils/validation";
import "./style.scss";
import RichText from "../../Components/RichText"; 
import "../../styles/ticket-modal-dialog.scss";
import { useDispatch } from "react-redux";

function Ticket() {
  const params = useParams();
  const ticketQuery = useTicket(params.id);
  const ticketMutation = useTicketReplay(params.id);
  const data = ticketQuery?.data?.data?.body?.data || null;
  const [isReplyModalOpen, setReplyModalOpen] = useState(false);
  const dispatch = useDispatch();
  const toggleReplyModal = () => {
    setReplyModalOpen(!isReplyModalOpen);
  };

  const replies = [
    {
      id: "1",
      content: data?.content || null,
      commentable: {
        id: data?.ticketable?.id || null,
        name: data?.ticketable?.name || null,
      },
      createdAt: data?.createdAt,
    },
    ...(data?.comments || []),
  ];

  useEffect(() => {
    if(data){ 
      putAPI(`markNotificationsAsViewed`,{type: "ticket_reply",id: data?.id }).then(res=>{
        dispatch({ type: "GET_NOTIFICATION_COUNT" })
      })
    }
  }, [data])

  const formik = useFormik({
    initialValues: {
      content: "",
    },
    validationSchema: replyTicketValidation,
    onSubmit: async (values) => {
      try {
        await ticketMutation.mutateAsync(values);
        formik.resetForm();
        ticketQuery.refetch();
        toggleReplyModal();
        toast.success("Reply sended successfully!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } catch (error) {
        toast.error("Something wrong happened", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    },
  });

  return (
    <div className="primary-bg">
      <div className="ticket-wrapper container">
        {!ticketQuery.isLoading ? (
          <>
            <div className="ticket-header">
              <h4>Ticket Details</h4>
              <button onClick={toggleReplyModal}>
                <strong>Add Reply</strong>
              </button>
            </div>
            <div className="ticket-details">
              <div className="detail-item">
                <span>Ticket Number:</span>
                <p className="word-break">#{data?.id}</p>
              </div>
              <div className="detail-item">
                <span>Ticket Created At:</span>
                <p className="word-break">{moment(data?.createdAt).format("MMM Do YY, h:mm a")}</p>
              </div>
              <div className="detail-item">
                <span>Subject:</span>
                <p className="word-break">{data?.subject}</p>
              </div>
              <div className="detail-item">
                <span>Status:</span>
                <p className="word-break">
                  {data?.status == ACTIVE_TICKET_STATUS
                    ? "Active"
                    : data?.status == RESOLVED_TICKET_STATUS
                    ? "Resolved"
                    : ""}
                </p>
              </div>
            </div>

            <div className="ticket-replies">
              {isArray(replies) &&
                arraySort(replies, "createdAt")?.map((item) => (
                  <ReplyItem key={item?.id} reply={item} />
                ))}
            </div>
          </>
        ) : (
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
      </div>

      <CModal
        centered={true}
        show={isReplyModalOpen}
        onClose={toggleReplyModal}
      >
        <CModalHeader>
          <CModalTitle>Send Reply To {data?.ticketable?.name}</CModalTitle>
        </CModalHeader>
        <CForm onSubmit={formik.handleSubmit}>
          <CModalBody>
            <div className="form-group">
              <label htmlFor="exampleFormControlTextarea1">Reply Message</label>
              <br />
              <br />
              <RichText
                value={formik.values.content}
                onChange={(value) => {
                  formik.setFieldValue("content", value);
                }}
              />
              {formik.touched.content && formik.errors.content ? (
                <div className="errmsg">{formik.errors.content}</div>
              ) : null}
            </div>
          </CModalBody>
          <CModalFooter style={{ display: "flex" }}>
            <CButton
              className="ticket-reply-btn"
              color="secondary"
              type="button"
              onClick={toggleReplyModal}
            >
              Cancel
            </CButton>
            <CButton
              className="ticket-reply-btn"
              color="primary"
              type="submit"
              disabled={ticketMutation.isLoading}
            >
              Send Reply
            </CButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </div>
  );
}

export default Ticket;
