import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import QualitativeRatingForm from "../../Components/QualitativeRatingForm";

const QualitativeRating = ({ showLook, isManager }) => {
  const location = useLocation();
  const qualitative_id = location?.state?.qualitative_id;
  const employeeAttribute = location?.state?.employeeAttribute;
  const id = location?.state?.id;
  const [qualitativeRating] = useState({
    id: qualitative_id,
    name: employeeAttribute?.name,
    comment: employeeAttribute?.comment,
    decs: employeeAttribute?.decs,
    rating: employeeAttribute?.rating,
  });
  const history = useHistory();

  return (
    <div className="min-height-60vh qualitative-rating-wrapper">
      <div className="container">
        {showLook && (
          <p className="form-control Goal">{employeeAttribute?.employeeName}</p>
        )}
        <br />
        {qualitativeRating ? (
          <QualitativeRatingForm
            id={id}
            key={qualitativeRating?.id}
            attributeId={qualitativeRating?.id}
            qualitativeTarget={qualitativeRating}
            readOnly={showLook}
            isManager={isManager}
            isUpdate={true}
            onSuccess={() => history.push("/dashboard")}
          />
        ) : (
          <div className="heding  heading-color">
            <h4>qualitative target not found</h4>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.globalReducer.loading,
    errorMsg: state.loginReducer.errorMsg,
    showLook: state.globalReducer.showLook,
    isManager: state.loginReducer.loginData?.is_manager,
  };
};

const mapDispatch = (dispatchEvent) => ({});

export default connect(mapStateToProps, mapDispatch)(QualitativeRating);
