import { call, put, apply } from 'redux-saga/effects'
import * as Action from '../type.action'
import { POST, GET, RESETPOST } from '../../utils/helper'
import 'react-toastify/dist/ReactToastify.css';
import { GETUNSEENNOTIFICATIONCOUNT } from "./ApiConstant";


export function* getNotificationCount(action) {
    // yield put({ type: Action.LOADING_SHOW })
    // yield put({ type:'HIDE_MSG' })
    try {

        //  const url = str===''?'viewGoalAsManager?offset=1&limit=500':`viewGoalAsManager?search_string=${str}&offset=1&limit=500`
        const Data = yield call(GET, GETUNSEENNOTIFICATIONCOUNT)
        //    console.log("viewGoalAsManager", Data)
        if (Data.status === 200) {
            yield put({ type: Action.NOTIFICATION_COUNT_SUCCESS, data: Data.body.data, msg: 'Success!!' })
            //yield put({ type: Action.LOADING_HIDE })
        } else {
            yield put({ type: Action.NOTIFICATION_COUNT_FAIL, data: Data.message })
            // yield put({ type: Action.LOADING_HIDE })
        }
    } catch (error) {

        if (error.response && error.response.data && error.response.data.message) {
            yield put({ type: Action.NOTIFICATION_COUNT_FAIL, data: error.response.data.message })

        } else {
            yield put({ type: Action.NOTIFICATION_COUNT_FAIL, data: 'something went wrong' })
        }
        // yield put({ type: Action.LOADING_HIDE })
    }
}