import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { toNumber } from "lodash";

import edit from "../../assets/images/editred.png";
import delete1 from "../../assets/images/delete1.png";
import { getGoalPercentage } from "../../utils/helper";
import GoalItem from "../GoalItem";
import "./style.scss";

const ManagerGoalItem = ({
  goal,
  readOnly,
  openGoalDetail,
  deletePrevGoal,
  index = 0,
}) => {
  const [isOpen, setOpen] = useState(false);
  const history = useHistory();
  const employeesGoal = goal?.team_goal_assigns;

  const getGoalTeamPercentage = () => {
    let sumOfTeamMeasure = 0;
    let sumOfTeamCompleteMeasure = 0;
    employeesGoal?.forEach((item) => {
      sumOfTeamMeasure += toNumber(item?.measure);
      sumOfTeamCompleteMeasure += toNumber(item?.complete_measure);
    });
    const factor = 10 ** 2;
    const truncatedNumber =
      Math.trunc((sumOfTeamCompleteMeasure / sumOfTeamMeasure) * 100 * factor) /
      factor;
    return isFinite(truncatedNumber) ? truncatedNumber : 0;
  };

  const viewManagerGoalDetails = () => {
    if (!readOnly)
    history.push(`viewmanagergoal/${goal?.id}`);
  };

  const handleOnClickEmployeeGoal = (goal_id, id) => {
    if (!readOnly)
      history.push({
        pathname: `/goalaproval/${goal_id}/${id}`,
      });
  };

  const handleOnOpenEdit = (e) => {
    e.stopPropagation();
    history.push(`edit-goal/${goal?.id}`);
  };

  return (
    <div className="manager-goal-item">
      <GoalItem
        key={goal?.id}
        title={goal?.title}
        goal={goal}
        index={index}
        onClick={viewManagerGoalDetails}
        percentageTitle={getGoalTeamPercentage() + "%"}
        percentage={getGoalTeamPercentage()}
        onCancel={handleOnOpenEdit}
        actions={
          <div className="manager-goal-item-actions">
            {!readOnly && (
              <>
                <img
                  className="action"
                  alt="edit"
                  src={edit}
                  onClick={handleOnOpenEdit}
                />

                {/* Managers Can't Delete the Goal */}

                {/* <img
                  className="action"
                  alt="del"
                  src={delete1}
                  onClick={(e) => {
                    e.stopPropagation();
                    deletePrevGoal(goal?.id);
                  }}
                /> */}
                
              </>
            )}

            <div
              className="action"
              onClick={(e) => {
                e.stopPropagation();
                setOpen(!isOpen);
              }}
            >
              {isOpen ? <IoIosArrowDown /> : <IoIosArrowUp />}
            </div>
          </div>
        }
      />

      {isOpen && (
        <div className="employees-goals">
          {employeesGoal?.map((employeeGoal) => {
            let goalCompletionPercentage = getGoalPercentage(
              Number(employeeGoal?.complete_measure),
              Number(employeeGoal?.measure)
            );

            return (
              <GoalItem
                key={employeeGoal?.id}
                title={employeeGoal?.employee?.name}
                goal={goal}
                onClick={() =>
                  handleOnClickEmployeeGoal(
                    employeeGoal?.goal_id,
                    employeeGoal?.id
                  )
                }
                percentageTitle={
                  <>
                    {employeeGoal.complete_measure}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {goalCompletionPercentage || 0}%
                  </>
                }
                percentage={goalCompletionPercentage}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ManagerGoalItem;
