import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

import logofree from "../../../../src/assets/images/logo-free.png";
import freeFile from "../../../../src/assets/images/free-file.png";

export default function FreeTrial() {
  const freeTrialEndDate = useSelector(
    (state) => state.loginReducer.employerloginData.free_trial_end_datetime
  );
  const history = useHistory();

  return (
    <div>
      <div class="free-trial-sign">
        <div class="container">
          <div class="free-trial-img-logo">
            <img class="logo" src={logofree} alt="" />
            <img class="edit-free-trail" src={freeFile} alt="" />
          </div>
        </div>
        <div class="trail-info-wrapper">
          <div class="container">
            <div class="trail-info">
              <div class="trial-btn">
                <button>
                  Your free trial will expire on
                  <br />
                  <span>{moment(freeTrialEndDate).format("DD MMM YYYY")}</span>
                </button>
              </div>
              <div class="Subscription-btn">
                <button onClick={() => history.push("/planlist")}>
                  Buy Subscription Plan
                </button>
              </div>
              <div class="trial-btn">
                <button
                  onClick={() => history.push("/employerdashboard")}
                  style={{
                    border: "none",
                    textDecoration: "underline",
                    width: "3rem",
                    height: "2rem",
                  }}
                >
                  Skip
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
