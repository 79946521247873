import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { isArray, toNumber } from "lodash";

import "./style.scss";
import { useGetScore } from "../../hooks/analytics.hook";
import SelectFilterInput from "../SelectFilterInput";
import LoadingWidget from "../LoadingWidget";
import arraySort from "array-sort";
import { Score, StoredEmails } from "../AnalyticsConstant";
import { useSelector } from "react-redux";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      // reverse: true, // Add the reverse property to the x-axis scale
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
    zoom: {
      pan: {
        enabled: true,
        mode: "x",
      },
      zoom: {
        wheel: {
          enabled: true,
          mode: "x",
        },
        mode: "x",
      },
    },
  },
};

const data = {
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: ["rgba(75, 192, 192, 0.2)"],
      borderColor: ["rgba(75, 192, 192, 1)"],
    },
  ],
};

const intervalOptions = [
  {
    id: 7,
    value: 7,
    label: "Last 7 Days",
  },
  {
    id: 15,
    value: 15,
    label: "Last 15 Days",
  },
  {
    id: 30,
    value: 30,
    label: "Last 30 Days",
  },
  {
    id: 90,
    value: 90,
    label: "Last 90 Days",
  },
];

const ScoreWidget = () => {
  const [selectedInterval, setSelectedInterval] = useState(7);
  const scoreQuery = useGetScore(selectedInterval);
  const [lineData, setLineData] = useState(data);
  const [isMoodNotFound, setIsMoodNotFound] = useState(true);

  const email = useSelector(state => state?.profileReducer?.profileData?.email);
  const isEmailStored = email && StoredEmails.includes(email)
  const scoreList = isEmailStored ? arraySort(Score(selectedInterval),"date") : arraySort(scoreQuery.data?.body?.data, "date")

  useEffect(() => {
    if (isArray(scoreList)) {
      if (scoreList?.length > 0) {
        setIsMoodNotFound(false);
      } else setIsMoodNotFound(true);

      setLineData((prev) => convertArrayToLineData(prev));
    }
  }, [scoreQuery.isLoading]);

  const convertArrayToLineData = (prev) => {
    const labels = [];
    const moodData = [];
    scoreList?.forEach((item) => {
      const date = item.date;
      const moodDifference =
        toNumber(item?.good_mood) - toNumber(item?.bad_mood);
      labels.push(date);
      moodData.push(moodDifference);
    });

    return {
      ...prev,
      labels,
      datasets: [
        {
          ...prev.datasets[0],
          data: moodData,
        },
      ],
    };
  };

  const handleChangeSelectInterval = (e) => {
    setSelectedInterval(e.target.value);
  };

  return (
    <div className="score-widget">
      <div className="header">
        <p>Score</p>
        <SelectFilterInput
          options={intervalOptions}
          currentValue={selectedInterval}
          handleSelectFilter={handleChangeSelectInterval}
        />
      </div>
      <div className="line-wrapper">
        {!scoreQuery.isLoading && !isMoodNotFound ? (
          <Line data={lineData} options={options} />
        ) : isMoodNotFound && !scoreQuery.isLoading ? (
          <>
            <p className="no-data-found">No data found</p>
          </>
        ) : (
          <LoadingWidget />
        )}
      </div>
    </div>
  );
};

export default ScoreWidget;
