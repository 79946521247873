import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import SearchTeam from "./Common/SearchTeam";
import ratestar from "../assets/images/rate-star.png";
import profilePlaceholder from "../assets/images/profilePlaceholder/profilePlaceholder.png";
import draw from "../assets/images/draw.png";
import "../styles/_search-team.scss";
import Svgs from "../../src/assets/Svgs/index";
import TreeSwitch from "./Common/TreeSwitch";
import TreeStructure from "./TreeStructure";
import { GET } from "../../src/utils/helper";
import useManagerTree from "./TreeStructure/ManagerTree";

const Team = (props) => {
  const [keyword, setKeyword] = useState("");
  const history = useHistory();

  useEffect(() => {
    props.getTeamList();
  }, []);

  const handleKeyword = (data) => {
    setKeyword(data);
  };
  const getRateDays = (date) => {
    var date1 = new Date(date);
    var date2 = new Date();
    var Difference_In_Time = date2.getTime() - date1.getTime();

    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days.toFixed(0);
  };

  const handleAddEmployee = () => {
    const manager_id = localStorage.getItem("id");
    history.push({
      pathname: "/addemployee",
      state: { is_manager: 1, manager_id: manager_id },
    });
  };
  const [ActiveTab, setActiveTab] = useState('list');
  const [TreeData, setTreeData] = useState({});

  useManagerTree(setTreeData,props);
  

  return (
    <div className="min-height-60vh">
      <div className="container">
        <div className="team-header-container">
          <SearchTeam handleKeyword={handleKeyword} fullWidth />
          <button
            onClick={handleAddEmployee}
            style={{ width: "9rem", height: "2.5rem" }}
          >
            Add Employee
          </button>
        </div>
      </div>
      <div class="manager-team-wrapper  container-employee-goal-div">
        {props.loading && (
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        )}

        <div class="container">

        <TreeSwitch active={ActiveTab} onChange={setActiveTab} />

          {!props.loading &&
            props.teamList.length === 0 &&
            keyword.length === 0 && (
              <div className="teammsg">No Team Member Added!</div>
            )}
          {!props.loading &&
            props.teamList.length === 0 &&
            keyword.length >= 1 && (
              <div className="teammsg">No Team Member Found!</div>
            )}

          <div className={ActiveTab != "list" && 'd-none'}>
          <h3 className="color-grey text-center">Click on card to view details</h3>
            {props?.teamList?.map((item, id) => {
              return (
                <div
                  class={id % 2 === 0 ? "manage-details" : "manage-details two"}
                  key={id}
                >
                  <div class="row no-gutters">
                    <div class="col-md-6">
                      <div class="media">
                        <div class="media-img">
                          <img
                            className="managerTeam-img"
                            src={
                              item.employee?.profile_pic_url
                                ? item.employee?.profile_pic_url
                                : profilePlaceholder
                            }
                          />
                          <div class="moji-img">
                            {item.isEmployeeEnergyUpdatedInLast24Hour && (
                              <img
                                className="pending-goalsdetail"
                                src={item.employee?.emoji.image_url}
                              />
                            )}
                          </div>
                        </div>

                        <div
                          class="media-body"
                          onClick={() =>
                            props.history.push({
                              pathname: `/teamemployeedetail/${item?.employee.id}`,
                              state: { item },
                            })
                          }
                        >
                          <p>{item.employee?.name}</p>
                          <p>{item.employee?.email}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="right-text float-right">
                        <p>
                          {item.employee?.country_code}{" "}
                          {item.employee?.phone_number}
                        </p>

                        <div className="manager-team-item-actions">
                          <button
                            class="Rate-employee"
                            onClick={() =>
                              props.history.push({
                                pathname: "/rating",
                                state: { id: item?.employee.id },
                              })
                            }
                          >
                            <img src={ratestar} />

                            <span> Rate Employee</span>
                          </button>

                          <div className="edit-action">
                            <img
                              alt=""
                              src={draw}
                              onClick={(e) => {
                                e.stopPropagation();
                                history.push({
                                  pathname: `/addemployee/${item?.employee?.id}`,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className={ActiveTab != "tree" && 'd-none'}>
            <TreeStructure TreeData={TreeData} />
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

const mapStateToProps = (state) => {
  const teamList = state.teamReducer.teamList;
  const errorMsg = state.teamReducer.errorMsg;
  const totalTeamCount = state.teamReducer.totalTeamCount;
  const loading = state.globalReducer.loading;
  return {
    teamList,
    loading,
    errorMsg,
    totalTeamCount,
    sucessMsg: state.ratingReducer.sucessMsg,
  };
};

const mapDispatch = (dispatchEvent) => ({
  getTeamList: () => dispatchEvent({ type: "GET_TEAMLIST" }),
  onFieldChange: (payload) =>
    dispatchEvent({ type: "ONFIELD_CHANGE", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(Team);
