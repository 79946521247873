import React, { Component, useEffect, useState } from "react";
import search from "../../assets/images/search.png";
import happy from "../../assets/images/happy1.png";
import pro from "../../assets/images/up-pro.png";
import workerimg from "../../assets/images/profilePlaceholder/profilePlaceholder.png";
import smalsmile from "../../assets/images/smile-small.png";
import deleteimg from "../../assets/images/delete.png";
import draw from "../../assets/images/draw.png";
import moment from "moment";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  CModal,
  CModalHeader,
  CModalFooter,
  CModalBody,
  CButton,
  CModalTitle,
} from "@coreui/react";
import { GET, POST, deleteAPI, putAPI } from "../../utils/helper";
import EmployeeCard from "./components/EmployeeCard";
import TreeSwitch from "../Common/TreeSwitch";
import useEmployerTree from "../TreeStructure/EmployerTree";
import TreeStructure from "../TreeStructure";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const EmployerTeam = (props) => {
  const [EmployerteamList, setEmployerteamList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [changeManagerModal, setChangeManagerModal] = useState(false);
  const [isManager, setIsManager] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [keyword, setKeyword] = useState("");
  const [dept, setDepartment] = useState("");
  const [errorText, setErrorText] = useState("");
  const [emplyrManagerData, setEmplyrManagerData] = useState(null);
  const [managerId, setManagerId] = useState(null);
  const [rank, setRank] = useState([]);
  const [selectedRank, setSelectedRank] = useState(null);

  useEffect(() => {
    const getRanks = async () => {
      const result = await GET("getEmployeeRankList");
      setRank(result?.body?.data?.rows);
    };
    const getData = async () => {
      props.getEmployerDepartmentList();
      try {
        ActiveTab != "list" && setActiveTab('list')
        let rankText;
        let searchText;
        let deptText;

        if (keyword) {
          searchText = `searchKey=${keyword}`;
        } else {
          searchText = "";
        }
        if (selectedRank && selectedRank !== "0") {
          rankText = `employeeRankId=${selectedRank}`;
        } else {
          rankText = "";
        }
        if (dept && dept !== "0") {
          deptText = `departmentId=${dept}`;
        } else {
          deptText = "";
        }

        setLoading(true);
        const res = await GET(
          `getEmployeeList?${deptText}&&${searchText}&&${rankText}`,
          1
        );
        if (res.status === 402) {
          toast.error(res.message, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 5000,
          });
        }
        setLoading(false);

        if (
          res.body.data &&
          res.body.data.rows &&
          res.body.data.rows.length > 0
        ) {
          setErrorText("");
          const data = res.body.data.rows;
          if (data.length === 0) {
            if (keyword !== "" || dept !== "" || dept !== 0) {
              setErrorText("No employees found");
              return;
            }
          } else {
            setErrorText("");
          }
          setEmployerteamList(data);
        } else {
          if (keyword !== "" || dept !== "" || dept !== 0) {
            setErrorText("No employees found");
          } else {
            setErrorText("No Employee Added");
          }
        }

        setEmployerteamList(res.body.data.rows);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    getData();
    getRanks();
  }, [keyword, dept, selectedRank]);

  const handleChange = (e) => {
    e.preventDefault();
    setKeyword(e.target.value);
  };

  const getmangerList = async () => {
    try {
      let res = await GET(`getManagerList`, 1);
      res = res.body.data.filter((item) => {
        return item.id !== userId;
      });
      setEmplyrManagerData(res);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const toggleDelete = (id, ismanager, name) => {
    setUsername(name);
    setUserId(id);
    setIsManager(ismanager);
    setDeleteModal(!deleteModal);
  };

  const handleChangeManager = async (paramid) => {
    let data = {
      current_manager_id: userId,
      new_manager_id: paramid,
    };

    const res = await putAPI("updateManager", data, 1);

    if (res.data.status === 200) {
      let query = "";
      if (dept && keyword && dept != 0) {
        query = `departmentId=${dept}&&searchKey=${keyword}&&employeeRankId${selectedRank}`;
      } else if (selectedRank) {
        query = `employeeRankId=${selectedRank}`;
      } else if (dept && dept != 0) {
        query = `departmentId=${dept}`;
      } else if (keyword) {
        query = `searchKey=${keyword}`;
      }

      setChangeManagerModal(!changeManagerModal);
      try {
        await deleteAPI(`deleteEmployee/${userId}`, 1);
        toast.success("Employee Deleted successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
      setLoading(true);
      const res = await GET(`getEmployeeList?${query}`, 1);
      setLoading(false);
      setEmployerteamList(res.body.data.rows);
    }
  };

  const handleDelete = async () => {
    if (isManager) {
      setDeleteModal(!deleteModal);
      getmangerList();
      setChangeManagerModal(!changeManagerModal);
      return;
    }

    try {
      let query = "";
      if (dept && keyword && dept !== 0) {
        query = `departmentId=${dept}&&searchKey=${keyword}&&employeeRankId${selectedRank}`;
      } else if (selectedRank) {
        query = `employeeRankId=${selectedRank}`;
      } else if (dept && dept != 0) {
        query = `departmentId=${dept}`;
      } else if (keyword) {
        query = `searchKey=${keyword}`;
      }

      setDeleteModal(!deleteModal);
      try {
        await deleteAPI(`deleteEmployee/${userId}`, 1);
        toast.success("Employee Deleted successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
      setLoading(true);
      const res = await GET(`getEmployeeList?${query}`, 1);
      setLoading(false);
      setEmployerteamList(res.body.data.rows);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const [ActiveTab, setActiveTab] = useState('list');
  const [TreeData, setTreeData] = useState({});
  useEmployerTree(setTreeData)

  const history = useHistory();
  
  const handleStartChat = (id)=>{
    POST("initiateChat",{
      employeeId:id
    })
    .then(data=>{
      history.push({
        pathname:'/chat',
        state: {
          title: "",
          id: data?.id,
          room_id: data.room_id,
          is_disabled: false,
        },
      })
    })
  }

  return (
    <React.Fragment>

      
      <div className="search-wrapper">
        <CModal
          show={deleteModal}
          centered={true}
          backdrop={true}
          color="warning"
          onClose={setDeleteModal}
        >
          <CModalHeader>
            <CModalTitle>Delete Employee?</CModalTitle>
          </CModalHeader>
          <CModalBody>
            Are you sure you want to Delete this Employee?
          </CModalBody>
          <CModalFooter style={{ display: "flex", flexDirection: "row" }}>
            <CButton
              style={{
                width: "6rem",
                height: "3rem",
                backgroundColor: "#F56A14",
                color: "white",
              }}
              onClick={handleDelete}
            >
              Yes
            </CButton>{" "}
            <CButton
              style={{
                width: "6rem",
                height: "3rem",
                backgroundColor: "#4B9CEB",
                color: "white",
              }}
              onClick={() => setDeleteModal(false)}
            >
              Cancel
            </CButton>
          </CModalFooter>
        </CModal>
        <CModal
          show={changeManagerModal}
          centered={true}
          backdrop={true}
          color="warning"
          onClose={setChangeManagerModal}
        >
          <CModalHeader>
            <CModalTitle>
              Please select new manager for all the employees whose current
              manager is {username}.
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            {emplyrManagerData && (
              <select
                className="custom-selec-wrap"
                name="manager_id"
                value={managerId}
                style={{ height: "43px" }}
                onChange={(e) => {
                  handleChangeManager(e.target.value);
                }}
              >
                <option value="">Select Manager</option>
                {emplyrManagerData?.map((e, i) => (
                  <option value={e.id}>{e.name}</option>
                ))}
              </select>
            )}
          </CModalBody>
          <CModalFooter style={{ display: "flex", flexDirection: "row" }}>
            <CButton
              style={{
                width: "6rem",
                height: "3rem",
                backgroundColor: "#4B9CEB",
                color: "white",
              }}
              onClick={() => setChangeManagerModal(false)}
            >
              Cancel
            </CButton>
          </CModalFooter>
        </CModal>
        <div className="search">
          <div className="row justify-content-center px-md-0 px-2">
            <div className="col-md-4 mb-2">
              <form action="">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by name, email or mobile number"
                    value={keyword}
                    onChange={(e) => handleChange(e)}
                  />
                  <img style={{ marginLeft: "1rem" }} src={search} alt="" />
                </div>
              </form>
            </div>
            <div className="col-md-3 mb-2">
              <select
                className="custom-selec-wrap"
                value={selectedRank}
                style={{ height: "43px" }}
                onChange={(e) => setSelectedRank(e.target.value)}
              >
                <option value="0">Team Level</option>
                {rank &&
                  rank.map((e, i) => <option value={e.id}>{e.name}</option>)}
              </select>
            </div>
            <div className="col-md-3 mb-2">
              <select
                className="custom-selec-wrap"
                value={dept}
                style={{ height: "43px" }}
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option value="0">Department </option>
                {props?.emplyrDepartmentData?.map((e, i) => (
                  <option value={e.id}>{e.name}</option>
                ))}
              </select>
            </div>

            <button
              onClick={() => props?.history.push({ pathname: "/addemployee" })}
              style={{ width: "7rem", height: "2.5rem" }}
            >
              Add
            </button>
          </div>
        </div>
      </div>


      <TreeSwitch active={ActiveTab} onChange={setActiveTab} />

      <div className={ActiveTab != "list" && 'd-none'}>
        <div style={{ minHeight: "50vh" }} className="employees-wrapper">
          {!loading ? (
            !errorText ? (
              EmployerteamList &&
              EmployerteamList?.map((item, index) => {
                let lastUpdate = moment().diff(
                  moment(item.energy_last_updated),
                  "hours"
                );
                return (
                  <EmployeeCard
                    handleStartChat={handleStartChat}
                    key={item.id}
                    employee={item}
                    primary={index % 2 !== 0}
                    history={props.history}
                    lastUpdate={lastUpdate}
                    toggleDelete={toggleDelete}
                  />
                );
              })
            ) : (
              <div className="teammsg">{errorText}</div>
            )
          ) : (
            <div
              style={{ marginBottom: "2rem" }}
              className="spinner-border text-primary"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          )}
        </div>
      </div>

      <div className={ActiveTab != "tree" && 'd-none'}>
        <div className="container"><TreeStructure TreeData={TreeData} /></div>
      </div>

    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const EmployerteamList = state.teamReducer.EmployerteamList;
  const errorMsg = state.teamReducer.errorMsg;
  const totalTeamCount = state.teamReducer.totalTeamCount;
  const loading = state.globalReducer.loading;
  return {
    EmployerteamList,
    loading,
    errorMsg,
    totalTeamCount,
    employerloginData: state.loginReducer.employerloginData,
    teamEnergy: state.teamReducer.teamEnergy,
    emplyrDepartmentData: state.teamReducer.emplyrDepartmentData,
  };
};

const mapDispatch = (dispatchEvent) => ({
  getEmployerTeamList: (payload) =>
    dispatchEvent({ type: "GET_EMPLOYERTEAMLIST", value: payload }),
  getEmployerDepartmentList: () =>
    dispatchEvent({ type: "GET_EMPLOYERDEPARTMENTLIST" }),
  deleteEmplyeEmply: (payload) =>
    dispatchEvent({ type: "DELETEEMPLYEMPLYE", value: payload }),
});

export default connect(mapStateToProps, mapDispatch)(EmployerTeam);
