import Svgs from "../../../assets/Svgs";

const SelectType = ({ setStep, onChange, Close }) => {
  return (
    <div className="d-flex align-items-center justify-content-center gap-5 my-5">
      <div>
        <input
          type="file"
          accept="image/*"
          multiple={false}
          onChange={(e) => {
            onChange(e?.target?.files);
            Close(false);
          }}
          id="profile_pic_url"
          name="profile_pic_url"
          hidden
        />
        <label htmlFor="profile_pic_url">
          <div className="border rounded px-4 py-4 d-flex flex-column align-items-center gap-6 card-hover">
            <Svgs.Image />
            <h4 className="text-center">Upload Image</h4>
          </div>
        </label>
      </div>

      <div
        onClick={() => setStep("UploadAvatar")}
        className="border rounded px-4 py-4 d-flex flex-column align-items-center gap-6 card-hover"
      >
        <Svgs.Avatar />
        <h4 className="text-center">Select Avatar</h4>
      </div>
    </div>
  );
};

export default SelectType;
