import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Modal, ModalBody } from "reactstrap";
import {
  CModal,
  CModalHeader,
  CModalFooter,
  CModalBody,
  CButton,
  CModalTitle,
} from "@coreui/react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { BsInfoCircleFill } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import { useHistory } from "react-router-dom";

import { GET, deleteAPI } from "../../utils/helper";
import cross from "../../assets/images/cross.png";
import map from "../../assets/images/map.png";
import "./style.scss";
import AssignedEmployee from "./components/AssignedEmployee";
import { isArray } from "lodash";

const AttributeItem = ({ attribute, handleRefresh, refresh }) => {
  const history = useHistory();
  const [modal, showModal] = useState(false);
  const [modaldata, setModalData] = useState("");
  const [attributeId, setAttributeId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isExpand, setExpand] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [assignedEmployee, setAssignedEmployees] = useState(null);

  const showModalData = (e, comment) => {
    e.stopPropagation();
    setModalData(comment);
    showModal(true);
  };

  const handleDelete = (e, id) => {
    e.stopPropagation();
    setAttributeId(id);

    setDeleteModal(true);
  };

  const deleteAttribute = async () => {
    setDeleteModal(false);

    try {
      const result = await deleteAPI(`deleteAttribute/${attributeId}`);
      if (result.status === 200) {
        handleRefresh(!refresh);
        toast.success("Deleted successfully", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
        });
      } else {
        toast.error(result.message, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleOnExpand = async () => {
    setExpand(!isExpand);
  };

  const getAssignedEmployee = async () => {
    if (!assignedEmployee) {
      try {
        setLoading(true);

        const result = await GET(`getAttributeEmployees/${attribute?.id}`);
        setLoading(false);

        setAssignedEmployees(result?.body?.data);
        return result?.body?.data
      } catch (error) {
        setLoading(false);
      }
    }else{
      return assignedEmployee
    }
  };

  const handleOnEdit = async (e) => {
    e.stopPropagation();
    const assignedEmployees = await getAssignedEmployee();
    let employees = [];
    await assignedEmployees?.map((item) => {
      employees.push({ value: item?.employee?.id, label: item?.employee?.name })
    });
    history.push({
      pathname: `/addattributes`,
      state: {
        attribute,
        employees
      },
    });
  };

  useEffect(() => {
    if (isExpand) getAssignedEmployee();
  }, [isExpand]);

  return (
    <>
      <div
        className="attribute-item attribute-item-box"
        onClick={handleOnExpand}
      >
        <span className="attribute-name">{attribute?.name}</span>
        <div className="attribute-item-actions">
          <BsInfoCircleFill
            className="action-item"
            onClick={(e) => showModalData(e, attribute?.comment)}
          />
          <MdEdit className="action-item" onClick={handleOnEdit} />
          <RiDeleteBin6Fill
            className="action-item delete"
            onClick={(e) => handleDelete(e, attribute?.id)}
          />

          {isExpand ? <IoIosArrowDown /> : <IoIosArrowUp />}
        </div>
      </div>
      {isExpand && isArray(assignedEmployee)
        ? assignedEmployee?.map((item) => (
          <div className="assigned-employees">
                  <AssignedEmployee
                    employeeAttribute={item}
                    attributeName={attribute?.name}
                    attributeId={attribute?.id}
                  />
          </div>
          ))
        : null}
      {isLoading ? (
        <div
          className="spinner-border loading-assigned-employees"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      ) : null}

      <Modal isOpen={modal} className="goalsucessmodal">
        <ModalBody>
          <div style={{ minHeight: "18rem" }} className="owenship-popup">
            <img
              alt="cross"
              src={cross}
              className="cross closepopup"
              onClick={() => showModal(false)}
            />
            <div className="map-img">
              <img
                alt="map"
                style={{ width: "3rem", height: "3rem" }}
                src={map}
              />
            </div>
            <p style={{ wordWrap: "break-word" }}>{modaldata}</p>
          </div>
        </ModalBody>
      </Modal>

      <CModal
        show={deleteModal}
        centered={true}
        backdrop={true}
        color="warning"
        onClose={setDeleteModal}
      >
        <CModalHeader>
          <CModalTitle>Delete Target?</CModalTitle>
        </CModalHeader>
        <CModalBody>Are you sure you want to Delete this Target?</CModalBody>
        <CModalFooter style={{ display: "flex", flexDirection: "row" }}>
          <CButton
            style={{
              width: "6rem",
              height: "3rem",
              backgroundColor: "#F56A14",
              color: "white",
            }}
            onClick={deleteAttribute}
          >
            Yes
          </CButton>{" "}
          <CButton
            style={{
              width: "6rem",
              height: "3rem",
              backgroundColor: "#4B9CEB",
              color: "white",
            }}
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default AttributeItem;
