import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { isArray } from "lodash";
import { toast } from "react-toastify";

import "../scss/customforall.scss";
import banner4 from "../assets/images/banner4.png";
import worker from "../assets/images/profilePlaceholder/profilePlaceholder.png";
import { GET, POST } from "../utils/helper";
import MentionInput from "./MentionInput";
import AchievementItem from "./AchievementItem";
import {
  useEmployeesForMention,
  useQualitativeTargetForMention,
} from "../hooks/mention.hook";

export default function Achievement() {
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "a few seconds",
      ss: "%d seconds",
      m: "a minute",
      mm: "%d minutes",
      h: "an hour",
      hh: "%d hours",
      d: "a day",
      dd: "%d days",
      w: "a week",
      ww: "%d weeks",
      M: "a month",
      MM: "%d months",
      y: "a year",
      yy: "%d years",
    },
  });

  const loginRole = useSelector((state) => state.loginReducer.loginRole);
  const employees = useEmployeesForMention(loginRole);
  const targets = useQualitativeTargetForMention(loginRole);
  const [profile_pic_url, setprofile_pic_url] = useState('');

  const getLatestProfilePic = async () => {
    const res = await GET(`${loginRole == 0 ? "getMyProfile" : "getProfile"}`);
    const data = res.body.data;
    setprofile_pic_url(data?.profile_pic_url);
  }

  useEffect(() => {
    getLatestProfilePic()
  }, [])
  
  const [makeInputBigger, setMakeInputBigger] = useState(false);
  const dispatch = useDispatch();
  const achievementList = useSelector(
    (state) => state.achievementListReducer.list
  );
  const loading = useSelector((state) => state.globalReducer.loading);

  const [post, setPost] = useState({
    id: null,
    createAchievementValue: "",
    isUpdate: false,
    createAchievementModal: false,
    refresh: false,
    editPostValue: "",
    employees_mentions: [],
    qualitative_tags: [],
  });
  const [selectedMention, setSelectedMention] = useState("");
  const [selectedTag, setSelectedTag] = useState("");

  const handleMentionChange = (e) => {
    setSelectedMention(e.target.value);
  };

  const handleTagChange = (e) => {
    setSelectedTag(e.target.value);
  };
  const getAchievementList = useCallback(
    async (showLoading) => {
      try {
        dispatch({
          type: "GET_ACHIEVEMENT_LIST",
          showLoading,
          mention: selectedMention,
          tag: selectedTag,
        });
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch,selectedMention,selectedTag]
  );

  useEffect(() => {
    dispatch({ type: "GET_NOTIFICATION_COUNT" });
    getAchievementList(true);
  }, [getAchievementList, dispatch]);

  const handlePostUpdate = async () => {
    if (!post.isUpdate) {
      if (post.createAchievementValue.trim().length === 0) {
        toast.error("Post is not allowed to be empty", {
          position: toast.POSITION.BOTTOM_CENTER,
        });

        return;
      }
    } else {
      if (post.editPostValue.trim().length === 0) {
        toast.error("Post is not allowed to be empty", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        setPost((state) => ({ ...state, editPostValue: "", isUpdate: false }));

        return;
      }
    }

    let data = {
      description: post.createAchievementValue,
      employees_mentions: post.employees_mentions,
      qualitative_tags: post.qualitative_tags,
    };

    let updateData = {
      achievement_id: post.id,
      description: post.editPostValue,
      employees_mentions: post.employees_mentions,
      qualitative_tags: post.qualitative_tags,
    };

    try {
      const result = await POST(
        "createUpdateAchievement",
        post.isUpdate ? updateData : data
      );

      if (result.status !== 200) {
        toast.error("Something went wrong", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
      if (!post.isUpdate) {
        setPost((state) => ({ ...state, createAchievementValue: "" }));

        setMakeInputBigger(false);
      } else {
        setPost((state) => ({ ...state, editPostValue: "", isUpdate: false }));
      }
      getAchievementList(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick1 = () => {
    handlePostUpdate();
    setPost((state) => ({
      ...state,
      createAchievementModal: false,
    }));
  };

  return (
    <div className="Employee-acheivment-wrapper bg-color">
      <div className="container">
        <div className="Employee-acheivment comman-width-container heightLess">
          <div className="banner-img">
            <img
              alt="banner"
              className="img-fluid achivement-img"
              src={banner4}
            />
          </div>
          <br />
          <div className="filters-wrapper">
            <div className="filter">
              <select
                className="custom-selec-wrap"
                value={selectedMention}
                style={{ height: "43px" }}
                onChange={handleMentionChange}
              >
                <option value="">Mention</option>
                {isArray(employees?.data?.data) &&
                  employees?.data?.data.map((e, i) => (
                    <option value={e.id}>{e.display}</option>
                  ))}
              </select>
            </div>
            <div className="filter">
              <select
                className="custom-selec-wrap"
                value={selectedTag}
                style={{ height: "43px" }}
                onChange={handleTagChange}
              >
                <option value="">Qualitative Target</option>
                {isArray(targets?.data?.data) &&
                  targets?.data?.data.map((e, i) => (
                    <option value={e.id}>{e.display}</option>
                  ))}
              </select>
            </div>
          </div>
          <div
            onClick={() => {
              setMakeInputBigger(true);
            }}
            class="row"
          >
            <div class="col-md-12">
              <div
                class={
                  makeInputBigger
                    ? "Acheivement-popup-wrapper two"
                    : "Acheivement-popup-wrapper"
                }
              >
                <div class="Acheivement-popup">
                  <div class="worker-img">
                    <img
                      alt="profilepic"
                      className="achivement-pic"
                      src={profile_pic_url ? profile_pic_url : worker}
                    />
                  </div>
                  {makeInputBigger ? (
                    <MentionInput
                      value={post.createAchievementValue}
                      mentionKey="user"
                      tagKey="target"
                      tagsData={targets?.data?.data || []}
                      mentionsData={employees?.data?.data || []}
                      onChange={(newValue, mentionList, tagsList) => {
                        setPost((state) => {
                          return {
                            ...state,
                            createAchievementValue: newValue,
                            employees_mentions: mentionList,
                            qualitative_tags: tagsList,
                          };
                        });
                      }}
                      placeholder="what do you want to talk about ?"
                    />
                  ) : (
                    <input
                      placeholder="what do you want to talk about ?"
                      class="form-control"
                    ></input>
                  )}
                </div>

                {makeInputBigger && (
                  <div class="post-btn">
                    <button onClick={() => handleClick1()}>Post</button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {!loading ? (
            isArray(achievementList) && achievementList.length > 0 ? (
              achievementList.map((item) => (
                <AchievementItem
                  key={item.id}
                  item={item}
                  employees={employees?.data?.data || []}
                  targets={targets?.data?.data || []}
                />
              ))
            ) : (
              <h4
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "rgb(255, 62, 62)",
                }}
              >
                No Achievements Found
              </h4>
            )
          ) : (
            <div
              style={{ marginTop: "8rem", marginBottom: "5rem" }}
              class="spinner-border text-primary"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
