import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { CModal, CModalBody, CModalHeader, CModalTitle } from "@coreui/react";

import "./style.scss";
import {
  useGetEmployeeEnergyHistory,
  useGetStreaks,
} from "../../hooks/analytics.hook";
import SelectFilterInput from "../SelectFilterInput";
import LoadingWidget from "../LoadingWidget";
import { useToggle } from "./../../hooks/useToggle";
import EnergyHistoryItem from "./components/EnergyHistoryItem";
import { StoredEmails, Streaks } from "../AnalyticsConstant";
import { useSelector } from "react-redux";

const filterOptions = [
  {
    id: 1,
    value: 1,
    label: "Positive",
  },
  {
    id: 0,
    value: 0,
    label: "Negative",
  },
  {
    id: 2,
    value: 2,
    label: "No Input",
  },
];

const StreaksWidget = () => {
  const [selectedFilter, setSelectedFilter] = useState(1);
  const streaksQuery = useGetStreaks();
  const [streaksList, setStreaksList] = useState([]);
  const [employeeInfo, setEmployeeInfo] = useState({});

  const [showStreaksHistory, toggleShowStreaksHistory] = useToggle(false);

  const userInfo = useSelector(state => state?.profileReducer?.profileData);
  const email = userInfo?.email;

  const employeeEnergyHistoryQuery = useGetEmployeeEnergyHistory(employeeInfo?.id);

  const isEmailStored = email && StoredEmails.includes(email);

  const streaks = isEmailStored ? Streaks(userInfo) : streaksQuery.data?.body?.data || []
  const energyHistory = isEmailStored ?
    Streaks(userInfo) : employeeEnergyHistoryQuery.data?.pages.flatMap((page) => page.body?.data) ||
    []

  const [columnVisibility, setColumnVisibility] = useState({
    good_mood_streak: true,
    bad_mood_streaks: false,
    no_input: false,
  });


  useEffect(() => {
    if (streaks) {
      filterStreaksList(selectedFilter, streaks);
    }
  }, [streaksQuery.isLoading]);

  useEffect(() => {
    if (employeeInfo?.id) {
      employeeEnergyHistoryQuery.refetch();
    }
  }, [employeeInfo?.id]);

  const handleChangeSelectFilter = (e) => {
    const value = e.target.value;
    setSelectedFilter(value);
    filterStreaksList(value, streaks);
  };

  const filterStreaksList = (filterValue, streaksArray) => {
    let streaks = [];
    if (filterValue == 1) {
      streaks = streaksArray.filter((item) => item.good_mood_streak > 0);
    } else if (filterValue == 0) {
      streaks = streaksArray.filter((item) => item.bad_mood_streaks > 0);
    } else if (filterValue == 2) {
      streaks = streaksArray.filter(
        (item) => item.bad_mood_streaks == 0 && item.good_mood_streak == 0
      );
    }
    setStreaksList(streaks);
    setColumnVisibility({
      good_mood_streak: filterValue == 1,
      bad_mood_streaks: filterValue == 0,
      no_input: filterValue == 2,
    });
  };

  const handleShowEmployeeMoodHistory = async (data) => {
    toggleShowStreaksHistory();
    setEmployeeInfo({ ...data });
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: "name",
      cell: (info) => <i>{info.getValue()}</i>,
      header: "Name",
    }),
    columnHelper.accessor((row) => row.good_mood_streak, {
      id: "good_mood_streak",
      cell: (info) => <i>{info.getValue()}</i>,
      header: "Positive Streaks",
    }),
    columnHelper.accessor((row) => row.bad_mood_streaks, {
      id: "bad_mood_streaks",
      cell: (info) => <i className="bad-streaks">{info.getValue()}</i>,
      header: "Negative Streaks",
    }),
    columnHelper.accessor((row) => 0, {
      id: "no_input",
      cell: (info) => <i className="no-input">---</i>,
      header: "No input",
    }),
  ];
  const table = useReactTable({
    data: streaksList,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="streaks-widget">
      <div className="header">
        <p>Streaks</p>
        <SelectFilterInput
          options={filterOptions}
          currentValue={selectedFilter}
          handleSelectFilter={handleChangeSelectFilter}
        />
      </div>

      {!streaksQuery.isLoading ? (
        <div className="streaks-wrapper">
          {!isEmpty(streaksList) ? (
            <table>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className={
                      selectedFilter == 0
                        ? "negative"
                        : selectedFilter == 2
                        ? "no-input"
                        : ""
                    }
                  >
                    {headerGroup.headers.map((header) => (
                      <th key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    onClick={() => handleShowEmployeeMoodHistory(row.original)}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="no-data-found">No data found</p>
          )}
        </div>
      ) : (
        <div className="loading-streaks">
          <LoadingWidget />
        </div>
      )}

      <CModal
        centered={true}
        show={showStreaksHistory}
        onClose={() => {
          toggleShowStreaksHistory();
        }}
      >
        <CModalHeader closeButton>
          <CModalTitle>Energy History ({employeeInfo?.name})</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <InfiniteScroll
            className="energy-history"
            dataLength={energyHistory.length}
            next={employeeEnergyHistoryQuery.fetchNextPage}
            hasMore={employeeEnergyHistoryQuery.hasNextPage}
            loader={
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            }
            endMessage={isEmailStored ? <></> :
              employeeEnergyHistoryQuery.isLoading ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <span style={{ textAlign: "center" }}>
                  <b>You reached the end</b>
                </span>
              )
            }
          >
            {energyHistory.map((item) => (
              <EnergyHistoryItem key={item?.id} energy={item} />
            ))}
          </InfiniteScroll>
        </CModalBody>
      </CModal>
    </div>
  );
};

export default StreaksWidget;
