import { CButton, CForm, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import useFAQ from './helper';

const FAQModal = ({ FAQForm, setFAQForm, Edit, setEdit, getAllFaq, setDataa }) => {

    const { createFAQ, updateFAQ, onSubmit, FAQschema } = useFAQ();

    const [Data, setData] = useState(Edit?.data || {});
    useEffect(() => {
        setData(Edit?.data)
    }, [Edit])

    const initialValues = {
        question: Data?.question || "",
        answer: Data?.answer || "",
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: FAQschema,
        onSubmit: (values) => {
            onSubmit(values, Data, formik, setFAQForm, setDataa, setEdit, Edit)
        },
    });

    return (
        <CModal
            show={FAQForm}
            centered={true}
            backdrop={true}
            onClose={() => {
                setFAQForm(false)
                setEdit({ status: false, data: {} })
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <CModalHeader>
                    <CModalTitle>{Edit?.status ? "Edit" : "Add"} FAQ</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <div className='form-group d-flex flex-column gap-3 w-100 px-4'>
                        <div className='d-flex flex-column gap-2'>
                            <label htmlFor="">Question</label>
                            <input type="text" onChange={formik.handleChange} value={formik.values.question} name='question' className='form-control' />
                            <span className='text-danger small'>{formik.errors.question}</span>
                        </div>
                        <div className='d-flex flex-column gap-2'>
                            <label htmlFor="">Answer</label>
                            <textarea name="answer" onChange={formik.handleChange} value={formik.values.answer} id="" cols="30" rows="10" className='form-control'></textarea>
                            <span className='text-danger small'>
                                {formik.errors.answer}
                            </span>
                        </div>
                    </div>
                </CModalBody>
                <CModalFooter>

                    <CButton
                        style={{ width: "6rem", height: "3rem", backgroundColor: '#F56A14', color: 'white' }}
                        type='submit'
                    >{Edit?.status ? "Edit" : "Add"}</CButton>

                    <CButton
                        style={{ width: "6rem", height: "3rem", backgroundColor: '#4B9CEB', color: 'white' }}
                        onClick={() => {
                            setFAQForm(false)
                            setEdit({ status: false, data: {} })
                            formik.resetForm();
                        }}
                    >Cancel</CButton>
                </CModalFooter>
            </form>
        </CModal>
    )
}

export default FAQModal