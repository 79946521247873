import axios from "axios";
import store from "../store";
import moment from "moment";
import { isNull } from "lodash";
import { isUndefined } from "lodash";
import config from "../config";

const { BASE_API_URL } = config;

const baseUrl = `${BASE_API_URL}employee/`;
const employerbaseUrl = `${BASE_API_URL}employer/`;
const coachbaseUrl = `${BASE_API_URL}coach/`;
const webbaseUrl = `${BASE_API_URL}web/`;

export const isLoggedIn = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (
    localStorage.getItem("s_token") === undefined ||
    localStorage.getItem("s_token") === null
  ) {
    return false;
  }
  return true;
};

export const GET = async (getUrl, loginstatus = 0) => {
  const state = store.getState()?.loginReducer?.loginRole;
  const token = 
    state == 1 
    ? store.getState()?.loginReducer?.employerloginData?.token 
    : state == 2 
    ? store.getState()?.loginReducer?.coachloginData?.token 
    : store.getState()?.loginReducer?.loginData?.token;
  
  if (state || state === 0) {
    loginstatus = state;
  }

  let Finalurl =
    loginstatus === 0
      ? baseUrl
      : loginstatus === 1
      ? employerbaseUrl
      : coachbaseUrl;
  const url = `${Finalurl.concat(getUrl)}`;

  const data = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
  if (data?.status === 401 || data?.status === 402) {
    window.location = "/home";
    clearLocalStorage();
    alert("session expired!!Please Login Again");
  } else {
    return data;
  }
};

export const GETEmployerBaseUrl = async (getUrl) => {
  const url = `${employerbaseUrl.concat(getUrl)}`;
  const token = localStorage.getItem("s_token");
  const data = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
  if (data?.status === 401 || data?.status === 402) {
    window.location = "/home";
    localStorage.clear();
    alert("session expired!!Please Login Again");
  } else {
    return data;
  }
};

export const WebGetAPI = (path) => {
  return axios({
    method: "GET",
    url: webbaseUrl + path,
    headers: { "content-type": "application/json" },
  });
};

export const deleteChatSession = async (data) => {
  const token = localStorage.getItem("s_token");
  let loginstatus;
  const state = store.getState()?.loginReducer?.loginRole;
  if (state || state === 0) {
    loginstatus = state;
  }

  let param;
  if (loginstatus === 0) {
    param = "employee";
  } else if (loginstatus === 2) {
    param = "coach";
  }else if (loginstatus === 1){
    param = "employer";
  }
  const res = await fetch(
    `${BASE_API_URL}${param}/dropChatSession`,
    {
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
      method: "DELETE",
    }
  );

  const result = await res.json();
  return result;
};

export const deleteChatThread = async (posturl, data, loginstatus = 0) => {
  const token = localStorage.getItem("s_token");
  const state = store.getState()?.loginReducer?.loginRole;
  if (state || state === 0) {
    loginstatus = state;
  }
  let FinalbaseUrl =
    loginstatus === 0
      ? baseUrl
      : loginstatus === 1
      ? employerbaseUrl
      : coachbaseUrl;

  const finalurl = FinalbaseUrl + posturl;

  const ResponseData = await axios(finalurl, {
    data,
    headers: {
      "content-type": "application/json",
      Authorization: token,
    },
    method: "DELETE",
  }).catch((error) => console.log(error));

  if (ResponseData?.status === 401 || ResponseData?.status === 402) {
    window.location = "/home";
    clearLocalStorage();
    alert("session expired!!Please Login Again");
  } else {
    return ResponseData;
  }
};

export const deleteSlots = async (posturl, data, loginstatus = 0) => {
  const token = localStorage.getItem("s_token");
  const state = store.getState()?.loginReducer?.loginRole;
  if (state || state === 0) {
    loginstatus = state;
  }
  let FinalbaseUrl =
    loginstatus === 0
      ? baseUrl
      : loginstatus === 1
      ? employerbaseUrl
      : coachbaseUrl;

  const finalurl = FinalbaseUrl + posturl;
  const ResponseData = await axios(finalurl, {
    data,
    headers: {
      "content-type": "application/json",
      Authorization: token,
    },
    method: "DELETE",
  }).catch((error) => console.log(error));
  if (ResponseData?.status === 401 || ResponseData?.status === 402) {
    window.location = "/home";
    clearLocalStorage();
    alert("session expired!!Please Login Again");
  } else {
    return ResponseData;
  }
};

export const deleteAPI = async (posturl, loginstatus = 0) => {
  const token = localStorage.getItem("s_token");
  const state = store.getState()?.loginReducer?.loginRole;
  if (state || state === 0) {
    loginstatus = state;
  }
  let FinalbaseUrl =
    loginstatus === 0
      ? baseUrl
      : loginstatus === 1
      ? employerbaseUrl
      : coachbaseUrl;

  const finalurl = FinalbaseUrl + posturl;
  const ResponseData = await fetch(finalurl, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
  if (ResponseData?.status === 401 || ResponseData?.status === 402) {
    window.location = "/home";
    clearLocalStorage();
    alert("session expired!!Please Login Again");
  } else {
    return ResponseData;
  }
};

export const POST = (path, data, loginstatus = 0) => {
  const token = localStorage.getItem("s_token");
  const state = store.getState()?.loginReducer?.loginRole;

  if (state || state === 0) {
    loginstatus = state;
  }
  let Finalurl =
    loginstatus === 0
      ? baseUrl
      : loginstatus === 1
      ? employerbaseUrl
      : coachbaseUrl;
  return axios({
    method: "post",
    url: Finalurl + path,
    headers: {
      "content-type": "application/json",
      Authorization: token,
    },
    data: data,
  });
};

export const RESET_PASSWORD = (path, data, finalToken) => {
  const token = localStorage.getItem("s_token");
  const Data = JSON.stringify(data);
  return axios({
    method: "post",
    url: baseUrl + path,
    headers: {
      "content-type": "application/json",
      Authorization: finalToken || token,
    },
    data: Data,
  });
};

export const putAPI = (path, data, loginstatus = 0) => {
  const token = localStorage.getItem("s_token");
  const state = store.getState()?.loginReducer?.loginRole;
  if (state || state === 0) {
    loginstatus = state;
  }

  let Finalurl =
    loginstatus === 0
      ? baseUrl
      : loginstatus === 1
      ? employerbaseUrl
      : coachbaseUrl;

  return axios({
    method: "put",
    url: Finalurl + path,
    headers: { "content-type": "application/json", Authorization: token },
    data: data,
  });
};

export const RESETPOST = async (posturl, data, loginstatus = 0) => {
  const token = localStorage.getItem("s_token");
  const state = store.getState()?.loginReducer?.loginRole;
  if (state || state === 0) {
    loginstatus = state;
  }
  let FinalBaseurl =
    loginstatus === 0
      ? baseUrl
      : loginstatus === 1
      ? employerbaseUrl
      : coachbaseUrl;
  const finalurl = FinalBaseurl + posturl;
  const Data = JSON.stringify(data);
  const ResponseData = await fetch(finalurl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: token,
    },
    body: Data,
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
  if (ResponseData?.status === 401 || ResponseData?.status === 402) {
    window.location = "/home";
    clearLocalStorage();
    alert("session expired!!Please Login Again");
  } else {
    return ResponseData;
  }
};

export const POSTIMAGE = async (posturl, data, loginstatus = 0) => {
  const token = localStorage.getItem("s_token");
  const state = store.getState()?.loginReducer?.loginRole;
  if (state || state === 0) {
    loginstatus = state;
  }
  let FinalBaseurl =
    loginstatus === 0
      ? baseUrl
      : loginstatus === 1
      ? employerbaseUrl
      : coachbaseUrl;
  const finalurl = FinalBaseurl + posturl;

  const ResponseData = await fetch(finalurl, {
    method: "POST",
    headers: {
      Authorization: token,
    },
    body: data,
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
  return ResponseData;
};

export const getGoalPercentage = (current, complete) => {
  var temp = Number(current) / Number(complete);
  var temp1 = temp * 100;
  var resp = "";
  if (temp1.toString().length > 5) {
    var tempArray = temp1.toString().split(".");
    if (parseInt(tempArray[1]) > 1) {
      resp = temp1.toString().slice(0, 5);
    } else {
      resp = tempArray[0];
    }
  } else {
    resp = temp1.toString();
  }
  return resp;
};

export const getSelectMeasure = (selectMeasure) => {
  switch (selectMeasure) {
    case GOAL_QUANTITY_STATUS:
      return "Quantity";
    case GOAL_DOLLAR_STATUS:
      return "$";
    case GOAL_PERCENTAGE_STATUS:
      return "Percentage";
    default:
      return;
  }
};

export const clearLocalStorage = () => {
  const agree_terms = localStorage.getItem("agree_terms")
  localStorage.clear();
  localStorage.setItem("agree_terms",agree_terms)
};

export function getErrorMessage(error) {
  const message =
    (error?.response &&
      error?.response?.data &&
      error?.response?.data?.message) ||
    error?.message ||
    error?.toString();
  return message;
}

export const toDayMoment = (time) => {
  return moment(time).format("MM/DD/YYYY");
};

export const toDayMillisecond = (time) => {
  return moment(time).valueOf();
};

export const getRouteName = (pathname) => {
  const match = pathname?.match(/\/([^/]+)/);
  const route = match ? match[1] : null;
  return route;
};

export const checkIsValueExit =(value) =>{
  return isNull(value) || isUndefined(value)
}

export const verifyAccessToken = async (accessToken) =>{
  const token = localStorage.getItem("s_token");
  const result = await fetch(`${baseUrl}verifyAccessToken`, {
    body: JSON.stringify(
        {
          "accessToken":accessToken
        }
      ),
      headers: {
          Accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
      },
      method: "POST",
  });

  return result

}

// Attribute Rating Status
export const ACCEPTED_RATE = 0
export const PENDING_RATE = 1
export const REJECTED_RATE = 2

export const ACTIVE_TICKET_STATUS = 1
export const RESOLVED_TICKET_STATUS = 2

export const GOAL_QUANTITY_STATUS = 1
export const GOAL_DOLLAR_STATUS = 2
export const GOAL_PERCENTAGE_STATUS = 3