import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import {
  CButton,
  CForm,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";

import { useTickets, useOpenTicket } from "../../hooks/ticket.hook";
import { openTicketValidation } from "../../utils/validation";
import { ACTIVE_TICKET_STATUS, putAPI } from "../../utils/helper";
import RichText from "../../Components/RichText";
import "../../scss/customforall.scss";
import "./style.scss";
import "../../styles/ticket-modal-dialog.scss";

export default function Contact() {
  const history = useHistory();
  const ticketsQuery = useTickets();
  const openTicketMutation = useOpenTicket();
  const [isModelOpen, setModelOpen] = useState(false);
  const columnHelper = createColumnHelper();

  const toggleOpenModel = () => {
    setModelOpen(!isModelOpen);
  };

  const formik = useFormik({
    initialValues: {
      subject: "",
      content: "",
    },
    onSubmit: async (values) => {
      try {
        const res = await openTicketMutation.mutateAsync(values);

        if (res?.data?.status === 200) {
          toast.success("Message Send Successfully", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          ticketsQuery.refetch();
          formik.resetForm();
          toggleOpenModel();
        }
      } catch (error) {
        toast.error("Something went wrong", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    },
    validationSchema: openTicketValidation,
    enableReinitialize: true,
  });

  const columns = [
    columnHelper.accessor((row) => row.id, {
      id: "ticket",
      cell: (info) => <i>#{info.getValue()}</i>,
      header: "Ticket",
    }),
    columnHelper.accessor((row) => row.subject, {
      id: "subject",
      cell: (info) => <i className="subject">{info.getValue()}</i>,
      header: () => <span>Subject</span>,
    }),
    columnHelper.accessor((row) => row.status, {
      id: "status",
      cell: (info) => (
        <i>{info.getValue() == ACTIVE_TICKET_STATUS ? "Active" : "Resolved"}</i>
      ),
      header: () => <span>Status</span>,
    }),
    columnHelper.accessor((row) => row.id, {
      id: "actions",
      cell: (info) => (
        <button
          className="details-btn"
          onClick={() => {
            history.push(`/ticket/${info.getValue()}`);
          }}
        >
          Details
        </button>
      ),
      header: "Actions",
    }),
  ];
  const ticket_data = ticketsQuery?.data?.data?.body?.data;
  const table = useReactTable({
    data: ticket_data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="primary-bg">
      <div className="contact-wrapper container">
        {!ticketsQuery.isLoading ? (
          <div className="contact-header">
            <h3>Tickets</h3>
            <button onClick={toggleOpenModel}>New ticket</button>
          </div>
        ) : null}

        {!ticketsQuery.isLoading && table.getRowModel().rows.length > 0 ? (
          <table className="table-bluexinga">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row,i) => (
                <tr key={row.id} className={ticket_data[i]?.unread && "unread_tr"}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : ticketsQuery.isLoading ? (
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <div className="no-content">
            <h4>No ticket has been opened </h4>
          </div>
        )}
      </div>

      <CModal centered={true} show={isModelOpen} onClose={toggleOpenModel}>
        <CModalHeader>
          <CModalTitle>Open new contact ticket</CModalTitle>
        </CModalHeader>
        <CForm onSubmit={formik.handleSubmit}>
          <CModalBody>
            <div className="reply-ticket-modal">
              <div className="form-group">
                <label for="formGroupExampleInput">Subject</label>
                <input
                  id="formGroupExampleInput"
                  type="text"
                  name="subject"
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  className="form-control"
                  placeholder="Write here subject"
                />
                {formik.touched.subject && formik.errors.subject ? (
                  <div className="errmsg">{formik.errors.subject}</div>
                ) : null}
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">
                  Reply Message
                </label>
                <br />
                <br />
                <RichText
                  value={formik.values.content}
                  onChange={(value) => {
                    formik.setFieldValue("content", value);
                  }}
                />
                {formik.touched.content && formik.errors.content ? (
                  <div className="errmsg">{formik.errors.content}</div>
                ) : null}
              </div>
            </div>
          </CModalBody>
          <CModalFooter style={{ display: "flex" }}>
            <CButton
              className="ticket-reply-btn"
              color="secondary"
              type="button"
              onClick={toggleOpenModel}
            >
              Cancel
            </CButton>
            <CButton
              className="ticket-reply-btn"
              color="primary"
              type="submit"
              disabled={openTicketMutation.isLoading}
            >
              Open ticket
            </CButton>
          </CModalFooter>
        </CForm>
      </CModal>
    </div>
  );
}
